import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import {featureAccessDeniedContent, roleAccessDeniedContent} from 'constants/variables'
import React from 'react'
import {AiFillLock} from 'react-icons/ai'
import {KooperButton, KooperContainer, KooperDivider, KooperRadio, KooperTooltip} from 'kooper-library'

const SettingLayout = props => {
  const {
    header,
    subHeader,
    learnMoreUrl = '',
    actionButton,
    headerButton,
    children,
    paddingLeft,
    headerRadioButton = false,
    headerbackbtn = false,
    table = false,
    fullWidth = false,
    headerDivider = false,
    icon,
    lockRole = false,
    lockFeature = false,
  } = props

  const renderHeaderButton = () => {
    if (lockFeature || lockRole) {
      return (
        <KooperTooltip
          content={lockFeature ? featureAccessDeniedContent : roleAccessDeniedContent}
          position="top center"
          size="mini"
          trigger={<KooperButton className="disabled-button" primary {...headerButton} />}
        />
      )
    }
    return <KooperButton primary {...headerButton} />
  }

  const renderHeaderRadioButton = () => {
    if (lockFeature || lockRole) {
      return (
        <KooperTooltip
          content={lockFeature ? featureAccessDeniedContent : roleAccessDeniedContent}
          position="top center"
          size="mini"
          trigger={
            <div>
              <AiFillLock className="mr-2 permission-icon" />
              <KooperRadio toggle {...headerRadioButton} disabled />
            </div>
          }
        />
      )
    }
    return <KooperRadio toggle {...headerRadioButton} />
  }

  return (
    <div
      style={paddingLeft ? {paddingLeft: 0} : {}}
      className={`set-layout ${table ? 'set-layout-tabel' : ''} ${fullWidth ? 'set-layout-full' : ''}`}
    >
      <KooperContainer>
        <div className="settingHeader">
          {icon ? <div className="settingHeaderIcon">{icon}</div> : null}
          <div className="settingHeaderText">
            {header && (
              <>
                {headerbackbtn && (
                  <KooperButton className="backBtn" {...headerbackbtn}>
                    <SvgIcon path="common/left" /> Back
                  </KooperButton>
                )}
                <div className="title">
                  <div className="d-flex" style={{justifyContent: 'space-between', width: '100%'}}>
                    <div>
                      <h4>{header}</h4>
                      <p className="set-sub-header">{subHeader}</p>
                    </div>
                    {headerButton && renderHeaderButton()}
                    {headerRadioButton && renderHeaderRadioButton()}
                  </div>
                </div>
              </>
            )}
            {(subHeader || learnMoreUrl) && (
              <p className="set-sub-header">
                {learnMoreUrl && (
                  <a href={learnMoreUrl} className="learn-more">
                    <SvgIcon path="common/book" /> learn more
                  </a>
                )}
              </p>
            )}
          </div>
        </div>
        {headerDivider && <KooperDivider />}
        <div className="set-my-profile">
          {children}
          {actionButton && (
            <div className="set-action-btn">
              {actionButton.cancel && <KooperButton basic {...actionButton.cancel} />}
              {actionButton.success && (
                <LockPermissionTooltip isFeatureAccessDenied={lockFeature} isRoleAccessDenied={lockRole}>
                  <KooperButton
                    primary
                    className={lockFeature || lockRole ? 'disabled-button' : ''}
                    {...actionButton.success}
                  />
                </LockPermissionTooltip>
              )}
            </div>
          )}
        </div>
      </KooperContainer>
    </div>
  )
}
export default SettingLayout
