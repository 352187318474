/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-prototype-builtins */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  KooperButton,
  KooperDropdown,
  KooperForm,
  KooperFormField,
  KooperInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperRadio,
} from 'kooper-library'
import {ADD_CONTACT_DEAL} from 'actions/types'
import {addContactDeal} from 'actions/deals'
import {get} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const AddContactModal = props => {
  const dispatch = useDispatch()
  const {isModalOpen, setIsModalOpen, dealId} = props
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {getAllContactsList, getAllContactCompanyList, addContactDealMsg} = useSelector(state => state.deals)

  const [allDataList, setAllDataList] = useState([])
  const [primaryValue, setPrimaryValue] = useState({})
  const [primaryModal, setPrimaryModal] = useState(false)
  const [radioprimary, setRadioprimary] = useState(false)
  const [primaryContactData, setPrimaryContactData] = useState([])
  const [contactCompanyList, setContactCompanyList] = useState([])
  const [contactError, setContactError] = useState(false)

  useEffect(() => {
    if (getAllContactCompanyList) {
      const companyList = getAllContactCompanyList.map(list => ({
        key: list.name,
        value: list.id,
        text: list.name,
      }))
      setContactCompanyList(companyList)
    }
  }, [getAllContactCompanyList])
  useEffect(() => {
    if (getAllContactsList) {
      const contactData = getAllContactsList.map(list => ({
        key: `contact${list.id}`,
        text: `${`${get(['firstName'], list, '')} ${get(['lastName'], list, '')}`.trim() || `#${list.id}`} (Contact)`,
        value: `contactId${list.id}`,
      }))
      setPrimaryContactData([...primaryContactData, ...contactData])
      setAllDataList([...allDataList, ...getAllContactsList])
    }
  }, [getAllContactsList])
  useEffect(() => {
    if (successLabels.includes(ADD_CONTACT_DEAL)) {
      setPrimaryValue({})
    }
  }, [successLabels])

  const addNewContact = () => {
    // delete primaryValue.type;
    if (primaryValue.hasOwnProperty('leadId') || primaryValue.hasOwnProperty('contactId')) {
      delete primaryValue.companyId
    }
    const data = _.omitBy(primaryValue, _.isNull)
    // dispatch(addContactDeal(dealId, data));
    if (primaryValue.type) {
      setContactError(false)
      delete data.type
      dispatch(addContactDeal(dealId, data))
    } else {
      setContactError(true)
    }
  }

  const primaryContactFunction = value => {
    if (_.includes(value, 'leadId')) {
      delete primaryValue.contactId
      // setPrimaryValue({});
      const lead = value.substring(6)
      const temp = allDataList.filter(list => {
        if (list.id === lead) {
          return list
        }
      })
      setPrimaryValue({
        leadId: lead,
        companyId: _.get(temp[0], 'company.name', '--'),
        type: value,
      })
    } else if (_.includes(value, 'contactId')) {
      delete primaryValue.leadId
      // setPrimaryValue({});
      const contact = value.substring(9)
      const tempC = allDataList.filter(list => {
        if (list.id === contact) {
          return list
        }
      })
      setPrimaryValue({
        contactId: contact,
        companyId: _.get(tempC[0], 'company.name', '--'),
        type: value,
      })
    }
  }
  useEffect(() => {
    if (addContactDealMsg === 200) {
      setPrimaryValue({})
    }
    if (addContactDealMsg && addContactDealMsg.statusCode === 400) {
      dispatch({type: 'CLEAR_ADD_CONTACT_DEAL'})
    }
  }, [addContactDealMsg])

  const isModalPrimary = () => (
    <KooperModal
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={primaryModal}
      onClose={() => setPrimaryModal(!primaryModal)}
    >
      <KooperModalHeader>Add a New Primary Contact</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperForm>
          <KooperFormField>
            <KooperRadio
              label="Create as Contact"
              name="radioGroup"
              checked={radioprimary === true}
              onChange={() => {
                setRadioprimary(!radioprimary)
              }}
            />
            {radioprimary && (
              <KooperInput
                onChange={(e, {value}) => {
                  setPrimaryValue({contact: value, type: value})
                }}
              />
            )}
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setPrimaryModal(false)}>
          Cancel
        </KooperButton>
        <KooperButton
          color="blue"
          onClick={() => {
            setPrimaryContactData([
              ...primaryContactData,
              {
                key: primaryValue.type,
                text: primaryValue.type,
                value: primaryValue.type,
              },
            ])
            setPrimaryModal(false)
          }}
        >
          Add
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={isModalOpen}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <KooperModalHeader>Add New People</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperForm>
          <KooperFormField required>
            <label>Primary Contact</label>
            <KooperDropdown
              selection
              search
              fluid
              value={primaryValue.type}
              options={_.uniqBy(primaryContactData, 'key')}
              allowAdditions
              additionLabel="create as New "
              onAddItem={() => setPrimaryModal(!primaryModal)}
              onChange={(e, {value}) => {
                primaryContactFunction(value)
                setContactError(false)
              }}
              error={
                contactError && {
                  content: '  Please select contact to link',
                }
              }
              // value={primaryValue["leadId" || "companyId" || "lead" || "contact"]}
            />
            {contactError && (
              <p
                style={{
                  color: '#a03c3a',
                  fontSize: '11px',
                  margin: '4px',
                }}
              >
                Please select contact to link
              </p>
            )}
          </KooperFormField>

          {primaryValue.hasOwnProperty('contact') ? (
            <KooperFormField>
              <label>Company</label>
              <KooperDropdown
                selection
                fluid
                search
                value={primaryValue.companyId}
                options={contactCompanyList}
                onChange={(e, {value}) => {
                  setPrimaryValue({...primaryValue, companyId: value})
                }}
                allowAdditions
                additionLabel="Add company "
                onAddItem={(e, {value}) => {
                  delete primaryValue.companyId
                  setContactCompanyList([...contactCompanyList, {key: value, text: value, value}])
                  setPrimaryValue({...primaryValue, company: value})
                }}
              />
            </KooperFormField>
          ) : (
            <KooperFormField>
              <label>Company</label>
              <KooperInput
                disabled={!(primaryValue.hasOwnProperty('lead') || primaryValue.hasOwnProperty('contact'))}
                placeholder="add company"
                value={primaryValue.companyId}
                onChange={(e, {value}) => {
                  setPrimaryValue({...primaryValue, company: value})
                }}
              />
            </KooperFormField>
          )}
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={() => addNewContact()}>
          Add
        </KooperButton>
      </KooperModalActions>
      {primaryModal && isModalPrimary()}
    </KooperModal>
  )
}

export default AddContactModal
