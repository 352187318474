import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperForm,
  KooperFormCheckbox,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormRadio,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'

import {CREATE_PROJECTS_TIMESHEET, GET_PROJECTS_SINGLE_TIMESHEET, UPDATE_PROJECTS_TIMESHEET} from 'actions/types'
import {createProjectsTimesheet, getProjectsSingleTimesheet, updateProjectsTimesheet} from 'actions/projects'
import {get, removeDoubleQuotes} from 'utils/helper'
import _ from 'lodash'
import {timesheetSchema} from 'validation/Projects/projects/time.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const timesheetDefaultValues = {
  title: '',
  enableEstimation: false,
  estimatedHrs: null,
  estimatedMins: null,
  isPublic: true,
  subscribers: [],
}

const TimesheetModal = ({open, toggle, projectId, timesheetId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectAssignees = [], timesheetData = {}} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, watch, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(timesheetSchema),
    defaultValues: timesheetDefaultValues,
  })

  useApiResponse({
    action: getProjectsSingleTimesheet,
    payload: {projectId, timesheetId},
    dependency: [projectId, timesheetId],
    enabled: projectId && timesheetId,
    label: GET_PROJECTS_SINGLE_TIMESHEET,
    storePath: 'projects.timesheetData',
    onSuccess: (timesheetData = {}) => {
      const data = {
        ..._.pick(timesheetData, ['title', 'enableEstimation', 'estimatedHrs', 'estimatedMins', 'isPublic']),
        subscribers: get(['subscribers'], timesheetData, []),
      }
      reset(data)
    },
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECTS_TIMESHEET) || successLabels.includes(UPDATE_PROJECTS_TIMESHEET)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    if (timesheetId) {
      dispatch(updateProjectsTimesheet({projectId, timesheetId, data}))
    } else {
      dispatch(createProjectsTimesheet({projectId, data}))
    }
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>{`${timesheetId ? 'Edit' : 'Add'} Timesheet`}</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <label>Title</label>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  maxLength={20}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField>
            <Controller
              name="enableEstimation"
              control={control}
              render={({value, onChange}) => (
                <KooperFormCheckbox
                  label="Estimated time"
                  checked={value}
                  onChange={(e, {checked}) => onChange(checked)}
                />
              )}
            />
          </KooperFormField>
          {watch('enableEstimation') && (
            <KooperFormGroup>
              <KooperFormField required width={8}>
                <div className="info-header">
                  <label>Hours</label>
                  <KooperTooltip
                    content="Set time in terms of hours"
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="right center"
                  />
                </div>
                <Controller
                  name="estimatedHrs"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormInput
                      type="number"
                      value={value}
                      onChange={(e, {value}) => onChange(+value)}
                      min={0}
                      max={24}
                      error={
                        errors.estimatedHrs && {
                          content: removeDoubleQuotes(errors.estimatedHrs.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
              <KooperFormField required width={8}>
                <div className="info-header">
                  <label>Minutes</label>
                  <KooperTooltip
                    content="Set time in terms of minutes"
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="right center"
                  />
                </div>
                <Controller
                  name="estimatedMins"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormInput
                      type="number"
                      value={value}
                      onChange={(e, {value}) => onChange(+value)}
                      min={0}
                      max={59}
                      error={
                        errors.estimatedMins && {
                          content: removeDoubleQuotes(errors.estimatedMins.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>
          )}
          <KooperFormField>
            <div className="d-flex">
              <div>
                <label>Mark as private</label>
              </div>
              <Controller
                name="isPublic"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormRadio toggle checked={!value} onClick={(e, {checked}) => onChange(!checked)} />
                )}
              />
            </div>
          </KooperFormField>
          {!watch('isPublic') && (
            <KooperFormField required>
              <label>Subscribers</label>
              <Controller
                name="subscribers"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormSelect
                    search
                    multiple
                    options={projectAssignees.map(({id, firstName, lastName}) => ({
                      key: id,
                      value: id,
                      text: `${firstName} ${lastName}`,
                    }))}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.subscribers && {content: removeDoubleQuotes(errors.subscribers.message)}}
                  />
                )}
              />
            </KooperFormField>
          )}
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          {timesheetId ? 'Update' : 'Add'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default TimesheetModal
