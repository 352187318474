import React from 'react'
import {KooperButton, KooperCard, KooperCardContent, KooperForm} from 'kooper-library'
import PreviewQuestions from './PreviewQuestions'

function FormPreview({watchFormData}) {
  const {settings, questions, design} = watchFormData

  return (
    <KooperCard fluid>
      <KooperCardContent>
        <div className="design-preview-tabs">
          <div className="formContent">
            <div className="formContentBody">
              <div className="formContentBodyhead" style={{backgroundColor: design?.backgroundColor}}>
                <p>{settings?.title}</p>
              </div>
              <div className="form-preview">
                <KooperForm className="errorLabel">
                  {questions.map(q => (
                    <>
                      <PreviewQuestions {...q} />
                    </>
                  ))}
                </KooperForm>
              </div>
              <div className="preview-contentfooter">
                <KooperButton
                  primary
                  style={{backgroundColor: design?.submitButtonColor, marginTop: design?.submitButtonMargin}}
                >
                  {design?.submitButtonText}
                </KooperButton>
              </div>
            </div>
            <div className="poweredByLogo">{settings?.showBranding && <span>Powered By Kooper</span>}</div>
          </div>
        </div>
      </KooperCardContent>
    </KooperCard>
  )
}

export default FormPreview
