/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiCheckSquare, FiFolder, FiSliders, FiStar, FiUserCheck} from 'react-icons/fi'
import {KooperDropdown, KooperDropdownItem, KooperDropdownMenu, KooperTooltip} from 'kooper-library'
import {
  massAction,
  moveConversationTo,
  updateConversation,
  updateConversationPriority,
  updateConversationStatus,
} from 'actions/inbox'
import CreateLabel from 'components/common/CreateLabel'
import UserImage from 'components/common/UserImage'
import KooperCustomDropdown from 'components/common/KooperCustomDropdown'
import {getTags} from 'actions/settings_productivity'
import {getUsers} from 'actions/settings'
import {
  GET_TAGS,
  MASS_ACTION,
  MERGE_CONVERSATION,
  MOVE_CONVERSATION_TO,
  PUT_CONVERSATION,
  SNOOZE_CONVERSATION,
} from 'actions/types'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {INBOX_TYPES} from 'constants/enums'

export const MassAction = props => {
  const {conversations, checkedConversations, setCheckedConversations, selectedInbox, refreshInboxData, setBulkSelect} =
    props

  const dispatch = useDispatch()

  const [newLabelModal, setNewLabelModal] = useState(false)
  const [users, setUsers] = useState([])
  const [listOfRemainingInbox, setListOfRemainingInbox] = useState([])
  const [labelOptionsState, setLabelOptionsState] = useState([])

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {statusList = []} = useSelector(state => state.emailInbox)
  const {inbox: mailBoxes = []} = useSelector(state => state.mailInbox)

  const {conversationsPermissions} = useUserPermissions()
  const manageConversationPermissions = conversationsPermissions?.manage_conversations
  const moveConversationPermissions = conversationsPermissions?.move_conversation

  const createLabel = data => {
    dispatch(massAction({...data, ids: checkedConversations}))
  }

  const createNewLabel = () => {
    return <CreateLabel isModalOpen={newLabelModal} setIsModalOpen={setNewLabelModal} createMethod={createLabel} />
  }

  const changeStatus = status => {
    const data = {
      statusId: status,
      conversationIds: checkedConversations,
    }
    dispatch(updateConversationStatus(data, 'Status'))
  }

  const changePriority = priority => {
    const data = {
      priority,
      conversationIds: checkedConversations,
    }
    dispatch(updateConversationPriority(data, 'Priority'))
  }

  const changeFav = fav => {
    const data = {
      isStarred: fav,
      conversationIds: checkedConversations,
    }
    dispatch(updateConversation(data, 'Favourite'))
  }

  const renderListOfAssigness = () => {
    return (
      <KooperCustomDropdown
        options={users}
        dropdownProps={{
          item: true,
          icon: (
            <KooperTooltip
              size="mini"
              content={
                manageConversationPermissions
                  ? 'Assignee'
                  : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
              }
              position="bottom center"
              inverted
              trigger={<FiUserCheck />}
            />
          ),
          button: true,
          className: 'icon',
        }}
        menu={{scrolling: true}}
        input={{
          icon: 'search',
          iconPosition: 'left',
          className: 'search',
        }}
        header="Assignee"
        item={{disabled: !manageConversationPermissions}}
        onClick={value => {
          if (!manageConversationPermissions) return
          if (value === 'unass') {
            dispatch(
              massAction({
                ids: checkedConversations,
                unassign: true,
              })
            )
          } else {
            dispatch(
              massAction({
                ids: checkedConversations,
                assigneeId: value,
              })
            )
          }
        }}
      />
    )
  }

  const renderListOfMailInbox = () => {
    return listOfRemainingInbox.map(inbox => (
      <KooperDropdownItem
        onClick={() => {
          if (!moveConversationPermissions) return
          const data = {
            conversationIds: checkedConversations,
            inboxId: inbox.value,
          }

          dispatch(moveConversationTo(data))
        }}
        disabled={!moveConversationPermissions || inbox.disabled}
      >
        {inbox.label}
      </KooperDropdownItem>
    ))
  }

  useEffect(() => {
    if (
      successLabels.includes(PUT_CONVERSATION) ||
      successLabels.includes(SNOOZE_CONVERSATION) ||
      successLabels.includes(MERGE_CONVERSATION) ||
      successLabels.includes(MOVE_CONVERSATION_TO) ||
      successLabels.includes(MASS_ACTION)
    ) {
      refreshInboxData()
      setCheckedConversations([])
      setBulkSelect(false)
    }
  }, [successLabels, setCheckedConversations, refreshInboxData, setBulkSelect])

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  useApiResponse({
    label: GET_TAGS,
    storePath: 'settingsProductivity.tagList',
    onSuccess: tagList => {
      const list = tagList?.map(({id, label, color}) => ({
        key: id,
        value: id,
        text: label,
        label: {color, empty: true},
      }))
      setLabelOptionsState(list || [])
    },
  })

  useEffect(() => {
    if (selectedInbox.type === 'inbox') {
      const assigneeList = (mailBoxes.find(inbox => inbox.id === selectedInbox.name).assignees || []).map(teammate => {
        const data = {
          value: teammate.id,
          text: teammate.firstName,
          key: teammate.id,
        }

        if (teammate.avatar) {
          data.image = {avatar: true, src: teammate.avatar}
        } else {
          data.content = (
            <>
              <UserImage profile={teammate} className="ui avatar image" />
              {teammate.firstName} {teammate.lastName}
            </>
          )
        }

        return data
      })
      setUsers([
        {
          key: 'unass',
          value: 'unass',
          text: 'Unassign',
          content: (
            <>
              <UserImage className="ui avatar image" />
              Unassign
            </>
          ),
        },
        ...assigneeList,
      ])

      const listOfInboxExceptOne = mailBoxes
        .filter(inbox => inbox.id !== selectedInbox.name)
        .filter(inbox => (inbox.type === INBOX_TYPES.EMAIL ? inbox.emailVerified : true))
        .map(inbox => ({
          value: inbox.id,
          label: inbox.name,
        }))
      setListOfRemainingInbox(listOfInboxExceptOne)
    }
  }, [mailBoxes, selectedInbox])

  return (
    <div className="email-02">
      {newLabelModal && createNewLabel()}
      <div className="massAction">
        <h1>What would you like to do?</h1>
        <p className="kooper-lead">
          {checkedConversations.length > 0
            ? `${checkedConversations.length} conversations out of ${conversations.length}`
            : ''}
        </p>
        <p className="kooper-lead bulkSelect">
          <span
            href=""
            onClick={() => {
              const ids = conversations.map(conv => conv.id)
              setCheckedConversations(ids)
            }}
          >
            Select All
          </span>
          <span className="mx-2 ">|</span>
          <span href="" onClick={() => setCheckedConversations([])}>
            Unselect All
          </span>
        </p>
        <div className="massActBtn">
          <KooperTooltip
            size="mini"
            content={
              manageConversationPermissions
                ? 'Status'
                : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
            }
            position="bottom center"
            inverted
            trigger={
              <KooperDropdown floating button className="icon" icon={<FiCheckSquare />}>
                <KooperDropdownMenu>
                  {statusList.map(status => (
                    <KooperDropdownItem
                      key={status.id}
                      text={startCase(status.status)}
                      onClick={() => manageConversationPermissions && changeStatus(status.id)}
                      disabled={!manageConversationPermissions}
                    />
                  ))}
                </KooperDropdownMenu>
              </KooperDropdown>
            }
          />
          <KooperTooltip
            size="mini"
            content={
              manageConversationPermissions
                ? 'Priority'
                : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
            }
            position="bottom center"
            inverted
            trigger={
              <KooperDropdown icon={<FiSliders />} floating button className="icon">
                <KooperDropdownMenu>
                  <KooperDropdownItem
                    text="Default"
                    disabled={!manageConversationPermissions}
                    onClick={() => manageConversationPermissions && changePriority('default')}
                  />
                  <KooperDropdownItem
                    text="Low"
                    disabled={!manageConversationPermissions}
                    onClick={() => manageConversationPermissions && changePriority('low')}
                  />
                  <KooperDropdownItem
                    text="Medium"
                    disabled={!manageConversationPermissions}
                    onClick={() => manageConversationPermissions && changePriority('medium')}
                  />
                  <KooperDropdownItem
                    text="High"
                    disabled={!manageConversationPermissions}
                    onClick={() => manageConversationPermissions && changePriority('high')}
                  />
                  <KooperDropdownItem
                    text="Critical"
                    disabled={!manageConversationPermissions}
                    onClick={() => manageConversationPermissions && changePriority('critical')}
                  />
                </KooperDropdownMenu>
              </KooperDropdown>
            }
          />
          {selectedInbox.type === 'inbox' ? renderListOfAssigness() : null}
          <KooperDropdown
            icon={
              <KooperTooltip
                size="mini"
                content={
                  manageConversationPermissions
                    ? 'Favourite'
                    : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
                }
                position="bottom center"
                inverted
                trigger={<FiStar />}
              />
            }
            floating
            button
            className="icon"
          >
            <KooperDropdownMenu>
              <KooperDropdownItem
                text=" Mark as Favourite"
                disabled={!manageConversationPermissions}
                onClick={() => manageConversationPermissions && changeFav(true)}
              />
              <KooperDropdownItem
                text="Remove as Favourite"
                disabled={!manageConversationPermissions}
                onClick={() => manageConversationPermissions && changeFav(false)}
              />
            </KooperDropdownMenu>
          </KooperDropdown>
          {selectedInbox.type === 'inbox' && (
            <KooperDropdown
              icon={
                <KooperTooltip
                  size="mini"
                  content={
                    moveConversationPermissions
                      ? 'MoveTo'
                      : getPermissionTooltipContent({roleAccess: moveConversationPermissions})
                  }
                  position="bottom center"
                  inverted
                  trigger={<FiFolder />}
                />
              }
              floating
              button
              className="icon"
            >
              <KooperDropdownMenu>{renderListOfMailInbox()}</KooperDropdownMenu>
            </KooperDropdown>
          )}
          <KooperCustomDropdown
            options={labelOptionsState}
            multiple
            dropdownProps={{
              className: 'bg-btn-color',
              onClick: () => dispatch(getTags()),
              // upward: true,
              icon: (
                <KooperTooltip
                  content={
                    manageConversationPermissions
                      ? 'Add Tag'
                      : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
                  }
                  size="mini"
                  position="bottom center"
                  inverted
                  trigger={<SvgIcon path="common/tag" id="TooltipTagInbox" />}
                />
              ),
              closeOnChange: false,
            }}
            menu={{scrolling: true}}
            input={{
              icon: 'search',
              iconPosition: 'left',
              className: 'search',
            }}
            item={{disabled: !manageConversationPermissions}}
            onClick={value => {
              if (!manageConversationPermissions) return
              const data = {
                ids: checkedConversations,
                labelIds: value,
              }
              dispatch(massAction(data, 'Tag updated'))
            }}
            extraAction={{
              content: '+ Add Tag',
              onClick: () => manageConversationPermissions && setNewLabelModal(true),
              disabled: !manageConversationPermissions,
            }}
          />
        </div>
      </div>
    </div>
  )
}
