import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  KooperButton,
  KooperCheckbox,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'
import {removeDoubleQuotes} from 'utils/helper'
import {moveProjectsTasksListTask} from 'actions/projects'
import {MOVE_PROJECTS_TASKS_LIST_TASK} from 'actions/types'
import {moveTaskSchema} from 'validation/Projects/projects/tasks.schema'
import SvgIcon from 'components/common/SvgIcon'

export const moveTaskDefaultValues = {
  title: '',
  projectId: null,
  tasklistId: null,
}

const MoveTaskModal = ({open, toggle, taskTitle, projectId, taskListId, taskId}) => {
  const dispatch = useDispatch()

  const [contents, setContents] = useState({
    assignees: true,
    dates: true,
    comments: true,
  })

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsList = [], tasksList = []} = useSelector(state => state.projects)

  const {handleSubmit, errors, control, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(moveTaskSchema),
    defaultValues: moveTaskDefaultValues,
  })

  useEffect(() => {
    const data = {title: taskTitle, projectId: Number(projectId)}
    reset(data)
  }, [reset, taskTitle, projectId])

  useEffect(() => {
    if (successLabels.includes(MOVE_PROJECTS_TASKS_LIST_TASK)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    const contentsObj = _.omitBy(contents, value => !value)
    const contentsArr = Object.keys(contentsObj)
    const payload = {...data, contents: contentsArr}
    dispatch(moveProjectsTasksListTask({projectId, taskId, data: payload}))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Move Task(s)</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <label>Title</label>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <label>Project</label>
            <Controller
              name="projectId"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  options={projectsList.map(({id, title}) => ({key: id, value: id, text: title}))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.projectId && {content: removeDoubleQuotes(errors.projectId.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <label>Tasklist</label>
            <Controller
              name="tasklistId"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  options={tasksList
                    .filter(task => task.id !== Number(taskListId))
                    .map(({id, title}) => ({key: id, value: id, text: title}))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.tasklistId && {content: removeDoubleQuotes(errors.tasklistId.message)}}
                />
              )}
            />
          </KooperFormField>
          <label>Move</label>
          <KooperFormField className="m-0">
            <KooperCheckbox
              label="Assignees"
              checked={contents.assignees}
              onChange={() => setContents({...contents, assignees: !contents.assignees})}
            />
          </KooperFormField>
          <KooperFormField className="m-0">
            <KooperCheckbox
              label="Dates"
              checked={contents.dates}
              onChange={() => setContents({...contents, dates: !contents.dates})}
            />
          </KooperFormField>
          <KooperFormField className="m-0">
            <KooperCheckbox
              label="Comments"
              checked={contents.comments}
              onChange={() => setContents({...contents, comments: !contents.comments})}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Ok
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default MoveTaskModal
