import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {FaPlus} from 'react-icons/fa'
import {KooperButton, KooperFormField, KooperFormInput, KooperFormTextArea, KooperRadio} from 'kooper-library'

const Date = ({question, control, currentIndex}) => {
  const [active, setActive] = useState(false)

  return (
    <div className="edit-component-wrapper">
      <div className="edit-component-content">
        <KooperFormField>
          <Controller
            control={control}
            name={`questions[${currentIndex}].question`}
            render={({value, onChange}) => (
              <>
                <KooperFormInput
                  maxLength={100}
                  type="text"
                  required
                  fluid
                  label="Question"
                  placeholder="Enter the question here"
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                />
              </>
            )}
          />
        </KooperFormField>
        <div className="d-flex" style={{marginBottom: '1.5em'}}>
          {!active ? (
            <KooperButton className="addDesBtn" onClick={() => setActive(true)}>
              <FaPlus /> Add description
            </KooperButton>
          ) : null}
        </div>
        {active && (
          <KooperFormField>
            <Controller
              control={control}
              name={`questions[${currentIndex}].description`}
              render={({value, onChange}) => (
                <KooperFormTextArea
                  type="text"
                  // maxLength={250}
                  value={value}
                  placeholder="Description"
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                />
              )}
            />
          </KooperFormField>
        )}
        <KooperFormField>
          <Controller
            name={`questions[${currentIndex}].isRequired`}
            control={control}
            render={({value, onChange}) => (
              <KooperRadio toggle label="Required" checked={value} onChange={(e, {checked}) => onChange(checked)} />
            )}
          />
        </KooperFormField>
      </div>
    </div>
  )
}
export default Date
