import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm, Controller} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperModalHeader,
  KooperModalContent,
  KooperButton,
  KooperModal,
  KooperModalActions,
  KooperFormField,
  KooperForm,
  KooperFormInput,
} from 'kooper-library'
import {get} from 'utils/helper'
import {taskEnteriesSchema} from 'validation/Projects/task.schema'

const UpdateModal = props => {
  const dispatch = useDispatch()

  const {
    isLoadingData,
    type: reducerType,
    successLabels = [],
  } = useSelector(state => {
    return get(['apiReducer'], state)
  })
  const {idTobeUpdated, updateAction, isModalOpen, setIsModalOpen, setValue, taskId, deleteModelHeader, type} = props

  useEffect(() => {
    if (successLabels.includes(type)) {
      setIsModalOpen(false)
    }
  }, [successLabels, type, setIsModalOpen])

  const initialtaskEntries = {
    title: setValue.description,
    duration: setValue.duration,
  }

  const {
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
  } = useForm({
    resolver: joiResolver(taskEnteriesSchema),
    mode: 'onTouched',
    submitFocusError: true,
    defaultValues: initialtaskEntries,
  })

  const submitEntries = data => {
    if (taskId) {
      dispatch(
        updateAction(idTobeUpdated, {
          description: data.title,
          updatedDuration: data.duration,
        })
      )
    } else {
      dispatch(
        updateAction({
          entryId: idTobeUpdated,
          description: data.title,
          updatedDuration: data.duration,
        })
      )
    }

    setIsModalOpen(false)
  }
  return (
    <KooperModal closeIcon size="tiny" centered={false} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <KooperModalHeader>{deleteModelHeader || 'Update Entries Task'}</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel" style={{overflow: 'hidden'}}>
          <KooperFormField height={4}>
            <label>Title</label>
            <Controller
              name="title"
              control={control2}
              render={({onChange, value}) => (
                <KooperFormInput
                  value={value}
                  placeholder="Enter Title.."
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors2.title && {
                      content: errors2.title.message,
                    }
                  }
                />
              )}
            />
          </KooperFormField>
          <div style={{display: 'flex', alignItems: 'center'}} className="pb-4">
            <KooperFormField widths={2}>
              <label>Duration</label>
              <Controller
                name="duration"
                control={control2}
                render={({onChange, value}) => (
                  <KooperFormInput
                    value={value}
                    placeholder="Duration"
                    onChange={e => onChange(e.target.value)}
                    error={
                      errors2.duration && {
                        content: errors2.duration.message,
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
          </div>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </KooperButton>
        <KooperButton
          primary
          loading={isLoadingData && reducerType.includes(type)}
          onClick={handleSubmit2(submitEntries)}
        >
          Update
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default UpdateModal
