import React, {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import moment from 'moment'
import {
  KooperButton,
  KooperDatePicker,
  KooperForm,
  KooperFormCheckbox,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperFormTextArea,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'

import {CREATE_PROJECTS_TIMESHEET_TIME_LOG} from 'actions/types'
import {
  createProjectsTimesheetTimeLog,
  getProjectsList,
  getProjectsTasksList,
  getProjectsTimeSheets,
} from 'actions/projects'
import {removeDoubleQuotes} from 'utils/helper'
import {timeTrackingSchema} from 'validation/Projects/projects/time.schema'
import SvgIcon from 'components/common/SvgIcon'

const statusList = [
  {key: 'billed', value: 'billed', text: 'Billed'},
  {key: 'billable', value: 'billable', text: 'Billable'},
  {key: 'non-billable', value: 'non-billable', text: 'Non-billable'},
  {key: 'void', value: 'void', text: 'Void'},
  {key: 'none', value: 'none', text: 'None'},
]

const timeTrackingDefaultValues = {
  projectId: null,
  timesheetId: null,
  date: moment(new Date()).format('YYYY-MM-DD'),
  status: 'none',
  loggedHrs: null,
  loggedMins: null,
  description: '',
  isAddToTask: false,
  tasklistId: null,
  taskId: null,
}

const TimeTrackingModal = ({open, toggle}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsList = [], timesheetList = [], tasksList = []} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, watch, setValue} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: timeTrackingDefaultValues,
    resolver: joiResolver(timeTrackingSchema),
  })
  const watchProjectId = watch('projectId')
  const watchTasklistId = watch('tasklistId')

  useEffect(() => {
    dispatch(getProjectsList())
  }, [dispatch])

  useEffect(() => {
    if (watchProjectId) {
      dispatch(getProjectsTimeSheets(watchProjectId))
      dispatch(getProjectsTasksList(watchProjectId))
    }
  }, [watchProjectId, dispatch, setValue])

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECTS_TIMESHEET_TIME_LOG)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const getTaskOptions = useCallback(() => {
    if (watchTasklistId) {
      const tasks = tasksList.find(tasklist => tasklist.id === watchTasklistId)?.tasks
      if (tasks) {
        return tasks.map(({id, title}) => ({key: id, value: id, text: title}))
      }
      return []
    }
    return []
  }, [tasksList, watchTasklistId])

  const submitForm = data => {
    const timeLogData = {...data, date: moment(data.date).format('YYYY-MM-DD')}
    const {projectId, ...payload} = timeLogData
    dispatch(createProjectsTimesheetTimeLog({projectId, data: payload}))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Add Time Tracking</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Project</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="select a project from list for time tracking"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="projectId"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  options={projectsList.map(({id, title}) => ({
                    key: id,
                    value: id,
                    text: title,
                  }))}
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                    setValue('timesheetId', null)
                    setValue('tasklistId', null)
                    setValue('taskId', null)
                  }}
                  error={
                    errors.projectId && {
                      content: removeDoubleQuotes(errors.projectId.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Timesheet</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Select a timesheet to maintain tracking records"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="timesheetId"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  options={timesheetList.map(({id, title}) => ({
                    key: id,
                    value: id,
                    text: title,
                  }))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.timesheetId && {
                      content: removeDoubleQuotes(errors.timesheetId.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
          <KooperFormGroup>
            <KooperFormField required width={8}>
              <div className="info-header">
                <label className="label-class">Date</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Mention time tracking starting date"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                name="date"
                control={control}
                render={({value, onChange}) => (
                  <KooperDatePicker
                    time={false}
                    value={value ? new Date(value) : value}
                    onChange={value => onChange(value)}
                    inputProps={{
                      component: props => <input {...props} readOnly />,
                    }}
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField required width={8}>
              <div className="info-header">
                <label className="label-class">Status</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Select a status for time sheet"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                name="status"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormSelect
                    value={value}
                    options={statusList}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.status && {content: removeDoubleQuotes(errors.status.message)}}
                  />
                )}
              />
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormGroup>
            <KooperFormField required width={8}>
              <div className="info-header">
                <label className="label-class">Hours</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Set hours of time tracking"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                name="loggedHrs"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormInput
                    type="number"
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.loggedHrs && {content: removeDoubleQuotes(errors.loggedHrs.message)}}
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField required width={8}>
              <div className="info-header">
                <label className="label-class">Minutes</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Set Minutes of time tracking"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                name="loggedMins"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormInput
                    type="number"
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.loggedMins && {content: removeDoubleQuotes(errors.loggedMins.message)}}
                  />
                )}
              />
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Description</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify a brief description"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="description"
              control={control}
              render={({value, onChange}) => (
                <KooperFormTextArea
                  maxLength={200}
                  style={{resize: 'none'}}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.description && {content: removeDoubleQuotes(errors.description.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField>
            <div className="info-header">
              <Controller
                name="isAddToTask"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormCheckbox
                    label="Add time details to the task"
                    checked={value}
                    onChange={(e, {checked}) => onChange(checked)}
                  />
                )}
              />
              <KooperTooltip
                trigger={<SvgIcon path="common/question" className="mb-4" />}
                content="Link time tracking with selected task"
                size="mini"
                psoition="top center"
              />
            </div>
          </KooperFormField>
          {watch('isAddToTask') && (
            <>
              <KooperFormField required={watch('isAddToTask')}>
                <label>Tasklist</label>
                <Controller
                  name="tasklistId"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      placeholder="Select"
                      options={tasksList.map(({id, title}) => ({key: id, value: id, text: title}))}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                        setValue('taskId', null)
                      }}
                      error={errors.tasklistId && {content: removeDoubleQuotes(errors.tasklistId.message)}}
                    />
                  )}
                />
              </KooperFormField>

              {watch('tasklistId') && (
                <KooperFormField required>
                  <label>Task</label>
                  <Controller
                    name="taskId"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        placeholder="Select"
                        options={getTaskOptions()}
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={errors.taskId && {content: removeDoubleQuotes(errors.taskId.message)}}
                      />
                    )}
                  />
                </KooperFormField>
              )}
            </>
          )}
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Add
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default TimeTrackingModal
