import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import moment from 'moment'
import {
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperDatePicker,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormRadio,
  KooperFormSelect,
  KooperTooltip,
} from 'kooper-library'

import {getUsers} from 'actions/settings'
import {GET_USERS} from 'actions/types'
import {removeDoubleQuotes} from 'utils/helper'
import TimeZoneDropdown from 'components/common/TimeZoneDropdown'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const Configuration = props => {
  const {style, control, errors, clearErrors, watch} = props

  const [assigneeFilter, setAssigneeFilter] = useState([])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setAssigneeFilter(list || [])
    },
  })

  return (
    <KooperContainer style={style} className="smallCont stepWrap">
      <KooperCard fluid>
        <KooperCardContent>
          <h3 className="mb-0">Configuration</h3>
          <p className="mb-4">Configure your email type campaign as per your requirement</p>
          <KooperForm className="errorLabel">
            <KooperFormField required width={10}>
              <label>Name</label>
              <Controller
                name="name"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormInput
                    placeholder="Name"
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={
                      errors.name && {
                        content: removeDoubleQuotes(errors.name.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>

            <KooperFormField required width={10}>
              <div className="info-header">
                <label className="label-class">Choose a team member</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                  content="Assign a team member for this campaign"
                />
              </div>
              <Controller
                name="userId"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormSelect
                    search
                    placeholder="Select assignee"
                    options={assigneeFilter}
                    value={value}
                    onClick={() => errors.userId && clearErrors('userId')}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.userId && {content: errors.userId.message}}
                  />
                )}
              />
            </KooperFormField>

            <h5>When to show message</h5>
            <Controller
              name="scheduleWhen"
              control={control}
              render={({value, onChange}) => (
                <>
                  <KooperFormRadio
                    className="m-0"
                    label="Schedule Now"
                    onChange={() => onChange('now')}
                    checked={value === 'now'}
                  />
                  <KooperFormRadio label="Schedule Later" onChange={() => onChange('later')} checked={value === 'later'} />
                </>
              )}
            />

            <KooperFormGroup>
              <KooperFormField width={7}>
                <label>Date & Time</label>
                <Controller
                  control={control}
                  name="scheduleTime"
                  render={({value, onChange}) => (
                    <KooperDatePicker
                      disabled={watch('scheduleWhen') === 'now'}
                      onChange={value => onChange(value)}
                      value={moment()
                        .set({
                          h: moment(value).format('HH'),
                          m: 0,
                        })
                        .toDate()}
                      
                      dropUp
                      step={60}
                      inputProps={{
                        component: props => <input {...props} readOnly />,
                      }}
                    />
                  )}
                />
              </KooperFormField>

              <KooperFormField required width={9}>
                <label>TimeZone</label>
                <Controller
                  control={control}
                  name="timezone"
                  render={({value, onChange}) => (
                    <TimeZoneDropdown
                      selection
                      realTime
                      format="Z (z) LT"
                      direction="right"
                      search
                      placeholder="Select timezone"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={errors.timezone}
                    />
                  )}
                />
                {errors.timezone && <p className="errorLabelElement">{removeDoubleQuotes(errors.timezone.message)}</p>}
              </KooperFormField>
            </KooperFormGroup>
          </KooperForm>
        </KooperCardContent>
      </KooperCard>
    </KooperContainer>
  )
}

export default Configuration
