import React, {useRef, useState} from 'react'
import {GET_API_TOKEN} from 'actions/types'
import {getApiToken} from 'actions/setting_api_and_integrations'
import SettingLayout from 'layouts/settingLayout'
import {copyToClipboard} from 'utils/helper'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import useApiResponse from 'hooks/impure/useApiResponse'
import {KooperTooltip, KooperForm, KooperFormField, KooperInput} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'

const DeveloperApi = () => {
  const valueRef = useRef(null)

  const [apiToken, setApiToken] = useState('')
  const [copyMsg, setCopyMsg] = useState('Copy')

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const developerApiLearnMore = settingsLearnMoreUrl?.apps?.developerApi?.learnMore

  useApiResponse({
    action: getApiToken,
    enabled: true,
    label: GET_API_TOKEN,
    storePath: 'settingApiAndIntegrations.apiTokenData',
    onSuccess: apiTokenData => {
      setApiToken(apiTokenData?.apiAccessKey)
    },
  })

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/developer-api" />}
      header="Developer API"
      subHeader="integrate kooper platform with external applications, systems, or services."
      learnMoreUrl={developerApiLearnMore}
      table={false}
      headerDivider
    >
      <KooperForm className="errorLabel">
        <KooperFormField width={10}>
          <div className="info-header">
            <label>API Key</label>
            <KooperTooltip
              content="Copy this API key for integration purposes"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <KooperInput
            placeholder="API Key"
            value={apiToken}
            ref={valueRef}
            action={{
              color: 'blue',
              labelPosition: 'right',
              icon: 'copy',
              content: copyMsg,
              circular: true,
              onClick: e => copyToClipboard(e, valueRef, setCopyMsg),
            }}
            actionPosition="right"
          />
        </KooperFormField>
      </KooperForm>
    </SettingLayout>
  )
}

export default DeveloperApi
