/* eslint-disable react/no-unknown-property */
import axios from 'axios'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {FiAlertTriangle, FiList} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperAttachment,
  KooperButton,
  KooperButtonGroup,
  KooperChipInput,
  KooperDatePicker,
  KooperDropdown,
  KooperDropdownDivider,
  KooperDropdownHeader,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperEditorSmall,
  KooperGridColumn,
  KooperInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
  kooperToast,
} from 'kooper-library'
import {extractTextContentFromHTML, get} from 'utils/helper'
import {
  getAllMailInbox,
  getUserTemplates,
  permanentDeleteConversation,
  sendEmail,
  singleUserTemplates,
} from 'actions/inbox'
import {getMailBox} from 'actions/index'
import {uploadAttachments} from 'actions/settings_productivity'
import {
  ATTACHMENT_POST,
  GET_ALL_MAIL_INBOX,
  GET_MAIL_BOX,
  GET_SINGLE_TEMPLATE,
  GET_USER_TEMPLATES,
  SEND_MAIL,
} from 'actions/types'
import {KOOPER_ATTACHMENTS} from 'actions/endpoint'
import {getAppToken} from 'utils/local-storage'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useApiResponse from 'hooks/impure/useApiResponse'
import {INBOX_TYPES} from 'constants/enums'
import AttachmentPreview from './AttachmentPreview'
import PermissionIcon from './PermissionIcon'
import SvgIcon from './SvgIcon'

const {CancelToken} = axios
const requestToken = CancelToken.source()

export default function EmailEditorBox(props) {
  const {
    isOpenMailComposer,
    defaultFrom,
    defaultTo,
    defaultCC,
    defaultBCC,
    defaultSubject,
    defaultEditorValue,
    isDraftConversation,
    ConversationId,
    setIsOpenMailComposer,
    selectedInbox,
    stopDraftOnClose,
  } = props

  const dispatch = useDispatch()
  const [templateList, setTemplateList] = useState([])
  const [emailEditorValue, setEmailEditorValue] = useState(defaultEditorValue || '')
  const [emailEditorCurrentValue, setEmailEditorCurrentValue] = useState('')
  const [files, setFiles] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])

  const [data, setData] = useState({
    sendLaterTime: new Date(),
    attachments: [],
    from: '',
    to: [],
    cc: [],
    bcc: [],
    sendTime: new Date(),
    subject: '',
  })

  const handleIsDraft = () => {
    if (
      _.isEqual(data.attachments, []) &&
      data.from === '' &&
      _.isEqual(data.to, []) &&
      _.isEqual(data.cc, []) &&
      _.isEqual(data.bcc, []) &&
      data.subject === '' &&
      emailEditorCurrentValue === ''
    ) {
      return true
    }
    return false
  }
  const handleSendMail = () => {
    if (data.from === '' || _.isEqual(data.to, [])) {
      return true
    }
    return false
  }

  const [toggleStates, setToggleStates] = useState({
    sendLater: false,
    isCCActive: false,
    isBCCActive: false,
    isSendLaterActive: false,
    moreCanOpen: false,
    timePicker: false,
  })
  const [listOfInbox, setListOfInbox] = useState([])
  const [timePickerDecider, setTimePickerDecider] = useState(null)

  const [defaultFontSize, setDefaultFontSize] = useState(16)
  const [defaultFontFamily, setDefaultFontFamily] = useState('Roboto')

  const {singleResponse} = useSelector(state => state.settings)
  const {inbox: mailboxes, getSingleInbox: inboxDetails = []} = useSelector(state => state.mailInbox)
  const {
    getUserTemplates: userTemplates = [],
    attachmentList = [],
    successData: attachmentSuccessData,
  } = useSelector(state => state.emailInbox)
  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  const {clientManagementAccess} = useUserPermissions()
  const scheduleConversationAccess = clientManagementAccess?.scheduleConversation

  const validateIsDraft = () => {
    if (!stopDraftOnClose || data.subject || data.from) return true
    return false
  }
  const sendMail = (draft, conversationId, isSendLaterActive, toastMsg) => {
    if (!emailEditorCurrentValue) {
      kooperToast.error(
        <div className="toast-body">
          <FiAlertTriangle />
          Please Write Content
        </div>
      )
      return
    }

    const messageBody = emailEditorCurrentValue

    const messageText = extractTextContentFromHTML(messageBody)

    const updatedSubject = data.subject === '' ? '(no subject)' : data.subject

    const mailList = data.to.map(mail => ({
      email: mail,
    }))

    const ccMail = data.cc.map(mail => ({
      email: mail,
    }))

    const bccMail = data.bcc.map(mail => ({
      email: mail,
    }))

    const payload = {
      inboxId: data.from,
      subject: updatedSubject,
      to: mailList,
      cc: ccMail,
      bcc: bccMail,
      isDraft: draft,
      messageHtml: messageBody,
      messageText,
      type: 'email',
    }

    if (conversationId) {
      payload.conversationId = conversationId
    }

    if (uploadedFiles.length > 0) {
      payload.attachments = uploadedFiles.map(file => file.id)
    }
    if (isSendLaterActive) {
      payload.sendLater = true
      payload.sendTime = data.sendLaterTime
    }

    dispatch(sendEmail(payload, toastMsg))
  }

  const onCloseCompose = () => {
    if (validateIsDraft()) {
      if (!data.to || !data.from || data.subject !== '' || data.emailEditorCurrentValue) {
        const draft = true

        if (isDraftConversation) {
          sendMail(draft, ConversationId, toggleStates.isSendLaterActive)
        } else {
          sendMail(draft, null, toggleStates.isSendLaterActive)
        }
      }
    }

    setIsOpenMailComposer(false)
  }

  const renderSetTime = () => {
    return (
      <KooperModal
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        size="tiny"
        open={toggleStates.timePicker}
        onClose={() =>
          setToggleStates({
            ...toggleStates,
            timePicker: false,
          })
        }
      >
        <KooperModalHeader>Send Later</KooperModalHeader>
        <KooperModalContent>
          <KooperGridColumn width={6}>
            <KooperDatePicker
              min={new Date()}
              onChange={value => {
                if (timePickerDecider === 'sendLater') {
                  const time = data.sendLaterTime.toTimeString()
                  const date = value.toDateString()
                  const fullDT = Date.parse(`${date} ${time}`)
                  setData({...data, sendLaterTime: new Date(fullDT)})
                }
              }}
              time={false}
            />
          </KooperGridColumn>
          <KooperGridColumn width={6}>
            <KooperDatePicker
              min={new Date()}
              onChange={value => {
                if (timePickerDecider === 'sendLater') {
                  const time = value.toTimeString()
                  const date = data.sendLaterTime.toDateString()
                  const fullDT = Date.parse(`${date} ${time}`)
                  setData({...data, sendLaterTime: new Date(fullDT)})
                }
              }}
              date={false}
            />
          </KooperGridColumn>
        </KooperModalContent>
        <KooperModalActions>
          <KooperButton
            onClick={() =>
              setToggleStates({
                ...toggleStates,
                timePicker: false,
              })
            }
          >
            Cancel
          </KooperButton>
          <KooperButton
            primary
            loading={isLoadingData && type.includes(SEND_MAIL)}
            disabled={isLoadingData && type.includes(SEND_MAIL)}
            onClick={() => {
              if (timePickerDecider === 'sendLater') {
                setToggleStates({
                  ...toggleStates,
                  isSendLaterActive: true,
                })
                const draft = false
                const id = null
                const isSendLaterActive = true

                sendMail(draft, id, isSendLaterActive)
              }
              setToggleStates({...toggleStates, timePicker: false})
            }}
          >
            Send Later
          </KooperButton>
        </KooperModalActions>
      </KooperModal>
    )
  }

  const insertTemplateIntoEditor = (oldEditorState, setOldEditorState, newHtmlBody, placeholderValues) => {
    let newString = newHtmlBody?.concat(oldEditorState)

    const keysArray = Object.keys(placeholderValues)
    for (let i = 0; i < keysArray.length; i + 1) {
      newString = newString.split(`{{${keysArray[i]}}}`).join(placeholderValues[keysArray[i]])
    }
    setOldEditorState(newString)
  }

  const searchFromTemplateList = searchString => {
    const updatedList = templateList.filter(template => template.subject.includes(searchString))
    setTemplateList(updatedList)
    if (searchString === '') {
      setTemplateList(userTemplates)
    }
  }

  useApiResponse({
    action: getAllMailInbox,
    enabled: true,
    label: GET_ALL_MAIL_INBOX,
    storePath: 'mailInbox.inbox',
    onSuccess: (inbox = []) => {
      const mailBoxList = inbox
        ?.filter(ls => ls.type === INBOX_TYPES.EMAIL && ls.emailVerified)
        ?.map(mail => {
          return {
            value: mail.id,
            key: mail.id,
            content: (
              <div>
                {get(['name'], mail, '')}
                <small className="ml-2 kooper-lead">({get(['email'], mail, '')})</small>
              </div>
            ),
            text: `${get(['name'], mail, '')}`,
          }
        })

      setListOfInbox(mailBoxList || [])
    },
  })

  useEffect(() => {
    let newData = {...data}

    if (defaultFrom) {
      newData = {...newData, from: defaultFrom}
    }
    if (defaultTo) {
      newData = {...newData, to: defaultTo}
    }
    if (defaultCC) {
      newData = {...newData, cc: defaultCC}
    }
    if (defaultBCC) {
      newData = {...newData, bcc: defaultBCC}
    }
    if (defaultSubject) {
      newData = {...newData, subject: defaultSubject}
    }

    setData(newData)
  }, [
    JSON.stringify(defaultFrom),
    JSON.stringify(defaultTo),
    JSON.stringify(defaultCC),
    JSON.stringify(defaultBCC),
    JSON.stringify(defaultSubject),
  ])

  useEffect(() => {
    if (attachmentSuccessData === 'composeAttachment' && successLabels.includes(ATTACHMENT_POST)) {
      const newFiles = attachmentList.map(file => ({
        id: file.id,
        name: file.name,
        size: file.size,
      }))

      setData({
        ...data,
        attachments: [...data.attachments, ...newFiles],
      })
      dispatch({
        type: 'CLEAR_ATTACHMENT',
      })
    }
  }, [successLabels])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_TEMPLATE)) {
      insertTemplateIntoEditor(
        emailEditorCurrentValue,
        setEmailEditorValue,
        singleResponse.body,
        get(['placeholderValues'], singleResponse)
      )

      setData({
        ...data,
        attachments: [...data.attachments, ...get(['attachments'], singleResponse, [])],
      })
    }
  }, [successLabels])

  useEffect(() => {
    if (selectedInbox) {
      setData({
        // snoozeDatePickerTime: new Date(),
        sendLaterTime: new Date(),
        attachments: [],
        from: '',
        to: [],
        cc: [],
        bcc: [],
        sendTime: new Date(),
        // snoozeTime: new Date(),
        subject: '',
      })
    }
  }, [selectedInbox])

  useEffect(() => {
    if (successLabels.includes(SEND_MAIL)) {
      setIsOpenMailComposer(false)
    }

    if (successLabels.includes(GET_MAIL_BOX)) {
      if (inboxDetails.settings.signatureEnabled === true && inboxDetails.signature !== {}) {
        const newStr = `${emailEditorCurrentValue}<br/><br/>${inboxDetails.signature.body}`

        setEmailEditorValue(newStr)
      } else {
        setEmailEditorValue(emailEditorCurrentValue)
      }

      if (Object.keys(inboxDetails.settings).length) {
        if (inboxDetails.settings.fontSize !== null) {
          setDefaultFontSize(inboxDetails.settings.fontSize)
        }

        if (inboxDetails.settings.fontFamily !== null) {
          setDefaultFontFamily(inboxDetails.settings.fontFamily)
        }
      } else {
        setDefaultFontSize(16)
        setDefaultFontFamily('Impact')
      }
    }
  }, [successLabels])

  useEffect(() => {
    if (successLabels.includes(GET_USER_TEMPLATES)) {
      setTemplateList(userTemplates)
    }
  }, [successLabels])

  useEffect(() => {
    return () =>
      setData({
        // snoozeDatePickerTime: new Date(),
        sendLaterTime: new Date(),
        attachments: [],
        from: '',
        to: [],
        cc: [],
        bcc: [],
        sendTime: new Date(),
        // snoozeTime: new Date(),
        subject: '',
      })
  }, [])

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    if (files && files[0]) {
      const formData = new FormData()
      formData.append('files[0]', files[0])
      axios({
        method: 'post',
        url: `${KOOPER_ATTACHMENTS}/inserted-image`,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${getAppToken()}`},
      }).then(function (res) {
        const response = {
          result: [
            {
              url: res.data.payload,
            },
          ],
        }
        uploadHandler(response)
      })
    }
  }

  return (
    <KooperModal
      open={isOpenMailComposer}
      onClose={() => setIsOpenMailComposer(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
    >
      <KooperModalHeader>New Conversation</KooperModalHeader>
      <KooperModalContent className="composeEmail">
        <div className="emailInput">
          <ul>
            <li>
              <div className="emlEdrHdr">From :</div>
              <div className="mailActivity">
                <KooperDropdown
                  direction="right"
                  button
                  icon="chevron down"
                  placeholder="Select Inbox"
                  options={listOfInbox}
                  onChange={(e, value) => {
                    dispatch(getMailBox(value.value))

                    const newData = {...data, from: value.value}

                    setData(newData)
                  }}
                  value={data.from}
                />
              </div>
            </li>
            <li>
              <div className="emlEdrHdr">To :</div>
              <div>
                <KooperChipInput
                  placeholder="Enter Email..."
                  defaultValue={data.to}
                  onChange={value => setData({...data, to: value})}
                />
              </div>
              <div className="ccBcc">
                {!toggleStates.isCCActive && (
                  <KooperButton
                    onClick={() =>
                      setToggleStates({
                        ...toggleStates,
                        isCCActive: true,
                      })
                    }
                    color="light"
                  >
                    Cc
                  </KooperButton>
                )}
                {!toggleStates.isBCCActive && (
                  <KooperButton
                    onClick={() =>
                      setToggleStates({
                        ...toggleStates,
                        isBCCActive: true,
                      })
                    }
                    color="light"
                  >
                    Bcc
                  </KooperButton>
                )}
              </div>
            </li>
            {toggleStates.isCCActive && (
              <li>
                <div className="emlEdrHdr">cc :</div>
                <KooperChipInput defaultValue={data.cc} onChange={value => setData({...data, cc: value})} />
              </li>
            )}
            {toggleStates.isBCCActive && (
              <li>
                <div className="emlEdrHdr">bcc :</div>
                <KooperChipInput defaultValue={data.bcc} onChange={value => setData({...data, bcc: value})} />
              </li>
            )}
            <li>
              <div className="emlEdrHdr">Subject :</div>
              <KooperInput onChange={e => setData({...data, subject: e.target.value})} value={data.subject} />
            </li>
          </ul>
        </div>
        <KooperEditorSmall
          setContents={emailEditorValue}
          onChange={content => {
            setEmailEditorCurrentValue(content)
          }}
          setDefaultStyle={`font-size: ${defaultFontSize}px; font-family: ${defaultFontFamily};`}
          onImageUploadBefore={handleImageUploadBefore}
        />
        <AttachmentPreview
          files={files}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          success="composeAttachment"
          cancelToken={requestToken}
        />
      </KooperModalContent>
      <KooperModalActions className="replyMailFooterBtn">
        <KooperButtonGroup className="replyMailFooterBtnLeft">
          <KooperTooltip
            content="Add Attachment"
            size="mini"
            position="top center"
            trigger={
              <KooperAttachment
                content={<SvgIcon path="common/attachment" />}
                multiple
                onChange={e => {
                  const data = {
                    file: e,
                  }
                  if (e.length > 0) {
                    dispatch(uploadAttachments(data, 'composeAttachment', requestToken))
                  }
                }}
                onClick={e => {
                  e.target.value = null
                }}
                fileList={files => {
                  setFiles(files)
                }}
              />
            }
          />
          <KooperTooltip
            content="Delete"
            size="mini"
            position="top center"
            trigger={
              <KooperButton
                icon
                onClick={() => {
                  setIsOpenMailComposer(false)

                  if (isDraftConversation) {
                    const conversationIds = []
                    conversationIds.push(ConversationId)

                    const data = {
                      conversationIds,
                    }

                    dispatch(permanentDeleteConversation(data))
                  }
                }}
              >
                <SvgIcon path="common/delete" />
              </KooperButton>
            }
          />
          <KooperTooltip
            content="Email Templates"
            size="mini"
            position="top center"
            trigger={
              <KooperDropdown
                button
                direction="right"
                floating
                className="icon"
                onClick={() => dispatch(getUserTemplates())}
                closeOnChange={false}
                icon={<FiList />}
              >
                <KooperDropdownMenu>
                  <KooperDropdownHeader>Insert Email Templates</KooperDropdownHeader>
                  <KooperDropdownDivider />
                  <KooperInput
                    icon="search"
                    iconPosition="left"
                    type="search"
                    name="search"
                    id="search"
                    placeholder="Search email templates... "
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onChange={e => {
                      searchFromTemplateList(e.target.value)
                    }}
                  />
                  <KooperDropdownDivider />
                  {templateList && templateList[0] ? (
                    templateList.map(temp => (
                      <KooperDropdownItem
                        key={temp.id}
                        onClick={() => {
                          if (isDraftConversation) {
                            dispatch(
                              singleUserTemplates(get(['id'], temp, '-'), ConversationId || null, 'templateListCompose')
                            )
                          } else {
                            dispatch(singleUserTemplates(get(['id'], temp, '-'), null, 'templateListCompose'))
                          }
                        }}
                      >
                        {get(['subject'], temp, '-')}
                      </KooperDropdownItem>
                    ))
                  ) : (
                    <div align="center" className="pb-2">
                      No Data Available
                    </div>
                  )}
                </KooperDropdownMenu>
              </KooperDropdown>
            }
          />
        </KooperButtonGroup>

        <div>
          <KooperButton
            basic
            className="mr-3"
            disabled={handleIsDraft() || (isLoadingData && type.includes(SEND_MAIL))}
            // loading={isLoadingData && type.includes(SEND_MAIL)}
            onClick={() => {
              const draft = true

              if (isDraftConversation && ConversationId) {
                sendMail(
                  draft,
                  ConversationId,
                  toggleStates.isSendLaterActive,
                  'Saved as Draft'
                  // toggleStates.isSnoozeActive
                )
              } else {
                sendMail(
                  draft,
                  null,
                  toggleStates.isSendLaterActive,
                  'Saved as Draft'
                  // toggleStates.isSnoozeActive
                )
              }
            }}
          >
            Save as Draft
          </KooperButton>

          <KooperButtonGroup color="primary">
            <KooperButton
              primary
              loading={isLoadingData && type.includes(SEND_MAIL)}
              disabled={handleSendMail() || (isLoadingData && type.includes(SEND_MAIL))}
              onClick={() => {
                sendMail(false, ConversationId || null, toggleStates.isSendLaterActive)
              }}
            >
              Send
            </KooperButton>
            <KooperDropdown
              upward
              className="button icon"
              icon="chevron down"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              floating
              disabled={data.to.length === 0 || emailEditorCurrentValue === ''}
            >
              <KooperDropdownMenu>
                <KooperDropdownItem
                  onClick={() => {
                    if (scheduleConversationAccess) {
                      if (!emailEditorCurrentValue) {
                        kooperToast.error(
                          <div className="toast-body">
                            <FiAlertTriangle />
                            Please Write Content
                          </div>
                        )
                      } else {
                        setTimePickerDecider('sendLater')
                        setToggleStates({
                          ...toggleStates,
                          isSendLaterActive: true,
                          timePicker: true,
                        })
                      }
                    }
                  }}
                >
                  Send Later
                  {!scheduleConversationAccess && (
                    <PermissionIcon isFeatureAccessDenied={!scheduleConversationAccess} />
                  )}
                </KooperDropdownItem>
              </KooperDropdownMenu>
            </KooperDropdown>
          </KooperButtonGroup>
        </div>
      </KooperModalActions>

      {renderSetTime()}
    </KooperModal>
  )
}
