import SvgIcon from 'components/common/SvgIcon'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormTextArea,
  KooperHeader,
  KooperIcon,
  KooperInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperProgress,
  KooperSegment,
  KooperTooltip,
} from 'kooper-library'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'

const UpdateGoalsModal = ({isModalOpen, setIsModalOpen = () => null}) => {
  const [status, setStatus] = useState('on-track')
  const [progress, setProgress] = useState(20)
  const [keyResult1, setKeyResult1] = useState(10)
  const [keyResult2, setKeyResult2] = useState(52)
  const [addNote, setAddNote] = useState(false)
  return (
    <KooperModal
      size="medium"
      open={isModalOpen}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <KooperModalHeader>Update goal progress</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperSegment className="goal-progress-container">
          <KooperForm>
            <div className="form-section">
              <KooperHeader as="h4" className="section-header">
                Status
              </KooperHeader>
              <div className="d-flex jcsb">
                <div className="status-buttons">
                  <KooperButton circular color="blue" basic onClick={() => setStatus('on-track')} size="small">
                    {status === 'on-track' && <KooperIcon name="check" />}
                    On track
                  </KooperButton>
                  <KooperButton circular basic color="green" onClick={() => setStatus('completed')} size="small">
                    {status === 'completed' && <KooperIcon name="check" />}
                    Completed
                  </KooperButton>
                  <KooperButton circular basic color="orange" onClick={() => setStatus('delayed')} size="small">
                    {status === 'delayed' && <KooperIcon name="check" />}
                    Delayed
                  </KooperButton>
                  <KooperButton circular basic color="red" onClick={() => setStatus('at-risk')} size="small">
                    {status === 'at-risk' && <KooperIcon name="check" />}
                    At risk
                  </KooperButton>
                  <KooperButton circular basic color="grey" onClick={() => setStatus('abandoned')} size="small">
                    {status === 'abandoned' && <KooperIcon name="check" />}
                    Abandoned
                  </KooperButton>
                </div>
                {!addNote && (
                  <div>
                    <KooperButton basic onClick={() => setAddNote(true)} content="+ Add note" />
                  </div>
                )}
              </div>
              {addNote && (
                <KooperFormField className="mt-3">
                  <div className="info-header">
                    <label>Note</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Note"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  {/* <Controller
                name="note"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormTextArea maxLength={100} placeholder="Enter organizational goal description" />
                )}
              /> */}
                  <KooperFormTextArea onBlur={() => setAddNote(false)} maxLength={200} placeholder="Enter Note" />
                </KooperFormField>
              )}
            </div>

            <div className="form-section">
              <KooperHeader as="h4" className="section-header">
                Progress
              </KooperHeader>
              <div className="progress-input-container">
                <KooperInput
                  type="range"
                  value={progress}
                  total={100}
                  className="progress-bar"
                  size="tiny"
                  min={0}
                  max={100}
                  defaultValue="30"
                  onChange={e => setProgress(e.target.value)}
                />
                <KooperInput
                  type="number"
                  value={progress}
                  onChange={e => setProgress(Number(e.target.value))}
                  label={{basic: true, content: '%'}}
                  labelPosition="right"
                  className="progress-input"
                />
              </div>
            </div>

            <div className="form-section">
              <KooperHeader as="h4" className="section-header">
                Key results
              </KooperHeader>
              <div className="key-result-list">
                <div className="key-result-item">
                  <div className="d-flex" style={{flex: 1}}>
                    <KooperIcon name="flag" className="key-result-icon" />
                    <div className="key-result-title ml-2">multiple</div>
                  </div>
                  <div className="key-result-input-group">
                    <KooperInput
                      type="number"
                      value={keyResult1}
                      onChange={e => setKeyResult1(Number(e.target.value))}
                      label={{basic: true, content: '%'}}
                      labelPosition="right"
                      className="key-result-input"
                    />
                    <span className="separator">/</span>
                    <span className="result-percentage">52%</span>
                  </div>
                </div>
                <div className="key-result-item">
                  <div className="d-flex" style={{flex: 1}}>
                    <KooperIcon name="flag" className="key-result-icon" />
                    <div className="key-result-title ml-2">we will</div>
                  </div>
                  <div className="key-result-input-group">
                    <KooperInput
                      type="number"
                      value={keyResult2}
                      onChange={e => setKeyResult2(Number(e.target.value))}
                      label={{basic: true, content: '%'}}
                      labelPosition="right"
                      className="key-result-input"
                    />
                    <span className="separator">/</span>
                    <span className="result-percentage">52%</span>
                  </div>
                </div>
              </div>
            </div>
          </KooperForm>
        </KooperSegment>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </KooperButton>

        <KooperButton primary>Save progress</KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default UpdateGoalsModal
