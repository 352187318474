import Joi from 'joi'
import moment from 'moment'

export const timesheetSchema = Joi.object({
  title: Joi.string().trim().min(3).max(20).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 20 characters`,
  }),
  enableEstimation: Joi.boolean().required(),
  estimatedHrs: Joi.any().when('enableEstimation', {
    is: Joi.boolean().valid(true),
    then: Joi.number().max(24).integer().required().messages({
      'number.base': 'Hours must be a number',
      'number.integer': 'Estimated hrs must be an integer',
      'number.max': 'Hours must be less than or equal to 24',
    }),
  }),
  estimatedMins: Joi.any().when('enableEstimation', {
    is: Joi.boolean().valid(true),
    then: Joi.number().max(59).integer().required().messages({
      'number.base': 'Minutes must be a number',
      'number.integer': 'Estimated Mins must be an integer',
      'number.max': 'Mins must be less than or equal to 59',
    }),
  }),
  isPublic: Joi.boolean().required(),
  subscribers: Joi.array().when('isPublic', {
    is: Joi.boolean().valid(false),
    then: Joi.array().items(Joi.number()).min(1).required().messages({
      'array.min': 'Subscribers must be specified',
    }),
  }),
})

export const timeDefaultValues = {
  date: moment(new Date()).format('YYYY-MM-DD'),
  status: 'billable',
  loggedHrs: null,
  loggedMins: null,
  description: '',
  isAddToTask: false,
  tasklistId: null,
  taskId: null,
}

export const createTimeSchema = Joi.object({
  date: Joi.date().required(),
  status: Joi.string().required(),
  loggedHrs: Joi.number().max(24).integer().required().messages({
    'number.base': 'Hours must be number',
    'number.integer': 'Hours must be an integer',
    'number.max': 'Hours must be less than or equal to 24',
  }),
  loggedMins: Joi.number().max(59).integer().required().messages({
    'number.base': 'Minutes must be number',
    'number.integer': 'Minutes must be an integer',
    'number.max': 'Mins must be less than or equal to 59',
  }),
  description: Joi.string().trim().max(200).allow('', null).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
  isAddToTask: Joi.boolean().required(),
  tasklistId: Joi.any().when('isAddToTask', {
    is: Joi.boolean().valid(true),
    then: Joi.number().required().messages({
      'number.base': 'Tasklist must be specified',
    }),
  }),
  taskId: Joi.any().when('tasklistId', {
    is: Joi.number().required(),
    then: Joi.number().required().messages({
      'number.base': 'Task must be specified',
    }),
  }),
})

export const updateTimeSchema = Joi.object({
  date: Joi.date().required(),
  status: Joi.string().required(),
  loggedHrs: Joi.number().required().messages({
    'number.base': 'Hours must be number',
  }),
  loggedMins: Joi.number().required().messages({
    'number.base': 'Minutes must be number',
  }),
  description: Joi.string().trim().max(200).allow('', null).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
})

export const timeTrackingSchema = Joi.object({
  projectId: Joi.number().required().messages({
    'number.base': 'Project must be specified',
  }),
  timesheetId: Joi.number().required().messages({
    'number.base': 'Timesheet must be specified',
  }),
  date: Joi.date().required(),
  status: Joi.string().required(),
  loggedHrs: Joi.number().required().messages({
    'number.base': 'Hours must be number',
  }),
  loggedMins: Joi.number().required().messages({
    'number.base': 'Minutes must be number',
  }),
  description: Joi.string().trim().max(200).allow('', null).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
  isAddToTask: Joi.boolean().required(),
  tasklistId: Joi.any().when('isAddToTask', {
    is: Joi.boolean().valid(true),
    then: Joi.number().required().messages({
      'number.base': 'Tasklist must be specified',
    }),
  }),
  taskId: Joi.any().when('tasklistId', {
    is: Joi.number().required(),
    then: Joi.number().required().messages({
      'number.base': 'Task must be specified',
    }),
  }),
})
