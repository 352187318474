import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {KooperCard, KooperCardContent, KooperCardHeader, KooperGrid, KooperGridColumn, KooperGridRow} from 'kooper-library'
import {sequnceOverview} from 'actions/sequence'
import {startCase, wrapText} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const SequenceOverview = ({id}) => {
  const dispatch = useDispatch()
  const {sequenceOverviewData: {steps = 0, days = 0, automated = 0, stepsStatus = []} = {}} = useSelector(
    state => state.sequences
  )

  const colors = {
    manual_email: '#2196F3',
    auto_email: '#4481bd',
    auto_sms: '#8BC34A',
    task_activity: '#ff9800',
    task_call: '#e03997',
    task_meeting: '#767676',
  }

  const getSubject = (type, data) => {
    if (type === 'task_call' || type === 'task_meeting' || type === 'task_activity') {
      return wrapText(data.subject, 50)
    }
    if (type === 'manual_email' || type === 'auto_email') {
      return wrapText(data.subject, 50)
    }
    if (type === 'auto_sms') {
      return wrapText(data.messageText, 50)
    }
  }

  const getIcon = type => {
    if (type === 'auto_email' || type === 'manual_email' || type === 'auto_sms') {
      return <SvgIcon path="common/mail" />
    }
    if (type === 'task_call') {
      return <SvgIcon path="settings/phone" />
    }
    if (type === 'task_activity' || type === 'task_meeting') {
      return <SvgIcon path="settings/users" />
    }
  }

  useEffect(() => {
    if (id) {
      dispatch(sequnceOverview(id))
    }
  }, [])

  return (
    <>
      <KooperCard fluid>
        <KooperCardContent>
          <KooperGrid columns={3} divided>
            <KooperGridRow>
              <KooperGridColumn>
                <div className="valueBox">
                  <KooperCardHeader>Steps</KooperCardHeader>
                  <h3> {steps}</h3>
                </div>
              </KooperGridColumn>
              <KooperGridColumn>
                <div className="valueBox">
                  <KooperCardHeader>Days</KooperCardHeader>
                  <h3> {days}</h3>
                </div>
              </KooperGridColumn>
              <KooperGridColumn>
                <div className="valueBox">
                  <KooperCardHeader>Automated</KooperCardHeader>
                  <h3> {automated}</h3>
                </div>
              </KooperGridColumn>
            </KooperGridRow>
          </KooperGrid>
        </KooperCardContent>
      </KooperCard>
      {stepsStatus.map((step, index) => (
        <>
          <div className="seqBox" style={{borderColor: colors[step.type], marginTop: '24px'}}>
            <div className="seqHeader" style={{background: colors[step.type]}}>
              <div className="info">
                {getIcon(step.type)} Step {index + 1} : {startCase(step.type)}
              </div>
            </div>
            <div className="p-3">
              <div className="timeBar pb-3">
                <div className="waitFor">
                  <p>Execution Time: {step.sendAt}</p>
                </div>
              </div>
              <div className="seqBody p-0">
                <p>Subject: {getSubject(step.type, step.data)}</p>
                <p>Active: {step.active}</p>
                {(step.type === 'manual_email' || step.type === 'auto_email') && <p>Completed: {step.completed}</p>}
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  )
}

export default SequenceOverview
