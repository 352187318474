import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {
  KooperHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
} from 'kooper-library'
import {getProjectsTemplatesFinanceRatesList} from 'actions/projects'
import {GET_PROJECTS_TEMPLATES_FINANCE_RATES_LIST, UPDATE_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE} from 'actions/types'
import {getFormattedDate} from 'utils/helper'
import useApiResponse from 'hooks/impure/useApiResponse'
import TemplateFinanceRateModal from './TemplateFinanceRateModal'

const TemplateFinanceRates = ({templateId, budgetId}) => {
  const [isProjectRateModalOpen, setIsProjectRateModalOpen] = useState(false)
  const [idToUpdateRate, setIdToUpdateRate] = useState(null)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {
    data: ratesList = [],
    isLoading,
    callAction,
  } = useApiResponse({
    action: getProjectsTemplatesFinanceRatesList,
    payload: {templateId, budgetId},
    dependency: [templateId, budgetId],
    enabled: templateId && budgetId,
    label: GET_PROJECTS_TEMPLATES_FINANCE_RATES_LIST,
    storePath: 'projects.projectsTemplatesFinanceRatesList',
  })

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE)) {
      setIsProjectRateModalOpen(false)
      callAction()
    }
  }, [successLabels, callAction])

  const renderTableBody = () => {
    if (!isLoading && ratesList.length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="4" className="emptyValue">
            No Data Available
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return ratesList.map(rate => (
      <KooperTableRow
        className="tableLink"
        key={rate.id}
        onClick={() => {
          setIdToUpdateRate(rate.id)
          setIsProjectRateModalOpen(true)
        }}
      >
        <KooperTableCell>
          <KooperHeader as="h5">{rate.user?.name}</KooperHeader>
        </KooperTableCell>
        <KooperTableCell>{rate.billableRate ? `$${rate.billableRate}` : '-'}</KooperTableCell>
        <KooperTableCell>{rate.costRate ? `$${rate.costRate}` : '-'}</KooperTableCell>
        <KooperTableCell>
          <div>{rate.updatedBy?.name}</div>
          <div>{rate.updatedAt ? getFormattedDate(rate.updatedAt) : '-'}</div>
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <div className="kooper-side-wrap">
      <KooperTable basic>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>Name</KooperTableHeaderCell>
            <KooperTableHeaderCell>Billable Rate/H</KooperTableHeaderCell>
            <KooperTableHeaderCell>Cost Rate/H</KooperTableHeaderCell>
            <KooperTableHeaderCell>Last Updated</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>{renderTableBody()}</KooperTableBody>
      </KooperTable>

      {isProjectRateModalOpen && (
        <TemplateFinanceRateModal
          open={isProjectRateModalOpen}
          toggle={setIsProjectRateModalOpen}
          templateId={templateId}
          budgetId={budgetId}
          userId={idToUpdateRate}
        />
      )}
    </div>
  )
}

export default TemplateFinanceRates
