import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {KooperButton, KooperModal, KooperModalActions, KooperModalContent, KooperModalHeader} from 'kooper-library'

import {STATUS_PROJECTS_TIMESHEET} from 'actions/types'
import {statusProjectsTimesheet} from 'actions/projects'
import SvgIcon from 'components/common/SvgIcon'

const StatusTimesheetModal = ({open, toggle, projectId, timesheetId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(STATUS_PROJECTS_TIMESHEET)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = () => {
    const data = {status: 'archived'}
    dispatch(statusProjectsTimesheet({projectId, timesheetId, data}))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Confirm</KooperModalHeader>
      <KooperModalContent>Are you sure you want to archive the timesheet?</KooperModalContent>
      <KooperModalContent>
        Note: You can always access the archived timesheets using filters and also unarchive them.
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={submitForm}>
          Yes
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default StatusTimesheetModal
