import React from 'react'
import {Controller} from 'react-hook-form'
import {removeDoubleQuotes} from 'utils/helper'
import {
  KooperCheckbox,
  KooperDatePicker,
  KooperFormField,
  KooperFormInput,
  KooperFormRadio,
  KooperFormSelect,
  KooperFormTextArea,
} from 'kooper-library'

const FormQuestions = ({type, question, isRequired, settings, description, control, errors, index}) => {
  switch (type) {
    case 'page':
      return (
        <KooperFormField>
          <div>
            <h3>{question}</h3>
          </div>
        </KooperFormField>
      )
    case 'header':
      return (
        <KooperFormField>
          <div>
            <h3>{question}</h3>
          </div>
        </KooperFormField>
      )
    case 'text':
      return (
        <KooperFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div style={{color: 'black'}}>
            <p>{description}</p>
          </div>
          <Controller
            name={`answers.${index}.answer[0]`}
            defaultValue=""
            render={({value, onChange, onBlur}) => (
              <KooperFormInput
                type="text"
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                }}
                onBlur={onBlur}
                error={
                  errors.answers?.[index]?.answer?.[0] && {
                    content: removeDoubleQuotes(errors.answers?.[index]?.answer?.[0]?.message),
                  }
                }
              />
            )}
            control={control}
          />
        </KooperFormField>
      )
    case 'dropdown':
      return (
        <KooperFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <Controller
            name={`answers.${index}.answer[0]`}
            defaultValue=""
            render={({value, onChange}) => (
              <KooperFormSelect
                options={settings?.options?.map(({choice}) => ({key: choice, value: choice, text: choice}))}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                error={
                  errors.answers?.[index]?.answer?.[0] && {
                    content: removeDoubleQuotes(errors.answers?.[index]?.answer?.[0]?.message),
                  }
                }
              />
            )}
            control={control}
          />
        </KooperFormField>
      )
    case 'number':
      return (
        <KooperFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <Controller
            name={`answers.${index}.answer[0]`}
            defaultValue=""
            render={({value, onChange, onBlur}) => (
              <KooperFormInput
                type="text"
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                }}
                onBlur={onBlur}
                error={
                  errors.answers?.[index]?.answer?.[0] && {
                    content: removeDoubleQuotes(errors.answers?.[index]?.answer?.[0]?.message),
                  }
                }
              />
            )}
            control={control}
          />
        </KooperFormField>
      )
    case 'date':
      return (
        <KooperFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <Controller
            name={`answers.${index}?.answer[0]`}
            defaultValue=""
            control={control}
            render={({value, onChange}) => (
              <KooperDatePicker
                time={false}
                value={value ? new Date(value) : value}
                onChange={value => {
                  onChange(value)
                }}
                defaultValue={typeof value === 'string' ? new Date(value) : value}
                inputProps={{
                  component: props => <input {...props} readOnly />,
                }}
              />
            )}
          />
          {errors.answers?.[index]?.answer?.[0] && (
            <p className="errorLabelElement">{removeDoubleQuotes(errors.answers?.[index]?.answer?.[0])}</p>
          )}
        </KooperFormField>
      )
    case 'checkbox':
      return (
        <KooperFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <Controller
            name={`answers.${index}.answer[0]`}
            defaultValue=""
            control={control}
            render={({onChange, value}) =>
              settings?.options?.map(({choice}) => (
                <KooperCheckbox
                  className="mr-2"
                  value={choice}
                  label={choice}
                  onChange={(e, {value}) => onChange(value)}
                />
              ))
            }
          />
        </KooperFormField>
      )
    case 'radio':
      return (
        <KooperFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <Controller
            name={`answers.${index}.answer[0]`}
            control={control}
            defaultValue=""
            render={({value, onChange}) =>
              settings?.options?.map(({choice}) => (
                <KooperFormRadio
                  className="mb-2"
                  value={choice}
                  checked={value === choice}
                  onChange={(e, {value}) => onChange(value)}
                  label={choice}
                  error={
                    errors.answers?.[index]?.answer?.[0] && {
                      content: removeDoubleQuotes(errors.answers?.[index]?.answer?.[0]?.message),
                    }
                  }
                />
              ))
            }
          />
        </KooperFormField>
      )
    case 'textarea':
      return (
        <KooperFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <Controller
            name={`answers.${index}.answer[0]`}
            control={control}
            defaultValue=""
            render={({onChange, value}) => (
              <KooperFormTextArea
                name={`answers.${index}.answer`}
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                }}
                error={
                  errors.answers?.[index]?.answer?.[0] && {
                    content: removeDoubleQuotes(errors.answers?.[index]?.answer?.[0]?.message),
                  }
                }
              />
            )}
          />
        </KooperFormField>
      )

    default:
      return null
  }
}

export default FormQuestions
