/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {KooperButton, KooperModal, KooperModalActions, KooperModalContent, KooperModalHeader} from 'kooper-library'
import SvgIcon from './SvgIcon'

const DeleteModal = props => {
  const {isLoadingData, type: reducerType, successLabels = []} = useSelector(state => state.apiReducer)
  const {
    idTobeDeleted,
    deleteAction,
    isModalOpen,
    setIsModalOpen,
    modalText,
    onDelete,
    deleteModelHeader,
    type,
    deleteButtonText,
  } = props

  const dispatch = useDispatch()

  useEffect(() => {
    if (successLabels.includes(type)) {
      setIsModalOpen(false)
    }
  }, [successLabels])

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      centered={false}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <KooperModalHeader>{deleteModelHeader || 'Delete'}</KooperModalHeader>
      <KooperModalContent>{modalText || 'Are you sure you want to delete ?'}</KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </KooperButton>
        <KooperButton
          primary
          loading={isLoadingData && reducerType.includes(type)}
          onClick={() => {
            if (onDelete) {
              onDelete()
              setIsModalOpen(false)
            } else {
              dispatch(deleteAction(idTobeDeleted))
              setIsModalOpen(false)
            }
          }}
        >
          {deleteButtonText || 'Delete'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default DeleteModal
