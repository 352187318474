import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiList} from 'react-icons/fi'
import {
  KooperButton,
  KooperHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTabPane,
  KooperTooltip,
} from 'kooper-library'
import {DELETE_PURCHASES_CATEGORY, EDIT_PURCHASES_CATEGORY, GET_PURCHASES_CATEGORY} from 'actions/types'
import {deletePurchasesCategory, getPurchasesCategory} from 'actions/purchases'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import SettingLayout from 'layouts/settingLayout'
import create from 'assets/create.svg'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {roleAccessDeniedContent} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import PurchasesCategoryModal from './CategoryModal'

const Category = () => {
  const dispatch = useDispatch()
  const [deleteModal, setDeleteModal] = useState(false)
  const [categoryModal, setCategoryModal] = useState(false)
  const [categoryId, setCategoryId] = useState()

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {financePermissions} = useUserPermissions()
  const managePurchaseSettingsPermissions = financePermissions?.manage_purchase_settings

  const {isLoading} = useApiLoader({label: GET_PURCHASES_CATEGORY})

  const {data: getPurchasesCategoryData = {}} = useApiResponse({
    action: getPurchasesCategory,
    label: GET_PURCHASES_CATEGORY,
    enabled: true,
    storePath: 'purchases.getPurchasesCategoryData',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_PURCHASES_CATEGORY) || successLabels.includes(EDIT_PURCHASES_CATEGORY)) {
      dispatch(getPurchasesCategory())
    }
  }, [successLabels, dispatch])

  const categoryProps = {
    open: categoryModal,
    toggleModal: setCategoryModal,
    categoryToggle: setCategoryId,
  }
  if (categoryId) {
    categoryProps.editId = categoryId
  }

  const headerButton = {
    content: 'Create Category',
    onClick: () => {
      if (managePurchaseSettingsPermissions) {
        setCategoryId()
        setCategoryModal(true)
      }
    },
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <SettingLayout
      header="Category Fields"
      subHeader="Manage your category fields to organise your purchases."
      table
      headerButton={headerButton}
      lockRole={!managePurchaseSettingsPermissions}
    >
      {getPurchasesCategoryData && !getPurchasesCategoryData.length ? (
        <div className="mt-4">
          {categoryModal ? <PurchasesCategoryModal {...categoryProps} /> : null}
          <CreateFirst
            src={<FiList />}
            header="Category"
            subHeader="Diversifying assets helps reduce the chance of loss of capital. Investment in debt instruments assures fixed return and safety of capital, having this as part of your portfolio ensures capital preservation."
            addFirst={() => {
              if (managePurchaseSettingsPermissions) {
                setCategoryId()
                setCategoryModal(true)
              }
            }}
            tooltip={!managePurchaseSettingsPermissions}
            tooltipContent={getPermissionTooltipContent({
              roleAccess: managePurchaseSettingsPermissions,
            })}
            buttonText="Add Category"
            Feature1="Organize your assets better"
            Feature2="Keep track and reporting according to the category"
            Feature3="Assets prevention is better"
            list1="Sorting assets according to their properties help organize it better and helps in tracking if number of assets are more"
            list2="Kooper support features detailed analytics with respect to the properties of the assets so it helps the company in making decision based on statistics"
            list3="Managing assets in kooper support will help in case of asset theft or missing asset situations and hence is more reliable"
            imageSrc={create}
          />
        </div>
      ) : (
        <KooperTabPane attached={false}>
          {deleteModal && (
            <DeleteModal
              idTobeDeleted={categoryId}
              deleteAction={deletePurchasesCategory}
              isModalOpen={deleteModal}
              setIsModalOpen={setDeleteModal}
              modalText="Are you sure you want to delete this Category?"
              type={DELETE_PURCHASES_CATEGORY}
            />
          )}
          {categoryModal ? <PurchasesCategoryModal {...categoryProps} /> : null}
          <KooperTable basic selectable>
            <KooperTableHeader>
              <KooperTableRow>
                <KooperTableHeaderCell className="pl-4">#</KooperTableHeaderCell>
                <KooperTableHeaderCell>Name</KooperTableHeaderCell>
                <KooperTableHeaderCell>Action</KooperTableHeaderCell>
              </KooperTableRow>
            </KooperTableHeader>
            <KooperTableBody>
              {isLoadingData && type.includes(GET_PURCHASES_CATEGORY) ? (
                <KooperTablePlaceholder columns={3} rows={3} />
              ) : (
                getPurchasesCategoryData &&
                getPurchasesCategoryData.map(({category, id}, index) => (
                  <KooperTableRow
                    className="tableLink"
                    onClick={() => {
                      setCategoryModal(true)
                      setCategoryId(id)
                    }}
                    key={id}
                  >
                    <KooperTableCell className="pl-4">{index + 1}</KooperTableCell>
                    <KooperTableCell>
                      <KooperHeader as="h5">{startCase(category)}</KooperHeader>
                    </KooperTableCell>
                    <KooperTableCell>
                      <KooperTooltip
                        content={!managePurchaseSettingsPermissions ? roleAccessDeniedContent : 'Delete'}
                        size="mini"
                        position="top center"
                        trigger={
                          <KooperButton
                            icon
                            onClick={e => {
                              if (managePurchaseSettingsPermissions) {
                                setCategoryId(id)
                                setDeleteModal(true)
                              }
                              e.stopPropagation()
                            }}
                          >
                            <SvgIcon path="common/delete" />
                          </KooperButton>
                        }
                      />
                    </KooperTableCell>
                  </KooperTableRow>
                ))
              )}
            </KooperTableBody>
          </KooperTable>
        </KooperTabPane>
      )}
    </SettingLayout>
  )
}

export default Category
