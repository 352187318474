import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import MyProfile from 'components/pages/Settings/MyProfile'
import UnSavedWrap from 'components/hoc/UnSavedWrap'
import Users from 'components/pages/Settings/MyOrganisation/Users/Users'
import UsersDetails from 'components/pages/Settings/MyOrganisation/Users/UsersDetails'
import {ChatMessenger} from 'components/pages/Settings/Channels/ChatMessenger'
import DocumentTitle from 'components/common/DocumentTitle'
import {ChatPages} from 'components/pages/Settings/Channels/ChatPages'
import {InboxTab} from 'components/pages/Settings/Channels/EmailInbox/InboxTab'
import {InboxEdit} from 'components/pages/Settings/Channels/EmailInbox/InboxEdit'
import Sms from 'components/pages/Settings/Channels/SMS/Sms'
import SmsDetail from 'components/pages/Settings/Channels/SMS/SmsDetail'
// import Twitter from 'components/pages/Settings/Channels/Twitter/Twitter'
// import TwitterDetails from 'components/pages/Settings/Channels/Twitter/TwitterDetails'
import Facebook from 'components/pages/Settings/Channels/Facebook/Facebook'
import FacebookDetails from 'components/pages/Settings/Channels/Facebook/FacebookDetails'
import FeedbackList from 'components/pages/Settings/Channels/Feedback/FeedbackList'
import Feedback from 'components/pages/Settings/Channels/Feedback/Feedback'
import Appearance from 'components/pages/Settings/Messenger/Appearance'
import Configure from 'components/pages/Settings/Messenger/Configure'
import Advanced from 'components/pages/Settings/Messenger/Advanced'
import OfflineExperience from 'components/pages/Settings/Messenger/OfflineExperience'
import Routing from 'components/pages/Settings/Automations/Routing/Routing'
import RoutingForm from 'components/pages/Settings/Automations/Routing/RoutingForm'
import BusinessHours from 'components/pages/Settings/Automations/BusinessHours/BusinessHours'
import BusinessHoursForm from 'components/pages/Settings/Automations/BusinessHours/BusinessHoursForm'
import SLA from 'components/pages/Settings/Automations/sla/Sla'
import SlaForm from 'components/pages/Settings/Automations/sla/SlaForm'
import ContactType from 'components/pages/Settings/DataManagement/Customization/ContactTypes'
import Tags from 'components/pages/Settings/Productivity/Tags'
import Currencies from 'components/pages/Settings/Sales/Currencies/Currencies'
import CurrenciesDetails from 'components/pages/Settings/Sales/Currencies/CurrenciesDetails'
import Deals from 'components/pages/Settings/DataManagement/Customization/Deals/Deals'
import LossReason from 'components/pages/Settings/DataManagement/Customization/LossReason'
import Template from 'components/pages/Settings/Productivity/TaskTemplates/Template'
import TemplateDetails from 'components/pages/Settings/Productivity/TaskTemplates/TemplateDetails'
import Source from 'components/pages/Settings/DataManagement/Customization/Sources'
import Goals from 'components/pages/Settings/Sales/Forecasting'
import EmailSurvey from 'components/pages/Settings/CustomerSatisfaction/EmailSurvey/EmailSurvey'
import EmailSurveyForm from 'components/pages/Settings/CustomerSatisfaction/EmailSurvey/EmailSurveyForm'
import ChatSurvey from 'components/pages/Settings/CustomerSatisfaction/ChatSurvey/ChatSurvey'
import ChatSurveyForm from 'components/pages/Settings/CustomerSatisfaction/ChatSurvey/ChatSurveyForm'
import AuditLogs from 'components/pages/Settings/Productivity/AuditLogs'
import Teams from 'components/pages/Settings/MyOrganisation/Teams/Teams'
import TeamsDetails from 'components/pages/Settings/MyOrganisation/Teams/TeamsDetails'
import AccountSettings from 'components/pages/Settings/MyOrganisation/AccountSettings'
import Imports from 'components/pages/Settings/DataManagement/Import'
import Exports from 'components/pages/Settings/DataManagement/Export'
import Activities from 'components/pages/Settings/DataManagement/Activities'
import ContactStatus from 'components/pages/Settings/DataManagement/Customization/ContactStatus'
import PrefixList from 'components/pages/Sales/Quotes/Prefix/PrefixList'
import EmailTemplate from 'components/pages/Settings/Productivity/EmailTemplates/EmailTemplate'
import EmailTemplateDetails from 'components/pages/Settings/Productivity/EmailTemplates/EmailTemplateDetails'
import ProjectCategories from 'components/pages/Settings/Projects/ProjectCategories'
import Meetings from 'components/pages/Settings/Sales/Meetings/GoogleCalIntergation'
import GoogleCalendar from 'components/pages/Settings/Sales/Meetings/GoogleCalendar'
import Holidays from 'components/pages/Settings/MyOrganisation/Holidays/Holidays'
import ScoringTab from 'components/pages/Settings/Sales/ScoringRules/ScoringTab'
import MyPlan from 'components/pages/Settings/Billing/MyPlan/MyPlan'
import Invoices from 'components/pages/Settings/Billing/Invoices/Invoices'
import InvoiceDetail from 'components/pages/Settings/Billing/Invoices/InvoiceDetail'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import Roles from 'components/pages/Settings/MyOrganisation/Roles/Roles'
import RolesDetails from 'components/pages/Settings/MyOrganisation/Roles/RolesDetails'
import TimeTracking from 'components/pages/Settings/Automations/TimeTracking/TimeTracking'
import DeveloperApi from 'components/pages/Settings/ApiAndIntegrations/DeveloperApi'
import {SETTINGS_PATH} from 'constants/path'
import UnsavedWrapLayout from 'layouts/UnsavedWrapLayout'

const settingsRoutes = [
  // My Settings
  {path: SETTINGS_PATH.MY_PROFILE, component: MyProfile, title: 'My Profile', unsavedWrap: true, exact: true},
  // My Organization
  {
    path: SETTINGS_PATH.ACCOUNT_SETTINGS,
    component: AccountSettings,
    title: 'Account Setting',
    unsavedWrap: true,
    exact: true,
  },
  {path: SETTINGS_PATH.USERS, component: Users, title: 'Users', unsavedWrap: true, exact: true},
  {path: `${SETTINGS_PATH.USERS}/:id`, component: UsersDetails, title: 'Users', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.TEAMS, component: Teams, title: 'Teams', unsavedWrap: true, exact: true},
  {path: `${SETTINGS_PATH.TEAMS}/:id`, component: TeamsDetails, title: 'Teams', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.ROLES, component: Roles, title: 'Roles', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.HOLIDAYS, component: Holidays, title: 'Holidays', unsavedWrap: true, exact: true},
  // Channels
  {path: SETTINGS_PATH.CHAT_MESSENGER, component: ChatMessenger, title: 'Chat Messenger', exact: true},
  {path: SETTINGS_PATH.CHAT_PAGES, component: ChatPages, title: 'Chat Pages', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.EMAILS, component: InboxTab, title: 'Email', unsavedWrap: true, exact: true},
  {
    path: `${SETTINGS_PATH.EMAILS}/:inboxId`,
    component: InboxEdit,
    title: 'Email',
    unsavedWrap: true,
    exact: true,
  },
  {path: SETTINGS_PATH.SMS, component: Sms, title: 'SMS', unsavedWrap: true, exact: true},
  {path: `${SETTINGS_PATH.SMS}/:inboxId`, component: SmsDetail, title: 'SMS', unsavedWrap: true, exact: true},
  // Commented out Twitter routes
  // {
  //   path: SETTINGS_PATH.TWITTER,
  //   component: Twitter,
  //   title: "Twitter",
  //   unsavedWrap: true,
  //   exact: true,
  // },
  // {
  //   path: `${SETTINGS_PATH.TWITTER}/:id,
  //   component: TwitterDetails,
  //   title: "Twitter",
  //   unsavedWrap: true,
  //   exact: true,
  // },
  {path: SETTINGS_PATH.FACEBOOK, component: Facebook, title: 'Facebook', unsavedWrap: true, exact: true},
  {
    path: `${SETTINGS_PATH.FACEBOOK}/:id`,
    component: FacebookDetails,
    title: 'Facebook',
    unsavedWrap: true,
    exact: true,
  },
  {path: SETTINGS_PATH.CONTACT_FORM, component: FeedbackList, title: 'Contact Form', unsavedWrap: true, exact: true},
  // Messenger
  {path: SETTINGS_PATH.APPEARANCE, component: Appearance, title: 'Appearance', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.CONFIGURE, component: Configure, title: 'Configure', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.ADVANCED, component: Advanced, title: 'Advanced', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.OFFLINE_EXPERIENCE, component: OfflineExperience, title: 'Offline Experience', exact: true},
  // Automations
  {path: SETTINGS_PATH.ROUTING, component: Routing, title: 'Routing', unsavedWrap: true, exact: true},
  {path: `${SETTINGS_PATH.ROUTING}/:id`, component: RoutingForm, title: 'Routing', unsavedWrap: true, exact: true},
  {
    path: SETTINGS_PATH.BUSINESS_HOURS,
    component: BusinessHours,
    title: 'Business Hours',
    unsavedWrap: true,
    exact: true,
  },
  {
    path: `${SETTINGS_PATH.BUSINESS_HOURS}/:id`,
    component: BusinessHoursForm,
    title: 'Business Hours',
    unsavedWrap: true,
    exact: true,
  },
  {path: SETTINGS_PATH.SLA_MANAGEMENT, component: SLA, title: 'SLA Policy', unsavedWrap: true, exact: true},
  {
    path: `${SETTINGS_PATH.SLA_MANAGEMENT}/:id`,
    component: SlaForm,
    title: 'SLA Policy',
    unsavedWrap: true,
    exact: true,
  },
  {
    path: SETTINGS_PATH.TIME_TRACKING,
    component: TimeTracking,
    title: 'Time Tracking',
    unsavedWrap: true,
    exact: true,
  },
  // productivity
  {path: SETTINGS_PATH.TAGS, component: Tags, title: 'Tags', exact: true},
  {
    path: SETTINGS_PATH.EMAIL_TEMPLATES,
    component: EmailTemplate,
    title: 'Email Templates',
    unsavedWrap: true,
    exact: true,
  },
  {
    path: `${SETTINGS_PATH.EMAIL_TEMPLATES}/:id`,
    component: EmailTemplateDetails,
    title: 'Email Templates',
    unsavedWrap: true,
    exact: true,
  },
  {path: SETTINGS_PATH.TASK_TEMPLATES, component: Template, title: 'Task Template', unsavedWrap: true, exact: true},
  {
    path: `${SETTINGS_PATH.TASK_TEMPLATES}/:id`,
    component: TemplateDetails,
    title: 'Task Template',
    unsavedWrap: true,
    exact: true,
  },
  {path: SETTINGS_PATH.AUDIT_LOGS, component: AuditLogs, title: 'Audit Log', unsavedWrap: true, exact: true},
  // Sales
  {path: SETTINGS_PATH.CURRENCIES, component: Currencies, title: 'Currencies', unsavedWrap: true, exact: true},
  {
    path: `${SETTINGS_PATH.CURRENCIES}/:id`,
    component: CurrenciesDetails,
    title: 'Currencies',
    unsavedWrap: true,
    exact: true,
  },
  {path: SETTINGS_PATH.FORECASTING, component: Goals, title: 'Forecasting', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.MEETINGS, component: Meetings, title: 'Meetings', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.CALENDAR, component: GoogleCalendar, title: 'Calendar', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.SCORING, component: ScoringTab, title: 'Scoring rules', exact: true},
  // Data Management
  {
    path: SETTINGS_PATH.CONTACT_STATUS,
    component: ContactStatus,
    title: 'Contact Status',
    unsavedWrap: true,
    exact: true,
  },
  {path: SETTINGS_PATH.CONTACT_TYPES, component: ContactType, title: 'Contact Types', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.DEALS_SOURCES, component: Source, title: 'Sources', exact: true},
  {path: SETTINGS_PATH.LOSS_REASONS, component: LossReason, title: 'Loss Reasons', exact: true},
  {path: SETTINGS_PATH.DEALS, component: Deals, title: 'Deals', exact: true},
  {path: SETTINGS_PATH.ACTIVITIES, component: Activities, title: 'Activities', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.QUOTES, component: PrefixList, title: 'Quotes Prefix', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.IMPORT, component: Imports, title: 'Import', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.EXPORT, component: Exports, title: 'Export', unsavedWrap: true, exact: true},
  // Projects
  {
    path: SETTINGS_PATH.PROJECT_CATEGORIES,
    component: ProjectCategories,
    title: 'Project Categories',
    unsavedWrap: true,
    exact: true,
  },
  {path: SETTINGS_PATH.EMAIL_SURVEY, component: EmailSurvey, title: 'Email Survey', unsavedWrap: true, exact: true},
  {
    path: `${SETTINGS_PATH.EMAIL_SURVEY}/:id`,
    component: EmailSurveyForm,
    title: 'Email Survey',
    unsavedWrap: true,
    exact: true,
  },
  {path: SETTINGS_PATH.CHAT_SURVEY, component: ChatSurvey, title: 'Chat Survey', unsavedWrap: true, exact: true},
  {
    path: `${SETTINGS_PATH.CHAT_SURVEY}/:id`,
    component: ChatSurveyForm,
    title: 'Chat Survey',
    unsavedWrap: true,
    exact: true,
  },
  // Billing
  {path: SETTINGS_PATH.MY_PLAN, component: MyPlan, title: 'My Plan', unsavedWrap: true, exact: true},
  {path: SETTINGS_PATH.INVOICES, component: Invoices, title: 'Invoices', unsavedWrap: true, exact: true},
  {
    path: `${SETTINGS_PATH.INVOICES}/:invoiceId`,
    component: InvoiceDetail,
    title: 'Invoices',
    unsavedWrap: true,
    exact: true,
  },
  {
    path: SETTINGS_PATH.DEVELOPER_API,
    exact: true,
    component: DeveloperApi,
    title: 'Developer API',
    unsavedWrap: true,
  },
]

const renderRoutes = ({path, exact = false, component: Component, title, unsavedWrap = false}) => {
  return (
    <Route
      key={path}
      path={`/${SETTINGS_PATH.PREFIX}/${path}`}
      exact={exact}
      render={props => (
        <>
          <DocumentTitle title={title} settings />
          <UnSavedWrap unsavedWrap={unsavedWrap}>
            <Component {...props} />
          </UnSavedWrap>
        </>
      )}
    />
  )
}

const ConditionalRoute = ({
  path,
  exact = false,
  component: Component,
  condition = false,
  fallbackRoute,
  title,
  unsavedWrap = false,
}) => {
  return (
    <Route
      path={path}
      exact={exact}
      render={props =>
        condition ? (
          <>
            <DocumentTitle title={title} settings />
            <UnsavedWrapLayout unsavedWrap={unsavedWrap}>
              <Component {...props} />
            </UnsavedWrapLayout>
          </>
        ) : (
          <Redirect to={fallbackRoute} />
        )
      }
    />
  )
}

const SettingRoutes = () => {
  const {
    clientManagementAccess,
    projectsAndCollaborationAccess,
    workflowAndAutomationAccess,
    generalAccess,
    teamAndUsersAccess,
  } = useUserPermissions()
  const contactFormsAccess = clientManagementAccess?.contactForms
  const projectsAccess = projectsAndCollaborationAccess?.projects
  const slaManagementAccess = workflowAndAutomationAccess?.slaManagement
  const taskTemplatesAccess = workflowAndAutomationAccess?.taskTemplates
  const chatSurveysAccess = generalAccess?.chatSurveys
  const customRolesAndPermisionAccess = teamAndUsersAccess?.customRolesAndPermision

  const conditionalRoutes = [
    {
      path: `/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.ROLES}/:roleId`,
      exact: true,
      component: RolesDetails,
      condition: customRolesAndPermisionAccess,
      fallbackRoute: `/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.ROLES}`,
      title: 'Roles',
      unsavedWrap: true,
    },
    {
      path: `/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.CONTACT_FORM}/:id`,
      exact: true,
      component: Feedback,
      condition: contactFormsAccess,
      fallbackRoute: `/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.CONTACT_FORM}`,
      title: 'Contact Form',
      unsavedWrap: true,
    },
    {
      path: `/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.SLA_MANAGEMENT}/:id`,
      exact: true,
      component: SlaForm,
      condition: slaManagementAccess,
      fallbackRoute: `/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.SLA_MANAGEMENT}`,
      title: 'SLA Policy',
      unsavedWrap: true,
    },
    {
      path: `/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.TASK_TEMPLATES}/:id`,
      exact: true,
      component: TemplateDetails,
      condition: taskTemplatesAccess,
      fallbackRoute: `/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.TASK_TEMPLATES}`,
      title: 'Task Template',
      unsavedWrap: true,
    },
    {
      path: `/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.CHAT_SURVEY}/:id`,
      exact: true,
      component: ChatSurveyForm,
      condition: chatSurveysAccess,
      fallbackRoute: `/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.CHAT_SURVEY}`,
      title: 'Chat Survey',
      unsavedWrap: true,
    },
  ]

  return (
    <Switch>
      {settingsRoutes.map(renderRoutes)}
      {conditionalRoutes.map(ConditionalRoute)}

      <Route path={`/${SETTINGS_PATH.PREFIX}/*`}>
        <Redirect to={`/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.MY_PROFILE}`} />
      </Route>
    </Switch>
  )
}
export default SettingRoutes
