import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiList} from 'react-icons/fi'
import {
  KooperButton,
  KooperHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTabPane,
  KooperTooltip,
} from 'kooper-library'
import {DELETE_EXPENSES_CATEGORY, GET_EXPENSES_CATEGORY} from 'actions/types'
import {deleteExpensesCategory, getExpensesCategory} from 'actions/expenses'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import SettingLayout from 'layouts/settingLayout'
import create from 'assets/create.svg'
import {startCase} from 'utils/helper'
import ExpenseSidemenu from 'navigation/sub-routes/Hr/Expenses/ExpenseMenu'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import SvgIcon from 'components/common/SvgIcon'
import ExpensesCategoryModal from './CategoryModal'

const Category = () => {
  const dispatch = useDispatch()
  const [deleteModal, setDeleteModal] = useState(false)
  const [categoryModal, setCategoryModal] = useState(false)
  const [categoryId, setCategoryId] = useState()

  const {getExpensesCategoryData} = useSelector(state => state.expenses)
  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_EXPENSES_CATEGORY})

  useEffect(() => {
    dispatch(getExpensesCategory())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(DELETE_EXPENSES_CATEGORY)) {
      dispatch(getExpensesCategory())
    }
  }, [dispatch, successLabels])

  const categoryProps = {
    open: categoryModal,
    toggleModal: setCategoryModal,
    categoryToggle: setCategoryId,
  }
  if (categoryId) {
    categoryProps.editId = categoryId
  }

  const headerButton =
    getExpensesCategoryData && getExpensesCategoryData.length
      ? {
          content: 'Create Category',
          onClick: () => {
            setCategoryId()
            setCategoryModal(true)
          },
        }
      : null

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <ExpenseSidemenu />

      <SettingLayout
        header="Category"
        subHeader="Create and manage categories for organizing your expenses efficiently"
        headerButton={headerButton}
        table
      >
        {getExpensesCategoryData && !getExpensesCategoryData.length ? (
          <div className="mt-4">
            {categoryModal ? <ExpensesCategoryModal {...categoryProps} /> : null}
            <CreateFirst
              src={<FiList />}
              header="Category"
              subHeader="Diversifying assets helps reduce the chance of loss of capital. Investment in debt instruments assures fixed return and safety of capital, having this as part of your portfolio ensures capital preservation."
              addFirst={() => {
                setCategoryId()
                setCategoryModal(true)
              }}
              buttonText="Add Category"
              Feature1="Organize your assets better"
              Feature2="Keep track and reporting according to the category"
              Feature3="Assets prevention is better"
              list1="Sorting assets according to their properties help organize it better and helps in tracking if number of assets are more"
              list2="Kooper support features detailed analytics with respect to the properties of the assets so it helps the company in making decision based on statistics"
              list3="Managing assets in kooper support will help in case of asset theft or missing asset situations and hence is more reliable"
              imageSrc={create}
            />
          </div>
        ) : (
          <KooperTabPane attached={false}>
            {deleteModal && (
              <DeleteModal
                idTobeDeleted={categoryId}
                deleteAction={deleteExpensesCategory}
                isModalOpen={deleteModal}
                setIsModalOpen={setDeleteModal}
                modalText="Are you sure you want to delete this Category?"
                type={DELETE_EXPENSES_CATEGORY}
              />
            )}
            {categoryModal ? <ExpensesCategoryModal {...categoryProps} /> : null}
            <KooperTable basic selectable>
              <KooperTableHeader>
                <KooperTableRow>
                  <KooperTableHeaderCell className="pl-4">#</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Category name</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Created by</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Action</KooperTableHeaderCell>
                </KooperTableRow>
              </KooperTableHeader>
              <KooperTableBody>
                {isLoadingData && type.includes(GET_EXPENSES_CATEGORY) ? (
                  <KooperTablePlaceholder columns={3} rows={3} />
                ) : (
                  getExpensesCategoryData &&
                  getExpensesCategoryData.map(({category, description, createdBy, id}, index) => (
                    <KooperTableRow
                      className="tableLink"
                      onClick={() => {
                        setCategoryModal(true)
                        setCategoryId(id)
                      }}
                      key={id}
                    >
                      <KooperTableCell className="pl-4">{index + 1}</KooperTableCell>
                      <KooperTableCell>
                        <KooperHeader as="h5">{startCase(category)}</KooperHeader>
                      </KooperTableCell>
                      <KooperTableCell>{startCase(createdBy?.name)}</KooperTableCell>
                      <KooperTableCell>
                        <KooperTooltip
                          content="Delete"
                          size="mini"
                          position="top center"
                          trigger={
                            <KooperButton
                              icon
                              onClick={e => {
                                setCategoryId(id)
                                setDeleteModal(true)
                                e.stopPropagation()
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </KooperButton>
                          }
                        />
                      </KooperTableCell>
                    </KooperTableRow>
                  ))
                )}
              </KooperTableBody>
            </KooperTable>
          </KooperTabPane>
        )}
      </SettingLayout>
    </>
  )
}

export default Category
