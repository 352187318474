/* eslint-disable no-nested-ternary */
import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {
  KooperButton,
  KooperCheckbox,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {CHANGE_AUTOMATION_STATUS, DELETE_AUTOMATION, GET_AUTOMATIONS} from 'actions/types'
import {changeAutomationStatus, deleteAutomation, getAutomations} from 'actions/automations'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import AutomationTitle from 'components/pages/Automations/AutomationTitle'
import create from 'assets/create-user.svg'
import {getPermissionTooltipContent, getTimeDifferenceFromNow, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import {AUTOMATIONS_PATH} from 'constants/path'

const Automations = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [idToChangeStatus, setIdToChangeStatus] = useState(null)

  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {automationsList = []} = useSelector(state => state.automations)

  const {isLoading} = useApiLoader({label: GET_AUTOMATIONS})
  const {workflowAndAutomationAccess, workflowsPermissions} = useUserPermissions()
  const advanceWorkflowsAccess = workflowAndAutomationAccess?.advanceWorkflows
  const advanceWorkflowsLimitAccess = workflowAndAutomationAccess?.advanceWorkflowsLimit
  const manageWorkflowsPermissions = workflowsPermissions?.manage_workflows

  const isFeatureLocked = useMemo(() => {
    return (
      !advanceWorkflowsAccess || (advanceWorkflowsLimitAccess && automationsList.length >= +advanceWorkflowsLimitAccess)
    )
  }, [advanceWorkflowsAccess, advanceWorkflowsLimitAccess, automationsList.length])

  useEffect(() => {
    if (advanceWorkflowsAccess && manageWorkflowsPermissions) {
      dispatch(getAutomations())
    }
  }, [advanceWorkflowsAccess, manageWorkflowsPermissions, dispatch])

  useEffect(() => {
    if (successLabels.includes(CHANGE_AUTOMATION_STATUS) || successLabels.includes(DELETE_AUTOMATION)) {
      dispatch(getAutomations())
    }
  }, [successLabels, dispatch])

  useEffect(() => {
    if (successLabels.includes(CHANGE_AUTOMATION_STATUS)) {
      setIsStatusModalOpen(false)
      setIdToChangeStatus(null)
    }
  }, [successLabels])

  const handleStatusChangeConfirm = () => {
    const data = {
      isActive: !automationsList.find(automation => automation.id === idToChangeStatus)?.isActive,
    }
    dispatch(changeAutomationStatus(idToChangeStatus, data))
  }

  if (isLoading && advanceWorkflowsAccess && manageWorkflowsPermissions) {
    return <PageLoader />
  }

  if (!automationsList.length || !advanceWorkflowsAccess || !manageWorkflowsPermissions) {
    return (
      <>
        {isCreateModalOpen && <AutomationTitle open={isCreateModalOpen} toggle={setIsCreateModalOpen} />}
        <CreateFirst
          header="Automations"
          subHeader="Helps to automate tasks or workflows, reducing manual intervention and improving efficiency"
          addFirst={() => advanceWorkflowsAccess && manageWorkflowsPermissions && setIsCreateModalOpen(true)}
          buttonText="Create Automation"
          tooltip={!advanceWorkflowsAccess || !manageWorkflowsPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: advanceWorkflowsAccess,
            roleAccess: manageWorkflowsPermissions,
          })}
          imageSrc={create}
          Feature1="Task Automation"
          list1="Automate routine tasks based on predefined triggers."
          Feature2="Appointment Scheduling"
          list2="Schedule appointments with your customers online"
          Feature3="Email Automation"
          list3="Set up automated email campaigns engaging with leads."
        />
      </>
    )
  }

  const renderBody = () => {
    if (type.includes(GET_AUTOMATIONS)) {
      return <KooperTablePlaceholder columns={7} row={3} />
    }
    return automationsList.map((automation, index) => (
      <KooperTableRow
        key={automation.id}
        className="tableLink"
        onClick={() =>
          history.push({
            pathname: `/${AUTOMATIONS_PATH.PREFIX}/${AUTOMATIONS_PATH.BUILDER}/${automation.id}`,
            state: {name: automation.name, description: ''},
          })
        }
      >
        <KooperTableCell>{index + 1}</KooperTableCell>
        <KooperTableCell>{startCase(automation.name)}</KooperTableCell>
        <KooperTableCell>{startCase(automation.entity)}</KooperTableCell>
        <KooperTableCell>{getTimeDifferenceFromNow(automation.createdAt)}</KooperTableCell>
        <KooperTableCell>{automation.createdBy.firstName}</KooperTableCell>
        <KooperTableCell>
          <KooperTooltip
            position="top center"
            size="mini"
            content={
              manageWorkflowsPermissions
                ? automation.isActive
                  ? 'Active'
                  : 'Inactive'
                : getPermissionTooltipContent({roleAccess: manageWorkflowsPermissions})
            }
            trigger={
              <KooperCheckbox
                toggle
                checked={automation.isActive}
                onClick={e => e.stopPropagation()}
                onChange={() => {
                  if (!manageWorkflowsPermissions) return
                  setIsStatusModalOpen(true)
                  setIdToChangeStatus(automation.id)
                }}
              />
            }
          />
        </KooperTableCell>
        <KooperTableCell>
          <KooperTooltip
            size="mini"
            position="top center"
            content={
              manageWorkflowsPermissions
                ? 'Delete'
                : getPermissionTooltipContent({roleAccess: manageWorkflowsPermissions})
            }
            trigger={
              <KooperButton
                icon
                onClick={e => {
                  e.stopPropagation()
                  if (manageWorkflowsPermissions) {
                    setIdToDelete(automation.id)
                    setIsDeleteModalOpen(true)
                  }
                }}
              >
                <SvgIcon path="common/delete" />
              </KooperButton>
            }
          />
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <>
      {isCreateModalOpen && <AutomationTitle open={isCreateModalOpen} toggle={setIsCreateModalOpen} />}

      <div className="kooper-full-wrap">
        <div className="page-header">
          <div className="page-title">
            <div className="page-titleIcon">
              <SvgIcon path="settings/users" />
              <h5>
                Automations (<span>{automationsList.length}</span>)
              </h5>
            </div>
          </div>
          <div className="page-action">
            <LockPermissionTooltip
              isFeatureAccessDenied={isFeatureLocked}
              isRoleAccessDenied={!manageWorkflowsPermissions}
            >
              <KooperButton
                primary
                className={isFeatureLocked || !manageWorkflowsPermissions ? 'disabled-button' : ''}
                onClick={() => !isFeatureLocked && manageWorkflowsPermissions && setIsCreateModalOpen(true)}
              >
                Create Automation
              </KooperButton>
            </LockPermissionTooltip>
          </div>
        </div>

        <KooperTable basic selectable>
          <KooperTableHeader>
            <KooperTableRow>
              <KooperTableHeaderCell className="pl-4">#</KooperTableHeaderCell>
              <KooperTableHeaderCell>Name</KooperTableHeaderCell>
              <KooperTableHeaderCell>Entity</KooperTableHeaderCell>
              <KooperTableHeaderCell>Created At</KooperTableHeaderCell>
              <KooperTableHeaderCell>Created By</KooperTableHeaderCell>
              <KooperTableHeaderCell>Status</KooperTableHeaderCell>
              <KooperTableHeaderCell>Action</KooperTableHeaderCell>
            </KooperTableRow>
          </KooperTableHeader>
          <KooperTableBody>{renderBody()}</KooperTableBody>
        </KooperTable>
      </div>

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          idTobeDeleted={idToDelete}
          deleteAction={deleteAutomation}
          type={DELETE_AUTOMATION}
        />
      )}

      {isStatusModalOpen && (
        <KooperModal
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
          size="tiny"
          centered={false}
          open={isStatusModalOpen}
          onClose={() => setIsStatusModalOpen(false)}
        >
          <KooperModalHeader>Status Change</KooperModalHeader>
          <KooperModalContent>Are you sure you want to change status ?</KooperModalContent>
          <KooperModalActions>
            <KooperButton basic onClick={() => setIsStatusModalOpen(false)}>
              Cancel
            </KooperButton>
            <KooperButton primary onClick={handleStatusChangeConfirm}>
              Confirm
            </KooperButton>
          </KooperModalActions>
        </KooperModal>
      )}
    </>
  )
}

export default Automations
