import React from 'react'
import {useHistory} from 'react-router-dom'
import {copyToClipboardDropdown} from 'utils/helper'
import {KooperButton, KooperFormInput, KooperModal, KooperModalActions, KooperModalContent} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'

function SalesOrderModal({open, toggle, link}) {
  const {push} = useHistory()

  return (
    <KooperModal
      open={open}
      onClose={toggle}
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      style={{textAlign: 'center'}}
    >
      <KooperModalContent>
        <h1>Download as PDf</h1>
        <h2 className="mt-3">A web page for your Sales Orders has been created</h2>
        <p className="my-4">
          Your Sales Order has been successfully created, below is the link to download and view it. You can also send
          this Sales oders directly to the customer from below button
        </p>

        <KooperFormInput
          readOnly
          value={link?.link}
          style={{width: '80%'}}
          action={{
            color: 'basic',
            content: 'Copy Link',
            onClick: e => copyToClipboardDropdown(e, link?.link, 'Link Copied'),
          }}
        />
        <div className="mt-4">
          <KooperButton
            primary
            onClick={() => {
              push({
                pathname: '/inbox',
                state: {
                  SOState: link?.link,
                },
              })
            }}
          >
            Send Sales Order
          </KooperButton>
          {/* <KooperButton basic className="ml-3">
            Download
          </KooperButton> */}
        </div>
      </KooperModalContent>

      <KooperModalActions>
        <KooperButton basic onClick={toggle}>
          Cancel
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default SalesOrderModal
