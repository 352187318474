import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperFormTextArea,
  KooperTextArea,
  KooperTooltip,
} from 'kooper-library'

import {CREATE_TEAMS, EDIT_TEAMS, GET_TEAM_DATA, GET_USERS} from 'actions/types'
import {getUsers} from 'actions/settings'
import {createTeams, editTeams, getAllTeams, getTeamsData} from 'actions/settings_organisation'
import {removeDoubleQuotes} from 'utils/helper'
import UserImage from 'components/common/UserImage'
import SettingLayout from 'layouts/settingLayout'
import {teamSchema} from 'validation/Settings/myOrganisation/teams.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {SETTINGS_PATH} from 'constants/path'

const TeamsDetails = ({setWarningModal, toggleModal}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id: editId} = useParams()

  const [usersList, setUsersList] = useState([])

  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {teamsData} = useSelector(state => state.settingsOrganisation)
  const {myOrganizationDefaultPermissions} = useUserPermissions()

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const teamsAddLearnMore = settingsLearnMoreUrl?.myOrganization?.teams?.addLearnMore
  const teamsUpdateLearnMore = settingsLearnMoreUrl?.myOrganization?.teams?.updateLearnMore

  const defaultValues = {
    name: '',
    assigneeIds: [],
    description: '',
  }

  const {
    handleSubmit,
    control,
    errors,
    clearErrors,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(teamSchema),
    defaultValues,
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => {
        const {id, firstName, lastName, avatar, email} = user
        return {
          key: id,
          value: id,
          text: `${firstName} ${lastName}`,
          content: (
            <div>
              <UserImage profile={avatar && user} className="ui avatar image" />
              {`${firstName} ${lastName} `}
              <small className="ml-2 kooper-lead">{email}</small>
            </div>
          ),
        }
      })
      setUsersList(list || [])
    },
  })

  useEffect(() => {
    if (editId) dispatch(getTeamsData(editId))
    return () => dispatch({type: 'CLEAR_TEAM_DATA'})
  }, [editId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_TEAM_DATA)) {
      reset(_.omit(teamsData, 'id'))
    }
  }, [successLabels, reset, teamsData])

  useEffect(() => {
    if (successLabels.includes(CREATE_TEAMS)) {
      dispatch(getAllTeams())
      toggleModal(false)
    }

    if (successLabels.includes(EDIT_TEAMS)) {
      setWarningModal(false)
      dispatch(getAllTeams())
      history.goBack()
    }
  }, [successLabels, dispatch, toggleModal, setWarningModal, history])

  useEffect(() => {
    if (editId && isDirty) {
      setWarningModal(isDirty)
    }
    return () => {
      if (editId && isDirty) setWarningModal(false)
    }
  }, [isDirty, editId, setWarningModal])

  const submitForm = data => {
    if (editId) {
      dispatch(editTeams(editId, data))
    } else {
      dispatch(createTeams(data))
    }
  }

  return (
    <SettingLayout
      header={editId ? 'Edit Team' : 'Create Team'}
      headerbackbtn={{
        onClick: () => {
          if (editId) {
            setWarningModal(false)
            history.push(`/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.TEAMS}`)
          } else {
            toggleModal(false)
          }
        },
      }}
      subHeader="Create a team by mentioning name and assigning team members"
      learnMoreUrl={editId ? teamsUpdateLearnMore : teamsAddLearnMore}
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Cancel',
          disabled: isLoadingData && (type.includes(CREATE_TEAMS) || type.includes(EDIT_TEAMS)),
          onClick: () => {
            if (editId) {
              setWarningModal(false)
              history.push(`${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.TEAMS}`)
            } else {
              toggleModal(false)
            }
          },
        },
        success: {
          content: editId ? 'Update' : 'Create',
          loading: isLoadingData && (type.includes(CREATE_TEAMS) || type.includes(EDIT_TEAMS)),
          ...(myOrganizationDefaultPermissions && {
            onClick: handleSubmit(submitForm),
          }),
          disabled: isLoadingData && (type.includes(CREATE_TEAMS) || type.includes(EDIT_TEAMS)),
        },
      }}
      lockRole={!myOrganizationDefaultPermissions}
    >
      <KooperForm className="errorLabel">
        <KooperFormField required width={8}>
          <div className="info-header">
            <label className="label-class">Name</label>
            <KooperTooltip
              content="Specify the team name "
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="name"
            control={control}
            render={({value, onChange}) => (
              <KooperFormInput
                maxLength={20}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                error={
                  errors.name && {
                    content: removeDoubleQuotes(errors.name.message),
                  }
                }
              />
            )}
          />
        </KooperFormField>

        <KooperFormField required width={8}>
          <div className="info-header">
            <label className="label-class">Members</label>
            <KooperTooltip
              content="Add users in the team"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="assigneeIds"
            control={control}
            render={({value, onChange}) => (
              <KooperFormSelect
                search
                multiple
                options={usersList}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                onClick={() => errors.assigneeIds && clearErrors('assigneeIds')}
                error={
                  errors.assigneeIds && {
                    content: removeDoubleQuotes(errors.assigneeIds.message),
                  }
                }
              />
            )}
          />
        </KooperFormField>

        <KooperFormField width={8}>
          <div className="info-header">
            <label className="label-class">Description</label>
            <KooperTooltip
              content="Specify a brief description about the team"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="description"
            render={({value, onChange}) => (
              <KooperFormTextArea
                maxLength={200}
                style={{resize: 'none'}}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                error={
                  errors.description && {
                    content: removeDoubleQuotes(errors.description.message),
                  }
                }
              />
            )}
            control={control}
          />
        </KooperFormField>
      </KooperForm>
    </SettingLayout>
  )
}

export default TeamsDetails
