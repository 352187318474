import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperMenu,
  KooperMenuMenu,
  KooperMenuItem,
  KooperDropdown,
  KooperDropdownMenu,
  KooperDropdownItem,
  KooperImage,
  KooperTooltip,
  KooperDropdownDivider,
  KooperModal,
  KooperModalHeader,
  KooperModalContent,
  KooperModalActions,
  KooperButton,
} from 'kooper-library'
import {useHistory} from 'react-router-dom'
import {logoutCall} from 'actions'
import {FiFolder, FiSettings} from 'react-icons/fi'
import SvgIcon from 'components/common/SvgIcon'
import {LOGOUT} from 'actions/types'
import {
  ANNOUNCEMENTS_PATH,
  APPRAISALS_PATH,
  AUTOMATIONS_PATH,
  BUDGETING_PATH,
  BUDGETS_PLANNER_PATH,
  CLIENT_PORTAL_PATH,
  COMPANIES_PATH,
  CONTACTS_PATH,
  DASHBOARDS_PATH,
  DEALS_PATH,
  EMPLOYEES_PATH,
  EXPENSES_PATH,
  FORECASTS_PATH,
  INVOICES_PATH,
  MEETINGS_PATH,
  PRODUCTS_PATH,
  PROJECTS_PATH,
  PROOFS_PATH,
  PURCHASE_ORDERS_PATH,
  PURCHASES_PATH,
  QUOTES_PATH,
  REPORTS_PATH,
  RESOURCE_PLANNING_PATH,
  SALES_ORDERS_PATH,
  SEQUENCES_PATH,
  SETTINGS_PATH,
  TASKS_PATH,
  TIME_OFF_PATH,
  TIMESHEETS_PATH,
  VENDORS_PATH,
} from 'constants/path'
import AccountUserImage from '../components/common/AccountUserImage'
import logo from '../assets/logo-white.svg'
import GlobalSearch from '../components/pages/GlobalSearch'

export function NavBar() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)

  const {type = []} = useSelector(state => state.apiReducer)

  return (
    <>
      <KooperMenu size="small" className="kooper-nav-bar">
        <div className="d-flex">
          <KooperImage src={logo} onClick={() => history.push('/home')} style={{cursor: 'pointer'}} />
          <KooperDropdownItem text="Home" onClick={() => history.push('/home')} style={{cursor: 'pointer'}} />
          <KooperDropdown className="simple" item icon="chevron down" text="Clients">
            <KooperDropdownMenu>
              <KooperDropdownItem item onClick={() => history.push(`/${CONTACTS_PATH.PREFIX}`)}>
                {/* <SvgIcon path="navigation/contacts" /> */}
                <span>Contacts</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${COMPANIES_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/companies" />
                <span>Companies</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${CLIENT_PORTAL_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/client-portal" />
                <span>Client Portal</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push('/calendar')}>
                <SvgIcon path="navigation/calendar" />
                <span>Calendar</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push('/activities-feed')}>
                <SvgIcon path="navigation/activities-feed" />
                <span>Activities Feed</span>
              </KooperDropdownItem>
              {/* <KooperDropdownItem item onClick={() => history.push('/live-visitors')}>
              <FiFolder />
              <span>Live Visitors</span>
            </KooperDropdownItem> */}
            </KooperDropdownMenu>
          </KooperDropdown>
          {/* <KooperDropdown
          className="simple"
          item
          icon="chevron down"
          onChange={(e, {value}) => history.push(`/${value}`)}
          options={conversationsOptions}
          text="Conversations"
        /> */}
          <KooperDropdownItem text="Inbox" onClick={() => history.push('/inbox')} style={{cursor: 'pointer'}} />
          {/* <KooperDropdown
          className="simple"
          item
          icon="chevron down"
          onChange={(e, {value}) => history.push(`/${value}`)}
          options={marketingOptions}
          text="Marketing"
        /> */}

          {/* <KooperDropdown className="simple" item icon="chevron down" text="Marketing">
          <KooperDropdownMenu>
            
            <KooperDropdownItem item onClick={() => history.push('/forms')}>
              <FiFolder /> <span>Forms</span>
            </KooperDropdownItem>
            <KooperDropdownItem item onClick={() => history.push('/landing-pages')}>
              <FiFolder /> <span>Landing Pages</span>
            </KooperDropdownItem>
          </KooperDropdownMenu>
        </KooperDropdown> */}

          <KooperDropdown className="simple" item icon="chevron down" text="Sales">
            <KooperDropdownMenu>
              <KooperDropdownItem item onClick={() => history.push(`/${DEALS_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/deals" /> <span>Deals</span>
              </KooperDropdownItem>
              {/* <KooperDropdownItem item onClick={() => history.push(`/${CAMPAIGNS_PATH.PREFIX}`)}>
              <KooperImage src={} /> <span>Campaigns</span>
            </KooperDropdownItem> */}
              <KooperDropdownItem item onClick={() => history.push(`/${MEETINGS_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/meetings" /> <span>Meetings</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${FORECASTS_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/forecasting" /> <span>Forecasting</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${SEQUENCES_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/sequences" /> <span>Sequences</span>
              </KooperDropdownItem>
              {/* <KooperDropdownItem item onClick={() => history.push('/playbooks')}>
              <FiFolder /> <span>Playbooks</span>
            </KooperDropdownItem> */}
              <KooperDropdownItem item onClick={() => history.push(`/${QUOTES_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/quotes" /> <span>Quotes</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${PRODUCTS_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/products" /> <span>Products</span>
              </KooperDropdownItem>
            </KooperDropdownMenu>
          </KooperDropdown>
          {/* <KooperDropdown
          className="simple"
          item
          icon="chevron down"
          onChange={(e, {value}) => history.push(`/${value}`)}
          options={supportOptions}
          text="Support"
        /> */}
          {/* <KooperDropdown
          className="simple"
          item
          icon="chevron down"
          onChange={(e, {value}) => history.push(`/${value}`)}
          options={projectsOptions}
          text="Projects"
        /> */}
          {/* <KooperDropdown
          className="simple"
          item
          icon="chevron down"
          options={financeOptions}
          text="Finance"
          onChange={(e, {value}) => history.push(`/${value}`)}
        /> */}

          <KooperDropdown className="simple" item icon="chevron down" text="Projects">
            <KooperDropdownMenu>
              <KooperDropdownItem item onClick={() => history.push(`/${PROJECTS_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/projects" /> <span>Projects</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${BUDGETING_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/budgeting" /> <span>Budgeting</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${TASKS_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/tasks" /> <span>Tasks</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${TIMESHEETS_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/timesheets" /> <span>Timesheets</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${PROOFS_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/proofs" /> <span>Proofs</span>
              </KooperDropdownItem>
              <KooperDropdownItem
                item
                onClick={() => history.push(`/${RESOURCE_PLANNING_PATH.PREFIX}/${RESOURCE_PLANNING_PATH.OVERVIEW}`)}
              >
                <SvgIcon path="navigation/resource-planning" /> <span>Resource Planning</span>
              </KooperDropdownItem>
            </KooperDropdownMenu>
          </KooperDropdown>

          <KooperDropdown className="simple" item icon="chevron down" text="Finance">
            <KooperDropdownMenu>
              <KooperDropdownItem item onClick={() => history.push(`/${INVOICES_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/invoices" /> <span>Invoices</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${SALES_ORDERS_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/sales-orders" /> <span>Sales Orders</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${PURCHASE_ORDERS_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/purchase-orders" /> <span>Purchase Orders</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${BUDGETS_PLANNER_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/budget-planner" /> <span>Budgets Planner</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${VENDORS_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/vendors" /> <span>Vendors</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${PURCHASES_PATH.PREFIX}`)}>
                <SvgIcon path="navigation/purchases" /> <span>Purchases</span>
              </KooperDropdownItem>
            </KooperDropdownMenu>
          </KooperDropdown>

          <KooperDropdown className="simple" item icon="chevron down" text="Teams">
            <KooperDropdownMenu>
              <KooperDropdownItem item onClick={() => history.push('/perfomance-reviews')}>
                <SvgIcon path="navigation/invoices" /> <span>Perfomance Reviews</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push('/goals')}>
                <SvgIcon path="navigation/sales-orders" /> <span>Goals</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push('/surveys')}>
                <SvgIcon path="navigation/purchase-orders" /> <span>Surveys</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push('/1-on-1s')}>
                <SvgIcon path="navigation/budget-planner" /> <span>1-on-1s</span>
              </KooperDropdownItem>
            </KooperDropdownMenu>
          </KooperDropdown>

          {/* <KooperDropdown className="simple" item icon="chevron down" text="HR">
            <KooperDropdownMenu>
              <KooperDropdownItem item onClick={() => history.push(`/${EMPLOYEES_PATH.PREFIX}`)}>
                <FiFolder /> <span>Employees</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${TIME_OFF_PATH.PREFIX}`)}>
                <FiFolder /> <span>Time Off</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${EXPENSES_PATH.PREFIX}`)}>
                <FiFolder /> <span>Expenses</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push('/payroll')}>
                <FiFolder /> <span>Payroll</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push('/contracts')}>
                <FiFolder /> <span>Contracts</span>
              </KooperDropdownItem>
              <KooperDropdownItem
                item
                onClick={() => history.push(`/${APPRAISALS_PATH.PREFIX}/${APPRAISALS_PATH.TEMPLATES}`)}
              >
                <FiFolder /> <span>Appraisals</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${ANNOUNCEMENTS_PATH.PREFIX}`)}>
                <FiFolder /> <span>Announcements</span>
              </KooperDropdownItem>
            </KooperDropdownMenu>
          </KooperDropdown> */}

          <KooperDropdownItem
            text="Automation"
            onClick={() => history.push(`/${AUTOMATIONS_PATH.PREFIX}`)}
            style={{cursor: 'pointer'}}
          />

          <KooperDropdown className="simple" item icon="chevron down" text="Analytics">
            <KooperDropdownMenu>
              <KooperDropdownItem
                item
                onClick={() => history.push(`/${DASHBOARDS_PATH.PREFIX}/${DASHBOARDS_PATH.OVERVIEW}`)}
              >
                <SvgIcon path="navigation/dashboards" /> <span>Dashboards</span>
              </KooperDropdownItem>
              <KooperDropdownItem item onClick={() => history.push(`/${REPORTS_PATH.PREFIX}/${REPORTS_PATH.OVERVIEW}`)}>
                <SvgIcon path="navigation/reports" /> <span>Reports</span>
              </KooperDropdownItem>
            </KooperDropdownMenu>
          </KooperDropdown>

          {/* <KooperDropdown
          className="simple"
          item
          icon="chevron down"
          onChange={(e, {value}) => history.push(`/${value}`)}
          options={analyticsOptions}
          text="Analytics"
        /> */}
        </div>

        <KooperMenuMenu position="right" className="pb-2">
          <div className="d-flex">
            <GlobalSearch />
            {/* <KooperTooltip
            position="top center"
            content="Activity Feed"
            size="mini"
            trigger={
              <KooperMenuItem onClick={() => {}}>
                <FiRss />
              </KooperMenuItem>
            }
          />
          <KooperTooltip
            position="top center"
            content="Notification"
            size="mini"
            trigger={
              <KooperMenuItem onClick={() => {}}>
                <FiBell />
              </KooperMenuItem>
            }
          /> */}
            <KooperTooltip
              position="top center"
              content="Settings"
              size="mini"
              trigger={
                <KooperMenuItem
                  active={window.location.pathname.includes(`/${SETTINGS_PATH.PREFIX}`)}
                  onClick={() => history.push(`/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.MY_PROFILE}`)}
                >
                  <FiSettings />
                </KooperMenuItem>
              }
            />
            <KooperDropdown
              icon={<AccountUserImage />}
              className="link item icon nav-agent-profile mt-2"
              direction="left"
            >
              <KooperDropdownMenu>
                <KooperDropdownItem
                  onClick={() => history.push(`/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.MY_PROFILE}`)}
                >
                  <SvgIcon path="navigation/my-profile" /> <span>My Profile</span>
                </KooperDropdownItem>
                <KooperDropdownItem onClick={() => history.push(`/getting-started`)}>
                  <SvgIcon path="navigation/getting-started" /> <span>Getting Started</span>
                </KooperDropdownItem>
                <KooperDropdownItem onClick={() => history.push(`/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.MY_PLAN}`)}>
                  <SvgIcon path="navigation/my-plan" /> <span>My Plan</span>
                </KooperDropdownItem>
                <KooperDropdownItem onClick={() => setIsLogoutModalOpen(true)}>
                  <SvgIcon path="navigation/logout" /> <span>Logout</span>
                </KooperDropdownItem>
              </KooperDropdownMenu>
            </KooperDropdown>
          </div>
        </KooperMenuMenu>
      </KooperMenu>

      {isLogoutModalOpen && (
        <KooperModal
          open={isLogoutModalOpen}
          onClose={() => setIsLogoutModalOpen(false)}
          size="tiny"
          centered={false}
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        >
          <KooperModalHeader>Logout from Kooper</KooperModalHeader>
          <KooperModalContent>Are you sure you want to logout?</KooperModalContent>
          <KooperModalActions>
            <KooperButton basic onClick={() => setIsLogoutModalOpen(false)}>
              Cancel
            </KooperButton>
            <KooperButton primary loading={type.includes(LOGOUT)} onClick={() => dispatch(logoutCall())}>
              Yes
            </KooperButton>
          </KooperModalActions>
        </KooperModal>
      )}
    </>
  )
}
