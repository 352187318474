/* eslint-disable no-nested-ternary */
import React, {useState, useEffect} from 'react'
import create from 'assets/create.svg'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory} from 'react-router'
import _ from 'lodash'
import {
  KooperButton,
  KooperTable,
  KooperTooltip,
  KooperTableCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableBody,
  KooperDivider,
  KooperDropdown,
  KooperDropdownMenu,
  KooperDropdownItem,
  KooperHeader,
} from 'kooper-library'
import {DELETE_VENDORS, GET_VENDORS_LIST, EXPORT_ALL_VENDORS} from 'actions/types'
import {
  deleteVendors,
  getVendorslist,
  getVendorsCategory,
  exportAllVendors,
  getVendorFieldByCategory,
} from 'actions/vendors'
import SearchComponent from 'components/common/SearchComponent'
import {downloadResponseCSV, getPermissionTooltipContent, getSortType, startCase} from 'utils/helper'
import DeleteModal from 'components/common/DeleteModal'
import CreateFirst from 'components/common/CreateFirst'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import SettingLayout from 'layouts/settingLayout'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import {roleAccessDeniedContent} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {VENDORS_PATH} from 'constants/path'

function VendorsList() {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [categoryList, setCategoryList] = useState([])
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    category: '',
    sortBy: '',
    sortType: '',
    text: '',
  })

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {getVendorsCategoryData, exportData} = useSelector(state => state.vendors)
  const {financePermissions} = useUserPermissions()
  const manageVendorsPermissions = financePermissions?.manage_vendors

  const {
    data: vendorsListData = [],
    totalPages,
    currentPage,
    pageSize,
    totalRecords,
  } = useApiResponse({
    label: GET_VENDORS_LIST,
    storePath: 'vendors.vendorsList',
    dataToExtract: 'vendors',
  })

  const {isLoading} = useApiLoader({label: GET_VENDORS_LIST})

  useEffect(() => {
    if (successLabels.includes(DELETE_VENDORS)) {
      dispatch(getVendorslist())
    }

    if (successLabels.includes(EXPORT_ALL_VENDORS)) {
      downloadResponseCSV(exportData, 'all-vendors')
      dispatch({type: 'CLEAR_SELECTED_PRODUCTS'})
    }
  }, [successLabels, exportData, dispatch])

  useEffect(() => {
    if (getVendorsCategoryData)
      setCategoryList(
        getVendorsCategoryData.map(({category, id}) => {
          return {key: id, value: id, text: category}
        })
      )
  }, [getVendorsCategoryData])

  useEffect(() => {
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getVendorslist(newCurrentListParams))
  }, [currentListParams, dispatch])

  const handleSort = clickedColumn => () => {
    if (currentListParams.sortBy !== clickedColumn) {
      setCurrentListParams({
        ...currentListParams,
        sortType: 'asc',
        sortBy: clickedColumn,
      })
      return
    }
    setCurrentListParams({
      ...currentListParams,
      sortType: currentListParams.sortType === 'asc' ? 'desc' : 'asc',
    })
  }

  useEffect(() => {
    dispatch(getVendorslist())
    dispatch(getVendorsCategory())
    dispatch(getVendorFieldByCategory())
  }, [dispatch])

  const renderTableBody = () => {
    return vendorsListData.map(({id, name, updatedBy, createdBy, category}, index) => {
      return (
        <KooperTableRow
          key={id}
          className="tableLink"
          onClick={() => {
            history.push(`/${VENDORS_PATH.PREFIX}/${VENDORS_PATH.UPDATE}/${id}`)
          }}
        >
          <KooperTableCell>{pageSize * (currentPage - 1) + index + 1}</KooperTableCell>
          <KooperTableCell>
            <KooperHeader as="h5">{startCase(name)}</KooperHeader>
          </KooperTableCell>
          <KooperTableCell>{startCase(createdBy.name)}</KooperTableCell>
          <KooperTableCell>{startCase(category.name)}</KooperTableCell>
          <KooperTableCell>{startCase(createdBy.name)}</KooperTableCell>
          <KooperTableCell>{startCase(updatedBy?.name)}</KooperTableCell>
          <KooperTableCell>
            <KooperTooltip
              content={!manageVendorsPermissions ? roleAccessDeniedContent : 'Delete'}
              size="mini"
              position="top center"
              trigger={
                <KooperButton
                  icon={<SvgIcon path="common/delete" />}
                  onClick={e => {
                    e.stopPropagation()
                    if (manageVendorsPermissions) {
                      setEditId(id)
                      setDeleteModal(true)
                    }
                  }}
                />
              }
            />
          </KooperTableCell>
        </KooperTableRow>
      )
    })
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {vendorsListData.length > 0 || currentListParams.text || currentListParams.category ? (
        <div className="kooper-side-wrap">
          <div className="page-header">
            <div className="page-title">
              <div className="page-titleIcon">
                <SvgIcon path="navigation/vendors" />
                <h5>Vendors List ({totalRecords})</h5>
              </div>
            </div>
            <div className="page-action">
              <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />

              <KooperTooltip
                position="top center"
                size="mini"
                content="Select Category"
                trigger={
                  <div className="mailActivity ml-3">
                    <KooperDropdown
                      icon="chevron down"
                      button
                      defaultValue="all"
                      options={[
                        {key: 'all', value: 'all', text: 'All Category'},
                        {
                          key: 'No Category',
                          value: 'No Category',
                          text: 'No Category',
                        },
                        <KooperDivider />,
                        ...categoryList,
                      ]}
                      onChange={(e, {value}) => {
                        setCurrentListParams({
                          ...currentListParams,
                          category: value,
                        })
                      }}
                    />
                  </div>
                }
              />

              <KooperTooltip
                size="mini"
                position="top center"
                content="Settings"
                trigger={
                  <KooperDropdown floating button className="icon" icon={<SvgIcon path="common/settings" />}>
                    <KooperDropdownMenu className="left">
                      <LockPermissionTooltip isRoleAccessDenied={!manageVendorsPermissions}>
                        <KooperDropdownItem
                          text="Export All"
                          onClick={() => manageVendorsPermissions && dispatch(exportAllVendors())}
                        />
                      </LockPermissionTooltip>
                    </KooperDropdownMenu>
                  </KooperDropdown>
                }
              />

              <LockPermissionTooltip isRoleAccessDenied={!manageVendorsPermissions}>
                <KooperButton
                  className={!manageVendorsPermissions ? 'disabled-button' : ''}
                  content="Create Vendor"
                  primary
                  onClick={() =>
                    manageVendorsPermissions && history.push(`/${VENDORS_PATH.PREFIX}/${VENDORS_PATH.CREATE}`)
                  }
                />
              </LockPermissionTooltip>
            </div>
          </div>
          <KooperTable sortable basic selectable>
            <KooperTableHeader>
              <KooperTableRow>
                <KooperTableHeaderCell>#</KooperTableHeaderCell>
                <KooperTableHeaderCell
                  sorted={currentListParams.sortBy === 'name' && getSortType(currentListParams.sortType)}
                  onClick={handleSort('name')}
                >
                  Name
                </KooperTableHeaderCell>
                <KooperTableHeaderCell>Owner</KooperTableHeaderCell>
                <KooperTableHeaderCell
                  sorted={currentListParams.sortBy === 'categoryId' && getSortType(currentListParams.sortType)}
                  onClick={handleSort('categoryId')}
                >
                  Category
                </KooperTableHeaderCell>
                <KooperTableHeaderCell>Created By</KooperTableHeaderCell>
                <KooperTableHeaderCell>Updated By</KooperTableHeaderCell>
                <KooperTableHeaderCell>Action</KooperTableHeaderCell>
              </KooperTableRow>
            </KooperTableHeader>
            <KooperTableBody>
              {isLoadingData && type.includes(GET_VENDORS_LIST) ? (
                <KooperTablePlaceholder columns={8} />
              ) : !isLoadingData && (vendorsListData || []).length > 0 ? (
                renderTableBody()
              ) : (
                <tr>
                  <td colSpan={6} className="emptyValue">
                    No Data Available
                  </td>
                </tr>
              )}
            </KooperTableBody>
          </KooperTable>
          <KooperCustomPagination
            activePage={currentPage}
            totalPages={totalPages}
            onPageChange={page =>
              setCurrentListParams(currentListParams => ({
                ...currentListParams,
                page,
              }))
            }
          />

          {deleteModal && (
            <DeleteModal
              isModalOpen={deleteModal}
              setIsModalOpen={setDeleteModal}
              modalText="Are you sure you want to delete ?"
              deleteAction={deleteVendors}
              idTobeDeleted={editId}
              type={DELETE_VENDORS}
            />
          )}
        </div>
      ) : (
        <SettingLayout>
          <CreateFirst
            header="Vendor"
            addFirst={() => manageVendorsPermissions && history.push(`/${VENDORS_PATH.PREFIX}/${VENDORS_PATH.CREATE}`)}
            tooltip={!manageVendorsPermissions}
            tooltipContent={getPermissionTooltipContent({
              roleAccess: manageVendorsPermissions,
            })}
            buttonText="Create Vendor"
            imageSrc={create}
            subHeader="Vendors are business entities who deliver products to customers on time"
            Feature1="Vendors Information"
            list1="Store and manage detailed information about vendors"
            Feature2="Vendors Categorization"
            list2="Organize and categorize vendors for better visibility and reporting"
            Feature3="Export Vendors"
            list3="Export vendors list in CSV format in one click"
          />
        </SettingLayout>
      )}
    </>
  )
}

export default VendorsList
