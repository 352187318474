import React from 'react'
import {FaUserLock} from 'react-icons/fa'
import {KooperTooltip} from 'kooper-library'
import {roleAccessDeniedContent} from 'constants/variables'

const LockRole = () => {
  return <KooperTooltip content={roleAccessDeniedContent} position="top center" size="mini" trigger={<FaUserLock />} />
}

export default LockRole
