import React, {useEffect, useMemo, useState} from 'react'
import {useHistory} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperButton,
  KooperHeader,
  KooperLabel,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {ALLOW_USER_ACCESS, DELETE_USER, GET_USERS} from 'actions/types'
import {allowUserAccess, deleteUser, getUsers} from 'actions/settings'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import SettingLayout from 'layouts/settingLayout'
import create from 'assets/create-user.svg'
import {get, getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import {FiRefreshCcw} from 'react-icons/fi'
import {resendInvitation} from 'actions'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import {SETTINGS_PATH} from 'constants/path'
import UserForm from './UserForm'

const Users = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [createUser, setCreateUser] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [userIdToDelete, setUserIdToDelete] = useState(0)

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {getUsersList: {users = [], pendingUsers = []} = {}} = useSelector(state => state.settings)
  const quantity = useSelector(state =>
    get(['billing', 'currentSubscriptionData', 'subscription', 'quantity'], state, 1)
  )
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const usersLearnMore = settingsLearnMoreUrl?.myOrganization?.users?.learnMore

  const {isLoading} = useApiLoader({label: GET_USERS})
  const {myOrganizationDefaultPermissions} = useUserPermissions()

  const activatedUser = useMemo(() => {
    return users.reduce((acc, user) => {
      if (!user.isDisabled) {
        return (acc += 1)
      }
      return acc
    }, 0)
  }, [users])

  useEffect(() => {
    dispatch(getUsers({setting: true}))
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(DELETE_USER) || successLabels.includes(ALLOW_USER_ACCESS)) {
      dispatch(getUsers({setting: true}))
    }
  }, [successLabels, dispatch])

  if (isLoading) {
    return <PageLoader />
  }

  if (createUser) {
    return <UserForm toggleForm={setCreateUser} {...props} />
  }

  if ([...users, ...pendingUsers].length < 1) {
    return (
      <CreateFirst
        header="Create User"
        subHeader="Create and Manage your company assets easily and efficiently
      at one place."
        addFirst={() => myOrganizationDefaultPermissions && setCreateUser(true)}
        buttonText="Add User"
        tooltip={!myOrganizationDefaultPermissions}
        tooltipContent={getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})}
        imageSrc={create}
      />
    )
  }

  const renderAccessButton = user => {
    if (user.isDisabled) {
      if (quantity > activatedUser) {
        return (
          <LockPermissionTooltip isRoleAccessDenied={!myOrganizationDefaultPermissions}>
            <KooperButton
              primary
              className={!myOrganizationDefaultPermissions ? 'disabled-button' : ''}
              onClick={e => {
                e.stopPropagation()
                if (myOrganizationDefaultPermissions) {
                  dispatch(allowUserAccess(user.id))
                }
              }}
            >
              Active
            </KooperButton>
          </LockPermissionTooltip>
        )
      }
      return <KooperLabel color="orange">De-Activated</KooperLabel>
    }
    return <KooperLabel color="green">Activated</KooperLabel>
  }

  const renderUserList = () => {
    return [...users, ...pendingUsers].map((user, index) => {
      if (user.isVerified) {
        return (
          <KooperTableRow
            className="tableLink"
            onClick={() => history.push(`${SETTINGS_PATH.USERS}/${user.id}`)}
            key={user.id}
          >
            <KooperTableCell>{index + 1}</KooperTableCell>
            <KooperTableCell>
              <KooperHeader as="h5">
                {`${startCase(get(['firstName'], user, ''))} ${startCase(get(['lastName'], user, ''))}`}
                {get(['online'], user, '') && <KooperLabel circular color="green" empty size="mini" />}
              </KooperHeader>
            </KooperTableCell>
            <KooperTableCell>{get(['email'], user, '')}</KooperTableCell>
            <KooperTableCell>{startCase(get(['role'], user, ''))}</KooperTableCell>
            <KooperTableCell>{getFormattedDate(user.createdAt)}</KooperTableCell>
            <KooperTableCell>{renderAccessButton(user)}</KooperTableCell>
            <KooperTableCell>
              <KooperTooltip
                size="mini"
                position="top center"
                content={
                  myOrganizationDefaultPermissions
                    ? 'Delete'
                    : getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})
                }
                trigger={
                  <KooperButton
                    basic
                    icon
                    className="tableLink-delete-icon"
                    onClick={e => {
                      e.stopPropagation()
                      if (myOrganizationDefaultPermissions) {
                        setIsDelete(true)
                        setUserIdToDelete(user.id)
                      }
                    }}
                  >
                    <SvgIcon path="common/delete" />
                  </KooperButton>
                }
              />
            </KooperTableCell>
          </KooperTableRow>
        )
      }
      return (
        <KooperTableRow
          onClick={() => history.push(`/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.USERS}/${user.id}`)}
          className="tableLink"
          key={user.id}
        >
          <KooperTableCell>{index + 1}</KooperTableCell>
          <KooperTableCell className="prevCell">
            <KooperHeader as="h5" className="m-0">
              {`${startCase(get(['firstName'], user, ''))} ${startCase(get(['lastName'], user, ''))}`}
              <div className="PrevActPart">
                <KooperTooltip
                  position="top center"
                  content={
                    myOrganizationDefaultPermissions
                      ? 'Resend Invitation'
                      : getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})
                  }
                  size="mini"
                  trigger={
                    <KooperButton
                      icon
                      onClick={e => {
                        e.stopPropagation()
                        if (myOrganizationDefaultPermissions) {
                          dispatch(resendInvitation(user.id))
                        }
                      }}
                    >
                      <FiRefreshCcw />
                    </KooperButton>
                  }
                />
              </div>
            </KooperHeader>
          </KooperTableCell>
          <KooperTableCell>{get(['email'], user, '')}</KooperTableCell>
          <KooperTableCell>{startCase(get(['role'], user, ''))}</KooperTableCell>
          <KooperTableCell>{getFormattedDate(user.createdAt)}</KooperTableCell>
          <KooperTableCell>
            <KooperLabel color="yellow">Pending</KooperLabel>
          </KooperTableCell>
          <KooperTableCell>
            <KooperTooltip
              size="mini"
              position="top center"
              content={
                myOrganizationDefaultPermissions
                  ? 'Delete'
                  : getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})
              }
              trigger={
                <KooperButton
                  basic
                  icon
                  className="tableLink-delete-icon"
                  onClick={e => {
                    e.stopPropagation()
                    if (myOrganizationDefaultPermissions) {
                      setIsDelete(true)
                      setUserIdToDelete(user.id)
                    }
                  }}
                >
                  <SvgIcon path="common/delete" />
                </KooperButton>
              }
            />
          </KooperTableCell>
        </KooperTableRow>
      )
    })
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/users" />}
      header="Users"
      subHeader="Add and manage users within your organization"
      learnMoreUrl={usersLearnMore}
      headerButton={{
        onClick: () => myOrganizationDefaultPermissions && setCreateUser(true),
        content: 'Create User',
      }}
      table
      headerDivider
      lockRole={!myOrganizationDefaultPermissions}
    >
      <KooperTable basic selectable>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>#</KooperTableHeaderCell>
            <KooperTableHeaderCell>Name</KooperTableHeaderCell>
            <KooperTableHeaderCell>Email</KooperTableHeaderCell>
            <KooperTableHeaderCell>Role</KooperTableHeaderCell>
            <KooperTableHeaderCell>Created Date</KooperTableHeaderCell>
            <KooperTableHeaderCell>Status</KooperTableHeaderCell>
            <KooperTableHeaderCell>Action</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>
          {isLoadingData && type.includes(GET_USERS) ? <KooperTablePlaceholder columns={7} /> : renderUserList()}
        </KooperTableBody>
      </KooperTable>

      {isDelete && (
        <DeleteModal
          onDelete={() => {
            dispatch(deleteUser({ids: [userIdToDelete]}))
          }}
          isModalOpen={isDelete}
          setIsModalOpen={setIsDelete}
          modalText="Are you sure you want to delete user ?"
          type={DELETE_USER}
        />
      )}
    </SettingLayout>
  )
}
export default Users
