import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation, useParams} from 'react-router'
import {useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {KooperButton, KooperCard, KooperCardContent} from 'kooper-library'

import {
  CONTACT_TYPE_LIST,
  CREATE_AUTOMATION,
  GET_ALL_COMPANIES_IDS,
  GET_ALL_CONTACTS_IDS,
  GET_ALL_DEALS,
  GET_ALL_TAG_LABEL,
  GET_LOST_REASONS,
  GET_SINGLE_AUTOMATION,
  GET_SOURCES,
  GET_STATUS_LIST,
  GET_TAGS,
  GET_TEAMS_LIST,
  GET_USERS,
  UPDATE_AUTOMATION,
} from 'actions/types'
import {createAutomation, getSingleAutomation, updateAutomation} from 'actions/automations'
import {getLostReasons, getSources} from 'actions/custom_options'
import {getAllContactsIds, getContactType} from 'actions/contacts'
import {getAllCompaniesIds} from 'actions/company'
import {getAllDeals} from 'actions/deals'
import {getUsers} from 'actions/settings'
import {getTagLabel} from 'actions/multiple_fields'
import {getTags} from 'actions/settings_productivity'
import {get, getFormattedDate, startCase} from 'utils/helper'
import {countryCodeList} from 'constants/variables'
import {getStatusList} from 'actions/inbox'
import {getAllTeams} from 'actions/settings_organisation'
import {
  addActionSchema,
  addTriggerSchema,
  addActionPerformedOnLinkSchema,
  addActionQuoteSchema,
  addActionUserIdsSchema,
  addActionSendExternalSchema,
} from 'validation/automation.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {AUTOMATIONS_PATH} from 'constants/path'
import {operatorsOptions} from '../../../constants/automation'
import AutomationNavbar from './AutomationNavbar'
import AddTrigger from './AddTrigger'
import AddAction from './AddAction'

const addTriggerDefaultValues = {
  entity: '',
  trigger: '',
  triggerId: '',
  conditionsJson: {
    conditionsArray: [{field: '', operator: '', value: ''}],
    conditionsOperator: 'and',
    days: null,
  },
}

const addActionDefaultValues = {
  actionEntity: '',
  actionEvent: '',
  actionJson: {
    performOnlinked: false,
    // actionsArray: [{field: '', value: ''}],
    actionsArray: [],
  },
}

const AutomationBuilder = () => {
  const location = useLocation()
  const {name, description} = location.state
  const dispatch = useDispatch()
  const history = useHistory()
  const {id} = useParams()

  const [automationData, setAutomationData] = useState({})
  const [addTrigger, setAddTrigger] = useState(false)
  const [trigger, setTrigger] = useState('')
  const [stepList, setStepList] = useState([])
  const [insertAt, setInsertAt] = useState(0)
  const [editData, setEditData] = useState(null)
  const [addAction, setAddAction] = useState(false)
  const [actionEntity, setActionEntity] = useState('')
  const [actionEvent, setActionEvent] = useState('')

  const [dropdownData, setDropdownData] = useState({
    sourceList: [],
    contactList: [],
    companyList: [],
    dealStageList: [],
    usersList: [],
    tagLabelData: [],
    lossReasonList: [],
    countryCodeUpdated: [],
    contactTypeOptionList: [],
    tagsList: [],
    statusList: [],
    teamList: [],
  })
  const [searchValue, setSearchValue] = useState([])

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {singleAutomationData = {}} = useSelector(state => state.automations)

  const {
    handleSubmit: handleSubmit1,
    control: control1,
    errors: errors1,
    clearErrors: clearErrors1,
    watch,
    setValue: setValue1,
    reset: reset1,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: addTriggerDefaultValues,
    resolver: joiResolver(addTriggerSchema(trigger)),
  })
  const watchEntity = watch('entity')
  const watchTrigger = watch('trigger')
  const watchTriggerId = watch('triggerId')

  const actionSchema = () => {
    if (Array.isArray(actionEvent.match('delete'))) {
      return addActionPerformedOnLinkSchema
    }
    if (['send_internal_email', 'in_app_notify'].includes(actionEvent)) {
      return addActionUserIdsSchema
    }
    if (actionEvent === 'send_external_email') {
      return addActionSendExternalSchema
    }
    if (actionEntity === 'quote') {
      return addActionQuoteSchema
    }
    return addActionSchema
  }

  const {
    handleSubmit: handleSubmit2,
    control: control2,
    errors: errors2,
    clearErrors: clearErrors2,
    watch: watch2,
    setValue: setValue2,
    reset: reset2,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: addActionDefaultValues,
    resolver: joiResolver(actionSchema()),
  })

  const watchActionEntity = useMemo(() => {
    return watch2('actionEntity')
  }, [watch2])

  const watchActionEvent = useMemo(() => {
    return watch2('actionEvent')
  }, [watch2])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setDropdownData(oldData => ({...oldData, usersList: list || []}))
    },
  })

  useApiResponse({
    action: getAllTeams,
    enabled: true,
    label: GET_TEAMS_LIST,
    storePath: 'settingsOrganisation.teamsList',
    onSuccess: teamsList => {
      const list = teamsList.map(team => ({
        key: team.id,
        value: team.id,
        text: team.name,
      }))
      setDropdownData(dropdownData => ({...dropdownData, teamList: list || []}))
    },
  })

  useApiResponse({
    action: getTagLabel,
    enabled: true,
    label: GET_ALL_TAG_LABEL,
    storePath: 'multipleFields.getTagList',
    onSuccess: getTagList => {
      const list = getTagList?.map(({id, label}) => ({key: id, value: id, text: label}))
      setDropdownData(oldData => ({...oldData, tagLabelData: list || []}))
    },
  })

  useApiResponse({
    action: getAllCompaniesIds,
    enabled: true,
    label: GET_ALL_COMPANIES_IDS,
    storePath: 'companies.companiesIdsList',
    onSuccess: companiesIdsList => {
      const list = companiesIdsList?.map(({id, name}) => ({key: id, value: id, text: name}))
      setDropdownData(oldData => ({...oldData, companyList: list || []}))
    },
  })

  useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
    onSuccess: contactsIdsList => {
      const list = contactsIdsList.map(({id, name}) => ({
        key: id,
        value: id,
        text: name,
      }))
      setDropdownData(oldData => ({...oldData, contactList: list}))
    },
  })

  useApiResponse({
    action: getContactType,
    enabled: true,
    label: CONTACT_TYPE_LIST,
    storePath: 'contacts.getTypeListData',
    onSuccess: getTypeListData => {
      const list = getTypeListData?.map(({id, type}) => ({key: id, value: id, text: type}))
      setDropdownData(oldData => ({...oldData, contactTypeOptionList: list || []}))
    },
  })

  useApiResponse({
    action: getSources,
    enabled: true,
    label: GET_SOURCES,
    storePath: 'custom_option.sourcesData',
    onSuccess: sourcesData => {
      const list = sourcesData?.map(({id, source}) => ({key: id, value: id, text: source}))
      setDropdownData(oldData => ({...oldData, sourceList: list || []}))
    },
  })

  useApiResponse({
    action: getAllDeals,
    enabled: true,
    label: GET_ALL_DEALS,
    storePath: 'deals.getAllDealsList',
    onSuccess: getAllDealsList => {
      const list = getAllDealsList?.stages?.map(({id, stage}) => ({key: id, value: id, text: stage}))
      setDropdownData(oldData => ({...oldData, dealStageList: list || []}))
    },
  })

  useApiResponse({
    action: getLostReasons,
    enabled: true,
    label: GET_LOST_REASONS,
    storePath: 'custom_option.lostReasonsData',
    onSuccess: lostReasonsData => {
      const list = lostReasonsData?.map(({id, reason}) => ({key: id, value: id, text: reason}))
      setDropdownData(oldData => ({...oldData, lossReasonList: list || []}))
    },
  })

  useApiResponse({
    action: getTags,
    enabled: true,
    label: GET_TAGS,
    storePath: 'settingsProductivity.tagList',
    onSuccess: tagList => {
      const list = tagList?.map(({id, label}) => ({key: id, value: id, text: label}))
      setDropdownData(oldData => ({...oldData, tagsList: list || []}))
    },
  })

  useApiResponse({
    action: getStatusList,
    enabled: true,
    label: GET_STATUS_LIST,
    storePath: 'emailInbox.statusList',
    onSuccess: statusList => {
      const list = statusList.map(({id, status}) => ({key: id, value: id, text: startCase(status)}))
      setDropdownData(dropdownData => ({...dropdownData, statusList: list || []}))
    },
  })

  useEffect(() => {
    const list = countryCodeList.map(item => ({
      key: `${item.name}`,
      value: item.name,
      flag: item.code.toLowerCase(),
      text: `${item.name}`,
      content: `${item.name}`,
    }))
    setDropdownData(oldData => ({...oldData, countryCodeUpdated: list}))
  }, [])

  useEffect(() => {
    if (watchTrigger) setTrigger(watchTrigger)
  }, [watchTrigger])

  useEffect(() => {
    if (watchActionEntity) setActionEntity(watchActionEntity)
  }, [watchActionEntity])

  useEffect(() => {
    if (watchActionEvent) setActionEvent(watchActionEvent)
  }, [watchActionEvent])

  useEffect(() => {
    setAutomationData(automationData => ({
      name,
      description,
      ...automationData,
    }))
  }, [name, description])

  useEffect(() => {
    if (singleAutomationData.actionEntity === 'quote') {
      setSearchValue(
        singleAutomationData?.actionJson?.products ? JSON.parse(singleAutomationData?.actionJson?.products) : []
      )
    }
    if (
      singleAutomationData.actionEntity === watchActionEntity &&
      singleAutomationData.actionEvent === watchActionEvent
    ) {
      reset2({
        actionEntity: watchActionEntity,
        actionEvent: watchActionEvent,
        actionJson: {
          ...(!['quote', 'communication'].includes(watchActionEntity) && {
            performOnlinked: singleAutomationData?.actionJson?.performOnlinked || false,
          }),
          ...(!['quote', 'communication'].includes(watchActionEntity) && {
            actionsArray: singleAutomationData?.actionJson?.actionsArray || [{field: '', value: ''}],
          }),
          ...(watchActionEntity === 'quote' && {name: singleAutomationData?.actionJson?.name}),
          ...(watchActionEntity === 'quote' &&
            ['update_quote', 'delete_quote'].includes(watchActionEvent) && {
              performOnlinked: singleAutomationData?.actionJson?.performOnlinked || false,
            }),
        },
      })
    } else if (watchActionEvent === 'create_deal') {
      reset2({
        actionEntity: watchActionEntity,
        actionEvent: watchActionEvent,
        actionJson: {
          performOnlinked: false,
          actionsArray: [
            {
              field: 'name',
              value: '',
            },
            {
              field: 'stageId',
              value: '',
            },
            {
              field: 'assigneeId',
              value: '',
            },
            {
              field: '',
              value: '',
            },
          ],
        },
      })
    } else if (watchActionEvent === 'create_contact') {
      reset2({
        actionEntity: watchActionEntity,
        actionEvent: watchActionEvent,
        actionJson: {
          performOnlinked: false,
          actionsArray: [
            {
              field: 'firstName',
              value: '',
            },
            {
              field: 'lastName',
              value: '',
            },
          ],
        },
      })
    } else if (watchActionEvent === 'create_company') {
      reset2({
        actionEntity: watchActionEntity,
        actionEvent: watchActionEvent,
        actionJson: {
          performOnlinked: false,
          actionsArray: [
            {
              field: 'name',
              value: '',
            },
          ],
        },
      })
    } else if (watchActionEntity === 'quote') {
      reset2({
        actionEntity: watchActionEntity,
        actionEvent: watchActionEvent,
        actionJson: {
          name: '',
          ...(['update_quote', 'delete_quote'].includes(watchActionEvent) && {performOnlinked: false}),
        },
      })
    } else if (watchActionEntity === 'task') {
      reset2({
        actionEntity: watchActionEntity,
        actionEvent: watchActionEvent,
        actionJson: {
          performOnlinked: false,
          actionsArray: [
            {field: 'subject', value: ''},
            {field: 'type', value: ''},
            {field: 'priority', value: ''},
            {field: 'duration', value: ''},
            {field: 'callType', value: ''},
          ],
        },
      })
    } else if (watchActionEntity === 'communication') {
      reset2({
        actionEntity: watchActionEntity,
        actionEvent: watchActionEvent,
        actionJson: {
          userIds: [],
          subject: '',
          body: '',
        },
      })
    } else {
      reset2({
        actionEntity: watchActionEntity,
        actionEvent: watchActionEvent,
        actionJson: {
          performOnlinked: false,
          actionsArray: [{field: '', value: ''}],
        },
      })
    }
  }, [watchActionEntity, reset2, watchActionEvent, JSON.stringify(singleAutomationData)])

  useEffect(() => {
    if (successLabels.includes(CREATE_AUTOMATION) || successLabels.includes(UPDATE_AUTOMATION)) {
      history.push(`/${AUTOMATIONS_PATH.PREFIX}`)
    }
  }, [successLabels, history])

  useEffect(() => {
    if (id) dispatch(getSingleAutomation(id))
  }, [id, dispatch])

  const actionStep = useCallback(
    data => {
      const {
        actionJson: {actionsArray = []},
      } = data

      if (data.actionEvent.match('delete')) {
        return `performOnlinked is ${data.actionJson.performOnlinked}`
      }
      if (['create_quote', 'update_quote'].includes(data.actionEvent)) {
        return `Quote name is ${data.actionJson.name}`
      }
      if (['send_internal_email', 'in_app_notify'].includes(data.actionEvent)) {
        const users = dropdownData.usersList
          .filter(user => data.actionJson.userIds.some(id => user.value === id))
          .map(user => user.text)
        return `User is ${users.join(', ')}`
      }
      if (data.actionEvent === 'send_external_email') {
        return `Subject is ${data.actionJson.subject}`
      }
      return actionsArray.map(action => {
        if (
          [
            'company',
            'sourceId',
            'assigneeId',
            'contactTypeId',
            'labelIds',
            'stageId',
            'type',
            'priority',
            'callType',
            'statusId',
            'addLabelIds',
            'removeLabelIds',
            'mailboxId',
            'teamId',
            'tag',
            'status',
          ].includes(action.field)
        ) {
          return <p>{`${startCase(action.field)} is ${action.value}`}</p>
        }
        if (
          [
            'name',
            'domain',
            'monetary Value',
            'description',
            'firstName',
            'lastName',
            'email',
            'phone',
            'title',
            'subject',
            'duration',
            'note',
          ].includes(action.field)
        ) {
          return <p>{`${startCase(action.field)} is ${action.value}`}</p>
        }
        if (action.field === 'deleteConversation') {
          return <p>{`${startCase(action.field)}`}</p>
        }
      })
    },
    [dropdownData.usersList]
  )

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_AUTOMATION)) {
      const data = _.omit(singleAutomationData, [
        'id',
        'accountId',
        'createdBy',
        'createdAt',
        'updatedBy',
        'updatedAt',
        'isActive',
        'deletedAt',
      ])
      setAutomationData(data)

      const {
        conditionsJson: {conditionsArray = [], days},
      } = singleAutomationData

      setStepList([
        {
          step: 0,
          data: (
            <div>
              <p>
                {startCase(singleAutomationData.entity)} {startCase(singleAutomationData.trigger)}
              </p>
              {['open_inactive_for', 'inactive_for', 'new_inactive_for'].includes(singleAutomationData.trigger) && (
                <p>{`${startCase(singleAutomationData.trigger)} : ${days} days`}</p>
              )}
              {conditionsArray.map(condition => {
                if (
                  [
                    'sourceId',
                    'priority',
                    'contactId',
                    'companyId',
                    'stageId',
                    'assigneeId',
                    'labelIds',
                    'lossReason',
                    'country',
                    'contactTypeId',
                    'status',
                    'type',
                    'haveTags',
                    'doNotHaveTags',
                  ].includes(condition.field)
                ) {
                  return (
                    <p>{`${startCase(condition.field)} ${
                      operatorsOptions.find(operator => operator.value === condition.operator)?.text
                    } ${condition.value}`}</p>
                  )
                }
                if (
                  [
                    'name',
                    'description',
                    'monetaryValue',
                    'firstName',
                    'lastName',
                    'title',
                    'interactions',
                    'domain',
                    'prefixId',
                    'validTill',
                    'Name',
                    'total',
                    'meetingName',
                    'meetingHeading',
                    'conversionRate',
                    'repeatContact',
                    'pageUrlMatch',
                    'pageUrlContain',
                    'referralUrl',
                    'referralDomain',
                    'contactEmailDomain',
                    'subjectLike',
                    'contactEmail',
                    'company',
                  ].includes(condition.field)
                ) {
                  return (
                    <p>{`${startCase(condition.field)} ${
                      operatorsOptions.find(operator => operator.value === condition.operator)?.text
                    } ${condition.value}`}</p>
                  )
                }
                if (['conversationstatus', 'newConversation'].includes(condition.field)) {
                  return (
                    <p>{`${startCase(condition.field)} ${
                      operatorsOptions.find(operator => operator.value === condition.operator)?.text
                    } ${condition.value.time} ${condition.value.unit}`}</p>
                  )
                }
                if (condition.field === 'closeDate') {
                  return (
                    <p>
                      {`${startCase(condition.field)} ${
                        operatorsOptions.find(operator => operator.value === condition.operator)?.text
                      } ${getFormattedDate(condition.value)}`}
                    </p>
                  )
                }
                if (condition.field === 'win') {
                  return (
                    <p>{`${startCase(condition.field)} ${
                      operatorsOptions.find(operator => operator.value === condition.operator)?.text
                    } ${condition.value}%`}</p>
                  )
                }
              })}
            </div>
          ),
        },
        {
          step: 1,
          data: (
            <div>
              <p>{startCase(singleAutomationData.actionEvent)}</p>
              {actionStep(singleAutomationData)}
            </div>
          ),
        },
      ])

      reset1({
        entity: singleAutomationData.entity,
        trigger: singleAutomationData.trigger,
        triggerId: singleAutomationData.triggerId,
        conditionsJson: {
          days: get(['conditionsJson', 'days'], singleAutomationData, null),
          conditionsOperator: get(['conditionsJson', 'conditionsOperator'], singleAutomationData, 'and'),
          conditionsArray: get(['conditionsJson', 'conditionsArray'], singleAutomationData, [
            {field: '', operator: '', value: ''},
          ]),
        },
      })
      reset2({
        actionEntity: singleAutomationData.actionEntity,
        actionEvent: singleAutomationData.actionEvent,
        actionJson: singleAutomationData.actionJson,
      })
    }
  }, [successLabels, singleAutomationData, actionStep, reset1, reset2])

  const handleAdd = data => {
    const steps = [...stepList]
    if (!editData) {
      steps.splice(insertAt, 0, {
        step: insertAt,
        data,
      })
      steps.map((item, index) => {
        if (index > insertAt) {
          item.step += 1
        }
      })
    } else {
      steps[insertAt].data = data
    }
    setStepList(steps)
    if (id) {
      setEditData(data)
    } else {
      setEditData(null)
    }
  }

  const onSubmitAutomation = () => {
    const payload = _.omitBy(automationData, value => value === '' || !value)
    if (['open_inactive_for', 'inactive_for', 'new_inactive_for'].includes(payload.trigger)) {
      delete payload.conditionsJson.conditionsArray
      delete payload.conditionsJson.conditionsOperator
    } else {
      delete payload.conditionsJson.days
    }

    if (id) {
      dispatch(updateAutomation(id, payload))
    } else {
      dispatch(createAutomation(payload))
    }
  }

  return (
    <>
      <div className="kooper-full-page">
        <AutomationNavbar
          automationData={automationData}
          setAutomationData={setAutomationData}
          onSubmitAutomation={onSubmitAutomation}
        />
        <KooperCard fluid style={{width: '60%', margin: '20px auto'}}>
          <KooperCardContent style={{margin: '0px auto'}}>
            <div className="seqFlow">
              {stepList.map((step, index) => (
                <React.Fragment key={step.step}>
                  {index > 0 && (
                    <div className="addBtn">
                      <KooperButton circular disabled>
                        <SvgIcon path="common/plus" />
                      </KooperButton>
                    </div>
                  )}
                  <div className="seqBox">
                    <div className="seqHeader">
                      <div className="info">
                        Step {index + 1} : {index === 0 ? 'Trigger' : 'Action'}
                      </div>
                      <div className="action">
                        <KooperButton
                          onClick={() => {
                            if (index === 0) {
                              setEditData(step.data)
                              setAddTrigger(true)
                            }
                            if (index === 1) {
                              setEditData(step.data)
                              setAddAction(true)
                            }
                            setInsertAt(index)
                          }}
                        >
                          <SvgIcon path="common/edit2" />
                        </KooperButton>
                      </div>
                    </div>
                    <div className="seqBody">{step.data}</div>
                  </div>
                </React.Fragment>
              ))}
              {stepList.length === 0 && (
                <KooperButton primary onClick={() => setAddTrigger(true)}>
                  Create Automation
                </KooperButton>
              )}
              <div className="addBtn">
                {stepList.length > 0 && (
                  <KooperButton
                    circular
                    onClick={() => {
                      if (stepList.length === 1) {
                        setAddAction(true)
                        setInsertAt(stepList.length)
                      }
                    }}
                    disabled={stepList.length === 2}
                  >
                    <SvgIcon path="common/plus" />
                  </KooperButton>
                )}
                {stepList.length > 0 && <h5 className="pb-3">End</h5>}
              </div>
            </div>
          </KooperCardContent>
        </KooperCard>
      </div>

      {addTrigger && (
        <AddTrigger
          open={addTrigger}
          toggle={setAddTrigger}
          handleSubmit={handleSubmit1}
          control={control1}
          errors={errors1}
          clearErrors={clearErrors1}
          setValue={setValue1}
          watchEntity={watchEntity}
          watchTrigger={watchTrigger}
          watchTriggerId={watchTriggerId}
          handleAdd={handleAdd}
          setAutomationData={setAutomationData}
          watch={watch}
          dropdownData={dropdownData}
          setEditData={setEditData}
        />
      )}

      {addAction && (
        <AddAction
          open={addAction}
          toggle={setAddAction}
          handleSubmit={handleSubmit2}
          control={control2}
          errors={errors2}
          clearErrors={clearErrors2}
          watchActionEntity={watchActionEntity}
          watchActionEvent={watchActionEvent}
          automationData={automationData}
          watch={watch2}
          setValue={setValue2}
          handleAdd={handleAdd}
          setAutomationData={setAutomationData}
          dropdownData={dropdownData}
          setEditData={setEditData}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      )}
    </>
  )
}

export default AutomationBuilder
