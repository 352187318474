import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {DateRange} from 'react-date-range'
import {FiRotateCcw, FiChevronRight} from 'react-icons/fi'
import moment from 'moment'
import {
  KooperAccordion,
  KooperAccordionContent,
  KooperAccordionTitle,
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperDivider,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperHeader,
  KooperHeaderContent,
  KooperHeaderSubheader,
  KooperIcon,
  KooperList,
  KooperListContent,
  KooperListItem,
  KooperMenuItem,
  KooperModalContent,
  KooperTooltip,
} from 'kooper-library'
import 'react-date-range/dist/theme/default.css'
import 'react-date-range/dist/styles.css' // main style fil
import {CLEAR_ACTIVITY_FEED, GET_ACTIVITY_FEED} from 'actions/types'
import {getActivityFeed} from 'actions/inbox'
import {AllEvent, eventList} from 'constants/variables'
import {ListPlaceholder} from 'components/common/InboxPlaceholders'
import SvgIcon from 'components/common/SvgIcon'

export default function ActivityFeed() {
  const dispatch = useDispatch()
  const eventRef = useRef()

  const [eventPayload, setEventPayload] = useState([...AllEvent])
  const [activeDD, setActiveDD] = useState(false)
  const [activeSDD, setActiveSDD] = useState(false)
  const [pageScroll, setPageScroll] = useState(1)

  const [currentDate, setCurrentDate] = useState({
    from: moment().startOf('d').subtract(1, 'w'),
    to: moment().toDate(),
  })

  const [activeIndex, setActiveIndex] = useState({
    email: false,
    sms: false,
    task: false,
    // sequences: false,
    // meetings: false,
  })

  const {getActivityFeedData = {}} = useSelector(state => state.mailInbox)
  const {isLoadingData, type} = useSelector(state => state.apiReducer)

  useEffect(() => {
    const onClickEvent = event => {
      if (eventRef.current && eventRef.current.contains(event.target)) return
      setActiveDD(false)
    }
    document.body.addEventListener('click', onClickEvent)

    return () => {
      document.body.removeEventListener('click', onClickEvent)
    }
  }, [])

  const apiCall = () => {
    if (activeSDD) setActiveSDD(false)
    const payload = {
      page: pageScroll,
      activities: eventPayload,
      from: currentDate.from.toISOString(),
      to: currentDate.to.toISOString(),
    }
    dispatch(getActivityFeed(payload))
  }

  useEffect(() => {
    apiCall()
  }, [dispatch, eventPayload, pageScroll])

  useEffect(() => {
    dispatch({type: CLEAR_ACTIVITY_FEED})
    setPageScroll(1)
  }, [dispatch, eventPayload])

  useEffect(() => {
    return () => {
      dispatch({type: CLEAR_ACTIVITY_FEED})
    }
  }, [dispatch])

  const getActivityFeedIcon = activityType => {
    if (activityType.includes('emails')) return 'mail'
    if (activityType.includes('sms')) return 'comments outline'
    if (activityType.includes('task')) return 'tasks'
    // if (activityType.includes('meetings')) return 'calendar alternate outline'
    // if (activityType.includes('sequences')) return 'check circle'
    return 'check circle'
  }

  const getActivityFeedContent = (activityType, list) => {
    if (activityType === 'emails.received') {
      return `Email Received with Subject: ${list?.subject}`
    }
    if (activityType === 'emails.sent') {
      return `Email Sent By  ${list?.agent} with Subject: ${list?.subject} ${
        list?.sequenceId ? `for step#${list?.step} of Failed Email Sequence` : ''
      }`
    }
    if (activityType === 'emails.replied') {
      return `Email Replied with Subject: ${list?.subject} ${
        list?.sequenceId ? `for step#${list?.step} of Failed Email Sequence` : ''
      }`
    }
    if (activityType === 'emails.autoReplied') {
      return `Email Auto Replied By  ${list?.agent} with Subject: ${list?.subject}`
    }
    if (activityType === 'emails.assigneeUpdated') {
      return `Email Assignee Updated By  ${list?.agent} with Subject: ${list?.subject}`
    }
    if (activityType === 'emails.opened') {
      return `Email Opened By  ${list?.agent} with Subject: ${list?.subject}`
    }
    if (activityType === 'emails.clicked') {
      return `Email Clicked By  ${list?.agent} with Subject: ${list?.subject}`
    }
    if (activityType === 'sms.received') {
      return `SMS Received with Subject: ${list?.subject}`
    }
    if (activityType === 'sms.sent') {
      return `SMS Sent By  ${list?.agent} with Subject: ${list?.subject} ${
        list?.sequenceId ? `for step#${list?.step} of Failed SMS Sequence` : ''
      }`
    }
    if (activityType === 'sms.replied') {
      return `SMS Replied with Subject: ${list?.subject} ${
        list?.sequenceId ? `for step#${list?.step} of Failed SMS Sequence` : ''
      }`
    }
    if (activityType === 'sms.assigneeUpdated') {
      return `SMS Assignee Updated By  ${list?.agent} with Subject: ${list?.subject}`
    }
    if (activityType === 'task.created') {
      return `Task was Created By ${list?.agent} with Subject: ${list?.subject}`
    }
    if (activityType === 'task.updated') {
      return `Task was Updated By ${list?.agent} with Subject: ${list?.subject}`
    }
    if (activityType === 'task.completed') {
      return `Task was Completed By ${list?.agent} with Subject: ${list?.subject}`
    }
    if (activityType === 'task.assigneeUpdated') {
      return `Task was Assignee Updated By ${list?.agent} to ${list?.assignee} with Subject: ${list?.subject}`
    }
    if (activityType === 'meetings.booked') {
      return `Meetings was Booked By ${list?.agent}`
    }
    // if (activityType === 'sequences.created') {
    //   return `Sequences was Created By ${list?.agent} with Sequence Name: ${list?.sequenceName}`
    // }
    // if (activityType === 'sequences.started') {
    //   return `Sequences was Started By ${list?.agent} with Sequence Name: ${list?.sequenceName}`
    // }
    return `No Data Available`
  }

  const getActivityFeedList = () => {
    if (isLoadingData && type.includes(GET_ACTIVITY_FEED) && pageScroll === 1) {
      return <ListPlaceholder />
    }
    if (!getActivityFeedData?.data?.length) return <h3 className="live-feed-no-data">No Data Available</h3>

    return getActivityFeedData?.data?.map((list, i) => (
      <KooperHeader as="h5" key={i}>
        <KooperIcon name={getActivityFeedIcon(list.activityType)} />
        <KooperHeaderContent>{getActivityFeedContent(list.activityType, list)}</KooperHeaderContent>
        <KooperHeaderSubheader>
          {moment(list?.createdAt).format('MMMM Do YYYY, h:mm a') ||
            moment(list?.startedAt).format('MMMM Do YYYY, h:mm a') ||
            moment(list?.bookedAt).format('MMMM Do YYYY, h:mm a') ||
            moment(list?.openedAt).format('MMMM Do YYYY, h:mm a')}
        </KooperHeaderSubheader>
      </KooperHeader>
    ))
  }

  const clickSingelEvent = eventOption => {
    setActiveDD(true)
    if (eventPayload.includes(eventOption.value)) {
      setEventPayload(eventPayload.filter(data => data !== eventOption.value))
      return
    }
    setEventPayload(prv => [...prv, eventOption.value])
  }

  const clickAllEvent = () => {
    if (eventPayload?.length === 18) {
      setEventPayload([])
      return
    }
    setEventPayload([...AllEvent])
  }

  const onScroll = e => {
    if (!getActivityFeedData?.data?.length) return
    if (
      e.target.scrollTop + e.target.clientHeight + pageScroll >= e.target.scrollHeight &&
      !(isLoadingData && type.includes(GET_ACTIVITY_FEED))
    ) {
      setPageScroll(prv => prv + 1)
    }
  }

  return (
    <div className="live-feed-modal" style={{background: '#F9FBFD'}}>
      <div className="pageHeader px-0">
        <div className="pageTitle">
          <div className="pageTitleIcon">
            <SvgIcon path="navigation/activities-feed" />
            <h5>Activity Feed</h5>
          </div>
        </div>
      </div>
      <KooperModalContent
        className="kooper-modal-body"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        scrolling
        onScroll={onScroll}
      >
        <div className="d-flex">
          <div className="mailActivity">
            <KooperDropdown
              icon="chevron down"
              button
              text="Event"
              direction="right"
              ref={eventRef}
              open={activeDD}
              onClick={() => setActiveDD(!activeDD)}
            >
              <KooperDropdownMenu className="live-feed-modal-dd">
                <div className="d-flex">
                  All
                  <KooperCheckbox checked={eventPayload?.length === 15} onChange={() => clickAllEvent()} />
                </div>
                <KooperDivider className="mb-0" />
                {eventList.map(({value, label, option}, i) => (
                  <KooperAccordion vertical key={i}>
                    <KooperMenuItem>
                      <KooperAccordionTitle
                        active={activeIndex[value]}
                        onClick={() => {
                          setActiveIndex({
                            ...activeIndex,
                            [value]: !activeIndex[value],
                          })
                          setActiveDD(true)
                        }}
                      >
                        <span>
                          {activeIndex[value] ? <SvgIcon path="common/down" /> : <FiChevronRight />}
                          {label}
                        </span>
                      </KooperAccordionTitle>
                      <KooperAccordionContent active={activeIndex[value]}>
                        <KooperList>
                          {option.map((eventOption, i) => (
                            <KooperListItem className="d-flex" key={i}>
                              {eventOption.label}
                              <KooperListContent floated="right">
                                <KooperCheckbox
                                  checked={eventPayload.includes(eventOption.value)}
                                  onChange={() => clickSingelEvent(eventOption)}
                                />
                              </KooperListContent>
                            </KooperListItem>
                          ))}
                        </KooperList>
                      </KooperAccordionContent>
                    </KooperMenuItem>
                  </KooperAccordion>
                ))}
              </KooperDropdownMenu>
            </KooperDropdown>
            <KooperDropdown
              icon="chevron down"
              button
              text={
                moment(currentDate.from).format('DDMMYYYY') !== moment(currentDate.to).format('DDMMYYYY')
                  ? `${moment(currentDate.from).format('DD MMM, YYYY')} to ${moment(currentDate.to).format(
                      'DD MMM, YYYY'
                    )}`
                  : moment(currentDate.to).format('DD MMM, YYYY')
              }
              direction="right"
              className="data-pick ml-3"
              open={activeSDD}
              onClick={() => setActiveSDD(!activeSDD)}
            >
              <KooperDropdownMenu>
                <KooperDropdownItem onClick={e => e.stopPropagation()}>
                  <DateRange
                    moveRangeOnFirstSelection={false}
                    ranges={[
                      {
                        startDate: new Date(currentDate.from),
                        endDate: new Date(currentDate.to),
                        key: 'selection',
                      },
                    ]}
                    onChange={value => {
                      setCurrentDate({
                        from: moment(value.selection.startDate),
                        to: moment(value.selection.endDate),
                      })
                    }}
                  />
                </KooperDropdownItem>
                <div className="action">
                  <KooperButton className="mr-3" basic onClick={() => setActiveSDD(false)}>
                    Close
                  </KooperButton>
                  <KooperButton primary onClick={apiCall}>
                    Apply
                  </KooperButton>
                </div>
              </KooperDropdownMenu>
            </KooperDropdown>
          </div>
          <KooperTooltip
            position="left center"
            content="Refresh"
            size="mini"
            trigger={
              <KooperButton
                className="ml-3"
                icon
                onClick={() => {
                  setPageScroll(1)
                  setEventPayload([...AllEvent])
                  setCurrentDate({
                    from: moment().startOf('d').subtract(1, 'w'),
                    to: moment().toDate(),
                  })
                }}
              >
                <FiRotateCcw />
              </KooperButton>
            }
          />
        </div>
        <KooperCard fluid style={{width: '70%'}}>
          <KooperCardContent>{getActivityFeedList()}</KooperCardContent>
        </KooperCard>
      </KooperModalContent>
    </div>
  )
}
