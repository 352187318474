import SvgIcon from 'components/common/SvgIcon'
import {
  KooperCard,
  KooperCardContent,
  KooperCardDescription,
  KooperCardGroup,
  KooperCardMeta,
  KooperHeader,
  KooperIcon,
  KooperModal,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'
import {useHistory} from 'react-router-dom'

const PersonalGoalSelectModal = ({open, onClose}) => {
  const history = useHistory()
  return (
    <KooperModal
      open={open}
      onClose={onClose}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="medium"
    >
      <KooperModalHeader>What do you want to accomplish?</KooperModalHeader>
      <KooperModalContent>
        <KooperCardGroup>
          <KooperCard onClick={() => history.push(`/goals/create/personal/contribute`)} fluid>
            <KooperCardContent>
              <div className="d-flex">
                <div>
                  <KooperHeader as="h3">Contribute to a team goal</KooperHeader>
                  <KooperCardDescription style={{color: 'black'}}>
                    Set goals related to your team's objectives.
                  </KooperCardDescription>
                  <KooperCardMeta style={{marginTop: '10px'}}>
                    <KooperIcon name="users" /> Visible to everyone
                  </KooperCardMeta>
                </div>
                <div>
                  <KooperIcon name="chart line" size="huge" style={{float: 'right', color: 'grey'}} />
                </div>
              </div>
            </KooperCardContent>
          </KooperCard>
          <KooperCard onClick={() => history.push(`/goals/create/personal`)} fluid>
            <KooperCardContent>
              <div className="d-flex">
                <div>
                  <KooperHeader as="h3">Develop myself</KooperHeader>
                  <KooperCardDescription style={{color: 'black'}}>
                    Set goals to develop your skills and enrich yourself.
                  </KooperCardDescription>
                  <KooperCardMeta style={{marginTop: '10px'}}>
                    <KooperIcon name="user" /> Visible to select managers
                  </KooperCardMeta>
                </div>
                <div>
                  {' '}
                  <KooperIcon name="leaf" size="huge" style={{float: 'right', color: 'grey'}} />
                </div>
              </div>
            </KooperCardContent>
          </KooperCard>
        </KooperCardGroup>
      </KooperModalContent>
    </KooperModal>
  )
}

export default PersonalGoalSelectModal
