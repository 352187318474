/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import create from 'assets/create.svg'
import {useHistory} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  KooperButton,
  KooperDivider,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperGrid,
  KooperGridColumn,
  KooperGridRow,
  KooperHeader,
  KooperIcon,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {DELETE_EMPLOYEES, GET_ALL_EMPLOYEES, GET_DEPARTMENT, GET_USERS} from 'actions/types'
import {getAllEmployees, deleteEmployee, getDepartment, resendEmployeeInvitation} from 'actions/employees'
import {getUsers} from 'actions/settings'
import SearchComponent from 'components/common/SearchComponent'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import {startCase} from 'utils/helper'
import EmployeeSidemenu from 'navigation/sub-routes/Hr/Employees/EmployeeMenu'
import {employeeSummary} from 'constants/hr/employees'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import SettingLayout from 'layouts/settingLayout'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {EMPLOYEES_PATH} from 'constants/path'

const Employees = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [departmentList, setDepartmentList] = useState([])
  const [managerList, setManagerList] = useState([])
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    department: '',
    manager: '',
    sortBy: '',
    sortType: '',
    text: '',
  })

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_ALL_EMPLOYEES})

  const {data: allEmployeesList = []} = useApiResponse({
    action: getAllEmployees,
    enabled: true,
    label: GET_ALL_EMPLOYEES,
    storePath: 'employees.allEmployeesList',
  })

  useApiResponse({
    action: getDepartment,
    enabled: true,
    label: GET_DEPARTMENT,
    storePath: 'employees.getDepartmentData',
    onSuccess: getDepartmentData => {
      const list = getDepartmentData.map(department => ({
        value: department.id,
        text: department.department,
        id: department.id,
      }))
      setDepartmentList(list)
    },
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setManagerList(list)
    },
  })

  useEffect(() => {
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getAllEmployees(newCurrentListParams))
  }, [currentListParams, dispatch])

  useEffect(() => {
    if (successLabels.includes(DELETE_EMPLOYEES)) {
      dispatch(getAllEmployees())
    }
  }, [successLabels, dispatch])

  const renderTableBody = () => {
    return allEmployeesList.map(
      (
        {
          id,
          firstName,
          lastName,
          employeeID,
          workEmail,
          title,
          department,
          manager,
          createdBy,
          updatedBy,
          allowPortalAccess,
          isOnboard,
        },
        index
      ) => {
        return (
          <KooperTableRow
            key={id}
            className="tableLink"
            onClick={() => {
              history.push(`/${EMPLOYEES_PATH.PREFIX}/${EMPLOYEES_PATH.UPDATE}/${id}`)
            }}
          >
            <KooperTableCell>{index + 1}</KooperTableCell>
            <KooperTableCell>
              <KooperHeader as="h5">{startCase(`${firstName} ${lastName || ''}`)}</KooperHeader>
            </KooperTableCell>
            <KooperTableCell>{startCase(employeeID)}</KooperTableCell>
            <KooperTableCell>{workEmail}</KooperTableCell>
            <KooperTableCell>{startCase(title)}</KooperTableCell>
            <KooperTableCell>{startCase(manager?.name)}</KooperTableCell>
            <KooperTableCell>{startCase(department?.department)}</KooperTableCell>
            <KooperTableCell>{startCase(createdBy?.name)}</KooperTableCell>
            <KooperTableCell>{startCase(updatedBy?.name)}</KooperTableCell>
            <KooperTableCell>
              <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon" direction="left">
                <KooperDropdownMenu>
                  {allowPortalAccess && !isOnboard && (
                    <KooperDropdownItem onClick={() => dispatch(resendEmployeeInvitation(id))}>
                      <KooperIcon name="refresh" /> Resend Invitation
                    </KooperDropdownItem>
                  )}
                  <KooperDropdownItem
                    onClick={() => {
                      setEditId(id)
                      setDeleteModal(true)
                    }}
                  >
                    <KooperIcon name="trash alternate" /> Delete
                  </KooperDropdownItem>
                </KooperDropdownMenu>
              </KooperDropdown>
            </KooperTableCell>
          </KooperTableRow>
        )
      }
    )
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {allEmployeesList?.length > 0 ||
      currentListParams.text ||
      currentListParams.department ||
      currentListParams.manager ? (
        <>
          <EmployeeSidemenu />

          <div className="kooper-side-wrap">
            <div className="page-header mb-0 pb-0">
              <div className="page-title">
                <div className="page-titleIcon">
                  <SvgIcon path="common/file" />
                  <h5>Employees ({allEmployeesList ? allEmployeesList.length : 0})</h5>
                </div>
              </div>
              <div className="page-action">
                <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
                <KooperTooltip
                  position="top center"
                  size="mini"
                  content="Select Manager"
                  trigger={
                    <div className="mailActivity ml-3">
                      <KooperDropdown
                        icon="chevron down"
                        button
                        defaultValue="all"
                        options={[
                          {key: 'all', value: 'all', text: 'All Manager'},
                          {
                            key: 'No Manager',
                            value: 'No Manager',
                            text: 'No Manager',
                          },
                          <KooperDivider />,
                          ...managerList,
                        ]}
                        onChange={(e, {value}) => {
                          setCurrentListParams({
                            ...currentListParams,
                            manager: value,
                          })
                        }}
                      />
                    </div>
                  }
                />
                <KooperTooltip
                  position="top center"
                  size="mini"
                  content="Select Department"
                  trigger={
                    <div className="mailActivity ml-3">
                      <KooperDropdown
                        icon="chevron down"
                        button
                        defaultValue="all"
                        options={[
                          {key: 'all', value: 'all', text: 'All Department'},
                          {
                            key: 'No Department',
                            value: 'No Department',
                            text: 'No Department',
                          },
                          <KooperDivider />,
                          ...departmentList,
                        ]}
                        onChange={(e, {value}) => {
                          setCurrentListParams({
                            ...currentListParams,
                            department: value,
                          })
                        }}
                      />
                    </div>
                  }
                />
                <KooperButton
                  content="Add"
                  primary
                  onClick={() => history.push(`/${EMPLOYEES_PATH.PREFIX}/${EMPLOYEES_PATH.CREATE}`)}
                />
              </div>
            </div>

            <KooperGrid style={{margin: '24px 0'}}>
              <KooperGridRow columns={5} className="p-0 m-0">
                {employeeSummary.map(e => (
                  <KooperGridColumn className="summary-grid">
                    <div className="m-0" style={{'--summary-color': e.color}}>
                      <span>{e.name}</span>
                      <span>{e.time}</span>
                    </div>
                  </KooperGridColumn>
                ))}
              </KooperGridRow>
            </KooperGrid>

            <KooperTable sortable basic selectable>
              <KooperTableHeader>
                <KooperTableRow>
                  <KooperTableHeaderCell>#</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Name</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Employee ID</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Email</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Title</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Manager</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Department</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Created By</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Modified By</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Actions</KooperTableHeaderCell>
                </KooperTableRow>
              </KooperTableHeader>
              <KooperTableBody>
                {isLoadingData && type.includes(GET_ALL_EMPLOYEES) ? (
                  <KooperTablePlaceholder columns={10} />
                ) : (allEmployeesList || []).length > 0 ? (
                  renderTableBody()
                ) : (
                  <tr>
                    <td colSpan={10} className="emptyValue">
                      No Data Available
                    </td>
                  </tr>
                )}
              </KooperTableBody>
            </KooperTable>
            {deleteModal && (
              <DeleteModal
                isModalOpen={deleteModal}
                setIsModalOpen={setDeleteModal}
                modalText="Are you sure you want to delete ?"
                deleteAction={deleteEmployee}
                idTobeDeleted={editId}
                type={DELETE_EMPLOYEES}
              />
            )}
          </div>
        </>
      ) : (
        <SettingLayout>
          <CreateFirst
            header="Employees"
            subHeader="Effectively create and maintain the list of employees working in your organization"
            addFirst={() => history.push(`/${EMPLOYEES_PATH.PREFIX}/${EMPLOYEES_PATH.CREATE}`)}
            buttonText="Create Employees"
            Feature1="Work Hours"
            Feature2="Employee Self Service"
            Feature3="Employment status"
            list1="Set work hours of employees to work for business."
            list2="Allow employee to update their own data"
            list3="Change employment status who have left or terminated"
            imageSrc={create}
          />
        </SettingLayout>
      )}
    </>
  )
}

export default Employees
