import React, {useMemo} from 'react'
import {Controller} from 'react-hook-form'
import {removeDoubleQuotes} from 'utils/helper'
import {KooperCheckbox, KooperForm, KooperFormField, KooperFormInput, KooperFormSelect, KooperFormTextArea, KooperTooltip} from 'kooper-library'
import {budgetTypes, PROJECT_FINANCE_BUDGET_TYPES} from 'constants/projects'
import SvgIcon from 'components/common/SvgIcon'
import {useSelector} from 'react-redux'
import BudgetForm from '../Steps/Finance/Budgets/BudgetForm'
import FixedFeeBudgetForm from '../Steps/Finance/Budgets/FixedFeeBudgetForm'
import BudgetTypeForm from '../BudgetTypeForm'

const ProjectDetailsCarousel = ({
  style,
  control,
  errors,
  budgetType,
  rangeType,
  budgetTimeError,
  setBudgetTimeError,
  setValue,
  getValues,
  useTemplate,
}) => {
  const {projectsTemplatesList = []} = useSelector(state => state.projects)

  const projectsTemplatesOptions = useMemo(() => {
    return projectsTemplatesList?.map(({id, title}) => ({key: id, value: id, text: title})) || []
  }, [projectsTemplatesList])

  return (
    <KooperForm className="errorLabel" style={style}>
      <KooperFormField>
        <Controller
          name="useTemplate"
          control={control}
          render={({value, onChange}) => (
            <KooperCheckbox label="Use Template" checked={value} onChange={(e, {checked}) => onChange(checked)} />
          )}
        />
      </KooperFormField>
      {useTemplate && (
        <KooperFormField required>
          <div className="info-header">
            <label>Select Template</label>
            <KooperTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Select a template for creating a project"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="templateId"
            control={control}
            render={({value, onChange}) => (
              <KooperFormSelect
                fluid
                placeholder="Select Template"
                options={projectsTemplatesOptions}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                error={errors.templateId && {content: removeDoubleQuotes(errors.templateId.message)}}
              />
            )}
          />
        </KooperFormField>
      )}
      <KooperFormField required>
        <div className="info-header">
          <label>Title</label>
          <KooperTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Give your project a title"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="title"
          control={control}
          render={({value, onChange}) => (
            <KooperFormInput
              maxLength={20}
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
            />
          )}
        />
      </KooperFormField>

      <KooperFormField>
        <div className="info-header">
          <label>Description</label>
          <KooperTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Write a brief description about the project"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="description"
          control={control}
          render={({value, onChange}) => (
            <KooperFormTextArea
              maxLength={200}
              style={{resize: 'none'}}
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={errors.description && {content: removeDoubleQuotes(errors.description.message)}}
            />
          )}
        />
      </KooperFormField>

      <BudgetTypeForm control={control} budgetTypes={budgetTypes} />

      {[PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER].includes(
        budgetType
      ) && (
        <BudgetForm
          control={control}
          errors={errors}
          rangeType={rangeType}
          budgetTimeError={budgetTimeError}
          setBudgetTimeError={setBudgetTimeError}
          budgetType={budgetType}
        />
      )}

      {[PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE].includes(budgetType) && (
        <FixedFeeBudgetForm control={control} errors={errors} setValue={setValue} getValues={getValues} />
      )}
    </KooperForm>
  )
}

export default ProjectDetailsCarousel
