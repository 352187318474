/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperButton,
  KooperDropdown,
  KooperForm,
  KooperFormField,
  KooperHeader,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
} from 'kooper-library'

import {GET_ALL_CURRENCY, UPDATE_ACCOUNT_CURRENCY_BASE} from 'actions/types'
import {getAccountCurrency, getAccountCurrencyNoteAdded, updateAccountCurrencyBase} from 'actions/setting_currency'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const CurrencyBaseModal = props => {
  const dispatch = useDispatch()
  const {isModalOpen, setIsModalOpen, baseCurrency} = props

  const [selectedBasecurrency, setSelectedBasecurrency] = useState('')
  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {allCurrencyList = []} = useSelector(state => state.settingCurrency)

  useEffect(() => {
    if (successLabels.includes(UPDATE_ACCOUNT_CURRENCY_BASE)) {
      dispatch(getAccountCurrency())
      dispatch(getAccountCurrencyNoteAdded())
      setIsModalOpen()
    }
  }, [successLabels])

  useEffect(() => {
    setSelectedBasecurrency(baseCurrency.currencyId)
  }, [baseCurrency])

  return (
    <>
      <KooperModal
        size="tiny"
        open={isModalOpen}
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        onClose={setIsModalOpen}
      >
        <KooperHeader >Change Company Base Currency</KooperHeader>
        <KooperModalContent>
          <KooperForm>
            <KooperFormField>
              <label>Currency</label>
              <KooperDropdown
                fluid
                search
                selection
                placeholder="Select Base Currency"
                value={selectedBasecurrency}
                options={allCurrencyList.map(list => ({
                  key: list.id,
                  text: `${list.currency} ${list.name}`,
                  value: list.id,
                }))}
                onChange={(e, {value}) => setSelectedBasecurrency(value)}
              />
            </KooperFormField>
          </KooperForm>
        </KooperModalContent>
        <KooperModalActions>
          <KooperButton
            basic
            disabled={isLoadingData || type.includes(UPDATE_ACCOUNT_CURRENCY_BASE)}
            onClick={() => {
              setIsModalOpen(false)
            }}
          >
            Cancel
          </KooperButton>
          <KooperButton
            primary
            loading={isLoadingData || type.includes(UPDATE_ACCOUNT_CURRENCY_BASE)}
            disabled={isLoadingData || type.includes(UPDATE_ACCOUNT_CURRENCY_BASE)}
            onClick={() => dispatch(updateAccountCurrencyBase(selectedBasecurrency))}
          >
            Update
          </KooperButton>
        </KooperModalActions>
      </KooperModal>
    </>
  )
}

export default CurrencyBaseModal
