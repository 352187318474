import React from 'react'
import {Controller} from 'react-hook-form'
import {
  KooperDatePicker,
  KooperDropdown,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperFormTextArea,
  KooperGrid,
  KooperGridColumn,
  KooperGridRow,
  KooperTooltip,
} from 'kooper-library'
import {removeDoubleQuotes, KooperCountryList} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const EmployeePersonalInformation = props => {
  const {errors, control, DateOfBirth, setDateOfBirth} = props

  return (
    <KooperForm className="errorLabel">
      <KooperGrid>
        <KooperGridRow>
          <KooperGridColumn width={8}>
            <h5 className="mb-0">Personal Details</h5>
            <p className="mt-0 kooper-lead mb-4">Mentions all personal details of the employee</p>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>ID type</label>
                <KooperTooltip
                  content="Choose ID type provided by employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.identityType"
                render={({onChange, value}) => (
                  <KooperDropdown
                    fluid
                    selection
                    options={[
                      {value: 'national_id', key: 'national_id', text: 'National ID'},
                      {value: 'passport', key: 'passport', text: 'Passport'},
                      {value: 'others', key: 'others', text: 'Others'},
                    ]}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errors?.personalInfo?.identityType && {
                        content: removeDoubleQuotes(errors?.personalInfo?.identityType?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>ID Number</label>
                <KooperTooltip
                  content="Write the ID number of employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.identityNumber"
                render={({value, onChange, onBlur}) => (
                  <KooperFormInput
                    fluid
                    type="text"
                    placeholder="ID Number.."
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errors?.personalInfo?.identityNumber && {
                        content: errors?.personalInfo?.identityNumber?.message,
                      }
                    }
                  />
                )}
                control={control}
              />{' '}
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Email</label>
                <KooperTooltip
                  content="Email id of the employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.email"
                render={({value, onChange, onBlur}) => (
                  <KooperFormInput
                    fluid
                    type="email"
                    placeholder="Type your Email.."
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errors?.personalInfo?.email && {
                        content: errors?.personalInfo?.email?.message,
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Phone</label>
                <KooperTooltip
                  content="Employees phone number"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.phone"
                render={({value, onChange, onBlur}) => (
                  <KooperFormInput
                    fluid
                    type="text"
                    name="phone"
                    placeholder="Type your number.."
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errors?.personalInfo?.phone && {
                        content: errors?.personalInfo?.phone?.message,
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Address</label>
                <KooperTooltip
                  content="Specify address of the employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.address"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormTextArea
                    type="textarea"
                    value={value}
                    placeholder="Write your address here"
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors?.personalInfo?.address && {
                        content: errors?.personalInfo?.address?.message,
                      }
                    }
                  />
                )}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>City</label>
                <KooperTooltip
                  content="Specify city in which employee live"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.city"
                render={({onChange, value}) => (
                  <KooperFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="city"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors?.personalInfo?.city && {
                        content: removeDoubleQuotes(errors?.personalInfo?.city?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Country</label>
                <KooperTooltip
                  content="Employees country name"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.country"
                render={({onChange, value, onBlur}) => (
                  <KooperFormSelect
                    search
                    fluid
                    options={KooperCountryList}
                    selection
                    placeholder="Country"
                    onBlur={onBlur}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errors?.personalInfo?.country && {
                        content: removeDoubleQuotes(errors?.personalInfo?.country?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>
          </KooperGridColumn>

          <KooperGridColumn width={8} style={{marginTop: '61px'}}>
            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Highest Qualification</label>
                <KooperTooltip
                  content="Mention highest qualification of employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.highestQualification"
                render={({onChange, value}) => (
                  <KooperFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="highest Qualification"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors?.personalInfo?.highestQualification && {
                        content: removeDoubleQuotes(errors?.personalInfo?.highestQualification?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Field of Study</label>
                <KooperTooltip
                  content="Specify in which field the employee has studied"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.educationalField"
                render={({onChange, value}) => (
                  <KooperFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="Educational Field"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors?.personalInfo?.educationalField && {
                        content: removeDoubleQuotes(errors?.personalInfo?.educationalField?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>University Name</label>
                <KooperTooltip
                  content="Specify university name from which employee studied"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.university"
                render={({onChange, value}) => (
                  <KooperFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="University"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors?.personalInfo?.university && {
                        content: removeDoubleQuotes(errors?.personalInfo?.university?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Date of Birth</label>
                <KooperTooltip
                  content="Set employee DOB"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="personalInfo.dateOfBirth"
                render={({onChange, value}) => (
                  <KooperDatePicker
                    type="date"
                    value={typeof value === 'string' ? new Date(DateOfBirth) : DateOfBirth}
                    inputProps={{readOnly: true}}
                    time={false}
                    onChange={value => {
                      onChange(value)
                      setDateOfBirth(value)
                    }}
                    error={
                      errors?.personalInfo?.dateOfBirth && {
                        content: removeDoubleQuotes(errors?.personalInfo.dateOfBirth?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>
          </KooperGridColumn>
        </KooperGridRow>
      </KooperGrid>
    </KooperForm>
  )
}

export default EmployeePersonalInformation
