import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import img from 'assets/create.svg'
import {
  KooperButton,
  KooperHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {deleteTaskTemplate, getAllTaskTemplate} from 'actions/settings_task'
import SettingLayout from 'layouts/settingLayout'
import DeleteModal from 'components/common/DeleteModal'
import CreateFirst from 'components/common/CreateFirst'
import {FaEnvelope} from 'react-icons/fa'
import {getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import {GET_ALL_TASK_TEMPLATES} from 'actions/types'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {SETTINGS_PATH} from 'constants/path'
import TemplateDetails from './TemplateDetails'

const Template = props => {
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState(false)
  const [idTobeDeleted, setIdTobeDeleted] = useState(false)
  const [createTemplate, setcreateTemplate] = useState(false)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const taskTemplatesLearnMore = settingsLearnMoreUrl?.productivity?.taskTemplate?.learnMore

  const {isLoading} = useApiLoader({label: GET_ALL_TASK_TEMPLATES})
  const {workflowAndAutomationAccess, tasksPermissions} = useUserPermissions()
  const taskTemplatesAccess = workflowAndAutomationAccess?.taskTemplates
  const manageTaskTemplatesPermissions = tasksPermissions?.manage_task_templates

  const {data: taskTemplateList = []} = useApiResponse({
    action: getAllTaskTemplate,
    enabled: taskTemplatesAccess,
    storePath: 'settingsTasks.taskTemplateList',
  })

  if (isLoading && taskTemplatesAccess) {
    return <PageLoader />
  }

  if (createTemplate) {
    return <TemplateDetails setNewTemplate={setcreateTemplate} {...props} />
  }

  if (taskTemplateList?.length === 0 || !taskTemplatesAccess) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          src={<FaEnvelope />}
          Feature1="Template Creation"
          Feature2="Subtasks"
          Feature3="Integration with Projects"
          list1="Create customized task templates based on the need"
          list2="Break complex tasks into smaller steps using subtask"
          list3="link task templates to specific projects or phases."
          addFirst={() => taskTemplatesAccess && manageTaskTemplatesPermissions && setcreateTemplate(true)}
          buttonText="Create Task Templates"
          tooltip={!taskTemplatesAccess || !manageTaskTemplatesPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: taskTemplatesAccess,
            roleAccess: manageTaskTemplatesPermissions,
          })}
          img={img}
          header="Templates"
          subHeader="Task templates are predefined task structures that can be reused for common workflows"
        />
      </div>
    )
  }

  const renderTaskTemplateList = () => {
    return taskTemplateList.map((list, index) => (
      <KooperTableRow
        onClick={() => history.push(`/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.TASK_TEMPLATES}/${list.id}`)}
        className="tableLink"
        key={list.id}
      >
        <KooperTableCell className="pl-4">{index + 1}</KooperTableCell>
        <KooperTableCell className="prevCell">
          <KooperHeader as="h5" style={{color: '#001F3D'}}>
            {startCase(list.name)}
          </KooperHeader>
        </KooperTableCell>
        <KooperTableCell>{list.createdBy}</KooperTableCell>
        <KooperTableCell>{getFormattedDate(list.createdAt)}</KooperTableCell>
        <KooperTableCell>
          <KooperTooltip
            content={
              manageTaskTemplatesPermissions
                ? 'Delete'
                : getPermissionTooltipContent({roleAccess: manageTaskTemplatesPermissions})
            }
            size="mini"
            position="top center"
            trigger={
              <KooperButton
                icon
                onClick={e => {
                  e.stopPropagation()
                  if (manageTaskTemplatesPermissions) {
                    setIdTobeDeleted(list.id)
                    setDeleteModal(!deleteModal)
                  }
                }}
              >
                <SvgIcon path="common/delete" />
              </KooperButton>
            }
          />
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/task-templates" />}
      header="Task Templates"
      subHeader="Create and assign set of predefined repetitive task to users to save more time"
      learnMoreUrl={taskTemplatesLearnMore}
      headerButton={{
        onClick: () => manageTaskTemplatesPermissions && setcreateTemplate(true),
        content: 'Create',
      }}
      table
      headerDivider
      lockRole={!manageTaskTemplatesPermissions}
    >
      <KooperTable basic selectable>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell className="pl-4">#</KooperTableHeaderCell>
            <KooperTableHeaderCell>Name</KooperTableHeaderCell>
            <KooperTableHeaderCell>Created By</KooperTableHeaderCell>
            <KooperTableHeaderCell>Created Date</KooperTableHeaderCell>
            <KooperTableHeaderCell>Action</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>
          {taskTemplateList?.length > 0 ? (
            renderTaskTemplateList()
          ) : (
            <tr>
              <td colSpan="6" className="emptyValue">
                No Data Available
              </td>
            </tr>
          )}
        </KooperTableBody>
      </KooperTable>
      {idTobeDeleted && (
        <DeleteModal
          idTobeDeleted={idTobeDeleted}
          deleteAction={deleteTaskTemplate}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
        />
      )}
    </SettingLayout>
  )
}
export default Template
