/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router'
import {FiArchive, FiChevronRight} from 'react-icons/fi'
import _ from 'lodash'
import {
  KooperAccordion,
  KooperAccordionContent,
  KooperAccordionTitle,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperMenu,
  KooperMenuItem,
} from 'kooper-library'

import {
  COPY_PROJECTS_TASK_LIST,
  CREATE_PROJECT_TASKS_LIST,
  DELETE_PROJECTS_TASKS_LIST,
  GET_PROJECTS_TASKS_LIST,
  MOVE_PROJECTS_TASK_LIST,
  STATUS_PROJECTS_TASKS_LIST,
  UPDATE_PROJECT_TASKS_LIST,
} from 'actions/types'
import {
  deleteProjectsTasksList,
  getProjectsFilters,
  getProjectsSingleTasksList,
  getProjectsTasksList,
  getProjectsTasksListFilter,
  getProjectsTasksListTasks,
  statusProjectsTasksList,
} from 'actions/projects'
import {get, getPermissionTooltipContent, startCase} from 'utils/helper'
import CreateFirst from 'components/common/CreateFirst'
import create from 'assets/create.svg'
import {MdOutlineMoreVert} from 'react-icons/md'
import DeleteModal from 'components/common/DeleteModal'
import {AiOutlineCheck} from 'react-icons/ai'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getTagLabel} from 'actions/multiple_fields'
import TaskListModal from './TaskListModal'
import SubscriberModal from './SubscriberModal'
import CopyTaskListModal from './CopyTaskListModal'
import MoveTaskListModal from './MoveTaskListModal'
import TasksDetails from './TasksDetails'

const Tasks = ({rolePermissions}) => {
  const {projectId} = useParams()
  const dispatch = useDispatch()

  const [isTaskListModalOpen, setIsTaskListModalOpen] = useState(false)
  const [active, setActive] = useState({
    active: true,
    archieve: false,
  })
  const [activeTasksList, setActiveTasksList] = useState([])
  const [archiveTasksList, setArchiveTasksList] = useState([])
  const [taskListId, setTaskListId] = useState([])
  const [isSubscriberModalOpen, setIsSubscriberModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [copyTasksList, setCopyTasksList] = useState({
    isCopyModalOpen: false,
    title: '',
  })
  const [moveTasksList, setMoveTasksList] = useState({
    isMoveModalOpen: false,
    title: '',
  })
  const [currentlySelectedTaskList, setCurrentlySelectedTaskList] = useState(null)
  const [currentListParams, setCurrentListParams] = useState({
    stageIds: 'all',
    status: 'active',
    assignedTo: 'all',
    labelIds: 'all',
  })

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_PROJECTS_TASKS_LIST})

  const {data: tasksList = [], callAction: callGetProjectsTasksListAction} = useApiResponse({
    action: getProjectsTasksList,
    payload: projectId,
    dependency: [projectId],
    enabled: projectId,
    label: GET_PROJECTS_TASKS_LIST,
    storePath: 'projects.tasksList',
    onSuccess: tasksList => {
      if (tasksList?.length > 0) {
        const allActiveTopics = tasksList.filter(topic => topic.status === 'active')
        setActiveTasksList(allActiveTopics)
        const allArchiveTopics = tasksList.filter(topic => topic.status === 'archived')
        setArchiveTasksList(allArchiveTopics)

        const newList = _.omitBy(currentListParams, value => value === 'all')
        if (currentlySelectedTaskList) {
          dispatch(getProjectsSingleTasksList({projectId, taskListId: currentlySelectedTaskList}))
          // dispatch(getProjectsTasksListTasks({projectId, taskListId: currentlySelectedTaskList, params: newList}))
          dispatch(getProjectsTasksListFilter({projectId, taskListId: currentlySelectedTaskList}))
        } else {
          setCurrentlySelectedTaskList(tasksList[0].id)
          dispatch(getProjectsSingleTasksList({projectId, taskListId: tasksList[0].id}))
          dispatch(getProjectsTasksListTasks({projectId, taskListId: tasksList[0].id, params: newList}))
          dispatch(getProjectsTasksListFilter({projectId, taskListId: tasksList[0].id}))
        }
      }
    },
  })

  useEffect(() => {
    dispatch(getTagLabel())
  }, [dispatch])

  useEffect(() => {
    dispatch(getProjectsFilters(projectId, {filters: ['assignedTo']}))
  }, [dispatch, projectId])

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECT_TASKS_LIST) ||
      successLabels.includes(UPDATE_PROJECT_TASKS_LIST) ||
      successLabels.includes(STATUS_PROJECTS_TASKS_LIST) ||
      successLabels.includes(COPY_PROJECTS_TASK_LIST) ||
      successLabels.includes(MOVE_PROJECTS_TASK_LIST)
    ) {
      callGetProjectsTasksListAction()
    }
    if (successLabels.includes(DELETE_PROJECTS_TASKS_LIST)) {
      callGetProjectsTasksListAction()
      setCurrentlySelectedTaskList(null)
    }
  }, [successLabels, callGetProjectsTasksListAction])

  useEffect(() => {
    if (currentlySelectedTaskList) {
      const newList = _.omitBy(currentListParams, value => value === 'all')
      dispatch(getProjectsTasksListTasks({projectId, taskListId: currentlySelectedTaskList, params: newList}))
    }
  }, [currentListParams, dispatch, projectId, currentlySelectedTaskList])

  if (isLoading) {
    return <PageLoader />
  }

  if (!tasksList.length) {
    return (
      <>
        {isTaskListModalOpen && (
          <TaskListModal open={isTaskListModalOpen} toggle={setIsTaskListModalOpen} projectId={projectId} />
        )}
        <CreateFirst
          header="Tasks"
          subHeader="List of activities assigned to team and track their progress to ensure they finish on time"
          addFirst={() => rolePermissions && setIsTaskListModalOpen(true)}
          buttonText="Create Task List"
          tooltip={!rolePermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: rolePermissions})}
          imageSrc={create}
          Feature1="Task creation"
          list1="Create task easily in just one click"
          Feature2="Associate workflow"
          list2="Set workflow based on priority and requirement"
          Feature3="Users assigning"
          list3="Assign users to a task for timely completion"
        />
      </>
    )
  }

  const renderDropdown = task => {
    return (
      <KooperDropdownMenu>
        <KooperDropdownItem
          onClick={() => {
            setTaskListId(task.id)
            setIsSubscriberModalOpen(true)
          }}
        >
          <SvgIcon path="settings/users" /> Manage People
        </KooperDropdownItem>
        <KooperDropdownItem
          onClick={() => {
            setTaskListId(task.id)
            setIsTaskListModalOpen(true)
          }}
        >
          <SvgIcon path="common/edit2" /> Edit
        </KooperDropdownItem>
        <KooperDropdownItem
          onClick={() => {
            setTaskListId(task.id)
            setIsDeleteModalOpen(true)
          }}
        >
          <SvgIcon path="common/delete" /> Delete
        </KooperDropdownItem>
        <KooperDropdownItem
          onClick={() => {
            setTaskListId(task.id)
            setCopyTasksList({...copyTasksList, isCopyModalOpen: true, title: task.title})
          }}
        >
          <SvgIcon path="common/clone" /> Create a copy
        </KooperDropdownItem>
        <KooperDropdownItem
          onClick={() => {
            setTaskListId(task.id)
            setMoveTasksList({...moveTasksList, isMoveModalOpen: true, title: task.title})
          }}
        >
          <SvgIcon path="common/move" /> Move
        </KooperDropdownItem>
        <KooperDropdownItem
          onClick={() => {
            let data
            if (task.status === 'active') {
              data = {status: 'archived'}
            } else {
              data = {status: 'active'}
            }
            dispatch(statusProjectsTasksList({projectId, taskListId: task.id, data}))
          }}
        >
          {task.status === 'active' ? (
            <>
              <FiArchive /> Archive
            </>
          ) : (
            <>
              <AiOutlineCheck /> Unarchive
            </>
          )}
        </KooperDropdownItem>
      </KooperDropdownMenu>
    )
  }

  const onClickTaskList = id => {
    if (currentlySelectedTaskList !== id) {
      setCurrentlySelectedTaskList(id)
      dispatch(getProjectsSingleTasksList({projectId, taskListId: id}))
      dispatch(getProjectsTasksListTasks({projectId, taskListId: id}))
      dispatch(getProjectsTasksListFilter({projectId, taskListId: id}))
    }
  }

  const renderTasksList = tasksList => {
    return tasksList.map(task => {
      return (
        <KooperMenuItem
          key={task.id}
          className="project-discussion-sidemenu pr-0"
          active={currentlySelectedTaskList === task.id}
          onClick={() => onClickTaskList(task.id)}
        >
          <div className="d-flex" style={{justifyContent: 'flex-start', width: '85%'}}>
            {get(['title'], task, '')}
          </div>
          {rolePermissions && (
            <KooperDropdown className="project-discussion-sidemenu-dropdown" icon={<MdOutlineMoreVert />}>
              {renderDropdown(task)}
            </KooperDropdown>
          )}
        </KooperMenuItem>
      )
    })
  }

  const SideMenu = () => {
    return (
      <KooperMenu vertical secondary className="projects-side-menu kooper-menu">
        <KooperAccordion className="mt-0">
          <KooperAccordionTitle className="d-flex">
            <div
              className="d-flex"
              style={{justifyContent: 'flex-start'}}
              onClick={() => setActive({...active, active: !active.active})}
            >
              {active.active ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Active
            </div>
            {rolePermissions && (
              <SvgIcon
                path="common/plus"
                onClick={() => {
                  setTaskListId(null)
                  setIsTaskListModalOpen(true)
                }}
              />
            )}
          </KooperAccordionTitle>
          <KooperAccordionContent active={active.active}>{renderTasksList(activeTasksList)}</KooperAccordionContent>
        </KooperAccordion>
        <KooperAccordion>
          <KooperAccordionTitle
            className="d-flex"
            style={{justifyContent: 'flex-start'}}
            onClick={() => setActive({...active, archieve: !active.archieve})}
          >
            {active.archieve ? <SvgIcon path="common/down" /> : <FiChevronRight />}
            Archived
          </KooperAccordionTitle>
          <KooperAccordionContent active={active.archieve}>{renderTasksList(archiveTasksList)}</KooperAccordionContent>
        </KooperAccordion>
      </KooperMenu>
    )
  }

  return (
    <>
      <SideMenu />
      <TasksDetails
        renderDropdown={renderDropdown}
        setTaskListId={setTaskListId}
        setIsSubscriberModalOpen={setIsSubscriberModalOpen}
        projectId={projectId}
        currentlySelectedTaskList={currentlySelectedTaskList}
        currentListParams={currentListParams}
        setCurrentListParams={setCurrentListParams}
        rolePermissions={rolePermissions}
      />

      {isTaskListModalOpen && (
        <TaskListModal
          open={isTaskListModalOpen}
          toggle={setIsTaskListModalOpen}
          projectId={projectId}
          taskListId={taskListId}
        />
      )}

      {isSubscriberModalOpen && (
        <SubscriberModal
          open={isSubscriberModalOpen}
          toggle={setIsSubscriberModalOpen}
          projectId={projectId}
          taskListId={taskListId}
        />
      )}

      {copyTasksList.isCopyModalOpen && (
        <CopyTaskListModal
          open={copyTasksList.isCopyModalOpen}
          toggle={() => setCopyTasksList({isCopyModalOpen: false, title: ''})}
          title={copyTasksList.title}
          projectId={projectId}
          taskListId={taskListId}
        />
      )}

      {moveTasksList.isMoveModalOpen && (
        <MoveTaskListModal
          open={moveTasksList.isMoveModalOpen}
          toggle={() => setMoveTasksList({isMoveModalOpen: false, title: ''})}
          title={moveTasksList.title}
          projectId={projectId}
          taskListId={taskListId}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          setIsModalOpen={setIsDeleteModalOpen}
          isModalOpen={isDeleteModalOpen}
          onDelete={() => dispatch(deleteProjectsTasksList({projectId, taskListId}))}
          type={DELETE_PROJECTS_TASKS_LIST}
        />
      )}
    </>
  )
}

export default Tasks
