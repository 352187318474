import SvgIcon from 'components/common/SvgIcon'
import {
  KooperAccordion,
  KooperAccordionContent,
  KooperAccordionTitle,
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperContainer,
  KooperFormDropdown,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormTextArea,
  KooperHeader,
  KooperIcon,
  KooperInput,
  KooperLabel,
  KooperTooltip,
} from 'kooper-library'
import React, {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import ParentGoalModal from './ParentGoalModal'
import CreateContributePersonalGoals from './CreateContributePersonalGoals'

const CreatePersonalGoals = () => {
  const {personalType} = useParams()
  const [activeIndices, setActiveIndices] = useState([])

  const handleClick = (e, titleProps) => {
    const {index} = titleProps
    if (activeIndices.includes(index)) {
      setActiveIndices(activeIndices.filter(i => i !== index))
    } else {
      setActiveIndices([...activeIndices, index])
    }
  }

  const {
    handleSubmit,
    errors,
    control,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    defaultValues: {},
  })

  const [isOpenParentGoalModal, setIsOpenParentGoalModal] = useState(false)
  if (personalType === 'contribute') {
    return <CreateContributePersonalGoals control={control} />
  }
  return (
    <>
      <KooperCard fluid>
        <KooperCardContent>
          <KooperFormGroup>
            <KooperFormField width={8} required>
              <div className="info-header">
                <label>Goal Title</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Goal Title"
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="title"
                control={control}
                render={({field}) => <KooperFormInput maxLength={20} {...field} placeholder="Enter goal title" />}
              />
            </KooperFormField>
          </KooperFormGroup>
        </KooperCardContent>
      </KooperCard>

      <KooperCard fluid>
        <KooperCardContent>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <KooperCardHeader>Define your goal</KooperCardHeader>
            <KooperLabel circular>0/5</KooperLabel>
          </div>

          <p style={{color: 'rgba(0,0,0,.6)'}}>
            Use this SMART framework to create effective goals that keep you headed in the right direction.
          </p>
          <KooperAccordion fluid>
            {/* SPECIFIC */}
            <KooperAccordionTitle active={activeIndices.includes(0)} index={0} onClick={handleClick}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{marginRight: '0.5em', color: 'rgba(0,0,0,.4)'}}>1.</span>
                <span style={{color: '#4a4a4a', letterSpacing: '0.5px', fontSize: '0.9em'}}>SPECIFIC</span>
                <KooperIcon
                  name={activeIndices.includes(0) ? 'chevron up' : 'chevron down'}
                  style={{marginLeft: 'auto'}}
                />
              </div>
            </KooperAccordionTitle>
            <KooperAccordionContent className="pb-0" active={activeIndices.includes(0)}>
              <KooperFormField width="11">
                <label>What do you want to accomplish?</label>
                <Controller
                  name="specific.goal"
                  control={control}
                  render={({field}) => (
                    <KooperFormInput
                      maxLength={100}
                      {...field}
                      placeholder="I want to accomplish..."
                      icon={<KooperIcon name="lightbulb outline" />}
                    />
                  )}
                />
              </KooperFormField>
            </KooperAccordionContent>

            {/* MEASURABLE */}
            <KooperAccordionTitle className="mt-2" active={activeIndices.includes(1)} index={1} onClick={handleClick}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{marginRight: '0.5em', color: 'rgba(0,0,0,.4)'}}>2.</span>
                <span style={{color: '#4a4a4a', letterSpacing: '0.5px', fontSize: '0.9em'}}>MEASURABLE</span>
                <KooperIcon
                  name={activeIndices.includes(1) ? 'chevron up' : 'chevron down'}
                  style={{marginLeft: 'auto'}}
                />
              </div>
            </KooperAccordionTitle>
            <KooperAccordionContent className="pb-0" active={activeIndices.includes(1)}>
              <KooperFormField width="11">
                <label>How will you and your manager know this goal has been achieved?</label>
                <Controller
                  name="measurable.goal"
                  control={control}
                  render={({field}) => (
                    <KooperFormInput
                      maxLength={100}
                      {...field}
                      placeholder="We expect to see changes in..."
                      icon={<KooperIcon name="lightbulb outline" />}
                    />
                  )}
                />
              </KooperFormField>
            </KooperAccordionContent>

            {/* ATTAINABLE */}
            <KooperAccordionTitle className="mt-2" active={activeIndices.includes(2)} index={2} onClick={handleClick}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{marginRight: '0.5em', color: 'rgba(0,0,0,.4)'}}>3.</span>
                <span style={{color: '#4a4a4a', letterSpacing: '0.5px', fontSize: '0.9em'}}>ATTAINABLE</span>
                <KooperIcon
                  name={activeIndices.includes(2) ? 'chevron up' : 'chevron down'}
                  style={{marginLeft: 'auto'}}
                />
              </div>
            </KooperAccordionTitle>
            <KooperAccordionContent className="pb-0" active={activeIndices.includes(2)}>
              <KooperFormField width="11">
                <label>Why do you feel confident about achieving this goal?</label>
                <Controller
                  name="attainable.confidence"
                  control={control}
                  render={({field}) => (
                    <KooperFormInput
                      maxLength={100}
                      {...field}
                      placeholder="I feel confident about this goal because..."
                      icon={<KooperIcon name="lightbulb outline" />}
                    />
                  )}
                />
              </KooperFormField>
              <KooperFormField width="11">
                <label>What could get in the way of achieving this goal?</label>
                <Controller
                  name="attainable.blockers"
                  control={control}
                  render={({field}) => (
                    <KooperFormInput
                      maxLength={100}
                      {...field}
                      placeholder="My work could be blocked by..."
                      icon={<KooperIcon name="lightbulb outline" />}
                    />
                  )}
                />
              </KooperFormField>
              <KooperFormField width="11">
                <label>What type of help or resources would help you achieve this goal?</label>
                <Controller
                  name="attainable.resources"
                  control={control}
                  render={({field}) => (
                    <KooperFormInput
                      maxLength={100}
                      {...field}
                      placeholder="I will probably need access to..."
                      icon={<KooperIcon name="lightbulb outline" />}
                    />
                  )}
                />
              </KooperFormField>
            </KooperAccordionContent>

            {/* RELEVANT */}
            <KooperAccordionTitle className="mt-2" active={activeIndices.includes(3)} index={3} onClick={handleClick}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{marginRight: '0.5em', color: 'rgba(0,0,0,.4)'}}>4.</span>
                <span style={{color: '#4a4a4a', letterSpacing: '0.5px', fontSize: '0.9em'}}>RELEVANT</span>
                <KooperIcon
                  name={activeIndices.includes(3) ? 'chevron up' : 'chevron down'}
                  style={{marginLeft: 'auto'}}
                />
              </div>
            </KooperAccordionTitle>
            <KooperAccordionContent className="pb-0" active={activeIndices.includes(3)}>
              <KooperFormField width="11">
                <label>Why is this goal important to your self-development?</label>
                <Controller
                  name="relevant.importance"
                  control={control}
                  render={({field}) => (
                    <KooperFormInput
                      maxLength={100}
                      {...field}
                      placeholder="This goal will help me..."
                      icon={<KooperIcon name="lightbulb outline" />}
                    />
                  )}
                />
              </KooperFormField>
              <KooperFormField>
                <KooperButton onClick={() => setIsOpenParentGoalModal(true)} basic content="+ Link team goal" />
              </KooperFormField>
            </KooperAccordionContent>

            {/* TIMEBOUND */}
            <KooperAccordionTitle className="mt-2" active={activeIndices.includes(4)} index={4} onClick={handleClick}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{marginRight: '0.5em', color: 'rgba(0,0,0,.4)'}}>5.</span>
                <span style={{color: '#4a4a4a', letterSpacing: '0.5px', fontSize: '0.9em'}}>TIMEBOUND</span>
                <KooperIcon
                  name={activeIndices.includes(4) ? 'chevron up' : 'chevron down'}
                  style={{marginLeft: 'auto'}}
                />
              </div>
            </KooperAccordionTitle>
            <KooperAccordionContent className="pb-0" active={activeIndices.includes(4)}>
              <KooperFormGroup>
                <KooperFormField width="8">
                  <label>When do you want to achieve this goal?</label>
                  <KooperFormGroup>
                    <KooperFormField width={8}>
                      <div className="info-header">
                        <label>Start Date</label>
                        <KooperTooltip
                          trigger={<SvgIcon path="common/question" />}
                          content="Start date"
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <Controller
                        name="timebound.startDate"
                        control={control}
                        render={({field}) => <KooperFormInput {...field} type="date" placeholder="Start date" />}
                      />
                    </KooperFormField>
                    <KooperFormField width={8}>
                      <div className="info-header">
                        <label>End Date</label>
                        <KooperTooltip
                          trigger={<SvgIcon path="common/question" />}
                          content="End date"
                          size="mini"
                          position="top center"
                        />
                      </div>
                      <Controller
                        name="timebound.endDate"
                        control={control}
                        render={({field}) => <KooperFormInput {...field} type="date" placeholder="End date" />}
                      />
                    </KooperFormField>
                  </KooperFormGroup>
                </KooperFormField>
              </KooperFormGroup>
            </KooperAccordionContent>
          </KooperAccordion>
        </KooperCardContent>
      </KooperCard>

      {/* <KooperCard fluid style={{borderRadius: '10px', marginBottom: '2.5rem'}}>
        <KooperCardContent>
          <KooperCardHeader>Action Items</KooperCardHeader>
          <KooperFormField>
            <label>What specific tasks will you need to complete?</label>
            <KooperButton basic content="+ Add action item" />
          </KooperFormField>
        </KooperCardContent>
      </KooperCard> */}
      {isOpenParentGoalModal && (
        <ParentGoalModal isModalOpen={isOpenParentGoalModal} setIsModalOpen={setIsOpenParentGoalModal} />
      )}
    </>
  )
}

export default CreatePersonalGoals
