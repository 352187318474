/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperButton,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {DELETE_DEPARTMENT, GET_DEPARTMENT} from 'actions/types'
import {getDepartment, deleteDepartment} from 'actions/employees'
import SettingLayout from 'layouts/settingLayout'
import DeleteModal from 'components/common/DeleteModal'
import {startCase} from 'utils/helper'
import EmployeeSidemenu from 'navigation/sub-routes/Hr/Employees/EmployeeMenu'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import CreateModal from './CreateModal'

const EmployeeDepartments = () => {
  const dispatch = useDispatch()

  const [departmentModal, setDepartmentModal] = useState(false)
  const [departmentId, setDepartmentId] = useState()
  const [deleteModal, setDeleteModal] = useState(false)

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {data: getDepartmentData = []} = useApiResponse({
    action: getDepartment,
    enabled: true,
    label: GET_DEPARTMENT,
    storePath: 'employees.getDepartmentData',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_DEPARTMENT)) {
      dispatch(getDepartment())
    }
  }, [dispatch, successLabels])

  const categoryProps = {
    open: departmentModal,
    toggleModal: setDepartmentModal,
    departmentToggle: setDepartmentId,
  }
  if (departmentId) {
    categoryProps.editId = departmentId
  }

  const renderTableBody = () => {
    return getDepartmentData.map(({id, updatedBy, createdBy, department, departmentHead}, index) => {
      return (
        <KooperTableRow
          key={id}
          className="tableLink"
          onClick={() => {
            setDepartmentModal(true)
            setDepartmentId(id)
          }}
        >
          <KooperTableCell>{index + 1}</KooperTableCell>
          <KooperTableCell>{startCase(department)}</KooperTableCell>
          <KooperTableCell>{departmentHead?.name}</KooperTableCell>
          <KooperTableCell>{createdBy?.name}</KooperTableCell>
          <KooperTableCell>{updatedBy?.name}</KooperTableCell>
          <KooperTableCell>
            <KooperTooltip
              content="Delete"
              size="mini"
              position="top center"
              trigger={
                <KooperButton
                  icon={<SvgIcon path="common/delete" />}
                  onClick={e => {
                    e.stopPropagation()
                    setDepartmentId(id)
                    setDeleteModal(true)
                  }}
                />
              }
            />
          </KooperTableCell>
        </KooperTableRow>
      )
    })
  }

  return (
    <>
      <EmployeeSidemenu />

      <SettingLayout
        header="Department Settings"
        subHeader="Create list of departments for organizing your employees within the organization"
        table
        headerButton={{
          onClick: () => {
            setDepartmentModal(true)
          },
          content: 'Create Department',
        }}
      >
        {departmentModal ? <CreateModal {...categoryProps} /> : null}

        <KooperTable sortable basic selectable>
          <KooperTableHeader>
            <KooperTableRow>
              <KooperTableHeaderCell>#</KooperTableHeaderCell>
              <KooperTableHeaderCell>Name</KooperTableHeaderCell>
              <KooperTableHeaderCell>Head of Department</KooperTableHeaderCell>
              <KooperTableHeaderCell>Created By</KooperTableHeaderCell>
              <KooperTableHeaderCell>Modified By</KooperTableHeaderCell>
              <KooperTableHeaderCell>Actions</KooperTableHeaderCell>
            </KooperTableRow>
          </KooperTableHeader>
          <KooperTableBody>
            {isLoadingData && type.includes(GET_DEPARTMENT) ? (
              <KooperTablePlaceholder columns={8} />
            ) : (getDepartmentData || []).length > 0 ? (
              renderTableBody()
            ) : (
              <tr>
                <td colSpan={6} className="emptyValue">
                  No Data Available
                </td>
              </tr>
            )}
          </KooperTableBody>
        </KooperTable>
        {deleteModal && (
          <DeleteModal
            isModalOpen={deleteModal}
            setIsModalOpen={setDeleteModal}
            modalText="Are you sure you want to delete ?"
            deleteAction={deleteDepartment}
            idTobeDeleted={departmentId}
            type={DELETE_DEPARTMENT}
          />
        )}
      </SettingLayout>
    </>
  )
}

export default EmployeeDepartments
