import {ResponsiveBar} from '@nivo/bar'
import {ResponsiveLine} from '@nivo/line'
import {
  getProjectFinanceBudget,
  getProjectFinanceBudgetOverview,
  getProjectFinanceBudgetProfitability,
  getProjectFionanceBudgetRetainerOverview,
} from 'actions/projects'
import {
  CREATE_PROJECT_FINANCE_BUDGET,
  DELETE_PROJECT_FINANCE_BUDGET,
  GET_PROJECT_FINANCE_BUDGET_OVERVIEW,
  GET_PROJECT_FINANCE_BUDGET_RETAINER_OVERVIEW,
  UPDATE_PROJECT_FINANCE_BUDGET,
} from 'actions/types'
import CustomLoader from 'components/common/CustomLoader'
import {PROJECT_FINANCE_BUDGET_TYPES, projectBudgetsBasedOnOptions} from 'constants/projects'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom/cjs/react-router-dom.min'
import {getGridYValues, getGridYValuesMultipleBar, maxOfGraph, maxOfGraphMultipleBar} from 'utils/graph'
import {
  getCurrentMonthDates,
  getCurrentQuarterDates,
  getCurrentWeekDates,
  getFormattedDate,
  startCase,
} from 'utils/helper'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperGrid,
  KooperGridColumn,
  KooperTooltip,
} from 'kooper-library'
import CreateFirst from 'components/common/CreateFirst'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import CreateProjectBudgetModal from './Budgets/CreateProjectBudgetModal'
import DeleteProjectBudgetModal from './Budgets/DeleteProjectBudgetModal'
import CustomDateSelectorModal from './Budgets/CustomDateSelectorModal'

const getProfitabilityDates = profitability => {
  switch (profitability) {
    case 'week':
      return getCurrentWeekDates()
    case 'month':
      return getCurrentMonthDates()
    case 'quarter':
      return getCurrentQuarterDates()
    default:
      return {startDate: new Date(), endDate: new Date()}
  }
}

const ProjectBudgetSummary = ({projectId, budgetId}) => {
  const dispatch = useDispatch()
  const {pathname} = useLocation()
  const history = useHistory()

  const [isCreateBudgetModalOpen, setIsCreateBudgetModalOpen] = useState(false)
  const [idToDeleteBudget, setIdToDeleteBudget] = useState(null)
  const [isDeleteBudgetModalOpen, setIsDeleteBudgetModalOpen] = useState(false)
  const [idToUpdateBudget, setIdToUpdateBudget] = useState(null)
  const [profitability, setProfitability] = useState('budgetDates')
  const [isCustomDateSelectorModalOpen, setIsCustomDateSelectorModalOpen] = useState(false)

  const {successLabels = [], type = []} = useSelector(state => state.apiReducer)
  const {
    isBudgetCreated = false,
    projectBudget = {},
    projectBudgetProfitabilityData = {},
    projectBudgetRetainerOverviewData = {},
  } = useSelector(state => state.projects)
  const {formattedData: retainerOverviewData = []} = projectBudgetRetainerOverviewData

  const {data: projectBudgetOverviewData = {}, callAction: callGetProjectFinanceBudgetOverviewAction} = useApiResponse({
    action: getProjectFinanceBudgetOverview,
    payload: {projectId, budgetId},
    dependency: [projectId, budgetId],
    enabled: projectId && budgetId,
    label: GET_PROJECT_FINANCE_BUDGET_OVERVIEW,
    storePath: 'projects.projectBudgetOverviewData',
  })
  const {formattedData = []} = projectBudgetOverviewData

  useEffect(() => {
    if (projectId && budgetId && projectBudget.budgetType === PROJECT_FINANCE_BUDGET_TYPES.RETAINER) {
      dispatch(getProjectFionanceBudgetRetainerOverview({projectId, budgetId}))
    }
  }, [dispatch, projectId, budgetId, projectBudget.budgetType])

  useEffect(() => {
    if (profitability === 'custom') return
    if (projectId && budgetId) {
      let data
      if (profitability === 'budgetDates') {
        data = {
          startDate: projectBudget.startDate,
          endDate: projectBudget.endDate,
        }
      } else {
        data = getProfitabilityDates(profitability)
      }
      dispatch(getProjectFinanceBudgetProfitability({projectId, budgetId, data}))
    }
  }, [profitability, dispatch, projectId, budgetId, projectBudget])

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECT_FINANCE_BUDGET)) {
      setIsCreateBudgetModalOpen(false)
      dispatch(getProjectFinanceBudget(projectId))
    }
    if (successLabels.includes(UPDATE_PROJECT_FINANCE_BUDGET)) {
      setIdToUpdateBudget(null)
      setIsCreateBudgetModalOpen(false)
      dispatch(getProjectFinanceBudget(projectId))
    }
    if (successLabels.includes(DELETE_PROJECT_FINANCE_BUDGET)) {
      setIdToDeleteBudget(null)
      setIsDeleteBudgetModalOpen(false)
      dispatch(getProjectFinanceBudget(projectId))
    }
  }, [successLabels, dispatch, projectId])

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECT_FINANCE_BUDGET)) {
      let data
      if (profitability === 'budgetDates') {
        data = {
          startDate: projectBudget.startDate,
          endDate: projectBudget.endDate,
        }
      } else {
        data = getProfitabilityDates(profitability)
      }
      dispatch(getProjectFinanceBudgetProfitability({projectId, budgetId, data}))
      callGetProjectFinanceBudgetOverviewAction()
    }
  }, [
    successLabels,
    projectId,
    budgetId,
    dispatch,
    profitability,
    projectBudget,
    callGetProjectFinanceBudgetOverviewAction,
  ])

  const getBudgetDates = () => {
    let startDate
    let endDate
    if (
      [PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE].includes(
        projectBudget.budgetType
      )
    ) {
      startDate = projectBudget.startDate
      endDate = projectBudget.endDate
    } else if (projectBudget.budgetType === PROJECT_FINANCE_BUDGET_TYPES.RETAINER) {
      startDate = projectBudget.currentBudget?.periodStartDate
      endDate = projectBudget.currentBudget?.periodEndDate
    }
    return `${getFormattedDate(startDate)} to ${getFormattedDate(endDate)}`
  }

  const getBudgetOverviewUsedPercentage = () => {
    return Math.round((projectBudgetOverviewData.usedAmount / projectBudgetOverviewData.currentPeriodTotal) * 100)
  }

  const getBudgetOverviewRemainingPercentage = () => {
    return Math.round((projectBudgetOverviewData.remeaningAmount / projectBudgetOverviewData.currentPeriodTotal) * 100)
  }

  const getRemainingBudget = () => {
    if (projectBudgetOverviewData.currentPeriodTotal > projectBudgetOverviewData.usedAmount) {
      return `${
        projectBudgetOverviewData.remeaningAmount?.toFixed(2) || 0
      } (${getBudgetOverviewRemainingPercentage()}%)`
    }
    return `${Math.abs(
      projectBudgetOverviewData.currentPeriodTotal - projectBudgetOverviewData.usedAmount || 0
    )} over budget (${Math.abs(getBudgetOverviewRemainingPercentage())}%)`
  }

  const handleCancelCustomDates = () => {
    setIsCustomDateSelectorModalOpen(false)
    setProfitability('budgetDates')
  }

  const handleApplyCustomDates = data => {
    setIsCustomDateSelectorModalOpen(false)
    dispatch(getProjectFinanceBudgetProfitability({projectId, budgetId, data}))
  }

  const renderBudgetModal = () => {
    if (isCreateBudgetModalOpen) {
      return (
        <CreateProjectBudgetModal
          open={isCreateBudgetModalOpen}
          toggle={value => {
            setIdToUpdateBudget(value)
            setIsCreateBudgetModalOpen(value)
          }}
          projectId={projectId}
          budgetId={idToUpdateBudget}
        />
      )
    }
  }

  if (!isBudgetCreated) {
    return (
      <div className="kooper-side-wrap">
        <CreateFirst
          header="Budgets"
          subHeader="Project managers can create, track, and monitor budgets for their projects with ease."
          addFirst={() => setIsCreateBudgetModalOpen(true)}
          buttonText="Create Budget"
          Feature1="Budget Creation"
          Feature2="Budget Limit"
          Feature3="Multiple currencies"
          list1="Create detailed project budgets with line items for expenses"
          list2="Set budget limits to prevent cost overruns"
          list3="Support multiple currencies for global projects"
        />
        {renderBudgetModal()}
      </div>
    )
  }

  return (
    <div className="kooper-side-wrap">
      <div className="project-finance-budget-header">
        <div className="budget-header-left">
          <div>
            <span>Date</span>
            <div>{getBudgetDates()}</div>
          </div>
          <div>
            <span>Type</span>
            <div>{_.startCase(projectBudget.budgetType)}</div>
          </div>
          {[PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER].includes(
            projectBudget.budgetType
          ) && (
            <div>
              <span>Based on</span>
              <div>{projectBudgetsBasedOnOptions.find(option => option.value === projectBudget.timelogType)?.text}</div>
            </div>
          )}
          {projectBudget.budgetType === PROJECT_FINANCE_BUDGET_TYPES.RETAINER && (
            <div>
              <span>Period Length</span>
              <div>{_.startCase(projectBudget.repeatUnit)}</div>
            </div>
          )}
        </div>
        <div className="d-flex">
          <KooperButton className="basic-fill-btn mr-3" onClick={() => history.push(pathname.replace(/[^/]*$/, 'rates'))}>
            Edit rates
          </KooperButton>
          <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon">
            <KooperDropdownMenu direction="left">
              {projectBudget.budgetType === PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS && (
                <KooperDropdownItem onClick={() => setIsCreateBudgetModalOpen(true)}>Add new budget</KooperDropdownItem>
              )}
              <KooperDropdownItem
                onClick={() => {
                  setIdToUpdateBudget(projectBudget.id)
                  setIsCreateBudgetModalOpen(true)
                }}
              >
                <SvgIcon path="common/edit2" /> Edit
              </KooperDropdownItem>
              <KooperDropdownItem
                onClick={() => {
                  setIdToDeleteBudget(projectBudget.id)
                  setIsDeleteBudgetModalOpen(true)
                }}        
              >
                <SvgIcon path="common/delete" /> Delete
              </KooperDropdownItem>
            </KooperDropdownMenu>
          </KooperDropdown>
        </div>
      </div>

      <KooperGrid className="my-2">
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox pb-0">
              <KooperCardHeader>Budget Overview</KooperCardHeader>
            </KooperCardContent>
            <KooperCardContent className="project-budget-overview">
              <div className="project-budget-overview-left">
                <p className="period-total">Current period total</p>
                <p className="period-amount">${projectBudgetOverviewData.currentPeriodTotal || 0}</p>
              </div>
              <div className="project-budget-overview-right">
                <div className="d-flex">
                  <div>Used</div>
                  <div>Remaining</div>
                </div>
                <div className="d-flex">
                  <div>
                    ${projectBudgetOverviewData.usedAmount?.toFixed(2) || 0} ({getBudgetOverviewUsedPercentage()}%)
                  </div>
                  <div>${getRemainingBudget()}</div>
                </div>
                <div className="budget-progress">
                  {getBudgetOverviewUsedPercentage() <= 100 ? (
                    <div
                      className="budget-progress-completed"
                      style={{width: `${getBudgetOverviewUsedPercentage()}%`}}
                    />
                  ) : (
                    <div className="budget-progress-overflow" />
                  )}
                </div>
              </div>
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 350}}>
              {type.includes(GET_PROJECT_FINANCE_BUDGET_OVERVIEW) && <CustomLoader />}
              {!type.includes(GET_PROJECT_FINANCE_BUDGET_OVERVIEW) &&
                !formattedData.map(({data}) => data.every(({y}) => y === 0)).includes(false) && (
                  <div className="empty-chart">No Data Available</div>
                )}
              {!type.includes(GET_PROJECT_FINANCE_BUDGET_OVERVIEW) && (
                <ResponsiveLine
                  data={formattedData}
                  margin={{
                    top: 10,
                    right: 27,
                    bottom: 70,
                    left: 60,
                  }}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(formattedData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableSlices="x"
                  enableGridX={false}
                  gridYValues={getGridYValues(formattedData)}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: 'Time',
                    legendOffset: 60,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(formattedData),
                    legend: 'Budget Used',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  colors={d => d.color}
                  lineWidth={2}
                  pointSize={3}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  enableArea
                  useMesh
                  animate
                  motionConfig="molasses"
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        {[PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER].includes(
          projectBudget.budgetType
        ) && (
          <KooperGridColumn width={7}>
            <KooperCard fluid className="chartBox">
              <KooperCardContent className="pb-0">
                <div className="d-flex">
                  <KooperCardHeader style={{fontWeight: 500}}>Profitability</KooperCardHeader>
                  <KooperDropdown text={startCase(profitability)} selection>
                    <KooperDropdownMenu>
                      <KooperDropdownItem text="Budget Dates" onClick={() => setProfitability('budgetDates')} />
                      <KooperDropdownItem text="Week" onClick={() => setProfitability('week')} />
                      <KooperDropdownItem text="Month" onClick={() => setProfitability('month')} />
                      <KooperDropdownItem text="Quarter" onClick={() => setProfitability('quarter')} />
                      <KooperDropdownItem
                        text="Custom"
                        onClick={() => {
                          setIsCustomDateSelectorModalOpen(true)
                          setProfitability('custom')
                        }}
                      />
                    </KooperDropdownMenu>
                  </KooperDropdown>
                </div>
              </KooperCardContent>
              <KooperCardContent>
                <div className="project-budget-profitability">
                  <p className="project-profit">Profit</p>
                  <p className="project-profit-amount">
                    ${projectBudgetProfitabilityData.profit?.toFixed(2) || 0}{' '}
                    <span>({projectBudgetProfitabilityData.profitPercentage || 0}%)</span>
                  </p>
                </div>
                <div className="d-flex mt-2">
                  <div>
                    <div>Total Cost</div>
                    <div>${projectBudgetProfitabilityData.totalCost?.toFixed(2) || 0}</div>
                  </div>
                  <div>
                    <div>Billable Cost</div>
                    <div>${projectBudgetProfitabilityData.billableCost?.toFixed(2) || 0}</div>
                  </div>
                </div>
              </KooperCardContent>
            </KooperCard>
          </KooperGridColumn>
        )}

        {projectBudget.budgetType === PROJECT_FINANCE_BUDGET_TYPES.RETAINER && (
          <KooperGridColumn width={9}>
            <KooperCard fluid className="chartBox">
              <KooperCardContent className="headerBox">
                <KooperCardHeader>Retainer history</KooperCardHeader>
              </KooperCardContent>
              <KooperCardContent className="pt-0 pb-0" style={{marginLeft: '30px'}}>
                <KooperGrid>
                  <KooperGridColumn width={7}>
                    <div className="headerBox">
                      <h4 className="mb-0">Default budget</h4>
                      <KooperTooltip
                        content="Recurring rate charged to client"
                        size="mini"
                        position="top center"
                        trigger={<SvgIcon path="common/question" />}
                      />
                    </div>
                    <h5 className="mt-0">$ {projectBudgetRetainerOverviewData.defaultBudget || 0}</h5>
                  </KooperGridColumn>
                  <KooperGridColumn width={9}>
                    <div className="headerBox">
                      <h4 className="mb-0">Average budget used</h4>
                      <KooperTooltip
                        content="Average billable total of all retaienr periods so far"
                        size="mini"
                        position="top center"
                        trigger={<SvgIcon path="common/question" />}
                      />
                    </div>
                    <h5 className="mt-0">$ {projectBudgetRetainerOverviewData.avarageBudgetUsed || 0}</h5>
                  </KooperGridColumn>
                </KooperGrid>
              </KooperCardContent>
              <KooperCardContent className="pt-1" style={{height: 300}}>
                {type.includes(GET_PROJECT_FINANCE_BUDGET_RETAINER_OVERVIEW) && <CustomLoader />}
                {!type.includes(GET_PROJECT_FINANCE_BUDGET_RETAINER_OVERVIEW) &&
                  retainerOverviewData.every(
                    ({budgetAmount, usedAmount}) => budgetAmount === 0 && usedAmount === 0
                  ) && <div className="empty-chart">No Data Available</div>}
                {!type.includes(GET_PROJECT_FINANCE_BUDGET_RETAINER_OVERVIEW) && (
                  <ResponsiveBar
                    data={retainerOverviewData}
                    keys={['budgetAmount', 'usedAmount']}
                    indexBy="period"
                    margin={{top: 37, right: 27, bottom: 60, left: 60}}
                    padding={0.3}
                    minValue={0}
                    maxValue={maxOfGraphMultipleBar(retainerOverviewData, ['budgetAmount', 'usedAmount'])}
                    gridYValues={getGridYValuesMultipleBar(retainerOverviewData, ['budgetAmount', 'usedAmount'])}
                    groupMode="grouped"
                    valueScale={{type: 'linear'}}
                    indexScale={{type: 'band', round: true}}
                    colors={d => d.data[`${d.id.replace('Amount', '')}Color`]}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 1.6]],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: 'Time',
                      legendPosition: 'middle',
                      legendOffset: 40,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      tickValues: getGridYValuesMultipleBar(retainerOverviewData, ['budgetAmount', 'usedAmount']),
                      legend: 'Budget',
                      legendPosition: 'middle',
                      legendOffset: -40,
                    }}
                    labelSkipHeight={1}
                    label={d => <tspan y={-8}>{d.value}</tspan>}
                    labelTextColor={{
                      from: 'color',
                      modifiers: [['darker', 1.6]],
                    }}
                    legends={[
                      {
                        dataFrom: 'keys',
                        anchor: 'top',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: -40,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                  />
                )}
              </KooperCardContent>
            </KooperCard>
          </KooperGridColumn>
        )}

        {projectBudget.budgetType === PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE && (
          <KooperGridColumn width={7}>
            <KooperCard fluid className="chartBox">
              <KooperCardHeader style={{fontWeight: 500, padding: '18px 0 0 18px'}}>Profitability</KooperCardHeader>
              <KooperCardContent>
                <div className="d-flex">
                  <div className="project-budget-profitability">
                    <p className="project-profit">Profit</p>
                    <p className="project-profit-amount mb-0">
                      ${projectBudgetProfitabilityData.profit?.toFixed(2) || 0}{' '}
                    </p>
                    <p>Target ${projectBudgetProfitabilityData.targetProfit?.toFixed(2) || 0}</p>
                  </div>
                  <div className="project-budget-profitability">
                    <p className="project-profit">Profit margin</p>
                    <p className="project-profit-amount mb-0">
                      ${projectBudgetProfitabilityData.profitPercentage?.toFixed(2) || 0}%
                    </p>
                    <p>Target ${projectBudgetProfitabilityData.targetProfitMargin?.toFixed(2) || 0}%</p>
                  </div>
                </div>

                <div className="project-budget-profitability mt-4">
                  <p className="project-profit">Total Cost</p>
                  <p className="project-profit-amount mb-0">
                    ${projectBudgetProfitabilityData.totalCost?.toFixed(2) || 0}
                  </p>
                  <p>Target ${projectBudgetProfitabilityData.targetCost?.toFixed(2) || 0}</p>
                </div>
              </KooperCardContent>
            </KooperCard>
          </KooperGridColumn>
        )}
      </KooperGrid>

      {renderBudgetModal()}

      {isDeleteBudgetModalOpen && (
        <DeleteProjectBudgetModal
          open={isDeleteBudgetModalOpen}
          toggle={setIsDeleteBudgetModalOpen}
          projectId={projectId}
          budgetId={idToDeleteBudget}
        />
      )}

      {isCustomDateSelectorModalOpen && (
        <CustomDateSelectorModal
          open={isCustomDateSelectorModalOpen}
          onCancel={handleCancelCustomDates}
          onApply={handleApplyCustomDates}
        />
      )}
    </div>
  )
}

export default ProjectBudgetSummary
