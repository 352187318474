import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormSelect,
  KooperFormTextArea,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

import {getSMSList, sendEmail} from 'actions/inbox'
import {SEND_MAIL} from 'actions/types'
import {removeDoubleQuotes} from 'utils/helper'
import {smsResolver} from 'validation/Customers/contacts.schema'
import SvgIcon from 'components/common/SvgIcon'

function SmsModal({open, toggle, userId, isContact}) {
  const {handleSubmit, errors, control} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(smsResolver),
    defaultValues: {
      messageText: '',
      phone: '',
      mailboxId: Joi.number(),
    },
  })

  const dispatch = useDispatch()
  const [smsList, setSmsList] = useState([])
  const [phoneList, setPhoneList] = useState([])

  const {getAllContactsList = []} = useSelector(state => state.contacts)
  const {getAllLeadList = []} = useSelector(state => state.leads)
  const {getSmsChannelList} = useSelector(state => state.emailInbox)
  const {successLabels = []} = useSelector(state => state.apiReducer)

  const phoneNumbers = isContact
    ? getAllContactsList.find(({id}) => id === userId).phones
    : getAllLeadList.find(({id}) => id === userId).phones

  useEffect(() => {
    if (getSmsChannelList) {
      dispatch(getSMSList())
    }
  }, [])

  useEffect(() => {
    if (successLabels.includes(SEND_MAIL)) {
      toggle(false)
    }
  }, [successLabels])

  useEffect(() => {
    if (getSmsChannelList?.length > 0) {
      setSmsList(
        getSmsChannelList.map(sms => ({
          key: sms.id,
          value: sms.mailboxId,
          text: sms.name,
        }))
      )
    }
  }, [getSmsChannelList])

  useEffect(() => {
    if (phoneNumbers?.length > 0) {
      setPhoneList(
        phoneNumbers.map(({id, phone}) => ({
          key: id,
          value: phone,
          text: phone,
        }))
      )
    }
  }, [phoneNumbers])

  const submitForm = data => {
    const type = isContact ? 'contactId' : 'leadId'
    const dataToDispatch = {
      type: 'sms',
      mailboxId: data.mailboxId,
      subject: data.messageText,
      messageText: data.messageText,
      messageHtml: data.messageText,
      isDraft: false,
      to: [{phone: data.phone, [type]: userId}],
    }
    dispatch(sendEmail(dataToDispatch, true)) // "true" is sms indicator
  }

  return (
    <KooperModal
      open={open}
      size="tiny"
      onClose={() => toggle(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
    >
      <KooperModalHeader>Auto SMS</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <label>Select Sms Channel</label>
            <Controller
              control={control}
              name="mailboxId"
              render={({value, onChange}) => (
                <KooperFormSelect
                  search
                  options={smsList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.mailboxId && {
                      content: removeDoubleQuotes(errors.mailboxId.message),
                    }
                  }
                  noResultsMessage="No results found."
                />
              )}
            />
          </KooperFormField>
          <KooperFormField>
            <label>Select Phone Number</label>
            <Controller
              control={control}
              name="phone"
              render={({value, onChange}) => (
                <KooperFormSelect
                  search
                  options={phoneList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  noResultsMessage="No results found."
                  placeholder={
                    phoneList.length > 0 ? '' : "This Contact does't have any phone numbers, create to see them in list"
                  }
                  error={
                    errors.phone && {
                      content: removeDoubleQuotes(errors.phone.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <label>Message</label>
            <Controller
              control={control}
              name="messageText"
              render={({value, onChange}) => (
                <KooperFormTextArea
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.messageText && {
                      content: removeDoubleQuotes(errors.messageText.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Send
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default SmsModal
