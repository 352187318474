import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {Controller} from 'react-hook-form'
import {
  KooperButton,
  KooperCheckbox,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

function TextArea({setToggleModal, toggleModal, activeSection, index, control, data, watch}) {
  const watchMaxPoints = watch(`templateSections[${activeSection}].sectionFields[${index}].withPoints`)
  return (
    <KooperModal
      open={toggleModal === 'text_area'}
      onClose={() => setToggleModal(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      centered={false}
    >
      <KooperModalHeader >Edit Text Area</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Question</label>
            </div>
            <Controller
              name={`templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].question`}
              defaultValue={watch(
                `templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].question`
              )}
              control={control}
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  placeholder="Enter Question"
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  onBlur={onBlur}
                  //   error={
                  //     _.get(errors, `fields[${index}].label`) && {
                  //       content: removeDoubleQuotes(_.get(errors, `fields[${index}].label.message`)),
                  //     }
                  //   }
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Input For</label>
            </div>
            <Controller
              name={`templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].inputFor`}
              defaultValue={watch(
                `templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].inputFor`
              )}
              render={({value, onChange}) => (
                <KooperFormSelect
                  search
                  fluid
                  options={[
                    {key: 'both', value: 'both', text: 'Both'},
                    {key: 'employee', value: 'employee', text: 'Employee'},
                    {key: 'Reviewer', value: 'Reviewer', text: 'Reviewer'},
                  ]}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  //   error={
                  //     errors?.items?.[i].unit && {
                  //       content: removeDoubleQuotes(errors.items?.[i].unit?.message),
                  //     }
                  //   }
                />
              )}
              control={control}
            />
          </KooperFormField>
          <KooperFormField>
            <Controller
              control={control}
              name={`templateSections[${activeSection}].sectionFields[${index}].withPoints`}
              defaultValue={watch(`templateSections[${activeSection}].sectionFields[${index}].withPoints`)}
              render={({onChange, value}) => (
                <KooperFormField>
                  <div className="info-header">
                    <label>Enable Points?</label>
                  </div>
                  <KooperCheckbox checked={value} onChange={(e, {checked}) => onChange(checked)} />
                </KooperFormField>
              )}
            />
          </KooperFormField>
          {watchMaxPoints ? (
            <KooperFormField width={8}>
              <Controller
                name={`templateSections[${activeSection}].sectionFields[${index}].maxPoints`}
                defaultValue={watch(`templateSections[${activeSection}].sectionFields[${index}].maxPoints`)}
                render={({onChange, value}) => (
                  <KooperFormInput type="number" onChange={(e, {value}) => onChange(+value)} value={value} />
                )}
                control={control}
              />
            </KooperFormField>
          ) : null}
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton onClick={() => setToggleModal(false)}>Cancel</KooperButton>
        <KooperButton primary onClick={() => setToggleModal(false)}>
          Ok
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default TextArea
