import React, {useEffect, useState} from 'react'
import {useHistory, useRouteMatch} from 'react-router'
import {useSelector} from 'react-redux'
import {
  KooperButton,
  KooperHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {DELETE_TEAMS, GET_TEAMS_LIST} from 'actions/types'
import {deleteTeam, getAllTeams} from 'actions/settings_organisation'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import SettingLayout from 'layouts/settingLayout'
import create from 'assets/create-user.svg'
import {getPermissionTooltipContent, getSortName, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import TeamsDetails from './TeamsDetails'

const Teams = () => {
  const history = useHistory()
  const {url} = useRouteMatch()

  const [isTeamOpen, setIsTeamOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [idToDelete, setIdToDelete] = useState(0)
  const [showMoreUsers, setShowMoreUsers] = useState({})

  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const teamsLearnMore = settingsLearnMoreUrl?.myOrganization?.teams?.learnMore

  const {isLoading} = useApiLoader({label: GET_TEAMS_LIST})
  const {myOrganizationDefaultPermissions} = useUserPermissions()

  const {data: teamsList = {}, callAction} = useApiResponse({
    action: getAllTeams,
    enabled: true,
    storePath: 'settingsOrganisation.teamsList',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_TEAMS)) {
      callAction()
    }
  }, [successLabels, callAction])

  if (isLoading) {
    return <PageLoader />
  }

  if (isTeamOpen) {
    return <TeamsDetails isTeamOpen={isTeamOpen} toggleModal={setIsTeamOpen} />
  }

  if (!teamsList.length) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          header="Teams"
          subHeader="Teams are groups of users who work together on specific projects or tasks"
          addFirst={() => myOrganizationDefaultPermissions && setIsTeamOpen(true)}
          buttonText="Create Teams"
          tooltip={!myOrganizationDefaultPermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})}
          imageSrc={create}
          Feature1="Team Members"
          list1="Add users in your teams for more productivity"
          Feature2="Project Collaboration"
          list2="Assign teams to ensure right user is involved"
          Feature3="Team management"
          list3="Managing and editing teams in one click"
          isTeamOpen={isTeamOpen}
        />
      </div>
    )
  }

  const toggleShowMore = id => {
    setShowMoreUsers(prev => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  const renderTableBody = () => {
    if (type.includes(GET_TEAMS_LIST)) {
      return <KooperTablePlaceholder columns={5} />
    }

    return teamsList.map(({id, name, users}, index) => {
      const displayedUsers = showMoreUsers[id] ? users : users.slice(0, 3)

      return (
        <KooperTableRow key={id} className="tableLink" onClick={() => history.push(`${url}/${id}`)}>
          <KooperTableCell>{index + 1}</KooperTableCell>
          <KooperTableCell>
            <KooperHeader as="h5">{startCase(name)}</KooperHeader>
          </KooperTableCell>
          <KooperTableCell>
            <div className="user-list">
              {displayedUsers.map(user => (
                <KooperTooltip
                  key={user} // Ensure each key is unique
                  content={user}
                  position="top center"
                  size="mini"
                  trigger={
                    <span className="circle-user">
                      {user
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase())
                        .join('')}
                    </span>
                  }
                />
              ))}
              {users.length > 5 && (
                <a
                  onClick={e => {
                    e.stopPropagation()
                    toggleShowMore(id)
                  }}
                  className="show-more"
                >
                  {showMoreUsers[id] ? 'Show less' : '+Others'}
                </a>
              )}
            </div>
          </KooperTableCell>
          <KooperTableCell>
            <KooperTooltip
              size="mini"
              position="top center"
              content={
                myOrganizationDefaultPermissions
                  ? 'Delete'
                  : getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})
              }
              trigger={
                <KooperButton
                  basic
                  circular
                  icon
                  className="tableLink-delete-icon"
                  onClick={e => {
                    e.stopPropagation()
                    if (myOrganizationDefaultPermissions) {
                      setDeleteModal(true)
                      setIdToDelete(id)
                    }
                  }}
                >
                  <SvgIcon path="common/delete" />
                </KooperButton>
              }
            />
          </KooperTableCell>
        </KooperTableRow>
      )
    })
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/teams" />}
      header="Teams"
      subHeader="Create and manage multiple teams for your organizational needs"
      learnMoreUrl={teamsLearnMore}
      headerButton={{
        onClick: () => myOrganizationDefaultPermissions && setIsTeamOpen(true),
        content: 'Create Team',
      }}
      table
      headerDivider
      lockRole={!myOrganizationDefaultPermissions}
    >
      <KooperTable basic selectable>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>#</KooperTableHeaderCell>
            <KooperTableHeaderCell>Name</KooperTableHeaderCell>
            <KooperTableHeaderCell>Members</KooperTableHeaderCell>
            <KooperTableHeaderCell>Action</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>{renderTableBody()}</KooperTableBody>
      </KooperTable>

      {deleteModal && (
        <DeleteModal
          idTobeDeleted={idToDelete}
          deleteAction={deleteTeam}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete team?"
          type={DELETE_TEAMS}
        />
      )}
    </SettingLayout>
  )
}

export default Teams
