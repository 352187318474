import SvgIcon from 'components/common/SvgIcon'
import {
  KooperCard,
  KooperCardContent,
  KooperDatePicker,
  KooperFormDropdown,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormTextArea,
  KooperTooltip,
} from 'kooper-library'
import {Controller, useForm} from 'react-hook-form'
import AlignmentCreatCard from './AlignmentCreatCard'
import KeyResultCreatCard from './KeyResultCreatCard'

const CreateOrganizationalGoals = () => {
  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    // resolver: joiResolver(),
    defaultValues: {},
  })

  return (
    <>
      <KooperCard fluid>
        <KooperCardContent>
          <KooperFormGroup>
            <KooperFormField width={8} required>
              <div className="info-header">
                <label>Organizational Goal Title</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Organizational Goal Title"
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="title"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormInput maxLength={20} placeholder="Enter organizational goal title" />
                )}
              />
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormGroup>
            <KooperFormField width={8}>
              <div className="info-header">
                <label>Organizational Goal Description</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Organizational Goal Description"
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="description"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormTextArea maxLength={100} placeholder="Enter organizational goal description" />
                )}
              />
            </KooperFormField>
          </KooperFormGroup>
        </KooperCardContent>
      </KooperCard>
      <KooperCard fluid>
        <KooperCardContent>
          <KooperFormGroup>
            <KooperFormField required width={8}>
              <div className="info-header">
                <label>Goal owner</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Owner of the goal"
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="owner"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormDropdown
                    placeholder="Select goal owner"
                    fluid
                    selection
                    defaultValue="jordan"
                    options={[
                      {key: 'jordan', text: 'Jordan', value: 'jordan'},
                      {key: 'alex', text: 'Alex', value: 'alex'},
                      {key: 'sam', text: 'Sam', value: 'sam'},
                    ]}
                  />
                )}
              />
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormField>
            <KooperFormGroup>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Timeline Start Date</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Timeline start date"
                    size="mini"
                    position="top center"
                  />
                </div>
                <KooperDatePicker time={false} />
              </KooperFormField>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Timeline End Date</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Timeline end date"
                    size="mini"
                    position="top center"
                  />
                </div>
                <KooperDatePicker time={false} />
              </KooperFormField>
            </KooperFormGroup>
          </KooperFormField>
        </KooperCardContent>
      </KooperCard>
      <KeyResultCreatCard control={control} />
      <AlignmentCreatCard control={control} />
    </>
  )
}

export default CreateOrganizationalGoals
