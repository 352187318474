/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/destructuring-assignment */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm, Controller} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {FiUserPlus} from 'react-icons/fi'
import moment from 'moment'
import {
  KooperDatePicker,
  KooperModalHeader,
  KooperModalContent,
  KooperButton,
  KooperModal,
  KooperModalActions,
  KooperFormField,
  KooperInput,
  KooperCheckbox,
  KooperButtonGroup,
  KooperTab,
  KooperHeader,
  KooperIcon,
  KooperHeaderContent,
  KooperTabPane,
  KooperFormTextArea,
  KooperFormGroup,
  KooperForm,
  KooperFormInput,
  KooperFormSelect,
  KooperFormRadio,
  KooperTooltip,
  KooperListItem,
} from 'kooper-library'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File

import {
  EDIT_TASK,
  GET_TASK,
  CREATE_SUBTASK,
  CREATE_TASK_NOTE,
  DELETE_SUBTASK,
  EDIT_SUBTASK,
  GET_TIME_TRACKING_ENTRY_STATUS_TASK,
  MANUAL_TASK_ENTERIES,
  DELETE_ENTERIES_TASK,
  UPDATE_ENTERIES_TASK,
  GET_USERS,
} from 'actions/types'
import {
  editTaskActivities,
  createSubtask,
  deleteSubtask,
  editSubtask,
  getTaskActivities,
  createTaskNote,
} from 'actions/activities'
import {getUsers} from 'actions/settings'
import {get, getFormattedDate, getFormattedTime, getHourMinuteFromMinutes, onIdealUser, startCase} from 'utils/helper'
import {statusList, priorityList} from 'constants/variables'
import DeleteModal from 'components/common/DeleteModal'
import UserImage from 'components/common/UserImage'
import KooperCustomDropdown from 'components/common/KooperCustomDropdown'
import {editTaskSchema, taskEnteriesSchema} from 'validation/Projects/task.schema'
import {
  deleteManualEnteriesByTask,
  getEnteriesByTask,
  submitTimeTrackingManualTask,
  updateManualEnteriesByTask,
} from 'actions/time_tracking'
import UpdateModal from 'components/common/UpdateModal'
import {MdCall} from 'react-icons/md'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {AddSubTaskForm} from './CreateTask'

const initialData = {
  subject: '',
  status: 'new',
  endAt: new Date(moment().add(1, 'days').startOf('day')),
  priority: 'default',
  assigneeId: 'unass',
  description: '',
  callType: 'inbound',
  startAt: new Date(moment().startOf('day')),
}

const initialtaskEntries = {
  title: '',
  duration: '',
}

const ViewTask = props => {
  const dispatch = useDispatch()

  const [deleteModal, setDeleteModal] = useState(false)
  const [idToEdit, setIdToEdit] = useState()
  const [transparentSubTaskField, setTransparentSubTaskField] = useState({})

  const [usersList, setUsersList] = useState([])
  const [note, setNote] = useState('')
  const [timeUsage, setTimeUsage] = useState(0)

  const [currentTaskId, setCurrentTaskId] = useState(null)

  const [createSubtaskData, setCreateSubtaskData] = useState({
    title: '',
    dueDate: new Date(),
  })

  const [timeTrackingTimer, setTimeTrackingTimer] = useState(0)
  const [timeTrackingCounterStatus, setTimeTrackingCounterStatus] = useState('completed')
  const [timeTrackingStartTime, setTimeTrackingStartTime] = useState(new Date())
  const [isDeleteTimeTrackingModalOpen, setIsDeleteTimeTrackingModalOpen] = useState(false)
  const [taskdata, setTaskData] = useState('')
  const [updateModal, setUpdateModal] = useState(false)

  const {
    handleSubmit,
    errors,
    control,
    reset,
    getValues,
    formState: {isDirty},
    watch,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    submitFocusError: true,
    resolver: joiResolver(editTaskSchema),
    defaultValues: initialData,
  })

  const {
    errors: errors2,
    handleSubmit: handleSubmit2,
    control: control2,
    reset: reset2,
  } = useForm({
    resolver: joiResolver(taskEnteriesSchema),
    mode: 'onTouched',
    submitFocusError: true,
    defaultValues: initialtaskEntries,
  })

  const watchStartAt = watch('startAt')
  const watchEndAt = watch('endAt')

  const {getTaskData = {}, getTaskData: {subtasks: getSubtaskData = [], allActivities: activitiesList = []} = {}} =
    useSelector(state => state.activities)
  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {timeTrackingData = {}, timeTrackingEntryStatusTaskData = {}} = useSelector(state => state.timeTracking)

  const taskEntries = useSelector(state => get(['timeTracking', 'timeTrackingTaskEnteries', 'trackEntries'], state, []))

  const {data: getUsersList = {}} = useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUsersList(list || [])
    },
  })

  useEffect(() => {
    if (timeTrackingCounterStatus === 'running') {
      if (timeTrackingData.pauseTimerIdle) {
        onIdealUser({
          onIdeal: () => {
            setTimeTrackingCounterStatus('paused')
          },
        })
      }
      if (!timeTrackingData.pauseTimerIdle && timeTrackingData.timerPauseLimit > 0) {
        onIdealUser({
          timeOut: timeTrackingData.timerPauseLimit * 1000,
          onIdeal: () => {
            setTimeTrackingCounterStatus('paused')
          },
        })
      }
    }
  }, [timeTrackingCounterStatus])

  useEffect(() => {
    let id
    if (timeTrackingCounterStatus === 'running') {
      id = setInterval(() => {
        setTimeTrackingTimer(timeTrackingTimer + 1000)
      }, 1000)
    }

    return () => clearInterval(id)
  })

  useEffect(() => {
    if (successLabels.includes(GET_TASK)) {
      reset({
        subject: get(['subject'], getTaskData, ''),
        status: get(['status'], getTaskData, 'new'),
        endAt: new Date(get(['endAt'], getTaskData, '')),
        priority: get(['priority'], getTaskData, ''),
        assigneeId: get(['assigneeId'], getTaskData) || 'unass',
        description: get(['description'], getTaskData, ''),
        callType: get(['callType'], getTaskData),
        startAt: new Date(get(['startAt'], getTaskData, '')),
      })
      setCurrentTaskId(get(['id'], getTaskData))
    }
    if (successLabels.includes(CREATE_SUBTASK)) {
      dispatch(getTaskActivities(currentTaskId))
      setCreateSubtaskData({title: '', dueDate: new Date()})
    }
    if (successLabels.includes(CREATE_TASK_NOTE)) {
      dispatch(getTaskActivities(currentTaskId))
      setNote('')
    }
    if (successLabels.includes(EDIT_TASK)) {
      if (
        timeTrackingData.task ||
        timeTrackingData.call ||
        timeTrackingData.meeting ||
        timeTrackingData.milestone ||
        timeTrackingData.event
      )
        dispatch(
          submitTimeTrackingManualTask({
            taskId: getTaskData.id,
            startTime: timeTrackingStartTime,
            status: 'completed',
            duration: timeTrackingTimer,
          })
        )
      props.setIsViewTaskOpen(false)
    }
    if (successLabels.includes(DELETE_SUBTASK)) {
      setDeleteModal(false)
      dispatch(getTaskActivities(currentTaskId))
    }
    if (successLabels.includes(EDIT_SUBTASK)) {
      dispatch(getTaskActivities(currentTaskId))
    }
    if (
      successLabels.includes(MANUAL_TASK_ENTERIES) ||
      successLabels.includes(DELETE_ENTERIES_TASK) ||
      successLabels.includes(UPDATE_ENTERIES_TASK)
    ) {
      dispatch(getEnteriesByTask(currentTaskId))
    }
    if (successLabels.includes(GET_TIME_TRACKING_ENTRY_STATUS_TASK)) {
      setTimeTrackingTimer(timeTrackingEntryStatusTaskData.duration * 1000)
      setTimeTrackingCounterStatus(timeTrackingEntryStatusTaskData.status)
    }
  }, [successLabels])

  useEffect(() => {
    if (watchStartAt && watchEndAt) {
      const diff = watchEndAt.getTime() - watchStartAt.getTime()
      if (diff > 0) {
        const minutes = diff / (1000 * 60)
        setTimeUsage(minutes)
      } else {
        setTimeUsage(0)
      }
    }
  }, [watchStartAt, watchEndAt])

  const getTypeIcon = (type = 'activity') => {
    if (type === 'activity') {
      return <KooperIcon name="calendar check" />
    }
    if (type === 'call') {
      return <MdCall />
    }
    if (type === 'meeting') {
      return <KooperIcon name="calendar alternate" />
    }
  }

  const editTask = data => {
    if (data.assigneeId === 'unass') {
      data.assigneeId = null
    }
    dispatch(editTaskActivities(currentTaskId, data))
  }

  const getActivityValue = (type, value) => {
    if (type === 'string') {
      return startCase(value)
    }
    if (type === 'date') {
      return moment(value).format('HH:MM')
    }
    if (type === 'array') {
      return value.join(', ')
    }
  }

  const submitEntries = data => {
    dispatch(
      submitTimeTrackingManualTask({
        taskId: currentTaskId,
        description: data.title,
        status: 'completed',
        duration: data.duration,
      })
    )

    reset2(initialtaskEntries)
  }

  const panes = [
    {
      menuItem: 'Details',
      pane: (
        <KooperTabPane className="mt-3">
          <KooperForm className="errorLabel" style={{overflowX: 'hidden'}}>
            <KooperFormField required>
              <div className="info-header">
                <label className="label-class">Subject</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Write about subject of the task"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                name="subject"
                render={({value, onChange, onBlur}) => (
                  <KooperFormInput
                    maxLength={30}
                    fluid
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={
                      errors.subject && {
                        content: errors.subject.message,
                      }
                    }
                    onBlur={onBlur}
                  />
                )}
                control={control}
              />
            </KooperFormField>
            <KooperFormGroup widths="equal">
              <KooperFormField>
                <div className="info-header">
                  <label>Status</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set initial status of the task"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="status"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormSelect
                      fluid
                      options={statusList}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors.status && {
                          content: errors.status.message,
                        }
                      }
                      onBlur={onBlur}
                    />
                  )}
                  control={control}
                />
              </KooperFormField>

              <KooperFormField>
                <div className="info-header">
                  <label>Priority</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="set priority of task"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="priority"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormSelect
                      fluid
                      options={priorityList}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors.priority && {
                          content: errors.priority.message,
                        }
                      }
                      onBlur={onBlur}
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup widths="equal">
              <KooperFormField>
                <div className="info-header">
                  <label>Assign to</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select whom you want to assign"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="assigneeId"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormSelect
                      fluid
                      options={[
                        {
                          key: 'unass',
                          value: 'unass',
                          text: 'Unassign',
                        },
                        ...usersList,
                      ]}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors.assigneeId && {
                          content: errors.assigneeId.message,
                        }
                      }
                      onBlur={onBlur}
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
              <KooperFormField>
                {get(['type'], getTaskData, 'call') === 'call' && (
                  <KooperFormGroup inline>
                    <div className="info-header">
                      <label>Direction</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Choose what is this direction for"
                        size="mini"
                        psoition="top center"
                      />
                    </div>
                    <Controller
                      name="callType"
                      control={control}
                      render={({value, onChange}) => (
                        <>
                          <KooperFormRadio
                            label="Inbound"
                            value="inbound"
                            checked={value === 'inbound'}
                            onClick={(e, {value}) => onChange(value)}
                          />
                          <KooperFormRadio
                            label="Outbound"
                            value="outbound"
                            checked={value === 'outbound'}
                            onClick={(e, {value}) => onChange(value)}
                          />
                        </>
                      )}
                    />
                  </KooperFormGroup>
                )}
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormGroup>
              <KooperFormField width={6}>
                <div className="info-header">
                  <label>Start Time</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Setup task start time for easy tracking"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="startAt"
                  render={({value, onChange}) => (
                    <KooperDatePicker
                      inputProps={{readOnly: true}}
                      
                      onChange={value => onChange(value)}
                      value={value}
                      max={getValues('endAt')}
                    />
                  )}
                  control={control}
                />
              </KooperFormField>

              <KooperFormField width={6}>
                <div className="info-header">
                  <label>End Time</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set estimated end time"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="endAt"
                  render={({value, onChange}) => (
                    <KooperDatePicker
                      
                      inputProps={{readOnly: true}}
                      onChange={value => {
                        onChange(value)
                      }}
                      value={value}
                      min={getValues('startAt')}
                    />
                  )}
                  control={control}
                />
              </KooperFormField>

              <KooperFormField width={4}>
                <div className="info-header">
                  <label>Time Usage</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Total time allotted for this task"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <KooperInput disabled placeholder="00:00" value={getHourMinuteFromMinutes(timeUsage)} />
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormGroup widths="equal">
              <KooperFormField>
                <div className="info-header">
                  <label>Created By</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Name of created"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <p>{get(['owner'], getTaskData, '-')}</p>
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormField>
              <div className="info-header">
                <label>Description</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Write brief description about the task"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                name="description"
                render={({value, onChange, onBlur}) => (
                  <KooperFormTextArea
                    maxLength={200}
                    placeholder="Write description..."
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={
                      errors.description && {
                        content: errors.description.message,
                      }
                    }
                    onBlur={onBlur}
                  />
                )}
                control={control}
              />
            </KooperFormField>
          </KooperForm>
          <div className="info-header">
            <h5 className="mb-0">Subtask</h5>
            <KooperTooltip
              content="Add a subtask to perform along with your task."
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <div className="subTaskView">
            {getSubtaskData?.map(({id, isCompleted, title, assigneeId, dueDate: dueDateSubtask}) => {
              return (
                <div className="subTaskViewBody">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <KooperCheckbox
                      checked={isCompleted}
                      onChange={() =>
                        dispatch(
                          editSubtask(id, {
                            isCompleted: !isCompleted,
                          })
                        )
                      }
                    />
                    <KooperInput
                      style={
                        isCompleted
                          ? {
                              width: '340px',
                              textDecoration: 'line-through',
                            }
                          : {width: '340px'}
                      }
                      onChange={e =>
                        setTransparentSubTaskField({
                          title: e.target.value,
                        })
                      }
                      onBlur={() => dispatch(editSubtask(id, transparentSubTaskField))}
                      transparent
                      defaultValue={title}
                      key={title}
                    />
                  </div>
                  {!isCompleted && (
                    <>
                      <KooperDatePicker
                        time={false}
                        inputProps={{
                          readOnly: true,
                        }}
                        value={dueDateSubtask && new Date(Date.parse(dueDateSubtask))}
                        dropUp
                        min={new Date()}
                        onChange={value => {
                          dispatch(
                            editSubtask(id, {
                              dueDate: value,
                            })
                          )
                        }}
                      />
                      <KooperButtonGroup>
                        <KooperButton icon style={{fontSize: '0rem'}}>
                          <KooperCustomDropdown
                            options={[
                              {
                                key: 'unass',
                                value: null,
                                text: 'Unassign',
                              },
                              ...usersList,
                            ]}
                            value={assigneeId || null}
                            dropdownProps={{
                              item: true,
                              upward: true,
                              icon: assigneeId ? (
                                <UserImage
                                  profile={getUsersList.users.find(user => {
                                    return user.id === assigneeId ? user : null
                                  })}
                                />
                              ) : (
                                <FiUserPlus />
                              ),
                            }}
                            menu={{
                              scrolling: true,
                            }}
                            activeIndicator
                            input={{
                              icon: 'search',
                              iconPosition: 'left',
                              className: 'search',
                            }}
                            header="Assignee"
                            onClick={value => {
                              dispatch(
                                editSubtask(id, {
                                  assigneeId: value,
                                })
                              )
                            }}
                          />
                        </KooperButton>
                        <KooperTooltip
                          content="Delete"
                          size="mini"
                          position="top center"
                          trigger={
                            <KooperButton
                              icon
                              onClick={() => {
                                setIdToEdit(id)
                                setDeleteModal(true)
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </KooperButton>
                          }
                        />
                      </KooperButtonGroup>
                    </>
                  )}
                </div>
              )
            })}
            <div className="addTaskView">
              <AddSubTaskForm
                usersList={usersList}
                addMethod={data =>
                  dispatch(
                    createSubtask({
                      ...data,
                      taskId: currentTaskId,
                    })
                  )
                }
              />
            </div>
          </div>
        </KooperTabPane>
      ),
    },
    {
      menuItem: 'Timeline',
      pane: (
        <KooperTabPane className="mt-3">
          <KooperInput
            fluid
            action={{
              color: 'blue',
              content: 'Add Note',
              onClick: () => {
                const data = {
                  note,
                }

                if (note.trim() !== '') {
                  dispatch(createTaskNote(currentTaskId, data))
                }
              },
            }}
            actionPosition="right"
            placeholder="Write note here..."
            value={note}
            onChange={e => setNote(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                const data = {
                  note,
                }

                if (note.trim() !== '') {
                  dispatch(createTaskNote(currentTaskId, data))
                }
              }
            }}
          />
          <div className="modalTimeline">
            {activitiesList.map(activity => (
              <KooperHeader as="h5">
                <KooperIcon>
                  <SvgIcon path="settings/activities" />
                </KooperIcon>
                <KooperHeaderContent>
                  <div className="content">
                    {[
                      <strong>
                        {get(['user', 'firstName'], activity, '') || get(['agent'], activity, '')} &nbsp;
                      </strong>,
                      <strong>
                        {activity.activityDescription}
                        &nbsp;
                      </strong>,
                      <strong>
                        {activity.activity
                          ? getActivityValue(activity.activityType, activity.activityValue)
                          : ` ${activity.note}`}
                      </strong>,
                    ]}
                    <span className="time mt-1">
                      {getFormattedTime(activity.ts || activity.createdAt)}{' '}
                      {getFormattedDate(activity.ts || activity.createdAt)}
                    </span>
                  </div>
                  {/* `${get(["activityDescription"], activity)} ${
                    activity.activity
                      ? getActivityValue(
                          activity.activityType,
                          activity.activityValue,
                        )
                      : activity.note
                  }` */}
                  {/* <KooperHeaderSubheader>
                    Lorem ipsum dolor sit amet consectetur adipisicing
                    Exercitationem
                  </KooperHeaderSubheader> */}
                </KooperHeaderContent>
              </KooperHeader>
            ))}
          </div>
        </KooperTabPane>
      ),
    },
    timeTrackingData.isTimeTracking &&
      ((timeTrackingData.task && get(['type'], getTaskData) === 'task') ||
        (timeTrackingData.call && get(['type'], getTaskData) === 'call') ||
        (timeTrackingData.meeting && get(['type'], getTaskData) === 'meeting') ||
        (timeTrackingData.event && get(['type'], getTaskData) === 'event') ||
        (timeTrackingData.milestone && get(['type'], getTaskData) === 'milestone')) && {
        menuItem: {
          key: 'Billing',
          icon: 'dollar sign',
          content: 'Billing',
        },
        pane: {
          content: (
            <div className="my-3">
              <KooperForm className="errorLabel" style={{overflowX: 'hidden'}}>
                <KooperFormField>
                  <label>Title</label>
                  <Controller
                    name="title"
                    control={control2}
                    render={({onChange, value}) => (
                      <KooperFormInput
                        value={value}
                        placeholder="Enter Title.."
                        onChange={e => onChange(e.target.value)}
                        error={
                          errors2.title && {
                            content: errors2.title.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <KooperFormField widths={2}>
                    <label>Duration</label>
                    <Controller
                      name="duration"
                      control={control2}
                      render={({onChange, value}) => (
                        <KooperFormInput
                          value={value}
                          placeholder="duration"
                          onChange={e => onChange(e.target.value)}
                          error={
                            errors2.duration && {
                              content: errors2.duration.message,
                            }
                          }
                        />
                      )}
                    />
                  </KooperFormField>
                  <KooperFormField>
                    <KooperButton color="blue" style={{marginLeft: '20px'}} onClick={handleSubmit2(submitEntries)}>
                      Add Data
                    </KooperButton>
                  </KooperFormField>
                </div>
              </KooperForm>
              <div className="billing-container">
                {taskEntries
                  .sort((a, b) => a.id - b.id)
                  .map((val, index) => (
                    <KooperHeaderContent key={index}>
                      <div className="taskEntries">
                        <KooperListItem>
                          <strong>Description : {val.description}</strong>
                        </KooperListItem>
                        <KooperListItem>
                          <strong>Amount : {val.costAmount}</strong>
                        </KooperListItem>
                      </div>
                      <span className="time">
                        <KooperButton
                          onClick={() => {
                            setIdToEdit(val.id)
                            setUpdateModal(true)
                            setTaskData(val)
                          }}
                        >
                          Edit
                        </KooperButton>
                      </span>
                      <span className="time">
                        <KooperButton
                          onClick={() => {
                            setIdToEdit(val.id)
                            setIsDeleteTimeTrackingModalOpen(true)
                          }}
                        >
                          Delete
                        </KooperButton>
                      </span>
                    </KooperHeaderContent>
                  ))}
              </div>
            </div>
          ),
        },
      },
  ]

  return (
    <KooperModal
      centered={false}
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={props.isViewTaskOpen}
      onClose={() => props.setIsViewTaskOpen(false)}
    >
      <KooperModalHeader  toggle={() => props.setIsViewTaskOpen(!props.isViewTaskOpen)}>
        {getTypeIcon(get(['type'], getTaskData))}&nbsp;
        {startCase(get(['type'], getTaskData))}
      </KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperTab
          menu={{
            color: 'blue',
            secondary: true,
            pointing: true,
          }}
          panes={panes}
          renderActiveOnly={false}
        />
      </KooperModalContent>
      <KooperModalActions>
        {timeTrackingData.isTimeTracking &&
          ((timeTrackingData.task && get(['type'], getTaskData) === 'task') ||
            (timeTrackingData.call && get(['type'], getTaskData) === 'call') ||
            (timeTrackingData.meeting && get(['type'], getTaskData) === 'meeting') ||
            (timeTrackingData.event && get(['type'], getTaskData) === 'event') ||
            (timeTrackingData.milestone && get(['type'], getTaskData) === 'milestone')) && (
            <div className="timeTrack">
              <KooperButton
                icon
                onClick={() => {
                  dispatch(
                    submitTimeTrackingManualTask({
                      taskId: get(['id'], getTaskData),
                      status: timeTrackingCounterStatus === 'running' ? 'paused' : 'running',
                    })
                  )
                  setTimeTrackingCounterStatus(timeTrackingCounterStatus === 'running' ? 'paused' : 'running')
                  if (timeTrackingCounterStatus === 'completed') {
                    setTimeTrackingStartTime(new Date())
                  }
                }}
              >
                {timeTrackingCounterStatus === 'running' ? <KooperIcon name="pause" /> : <KooperIcon name="play" />}
              </KooperButton>
              {`${moment('2000-01-01 00:00:00').add(moment.duration(timeTrackingTimer)).format('HH:mm:ss')}`}
              <KooperButton icon>
                <KooperIcon name="redo" />
              </KooperButton>
            </div>
          )}
        <KooperButton
          basic
          disabled={isLoadingData && type.includes(EDIT_TASK)}
          onClick={() => props.setIsViewTaskOpen(false)}
        >
          Cancel
        </KooperButton>
        <KooperButton
          color="primary"
          loading={isLoadingData && type.includes(EDIT_TASK)}
          disabled={!isDirty || (isLoadingData && type.includes(EDIT_TASK))}
          onClick={handleSubmit(editTask)}
        >
          Update
        </KooperButton>
      </KooperModalActions>
      {deleteModal && (
        <DeleteModal
          idTobeDeleted={idToEdit}
          deleteAction={deleteSubtask}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          type={DELETE_SUBTASK}
        />
      )}

      {isDeleteTimeTrackingModalOpen && (
        <DeleteModal
          idTobeDeleted={idToEdit}
          deleteAction={deleteManualEnteriesByTask}
          isModalOpen={isDeleteTimeTrackingModalOpen}
          setIsModalOpen={setIsDeleteTimeTrackingModalOpen}
          type={DELETE_ENTERIES_TASK}
        />
      )}
      {updateModal && (
        <UpdateModal
          idTobeUpdated={idToEdit}
          updateAction={updateManualEnteriesByTask}
          isModalOpen={updateModal}
          taskId
          setIsModalOpen={setUpdateModal}
          setValue={taskdata}
          type={UPDATE_ENTERIES_TASK}
        />
      )}
    </KooperModal>
  )
}

export default ViewTask
