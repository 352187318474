import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperDivider,
  KooperGrid,
  KooperGridColumn,
  KooperHeader,
  KooperIcon,
  KooperLabel,
  KooperProgress,
  KooperTooltip,
} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'
import {useState} from 'react'
import GoalsCardItem from '../GoalsCardItem'
import ActivityCard from './ActivityCard'
import UpdateGoalsModal from './UpdateGoalsModal'

const TeamNOrganizationGoalsDetails = () => {
  const goalsList = [
    {
      title: 'Goal 1',
      id: `1`,
      status: 'active',
      type: 'personal',
    },
  ]
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false)
  return (
    <>
      <KooperCard fluid>
        <KooperCardContent>
          <div className="d-flex">
            <div className="d-flex">
              <KooperIcon className="mr-2" name="target" color="teal" circular />
              <div>
                <span style={{fontSize: '0.9em', color: 'rgba(0,0,0,.6)'}}>Organizational GOAL</span>
                {/* <KooperLabel size="tiny" style={{marginLeft: '1em', background: '#f3f4f5'}}>
                      Self-development
                    </KooperLabel> */}
              </div>
            </div>
            <div className="icon-row" style={{display: 'flex', gap: '1rem'}}>
              <KooperTooltip
                content="Delete"
                size="mini"
                position="top center"
                trigger={<KooperButton icon={<SvgIcon path="common/delete" />} />}
              />
              <KooperTooltip
                content="Copy"
                size="mini"
                position="top center"
                trigger={<KooperButton icon={<SvgIcon path="common/clone" />} />}
              />

              <KooperTooltip
                content="Edit"
                size="mini"
                position="top center"
                trigger={<KooperButton icon={<SvgIcon path="common/edit2" />} />}
              />
              {/* <KooperTooltip
                content="Delete"
                size="mini"
                position="top center"
                trigger={<KooperButton icon={<SvgIcon path="common/share" />} />}
              /> */}
            </div>
          </div>
          <KooperHeader as="h2" className="mt-3">
            Organization
          </KooperHeader>
          <KooperHeader as="h3" color="grey" className="mb-2">
            fdgdfg
          </KooperHeader>
          <KooperGrid columns={2} style={{marginBottom: '2em'}}>
            <KooperGridColumn>
              <div className="d-flex jcfs">
                <KooperIcon name="user circle" size="large" />
                <div className="ml-2">
                  <div style={{color: 'rgba(0,0,0,.6)', fontSize: '0.9em', marginBottom: '0.2em'}}>GOAL OWNER</div>
                  <span>You</span>
                </div>
              </div>
            </KooperGridColumn>
            <KooperGridColumn>
              <div>
                <div style={{color: 'rgba(0,0,0,.6)', fontSize: '0.9em', marginBottom: '0.2em'}}>TIMELINE</div>
                <div>—</div>
              </div>
            </KooperGridColumn>
          </KooperGrid>
          <KooperDivider />
          <div style={{marginBottom: '2em'}} className="mt-2">
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1em'}}>
              <div>
                <span style={{fontSize: '1.2em', fontWeight: '500', marginRight: '1em'}}>Progress</span>
                <KooperLabel size="tiny" color="blue" style={{background: '#f0f5ff', color: '#3182ce'}}>
                  On track
                </KooperLabel>
              </div>
              <KooperButton onClick={() => setIsOpenUpdateModal(true)} primary>
                Update progress
              </KooperButton>
            </div>
            <div className="d-flex" style={{gap: 10}}>
              <div style={{flex: 1}}>
                <KooperProgress
                  size="tiny"
                  style={{
                    margin: 0,
                    flex: 1,
                    background: '#edf2f7',
                    height: '8px',
                  }}
                  percent={0}
                />
              </div>
              <div style={{textAlign: 'right', color: 'rgba(0,0,0,.6)', fontWeight: 600}}>0%</div>
            </div>
          </div>

          <div className="goal-key-results">
            <div className="key-results-title">Key results</div>
            <div className="d-flex">
              <div className="key-result-item">
                <KooperIcon name="flag outline" />
                <span>afegfgfg</span>
              </div>
              <div className="key-results-pagination">
                <span>1 / 11</span>
              </div>
            </div>
          </div>
        </KooperCardContent>
      </KooperCard>
      <KooperHeader as="h3">Sub-goals</KooperHeader>
      {goalsList?.length > 0
        ? goalsList?.map(goal => (
            <GoalsCardItem
              isSubGoal
              // reportPermissions={reportPermissions?.banner}
              // managePermission={assistPermissions?.manage_banner}
              // setCustomDeleteBanner={setCustomDeleteBanner}
              key={goal.id}
              goal={goal}
              // setCustomPublish={setCustomPublish}
            />
          ))
        : null}
      <KooperHeader as="h3">Activity</KooperHeader>
      <ActivityCard
        activities={[
          {title: 'activity 1', time: 'Today, 10:00 AM'},
          {title: 'activity 2', time: 'Today, 11:00 AM'},
        ]}
      />
      {isOpenUpdateModal && <UpdateGoalsModal isModalOpen={isOpenUpdateModal} setIsModalOpen={setIsOpenUpdateModal} />}
    </>
  )
}

export default TeamNOrganizationGoalsDetails
