export const INBOX_TYPES = {
  EMAIL: 'email',
  WEB: 'web',
  SMS: 'sms',
  CUSTOM_SMS: 'custom_sms',
  TWILIO_SMS: 'twilio',
  SUPPORT_FORM: 'support_form',
  SUPPORT_WIDGET: 'support_widget',
  INTERNAL_TICKET: 'internal_ticket',
  HELPDESK: 'helpdesk',
}

export const CAMPAIGN_TYPES = {
  MESSAGE: '1',
  EMAIL: '3',
  SMS: 'sms_campaign',
}
