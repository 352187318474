import SvgIcon from 'components/common/SvgIcon'
import {
  KooperCard,
  KooperCardContent,
  KooperDatePicker,
  KooperForm,
  KooperFormDropdown,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormTextArea,
  KooperHeader,
  KooperIcon,
  KooperInput,
  KooperTooltip,
} from 'kooper-library'
import {useState} from 'react'
import {Controller} from 'react-hook-form'
import KeyResultCreatCard from './KeyResultCreatCard'

const CreateContributePersonalGoals = ({control}) => {
  const [showSecondStep, setShowSecondstep] = useState()
  if (showSecondStep) {
    return (
      <>
        <KooperCard fluid>
          <KooperCardContent>
            <KooperFormGroup>
              <KooperFormField width={8} required>
                <div className="info-header">
                  <label>Goal Title</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Goal Title"
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="title"
                  control={control}
                  render={({onChange, value}) => <KooperFormInput maxLength={20} placeholder="Enter goal title" />}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormGroup>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Goal Description</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Goal Description"
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="description"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperFormTextArea maxLength={100} placeholder="Enter  goal description" />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>
          </KooperCardContent>
        </KooperCard>
        <KooperCard fluid>
          <KooperCardContent>
            <KooperForm>
              <KooperFormField>
                <KooperFormGroup>
                  <KooperFormField width={8}>
                    <div className="info-header">
                      <label>Timeline Start date</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Timeline start date"
                        size="mini"
                        position="top center"
                      />
                    </div>
                    <KooperDatePicker time={false} />
                  </KooperFormField>
                  <KooperFormField width={8}>
                    <div className="info-header">
                      <label>Timeline End date</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Timeline end date"
                        size="mini"
                        position="top center"
                      />
                    </div>
                    <KooperDatePicker time={false} />
                  </KooperFormField>
                </KooperFormGroup>
              </KooperFormField>
            </KooperForm>
          </KooperCardContent>
        </KooperCard>
        <KeyResultCreatCard control={control} />
      </>
    )
  }
  return (
    <KooperCard fluid>
      <KooperCardContent>
        <KooperHeader as="h4">Which team goal do you want to contribute to?</KooperHeader>
        <KooperFormField>
          <label>
            Select a team goal that you can meaningfully impact. If you’re unsure, ask your manager for help.
          </label>
        </KooperFormField>
        <div>
          <KooperFormGroup>
            <KooperFormField width={8}>
              <Controller
                name="owner"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormDropdown
                    placeholder="e.g. Marketing team"
                    fluid
                    selection
                    defaultValue="marketting"
                    options={[
                      {key: 'marketting', text: 'marketting', value: 'Marketting'},
                      {key: 'alex', text: 'Alex', value: 'alex'},
                      {key: 'sam', text: 'Sam', value: 'sam'},
                    ]}
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField width={8}>
              <KooperInput fluid icon="search" placeholder="Search by goal" className="goal-search-input" />
            </KooperFormField>
          </KooperFormGroup>

          {[{title: 'New O goal'}, {title: 'Organization'}, {title: 'sghtghgh'}, {title: 'test title'}].map(
            (item, index) => (
              <KooperCard onClick={() => setShowSecondstep(true)} key={index} fluid link className="goal-card">
                <KooperCardContent>
                  <div className="card-content">
                    <KooperIcon name="building outline" className="card-icon" />
                    <div>
                      <div className="card-subtitle">LINEACR</div>
                      <div className="card-title">{item.title}</div>
                    </div>
                  </div>
                </KooperCardContent>
              </KooperCard>
            )
          )}
        </div>
      </KooperCardContent>
    </KooperCard>
  )
}

export default CreateContributePersonalGoals
