/* eslint-disable no-nested-ternary */
import React from 'react'
import {KooperModal, KooperModalHeader, KooperButton, KooperModalContent, KooperModalActions} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'

function EmailDeleteModal(props) {
  const {isModalOpen, setIsModalOpen, onDeleteConversation, isTrashedConversation, isDraftConversation} = props
  return (
    <KooperModal
      size="tiny"
      centered={false}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
    >
      <KooperModalHeader>Delete This Conversations</KooperModalHeader>
      <KooperModalContent>
        <h5>
          {` Are you sure you want to delete this Conversations ${
            isTrashedConversation ? 'permanently' : isDraftConversation ? 'from Draft' : ''
          }`}
          ?
        </h5>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsModalOpen(false)}>
          No
        </KooperButton>
        <KooperButton onClick={onDeleteConversation} primary content="Yes" />
      </KooperModalActions>
    </KooperModal>
  )
}

export default EmailDeleteModal
