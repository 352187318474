import {joiResolver} from '@hookform/resolvers'
import {getProjectsTasksListSingleStage, updateProjectsTasksListStage} from 'actions/projects'
import {GET_PROJECTS_TASKS_LIST_SINGLE_STAGE} from 'actions/types'
import SvgIcon from 'components/common/SvgIcon'
import {colorOptions} from 'constants/variables'
import useApiResponse from 'hooks/impure/useApiResponse'
import Joi from 'joi'
import _ from 'lodash'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {removeDoubleQuotes} from 'utils/helper'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

const UpdateColumnModal = ({open, toggle, projectId, stageId}) => {
  const dispatch = useDispatch()

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        name: Joi.string().trim().min(2).max(20).label('Column').required(),
        color: Joi.string().required(),
      })
    ),
    defaultValues: {
      name: '',
      color: 'blue',
    },
  })

  useApiResponse({
    action: getProjectsTasksListSingleStage,
    payload: {projectId, stageId},
    dependency: [projectId, stageId],
    enabled: projectId && stageId,
    label: GET_PROJECTS_TASKS_LIST_SINGLE_STAGE,
    storePath: 'projects.stageData',
    onSuccess: (stageData = {}) => {
      const data = _.pick(stageData, ['name', 'color'])
      reset(data)
    },
  })

  const submitForm = data => {
    dispatch(updateProjectsTasksListStage({projectId, stageId, data}))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Update Column</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <label>Column Name</label>
            <Controller
              name="name"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  maxLength={20}
                  placeholder="Column name"
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.name && {content: removeDoubleQuotes(errors.name.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <label>Select Color</label>
            <Controller
              name="color"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  placeholder="Choose color"
                  options={colorOptions}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.color && {content: removeDoubleQuotes(errors.color.message)}}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Update
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default UpdateColumnModal
