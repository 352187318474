/* eslint-disable no-nested-ternary */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperContainer,
  KooperGrid,
  KooperGridColumn,
  KooperGridRow,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTablePlaceholder,
  KooperTableRow,
} from 'kooper-library'
import {SEQUENCE_CONTACTS} from 'actions/types'
import {sequnceContactList} from 'actions/sequence'

const SequenceContact = ({id}) => {
  const dispatch = useDispatch()
  const {
    sequenceContactData: {contacts = [], active = 0, pending = 0, paused = 0, unsubscribed = 0, completed = 0} = {},
  } = useSelector(state => state.sequences)
  const {isLoadingData, type = []} = useSelector(state => state.apiReducer)

  const renderLeadList = () => {
    return contacts.map(item => (
      <KooperTableRow key={item.id} className="tableLink">
        <KooperTableCell key="checkbox" onClick={e => e.stopPropagation()}>
          {`${item.firstName} ${item.lastName}`}
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  useEffect(() => {
    if (id) {
      dispatch(sequnceContactList(id))
    }
  }, [])

  return (
    <>
      <KooperCard fluid className="mb-0">
        <KooperCardContent>
          <KooperGrid columns={5} divided>
            <KooperGridRow>
              <KooperGridColumn>
                <div className="valueBox">
                  <KooperCardHeader>Active</KooperCardHeader>
                  <h3> {active}</h3>
                </div>
              </KooperGridColumn>
              <KooperGridColumn>
                <div className="valueBox">
                  <KooperCardHeader>Pending</KooperCardHeader>
                  <h3> {pending}</h3>
                </div>
              </KooperGridColumn>
              <KooperGridColumn>
                <div className="valueBox">
                  <KooperCardHeader>Paused</KooperCardHeader>
                  <h3> {paused}</h3>
                </div>
              </KooperGridColumn>
              <KooperGridColumn>
                <div className="valueBox">
                  <KooperCardHeader>Unsubscribed</KooperCardHeader>
                  <h3> {unsubscribed}</h3>
                </div>
              </KooperGridColumn>
              <KooperGridColumn>
                <div className="valueBox">
                  <KooperCardHeader>Completed</KooperCardHeader>
                  <h3> {completed}</h3>
                </div>
              </KooperGridColumn>
            </KooperGridRow>
          </KooperGrid>
        </KooperCardContent>
      </KooperCard>
      <div className="seqTabel">
        <KooperContainer fluid>
          <div className="pageHeader">
            <div className="pageTitle">
              <h5>Contacts</h5>
            </div>
          </div>
          <KooperTable basic selectable className="m-0">
            <KooperTableHeader>
              <KooperTableRow>
                <th className="pl-4">Email</th>
              </KooperTableRow>
            </KooperTableHeader>
            <KooperTableBody>
              {isLoadingData && type.includes(SEQUENCE_CONTACTS) ? (
                <KooperTablePlaceholder columns={1} row={5} />
              ) : contacts.length > 0 ? (
                renderLeadList()
              ) : (
                <tr>
                  <td className="emptyValue">No Data Available</td>
                </tr>
              )}
            </KooperTableBody>
          </KooperTable>
        </KooperContainer>
      </div>
    </>
  )
}

export default SequenceContact
