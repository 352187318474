import React from 'react'
import {Controller} from 'react-hook-form'
import _ from 'lodash'
import {KooperButton, KooperDropdown, KooperFormField, KooperFormInput, KooperIcon, KooperTooltip} from 'kooper-library'
import SvgIcon from './SvgIcon'

const EmailCreateMultiple = props => {
  const {emailFields, errors, appendEmail, control, removeEmail} = props
  return emailFields.map((emailItem, index) => (
    <div className="mutiple">
      <KooperFormField key={index} className={index !== 0 && 'mt-3'}>
        <div className="hover-plusicon d-flex">
          <Controller
            name={`emails[${index}].category`}
            defaultValue={emailItem.category}
            render={({onChange, value, onBlur}) => (
              <div className="mailActivity">
                <KooperDropdown
                  className="email-component"
                  icon="chevron down"
                  button
                  options={[
                    {value: 'work', text: 'Work Email', key: 'work'},
                    {
                      value: 'personal',
                      text: 'Personal Email',
                      key: 'personal',
                    },
                    {
                      value: 'other',
                      text: 'Other Email',
                      key: 'other',
                    },
                  ]}
                  onBlur={onBlur}
                  placeholder="Select category"
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  error={
                    _.get(errors, `emails[${index}].category`) && {
                      content: _.get(errors, `emails[${index}].category.message`),
                    }
                  }
                />
              </div>
            )}
            control={control}
          />

          <div className="d-flexb">
            {_.size(emailFields) > 1 && (
              <div className="ShowAddBtn">
                <KooperButton
                  style={{padding: '6px'}}
                  onClick={() => {
                    removeEmail(index)
                  }}
                >
                  <KooperTooltip
                    content="Delete Field"
                    size="mini"
                    position="left center"
                    trigger={<KooperIcon className="m-0" name="trash alternate outline" />}
                  />
                </KooperButton>
              </div>
            )}

            {index === emailFields.length - 1 && _.size(emailFields) < 3 && (
              <div className="ShowAddBtn">
                <KooperButton
                  style={{padding: '4px'}}
                  onClick={() => {
                    appendEmail({email: '', category: 'work'})
                  }}
                >
                  <KooperTooltip
                    content="Add Email"
                    size="mini"
                    position="left center"
                    trigger={<SvgIcon path="common/plus" />}
                  />
                </KooperButton>
              </div>
            )}
          </div>
        </div>
        <Controller
          name={`emails[${index}].email`}
          defaultValue={emailItem.email}
          render={({onChange, value}) => (
            <KooperFormInput
              type="text"
              fluid
              value={value}
              placeholder="Email"
              onChange={e => {
                onChange(e.target.value)
              }}
              icon={
                <i style={{padding: '12px'}} className="ShowAddBtn link icon">
                  <SvgIcon path="common/edit2" />
                </i>
              }
              error={
                _.get(errors, `emails[${index}].email`) && {
                  content: 'Enter a vaild email',
                }
              }
            />
          )}
          control={control}
        />
      </KooperFormField>
    </div>
  ))
}

export default EmailCreateMultiple
