import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperCheckbox,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormTextArea,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'
import {CREATE_EXPENSES_CATEGORY, EDIT_EXPENSES_CATEGORY, GET_SINGLE_EXPENSES_CATEGORY} from 'actions/types'
import {
  createExpensesCategory,
  getSingleExpensesCategory,
  editExpensesCategory,
  getExpensesCategory,
} from 'actions/expenses'
import {removeDoubleQuotes} from 'utils/helper'
import {createExpensesCategorySchema} from 'validation/Hr/expenses.schema'
import SvgIcon from 'components/common/SvgIcon'

const initialFormData = {
  category: '',
  description: '',
  needApproval: true,
}

const ExpensesCategoryModal = props => {
  const dispatch = useDispatch()
  const {open, toggleModal, editId, categoryToggle} = props
  const {handleSubmit, errors, control, reset} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(createExpensesCategorySchema),
    defaultValues: initialFormData,
  })

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {getSingleExpensesCategoryData} = useSelector(state => state.expenses)

  useEffect(() => {
    return () => {
      categoryToggle('')
    }
  }, [dispatch, categoryToggle])

  useEffect(() => {
    if (editId) {
      dispatch(getSingleExpensesCategory(editId))
    }
  }, [editId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_EXPENSES_CATEGORY)) {
      reset({
        category: getSingleExpensesCategoryData.category,
        description: getSingleExpensesCategoryData.description,
        needApproval: getSingleExpensesCategoryData.needApproval,
      })
    }
    if (successLabels.includes(EDIT_EXPENSES_CATEGORY) || successLabels.includes(CREATE_EXPENSES_CATEGORY)) {
      toggleModal(false)
      dispatch(getExpensesCategory())
    }
  }, [successLabels, toggleModal, getSingleExpensesCategoryData, reset, dispatch])

  const handleSave = data => {
    if (editId) {
      dispatch(editExpensesCategory(editId, data))
    } else {
      dispatch(createExpensesCategory(data))
    }
  }

  return (
    <KooperModal
      open={open}
      onClose={() => toggleModal(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      centered={false}
    >
      <KooperModalHeader >Category Field</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel" onSubmit={handleSubmit(handleSave)}>
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Name</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify name of category"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="category"
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  maxLength={20}
                  autoFocus
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  onKeyDown={e => e.key === 'Enter' && handleSubmit(data => handleSave(data))}
                  error={
                    errors.category && {
                      content: errors.category.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>

          <KooperFormField>
            <label>Description</label>
            <Controller
              name="description"
              render={({onChange, value}) => (
                <KooperFormTextArea
                  maxLength={200}
                  type="text"
                  value={value}
                  placeholder="Description"
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    errors.description && {
                      content: removeDoubleQuotes(errors.description.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>

          <KooperFormField width={8}>
            <Controller
              control={control}
              name="needApproval"
              render={({onChange, value}) => (
                <KooperFormField>
                  <div className="info-header">
                    <label>Need Approval?</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Enable to apply approval for adding field"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                </KooperFormField>
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton
          disabled={isLoadingData && (type.includes(EDIT_EXPENSES_CATEGORY) || type.includes(CREATE_EXPENSES_CATEGORY))}
          basic
          onClick={() => toggleModal(false)}
        >
          Cancel
        </KooperButton>
        <KooperButton
          loading={isLoadingData && (type.includes(EDIT_EXPENSES_CATEGORY) || type.includes(CREATE_EXPENSES_CATEGORY))}
          disabled={isLoadingData && (type.includes(EDIT_EXPENSES_CATEGORY) || type.includes(CREATE_EXPENSES_CATEGORY))}
          primary
          onClick={handleSubmit(data => handleSave(data))}
        >
          {editId ? 'Save' : 'Create'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default ExpensesCategoryModal
