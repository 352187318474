import React from 'react'
import {Controller, useFieldArray} from 'react-hook-form'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormRadio,
  KooperTooltip,
} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'
import RenderRules from './UserAudience/RenderRules'

const UserAudience = props => {
  const {style, control, errors, clearErrors, watch, setValue} = props

  const {
    fields: rulesFields,
    append: rulesAppend,
    remove: rulesRemove,
  } = useFieldArray({
    control,
    name: 'rules',
  })

  return (
    <KooperContainer style={style} className="stepWrap">
      <KooperCard fluid>
        <KooperCardContent>
          <h3 className="mb-0">User Audience</h3>
          <p className="mb-4">Set the condition and actions for sending campaigns to particular audience</p>
          <KooperForm>
            <Controller
              name="matchCase"
              control={control}
              render={({value, onChange}) => (
                <KooperFormField error={errors.matchCase && {content: errors.matchCase.message}}>
                  <label>Filters</label>
                  <p className="kooper-lead">
                    Users who match
                    <span style={{color: '#1e90ff', fontWeight: '600'}}> {value === 'and' ? 'ALL' : 'ANY'} </span>
                    of the below conditions
                  </p>
                  <KooperFormGroup inline>
                    <KooperFormRadio
                      value="and"
                      checked={value === 'and'}
                      onChange={(e, {value}) => onChange(value)}
                      label="All"
                      name="matchCaseRadio"
                    />
                    <KooperFormRadio
                      value="or"
                      checked={value === 'or'}
                      onChange={(e, {value}) => onChange(value)}
                      label="Any"
                      name="matchCaseRadio"
                    />
                  </KooperFormGroup>
                </KooperFormField>
              )}
            />
          </KooperForm>
          <div className="userAuu">
            <RenderRules
              rulesFields={rulesFields}
              rulesRemove={rulesRemove}
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              watch={watch}
              setValue={setValue}
            />
            {rulesFields.length < 3 && (
              <KooperTooltip
                position="right center"
                content="Add New Condition"
                size="mini"
                trigger={
                  <KooperButton
                    basic
                    icon
                    className="cmpgAddBtn"
                    onClick={() => rulesAppend({operator: 'or', filters: [{key: ''}]})}
                  >
                    <SvgIcon path="common/plus" />
                  </KooperButton>
                }
              />
            )}
          </div>
        </KooperCardContent>
      </KooperCard>
    </KooperContainer>
  )
}

export default UserAudience
