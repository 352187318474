import React from 'react'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {KooperMenu, KooperMenuItem} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'
import {isActiveNavlink} from 'utils/helper'
import {DASHBOARDS_PATH} from 'constants/path'

const DashboardsMenu = () => {
  const history = useHistory()
  const {url} = useRouteMatch()

  const menus = [
    {
      path: DASHBOARDS_PATH.OVERVIEW,
      title: 'Overview',
      icon: <SvgIcon path="analytics/reports/overview" />,
    },
    {
      path: DASHBOARDS_PATH.MARKETING,
      title: 'Marketing',
      icon: <SvgIcon path="analytics/dashboards/marketing" />,
    },
    {
      path: DASHBOARDS_PATH.SALES,
      title: 'Sales',
      icon: <SvgIcon path="analytics/dashboards/sales" />,
    },
    {
      path: DASHBOARDS_PATH.SUPPORT,
      title: 'Support',
      icon: <SvgIcon path="analytics/dashboards/support" />,
    },
    {
      path: DASHBOARDS_PATH.PROJECTS,
      title: 'Projects',
      icon: <SvgIcon path="analytics/dashboards/project" />,
    },
    {
      path: DASHBOARDS_PATH.FINANCE,
      title: 'Finance',
      icon: <SvgIcon path="analytics/dashboards/finance" />,
    },
    // {
    //   path: DASHBOARDS_PATH.HUMAN_RESOURCES,
    //   title: 'HR',
    //   icon: <SvgIcon path="analytics/dashboards/hr" />,
    // },
  ]

  return (
    <KooperMenu vertical secondary className="kooper-menu scrollbar-setting">
      {menus.map(({path, title, icon}) => (
        <KooperMenuItem
          key={path}
          active={isActiveNavlink(`${url}/${path}`)}
          onClick={() => history.push(`${url}/${path}`)}
        >
          {icon} {title}
        </KooperMenuItem>
      ))}
    </KooperMenu>
  )
}

export default DashboardsMenu
