import React, {useEffect, useState} from 'react'
import {KooperTab, KooperTabPane} from 'kooper-library'
import {useDispatch, useSelector} from 'react-redux'
import DeleteModal from 'components/common/DeleteModal'
import {COMPLETE_PROJECT_FINANCE_BILLING_INVOICE, DELETE_PROJECT_FINANCE_BILLING_INVOICE} from 'actions/types'
import {deleteProjectFinanceBillingInvoice, getProjectFinanceBillingOpenInvoicesList} from 'actions/projects'
import ProjectBillingUnbilledTime from './ProjectBillingUnbilledTime'
import ProjectBillingInvoice from './ProjectBillingInvoice'
import ProjectBillingInvoiceList from '../ProjectBillingInvoiceList'
import ProjectBillingUnbilledExpenses from './ProjectBillingUnbilledExpenses'

const ProjectBillingOpenInvoices = ({projectId, budgetId}) => {
  const dispatch = useDispatch()

  const [activeIndex, setActiveIndex] = useState(0)
  const [invoiceId, setInvoiceId] = useState(null)
  const [isDeleteModalOpen, setisDeleteModalOpen] = useState(false)
  const [idToDeleteInvoice, setIdToDeleteInvoice] = useState(null)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {billingOpenInvoicesList = []} = useSelector(state => state.projects)

  useEffect(() => {
    if (successLabels.includes(DELETE_PROJECT_FINANCE_BILLING_INVOICE)) {
      if (invoiceId === idToDeleteInvoice) {
        setInvoiceId(null)
      }
      setIdToDeleteInvoice(null)
      dispatch(getProjectFinanceBillingOpenInvoicesList({projectId, budgetId}))
    }
    if (successLabels.includes(COMPLETE_PROJECT_FINANCE_BILLING_INVOICE)) {
      setInvoiceId(null)
      setActiveIndex(0)
      dispatch(getProjectFinanceBillingOpenInvoicesList({projectId, budgetId}))
    }
  }, [successLabels, dispatch, projectId, budgetId, invoiceId, idToDeleteInvoice])

  const panes = [
    {
      menuItem: {key: 'unbilledTime', icon: 'clock', content: 'Unbilled Time'},
      render: () => (
        <KooperTabPane attached={false}>
          <ProjectBillingUnbilledTime projectId={projectId} budgetId={budgetId} />
        </KooperTabPane>
      ),
    },
    {
      menuItem: {key: 'unbilledExpense', icon: 'money', content: 'Unbilled Expense'},
      render: () => (
        <KooperTabPane attached={false}>
          <ProjectBillingUnbilledExpenses projectId={projectId} budgetId={budgetId} />
        </KooperTabPane>
      ),
    },
    ...(invoiceId
      ? [
          {
            menuItem: {
              key: 'invoice',
              icon: 'folder open',
              content: `Invoice : ${billingOpenInvoicesList.find(invoice => invoice.id === invoiceId)?.invoiceNumber}`,
            },
            render: () => (
              <KooperTabPane attached={false}>
                <ProjectBillingInvoice projectId={projectId} budgetId={budgetId} invoiceId={invoiceId} />
              </KooperTabPane>
            ),
          },
        ]
      : []),
  ]

  return (
    <div className="project-finance-billing-content">
      <div className="project-finance-billing-content-left">
        <ProjectBillingInvoiceList
          invoiceList={billingOpenInvoicesList}
          onListItemClick={invoiceId => {
            setInvoiceId(invoiceId)
            setActiveIndex(2)
          }}
          onDeleteClick={invoiceId => {
            setIdToDeleteInvoice(invoiceId)
            setisDeleteModalOpen(true)
          }}
        />
      </div>

      <KooperTab
        panes={panes}
        menu={{color: 'blue', secondary: true, pointing: true}}
        activeIndex={activeIndex}
        onTabChange={(e, data) => {
          setInvoiceId(null)
          setActiveIndex(data.activeIndex)
        }}
        className="project-finance-billing-content-right"
      />

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setisDeleteModalOpen}
          modalText="Are you sure you want to delete this invoice?"
          onDelete={() =>
            dispatch(deleteProjectFinanceBillingInvoice({projectId, budgetId, invoiceId: idToDeleteInvoice}))
          }
          type={DELETE_PROJECT_FINANCE_BILLING_INVOICE}
        />
      )}
    </div>
  )
}

export default ProjectBillingOpenInvoices
