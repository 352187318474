/* eslint-disable react/no-unknown-property */
import React from 'react'
import {FiActivity, FiCalendar, FiFileText} from 'react-icons/fi'
import moment from 'moment'
import _ from 'lodash'
import {
  KooperCard,
  KooperCardDescription,
  KooperCardHeader,
  KooperList,
  KooperListItem,
  KooperTab,
  KooperTabPane,
} from 'kooper-library'
import {GetFormattedDate, getTimeDifferenceFromNow, startCase} from 'utils/helper'
import SvgIcon from './SvgIcon'

const DurationConvertMinToString = val => {
  const hours = Math.floor(val / 60)
  const realmin = val % 60
  return hours
    ? `${`0${hours}`.slice(-2)}:${`0${realmin}`.slice(-2)} ${hours === 0 ? 'mins' : 'hours'}`
    : `${`0${realmin}`.slice(-2)} ${hours === 0 ? 'mins' : 'hours'}`
}

const MeetingTimeLineComp = ({list}) => (
  <KooperListItem>
    <KooperCard
      fluid
      style={{
        margin: '20px',
        display: 'contents',
        lineHeight: '25px',
      }}
    >
      <KooperCardHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <span style={{fontSize: '16px'}}>{list.summary}</span>
          <span>{moment(_.get(list, 'start.dateTime')).format('MMM DD, YYYY LT Z')}</span>
        </div>
      </KooperCardHeader>
      <KooperCardDescription>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <div>
            <div>Outcome</div>
            <span>{list.status}</span>
          </div>
          {list.attendees && (
            <div>
              <div>Attendees</div>
              {list.attendees.map(l => (
                <span>{l.email}</span>
              ))}
            </div>
          )}
          <div>
            <div>Duration</div>
            <span>{DurationConvertMinToString(list.duration)}</span>
          </div>
        </div>
        <div style={{textAlign: 'center'}}>
          <span>
            {`${list.agent} scheduled a meeting With ${_.get(list, 'formDetail.firstName')} ${_.get(
              list,
              'formDetail.lastName'
            )}`}
          </span>
        </div>
        <p>{`${_.get(list, 'videoConferenceLink') || ''}`}</p>
      </KooperCardDescription>
    </KooperCard>
  </KooperListItem>
)

const TimeLine = props => {
  const {
    allActivities = [],
    getAllInterActionsData = [],
    getAllNotesData = [],
    getAllUpdatesData = [],
    conversationTimeline = [],
    meetings = [],
  } = props

  const panes = [
    {
      menuItem: {key: 'all', icon: <SvgIcon path="clients/contacts/contacts-dashboard/all" />, content: 'All'},
      render: () => (
        <KooperTabPane attached={false} className="p-4">
          {allActivities.length > 0 ? (
            allActivities.map((list, i) => {
              // if (list.text) {
              //   return (
              //     <div className="kooper-timeline" key={`${i}text`}>
              //       <div className="icon">
              //         <FiFileText />
              //       </div>
              //       <div className="content">
              //         <article>
              //           <b>{list.activityDescription} </b> {list.name}
              //         </article>
              //         <p>list.text</p>
              //       </div>
              //       <div className="time">{GetFormattedDate(list.createdAt)}</div>
              //     </div>
              //   );
              // }
              // if (list.category) {
              //   return (
              //     <div className="kooper-timeline" key={`${i}category`}>
              //       <div className="icon">
              //         <FiLayers />
              //       </div>
              //       <div className="content">
              //         <article>
              //           <b>{` ${list.title} (${list.category})`} </b> {list.text}
              //         </article>
              //         <p>list.category</p>
              //       </div>
              //       <div className="time">{GetFormattedDate(list.createdAt)}</div>
              //     </div>
              //   );
              // }
              // if (list.activity) {
              return (
                <div className="kooper-timeline" key={`${i}activity`}>
                  <div className="icon">
                    <FiActivity />
                  </div>
                  <div className="content">
                    <article>
                      {list.activity !== 'add_conversation' && `${list.agent}`}{' '}
                      <span style={{color: 'var(--primary-color)'}}>{list.activityDescription}</span>
                      {/* <b>{abbreviateNumber(list.activityValue)} </b> */}
                      <br />
                      {list.activityDescription === 'added a interaction' && (
                        <>
                          {` ${list.title} (${list.category})`}
                          <br />
                          <span style={{fontWeight: '300'}}>{` ${list.description || ''}`}</span>
                        </>
                      )}
                      {list.activityDescription === 'added a note' && (
                        <>
                          {list.note.split('\n').map((val, id) => (
                            <React.Fragment key={id}>
                              {val}
                              {<br />}
                            </React.Fragment>
                          ))}
                        </>
                      )}
                      {list.mergeValues && (
                        <>
                          <b>{startCase(list.mergeValues)}</b> into{' '}
                        </>
                      )}
                      {typeof activityValue === 'string' ? startCase(list.activityValue) : list.activityValue}
                    </article>
                  </div>
                  <div className="time">{getTimeDifferenceFromNow(list.ts)}</div>
                </div>
              )
              // }
              // if (list.start) {
              //   return <MeetingTimeLineComp list={list} />;
              // }
            })
          ) : (
            <div align="center">No Data Available </div>
          )}
        </KooperTabPane>
      ),
    },
    {
      menuItem: {
        key: 'interaction',
        icon: <SvgIcon path="clients/contacts/contacts-dashboard/interaction" />,
        content: 'Interaction',
      },
      render: () => (
        <KooperTabPane attached={false} className="p-4">
          <KooperList relaxed="very" className="timeLineList">
            {getAllInterActionsData?.length > 0 ? (
              getAllInterActionsData.map((list, i) => (
                <div className="kooper-timeline" key={`${i}interaction`}>
                  <div className="icon">
                    <FiCalendar />
                  </div>
                  <div className="content">
                    <article>
                      {`${list.agent}`}&nbsp;
                      <span style={{color: 'var(--primary-color)'}}>{list.activityDescription}</span>
                      <br />
                      {` ${list.title} (${list.category})`}
                      <br />
                      <span style={{fontWeight: 300}}>{` ${list.description || ' '}`}</span>
                    </article>
                  </div>
                  <div className="time">{GetFormattedDate(list.createdAt)}</div>
                </div>
              ))
            ) : (
              <div align="center">No Data Available </div>
            )}
          </KooperList>
        </KooperTabPane>
      ),
    },
    {
      menuItem: {
        key: 'conversations',
        icon: <SvgIcon path="clients/contacts/contacts-dashboard/conversations" />,
        content: 'Conversations',
      },
      render: () => (
        <KooperTabPane attached={false} className="p-4">
          <KooperList relaxed="very" className="timeLineList">
            {conversationTimeline.length > 0 ? (
              conversationTimeline.map((list, i) => (
                <div className="kooper-timeline" key={`${i}allUpdates`}>
                  <div className="icon">
                    <FiActivity />
                  </div>
                  <div className="content">
                    <article>
                      {list.activity !== 'add_conversation' && `${list.agent}`}{' '}
                      <span style={{color: 'var(--primary-color)'}}>{list.activityDescription}</span>
                      <br />
                      {list.activityValue}
                    </article>
                  </div>
                  <div className="time">{GetFormattedDate(list.ts)}</div>
                </div>
              ))
            ) : (
              <div align="center">No Data Available </div>
            )}
          </KooperList>
        </KooperTabPane>
      ),
    },
    {
      menuItem: {key: 'notes', icon: <SvgIcon path="clients/contacts/contacts-dashboard/notes" />, content: 'Notes'},
      render: () => (
        <KooperTabPane attached={false} className="p-4">
          <KooperList relaxed="very" className="timeLineList">
            {getAllNotesData.length > 0 ? (
              getAllNotesData.map((list, i) => (
                <div className="kooper-timeline" key={`${i}allNotes`}>
                  <div className="icon">
                    <FiFileText />
                  </div>
                  <div className="content">
                    <article>
                      {/*  */}
                      {`${list.agent}`}&nbsp;
                      <span style={{color: 'var(--primary-color)'}}>{list.activityDescription}</span>
                      <br />
                      {list.text?.split('\n').map((val, id) => (
                        <React.Fragment key={id}>
                          {val}
                          <br />
                        </React.Fragment>
                      ))}
                    </article>
                  </div>
                  <div className="time">{GetFormattedDate(list.createdAt)}</div>
                </div>
              ))
            ) : (
              <div align="center">No Data Available </div>
            )}
          </KooperList>
        </KooperTabPane>
      ),
    },
    {
      menuItem: {
        key: 'updates',
        icon: <SvgIcon path="clients/contacts/contacts-dashboard/updates" />,
        content: 'Updates',
      },
      render: () => (
        <KooperTabPane attached={false} className="p-4">
          <KooperList relaxed="very" className="timeLineList">
            {getAllUpdatesData.length > 0 ? (
              getAllUpdatesData.map((list, i) => (
                <div className="kooper-timeline" key={`${i}allUpdates`}>
                  <div className="icon">
                    <FiActivity />
                  </div>
                  <div className="content">
                    <article>
                      {`${list.agent}`}&nbsp;
                      <span style={{color: 'var(--primary-color)'}}>{list.activityDescription}</span>
                      <br />
                      {list.activityValue}
                    </article>
                  </div>
                  <div className="time">{GetFormattedDate(list.ts)}</div>
                </div>
              ))
            ) : (
              <div align="center">No Data Available </div>
            )}
          </KooperList>
        </KooperTabPane>
      ),
    },
    {
      menuItem: {
        key: 'meetings',
        icon: <SvgIcon path="clients/contacts/contacts-dashboard/meeting" />,
        content: 'Meetings',
      },
      render: () => (
        <KooperTabPane attached={false} className="p-4">
          <KooperList relaxed="very" className="timeLineList">
            {meetings.length > 0 ? (
              meetings.map(list => <MeetingTimeLineComp list={list} />)
            ) : (
              <div align="center">No Data Available </div>
            )}
          </KooperList>
        </KooperTabPane>
      ),
    },
  ]
  return (
    <div>
      <KooperTab
        key="timeline"
        className="timeline"
        menu={{secondary: true, pointing: true, color: 'blue'}}
        panes={panes}
      />
    </div>
  )
}

export default TimeLine
