/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import {useHistory, useParams} from 'react-router'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {KooperButton, KooperTab} from 'kooper-library'

import {
  EDIT_SEQUENCE,
  GET_ALL_CONTACTS_IDS,
  GET_ALL_MAIL_INBOX,
  GET_ALL_TAG_LABEL,
  GET_SEGMENTS_LIST,
  GET_SEQUENCE,
  GET_USERS,
  UPDATE_SEQUENCE_STATUS,
} from 'actions/types'
import {getAllContactsIds} from 'actions/contacts'
import {getAllMailInbox} from 'actions/inbox'
import {getTagLabel} from 'actions/multiple_fields'
import {editSequences, getAllSequences, getSequence, updateSequenceStatus} from 'actions/sequence'
import {get} from 'utils/helper'
import {CreateSequence} from 'validation/Sales/sequence.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getUsers} from 'actions/settings'
import {getSegmentListWithNumbers} from 'actions/segment'
import {INBOX_TYPES} from 'constants/enums'
import {SEQUENCES_PATH} from 'constants/path'
import Settings from './Steps/Settings'
import Setup from './Steps/Setup'
import Flow from './Steps/Flow'

const initialData = {
  name: '',
  inboxId: '',
  contactIds: [],
  contactSegemntIds: [],
  contactSegmentCheckbox: false,
  contactCheckbox: false,
  startAt: new Date().toISOString(),
  timezone: '',
  startTime: '10:00:00',
  endTime: '18:00:00',
  workingDays: '',
  holidays: '',

  isSingleThread: false,
  addUnsubLink: false,
  updateContactAfterDays: '',
  pauseOnOutOfEmail: false,
  resumeAfterDays: '',
  steps: [],
  addContactLabels: [],
  contactUnsubLabels: [],
  firstStepLabels: [],
  contactReplyLabels: [],
  contactNoReplyLabels: [],
  stopsOnBookMeeting: true,
  stopsOnEmail: true,
  stopsOnText: true,
  stopsOnCloseDeal: true,
  stopsOnTaskStatus: true,
  delayBetweenEmails: 15,
  dailyLimit: '',
  vmoBranding: true,
  taskStatus: 'closed',
}

const SequencesDetails = props => {
  const {setWarningModal} = props
  const dispatch = useDispatch()
  const history = useHistory()
  const {id} = useParams()

  const dayList = Array.from({length: 30}, (value, index) => ({
    value: index + 1,
    text: index + 1,
    key: index + 1,
  }))

  const {control, errors, handleSubmit, reset, formState, watch, setError, clearErrors} = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: joiResolver(CreateSequence),
    defaultValues: initialData,
  })
  const {isDirty} = formState

  const watchMailBoxId = watch('inboxId')

  const [contactSegmentList, setContactSegmentList] = useState([])
  const [contactList, setcontactList] = useState([])
  const [stepList, setStepList] = useState([])
  const [allAssigness, setAllAssigness] = useState([])
  const [listOfInbox, setListOfInbox] = useState([])
  const [labelOptionsState, setLabelOptionsState] = useState([])
  const [isPublished, setIsPublished] = useState(false)
  const [isContactCheckbox, setIsContactCheckbox] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {sequenceData} = useSelector(state => state.sequences)

  const {sequencesPermissions} = useUserPermissions()
  const manageSequencesPermissions = sequencesPermissions?.manage_sequences

  useEffect(() => {
    if (id) {
      dispatch(getAllSequences())
      dispatch(getSequence(id))
    }
  }, [])

  useEffect(() => {
    if (successLabels.includes(EDIT_SEQUENCE)) {
      history.push(`/${SEQUENCES_PATH.PREFIX}`)
    }
  }, [successLabels])

  useEffect(() => {
    if (successLabels.includes(GET_SEQUENCE)) {
      reset(_.omit(sequenceData, ['accountId', 'id', 'steps', 'currentStep', 'isCompleted', 'isActive']))

      setIsPublished(sequenceData.isActive || false)
      setStepList(sequenceData.steps || [])
      setCurrentStep(sequenceData.currentStep)
    }
    if (successLabels.includes(UPDATE_SEQUENCE_STATUS)) {
      dispatch(getSequence(id))
    }
  }, [successLabels])

  const handleUnSaved = () => {
    if (isDirty) {
      setWarningModal(true)
    } else {
      setWarningModal(false)
    }
  }

  useEffect(() => {
    handleUnSaved()
  }, [isDirty])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
        content: (
          <>
            {`${get(['firstName'], user, '')} ${get(['lastName'], user, '')}`}
            <small className="ml-2 kooper-lead">({user.email})</small>
          </>
        ),
      }))
      setAllAssigness(list)
    },
  })

  useApiResponse({
    action: getSegmentListWithNumbers,
    enabled: 'contacts',
    label: GET_SEGMENTS_LIST,
    storePath: 'segment.segmentList',
    onSuccess: segmentList => {
      if (segmentList.contacts) {
        const list = segmentList.contacts.map(contact => ({
          value: contact.id,
          key: contact.id,
          text: `${contact.name} (${contact.contacts} contacts)`,
        }))
        setContactSegmentList(list)
      }
    },
  })

  useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
    onSuccess: contactsIdsList => {
      const list = contactsIdsList.map(contact => ({
        value: contact.id,
        key: contact.id,
        text: contact.name,
      }))
      setcontactList(list)
    },
  })

  useApiResponse({
    action: getAllMailInbox,
    enabled: true,
    label: GET_ALL_MAIL_INBOX,
    storePath: 'mailInbox.inbox',
    onSuccess: mailboxes => {
      const mailBoxList = mailboxes
        .filter(ls => ls.type === INBOX_TYPES.EMAIL && ls.emailVerified)
        .map(mail => {
          return {
            value: mail.id,
            key: mail.id,
            content: (
              <div>
                {get(['name'], mail, '')}
                <small className="ml-2 kooper-lead">({get(['email'], mail, '')})</small>
              </div>
            ),
            text: `${get(['name'], mail, '')}`,
          }
        })

      setListOfInbox(mailBoxList)
    },
  })

  useApiResponse({
    action: getTagLabel,
    enabled: true,
    label: GET_ALL_TAG_LABEL,
    storePath: 'multipleFields.getTagList',
    onSuccess: labelOptions => {
      const newData = labelOptions.map(label => ({
        value: label.id,
        key: label.id,
        text: label.label,
      }))
      setLabelOptionsState(newData)
    },
  })

  const handleCreateSequence = data => {
    const newList = _.omitBy(data, value => !!(value === '' || value === null || _.isEqual(value, [])))

    const payload = {
      ...newList,
      steps: stepList,
    }

    if (payload.steps < 1) {
      setError('steps', {
        type: 'manual',
        message: 'Please add minimum one step to proceed',
      })
    } else {
      setWarningModal(false)
      dispatch(editSequences(id, payload))
    }
  }

  const sequences = [
    {
      menuItem: {key: 'setup', icon: 'clipboard outline', content: 'Setup'},
      pane: {
        content: (
          <Setup
            control={control}
            errors={errors}
            isContactCheckbox={isContactCheckbox}
            listOfInbox={listOfInbox}
            contactList={contactList}
            contactSegmentList={contactSegmentList}
            watch={watch}
          />
        ),
      },
    },
    {
      menuItem: {
        key: 'flow',
        icon: 'unordered list',
        content: 'Flow',
      },
      pane: {
        content: (
          <Flow
            errors={errors}
            control={control}
            stepList={stepList}
            setStepList={setStepList}
            dayList={dayList}
            assignee={allAssigness}
            currentStep={currentStep}
            watchMailBoxId={watchMailBoxId}
            clearErrors={clearErrors}
          />
        ),
      },
    },
    {
      menuItem: {key: 'settings', icon: 'setting', content: 'Settings'},
      pane: {
        content: (
          <Settings
            control={control}
            errors={errors}
            labelOptionsState={labelOptionsState}
            watch={watch}
            dayList={dayList}
          />
        ),
      },
    },
  ]
  return (
    <div className="kooper-full-page">
      <div className="page-header" style={{boxShadow: 'none'}}>
        <div className="top">
          <div className="title">
            <KooperButton basic inverted onClick={() => history.push(`/${SEQUENCES_PATH.PREFIX}`)}>
              Exit
            </KooperButton>
          </div>

          <div className="center">
            <h5>{sequenceData?.name}</h5>
          </div>

          <div className="action">
            <LockPermissionTooltip isRoleAccessDenied={!manageSequencesPermissions}>
              <KooperButton
                primary
                basic
                inverted
                {...(manageSequencesPermissions && {
                  onClick: handleSubmit(handleCreateSequence),
                })}
              >
                Save
              </KooperButton>
            </LockPermissionTooltip>
            <LockPermissionTooltip isRoleAccessDenied={!manageSequencesPermissions}>
              <KooperButton
                primary
                className={!manageSequencesPermissions ? 'disabled-button' : ''}
                onClick={() =>
                  manageSequencesPermissions &&
                  dispatch(
                    updateSequenceStatus(id, {
                      isActive: !isPublished,
                    })
                  )
                }
              >
                {isPublished ? 'Unpublish' : 'Publish'}
              </KooperButton>
            </LockPermissionTooltip>
          </div>
        </div>
      </div>
      <div className="editTab">
        <KooperTab
          menu={{
            secondary: true,
            pointing: true,
            color: 'blue',
          }}
          panes={sequences}
          renderActiveOnly={false}
        />
      </div>
    </div>
  )
}

export default SequencesDetails
