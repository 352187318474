import {joiResolver} from '@hookform/resolvers'
import {
  createProjectFinanceExpense,
  getProjectFinanceSingleExpense,
  updateProjectFinanceExpense,
} from 'actions/projects'
import {GET_PROJECT_FINANCE_SINGLE_EXPENSE} from 'actions/types'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import Joi from 'joi'
import _ from 'lodash'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {removeDoubleQuotes} from 'utils/helper'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormTextArea,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

const ProjectBudgetExpenseModal = ({open, toggle, projectId, budgetId, expenseId}) => {
  const dispatch = useDispatch()

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        cost: Joi.number().min(1).required().messages({
          'number.min': 'Please enter a value greater than 0',
        }),
        description: Joi.string().trim().min(2).required(),
      })
    ),
    defaultValues: {
      cost: 0,
      description: '',
    },
  })

  useApiResponse({
    action: getProjectFinanceSingleExpense,
    payload: {projectId, budgetId, expenseId},
    dependency: [projectId, budgetId, expenseId],
    enabled: projectId && budgetId && expenseId,
    label: GET_PROJECT_FINANCE_SINGLE_EXPENSE,
    storePath: 'projects.budgetExpenseData',
    onSuccess: (budgetExpenseData = {}) => {
      const data = _.pick(budgetExpenseData, ['cost', 'description'])
      reset(data)
    },
  })

  const submitForm = data => {
    const payload = {...data, isBillable: true, isBudgetExpense: true}
    if (expenseId) {
      dispatch(updateProjectFinanceExpense({projectId, budgetId, expenseId, data: payload}))
    } else {
      dispatch(createProjectFinanceExpense({projectId, budgetId, data: payload}))
    }
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>{expenseId ? 'Update Budget Expense' : 'Add Budget Expense'}</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required width={8}>
            <label>Cost</label>
            <Controller
              name="cost"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.cost && {content: removeDoubleQuotes(errors.cost.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required width={8}>
            <label>Description</label>
            <Controller
              name="description"
              control={control}
              render={({value, onChange}) => (
                <KooperFormTextArea
                  maxLength={160}
                  style={{resize: 'none'}}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.description && {content: removeDoubleQuotes(errors.description.message)}}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          {expenseId ? 'Update' : 'Save'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default ProjectBudgetExpenseModal
