import {apiAction} from './api'
import {KOOPER_ACCOUNT_V1} from './endpoint'
import {GET_API_TOKEN} from './types'

export function getApiToken() {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/api-access/key`,
    method: 'GET',
    label: GET_API_TOKEN,
  })
}
