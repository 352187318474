import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'
import {COPY_PROJECT_MILESTONE} from 'actions/types'
import {copyProjectMilestone} from 'actions/projects'
import {removeDoubleQuotes} from 'utils/helper'
import {milestoneDuplicateSchema} from 'validation/Projects/projects/milestone.schema'
import SvgIcon from 'components/common/SvgIcon'

const MilestoneDuplicateModal = ({open, toggle, projectId, milestoneId, milestoneName}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {handleSubmit, control, errors} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(milestoneDuplicateSchema),
    defaultValues: {
      name: `${milestoneName} (copy)`,
    },
  })

  useEffect(() => {
    if (successLabels.includes(COPY_PROJECT_MILESTONE)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    dispatch(copyProjectMilestone({data, milestoneId, projectId}))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Duplicate Milestone</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <label>Name</label>
            <Controller
              name="name"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.name && {content: removeDoubleQuotes(errors.name.message)}}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Duplicate Milestone
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default MilestoneDuplicateModal
