import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import _ from 'lodash'
import {
  KooperModal,
  KooperModalHeader,
  KooperModalContent,
  KooperModalActions,
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperRadio,
} from 'kooper-library'

import {multipleMergeCompany} from 'actions/company'
import {get} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const MergeCompanyModal = props => {
  const {isModalOpen, setIsModalOpen, mergeId} = props
  const dispatch = useDispatch()

  const [mergeCompanyData, setMergeCompanyData] = useState([])
  const [mergeName, setMergeName] = useState([])
  const [mergeDomain, setMergeDomain] = useState([])
  const [mergeDescription, setMergeDescription] = useState([])
  const [mergeAddress, setMergeAddress] = useState([])
  const [meregContactType, setMeregContactType] = useState([])
  const [meregAssigneeId, setMeregAssigneeId] = useState([])
  const [meregisFollowed, setMeregisFollowed] = useState([])
  const [radiobtn, setRadiobtn] = useState({})

  const {getAllCompanyList = []} = useSelector(state => state.companies)

  useEffect(() => {
    let temp = []

    getAllCompanyList.forEach(list => {
      mergeId.forEach(id => {
        if (id === list.id) {
          const {id, name, domain, description, address, assigneeId, isFollowed, owner, contactType, contactTypeId} =
            list
          temp = [
            ...temp,
            {
              id,
              name,
              domain,
              description,
              address,
              assigneeId,
              isFollowed,
              owner,
              contactType,
              contactTypeId,
            },
          ]
        }
      })
    })
    setMergeCompanyData(temp)
  }, [mergeId])

  useEffect(() => {
    let tempRadioBtn

    // Name
    const nameNullRemove = mergeCompanyData.filter(function (el) {
      return !!el.name
    })
    const uniqName = _.uniqBy(nameNullRemove, 'name')
    setMergeName(uniqName)
    tempRadioBtn = {
      ...tempRadioBtn,
      name: get([[0], 'id'], uniqName, null),
    }

    // description
    const descriptionNullRemove = mergeCompanyData.filter(function (el) {
      return !!el.description
    })
    const uniqDescrption = _.uniqBy(descriptionNullRemove, 'description')
    setMergeDescription(uniqDescrption)
    tempRadioBtn = {
      ...tempRadioBtn,
      description: get([[0], 'id'], uniqDescrption, null),
    }

    // Address
    const addressNullRemove = mergeCompanyData.filter(function (el) {
      return !!el.address
    })
    const tempAddress = addressNullRemove.map(desgin => {
      return {
        address: `${get(['address', 'streetAddress'], desgin) || ''} ${get(['address', 'locality'], desgin) || ''} ${
          get(['address', 'state'], desgin) || ''
        } ${get(['address', 'country'], desgin) || ''} ${get(['address', 'postalCode'], desgin) || ''}`,
        id: desgin.id,
      }
    })
    const uniqAddress = _.uniqBy(tempAddress, 'address')
    setMergeAddress(uniqAddress)
    tempRadioBtn = {
      ...tempRadioBtn,
      address: get([[0], 'id'], uniqAddress, null),
    }

    // Domain
    const domainValueNullRemove = mergeCompanyData.filter(function (el) {
      return !!el.domain
    })
    const uniqDomain = _.uniqBy(domainValueNullRemove, 'domain')
    setMergeDomain(uniqDomain)
    tempRadioBtn = {
      ...tempRadioBtn,
      domain: get([[0], 'id'], uniqDomain, null),
    }

    // AssigneeId
    const assigneeIdNullRemove = mergeCompanyData.filter(function (el) {
      return !!el.assigneeId
    })
    const uniqAssigneeId = _.uniqBy(assigneeIdNullRemove, "owner['id']")
    setMeregAssigneeId(uniqAssigneeId)
    tempRadioBtn = {
      ...tempRadioBtn,
      assigneeId: get([[0], 'assigneeId'], uniqAssigneeId, null),
    }
    // contactType
    const contactTypeNullRemove = mergeCompanyData.filter(function (el) {
      return !!el.contactType
    })
    const uniqContactType = _.uniqBy(contactTypeNullRemove, "contactType['id']")
    setMeregContactType(uniqContactType)
    tempRadioBtn = {
      ...tempRadioBtn,
      contactTypeId: get([[0], 'contactTypeId'], uniqContactType, null),
    }

    // Followed
    const uniqisFollowed = _.uniqBy(mergeCompanyData, 'isFollowed')
    setMeregisFollowed(uniqisFollowed)
    tempRadioBtn = {
      ...tempRadioBtn,
      isFollowed: get([[0], 'isFollowed'], uniqisFollowed, null),
    }

    setRadiobtn(tempRadioBtn)
  }, [mergeCompanyData])

  const multipleMergeBtn = () => {
    const data = {
      ids: mergeId,
      data: _.omitBy(radiobtn, _.isNull),
    }
    dispatch(multipleMergeCompany(data))
  }

  return (
    <KooperModal
      className="kooper-modal"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isModalOpen}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <KooperModalHeader>Merge Company</KooperModalHeader>

      <KooperModalContent scrolling>
        <h4 className="pb-3">Merging Company ({mergeId.length})</h4>
        <KooperForm>
          <KooperFormField>
            <label>Name</label>
            {mergeName.length > 0 ? (
              mergeName.map((item, i) => (
                <KooperRadio
                  className="mr-2"
                  label={item.name}
                  name="radioName"
                  key={i}
                  value={item.id}
                  checked={item.id === radiobtn.name}
                  onChange={() => setRadiobtn({...radiobtn, name: item.id})}
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
          <KooperFormField>
            <label>Domain</label>
            {mergeDomain.length > 0 ? (
              mergeDomain.map((item, i) => (
                <KooperRadio
                  className="mr-2"
                  label={item.domain}
                  name="radioDomain"
                  key={i}
                  value={item.id}
                  checked={item.id === radiobtn.domain}
                  onChange={() => setRadiobtn({...radiobtn, domain: item.id})}
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
          <KooperFormField>
            <label>Description</label>
            {mergeDescription.length > 0 ? (
              mergeDescription.map((item, i) => (
                <KooperRadio
                  className="mr-2"
                  key={i}
                  label={get(['description'], item)}
                  name="radioDescription"
                  value={item.id}
                  checked={item.id === radiobtn.description}
                  onChange={() => setRadiobtn({...radiobtn, description: item.id})}
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
          <KooperFormField>
            <label>Address</label>
            {mergeAddress.length > 0 ? (
              mergeAddress.map((item, i) => (
                <KooperRadio
                  className="mr-2"
                  key={i}
                  label={get(['address'], item)}
                  name="radioAddress"
                  value={item.id}
                  checked={item.id === radiobtn.address}
                  onChange={() => setRadiobtn({...radiobtn, address: item.id})}
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
          <KooperFormField>
            <label>Contact Type</label>
            {meregContactType.length > 0 ? (
              meregContactType.map((item, i) => (
                <KooperRadio
                  className="mr-2"
                  key={i}
                  label={get(['contactType', 'type'], item)}
                  name="radioContactType"
                  value={get(['contactType', 'id'], item)}
                  checked={get(['contactTypeId'], item) === radiobtn.contactTypeId}
                  onChange={() =>
                    setRadiobtn({
                      ...radiobtn,
                      contactTypeId: get(['contactType', 'id'], item),
                    })
                  }
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
          <KooperFormField>
            <label>Assignee</label>
            {meregAssigneeId.length > 0 ? (
              meregAssigneeId.map((item, i) => (
                <KooperRadio
                  className="mr-2"
                  key={i}
                  label={`${get(['owner', 'firstName'], item, ' ')} ${get(['owner', 'lastName'], item, ' ')}`}
                  name="radioOwner"
                  value={get(['assigneeId'], item)}
                  checked={get(['assigneeId'], item) === radiobtn.assigneeId}
                  onChange={() =>
                    setRadiobtn({
                      ...radiobtn,
                      assigneeId: get(['assigneeId'], item),
                    })
                  }
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
          <KooperFormField>
            <label>Followed</label>
            {meregisFollowed.length > 0 ? (
              meregisFollowed.map((item, i) => (
                <KooperRadio
                  className="mr-2"
                  key={i}
                  label={get(['isFollowed'], item).toString()}
                  name="radioFollowed"
                  value={get(['isFollowed'], item)}
                  checked={get(['isFollowed'], item) === radiobtn.isFollowed}
                  onChange={() =>
                    setRadiobtn({
                      ...radiobtn,
                      isFollowed: get(['isFollowed'], item),
                    })
                  }
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={() => multipleMergeBtn()}>
          Save
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default MergeCompanyModal
