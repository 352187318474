/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {KooperForm, KooperFormField, KooperFormGroup, KooperFormInput, KooperFormSelect, KooperRadio, KooperTooltip} from 'kooper-library'
import {get, startCase} from 'utils/helper'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import PlanPreview from './PlanPreview'

const getPlan = plan => {
  if (plan.includes('Monthly')) {
    return plan.replace('Monthly', 'Yearly')
  }
  if (plan.includes('Yearly')) {
    return plan.replace('Yearly', 'Monthly')
  }
  return plan
}

const planIncludes = {
  standard: ['Lorem Ipsum', 'Lorem Ipsum', 'Lorem Ipsum'],
  business: ['Lorem Ipsum', 'Lorem Ipsum', 'Lorem Ipsum'],
  ultimate: ['Lorem Ipsum', 'Lorem Ipsum', 'Lorem Ipsum'],
}

const planNotIncludes = {
  standard: ['Lorem Ipsum', 'Lorem Ipsum', 'Lorem Ipsum'],
  business: ['Lorem Ipsum', 'Lorem Ipsum', 'Lorem Ipsum'],
}

const PlanStep2 = props => {
  const {paymentData, setPaymentData, couponError, setCouponError} = props

  const [addCoupon, setAddCoupon] = useState(false)
  const [tempCoupon, setTempCoupon] = useState('')

  const {getKooperPlansData = {}} = useSelector(state => state.billing)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const pricingIssueLearnMore = settingsLearnMoreUrl?.billing?.myPlan?.pricingIssue

  // const renderAddOnTable = () => {
  //   const addOnList = get(['addOns', paymentData.interval], getKooperPlansData, [])
  //   if (addOnList.length > 0) {
  //     return (
  //       <div className="addOnPlan">
  //         <h4>Add-On Application</h4>
  //         {addOnList.map((addOn, i) => (
  //           <div className="addOnList">
  //             <KooperCheckbox
  //               value={addOn.addOn}
  //               checked={paymentData.addons.some(add => addOn.addOn === add.addon)}
  //               onChange={(e, {checked, value}) => {
  //                 setPaymentData({
  //                   ...paymentData,
  //                   addons: !checked
  //                     ? paymentData.addons.filter(ad => ad.addon !== value)
  //                     : [
  //                         ...paymentData.addons,
  //                         {
  //                           addon: value,
  //                           quantity: 5,
  //                         },
  //                       ],
  //                 })
  //               }}
  //             />
  //             <div className="channel">
  //               <h5>{startCase(addOn.name)}</h5>
  //               <p>Add twitter channel integration to your product</p>
  //             </div>
  //             <div className="count">
  //               <span>
  //                 <MdClose />
  //               </span>
  //               <KooperDropdown
  //                 compact
  //                 selection
  //                 className="add-on-application-drop"
  //                 disabled={paymentData.addons.length === 0}
  //                 value={paymentData.addons[i]?.quantity || 5}
  //                 options={_.times(5, index => ({
  //                   key: index + 1,
  //                   text: `${index + 1}`,
  //                   value: index + 1,
  //                 }))}
  //                 onChange={(e, {value}) => {
  //                   const payTemp = {...paymentData}

  //                   payTemp.addons[i].quantity = value

  //                   setPaymentData(payTemp)
  //                 }}
  //               />
  //             </div>
  //             <h3>$ {(paymentData.addons[i]?.quantity || 5) * addOn.amount}</h3>
  //           </div>
  //         ))}
  //       </div>
  //     )
  //   }
  // }

  const renderPlanExtraInfo = planName => {
    return (
      <div className="planExtraInfo">
        <h5>What's include in {planName} plan?</h5>
        <ul>
          {get([planName], planIncludes, []).map((planInclude, index) => (
            <li key={index}>{planInclude}</li>
          ))}
        </ul>
        {planName !== 'ultimate' && (
          <>
            <h5>What's not include in {planName} plan?</h5>
            <ul>
              {get([planName], planNotIncludes, []).map((planNotInclude, index) => (
                <li key={index}>{planNotInclude}</li>
              ))}
            </ul>
          </>
        )}
        <h5>FAQ</h5>
        <a href={pricingIssueLearnMore}>Pricing Issue</a>
      </div>
    )
  }

  return (
    <div className="plan-two">
      <div className="plan-two-left">
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label>My Plan</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Select a plan which you wish to active"
                size="mini"
                position="top center"
              />
            </div>
            <KooperFormSelect
              fluid
              width={6}
              value={paymentData.plan}
              options={get(['plans', paymentData.interval], getKooperPlansData, []).map(plan => ({
                key: plan.plan,
                text: startCase(plan.name),
                value: plan.plan,
              }))}
              onChange={(e, {value}) => {
                setPaymentData({...paymentData, plan: value, coupon: ''})
                setTempCoupon('')
                setAddCoupon(false)
              }}
            />
          </KooperFormField>

          <KooperFormField required>
            <div className="info-header">
              <label>Agents</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify the number of agent’s support you want"
                size="mini"
                position="top center"
              />
            </div>
            <KooperFormInput
              type="text"
              width={4}
              onChange={e => {
                const re = /^(0?[1-9]|[1-9][0-9])$/
                if (e.target.value === '' || e.target.value === 0 || re.test(e.target.value)) {
                  setPaymentData({
                    ...paymentData,
                    quantity: e.target.value,
                  })
                }
              }}
              value={paymentData.quantity}
              error={
                paymentData.quantity === '0' ||
                (paymentData.quantity === '' && {
                  content: (
                    <p
                      style={{
                        position: 'absolute',
                        top: '-15px',
                        color: 'red',
                        width: '200px',
                      }}
                    >
                      must have 1 minimum agent
                    </p>
                  ),
                })
              }
              max={99}
              step={1}
              min={1}
            />
          </KooperFormField>

          <KooperFormField>
            <label>Subscription</label>
            <KooperFormGroup className="mt-3" inline>
              <KooperRadio
                id="subscription"
                name="subscription"
                className="mr-4"
                label="Annually (Limited Time)"
                value="yearly"
                checked={paymentData.interval === 'yearly'}
                onChange={(e, {value}) =>
                  setPaymentData({...paymentData, interval: value, plan: getPlan(paymentData.plan), addons: []})
                }
              />

              <KooperRadio
                id="subscription1"
                name="subscription"
                label="Monthly"
                value="monthly"
                checked={paymentData.interval === 'monthly'}
                onChange={(e, {value}) =>
                  setPaymentData({...paymentData, interval: value, plan: getPlan(paymentData.plan), addons: []})
                }
              />
            </KooperFormGroup>
          </KooperFormField>
        </KooperForm>
        {/* {renderAddOnTable()} */}
      </div>

      <div className="plan-two-right">
        <PlanPreview
          paymentData={paymentData}
          addCoupon={addCoupon}
          setAddCoupon={setAddCoupon}
          tempCoupon={tempCoupon}
          setTempCoupon={setTempCoupon}
          couponError={couponError}
          setCouponError={setCouponError}
        />
        {renderPlanExtraInfo(paymentData.plan.replace('Monthly', '').replace('Yearly', ''))}
      </div>
    </div>
  )
}

export default PlanStep2
