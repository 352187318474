import axios from 'axios'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {FiCode} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperButton,
  KooperCheckbox,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperEditorSmall,
  KooperFormField,
  KooperFormGroup,
  KooperFormSelect,
  KooperInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'
import {getMailBox} from 'actions/inbox'
import {extractTextContentFromHTML, startCase} from 'utils/helper'
import AttachmentPreview from 'components/common/AttachmentPreview'
import SvgIcon from 'components/common/SvgIcon'

const placeHolderList = [
  {value: '{{firstName}}', text: 'First Name'},
  {value: '{{lastName}}', text: 'Last Name'},
  {value: '{{companyName}}', text: 'Company Name'},
  {value: '{{jobTitle}}', text: 'Job title'},
]

const {CancelToken} = axios
const requestToken = CancelToken.source()

export default function EmailEditor(props) {
  const {handleAdd, addType, editData, toggle, assignee, watchMailBoxId, open} = props

  const dispatch = useDispatch()

  const [emailEditorValue, setEmailEditorValue] = useState(editData ? editData.messageHtml : '')
  const [emailEditorCurrentValue, setEmailEditorCurrentValue] = useState('')
  const [defaultFontSize, setDefaultFontSize] = useState(14)
  const [defaultFontFamily, setDefaultFontFamily] = useState('Roboto')
  const [data, setData] = useState(
    _.omit(
      editData || {
        subject: '',
        addSignature: false,
        trackClicks: false,
        trackOpen: false,
        attachments: [],
        assignee: '',
      },
      'messageHtml'
    )
  )

  const {getSingleInbox: inboxDetails = []} = useSelector(state => state.mailInbox)

  const addPlaceholder = (string, stringToBeAdded) => {
    return string + stringToBeAdded
  }

  useEffect(() => {
    if (watchMailBoxId) {
      dispatch(getMailBox(watchMailBoxId))
    }
  }, [watchMailBoxId])

  const addSign = () => {
    if (inboxDetails.signature) {
      const newStr = `${emailEditorCurrentValue}<br/>${inboxDetails.signature.body}`

      setEmailEditorCurrentValue(newStr)
      setEmailEditorValue(newStr)
    }

    if (Object.keys(inboxDetails.settings).length) {
      if (inboxDetails.settings.fontSize !== null) {
        setDefaultFontSize(inboxDetails.settings.fontSize)
      }

      if (inboxDetails.settings.fontFamily !== null) {
        setDefaultFontFamily(inboxDetails.settings.fontFamily)
      }
    } else {
      setDefaultFontSize(16)
      setDefaultFontFamily('Impact')
    }
  }

  return (
    <KooperModal
      size="small"
      open={open}
      onClose={() => toggle(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      className="seqMailModal"
    >
      <KooperModalHeader>{startCase(addType)}</KooperModalHeader>
      <KooperModalContent className="composeEmail">
        <div className="emailInput">
          <ul>
            <li>
              <div className="emlEdrHdr">Subject :</div>
              <KooperInput
                placeholder="Enter Subject"
                onChange={e => setData({...data, subject: e.target.value})}
                value={data.subject}
              />
              <div className="mailActivity">
                <KooperDropdown icon="chevron down" button direction="left" text="Insert Merge Fields">
                  <KooperDropdownMenu>
                    {placeHolderList.map(title => (
                      <KooperDropdownItem
                        onClick={() =>
                          setData({
                            ...data,
                            subject: addPlaceholder(data.subject, title.value),
                          })
                        }
                      >
                        {title.text}
                      </KooperDropdownItem>
                    ))}
                  </KooperDropdownMenu>
                </KooperDropdown>
              </div>
            </li>
            <li style={{justifyContent: 'normal'}}>
              <div className="emlEdrHdr">Assignee :</div>
              <div className="mailActivity">
                <KooperDropdown
                  direction="right"
                  button
                  icon="chevron down"
                  placeholder="Assignee"
                  options={assignee}
                  value={data.assignee}
                  onChange={(e, {value}) => {
                    setData({
                      ...data,
                      assignee: value,
                    })
                  }}
                />
              </div>
            </li>
          </ul>
        </div>
        <div className="p-3">
          {/* <KooperFormField width={10}>
            <label>Assignee :</label>
            <KooperFormSelect
              className="my-2"
              selectOnBlur={false}
              options={assignee}
              placeholder="Assignee"
              value={data.assignee}
              onChange={(e, {value}) => {
                setData({
                  ...data,
                  assignee: value,
                })
              }}
            />
          </KooperFormField> */}

          <KooperEditorSmall
            setContents={emailEditorValue}
            onChange={content => {
              setEmailEditorCurrentValue(content)
            }}
            setDefaultStyle={`font-size: ${defaultFontSize}px; font-family: ${defaultFontFamily};`}
          />
          <AttachmentPreview success="composeAttachment" cancelToken={requestToken} />
        </div>
      </KooperModalContent>
      <KooperModalActions className="d-flex">
        <div>
          <KooperTooltip
            size="mini"
            position="top center"
            content="Code"
            trigger={
              <KooperDropdown upward className="icon button mr-3 mb-2" floating icon={<FiCode />}>
                <KooperDropdownMenu>
                  {placeHolderList.map(title => (
                    <KooperDropdownItem
                      onClick={() => {
                        setEmailEditorCurrentValue(addPlaceholder(emailEditorCurrentValue, title.value))
                        setEmailEditorValue(addPlaceholder(emailEditorCurrentValue, title.value))
                      }}
                    >
                      {title.text}
                    </KooperDropdownItem>
                  ))}
                </KooperDropdownMenu>
              </KooperDropdown>
            }
          />
          <KooperCheckbox
            label="Track Open"
            onClick={() => {
              setData({
                ...data,
                trackOpen: !data.trackOpen,
              })
            }}
          />
          <KooperCheckbox
            label="Track Clicks"
            className="mx-3"
            onClick={() => {
              setData({
                ...data,
                trackClicks: !data.trackClicks,
              })
            }}
          />
          <KooperCheckbox
            label="Add Signature"
            onClick={() => {
              if (!data.addSignature) {
                addSign()
              } else {
                const newStr = emailEditorCurrentValue.replace('<p><br></p><p>null</p>', '')

                setEmailEditorCurrentValue(newStr)
                setEmailEditorValue(newStr)
              }
              setData({
                ...data,
                addSignature: !data.addSignature,
              })
            }}
          />
        </div>

        <div style={{marginTop: '20px'}}>
          <KooperButton basic className="mr-3" onClick={() => toggle(false)}>
            Cancel
          </KooperButton>
          <KooperButton
            primary
            onClick={() => {
              toggle(false)
              const messageText = extractTextContentFromHTML(emailEditorCurrentValue)
              data.messageText = messageText
              data.messageHtml = emailEditorCurrentValue
              handleAdd(data)
            }}
            disabled={!emailEditorCurrentValue || !data.subject}
          >
            Save
          </KooperButton>
        </div>
      </KooperModalActions>
    </KooperModal>
  )
}
