import Joi from 'joi'

export const emailValidation = Joi.string().email({tlds: {allow: false}})

export const inputStringValidation = Joi.string().trim().min(2).max(20).messages({
  'string.min': `Length must be at least 2 charactes`,
  'string.max': `Length must be less than or equal to 20 characters`,
})
export const textAreaValidation = Joi.string().trim()
export const inputPhoneValidation = Joi.string()
  .trim()
  .max(20)
  .regex(/^\+?[0-9]{4,20}$/)
  .required()
  .messages({
    'string.pattern.base': 'Enter a valid phone number',
    'string.empty': `Phone cannot be an empty field`,
    'any.required': `Phone is required`,
  })
export const phoneValidationLocal = Joi.string()
  .trim()
  .max(15)
  .regex(/^[0-9]+$/)
  .messages({'string.pattern.base': 'Enter a valid phone number'})
export const inputNumberValidation = Joi.number()
export const domainValidation = Joi.string()
  .regex(/^[A-Za-z0-9-]+$/)
  .min(4)
  .max(20)
  .trim()
  .messages({
    'string.pattern.base': 'Domain should have only number , aplhabet or `-`',
  })
