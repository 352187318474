import React from 'react'
import {useSelector} from 'react-redux'
import {KooperContainer} from 'kooper-library'
import StandardTemplate from './StandardTemplate'

function PreviewPurchaseOrder({watch, addressTemplate, liveNumber, style}) {
  const {PurchaseOrderForm = {}} = useSelector(state => state.purchaseOrders)
  const {previewFile} = useSelector(state => state.purchaseOrders.uploadFile)

  const {template: {templateNo = 2} = {}} = PurchaseOrderForm

  // const addressTem = {...PurchaseOrderForm, addressTemplate}

  if ((liveNumber || templateNo) === 2) {
    return (
      <KooperContainer>
        <StandardTemplate data={{...PurchaseOrderForm, ...watch, preview: previewFile, addressTemplate, style}} />
      </KooperContainer>
    )
  }
}

export default PreviewPurchaseOrder
