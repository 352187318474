import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {KooperButton, KooperDropdown, KooperTooltip} from 'kooper-library'
import {
  CLONE_PROJECT,
  CREATE_PROJECT,
  DELETE_PROJECT,
  GET_PROJECTS_LIST,
  GET_PROJECTS_LOOKUP,
  GET_USERS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_STATUS,
} from 'actions/types'
import {getUsers} from 'actions/settings'
import {getProjectsList, getProjectsLookup} from 'actions/projects'
import CreateFirst from 'components/common/CreateFirst'
import {projectStatusList, sortByList} from 'constants/projects'
import create from 'assets/create-user.svg'
import ProjectList from 'components/pages/Projects/Projects/ProjectList'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {getPermissionTooltipContent} from 'utils/helper'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import CreateProjectModalStep from 'components/pages/Projects/Projects/CreateProjectModalStep'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const Projects = () => {
  const dispatch = useDispatch()

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [usersList, setUsersList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [filter, setFilter] = useState({
    status: 'all',
    categoryIds: 'all',
    assignedTo: 'all',
    sortBy: 'category',
  })

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsList = [], categories = []} = useSelector(state => state.projects)

  const {isLoading} = useApiLoader({label: GET_PROJECTS_LIST})
  const {projectsAndCollaborationAccess, projectsPermissions} = useUserPermissions()
  const projectsAccess = projectsAndCollaborationAccess?.projects
  const manageProjectsPermissions = projectsPermissions?.manage_projects

  useEffect(() => {
    if (projectsAccess) {
      dispatch(getProjectsLookup({lookup: ['categories', 'users']}))
    }
  }, [projectsAccess, dispatch])

  useApiResponse({
    action: getUsers,
    enabled: projectsAccess,
    dependency: [projectsAccess],
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUsersList(list || [])
    },
  })

  useEffect(() => {
    if (!projectsAccess) return
    const newFilter = _.omitBy(filter, value => {
      if (value === 'none' || value === '' || value === 'all') {
        return true
      }
      return false
    })
    dispatch(getProjectsList(newFilter))
  }, [projectsAccess, filter, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_PROJECTS_LOOKUP)) {
      if (categories.length === 0) {
        setCategoryList([{key: 0, value: 0, text: 'General'}])
      } else {
        setCategoryList([...categories.map(({id, name}) => ({key: id, value: id, text: name}))])
      }
    }
  }, [successLabels, categories])

  useEffect(() => {
    const newFilter = _.omitBy(filter, value => {
      if (value === 'none' || value === '' || value === 'all') {
        return true
      }
      return false
    })
    if (
      successLabels.includes(CREATE_PROJECT) ||
      successLabels.includes(UPDATE_PROJECT) ||
      successLabels.includes(DELETE_PROJECT) ||
      successLabels.includes(CLONE_PROJECT) ||
      successLabels.includes(UPDATE_PROJECT_STATUS)
    ) {
      dispatch(getProjectsList(newFilter))
      dispatch(getProjectsLookup({lookup: ['categories', 'users']}))
    }
  }, [successLabels, dispatch, filter])

  const renderProjectModal = () => {
    if (isCreateModalOpen) {
      return <CreateProjectModalStep open={isCreateModalOpen} toggle={setIsCreateModalOpen} />
    }
    return null
  }

  if (isLoading && projectsAccess) {
    return <PageLoader />
  }

  if (
    !projectsAccess ||
    (!projectsList.length && filter.status === 'all' && filter.categoryIds === 'all' && filter.assignedTo === 'all')
  ) {
    return (
      <>
        {renderProjectModal()}
        <div className="setEmpty-wrap">
          <CreateFirst
            header="Project"
            subHeader="Track project from scratch, collaborate on project activities and resources seamlessly"
            addFirst={() => projectsAccess && manageProjectsPermissions && setIsCreateModalOpen(true)}
            buttonText="Create Project"
            tooltip={!projectsAccess || !manageProjectsPermissions}
            tooltipContent={getPermissionTooltipContent({
              featureAccess: projectsAccess,
              roleAccess: manageProjectsPermissions,
            })}
            imageSrc={create}
            Feature1="Create Projects"
            list1="Create & manage projects through entire life cycle seamlessly"
            Feature2="Custom Template"
            list2="Use templates & quickly launch new projects with ease"
            Feature3="Project Reporting"
            list3="One-click access to reports, and monitor time, budget"
          />
        </div>
      </>
    )
  }

  return (
    <>
      {renderProjectModal()}
      <div className="kooper-side-wrap" style={{height: '100%', background: 'rgb(249, 251, 253)'}}>
        <div className="page-header pb-0" style={{marginBottom: '24px'}}>
          <div className="page-title">
            <div className="page-titleIcon">
              <SvgIcon path="navigation/projects" />
              <h5>Projects</h5>
            </div>
          </div>
          <div className="page-action">
            <div className="mailActivity">
              <KooperTooltip
                content="Status"
                position="top center"
                size="mini"
                trigger={
                  <KooperDropdown
                    button
                    icon="chevron down"
                    options={[{key: 'all', value: 'all', text: 'All'}, ...projectStatusList].map(status => ({
                      key: status.key,
                      value: status.value,
                      text: status.text,
                    }))}
                    onChange={(e, {value}) => setFilter({...filter, status: value})}
                    defaultValue={filter.status}
                  />
                }
              />
              <KooperTooltip
                content={<div className="tooltip-content">Category</div>}
                position="top center"
                size="mini"
                trigger={
                  <KooperDropdown
                    button
                    icon="chevron down"
                    options={[{key: 'all', value: 'all', text: 'All'}, ...categoryList].map(category => ({
                      key: category.key,
                      value: category.value,
                      text: category.text,
                    }))}
                    onChange={(e, {value}) => setFilter({...filter, categoryIds: value})}
                    defaultValue={filter.categoryIds}
                  />
                }
              />
              <KooperTooltip
                content="Assignee"
                position="top center"
                size="mini"
                trigger={
                  <KooperDropdown
                    button
                    icon="chevron down"
                    options={[{key: 'all', value: 'all', text: 'All'}, ...usersList].map(user => ({
                      key: user.key,
                      value: user.value,
                      text: user.text,
                    }))}
                    onChange={(e, {value}) => setFilter({...filter, assignedTo: value})}
                    defaultValue={filter.assignedTo}
                  />
                }
              />
              <KooperTooltip
                content="Sort By"
                position="top center"
                size="mini"
                trigger={
                  <KooperDropdown
                    button
                    icon="chevron down"
                    options={sortByList.map(user => ({
                      key: user.key,
                      value: user.value,
                      text: user.text,
                    }))}
                    onChange={(e, {value}) => setFilter({...filter, sortBy: value})}
                    defaultValue={filter.sortBy}
                  />
                }
              />
            </div>
            <LockPermissionTooltip isRoleAccessDenied={!manageProjectsPermissions}>
              <KooperButton
                primary
                content="Create Project"
                className={!manageProjectsPermissions ? 'disabled-button' : ''}
                onClick={() => manageProjectsPermissions && setIsCreateModalOpen(true)}
              />
            </LockPermissionTooltip>
          </div>
        </div>

        <ProjectList filter={filter} setFilter={setFilter} rolePermissions={manageProjectsPermissions} />
      </div>
    </>
  )
}

export default Projects
