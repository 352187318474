import React, {useState} from 'react'
import {KooperModal, KooperModalHeader, KooperModalContent, KooperDatePicker, KooperModalActions, KooperButton} from 'kooper-library'
import moment from 'moment'
import SvgIcon from 'components/common/SvgIcon'

const OverdueModal = props => {
  const {isOpen, setIsOpen, onSuccess} = props

  const [time, setTime] = useState(moment(new Date()).add('days', 5).toDate())

  return (
    <KooperModal
      centered={false}
      size="tiny"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setIsOpen(false)}
      open={isOpen}
    >
      <KooperModalHeader >Overdue Time</KooperModalHeader>
      <KooperModalContent>
        <KooperDatePicker
          value={time}
          inputProps={{
            component: props => <input {...props} readOnly />,
          }}
          onChange={value => {
            setTime(value)
          }}
          time={false}
        />
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsOpen(false)}>
          Cancel
        </KooperButton>
        <KooperButton
          primary
          onClick={() => {
            onSuccess(time)
            setIsOpen(false)
          }}
        >
          Update
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}
export default OverdueModal
