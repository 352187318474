import DeleteModal from 'components/common/DeleteModal'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {
  KooperButton,
  KooperCheckbox,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperHeader,
  KooperIcon,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableFooter,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
} from 'kooper-library'
import {deleteProjectFinanceExpense} from 'actions/projects'
import {useDispatch, useSelector} from 'react-redux'
import {
  DELETE_PROJECT_FINANCE_EXPENSE,
  MOVE_PROJECT_FINANCE_BILLING_ITEMS,
  UPDATE_PROJECT_FINANCE_EXPENSE,
} from 'actions/types'
import {getFormattedDate, startCase} from 'utils/helper'
import {MdOutlineMoreVert} from 'react-icons/md'
import SvgIcon from 'components/common/SvgIcon'
import ProjectBillingExpenseModal from '../ProjectBillingExpenseModal'
import ProjectBillingMoveExpenseModal from './ProjectBillingMoveExpenseModal'

const ProjectBillingExpenseTableList = ({expenseList = [], currency = '', projectId, budgetId, invoiceId}) => {
  const dispatch = useDispatch()

  const [isBillingExpenseModalOpen, setIsBillingExpenseModalOpen] = useState(false)
  const [idToUpdateExpense, setIdToUpdateExpense] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [idToDeleteExpense, setIdToDeleteExpense] = useState(null)
  const [checked, setChecked] = useState([])
  const [isMoveExpenseModalOpen, setIsMoveExpenseModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECT_FINANCE_EXPENSE)) {
      setIsBillingExpenseModalOpen(false)
      setIdToUpdateExpense(null)
    }
    if (successLabels.includes(DELETE_PROJECT_FINANCE_EXPENSE)) {
      setIsDeleteModalOpen(false)
      setIdToDeleteExpense(null)
    }

    if (successLabels.includes(MOVE_PROJECT_FINANCE_BILLING_ITEMS)) {
      setChecked([])
      setIsMoveExpenseModalOpen(false)
    }
  }, [successLabels, dispatch, projectId, budgetId])

  const isCheckedAll = () => {
    const allIds = expenseList.map(lead => lead.id)

    const checkAll = _.xor(allIds, checked).length === 0 && allIds.length !== 0
    if (checkAll) {
      return true
    }
    return false
  }

  const handleCheckAll = () => {
    const allIds = expenseList.map(lead => lead.id)

    if (_.xor(allIds, checked).length === 0) {
      setChecked([])
    } else setChecked(allIds)
  }

  const handleCheck = id => {
    const isChecked = checked.some(lead => lead === id)

    if (isChecked) {
      const removeChecked = checked.filter(lead => lead !== id)
      setChecked(removeChecked)
    } else {
      setChecked([...checked, id])
    }
  }

  const isChecked = id => {
    const check = checked.find(lead => lead === id)
    if (check) {
      return true
    }
    return false
  }

  const renderTableBody = () => {
    if (expenseList.length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="7" className="emptyValue">
            No Data Available
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return expenseList.map(expense => (
      <KooperTableRow key={expense.id} className="tableLink">
        <KooperTableCell>
          <KooperCheckbox checked={isChecked(expense.id)} onChange={() => handleCheck(expense.id)} />
        </KooperTableCell>
        <KooperTableCell>{getFormattedDate(expense.expenseDate)}</KooperTableCell>
        <KooperTableCell>
          <KooperHeader as="h5">{startCase(expense.title)}</KooperHeader>
        </KooperTableCell>
        <KooperTableCell>{expense.description || '-'}</KooperTableCell>
        <KooperTableCell>{expense.createdBy?.name}</KooperTableCell>
        <KooperTableCell>
          {currency} {expense.cost}
        </KooperTableCell>
        <KooperTableCell>
          <KooperDropdown className="icon" direction="left" style={{marginLeft: 'auto'}} icon={<MdOutlineMoreVert />}>
            <KooperDropdownMenu>
              <KooperDropdownItem
                onClick={() => {
                  setIdToUpdateExpense(expense.id)
                  setIsBillingExpenseModalOpen(true)
                }}
              >
                <SvgIcon path="common/edit2" /> Edit
              </KooperDropdownItem>
              <KooperDropdownItem
                onClick={() => {
                  setIdToDeleteExpense(expense.id)
                  setIsDeleteModalOpen(true)
                }}
              >
                <SvgIcon path="common/delete" /> Delete
              </KooperDropdownItem>
            </KooperDropdownMenu>
          </KooperDropdown>
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  const getTotalCost = () => {
    return expenseList.reduce((acc, item) => acc + item.cost, 0)
  }

  return (
    <>
      <KooperTable basic>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>
              <KooperCheckbox checked={isCheckedAll()} onChange={handleCheckAll} />
            </KooperTableHeaderCell>
            <KooperTableHeaderCell>Date</KooperTableHeaderCell>
            <KooperTableHeaderCell>Expense</KooperTableHeaderCell>
            <KooperTableHeaderCell>Description</KooperTableHeaderCell>
            <KooperTableHeaderCell>Who</KooperTableHeaderCell>
            <KooperTableHeaderCell>Cost</KooperTableHeaderCell>
            <KooperTableHeaderCell>Actions</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>{renderTableBody()}</KooperTableBody>
        {expenseList.length !== 0 && (
          <KooperTableFooter>
            <KooperTableRow>
              <KooperTableCell colSpan="4" />
              <KooperTableCell textAlign="right">Sub-Total:</KooperTableCell>
              <KooperTableCell>
                {currency} {getTotalCost()}
              </KooperTableCell>
              <KooperTableCell colSpan="1" />
            </KooperTableRow>
          </KooperTableFooter>
        )}
      </KooperTable>

      {expenseList.length !== 0 && (
        <KooperButton
          className="mt-3"
          basic
          primary
          disabled={!checked.length}
          onClick={() => setIsMoveExpenseModalOpen(true)}
        >
          Move Items
        </KooperButton>
      )}

      {isBillingExpenseModalOpen && (
        <ProjectBillingExpenseModal
          open={isBillingExpenseModalOpen}
          toggle={setIsBillingExpenseModalOpen}
          projectId={projectId}
          budgetId={budgetId}
          expenseId={idToUpdateExpense}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          modalText="Are you sure you want to delete this expense?"
          onDelete={() => dispatch(deleteProjectFinanceExpense({projectId, budgetId, expenseId: idToDeleteExpense}))}
        />
      )}

      {isMoveExpenseModalOpen && (
        <ProjectBillingMoveExpenseModal
          open={isMoveExpenseModalOpen}
          toggle={setIsMoveExpenseModalOpen}
          projectId={projectId}
          budgetId={budgetId}
          expenseIds={checked}
          invoiceId={invoiceId}
        />
      )}
    </>
  )
}

export default ProjectBillingExpenseTableList
