import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {Controller} from 'react-hook-form'
import {
  KooperButton,
  KooperDivider,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

function Rating({setToggleModal, toggleModal, activeSection, index, control, data, watch}) {
  return (
    <KooperModal
      open={toggleModal === 'rating'}
      onClose={() => setToggleModal(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      centered={false}
    >
      <KooperModalHeader >Edit Rating</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormGroup widths="equal">
            <KooperFormField required>
              <div className="info-header">
                <label className="label-class">Label for lowest point</label>
              </div>
              <Controller
                name={`templateSections[${activeSection}].sectionFields[${index}].maxPointLabel`}
                defaultValue={watch(`templateSections[${activeSection}].sectionFields[${index}].maxPointLabel`)}
                control={control}
                render={({value, onChange, onBlur}) => (
                  <KooperFormInput value={value} onChange={(e, {value}) => onChange(value)} onBlur={onBlur} />
                )}
              />
            </KooperFormField>
            <KooperFormField required>
              <div className="info-header">
                <label className="label-class">Label for highest point</label>
              </div>
              <Controller
                name={`templateSections[${activeSection}].sectionFields[${index}].minPointLabel`}
                defaultValue={watch(`templateSections[${activeSection}].sectionFields[${index}].minPointLabel`)}
                control={control}
                render={({value, onChange, onBlur}) => (
                  <KooperFormInput value={value} onChange={(e, {value}) => onChange(value)} onBlur={onBlur} />
                )}
              />
            </KooperFormField>
          </KooperFormGroup>

          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Number of options</label>
            </div>
            <Controller
              name={`templateSections[${activeSection}].sectionFields[${index}].numberOfButtons`}
              defaultValue={watch(`templateSections[${activeSection}].sectionFields[${index}].numberOfButtons`)}
              control={control}
              render={({value, onChange, onBlur}) => (
                <KooperFormInput type="number" value={value} onChange={(e, {value}) => onChange(+value)} onBlur={onBlur} />
              )}
            />
          </KooperFormField>
          <KooperDivider />
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Question</label>
            </div>
            <Controller
              name={`templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].question`}
              defaultValue={watch(
                `templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].question`
              )}
              control={control}
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  placeholder="Enter Question"
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  onBlur={onBlur}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Input For</label>
            </div>
            <Controller
              name={`templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].inputFor`}
              defaultValue={watch(
                `templateSections[${activeSection}].sectionFields[${index}].fieldQuestions[${0}].inputFor`
              )}
              render={({value, onChange}) => (
                <KooperFormSelect
                  search
                  fluid
                  options={[
                    {key: 'both', value: 'both', text: 'Both'},
                    {key: 'employee', value: 'employee', text: 'Employee'},
                    {key: 'Reviewer', value: 'Reviewer', text: 'Reviewer'},
                  ]}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                />
              )}
              control={control}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton onClick={() => setToggleModal(false)}>Cancel</KooperButton>
        <KooperButton primary onClick={() => setToggleModal(false)}>
          Ok
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default Rating
