import SvgIcon from 'components/common/SvgIcon'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperIcon,
  KooperLabel,
  KooperProgress,
  KooperTooltip,
} from 'kooper-library'
import {startCase} from 'lodash'
import {FaArchive, FaClock, FaTimes} from 'react-icons/fa'
import {useHistory} from 'react-router-dom'

const GoalsCardItem = ({goal, rolePermissions = true, isSubGoal = false}) => {
  const history = useHistory()

  const getStatus = status => {
    if (status === 'active') return 'Active'
    if (status === 'inactive') return 'In-Active'
    if (status === 'archived') return 'Archived'
    return null
  }
  return (
    <KooperCard fluid className="goals-list-card">
      <KooperCardContent onClick={() => history.push(`/goals/details/${goal?.type}`)}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '2rem',
          }}
        >
          <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem', minWidth: '35%'}}>
            <KooperIcon name="building outline" size="large" />
            <span className="goal-title">{startCase(goal.title)}</span>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2rem',
              flex: 1,
              color: 'rgba(0,0,0,.6)',
            }}
          >
            <span style={{minWidth: '20%'}}>LINEACR</span>
            <span>
              Nov 13, 2024
              {/* {goal.dueDate ? new Date(goal.dueDate).toLocaleDateString() : 'No Date'} */}
            </span>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                flex: 1,
              }}
            >
              <span>0%</span>
              <KooperProgress
                percent={0}
                size="tiny"
                style={{
                  margin: 0,
                  flex: 1,
                  background: '#edf2f7',
                  height: '4px',
                }}
              />
              <KooperLabel
                size="tiny"
                style={{
                  background: '#ebf5ff',
                  color: '#3182ce',
                  fontWeight: 'normal',
                  marginLeft: '1rem',
                }}
              >
                On track
              </KooperLabel>
            </div>
          </div>

          {!isSubGoal && (
            <KooperTooltip
              content="More"
              size="mini"
              position="top center"
              trigger={
                <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon mr-0 button">
                  <KooperDropdownMenu direction="left">
                    <KooperDropdownItem>
                      <SvgIcon path="common/check-circle" /> Update Progress
                    </KooperDropdownItem>
                    <KooperDropdownItem>
                      <SvgIcon path="common/edit2" /> Edit
                    </KooperDropdownItem>
                    <KooperDropdownItem>
                      <FaArchive /> Archive
                    </KooperDropdownItem>
                    <KooperDropdownItem>
                      <SvgIcon path="common/delete" /> Delete
                    </KooperDropdownItem>
                  </KooperDropdownMenu>
                </KooperDropdown>
              }
            />
          )}
        </div>
      </KooperCardContent>
    </KooperCard>
  )
}

export default GoalsCardItem
