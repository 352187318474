/* eslint-disable no-nested-ternary */
import {GET_ALL_EMPLOYEES} from 'actions/types'
import React from 'react'
import {useSelector} from 'react-redux'
import {getFormattedDate} from 'utils/helper'
import {
  KooperLabel,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
} from 'kooper-library'

function AppraisalList() {
  const {myAppraisalData} = useSelector(state => state.employees)
  const {isLoadingData, type} = useSelector(state => state.apiReducer)

  const renderTableBody = () => {
    return myAppraisalData.map(({id, templateDetails, createdAt, status, reviewer, isPrivate}, index) => (
      <KooperTableRow key={id} className="tableLink">
        <KooperTableCell>{index + 1}</KooperTableCell>
        <KooperTableCell>{templateDetails.name}</KooperTableCell>
        <KooperTableCell>{getFormattedDate(createdAt)}</KooperTableCell>
        <KooperTableCell>
          <KooperLabel className="tag-label">{status.replace(/_/g, ' ')}</KooperLabel>
        </KooperTableCell>
        <KooperTableCell>{reviewer}</KooperTableCell>
        <KooperTableCell />
        <KooperTableCell />
        <KooperTableCell>{isPrivate === true ? 'Yes' : 'No'}</KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <>
      <KooperTable sortable basic selectable>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>#</KooperTableHeaderCell>
            <KooperTableHeaderCell>Template Name</KooperTableHeaderCell>
            <KooperTableHeaderCell>Appraisal Date</KooperTableHeaderCell>
            <KooperTableHeaderCell>Status</KooperTableHeaderCell>
            <KooperTableHeaderCell>Reviewer</KooperTableHeaderCell>
            <KooperTableHeaderCell>Points</KooperTableHeaderCell>
            <KooperTableHeaderCell>Weightage</KooperTableHeaderCell>
            <KooperTableHeaderCell>Private</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>
          {isLoadingData && type.includes(GET_ALL_EMPLOYEES) ? (
            <KooperTablePlaceholder columns={8} />
          ) : (myAppraisalData || []).length > 0 ? (
            renderTableBody()
          ) : (
            <tr>
              <td colSpan={10} className="emptyValue">
                No Data Available
              </td>
            </tr>
          )}
        </KooperTableBody>
      </KooperTable>
    </>
  )
}

export default AppraisalList
