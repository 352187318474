import React from 'react'
import {Controller} from 'react-hook-form'
import {BiConversation} from 'react-icons/bi'
import {
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperDivider,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormRadio,
  KooperTabPane,
} from 'kooper-library'

const PrefrencesPane = ({control, errors, isActive, pauseTimerIdle}) => {
  return (
    <KooperTabPane>
      <KooperCard fluid className="switchBox">
        <KooperCardContent>
          <div className="d-flex">
            <div className="d-flex" style={{justifyContent: 'flex-start'}}>
              <div className="checkbox-card-image">
                <BiConversation />
              </div>
              <div>
                <h5>Conversation Tracking</h5>
                <p className="kooper-lead">Automatiacilly logs the time spent on tickets</p>
              </div>
            </div>
            <Controller
              control={control}
              name="isTimeTracking"
              render={({value, onChange}) => (
                <div className="d-flex">
                  <KooperFormRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                </div>
              )}
            />
          </div>
        </KooperCardContent>
        <KooperCardContent>
          <KooperForm>
            <KooperFormField disabled={!isActive}>
              <label>Tracking Type</label>
              <p className="kooper-lead">Select the type of tracking for your agents and admins</p>
              <Controller
                control={control}
                name="isAutoForConversation"
                render={({value, onChange}) => (
                  <KooperFormGroup>
                    <KooperFormRadio
                      value
                      label="Automatic"
                      checked={value === true}
                      onChange={(e, {value}) => onChange(value)}
                      className="mr-4"
                    />
                    <KooperFormRadio
                      label="Manual"
                      value={false}
                      checked={value === false}
                      onChange={(e, {value}) => onChange(value)}
                    />
                  </KooperFormGroup>
                )}
              />
            </KooperFormField>
            <KooperFormField disabled={!isActive}>
              <label>Specify actions to be tracked</label>
              <p className="kooper-lead">Select your custom specific options which you wish to track</p>
              <KooperFormField className="mb-2" disabled={!isActive}>
                <Controller
                  control={control}
                  name="onConversation"
                  render={({value, onChange}) => (
                    <KooperCheckbox label="Reply" toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </KooperFormField>
              <KooperFormField disabled={!isActive}>
                <Controller
                  control={control}
                  name="onDraftAndReply"
                  render={({value, onChange}) => (
                    <KooperCheckbox
                      label="Compose Draft"
                      toggle
                      checked={value}
                      onChange={(e, {checked}) => onChange(checked)}
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormField>
            <KooperFormField disabled={!isActive}>
              <label>Time Pause Limit</label>
              <p className="kooper-lead">Select the type of tracking for your agents and admins</p>
              <KooperFormField disabled={!isActive}>
                <Controller
                  control={control}
                  name="pauseTimerIdle"
                  render={({value, onChange}) => (
                    <KooperFormGroup>
                      <KooperFormRadio
                        value
                        label="Default"
                        checked={value === true}
                        onChange={(e, {value}) => onChange(value)}
                        className="mr-4"
                      />
                      <KooperFormRadio
                        label="Manual"
                        value={false}
                        checked={value === false}
                        onChange={(e, {value}) => onChange(value)}
                      />
                    </KooperFormGroup>
                  )}
                />
              </KooperFormField>
              <p className="kooper-lead">Default Time Pause Limit is 30s.</p>
              {!pauseTimerIdle && (
                <Controller
                  name="timerPauseLimit"
                  control={control}
                  render={({value, onChange, onBlur}) => (
                    <KooperFormInput
                      width={2}
                      type="text"
                      placeholder="50 seconds"
                      value={value}
                      onBlur={onBlur}
                      onChange={e => onChange(e.target.value)}
                      error={
                        errors.timerPauseLimit && {
                          content: errors.timerPauseLimit.message,
                        }
                      }
                    />
                  )}
                />
              )}
            </KooperFormField>
          </KooperForm>
        </KooperCardContent>
        <KooperCardContent>
          <h4 className="mb-0">Activity</h4>
          <p className="kooper-lead">Tick the checkboxes for which you wish to manually track time</p>
          <KooperDivider />
          <KooperForm>
            <KooperFormField disabled={!isActive}>
              <label>Manually Track time for</label>
              <p className="kooper-lead">Tick the checkboxes for which you wish to manually track time</p>
              <KooperFormGroup className="mb-0">
                <KooperFormField disabled={!isActive}>
                  <Controller
                    control={control}
                    name="call"
                    render={({value, onChange}) => (
                      <KooperCheckbox label="Call" checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </KooperFormField>
                <KooperFormField disabled={!isActive}>
                  <Controller
                    control={control}
                    name="meeting"
                    render={({value, onChange}) => (
                      <KooperCheckbox label="Meeting" checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </KooperFormField>
                <KooperFormField disabled={!isActive}>
                  <Controller
                    control={control}
                    name="task"
                    render={({value, onChange}) => (
                      <KooperCheckbox label="Task" checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </KooperFormField>
                <KooperFormField disabled={!isActive}>
                  <Controller
                    control={control}
                    name="event"
                    render={({value, onChange}) => (
                      <KooperCheckbox label="Event" checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </KooperFormField>
                <KooperFormField disabled={!isActive}>
                  <Controller
                    control={control}
                    name="milestone"
                    render={({value, onChange}) => (
                      <KooperCheckbox label="Milestone" checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </KooperFormField>
              </KooperFormGroup>
            </KooperFormField>
            <KooperFormField disabled={!isActive}>
              <div className="d-flex">
                <div>
                  <label>Automatic time tracking for activities</label>
                  <p className="mt-2 mb-0 kooper-lead">Run the timer for one activity at a time</p>
                </div>
                <Controller
                  control={control}
                  name="oneActivityAtTime"
                  render={({value, onChange}) => (
                    <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </div>
            </KooperFormField>
          </KooperForm>
        </KooperCardContent>
      </KooperCard>
    </KooperTabPane>
  )
}

export default PrefrencesPane
