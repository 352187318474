import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  KooperButton,
  KooperChipInput,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperFormTextArea,
  KooperGrid,
  KooperGridColumn,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'

import {addCompany} from 'actions/company'
import {createTagSettings} from 'actions/multiple_fields'
import {get, removeDoubleQuotes, KooperCountryList} from 'utils/helper'
import EmailCreateMultiple from 'components/common/EmailCreateMultiple'
import PhoneCreateMultiple from 'components/common/PhoneCreateMultiple'
import WebsiteCreateMultiple from 'components/common/WebsiteCreateMultiple'
import CreateLabel from 'components/common/CreateLabel'
import KooperCustomDropdown from 'components/common/KooperCustomDropdown'
import {createCompanySchema} from 'validation/Customers/company.schema'
import {addSocialOptionList} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'

const CreateCompanyModal = props => {
  const dispatch = useDispatch()
  const {isModalOpen, setIsModalOpen} = props
  const initialCreateInfo = {
    name: '',
    domain: '',
    emails: [{category: 'work', email: ''}],
    phones: [{category: 'work', phone: ''}],
    websites: [{category: 'work', url: ''}],
    address: {},
    contactTypeId: null,
    assigneeId: null,
    socials: [{category: 'facebook', url: ''}],
    description: '',
    labelIds: [],
  }
  const {handleSubmit, errors, control} = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(createCompanySchema),
    defaultValues: initialCreateInfo,
  })
  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: 'emails',
  })
  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: 'phones',
  })
  const {
    fields: websiteFields,
    append: appendWebsite,
    remove: removeWebsite,
  } = useFieldArray({
    control,
    name: 'websites',
  })
  const [createInfo, setCreateInfo] = useState({})
  const {getTagList} = useSelector(state => state.multipleFields)
  const {getTypeListData} = useSelector(state => state.companies)
  const {getUsersList = {}} = useSelector(state => state.settings)

  const createCompany = data => {
    const getNewData = _.pickBy(
      {
        ...data,
        emails: _.filter(_.get(data, 'emails'), item => item.email !== '' && item.email !== undefined),
        phones: _.filter(_.get(data, 'phones'), item => item.phone !== '' && item.phone !== undefined),
        websites: _.filter(_.get(data, 'websites'), item => item.url !== '' && item.url !== undefined),
        socials: _.filter(_.get(data, 'socials'), item => item.url !== '' && item.url !== undefined),
      },
      _.identity
    )
    dispatch(addCompany({...getNewData, ...createInfo}))
  }

  const [labelOptionsState, setLabelOptionsState] = useState([])
  const [newLabelModal, setNewLabelModal] = useState(false)
  const [listOfLable, setListOfLabel] = useState([])
  const [userOption, setUserOption] = useState([])
  const [contactTypeList, setContactTypeList] = useState([])
  useEffect(() => {
    if (getTagList) {
      const list = getTagList.map(user => ({
        value: user.id,
        text: user.label,
        key: user.id,
        label: {
          style: {background: user.color},
          empty: true,
          circular: true,
        },
      }))
      setLabelOptionsState(list)
    }
  }, [getTagList])
  useEffect(() => {
    if (getTypeListData) {
      const list = getTypeListData.map(item => ({
        value: item.id,
        text: item.type,
        key: item.id,
      }))
      setContactTypeList(list)
    }
  }, [getTypeListData])
  useEffect(() => {
    if (getUsersList) {
      const list = getUsersList.users.map(user => ({
        value: user.id,
        text: `${get(['firstName'], user, '')} ${get(['lastName'], user, '')}`,
        id: user.id,
      }))
      setUserOption(list)
    }
  }, [getUsersList])

  const createNewLabel = () => {
    if (newLabelModal) {
      return (
        <CreateLabel
          isModalOpen={newLabelModal}
          setIsModalOpen={setNewLabelModal}
          createMethod={data => dispatch(createTagSettings(data))}
        />
      )
    }
  }

  return (
    <KooperModal
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isModalOpen}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <KooperModalHeader>Add Company</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperForm className="errorLabel">
          <KooperFormGroup widths="equal">
            <KooperFormField required>
              <label>Name</label>
              <Controller
                name="name"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormInput
                    maxLength={20}
                    type="text"
                    placeholder="Name"
                    onChange={(e, {value}) => onChange(value)}
                    value={value}
                    error={
                      errors.name && {
                        content: removeDoubleQuotes(errors.name.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>

            <KooperFormField>
              <div className="info-header">
                <label>Domain</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Mention domain of the company"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                name="domain"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormInput
                    type="url"
                    maxLength={20}
                    placeholder="e.g. abc@email.com"
                    onChange={(e, {value}) => onChange(value)}
                    value={value}
                    error={
                      errors.domain && {
                        content: removeDoubleQuotes(errors.domain.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
          </KooperFormGroup>

          <KooperFormGroup widths="equal">
            <KooperFormField>
              <EmailCreateMultiple
                emailFields={emailFields}
                errors={errors}
                appendEmail={appendEmail}
                control={control}
                removeEmail={removeEmail}
              />
            </KooperFormField>
            <KooperFormField>
              <PhoneCreateMultiple
                phoneFields={phoneFields}
                errors={errors}
                appendPhone={appendPhone}
                control={control}
                removePhone={removePhone}
              />
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormGroup widths="equal">
            <KooperFormField>
              <div className="info-header">
                <label>Assignee</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Assign agent for this company"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                name="assigneeId"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormSelect
                    placeholder="Assignee"
                    fluid
                    selection
                    value={value}
                    options={userOption}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField>
              <div className="info-header">
                <label>Contact Type</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Choose which type of contact need to be included"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                name="contactTypeId"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormSelect
                    placeholder="Contact Type"
                    options={contactTypeList}
                    fluid
                    search
                    selection
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                  />
                )}
              />
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormField>
            <WebsiteCreateMultiple
              websiteFields={websiteFields}
              errors={errors}
              appendWebsite={appendWebsite}
              control={control}
              removeWebsite={removeWebsite}
            />
          </KooperFormField>
          <KooperFormField className="mb-2">
            <KooperFormField>
              <label>Address</label>
              <Controller
                name="address.streetAddress"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormInput
                    maxLength={20}
                    type="text"
                    value={value}
                    placeholder="Street"
                    onChange={(e, {value}) => onChange(value)}
                  />
                )}
              />
            </KooperFormField>

            <KooperGrid className="mt-2">
              <KooperGridColumn width={8}>
                <KooperFormField>
                  <Controller
                    name="address.locality"
                    control={control}
                    render={({onChange, value}) => (
                      <KooperFormInput
                        type="text"
                        maxLength={20}
                        value={value}
                        placeholder="Locality"
                        onChange={(e, {value}) => onChange(value)}
                      />
                    )}
                  />
                </KooperFormField>
              </KooperGridColumn>
              <KooperGridColumn width={8}>
                <KooperFormField>
                  <Controller
                    name="address.state"
                    control={control}
                    render={({onChange, value}) => (
                      <KooperFormInput
                        type="text"
                        maxLength={20}
                        value={value}
                        placeholder="State"
                        onChange={(e, {value}) => onChange(value)}
                      />
                    )}
                  />
                </KooperFormField>
              </KooperGridColumn>
              <KooperGridColumn width={8}>
                <Controller
                  name="address.country"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperFormSelect
                      search
                      fluid
                      value={value}
                      options={KooperCountryList}
                      selection
                      placeholder="Country"
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
              </KooperGridColumn>
              <KooperGridColumn width={8}>
                <KooperFormField>
                  <Controller
                    name="address.postalCode"
                    control={control}
                    render={({onChange, value}) => (
                      <KooperFormInput
                        type="text"
                        placeholder="Postal code"
                        maxLength={10}
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={
                          errors.address?.postalCode && {
                            content: removeDoubleQuotes(errors.address?.postalCode.message),
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
              </KooperGridColumn>
            </KooperGrid>
          </KooperFormField>

          <KooperFormField>
            <div className="info-header">
              <label>Add Social</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Add social media links of the company"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="socials[0].url"
              control={control}
              render={({onChange, value}) => (
                <KooperFormInput
                  label={
                    <Controller
                      name="socials[0].category"
                      control={control}
                      render={({onChange, value}) => (
                        <KooperFormSelect
                          value={value}
                          placeholder="Social"
                          options={addSocialOptionList}
                          onChange={(e, {value}) => {
                            onChange(value)
                          }}
                          error={
                            errors.socials?.[0].category && {
                              content: removeDoubleQuotes(errors.socials?.[0].category.message),
                            }
                          }
                        />
                      )}
                    />
                  }
                  value={value}
                  labelPosition="left"
                  placeholder="add link"
                  maxLength={200}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  error={
                    errors.socials?.[0].url && {
                      content: removeDoubleQuotes(errors.socials?.[0].url.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
          <KooperFormField>
            <label>Description</label>
            <Controller
              name="description"
              control={control}
              render={({onChange, value}) => (
                <KooperFormTextArea
                  type="textarea"
                  maxLength={200}
                  value={value}
                  placeholder="Write your description here"
                  onChange={(e, {value}) => onChange(value)}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField>
            <div className="info-header">
              <label className="d-block">Add Tag</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Label companies with tags"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              control={control}
              name="labelIds"
              render={({value, onChange}) => (
                <KooperChipInput
                  defaultValue={listOfLable.map(tag => {
                    return {
                      id: tag.value,
                      text: (
                        <>
                          <div
                            style={{
                              height: '10px',
                              width: '10px',
                              background: tag.label.style.background,
                              borderRadius: '50%',
                              marginRight: '10px',
                              display: 'inline-block',
                            }}
                          />
                          {tag.text}
                        </>
                      ),
                    }
                  })}
                  onChange={value => {
                    onChange(value)
                    const valueIds = value.map(tag => tag.id)
                    const removeTag = listOfLable.filter(contact => valueIds.indexOf(contact.value) !== -1)
                    setListOfLabel(removeTag)
                    const id = _.map(removeTag, 'value')
                    setCreateInfo({...createInfo, labelIds: id})
                  }}
                  hideInputField
                />
              )}
            />
            <Controller
              control={control}
              name="labelIds"
              render={({value, onChange}) => (
                <KooperCustomDropdown
                  options={labelOptionsState}
                  multiple
                  dropdownProps={{
                    icon: (
                      <KooperTooltip
                        content="Tags"
                        size="mini"
                        position="bottom center"
                        trigger={<SvgIcon path="common/tag" id="TooltipTagInbox" />}
                      />
                    ),
                    direction: 'right',
                    closeOnChange: false,
                  }}
                  menu={{scrolling: true}}
                  input={{
                    icon: 'search',
                    iconPosition: 'left',
                    className: 'search',
                  }}
                  activeIndicator
                  value={listOfLable.map(labels => labels.value)}
                  onClick={value => {
                    onChange(value)
                    const updatedList = labelOptionsState.filter(val => value.indexOf(val.value) !== -1)
                    setListOfLabel(updatedList)
                  }}
                  extraAction={{
                    content: '+ Add Tag',
                    onClick: () => setNewLabelModal(true),
                  }}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </KooperButton>
        <KooperButton color="blue" onClick={handleSubmit(createCompany)}>
          Add
        </KooperButton>
      </KooperModalActions>
      {createNewLabel()}
    </KooperModal>
  )
}

export default CreateCompanyModal
