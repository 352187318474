import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import Expenses from 'components/pages/HumanResourse/Expenses/Expenses'
import Category from 'components/pages/HumanResourse/Expenses/Settings/Category/Category'
import {EXPENSES_PATH} from 'constants/path'

const routes = [
  {path: EXPENSES_PATH.ALL, component: Expenses, title: 'All Expenses', exact: true},
  {path: EXPENSES_PATH.CATEGORIES, component: Category, title: 'Category', exact: false},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={`/${EXPENSES_PATH.PREFIX}/${path}`}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} />
        <Component {...props} />
      </>
    )}
  />
)

const ExpenseRoutes = () => (
  <Switch>
    <Route exact path={`/${EXPENSES_PATH.PREFIX}`}>
      <Redirect to={`/${EXPENSES_PATH.PREFIX}/${EXPENSES_PATH.ALL}`} />
    </Route>
    {routes.map(renderRoute)}
    <Route path={`/${EXPENSES_PATH.PREFIX}/*`}>
      <Redirect to={`/${EXPENSES_PATH.PREFIX}/${EXPENSES_PATH.ALL}`} />
    </Route>
  </Switch>
)

export default ExpenseRoutes
