import React, {useCallback} from 'react'
import {FaCalendar} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectsTimesheetTimelogsByUserList} from 'actions/analytics_projects'
import {startCase} from 'utils/helper'
import {
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperGrid,
  KooperGridColumn,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {GET_PROJECTS_TIMESHEET_TIMELOGS_BY_USER_LIST} from 'actions/types'
import SvgIcon from 'components/common/SvgIcon'
import DateRangeGroup from '../../DateRangeGroup'

const TimesheetByUsers = () => {
  const dispatch = useDispatch()

  const {type = []} = useSelector(state => state.apiReducer)
  const {projectsTimesheetTimelogsByUserList = []} = useSelector(state => state.analyticsProjects)

  const actionsToDispatch = useCallback(
    data => {
      const {from, to} = data
      const payload = {from, to}
      dispatch(getProjectsTimesheetTimelogsByUserList(payload))
    },
    [dispatch]
  )

  const renderTableBody = () => {
    if (type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_BY_USER_LIST)) {
      return <KooperTablePlaceholder columns={4} />
    }
    if (!type.includes(GET_PROJECTS_TIMESHEET_TIMELOGS_BY_USER_LIST) && !projectsTimesheetTimelogsByUserList.length) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="4" className="emptyValue">
            No Data Available
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return projectsTimesheetTimelogsByUserList.map(project => (
      <KooperTableRow key={project.id} className="tableLink">
        <KooperTableCell>{startCase(project.name)}</KooperTableCell>
        <KooperTableCell>{project.loggedHrs || '-'}</KooperTableCell>
        <KooperTableCell>{project.billableHrs || '-'}</KooperTableCell>
        <KooperTableCell>{project.nonBillableHrs || '-'}</KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/time-sheet-users" />
            <h5>Timesheet By Users</h5>
          </div>
        </div>
        <div className="page-action">
          <DateRangeGroup actionsToDispatch={actionsToDispatch} />
        </div>
      </div>

      <KooperGrid>
        <KooperGridColumn>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Timesheet By Users</KooperCardHeader>
              <KooperTooltip
                content="List of agents based on timesheet"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0">
              <KooperTable basic>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell>Name</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Logged Time</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Billable Time</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Non Billable Time</KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>{renderTableBody()}</KooperTableBody>
              </KooperTable>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default TimesheetByUsers
