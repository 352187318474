import React from 'react'
import {
  KooperGrid,
  KooperGridColumn,
  KooperImage,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
} from 'kooper-library'
import {getFormattedDate} from 'utils/helper'

// import logo from "images/logo.svg";

function StandardTemplate({data}) {
  const {
    setupData = {},
    buyerInfo: {contacts = [], company} = {},
    yourInfo = {},
    template = {},
    products = {},
    signature = {},
    preview,
  } = data

  const yourCompany = yourInfo?.company || {}

  return (
    <div className="quote-theme">
      <div className="quote-thm-wrap">
        <div className="quo-thm-two">
          <div className="quo-thm-two-header d-flex">
            <h3>{template.header}</h3>
            <div className="logo">
              <KooperImage src={preview} /> Kooper Pvt Ltd
            </div>
            <h5>{setupData.name}</h5>
          </div>
          <div className="customer d-flex">
            {contacts?.map(contact => {
              return (
                <div key={contact.id} className="clientInfo">
                  {/* <h6>Bill To :</h6> */}
                  <h4>{`${contact.firstName} ${contact.lastName}`}</h4>
                  <h5>{contact.jobTitle}</h5>
                  <p>
                    {/* <FiPhone /> */} {contact.phone}
                  </p>
                  <a href="mailto:">
                    {/* <SvgIcon path="common/mail" /> */} {contact.email}
                  </a>
                </div>
              )
            })}

            {company && (
              <div className="clientInfo">
                <h4>{company.name}</h4>
                <h5>
                  {company.streetAddress} {company.locality}
                </h5>
                <h5>
                  {company.state} {company.country}
                </h5>
                <h5>{company.postalCode}</h5>
              </div>
            )}

            <div className="clientInfo">
              {setupData.prefixId || setupData.quoteNo ? (
                <>
                  <h4>Quote No</h4>
                  <h5>{setupData.quoteNo}</h5>
                </>
              ) : null}

              <h4 className="mt-3">Date</h4>
              <h5>{getFormattedDate(new Date())}</h5>
              {setupData.validTill ? (
                <>
                  <h4 className="mt-3">Validity</h4>
                  <h5>{getFormattedDate(setupData.validTill)}</h5>
                </>
              ) : null}
            </div>
          </div>

          {products?.products?.length ? (
            <div className="bill-table">
              <KooperTable celled>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell>Product Name</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Item</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Unit Price</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Amount ($)</KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>
                  {products.products.map(prod => {
                    return (
                      <KooperTableRow>
                        <KooperTableCell>{prod.productId}</KooperTableCell>
                        <KooperTableCell>{prod.quantity}</KooperTableCell>
                        <KooperTableCell>{prod.unitPrice}</KooperTableCell>
                        <KooperTableCell>{prod.unitTotal}</KooperTableCell>
                      </KooperTableRow>
                    )
                  })}

                  <KooperTableRow style={{fontWeight: '500', fontSize: '15px'}}>
                    <KooperTableCell colspan="3">Total</KooperTableCell>
                    <KooperTableCell>{products.subTotal}</KooperTableCell>
                  </KooperTableRow>

                  {products?.otherCharges?.map((charge, index) => {
                    return (
                      <KooperTableRow key={`charge${index}`}>
                        <KooperTableCell
                          colspan="2"
                          // rowspan="3"
                          style={{verticalAlign: 'top'}}
                        >
                          {charge.name}
                        </KooperTableCell>
                        <KooperTableCell style={{textAlign: 'right'}}>
                          {charge.type}
                          {charge.subType === '%' ? `( ${charge.value} %)` : null}
                        </KooperTableCell>
                        <KooperTableCell>{charge.total}</KooperTableCell>
                      </KooperTableRow>
                    )
                  })}

                  <KooperTableRow style={{fontWeight: '600', fontSize: '15px'}}>
                    <KooperTableCell colspan="3" style={{textAlign: 'right'}}>
                      Quote Total
                    </KooperTableCell>
                    <KooperTableCell>$ {products.total}</KooperTableCell>
                  </KooperTableRow>
                </KooperTableBody>
              </KooperTable>
            </div>
          ) : null}

          {template.terms && (
            <div className="comments">
              <h5 className="mb-0">Terms and Conditions</h5>
              <p className="kooper-lead">{template.terms}</p>
            </div>
          )}

          {template.paymentProcess && (
            <div className="comments">
              <h5 className="mb-0">Payment Process</h5>
              <p className="kooper-lead">{template.paymentProcess}</p>
            </div>
          )}
          <div className="bill-item py-0">
            {signature.signature === 'Add Signature' ? (
              <div className="sign">
                <h6 className="mt-2">Customer Acceptance</h6>
                <KooperGrid>
                  <KooperGridColumn width={8}>
                    <h5>Signature</h5>
                  </KooperGridColumn>
                  <KooperGridColumn width={4}>
                    <h5>Printed Name</h5>
                  </KooperGridColumn>
                  <KooperGridColumn width={4}>
                    <h5>Date</h5>
                  </KooperGridColumn>
                </KooperGrid>
              </div>
            ) : null}
          </div>
          <div className="footer">
            <span>
              If you have any questions, Please contact us on
              <b> {yourInfo.phone}</b> or mail us on{' '}
              <a href="mailto:">
                <b>{yourInfo.email}</b>
              </a>
            </span>
            <div className="logo">
              <KooperImage src={preview} /> {yourCompany.name}
            </div>

            <p>
              {yourCompany.streetAddress ? `${yourCompany.streetAddress}, ` : ''}
              {yourCompany.locality ? `${yourCompany.locality}, ` : ''}
              {yourCompany.state ? `${yourCompany.state}, ` : ''}
              {yourCompany.country ? `${yourCompany.country}, ` : ''}
              {yourCompany.postalCode ? yourCompany.postalCode : ''}
            </p>
            <p>{template.footer}</p>
            {/* <h5>
              {yourInfo.email ? (
                <>
                  <SvgIcon path="common/mail" /> {yourInfo.email}
                </>
              ) : (
                ""
              )}
              {yourInfo.phone ? (
                <>
                  <FiPhone className="ml-4" /> {yourInfo.phone}
                </>
              ) : (
                ""
              )}
            </h5> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StandardTemplate
