import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import TimeOff from 'components/pages/HumanResourse/TimeOff/TimeOff'
import TimeOffTypesList from 'components/pages/HumanResourse/TimeOff/Settings/TimeOffTypes/TimeOffTypesList'
import {TIME_OFF_PATH} from 'constants/path'

const routes = [
  {path: TIME_OFF_PATH.ALL, component: TimeOff, title: 'All TimeOff', exact: true},
  {path: TIME_OFF_PATH.TYPES, component: TimeOffTypesList, title: 'TimeOff', exact: false},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={`/${TIME_OFF_PATH.PREFIX}/${path}`}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} />
        <Component {...props} />
      </>
    )}
  />
)

const TimeOffRoutes = () => (
  <Switch>
    <Route exact path={`/${TIME_OFF_PATH.PREFIX}`}>
      <Redirect to={`/${TIME_OFF_PATH.PREFIX}/${TIME_OFF_PATH.ALL}`} />
    </Route>
    {routes.map(renderRoute)}
    <Route path={`/${TIME_OFF_PATH.PREFIX}/*`}>
      <Redirect to={`/${TIME_OFF_PATH.PREFIX}/${TIME_OFF_PATH.ALL}`} />
    </Route>
  </Switch>
)

export default TimeOffRoutes
