import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperDatePicker,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperFormTextArea,
  KooperGrid,
  KooperGridColumn,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'
import moment from 'moment'
import {removeDoubleQuotes, startCase} from 'utils/helper'
import {createProjectsTasksListTask} from 'actions/projects'
import {CREATE_PROJECTS_TASKS_LIST_TASK} from 'actions/types'
import {taskSchema} from 'validation/Projects/projects/tasks.schema'
import SvgIcon from 'components/common/SvgIcon'

const taskDefaultValues = {
  type: 'task',
  title: '',
  description: '',
  estimatedHrs: null,
  estimatedMin: null,
  startDate: new Date(moment().startOf('day')),
  endDate: new Date(moment().add(1, 'days').startOf('day')),
  stageId: null,
  labelIds: [],
  assignedTo: null,
}

const typeOptions = [
  {key: 'task', value: 'task', text: 'Task'},
  {key: 'event', value: 'event', text: 'Event'},
  {key: 'meeting', text: 'Meeting', value: 'meeting'},
]

const TaskModal = ({open, toggle, projectId, currentlySelectedTaskList}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {taskListAssignees = [], taskListStages = []} = useSelector(state => state.projects)
  const {getTagList = []} = useSelector(state => state.multipleFields)

  const {handleSubmit, control, errors} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(taskSchema),
    defaultValues: taskDefaultValues,
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECTS_TASKS_LIST_TASK)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    const payload = {...data, isRecurring: false}
    dispatch(createProjectsTasksListTask({projectId, taskListId: currentlySelectedTaskList, data: payload}))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Add Task</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label>Select Type</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Choose type of the task"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="type"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  options={typeOptions}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.type && {content: removeDoubleQuotes(errors.type.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label>Assignee</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Select whom to assign the task"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="assignedTo"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  search
                  options={taskListAssignees.map(({id, firstName, lastName}) => ({
                    key: id,
                    value: id,
                    text: `${firstName} ${lastName}`,
                  }))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.assignedTo && {content: removeDoubleQuotes(errors.assignedTo.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label>Title</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify a title for the task"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  maxLength={50}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField>
            <div className="info-header">
              <label>Description</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify a brief description about the task"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="description"
              control={control}
              render={({value, onChange}) => (
                <KooperFormTextArea
                  maxLength={200}
                  style={{resize: 'none'}}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.description && {content: removeDoubleQuotes(errors.description.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormGroup>
            <KooperFormField required width={8} className="errMsg">
              <div className="info-header">
                <label>Estimate Time</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Set estimated time needed to complete the task"
                  size="mini"
                  position="top center"
                />
              </div>
              <KooperGrid columns="equal">
                <KooperGridColumn>
                  <Controller
                    name="estimatedHrs"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormInput
                        fluid
                        placeholder="00H"
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={errors.estimatedHrs && {content: removeDoubleQuotes(errors.estimatedHrs.message)}}
                      />
                    )}
                  />
                </KooperGridColumn>
                <KooperGridColumn>
                  <Controller
                    name="estimatedMin"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormInput
                        fluid
                        placeholder="00M"
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={
                          errors.estimatedMin && {
                            content: removeDoubleQuotes(errors.estimatedMin.message),
                          }
                        }
                      />
                    )}
                  />
                </KooperGridColumn>
              </KooperGrid>
            </KooperFormField>
            <KooperFormField required width={8}>
              <div className="info-header">
                <label>Stage</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Select current stage of the task"
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="stageId"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormSelect
                    options={taskListStages.map(({id, name}) => ({key: id, value: id, text: name}))}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.stageId && {content: removeDoubleQuotes(errors.stageId.message)}}
                  />
                )}
              />
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormGroup>
            <KooperFormField required>
              <div className="info-header">
                <label>Start Date</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Set start date of the task"
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="startDate"
                control={control}
                render={({value, onChange}) => (
                  <KooperDatePicker
                    time={false}
                    value={value ? new Date(value) : value}
                    onChange={value => {
                      onChange(value)
                    }}
                    min={new Date()}
                    defaultValue={typeof value === 'string' ? new Date(value) : value}
                    inputProps={{
                      component: props => <input {...props} readOnly />,
                    }}
                  />
                )}
              />
              {errors.startDate && <p className="errorLabelElement">{removeDoubleQuotes(errors.startDate.message)}</p>}
            </KooperFormField>
            <KooperFormField required>
              <div className="info-header">
                <label>End Date</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Set end date of the task"
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="endDate"
                control={control}
                render={({value, onChange}) => (
                  <KooperDatePicker
                    time={false}
                    value={value ? new Date(value) : value}
                    onChange={value => {
                      onChange(value)
                    }}
                    min={new Date()}
                    defaultValue={typeof value === 'string' ? new Date(value) : value}
                    inputProps={{
                      component: props => <input {...props} readOnly />,
                    }}
                  />
                )}
              />
              {errors.endDate && <p className="errorLabelElement">{removeDoubleQuotes(errors.endDate.message)}</p>}
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormField>
            <div className="info-header">
              <label>Labels</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Add some tags to the task"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="labelIds"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  search
                  multiple
                  options={getTagList.map(({id, label, color}) => ({
                    key: id,
                    value: id,
                    text: startCase(label),
                    label: {color},
                  }))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.labelIds && {content: removeDoubleQuotes(errors.labelIds.message)}}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Add
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default TaskModal
