import NoDataChart from 'components/common/NoDataChart'
import SvgIcon from 'components/common/SvgIcon'
import React, {useState} from 'react'
import {FiActivity, FiChevronRight, FiFileText, FiList} from 'react-icons/fi'
import {
  KooperAccordion,
  KooperAccordionContent,
  KooperAccordionTitle,
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperContainer,
  KooperGrid,
  KooperGridColumn,
  KooperMenu,
  KooperMenuItem,
  KooperTooltip,
} from 'kooper-library'

const Overview = () => {
  const [active, setActive] = useState({
    overview: true,
    summary: true,
    activities: false,
    accorduan: '-1',
  })

  return (
    <>
      <KooperMenu vertical secondary className="projects-side-menu kooper-menu">
        <KooperMenuItem
          className="kooper-seg-item"
          active={active.summary}
          onClick={() => setActive({...active, summary: true, activities: false})}
        >
          <div className="d-flex-js">
            <FiList className="mr-1 mb-1" /> Summary
          </div>
        </KooperMenuItem>
        <KooperMenuItem
          className="kooper-seg-item"
          active={active.activities}
          onClick={() => setActive({...active, summary: false, activities: true})}
        >
          <div className="d-flex-js">
            <FiFileText className="mr-1 mb-1" /> Activities
          </div>
        </KooperMenuItem>
      </KooperMenu>

      <div className="project-discussion-side-wrap" style={{minHeight: '100%'}}>
        {active.summary ? (
          <>
            <KooperGrid>
              <KooperGridColumn width={16}>
                <KooperCard fluid className="chartBox">
                  <KooperCardContent className="headerBox">
                    <KooperCardHeader>Project Progress</KooperCardHeader>
                    <KooperTooltip
                      content="Breakdown of project progress over a timeframe"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </KooperCardContent>
                  <KooperCardContent className="pt-0" style={{height: 270}}>
                    <NoDataChart />
                  </KooperCardContent>
                </KooperCard>
              </KooperGridColumn>
            </KooperGrid>

            <KooperGrid columns="equal">
              <KooperGridColumn>
                <KooperCard fluid className="chartBox">
                  <KooperCardContent className="headerBox">
                    <KooperCardHeader>Tasks (16)</KooperCardHeader>
                    <KooperTooltip
                      content="Breakdown of all task under the project over a timeframe"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </KooperCardContent>
                  <KooperCardContent className="pt-0" style={{height: 270}}>
                    <NoDataChart />
                  </KooperCardContent>
                </KooperCard>
              </KooperGridColumn>
              <KooperGridColumn>
                <KooperCard fluid className="chartBox">
                  <KooperCardContent className="headerBox">
                    <KooperCardHeader>Time On Tasks</KooperCardHeader>
                    <KooperTooltip
                      content="Graphical representation of time required for each task to complete"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </KooperCardContent>
                  <KooperCardContent className="pt-0" style={{height: 270}}>
                    <div className="empty-chart">No Data Available</div>
                  </KooperCardContent>
                </KooperCard>
              </KooperGridColumn>
              <KooperGridColumn>
                <KooperCard fluid className="chartBox">
                  <KooperCardContent className="headerBox">
                    <KooperCardHeader>Milestones (1)</KooperCardHeader>
                    <KooperTooltip
                      content="Breakdown of project milestones over a timeframe"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </KooperCardContent>
                  <KooperCardContent className="pt-0" style={{height: 270}}>
                    <NoDataChart />
                  </KooperCardContent>
                </KooperCard>
              </KooperGridColumn>
            </KooperGrid>

            <KooperGrid columns="equal">
              <KooperGridColumn>
                <KooperCard fluid className="chartBox">
                  <KooperCardContent className="headerBox">
                    <KooperCardHeader>Unresolved Conversations By Status</KooperCardHeader>
                    <KooperTooltip
                      content="Breakdown of unresolved conversations based on status"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </KooperCardContent>
                  <KooperCardContent className="pt-0" style={{height: 270}}>
                    <NoDataChart />
                  </KooperCardContent>
                </KooperCard>
              </KooperGridColumn>
              <KooperGridColumn>
                <KooperCard fluid className="chartBox">
                  <KooperCardContent className="headerBox">
                    <KooperCardHeader>Unresolved Conversations By Priority</KooperCardHeader>
                    <KooperTooltip
                      content="Breakdown of unresolved conversations based on priority"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </KooperCardContent>
                  <KooperCardContent className="pt-0" style={{height: 270}}>
                    <div className="empty-chart">No Data Available</div>
                  </KooperCardContent>
                </KooperCard>
              </KooperGridColumn>
              <KooperGridColumn>
                <KooperCard fluid className="chartBox">
                  <KooperCardContent className="headerBox">
                    <KooperCardHeader>Unresolved Conversations By Inbox</KooperCardHeader>
                    <KooperTooltip
                      content=" Breakdown of all unresolved conversations based on different inboxes"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </KooperCardContent>
                  <KooperCardContent className="pt-0" style={{height: 270}}>
                    <NoDataChart />
                  </KooperCardContent>
                </KooperCard>
              </KooperGridColumn>
            </KooperGrid>
          </>
        ) : null}

        {active.activities ? (
          <KooperContainer className="project-overview-activities">
            {['Nov 24', 'Nov 25', 'Nov 26'].map((e, index) => (
              <KooperCard fluid>
                <KooperCardContent>
                  <KooperAccordion className="mt-0">
                    <KooperAccordionTitle
                      className="d-flex"
                      style={{justifyContent: 'flex-start'}}
                      onClick={() =>
                        setActive({
                          ...active,
                          accorduan: active.accorduan === index ? '-1' : index,
                        })
                      }
                    >
                      {active.accorduan === index ? (
                        <SvgIcon path="common/down" style={{marginRight: 8}} />
                      ) : (
                        <FiChevronRight style={{marginRight: 8}} />
                      )}
                      {e}
                    </KooperAccordionTitle>
                    <KooperAccordionContent active={active.accorduan === index}>
                      {[...Array(20)].map(() => (
                        <div className="kooper-timeline product-overview-activites">
                          <div className="icon">
                            <FiActivity />
                          </div>
                          <div className="content">
                            <article>
                              Agent Name&nbsp;
                              <span style={{color: 'var(--primary-color)'}}>Activity Description</span>
                              <br />
                              Activity Value
                            </article>
                          </div>
                          <div className="time">Nov 19</div>
                        </div>
                      ))}
                    </KooperAccordionContent>
                  </KooperAccordion>
                </KooperCardContent>
              </KooperCard>
            ))}
          </KooperContainer>
        ) : null}
      </div>
    </>
  )
}

export default Overview
