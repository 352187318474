/* eslint-disable jsx-a11y/anchor-is-valid */
import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {
  KooperModal,
  KooperModalHeader,
  KooperModalContent,
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperCardContent,
  KooperIcon,
} from 'kooper-library'

const ImportModal = props => {
  const {isModalOpen, setIsModalOpen} = props

  return (
    <KooperModal
      size="small"
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      toggle={() => setIsModalOpen(!isModalOpen)}
      closeIcon={
        <KooperButton icon floated="right">
          <SvgIcon path="common/close" />
        </KooperButton>
      }
    >
      <KooperModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Import CSV</KooperModalHeader>
      <KooperModalContent>
        <KooperForm >
          <KooperFormField>
            <label htmlFor="import">Import CSV</label>
            <KooperButton
              primary
              icon
              labelPosition="left"
              // onClick={() => uploadCVS.current.click()}
            >
              <KooperIcon name="file" />
              Upload CSV
            </KooperButton>
            <input
              hidden
              // ref={uploadCVS}
              type="file"
              id="import"
              // name="import"
              // label="Upload CSV"
              // onChange={(e) => setFiles(e.target.files)}
            />
            <br />
            <small>Max upload file size is 2 MB</small>
          </KooperFormField>
          <h5 className="mb-0">Download CSV example</h5>
          <p className="kooper-lead m-0">
            Download file : <a href="">Sample.csv</a>
          </p>
        </KooperForm>
      </KooperModalContent>
      <KooperCardContent>
        <KooperButton onClick={() => setIsModalOpen(!isModalOpen)}>Cancel</KooperButton>
        <KooperButton
          primary
          // onClick={() => {
          //   let data = {
          //     file: files,
          //   };
          //   dispatch(uploadContactsCSV(data));
          // }}
        >
          Import
        </KooperButton>{' '}
      </KooperCardContent>
    </KooperModal>
  )
}

export default ImportModal
