import {joiResolver} from '@hookform/resolvers'
import SvgIcon from 'components/common/SvgIcon'
import Joi from 'joi'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {removeDoubleQuotes} from 'utils/helper'
import {
  KooperButton,
  KooperDatePicker,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

const CustomDateSelectorModal = ({open, onCancel, onApply}) => {
  const {handleSubmit, control, errors} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        startDate: Joi.date().required(),
        endDate: Joi.date().greater(Joi.ref('startDate')).required().messages({
          'date.greater': 'End date must be greater than start time',
        }),
      })
    ),
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(),
    },
  })

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => onCancel()}
    >
      <KooperModalHeader>Custom Dates</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormGroup>
            <KooperFormField required>
              <label>Start Date</label>
              <Controller
                name="startDate"
                control={control}
                render={({value, onChange}) => (
                  <KooperDatePicker
                    time={false}
                    value={value ? new Date(value) : value}
                    onChange={value => onChange(value)}
                    inputProps={{readOnly: true}}
                  />
                )}
              />
              {errors.startDate && <p className="errorLabelElement">{removeDoubleQuotes(errors.startDate.message)}</p>}
            </KooperFormField>
            <KooperFormField required>
              <label>End Date</label>
              <Controller
                name="endDate"
                control={control}
                render={({value, onChange}) => (
                  <KooperDatePicker
                    time={false}
                    value={value ? new Date(value) : value}
                    onChange={value => onChange(value)}
                    inputProps={{readOnly: true}}
                  />
                )}
              />
              {errors.endDate && <p className="errorLabelElement">{removeDoubleQuotes(errors.endDate.message)}</p>}
            </KooperFormField>
          </KooperFormGroup>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={onCancel}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(onApply)}>
          Apply
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default CustomDateSelectorModal
