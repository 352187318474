/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import {
  KooperDropdown,
  KooperDropdownHeader,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperTooltip,
} from 'kooper-library'
import {copyToClipboardDropdown, removeDoubleQuotes} from 'utils/helper'
import {msgPlaceholder} from 'constants/campaign'
import EmailEditorTemplate from '../EmailEditor'

const Messages = props => {
  const {style, errors, control, editorRef, editorDefaultValue} = props

  const [templateValue, setTemplateValue] = useState(null)
  const [editorValue, setEditorValue] = useState(editorDefaultValue || '')

  const {getCampaignTemplateListData = []} = useSelector(store => store.settingsProductivity)

  useEffect(() => {
    if (templateValue) {
      setEditorValue(getCampaignTemplateListData.find(temp => temp.id === templateValue).textDesign)
    }
  }, [templateValue])

  useEffect(() => {
    if (editorDefaultValue) setEditorValue(editorDefaultValue)
  }, [editorDefaultValue])

  return (
    <div style={style} className="stepWrap px-4">
      <div className="d-flex py-4">
        <KooperForm className="errorLabel">
          <KooperFormField required className="error-bg-transparent">
            <label>Email Subject</label>
            <Controller
              name="campaign_email.subject"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  className="email-sub-input"
                  placeholder="Subject"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors?.campaign_email?.subject && {
                      content: removeDoubleQuotes(errors.campaign_email.subject.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
        <div className="action d-flex">
          <KooperTooltip
            size="mini"
            position="top center"
            content="Clipboard"
            trigger={
              <KooperDropdown icon="clipboard" direction="left" floating button className="icon basic">
                <KooperDropdownMenu>
                  <KooperDropdownHeader>
                    <b>Insert Template</b>
                  </KooperDropdownHeader>
                  {getCampaignTemplateListData.length > 0 ? (
                    getCampaignTemplateListData.map(temp => (
                      <KooperDropdownItem onClick={() => setTemplateValue(temp.id)}>{temp.name}</KooperDropdownItem>
                    ))
                  ) : (
                    <KooperDropdownItem style={{pointerEvents: 'none'}}>No Data Available</KooperDropdownItem>
                  )}
                </KooperDropdownMenu>
              </KooperDropdown>
            }
          />

          <KooperTooltip
            size="mini"
            position="top center"
            content="Placeholder"
            trigger={
              <KooperDropdown icon="file alternate" direction="left" floating button className="icon basic ml-2">
                <KooperDropdownMenu>
                  <KooperDropdownHeader>
                    <b>Copy Placeholder</b>
                  </KooperDropdownHeader>
                  {msgPlaceholder.map(option => (
                    <KooperDropdownItem
                      key={option.key}
                      onClick={e => copyToClipboardDropdown(e, option.value, option.toast)}
                    >
                      {option.text}
                    </KooperDropdownItem>
                  ))}
                </KooperDropdownMenu>
              </KooperDropdown>
            }
          />
        </div>
      </div>

      <EmailEditorTemplate emailRef={editorRef} defaultValue={editorValue} />
    </div>
  )
}

export default Messages
