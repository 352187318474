import React from 'react'
import {KooperModal, KooperModalHeader, KooperModalContent, KooperModalDescription, KooperModalActions, KooperButton} from 'kooper-library'

const LogoutModal = props => {
  const {tokenModal, setTokenModal} = props

  return (
    <KooperModal
      size="tiny"
      onClose={() => {
        setTokenModal(false)
      }}
      open={tokenModal}
    >
      <KooperModalHeader>Session Expired</KooperModalHeader>
      <KooperModalContent>
        <KooperModalDescription>
          <p>Token is expired and you are logged out</p>
        </KooperModalDescription>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton
          content="OK"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            setTokenModal(false)
          }}
          primary
        />
      </KooperModalActions>
    </KooperModal>
  )
}

export default LogoutModal
