import React, {useEffect, useState} from 'react'
import {
  KooperHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
} from 'kooper-library'
import {useSelector} from 'react-redux'
import {getProjectFinanceRatesList} from 'actions/projects'
import {GET_PROJECT_FINANCE_RATES_LIST, UPDATE_PROJECT_FINANCE_SINGLE_RATE} from 'actions/types'
import {getFormattedDate} from 'utils/helper'
import useApiResponse from 'hooks/impure/useApiResponse'
import ProjectRateModal from './ProjectRateModal'

const ProjectRates = ({projectId, budgetId}) => {
  const [isProjectRateModalOpen, setIsProjectRateModalOpen] = useState(false)
  const [idToUpdateRate, setIdToUpdateRate] = useState(null)

  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)

  const {data: ratesList = [], callAction} = useApiResponse({
    action: getProjectFinanceRatesList,
    payload: {projectId, budgetId},
    dependency: [projectId, budgetId],
    enabled: projectId && budgetId,
    storePath: 'projects.ratesList',
  })

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECT_FINANCE_SINGLE_RATE)) {
      setIsProjectRateModalOpen(false)
      callAction()
    }
  }, [successLabels, callAction])

  const renderTableBody = () => {
    if (!type.includes(GET_PROJECT_FINANCE_RATES_LIST) && ratesList.length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="4" className="emptyValue">
            No Data Available
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return ratesList.map(rate => (
      <KooperTableRow
        className="tableLink"
        key={rate.id}
        onClick={() => {
          setIdToUpdateRate(rate.id)
          setIsProjectRateModalOpen(true)
        }}
      >
        <KooperTableCell>
          <KooperHeader as="h5">{rate.user?.name}</KooperHeader>
        </KooperTableCell>
        <KooperTableCell>{rate.billableRate ? `$${rate.billableRate}` : '-'}</KooperTableCell>
        <KooperTableCell>{rate.costRate ? `$${rate.costRate}` : '-'}</KooperTableCell>
        <KooperTableCell>
          <div>{rate.updatedBy?.name}</div>
          <div>{rate.updatedAt ? getFormattedDate(rate.updatedAt) : '-'}</div>
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <div className="kooper-side-wrap">
      <KooperTable basic>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>Name</KooperTableHeaderCell>
            <KooperTableHeaderCell>Billable Rate/H</KooperTableHeaderCell>
            <KooperTableHeaderCell>Cost Rate/H</KooperTableHeaderCell>
            <KooperTableHeaderCell>Last Updated</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>{renderTableBody()}</KooperTableBody>
      </KooperTable>

      {isProjectRateModalOpen && (
        <ProjectRateModal
          open={isProjectRateModalOpen}
          toggle={setIsProjectRateModalOpen}
          projectId={projectId}
          budgetId={budgetId}
          userId={idToUpdateRate}
        />
      )}
    </div>
  )
}

export default ProjectRates
