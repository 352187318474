import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {Controller} from 'react-hook-form'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormRadio,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

const SegmentStep1 = props => {
  const {open, setOpen, control, handleSubmit, errors, type} = props

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setOpen(false)}
      open={open}
      size="tiny"
    >
      <KooperModalHeader>Segment Information</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <label>Name</label>
            <Controller
              name="name"
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  maxLength={20}
                  placeholder="Enter name"
                  onChange={onChange}
                  value={value}
                  error={
                    errors.name && {
                      content: errors.name.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>
          <KooperFormField>
            <label>Type</label>
            <KooperFormGroup inline>
              <Controller
                name="isStatic"
                render={({value, onChange}) => (
                  <div>
                    <KooperFormRadio
                      label="Static"
                      value
                      checked={value === true}
                      onClick={(e, {value}) => onChange(value)}
                      error={
                        errors.isStatic && {
                          content: errors.isStatic.message,
                        }
                      }
                    />
                    <label className="kooper-lead" style={{marginLeft: '27px'}}>
                      Create a static or fixed segment
                    </label>
                    <KooperFormRadio
                      className="mt-3"
                      label="Dynamic"
                      value={false}
                      checked={value === false}
                      onClick={(e, {value}) => onChange(value)}
                      error={
                        errors.isStatic && {
                          content: errors.isStatic.message,
                        }
                      }
                    />
                    <label className="kooper-lead" style={{marginLeft: '27px'}}>
                      Create a dynamic or variable segment
                    </label>
                  </div>
                )}
                control={control}
              />
            </KooperFormGroup>
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton onClick={() => setOpen(false)} basic>
          Cancel
        </KooperButton>
        <KooperButton content="Next" primary onClick={handleSubmit(() => setOpen(type))} />
      </KooperModalActions>
    </KooperModal>
  )
}

export default SegmentStep1
