import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import {
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperGrid,
  KooperGridColumn,
  KooperTooltip,
} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'
import PreviewQuote from '../Templates/PreviewQuote'

function Signature({formData: {control, watch, setValue: setFormValue}, quoteId}) {
  const [value, setValue] = useState('No Signature')
  const [publish, setPublish] = useState(true)

  const {
    quoteForm: {signature},
  } = useSelector(state => state.quotes)

  useEffect(() => {
    setFormValue('signature', value)
  }, [value])

  useEffect(() => {
    setFormValue('publish', publish)
  }, [publish])

  useEffect(() => {
    if (signature) {
      setValue(signature.signature)
      setPublish(signature.publish)
    }
  }, [signature])

  const watchAll = watch()

  return (
    <div className="stepWrap">
      <KooperGrid>
        <KooperGridColumn width={8}>
          <KooperCard fluid>
            <KooperCardContent>
              <Controller control={control} name="signature" render={({value, onChange}) => <input type="hidden" />} />
              <Controller control={control} name="publish" render={({value, onChange}) => <input type="hidden" />} />
              <KooperForm>
                <h3 className="mb-0">Signature</h3>
                <p className="mt-0 mb-4 card-description">Manage your signature settings in your quote</p>

                <KooperFormGroup grouped>
                  <div className="info-header">
                    <label>Select Signature</label>
                    <KooperTooltip
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                      content="Select the signature type you would like to include"
                    />
                  </div>
                  <KooperFormField className="m-0">
                    <KooperCheckbox
                      radio
                      label="No Signature"
                      value="No Signature"
                      checked={value === 'No Signature'}
                      onChange={(e, {value}) => setValue(value)}
                    />
                  </KooperFormField>

                  <KooperFormField className="m-0">
                    <KooperCheckbox
                      radio
                      label="Add Signature"
                      value="Add Signature"
                      checked={value === 'Add Signature'}
                      onChange={(e, {value}) => setValue(value)}
                    />
                  </KooperFormField>
                </KooperFormGroup>

                <KooperFormField>
                  <label>Publish</label>
                  <KooperFormField>
                    <KooperCheckbox
                      label="Publish this quote"
                      checked={publish}
                      onChange={(e, {checked}) => setPublish(checked)}
                    />
                  </KooperFormField>
                </KooperFormField>
              </KooperForm>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn width={8} className="quotePreBox">
          <PreviewQuote watch={{signature: watchAll}} />
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default Signature
