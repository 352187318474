import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiList, FiSliders} from 'react-icons/fi'
import {MdOutlineMoreVert} from 'react-icons/md'
import _ from 'lodash'
import {KooperButton, KooperDropdown, KooperTooltip} from 'kooper-library'

import {
  COMPLETE_PROJECTS_TASKS_LIST_TASK,
  COPY_PROJECTS_TASKS_LIST_TASK,
  CREATE_PROJECTS_TASKS_LIST_STAGE,
  CREATE_PROJECTS_TASKS_LIST_TASK,
  DELETE_PROJECTS_TASKS_LIST_STAGE,
  DELETE_PROJECTS_TASKS_LIST_TASK,
  GET_PROJECTS_TASKS_LIST_TASKS,
  MOVE_PROJECTS_TASKS_LIST_STAGE,
  MOVE_PROJECTS_TASKS_LIST_TASK,
  STATUS_PROJECTS_TASKS_LIST_TASK,
  UPDATE_PROJECTS_TASKS_LIST_SINGLE_TASK,
  UPDATE_PROJECTS_TASKS_LIST_STAGE,
  UPDATE_PROJECTS_TASKS_LIST_TASK_ASSIGNEE,
  UPDATE_PROJECTS_TASKS_LIST_TASK_STAGE,
} from 'actions/types'
import {getProjectsTasksListFilter, getProjectsTasksListTasks} from 'actions/projects'
import {get, startCase} from 'utils/helper'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import BoardView from './BoardView'
import TableView from './TableView'
import TaskModal from './TaskModal'

const TasksDetails = props => {
  const {
    renderDropdown,
    setTaskListId,
    setIsSubscriberModalOpen,
    projectId,
    currentlySelectedTaskList,
    currentListParams,
    setCurrentListParams,
    rolePermissions,
  } = props

  const dispatch = useDispatch()

  const [isDefaultView, setDefaultView] = useState(false)
  const [kanBoardData, setKanBoardData] = useState({
    lanes: [],
  })
  const [tableData, setTableData] = useState([])
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {
    tasksListData,
    getAllTasks = {},
    taskListStages = [],
    taskListAssignees = [],
    taskListLabels = [],
  } = useSelector(state => state.projects)
  const {tasks: allTasks = [], page = 1, totalPages = 1} = getAllTasks || {}

  useEffect(() => {
    if (successLabels.includes(GET_PROJECTS_TASKS_LIST_TASKS)) {
      if (isDefaultView) {
        setTableData(allTasks)
      } else {
        const boardData = {
          lanes: Object.values(getAllTasks).map(task => {
            return {
              id: task.id,
              title: task.name,
              label: task.tasks.length,
              laneId: task.id,
              color: task.color,
              disallowAddingCard: true,
              cards: task.tasks.map(task => {
                return {
                  id: task.id,
                  title: task.title,
                  metadata: {
                    endDate: task.endDate,
                    labels: task.labelDetails,
                    progressRate: task.progressRate,
                    assigneeDetails: task.assigneeDetails,
                    stageId: task.stageId,
                    status: task.status,
                    isCompleted: task.isCompleted,
                    ...(task.estimatedHrs || task.estimatedMin
                      ? {
                          estimateTime: `${task.estimatedHrs || 0}h ${task.estimatedMin || 0}m`,
                        }
                      : {}),
                    ...(task.actualHrs || task.actualMin
                      ? {
                          actualTime: `${task.actualHrs || 0}h ${task.actualMin || 0}m`,
                        }
                      : {}),
                  },
                }
              }),
            }
          }),
        }
        setKanBoardData(boardData)
      }
    }
  }, [successLabels, getAllTasks, allTasks, isDefaultView])

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECTS_TASKS_LIST_TASK) ||
      successLabels.includes(UPDATE_PROJECTS_TASKS_LIST_SINGLE_TASK) ||
      successLabels.includes(UPDATE_PROJECTS_TASKS_LIST_TASK_ASSIGNEE) ||
      successLabels.includes(DELETE_PROJECTS_TASKS_LIST_TASK) ||
      successLabels.includes(COPY_PROJECTS_TASKS_LIST_TASK) ||
      successLabels.includes(MOVE_PROJECTS_TASKS_LIST_TASK) ||
      successLabels.includes(STATUS_PROJECTS_TASKS_LIST_TASK) ||
      successLabels.includes(COMPLETE_PROJECTS_TASKS_LIST_TASK) ||
      successLabels.includes(UPDATE_PROJECTS_TASKS_LIST_TASK_STAGE) ||
      successLabels.includes(CREATE_PROJECTS_TASKS_LIST_STAGE) ||
      successLabels.includes(UPDATE_PROJECTS_TASKS_LIST_STAGE) ||
      successLabels.includes(DELETE_PROJECTS_TASKS_LIST_STAGE) ||
      successLabels.includes(MOVE_PROJECTS_TASKS_LIST_STAGE)
    ) {
      const newList = _.omitBy(currentListParams, value => value === 'all')
      dispatch(getProjectsTasksListTasks({projectId, taskListId: currentlySelectedTaskList, params: newList}))
    }
  }, [successLabels, dispatch, projectId, currentlySelectedTaskList, currentListParams])

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECTS_TASKS_LIST_STAGE) ||
      successLabels.includes(UPDATE_PROJECTS_TASKS_LIST_STAGE) ||
      successLabels.includes(DELETE_PROJECTS_TASKS_LIST_STAGE)
    ) {
      dispatch(getProjectsTasksListFilter({projectId, taskListId: currentlySelectedTaskList}))
    }
  }, [successLabels, dispatch, projectId, currentlySelectedTaskList])

  const getTaskListStages = () => {
    const stages = taskListStages.map(({id, name}) => ({key: id, value: id, text: name}))
    return [{key: 'all', value: 'all', text: 'All'}, ...stages]
  }

  const getTaskListAssignees = () => {
    const assignees = taskListAssignees.map(({id, firstName, lastName}) => ({
      key: id,
      value: id,
      text: `${firstName} ${lastName}`,
    }))
    return [{key: 'all', value: 'all', text: 'All'}, ...assignees]
  }

  const getTaskListLabels = () => {
    const labels = taskListLabels.map(({id, label, color}) => ({
      key: id,
      value: id,
      text: startCase(label),
      label: {color, empty: true},
    }))
    return [{key: 'all', value: 'all', text: 'All'}, ...labels]
  }

  return (
    <>
      <div className="project-discussion-side-wrap">
        <div className="project-discussion-head">
          <div className="d-flex project-discussion-head-left">{/*  */}</div>
          <div className="d-flex project-discussion-head-action">
            <KooperTooltip
              position="top center"
              content="Stages"
              size="mini"
              trigger={
                <KooperDropdown
                  icon="chevron down"
                  button
                  className="dropdown-ellipsis"
                  options={getTaskListStages()}
                  value={currentListParams.stageIds}
                  onChange={(e, {value}) => setCurrentListParams({...currentListParams, stageIds: value})}
                />
              }
            />
            <KooperTooltip
              position="top center"
              content="Tasks"
              size="mini"
              trigger={
                <KooperDropdown
                  icon="chevron down"
                  button
                  className="dropdown-ellipsis"
                  options={[
                    {key: 'active', value: 'active', text: 'Active'},
                    {key: 'archived', value: 'archived', text: 'Archived'},
                  ]}
                  value={currentListParams.status}
                  onChange={(e, {value}) => setCurrentListParams({...currentListParams, status: value})}
                />
              }
            />
            <KooperTooltip
              position="top center"
              content="Assigned"
              size="mini"
              trigger={
                <KooperDropdown
                  icon="chevron down"
                  button
                  className="dropdown-ellipsis"
                  options={getTaskListAssignees()}
                  value={currentListParams.assignedTo}
                  onChange={(e, {value}) => setCurrentListParams({...currentListParams, assignedTo: value})}
                />
              }
            />
            <KooperTooltip
              position="top center"
              content="Labels"
              size="mini"
              trigger={
                <KooperDropdown
                  icon="chevron down"
                  button
                  className="dropdown-ellipsis"
                  options={getTaskListLabels()}
                  value={currentListParams.labelIds}
                  onChange={(e, {value}) => setCurrentListParams({...currentListParams, labelIds: value})}
                />
              }
            />
            {isDefaultView ? (
              <KooperTooltip
                content="Board View"
                position="top center"
                size="mini"
                trigger={
                  <KooperButton
                    icon
                    className="mr-1"
                    active={!isDefaultView}
                    onClick={() => {
                      setCurrentListParams({
                        ...currentListParams,
                        page: null,
                        view: 'board',
                      })
                      setDefaultView(false)
                      // dispatch({
                      //   type: 'CLEAR_ALL_TASK_DATA',
                      // })
                    }}
                  >
                    <FiList />
                  </KooperButton>
                }
              />
            ) : (
              <KooperTooltip
                content="List View"
                position="top center"
                size="mini"
                trigger={
                  <KooperButton
                    className="mr-1"
                    icon
                    active={isDefaultView}
                    onClick={() => {
                      setCurrentListParams({
                        ...currentListParams,
                        page: 1,
                        view: 'table',
                      })
                      setDefaultView(true)
                      // dispatch({
                      //   type: 'CLEAR_ALL_TASK_DATA',
                      // })
                    }}
                  >
                    <FiSliders />
                  </KooperButton>
                }
              />
            )}
            <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
              <KooperButton
                primary
                className={!rolePermissions ? 'disabled-button' : ''}
                onClick={() => rolePermissions && setIsAddTaskModalOpen(true)}
              >
                Create Task
              </KooperButton>
            </LockPermissionTooltip>
            <div className="project-discussion-head-usersGroup">
              {get(['subscriberDetails'], tasksListData, []).map(subscriber => {
                const firstNameInitial = (get(['firstName'], subscriber, '')[0] || '').toUpperCase()
                const lastNameInitial = (get(['lastName'], subscriber, '')[0] || '').toUpperCase()

                return (
                  <KooperTooltip
                    position="top center"
                    size="mini"
                    content={`${subscriber.firstName} ${subscriber.lastName}`}
                    trigger={
                      <div className="discussion-users" style={{'--user-bg': 'green'}}>
                        {`${firstNameInitial}${lastNameInitial}`}
                      </div>
                    }
                  />
                )
              })}
              {rolePermissions && (
                <KooperButton
                  icon
                  className="m-0 ml-2"
                  onClick={() => {
                    setTaskListId(tasksListData.id)
                    setIsSubscriberModalOpen(true)
                  }}
                >
                  <SvgIcon path="common/plus" />
                </KooperButton>
              )}
            </div>
            {rolePermissions && (
              <KooperDropdown className="project-discussion-head-more icon button" icon={<MdOutlineMoreVert />}>
                {tasksListData && renderDropdown(tasksListData)}
              </KooperDropdown>
            )}
          </div>
        </div>
        {isDefaultView ? (
          <TableView
            tableData={tableData}
            page={page}
            totalPages={totalPages}
            currentListParams={currentListParams}
            setCurrentListParams={setCurrentListParams}
            projectId={projectId}
            rolePermissions={rolePermissions}
          />
        ) : (
          <BoardView
            kanBoardData={kanBoardData}
            projectId={projectId}
            currentlySelectedTaskList={currentlySelectedTaskList}
            rolePermissions={rolePermissions}
          />
        )}
      </div>

      {isAddTaskModalOpen && (
        <TaskModal
          open={isAddTaskModalOpen}
          toggle={setIsAddTaskModalOpen}
          projectId={projectId}
          currentlySelectedTaskList={currentlySelectedTaskList}
        />
      )}
    </>
  )
}

export default TasksDetails
