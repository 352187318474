import {FaUser, FaUsers, FaWindowMaximize} from 'react-icons/fa'

export const createGoalsDropdownData = [
  {
    Icon: FaWindowMaximize,
    header: 'Organizational Goal',
    subHeader: 'Organizational Goals',
    value: 'organizational',
  },
  {
    Icon: FaUsers,
    header: 'Team Goal',
    subHeader: 'Team Goal',
    value: 'team',
  },
  {
    Icon: FaUser,
    header: 'Individual Goal',
    subHeader: 'Individual Goal',
    value: 'personal',
  },
]
