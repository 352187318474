import {useMemo} from 'react'
import {useLocation} from 'react-router-dom'

//* get your url search params in an object
function useUrlSearch() {
  const {search} = useLocation()

  return useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search])
}

export {useUrlSearch}
