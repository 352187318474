/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperModal,
  KooperModalHeader,
  KooperModalContent,
  KooperForm,
  KooperFormField,
  KooperModalActions,
  KooperButton,
  KooperTextArea,
  KooperDatePicker,
} from 'kooper-library'
import {ABANDON_DEAL} from 'actions/types'
import {abandonDeal} from 'actions/deals'
import {abandonedModalSchema} from 'validation/Sales/deals.schema'
import SvgIcon from 'components/common/SvgIcon'

const AbandonedModal = props => {
  const dispatch = useDispatch()
  const {isModalOpen, setIsModalOpen, Id} = props
  const {successLabels = []} = useSelector(state => state.apiReducer)

  const abandonDealBtn = data => {
    dispatch(abandonDeal(Id, data))
  }

  const {control, handleSubmit} = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      lossReason: null,
      closedNote: '',
      closedAt: new Date(),
    },
    resolver: joiResolver(abandonedModalSchema),
  })

  useEffect(() => {
    if (successLabels.includes(ABANDON_DEAL)) {
      setIsModalOpen(false)
    }
  }, [successLabels])

  return (
    <KooperModal
      size="tiny"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isModalOpen}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <KooperModalHeader>Abandoned Deal</KooperModalHeader>
      <KooperModalContent>
        <KooperForm>
          <KooperFormField>
            <label>Add Note</label>
            <Controller
              control={control}
              name="closedNote"
              render={({onChange, value}) => (
                <KooperTextArea
                  placeholder="type here..."
                  className="mb-3"
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                />
              )}
            />
            <p className="kooper-lead">
              Lost opportunities can be seen in the opportunities list by selecting the status filter and checking the
              'Lost' box
            </p>
          </KooperFormField>

          <KooperFormField required>
            <label>Closed Date</label>
            <Controller
              control={control}
              name="closedAt"
              render={({onChange, value}) => (
                <KooperDatePicker
                  
                  value={value}
                  inputProps={{readOnly: true}}
                  onChange={value => onChange(value)}
                  min={new Date()}
                  time={false}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton
          basic
          onClick={() => {
            setIsModalOpen(false)
          }}
        >
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(abandonDealBtn)}>
          Save
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default AbandonedModal
