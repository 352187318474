import React, {useCallback, useEffect, useState} from 'react'
import {joiResolver} from '@hookform/resolvers'
import {getProjectsTasksList} from 'actions/projects'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {removeDoubleQuotes, get} from 'utils/helper'
import {
  KooperAttachment,
  KooperButton,
  KooperDatePicker,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormRadio,
  KooperFormSelect,
  KooperFormTextArea,
  KooperGrid,
  KooperGridColumn,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'
import {uploadAttachments} from 'actions/settings_productivity'
import axios from 'axios'
import AttachmentPreview from 'components/common/AttachmentPreview'
import {CREATE_PROOF, EDIT_PROOF, GET_USERS, GET_SINGLE_PROOF} from 'actions/types'

import {addProofSchema} from 'validation/Projects/projects/proofs.schema'
import {createProof, editProof, getAllProofs} from 'actions/proofs'
import {ProofStatusOptions} from 'constants/projects'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'

const initialCreateInfo = {
  name: '',
  projectId: null,
  tasklistId: null,
  taskId: null,
  dueDate: null,
  description: '',
  option: 'file',
  links: [
    {
      link: '',
    },
  ],
  assignees: [
    {
      status: 'approve',
      reviewerIds: [],
    },
  ],
}

const {CancelToken} = axios
const requestToken = CancelToken.source()

function AddProofs(props) {
  const {isAddProofsOpen, setIsAddProofsOpen, projectId} = props
  const dispatch = useDispatch()

  const [proofId, setProofId] = useState(null)
  const [files, setFiles] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [userOption, setUserOption] = useState([])

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsList = [], tasksList = []} = useSelector(state => state.projects)
  const {getSingleProofData, getVersionsList} = useSelector(state => state.proofs)

  const {handleSubmit, errors, control, watch, setValue, reset} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(addProofSchema),
    defaultValues: initialCreateInfo,
  })

  const {
    fields: userField,
    append: userAdd,
    remove: userRemove,
  } = useFieldArray({
    name: 'assignees',
    control,
  })
  const {
    fields: linkField,
    append: addLink,
    remove: removeLink,
  } = useFieldArray({
    name: 'links',
    control,
  })

  const watchProjectId = watch('projectId')
  const watchTasklistId = watch('tasklistId')
  const watchSelectOption = watch('option')
  const watchLinks = watch('links')

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list || [])
    },
  })

  useEffect(() => {
    if (watchProjectId) {
      dispatch(getProjectsTasksList(watchProjectId))
    }
  }, [watchProjectId, dispatch, setValue])

  useEffect(() => {
    if (projectId) {
      setValue('projectId', Number(projectId))
    }
  }, [projectId, setValue])

  useEffect(() => {
    if ((successLabels.includes(CREATE_PROOF) || successLabels.includes(EDIT_PROOF)) && !projectId) {
      reset(initialCreateInfo)
      dispatch(getAllProofs())
      setIsAddProofsOpen(false)
    }
    if ((successLabels.includes(CREATE_PROOF) || successLabels.includes(EDIT_PROOF)) && projectId) {
      reset(initialCreateInfo)
      dispatch(
        getAllProofs({
          projectIds: projectId,
        })
      )
      setIsAddProofsOpen(false)
    }
  }, [successLabels, dispatch, projectId])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_PROOF)) {
      const {assignees} = getSingleProofData
      const {links, description, option} = getVersionsList[0]
      reset({
        name: get(['name'], getSingleProofData, ''),
        projectId: get(['projectId'], getSingleProofData, ''),
        tasklistId: get(['tasklistId'], getSingleProofData, null),
        taskId: get(['taskId'], getSingleProofData, null),
        dueDate: get(['dueDate'], getSingleProofData, null),
        option: get(['option'], getSingleProofData, ''),
        description,
        links:
          option === 'link'
            ? Object.entries(links)?.map(([key, data]) => {
                return {link: data}
              })
            : [],
        assignees: assignees?.map(({reviewerIds, status}) => {
          return {reviewerIds, status}
        }),
      })

      setProofId(get(['id'], getSingleProofData, null))
    }
  }, [successLabels, getSingleProofData])

  const getTaskOptions = useCallback(() => {
    if (watchTasklistId) {
      const tasks = tasksList.find(tasklist => tasklist.id === watchTasklistId)?.tasks
      if (tasks) {
        return tasks.map(({id, title}) => ({key: id, value: id, text: title}))
      }
      return []
    }
    return []
  }, [tasksList, watchTasklistId])

  const FieldList = () => {
    return (
      <>
        {userField?.map(({id, reviewerIds, status}, i) => {
          return (
            <KooperFormGroup key={id} className={userField.length !== 2 ? 'mb-3' : ''}>
              <KooperFormField required width={8}>
                <Controller
                  name={`assignees[${i}].reviewerIds`}
                  defaultValue={reviewerIds}
                  render={({onChange, value}) => (
                    <KooperFormSelect
                      multiple
                      upward
                      options={userOption}
                      placeholder="Select"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.assignees?.[i]?.reviewerIds && {
                          content: removeDoubleQuotes(errors?.assignees?.[i]?.reviewerIds?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>

              <KooperFormField required width={userField.length === 1 ? 8 : 6}>
                <Controller
                  name={`assignees[${i}].status`}
                  defaultValue={status}
                  render={({onChange, value, onBlur}) => (
                    <KooperFormSelect
                      upward
                      options={ProofStatusOptions}
                      selection
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.assignees?.[i]?.status && {
                          content: removeDoubleQuotes(errors?.assignees?.[i].status?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
              {userField.length > 1 && (
                <KooperFormField width={2}>
                  <KooperButton icon onClick={() => userRemove(i)}>
                    <SvgIcon path="common/delete" />
                  </KooperButton>
                </KooperFormField>
              )}
            </KooperFormGroup>
          )
        })}
      </>
    )
  }

  const submitForm = data => {
    if (uploadedFiles?.length > 0) {
      data.attachmentIds = uploadedFiles.map(file => file.id)
    }

    if (watchSelectOption === 'link') {
      data.links = watchLinks.map(link => link.link)
    }

    if (proofId) {
      delete data.projectId
      delete data.option
      delete data.links
      delete data.description

      dispatch(editProof(proofId, data))
    } else {
      dispatch(createProof(data))
    }
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isAddProofsOpen}
      onClose={() => setIsAddProofsOpen(false)}
      size="small"
      centered={false}
    >
      <KooperModalHeader >{proofId ? 'Edit Proofs' : 'Add Proofs'} </KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label>Proof Name</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify the name of the proof"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="name"
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  maxLength={20}
                  type="text"
                  placeholder="Proof name.."
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  error={
                    errors.name && {
                      content: removeDoubleQuotes(errors.name.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>

          <KooperFormField required>
            <div className="info-header">
              <label>Project</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Project to which proof is associated"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="projectId"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  disabled={(proofId || projectId) && true}
                  options={projectsList.map(({id, title}) => ({
                    key: id,
                    value: id,
                    text: title,
                  }))}
                  value={value}
                  // defaultValues={projectId && Number(projectId)}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  error={
                    errors.projectId && {
                      content: removeDoubleQuotes(errors.projectId.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>

          <KooperFormGroup widths="equal">
            <KooperFormField>
              <div className="info-header">
                <label>Tasklist</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Select a tasklist from project"
                  size="mini"
                  psoition="top center"
                />
              </div>

              <Controller
                name="tasklistId"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormSelect
                    disabled={!watchProjectId && true}
                    placeholder="Select"
                    options={tasksList.map(({id, title}) => ({key: id, value: id, text: title}))}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                      setValue('taskId', null)
                    }}
                    error={errors.tasklistId && {content: removeDoubleQuotes(errors.tasklistId.message)}}
                  />
                )}
              />
            </KooperFormField>

            {watch('tasklistId') && (
              <KooperFormField>
                <div className="info-header">
                  <label>Task</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select a task for associating proof"
                    size="mini"
                    psoition="top center"
                  />
                </div>

                <Controller
                  name="taskId"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      placeholder="Select"
                      options={getTaskOptions()}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={errors.taskId && {content: removeDoubleQuotes(errors.taskId.message)}}
                    />
                  )}
                />
              </KooperFormField>
            )}
          </KooperFormGroup>

          <KooperFormField required>
            <div className="info-header">
              <label>Due Date</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Set a due date for uploading proof"
                size="mini"
                psoition="top center"
              />
            </div>

            <Controller
              name="dueDate"
              control={control}
              render={({value, onChange}) => (
                <KooperDatePicker
                  time={false}
                  value={value ? new Date(value) : value}
                  onChange={value => onChange(value)}
                  inputProps={{
                    component: props => <input {...props} readOnly />,
                  }}
                  error={errors.dueDate && {content: removeDoubleQuotes(errors.dueDate.message)}}
                />
              )}
            />
          </KooperFormField>
          {!proofId && (
            <KooperFormField>
              <div className="info-header">
                <label>Description</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Specify a brief description about the proof"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                name="description"
                render={({onChange, value}) => (
                  <KooperFormTextArea
                    maxLength={160}
                    type="text"
                    value={value}
                    placeholder="Description"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors.description && {
                        content: removeDoubleQuotes(errors.description.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>
          )}

          <KooperGrid>
            <KooperGridColumn width={4}>
              <KooperFormField>
                <Controller
                  name="option"
                  control={control}
                  render={({value, onChange}) => (
                    <>
                      <KooperFormRadio
                        disabled={proofId && true}
                        className="mb-2"
                        value="file"
                        checked={value === 'file'}
                        onChange={(e, {value}) => onChange(value)}
                        label="Upload a File"
                      />
                      <KooperFormRadio
                        disabled={proofId && true}
                        value="link"
                        checked={value === 'link'}
                        onChange={(e, {value}) => onChange(value)}
                        label="Add a Link"
                      />
                    </>
                  )}
                />
              </KooperFormField>
            </KooperGridColumn>

            <KooperGridColumn width={12}>
              {watchSelectOption === 'link' && (
                <>
                  {linkField?.map(({id, link}, i) => {
                    return (
                      <>
                        <KooperFormGroup>
                          <KooperFormField required={watchSelectOption === 'link'} width={linkField.length > 1 ? 14 : 16}>
                            <Controller
                              name={`links[${i}].link`}
                              defaultValue={link}
                              render={({value, onChange}) => (
                                <KooperFormInput
                                  disabled={proofId && true}
                                  type="text"
                                  placeholder="www.yourlinkhere.com"
                                  value={value}
                                  onChange={(e, {value}) => {
                                    onChange(value)
                                  }}
                                  error={
                                    errors?.links?.[i]?.link && {
                                      content: removeDoubleQuotes(errors?.links?.[i].link?.message),
                                    }
                                  }
                                />
                              )}
                              control={control}
                            />
                          </KooperFormField>
                          {linkField.length > 1 && !proofId && (
                            <KooperFormField width={2}>
                              <KooperButton icon onClick={() => removeLink(i)}>
                                <SvgIcon path="common/delete" />
                              </KooperButton>
                            </KooperFormField>
                          )}
                        </KooperFormGroup>
                      </>
                    )
                  })}
                  {linkField.length !== 5 && !proofId && (
                    <KooperFormField>
                      <KooperTooltip
                        content="Add another link"
                        position="top center"
                        size="mini"
                        trigger={
                          <KooperButton
                            className="backBtn"
                            style={{color: 'var(--primary-color)'}}
                            onClick={() =>
                              addLink({
                                link: '',
                              })
                            }
                          >
                            <SvgIcon path="common/plus" /> Add another
                          </KooperButton>
                        }
                      />
                    </KooperFormField>
                  )}
                </>
              )}

              {watchSelectOption === 'file' && !proofId && (
                <KooperFormField required width={16} className="expense-file-input">
                  <AttachmentPreview
                    files={files}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    success="composeAttachment"
                    cancelToken={requestToken}
                  />
                  {uploadedFiles?.length !== 5 && (
                    <KooperAttachment
                      content={
                        <>
                          <SvgIcon path="common/attachment" /> Upload Attachment
                        </>
                      }
                      disabled={proofId && true}
                      multiple
                      onChange={e => {
                        const data = {
                          file: e,
                        }
                        if (e.length > 0) {
                          dispatch(uploadAttachments(data, 'composeAttachment', requestToken))
                        }
                      }}
                      onClick={e => {
                        e.target.value = null
                      }}
                      fileList={files => {
                        setFiles(files)
                      }}
                    />
                  )}
                </KooperFormField>
              )}
            </KooperGridColumn>
          </KooperGrid>

          <div className="info-header mb-1 mt-4">
            <label className="label-class">Reviewers & Approvers</label>
            <KooperTooltip
              content="Choose reviewer and approver for the proof"
              trigger={<SvgIcon path="common/question" />}
              size="mini"
              position="top center"
            />
          </div>
          {FieldList()}

          {userField.length !== 2 && (
            <KooperTooltip
              content="Add another approver or reviewer"
              position="top center"
              size="mini"
              trigger={
                <KooperButton
                  className="backBtn"
                  style={{color: 'var(--primary-color)'}}
                  onClick={() =>
                    userAdd({
                      status: '',
                      reviewerIds: null,
                    })
                  }
                >
                  <SvgIcon path="common/plus" /> Add another
                </KooperButton>
              }
            />
          )}
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions style={{position: 'relative'}}>
        <KooperButton
          basic
          disabled={isLoadingData && type.includes(CREATE_PROOF)}
          onClick={() => setIsAddProofsOpen(false)}
        >
          Cancel
        </KooperButton>

        <KooperButton
          primary
          loading={(isLoadingData && type.includes(CREATE_PROOF)) || type.includes(EDIT_PROOF)}
          disabled={(isLoadingData && type.includes(CREATE_PROOF)) || type.includes(EDIT_PROOF)}
          onClick={handleSubmit(submitForm)}
        >
          {proofId ? 'Save' : 'Add Proof'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default AddProofs
