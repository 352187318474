import React from 'react'
import {Redirect, Route, Switch} from 'react-router'
import DocumentTitle from 'components/common/DocumentTitle'
import Overview from 'components/pages/Analytics/Dashboards/Overview'
import Support from 'components/pages/Analytics/Dashboards/Support'
import Sales from 'components/pages/Analytics/Dashboards/Sales'
import Projects from 'components/pages/Analytics/Dashboards/Projects'
import {DASHBOARDS_PATH} from 'constants/path'

const routes = [
  {path: DASHBOARDS_PATH.OVERVIEW, component: Overview, title: 'Overview', exact: true},
  {path: DASHBOARDS_PATH.SUPPORT, component: Support, title: 'Support', exact: true},
  {path: DASHBOARDS_PATH.SALES, component: Sales, title: 'Sales', exact: true},
  {path: DASHBOARDS_PATH.PROJECTS, component: Projects, title: 'Projects', exact: true},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={`/${DASHBOARDS_PATH.PREFIX}/${path}`}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} dashboards />
        <Component {...props} />
      </>
    )}
  />
)

const DashboardsRoutes = () => (
  <Switch>
    {routes.map(renderRoute)}
    <Route path="/dashboards/*">
      <Redirect to="/dashboards/overview" />
    </Route>
  </Switch>
)

export default DashboardsRoutes
