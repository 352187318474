import {joiResolver} from '@hookform/resolvers'
import {updateProjectFinanceTasksListBudget} from 'actions/projects'
import SvgIcon from 'components/common/SvgIcon'
import Joi from 'joi'
import _ from 'lodash'
import React from 'react'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {removeDoubleQuotes} from 'utils/helper'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

const ProjectBudgetTasklistBudgetModal = ({open, toggle, projectId}) => {
  const dispatch = useDispatch()

  const {tasksList = [], projectBudget = {}} = useSelector(state => state.projects)

  const {
    handleSubmit,
    control,
    errors,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        taskListBudgets: Joi.array().items(
          Joi.object().keys({
            taskListId: Joi.number(),
            capacity: Joi.number().min(1).required().messages({
              'number.min': 'Please enter a value greater than 0',
            }),
          })
        ),
      })
    ),
    defaultValues: {
      taskListBudgets: tasksList.map(({id, capacity = 0}) => ({taskListId: id, capacity: capacity || 0})),
    },
  })

  const {fields} = useFieldArray({
    name: 'taskListBudgets',
    control,
  })

  const getUnassignedBudget = () => {
    const totalBudget = projectBudget?.budgetAmount || 0
    const budgetUsed = tasksList.reduce((acc, item) => (+item.capacity ? acc + item.capacity : acc), 0)

    return totalBudget - budgetUsed
  }

  const getAssignedBudgetPercentage = () => {
    const totalBudget = projectBudget?.budgetAmount || 0
    const assginedBudget = totalBudget - getUnassignedBudget()
    return ((assginedBudget / totalBudget) * 100).toFixed(2)
  }

  const submitForm = data => {
    dispatch(updateProjectFinanceTasksListBudget({projectId, data}))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Assign Tasklist Budget</KooperModalHeader>
      <KooperModalContent>
        <div className="project-tasklist-budget">
          <div className="d-flex mb-2">
            <span>
              Project budget: <strong>${projectBudget?.budgetAmount}</strong>
            </span>
            <span>
              Unassigned: <strong>${getUnassignedBudget()}</strong>
            </span>
          </div>
          <div className="budget-progress">
            <div className="budget-progress-completed" style={{width: `${getAssignedBudgetPercentage()}%`}} />
          </div>
        </div>

        <KooperForm className="errorLabel mt-3">
          {fields.map(({id, taskListId, capacity}, index) => (
            <div key={id} className="d-flex mb-4">
              <div>
                <Controller
                  control={control}
                  name={`taskListBudgets[${index}].taskListId`}
                  render={() => <input type="hidden" />}
                />
                <strong>{tasksList.find(tasklist => tasklist.id === taskListId)?.title}</strong> budget
              </div>
              <KooperFormField>
                <Controller
                  name={`taskListBudgets[${index}].capacity`}
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormInput
                      type="number"
                      defaultValues={capacity}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        _.get(errors, `taskListBudgets[${index}].capacity`) && {
                          content: removeDoubleQuotes(errors.taskListBudgets[index].capacity.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
            </div>
          ))}
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary disabled={!isDirty} onClick={handleSubmit(submitForm)}>
          Save
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default ProjectBudgetTasklistBudgetModal
