import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperDatePicker,
  KooperDivider,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperRadio,
  KooperTable,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTooltip,
} from 'kooper-library'
import {useHistory, useParams} from 'react-router-dom'
import {getFormattedDate, newTimeZoneData, removeDoubleQuotes} from 'utils/helper'
import {
  createTimingScheme,
  deleteTimingScheme,
  editingTimingScheme,
  getTimingScheme,
} from 'actions/settings_automations'
import {CREATE_TIMING_SCHEME, DELETE_TIMING_SCHEME, EDIT_TIMING_SCHEME, GET_TIMING_SCHEME} from 'actions/types'
import SettingLayout from 'layouts/settingLayout'
import DeleteModal from 'components/common/DeleteModal'
import {bussinessHoursSchema} from 'validation/Settings/automations/bussinessHoursSchema'
import {setMetaData} from 'actions'
import {getMetadataInfo} from 'utils/local-storage'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import {SETTINGS_PATH} from 'constants/path'

const BusinessHoursForm = ({toggleCreate, setWarningModal}) => {
  const history = useHistory()
  const {id} = useParams()

  const dispatch = useDispatch()

  const timeZoneDropdownData = newTimeZoneData.map(zone => ({
    text: zone.country,
    value: zone.timezone,
    key: zone.country,
  }))

  const {handleSubmit, errors, control, watch, reset, formState} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(bussinessHoursSchema),
    defaultValues: {
      timezone: '',
      allDay: false,
      haveHolidays: false,
    },
  })

  const {isDirty} = formState
  const watchAllDay = watch('allDay')
  const watchHolidays = watch('haveHolidays')

  const {timingScheme} = useSelector(state => state.settingAutomation)
  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const businessHoursAddLearnMore = settingsLearnMoreUrl?.automations?.businessHours?.learnMore
  const businessHoursUpdateLearnMore = settingsLearnMoreUrl?.automations?.businessHours?.updateLearnMore

  const [holidayList, setHolidayList] = useState([])
  const [isChanged, setIsChanged] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [idToDelete, setIdToDelete] = useState(null)

  const [holidayValue, setHolidayValue] = useState({
    name: '',
    dateVal: new Date(),
  })

  const initialWorkingDaysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(
    (day, index) => {
      return {
        day: index,
        label: day,
        isChecked: !!(index > 0 && index < 6),
        startTime: moment('2019-01-01T09:00:00').format('HH:mm'),
        endTime: moment('2019-01-01T18:00:00').format('HH:mm'),
      }
    }
  )
  const [workingDaysList, setWorkingDaysList] = useState(initialWorkingDaysList)

  const {automationPermissions} = useUserPermissions()
  const manageBussinesHoursPermissions = automationPermissions?.manage_bussines_hours

  const resetData = () => {
    if (id) {
      reset(
        _.omit(timingScheme, [
          'accountId',
          'createdAt',
          'createdBy',
          'id',
          'holidays',
          'timings',
          'title',
          'description',
          'inboxIds',
        ])
      )

      if (timingScheme.holidays?.length)
        setHolidayList(
          timingScheme.holidays.map(({name, dateVal}) => {
            return {
              name,
              dateVal,
            }
          })
        )

      const currentTiming = timingScheme.timings?.map(({day, startTime, endTime}) => {
        return {
          day,
          startTime,
          endTime,
        }
      })

      setWorkingDaysList(workingDaysList =>
        workingDaysList.map(item => {
          const find = currentTiming?.find(findDay => findDay.day === item.day)

          return find
            ? {
                label: item.label,
                day: item.day,
                startTime: find.startTime.substring(0, 5),
                endTime: find.endTime.substring(0, 5),
                isChecked: true,
              }
            : {...item, isChecked: false}
        })
      )
      setWarningModal(false)
      setIsChanged(false)
    } else {
      // create form
      reset({
        timezone: '',
        allDay: false,
        haveHolidays: false,
      })
      setHolidayList([])
      setWorkingDaysList(initialWorkingDaysList)
    }
  }

  const handleForm = data => {
    let timings = []
    if (!data.allDay) {
      const newWorkingDayList = workingDaysList
        .filter(item => item.isChecked === true)
        .map(item => _.omit(item, ['label', 'isChecked']))
      timings = [...timings, ...newWorkingDayList]
    }
    if (data.haveHolidays) {
      timings = [...timings, ...holidayList]
    }
    data.timings = timings

    if (id) {
      dispatch(editingTimingScheme(id, data))
      setWarningModal(false)
    } else {
      dispatch(createTimingScheme(data))
      if (!getMetadataInfo().bussinessHoursStatus) {
        dispatch(
          setMetaData({
            bussinessHoursStatus: true,
          })
        )
      }
    }
  }

  useEffect(() => {
    if (id) dispatch(getTimingScheme())
  }, [dispatch, id])

  useEffect(() => {
    if (successLabels.includes(CREATE_TIMING_SCHEME)) {
      dispatch(getTimingScheme())
      toggleCreate(false)
    }
    if (successLabels.includes(GET_TIMING_SCHEME)) {
      resetData()
      setWarningModal(false)
    }
    if (successLabels.includes(EDIT_TIMING_SCHEME)) {
      setWarningModal(false)
    }
    if (successLabels.includes(DELETE_TIMING_SCHEME)) {
      history.push(`/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.BUSINESS_HOURS}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successLabels])

  useEffect(() => {
    if (id) {
      if (isDirty || isChanged) setWarningModal(true)
    }
    return () => {
      if (id) setWarningModal(false)
    }
  }, [isDirty, isChanged, id, setWarningModal])

  const getWorkingHours = (start, end) => {
    const momentStart = moment(new Date()).set({
      h: start.substring(0, 2),
      m: start.substring(3),
    })
    const momentEnd = moment(new Date()).set({
      h: end.substring(0, 2),
      m: end.substring(3),
    })
    const duration = momentEnd.diff(momentStart, 'hours')
    return duration
  }

  const workingHoursTable = () => {
    return (
      <KooperCard fluid>
        <KooperTable basic>
          <KooperTableHeader>
            <tr>
              <th>Working Days</th>
              <th>Starting Time</th>
              <th>Ending Time</th>
              <KooperTableHeaderCell width={4}>Total Hours</KooperTableHeaderCell>
            </tr>
          </KooperTableHeader>
          <tbody>
            {workingDaysList.map(({day, startTime, endTime, label, isChecked}) => {
              return (
                <tr key={day}>
                  <td>
                    <KooperCheckbox
                      label={label}
                      checked={isChecked}
                      onChange={(e, {checked}) => {
                        const tempList = [...workingDaysList]
                        const index = tempList.findIndex(item => item.day === day)
                        tempList[index].isChecked = checked
                        setWorkingDaysList(tempList)
                        setIsChanged(true)
                      }}
                    />
                  </td>
                  <td>
                    <KooperDatePicker
                      onChange={value => {
                        const tempList = [...workingDaysList]
                        const index = tempList.findIndex(item => item.day === day)
                        tempList[index].startTime = moment(value).format('HH:mm')
                        setWorkingDaysList(tempList)
                        setIsChanged(true)
                      }}
                      value={moment(new Date())
                        .set({
                          h: startTime.substring(0, 2),
                          m: startTime.substring(3),
                        })
                        .toDate()}
                      step={60}
                      date={false}
                      disabled={!isChecked}
                      inputProps={{
                        component: props => <input {...props} readOnly />,
                      }}
                    />
                  </td>
                  <td>
                    <KooperDatePicker
                      onChange={value => {
                        const tempList = [...workingDaysList]
                        const index = tempList.findIndex(item => item.day === day)
                        tempList[index].endTime = moment(value).format('HH:mm')
                        setWorkingDaysList(tempList)
                        setIsChanged(true)
                      }}
                      value={moment(new Date())
                        .set({
                          h: endTime.substring(0, 2),
                          m: endTime.substring(3),
                        })
                        .toDate()}
                      min={moment(new Date())
                        .set({
                          h: startTime.substring(0, 2),
                          m: startTime.substring(3),
                        })
                        .toDate()}
                      step={60}
                      date={false}
                      disabled={!isChecked}
                      inputProps={{
                        component: props => <input {...props} readOnly />,
                      }}
                    />
                  </td>
                  <td>{getWorkingHours(startTime, endTime)} Hours</td>
                </tr>
              )
            })}
          </tbody>
        </KooperTable>
      </KooperCard>
    )
  }

  const holidaySection = () => {
    return (
      <KooperCardContent>
        <p>Holidays will be ignored when calculating SLA for a ticket</p>
        <KooperForm>
          <KooperFormGroup>
            <KooperFormField width={10}>
              <KooperFormInput
                maxLength={20}
                onChange={e => setHolidayValue({...holidayValue, name: e.target.value})}
                value={holidayValue.name}
                placeholder="Holiday Name"
              />
            </KooperFormField>
            <KooperFormField width={4}>
              <KooperDatePicker
                time={false}
                value={holidayValue.dateVal}
                onChange={value => setHolidayValue({...holidayValue, dateVal: value})}
                inputProps={{
                  component: props => <input {...props} readOnly />,
                }}
              />
            </KooperFormField>
            <KooperFormField width={2}>
              <KooperButton
                primary
                fluid
                disabled={holidayValue.name.trim() === ''}
                onClick={() => {
                  holidayList.push(holidayValue)
                  setHolidayValue({
                    name: '',
                    dateVal: new Date(),
                  })
                  setIsChanged(true)
                }}
              >
                Create
              </KooperButton>
            </KooperFormField>
          </KooperFormGroup>
        </KooperForm>

        {holidayList.length > 0 && (
          <KooperTable basic>
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {holidayList.map((list, i) => (
                <tr key={i}>
                  <td>{list.name}</td>
                  <td>{getFormattedDate(list.dateVal)}</td>
                  <td>
                    <KooperButton
                      icon
                      onClick={() => {
                        const updatedList = [...holidayList]
                        updatedList.splice(i, 1)
                        setHolidayList(updatedList)
                        setIsChanged(true)
                      }}
                    >
                      <SvgIcon path="common/delete" />
                    </KooperButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </KooperTable>
        )}
      </KooperCardContent>
    )
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/business-hours" />}
      header={id ? 'Edit Business Hours' : 'Create Business Hours'}
      headerbackbtn={
        !id && {
          onClick: () => toggleCreate(false),
        }
      }
      subHeader="Configure operating hours to manage customer support availability and expectations"
      learnMoreUrl={id ? businessHoursUpdateLearnMore : businessHoursAddLearnMore}
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: id ? 'Reset' : 'Cancel',
          disabled: isLoadingData && (type.includes(EDIT_TIMING_SCHEME) || type.includes(CREATE_TIMING_SCHEME)),
          onClick: () => {
            if (id) {
              resetData()
            } else {
              toggleCreate(false)
            }
          },
        },
        success: {
          content: id ? 'Update' : 'Create',
          loading: isLoadingData && type.includes(EDIT_TIMING_SCHEME || CREATE_TIMING_SCHEME),
          ...(manageBussinesHoursPermissions && {
            onClick: handleSubmit(data => handleForm(data)),
          }),
          disabled:
            !(isDirty || isChanged) ||
            (isLoadingData && (type.includes(EDIT_TIMING_SCHEME) || type.includes(CREATE_TIMING_SCHEME))),
        },
      }}
      lockRole={!manageBussinesHoursPermissions}
    >
      <KooperForm className="errorLabel">
        {/* <KooperFormGroup> */}
        <KooperFormField required>
          <Controller
            name="timezone"
            control={control}
            render={({value, onChange, onBlur}) => (
              <>
                <div className="info-header">
                  <label className="label-class">Time Zone</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select timezone in which you have set your business hours"
                    size="mini"
                    position="top center"
                  />
                </div>
                <KooperFormSelect
                  width={8}
                  fluid
                  search
                  options={timeZoneDropdownData}
                  placeholder="Select TimeZone "
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  onBlur={onBlur}
                  error={
                    errors.timezone && {
                      content: removeDoubleQuotes(errors.timezone.message),
                    }
                  }
                />
              </>
            )}
          />
        </KooperFormField>
        {/* </KooperFormGroup> */}
        <KooperFormGroup inline>
          <label>Help Desk Hours :</label>
          <Controller
            name="allDay"
            control={control}
            render={({value, onChange}) => (
              <KooperFormField>
                <KooperRadio
                  value
                  checked={value === true}
                  onChange={(e, {value}) => onChange(value)}
                  label="24 hrs x 7 days"
                />
                <KooperRadio
                  value={false}
                  checked={value === false}
                  onChange={(e, {value}) => onChange(value)}
                  label="Select working days/hours"
                />
              </KooperFormField>
            )}
          />
        </KooperFormGroup>

        {!watchAllDay && workingHoursTable()}

        <KooperDivider hidden />
        <KooperCard fluid className="switchBox">
          <KooperCardContent>
            <div className="d-flex">
              <div>
                <h5>Yearly Holiday List </h5>
                <p className="kooper-lead">Enable and add yearly custom holidays for your business hour.</p>
              </div>
              <Controller
                name="haveHolidays"
                control={control}
                render={({value, onChange}) => (
                  <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                )}
              />
            </div>
          </KooperCardContent>
          {watchHolidays && holidaySection()}
        </KooperCard>
        {id && (
          <>
            <h4 className="mt-5 mb-0">Want To Delete Business Hours?</h4>
            <p className="kooper-lead">All your settings related to Business Hours will be deleted </p>
            <KooperButton
              className="basic-red"
              onClick={() => {
                setIdToDelete(id)
                setDeleteModal(true)
              }}
            >
              Delete Business Hours
            </KooperButton>
          </>
        )}
      </KooperForm>
      {deleteModal && (
        <DeleteModal
          idTobeDeleted={idToDelete}
          deleteAction={deleteTimingScheme}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          type={DELETE_TIMING_SCHEME}
        />
      )}
    </SettingLayout>
  )
}

export default BusinessHoursForm
