import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import _ from 'lodash'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

import {GET_PROJECT_DISCUSSION_SINGLE_TOPIC, UPDATE_PROJECT_DISCUSSION_TOPIC_SUBSCRIBERS} from 'actions/types'
import {getProjectDiscussionSingleTopic, updateProjectDiscussionTopicSubscribers} from 'actions/projects'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const SubscriberModal = ({open, toggle, projectId, topicId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectAssignees = []} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {subscribers: []},
    resolver: joiResolver(
      Joi.object({
        subscribers: Joi.array().items(Joi.number()).min(1).required().messages({
          'array.min': 'Subscriber must be specified',
        }),
      })
    ),
  })

  useApiResponse({
    action: getProjectDiscussionSingleTopic,
    payload: {projectId, topicId},
    dependency: [projectId, topicId],
    enabled: projectId && topicId,
    label: GET_PROJECT_DISCUSSION_SINGLE_TOPIC,
    storePath: 'projects.topicData',
    onSuccess: topicData => {
      reset(_.pick(topicData, ['subscribers']))
    },
  })

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECT_DISCUSSION_TOPIC_SUBSCRIBERS)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    dispatch(updateProjectDiscussionTopicSubscribers({projectId, topicId, data}))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Subscribers</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField>
            <label>Subscriber</label>
            <Controller
              name="subscribers"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  search
                  multiple
                  options={projectAssignees.map(({id, firstName, lastName}) => ({
                    key: id,
                    value: id,
                    text: `${firstName} ${lastName}`,
                  }))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.subscribers && {content: removeDoubleQuotes(errors.subscribers.message)}}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Update
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default SubscriberModal
