import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {BrowserRouter as Router} from 'react-router-dom'
import AppProvider from 'context/AppProvider'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import store from './store'
import 'semantic-ui-less/semantic.less'
import './styles/sign-signup.css'
import 'global-styles'
import './styles/media-query.css'
import 'kooper-library/dist/index.css'

import UnsavedChanges from './components/pages/UnsavedChanges'
import ErrorBoundary from './components/pages/ErrorBoundary'

const getUserConfirmation = (message, callback) => {
  const modal = document.createElement('div')
  document.body.appendChild(modal)
  const withCleanup = answer => {
    ReactDOM.unmountComponentAtNode(modal)
    document.body.removeChild(modal)
    callback(answer)
  }
  ReactDOM.render(<UnsavedChanges cleanUp={withCleanup} />, modal)
}
ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <Router getUserConfirmation={getUserConfirmation}>
        <AppProvider>
          <App />
        </AppProvider>
      </Router>
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
)
registerServiceWorker()
