/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperButton,
  KooperDivider,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperGrid,
  KooperGridColumn,
  KooperInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperFormDropdown,
  KooperIcon,
  KooperImage,
  KooperTooltip,
} from 'kooper-library'
import {getProfilePicColor, getUserInfo, setUserInfo} from 'utils/local-storage'
import {get, removeDoubleQuotes} from 'utils/helper'
import {countryCodeList} from 'constants/variables'

import {changePasswordProfile, deleteProfilePicture, updateProfile, updateProfilePicture} from 'actions/settings'
import {GET_USER, UPDATE_PROFILE} from 'actions/types'
import SettingLayout from 'layouts/settingLayout'
import DeleteModal from 'components/common/DeleteModal'
import {getUser} from 'actions/settings_organisation'
import {myProfileSchema} from 'validation/Settings/myProfile.schema'
import DummyAvtarAccountUser from 'components/common/DummyAvtar'
import moment from 'moment'
import {dateFormatDelimiterOptions, dateFormatOptions} from 'constants/settings'
import TimeZoneDropdown from 'components/common/TimeZoneDropdown'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import {getAllRoles} from 'actions/roles'
import useApiResponse from 'hooks/impure/useApiResponse'

const defaultValues = {
  roleId: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  phoneCode: '',
  timezone: moment.tz.guess(),
  timeFormat: '12',
  dateFormat: 'DD MM YYYY',
  dateDelimiterFormat: ' ',
}

function getFirstSpecialCharacter(text) {
  // Find the first occurrence of any of " ", ".", "/", or "-"
  const index = text.search(/[ ./-]/)
  // If a match is found, return the character, otherwise return an empty string
  return index !== -1 ? text[index] : ''
}

const MyProfile = props => {
  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(myProfileSchema),
    defaultValues,
  })

  const firstName = watch('firstName')
  const lastName = watch('lastName')
  const watchTimeFormat = watch('timeFormat')
  const watchDateFormat = watch('dateFormat')
  const watchDateDelimiterFormat = watch('dateDelimiterFormat')

  const [countryCodeUpdated, setCountryCodeUpdated] = useState([])
  const [password, setPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [passwordChangeModal, setPasswordChangeModal] = useState(false)
  const [profilePicture, setProfilePicture] = useState('null')
  const [uploadProfile, setUploadProfile] = useState(null)
  const [userAccountImage, setUserAccountImage] = useState(true)
  const [isDelete, setIsDelete] = useState(false)

  const dispatch = useDispatch()

  const closeChangePassword = () => {
    setPasswordChangeModal(false)
    setPassword('')
    setOldPassword('')
    setConfirmPassword('')
  }

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const myProfileLearnMore = settingsLearnMoreUrl?.mySetting?.myProfile?.learnMore
  const {updateAvatarData} = useSelector(state => state.settings)
  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  const {getUserData} = useSelector(state => state.settings)

  const {data: getRoleList = []} = useApiResponse({
    action: getAllRoles,
    enabled: true,
    storePath: 'roles.getRoleList',
  })

  useEffect(() => {
    const updatedList = countryCodeList.map(item => ({
      key: `${item.name} ${item.dial_code}`,
      value: item.dial_code,
      flag: item.code.toLowerCase(),
      text: `${item.dial_code}`,
      content: `${item.dial_code}  ${item.name}`,
    }))

    setCountryCodeUpdated(updatedList)
  }, [])

  useEffect(() => {
    dispatch(getUser(get(['id'], getUserInfo())))
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_USER)) {
      setUserInfo({...getUserInfo(), ...getUserData})
      const data = _.omit(getUserData, ['avatar', 'createdAt', 'id', 'isVerified', 'isDisabled', 'workPeriods'])
      data.timezone = data.timezone || moment.tz.guess()
      data.timeFormat = data.timeFormat || '12'
      data.dateFormat = data.dateFormat?.replace(/[./-]/g, ' ') || 'DD MM YYYY'
      data.dateDelimiterFormat = getFirstSpecialCharacter(getUserData.dateFormat || 'DD MM YYYY')
      reset(data)

      if (get(['avatar'], getUserInfo()) !== null) {
        setUserAccountImage(false)
      }
    }
    if (successLabels.includes(UPDATE_PROFILE)) {
      dispatch(getUser(get(['id'], getUserInfo())))
      reset(_.omit(getUserData, ['avatar', 'createdAt', 'id', 'isVerified', 'isDisabled', 'workPeriods']))
    }

    if (successLabels.includes('DELETE_AVATAR')) {
      setProfilePicture('null')
      setUserAccountImage(true)
      setUploadProfile(null)
      setUploadProfile(null)
      setUserInfo({...getUserInfo(), avatar: null})
    }
  }, [successLabels])

  useEffect(() => {
    if (_.get(getUserData, 'id')) {
      if (isDirty) props.setWarningModal(true)
    }
    return () => {
      props.setWarningModal(false)
    }
  }, [isDirty])

  const renderPasswordModal = () => {
    if (passwordChangeModal) {
      return (
        <KooperModal
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
          size="mini"
          open={passwordChangeModal}
          onClose={() => closeChangePassword()}
          toggle={() => setPasswordChangeModal(!passwordChangeModal)}
        >
          <KooperModalHeader>Change Password</KooperModalHeader>
          <KooperModalContent>
            <KooperForm>
              <KooperFormField>
                <label>Old Password</label>

                <KooperFormInput
                  type="password"
                  name="name"
                  value={oldPassword}
                  onChange={e => setOldPassword(e.target.value)}
                />
              </KooperFormField>
              <KooperFormField>
                <label>New Password</label>
                <KooperFormInput
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  name="name"
                />
              </KooperFormField>
              <KooperFormField>
                <label>Confirm Password</label>
                <KooperFormInput
                  type="password"
                  name="name"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
                <span
                  style={{
                    color: 'red',
                    display: confirmPassword !== '' && confirmPassword !== password ? 'block' : 'none',
                  }}
                >
                  Password do not match
                </span>
                <span
                  style={{
                    color: 'red',
                    display: password.length < 8 && confirmPassword !== password ? 'block' : 'none',
                  }}
                >
                  Password must be atleast 8 characters long
                </span>
              </KooperFormField>
            </KooperForm>
          </KooperModalContent>
          <KooperModalActions>
            <KooperButton basic onClick={() => closeChangePassword()}>
              Cancel
            </KooperButton>
            <KooperButton
              primary
              onClick={() => {
                const data = {
                  password,
                  oldPassword,
                }
                dispatch(changePasswordProfile(data))
                closeChangePassword()
              }}
              disabled={
                confirmPassword === '' ||
                password.length < 8 ||
                confirmPassword.length < 8 ||
                confirmPassword !== password ||
                password === '' ||
                oldPassword === ''
              }
            >
              Change
            </KooperButton>
          </KooperModalActions>
        </KooperModal>
      )
    }
  }

  const getImage = () => {
    if (get(['avatar'], getUserInfo())) {
      return get(['avatar'], getUserInfo())
    }
    if (_.get(getUserData, 'avatar') !== null) {
      return _.get(getUserData, 'avatar')
    }
    setUserAccountImage(true)
    return 'null'
  }

  useEffect(() => {
    setProfilePicture(getImage())
  }, [get(['avatar'], getUserInfo())])

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROFILE)) {
      props.setWarningModal(false)
    }
    if (successLabels.includes('DELETE_AVATAR')) {
      dispatch(getUser(get(['id'], getUserInfo())))
    }

    if (successLabels.includes('UPDATE_AVATAR')) {
      setUserInfo({...getUserInfo(), avatar: updateAvatarData})
      dispatch(getUser(get(['id'], getUserInfo())))
      setUploadProfile(null)
      setProfilePicture('null')
    }
  }, [successLabels])

  function renderIcon() {
    if (!userAccountImage) return <KooperIcon name="trash" onClick={() => setIsDelete(true)} />
  }

  const handleCancelClick = () => {
    const data = _.omit(getUserData, ['avatar', 'createdAt', 'id', 'isVerified', 'isDisabled'])
    data.dateFormat = data.dateFormat?.replace(/[./-]/g, ' ') || 'DD MM YYYY'
    data.dateDelimiterFormat = getFirstSpecialCharacter(getUserData.dateFormat || 'DD MM YYYY')
    reset(data)
    setProfilePicture(getImage())
    setUploadProfile(null)
    if (userAccountImage) setUserAccountImage(true)
  }

  const handleSubmitClick = data => {
    const payload = {
      ...data,
      dateFormat: data.dateFormat.replaceAll(' ', data.dateDelimiterFormat),
    }
    delete payload.dateDelimiterFormat
    dispatch(updateProfile(payload))
    // if (uploadProfile) {
    //   dispatch(updateProfilePicture({file: uploadProfile}))
    // }
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/my-profile" />}
      header="My Profile"
      subHeader="Manage your personal profile and information."
      learnMoreUrl={myProfileLearnMore}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Reset',
          disabled: (isLoadingData && type.includes(UPDATE_PROFILE)) || !isDirty,
          onClick: handleCancelClick,
        },
        success: {
          content: 'Update',
          loading: isLoadingData && type.includes(UPDATE_PROFILE || 'DELETE_AVATAR'),
          onClick: handleSubmit(handleSubmitClick),
          disabled: uploadProfile
            ? false
            : !isDirty || (isLoadingData && (type.includes(UPDATE_PROFILE) || type.includes('DELETE_AVATAR'))),
        },
      }}
    >
      {renderPasswordModal()}
      <div className="user-avtar">
        <div className="user-image">
          {userAccountImage ? (
            <div
              className="user-image-dummy"
              style={{
                backgroundColor: getProfilePicColor() || '#9EB9D6',
              }}
            >
              <DummyAvtarAccountUser />
            </div>
          ) : (
            <KooperImage loading="lazy" src={profilePicture} className="img-fluid" alt="img" />
          )}
          {renderIcon()}
        </div>
        <div className="image-text">
          <h4 className="m-0 mb-1">
            {_.get(getUserData, 'firstName', '-')} {_.get(getUserData, 'lastName', '-')}
          </h4>
          <p className="kooper-lead">PNG or JPG and File size should be less than 5MB</p>
        </div>
        <KooperButton className="fileBtn basic-primary">
          Upload
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            key={uploadProfile}
            onInput={e => {
              if (e.target.files) {
                if (e.target.files[0].size < 5000000) {
                  setUserAccountImage(false)
                  setProfilePicture(URL.createObjectURL(e.target.files[0]))
                  setUploadProfile(e.target.files)
                  dispatch(updateProfilePicture({file: e.target.files}))
                } else {
                  alert('File size must be less than 5Mb')
                }
              }
            }}
          />
        </KooperButton>
      </div>

      <KooperDivider className="my-4" />
      <KooperForm className="errorLabel ">
        <KooperFormGroup>
          <KooperFormField width={8} required>
            <label>First Name</label>
            <Controller
              control={control}
              name="firstName"
              render={({onChange, value, onBlur}) => (
                <KooperFormInput
                  maxLength={20}
                  fluid
                  placeholder="First Name"
                  value={value}
                  onBlur={onBlur}
                  onChange={e => e.target.value?.length <= 20 && onChange(e.target.value)}
                  error={
                    errors.firstName && {
                      content: removeDoubleQuotes(errors.firstName.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
          <KooperFormField width={8} required>
            <label>Last Name</label>
            <Controller
              control={control}
              name="lastName"
              render={({onChange, value, onBlur}) => (
                <KooperFormInput
                  maxLength={20}
                  fluid
                  placeholder="Last Name"
                  value={value}
                  onBlur={onBlur}
                  onChange={e => e.target.value?.length <= 20 && onChange(e.target.value)}
                  error={
                    errors.lastName && {
                      content: removeDoubleQuotes(errors.lastName.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
        </KooperFormGroup>
        <KooperFormGroup>
          <KooperFormField width={8} required>
            <label>Email</label>
            <Controller
              control={control}
              name="email"
              render={({onChange, value, onBlur}) => (
                <KooperFormInput
                  fluid
                  disabled
                  placeholder="e.g. email@domain.com"
                  onBlur={onBlur}
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.email && {
                      content: removeDoubleQuotes(errors.email.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>

          <KooperFormField width={8} required>
            <label>Mobile No</label>
            <KooperGrid>
              <KooperGridColumn width={4}>
                <Controller
                  control={control}
                  name="phoneCode"
                  render={({onChange, value, onBlur}) => (
                    <KooperFormDropdown
                      fluid
                      className="numDropDown"
                      selection
                      placeholder="Select"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      onBlur={onBlur}
                      options={countryCodeUpdated}
                      search={(event, searchQuery) => {
                        if (searchQuery === '') {
                          return event
                        }
                        const r = event.filter(o => {
                          return o.key.toLowerCase().includes(searchQuery.toLowerCase())
                        })
                        return r
                      }}
                    />
                  )}
                />
              </KooperGridColumn>
              <KooperGridColumn width={12}>
                <KooperFormField className="phone">
                  <Controller
                    control={control}
                    name="phone"
                    render={({onChange, value, onBlur}) => (
                      <KooperFormInput
                        maxLength={20}
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        onBlur={onBlur}
                        error={
                          errors.phone && {
                            content: errors.phone.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
              </KooperGridColumn>
            </KooperGrid>
          </KooperFormField>
        </KooperFormGroup>
        <KooperFormGroup>
          <KooperFormField width={8}>
            <label>Password</label>
            <KooperInput type="Password" value="1234526" disabled />
            <a className="changePass" onClick={() => setPasswordChangeModal(true)}>
              Change Password
            </a>
          </KooperFormField>
          <KooperFormField width={8} disabled required>
            <label>Role</label>
            <Controller
              control={control}
              name="roleId"
              render={({onChange, value, onBlur}) => (
                <KooperFormSelect
                  options={getRoleList.map(role => ({
                    value: role.id,
                    key: role.id,
                    text: role.role,
                  }))}
                  onChange={(e, {value}) => onChange(value)}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />
          </KooperFormField>
        </KooperFormGroup>
        <KooperFormGroup>
          <KooperFormField width={8} required>
            <div className="info-header">
              <label>Date Format</label>
              <KooperTooltip
                content="Date Format"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <KooperGrid>
              <KooperGridColumn width={8}>
                <Controller
                  name="dateFormat"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      fluid
                      options={dateFormatOptions}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
              </KooperGridColumn>
              <KooperGridColumn width={8}>
                <Controller
                  name="dateDelimiterFormat"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      fluid
                      options={dateFormatDelimiterOptions}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
              </KooperGridColumn>
            </KooperGrid>
            <span className="kooper-lead-profile">
              e.g. {moment().format(watchDateFormat?.replaceAll(' ', watchDateDelimiterFormat))}
            </span>
          </KooperFormField>
          <KooperFormField width={8} required>
            <div className="info-header">
              <label>Time Format</label>
              <KooperTooltip
                content="Time Format"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              name="timeFormat"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  options={[
                    {key: '12', value: '12', text: '12 hours'},
                    {key: '24', value: '24', text: '24 hours'},
                  ]}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                />
              )}
            />
            <span className="kooper-lead-profile">e.g. {watchTimeFormat === '12' ? '9:45 PM' : '21:45 hours'}</span>
          </KooperFormField>
        </KooperFormGroup>
        <KooperFormGroup>
          <KooperFormField width={8} required>
            <div className="info-header">
              <label>Time Zone</label>
              <KooperTooltip
                content="Time Zone"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              name="timezone"
              control={control}
              render={({value, onChange}) => (
                <TimeZoneDropdown
                  selection
                  realTime
                  format="Z (z) LT"
                  direction="right"
                  search
                  placeholder="Select Timezone"
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  value={value}
                  error={errors.timezone}
                />
              )}
            />
          </KooperFormField>
        </KooperFormGroup>
      </KooperForm>
      {isDelete && (
        <DeleteModal
          deleteAction={deleteProfilePicture}
          isModalOpen={isDelete}
          setIsModalOpen={setIsDelete}
          modalText="Are you sure you want remove your current Avatar?"
          type="DELETE_AVATAR"
        />
      )}
    </SettingLayout>
  )
}
export default MyProfile
