import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import {
  KooperButton,
  KooperForm,
  KooperFormCheckbox,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

import {MOVE_PROJECTS_NOTEBOOK} from 'actions/types'
import {moveProjectsNotebook} from 'actions/projects'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const MoveNotebookModal = ({open, toggle, projectId, notebookId, notebookTitle}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsList = []} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, setValue} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        title: Joi.string().trim().min(3).max(50).required().messages({
          'string.min': `Length must me at least 3 charactes`,
          'string.max': `Length must be less than or equal to 50 characters`,
        }),
        projectId: Joi.number().required().messages({
          'number.base': 'Project must be specified',
        }),
        includeSubscribers: Joi.boolean(),
      })
    ),
    defaultValues: {
      title: '',
      projectId: null,
      includeSubscribers: false,
    },
  })

  useEffect(() => {
    if (successLabels.includes(MOVE_PROJECTS_NOTEBOOK)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    dispatch(moveProjectsNotebook({projectId, notebookId, data}))
  }

  useEffect(() => {
    if (notebookTitle) {
      setValue('title', notebookTitle)
    }
  }, [notebookTitle, setValue])

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Move Notebook</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <label>Title</label>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <label>Project</label>
            <Controller
              name="projectId"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  options={projectsList
                    .filter(project => project.id !== Number(projectId))
                    .map(({id, title}) => ({key: id, value: id, text: title}))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.projectId && {content: removeDoubleQuotes(errors.projectId.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField>
            <Controller
              name="includeSubscribers"
              control={control}
              render={({value, onChange}) => (
                <KooperFormCheckbox
                  label="Move and add people subscribed to the notes to the destination project"
                  checked={value}
                  onChange={(e, {checked}) => onChange(checked)}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Ok
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default MoveNotebookModal
