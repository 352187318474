/* eslint-disable react/jsx-props-no-spreading */
import DocumentTitle from 'components/common/DocumentTitle'
import UnSavedWrap from 'components/hoc/UnSavedWrap'
import AutomationBuilder from 'components/pages/Automations/AutomationBuilder'
import ActivityFeed from 'components/pages/Customers/ActivityFeed/ActivityFeed'
import CalendarContainer from 'components/pages/Customers/Calendar/CalendarContainer'
import AnnouncementDetails from 'components/pages/Customers/ClientPortal/AnnouncementDetails'
import Companies from 'components/pages/Customers/Companies/Companies'
import CompaniesDetail from 'components/pages/Customers/Companies/CompanyDetail'
import ContactCustomer from 'components/pages/Customers/Contacts/ContactsCustomer'
import Contacts from 'components/pages/Customers/Contacts/ContactsList'
import LiveVisitors from 'components/pages/Customers/LiveVisitors'
import BudgetsPlanner from 'components/pages/Finance/BudgetsPlanner/BudgetsPlanner'
import CreateBudgetPlanner from 'components/pages/Finance/BudgetsPlanner/CreateBudgetPlanner'
import InvoicesEdit from 'components/pages/Finance/Invoices/InvoicesEdit'
import InvoicesIndex from 'components/pages/Finance/Invoices/InvoicesForm/Index'
import PurchaseOrdersEdit from 'components/pages/Finance/PurchaseOrders/PurchaseOrdersEdit'
import Index from 'components/pages/Finance/PurchaseOrders/PurchaseOrdersForm/Index'
import PurchaseModal from 'components/pages/Finance/Purchases/PurchaseModal'
import SalesOrdersEdit from 'components/pages/Finance/SalesOrders/SalesOrdersEdit'
import IndexSalesOrders from 'components/pages/Finance/SalesOrders/SalesOrdersForm/Index'
import CreateModal from 'components/pages/Finance/Vendors/CreateModal'
import Announcements from 'components/pages/HumanResourse/Announcements/Announcements'
import CreateAnnouncement from 'components/pages/HumanResourse/Announcements/CreateAnnouncement'
import AppraisalSend from 'components/pages/HumanResourse/Appraisal/AppraisalSend'
import AppraisalTemplate from 'components/pages/HumanResourse/Appraisal/AppraisalTemplate'
import CreateAppraiselTemplate from 'components/pages/HumanResourse/Appraisal/CreateAppraiselTemplate'
import ViewAppraisal from 'components/pages/HumanResourse/Appraisal/ViewAppraisal'
import Contracts from 'components/pages/HumanResourse/Contracts/Contracts'
import CreateEmployee from 'components/pages/HumanResourse/Employees/EmployeesForm/CreateEmployee'
import CreateExpense from 'components/pages/HumanResourse/Expenses/CreateExpense'
import Payroll from 'components/pages/HumanResourse/Payroll/Payroll'
import CreateTimeOffForm from 'components/pages/HumanResourse/TimeOff/CreateTimeOffForm'
import OneTimeEmailSteps from 'components/pages/Marketing/Campaigns/OneTimeEmail/OneTimeEmailSteps'
import OneTimeEmailTab from 'components/pages/Marketing/Campaigns/OneTimeEmail/OneTimeEmailTab'
import OneTimeMessageSteps from 'components/pages/Marketing/Campaigns/OneTimeMessage/OneTimeMessageSteps'
import OneTimeMessageTab from 'components/pages/Marketing/Campaigns/OneTimeMessage/OneTimeMessageTab'
import OneTimeSms from 'components/pages/Marketing/Campaigns/OneTimeSms'
import InsideSteps from 'components/pages/Projects/Projects/InsideSteps'
import ProjectTemplateInsideSteps from 'components/pages/Projects/Projects/ProjectTemplates/ProjectTemplateInsideSteps'
import PublicForm from 'components/pages/Projects/Projects/Steps/Forms/PublicFrom/PublicForm'
import UpdateFormPage from 'components/pages/Projects/Projects/Steps/Forms/UpdateFormPage'
import ApprovePage from 'components/pages/Projects/Proofs/ApprovePage'
import ProofsList from 'components/pages/Projects/Proofs/ProofsList'
import ResourcePlanning from 'components/pages/Projects/ResourcePlanning/ResourcePlanning'
import TimeTracking from 'components/pages/Projects/TimeTracking/TimeTracking'
import Deals from 'components/pages/Sales/Deals/Deals'
import DealsDetails from 'components/pages/Sales/Deals/DealsDetails'
import Forecast from 'components/pages/Sales/Forecast/Forecast'
import MeetingBook from 'components/pages/Sales/Meetings/MeetingBook'
import MeetingConfrimd from 'components/pages/Sales/Meetings/MeetingConfrimd'
import MeetingCreate from 'components/pages/Sales/Meetings/MeetingCreate'
import MeetingList from 'components/pages/Sales/Meetings/MeetingsList'
import MeetingUpdate from 'components/pages/Sales/Meetings/MeetingUpdate'
import QuotesForm from 'components/pages/Sales/Quotes/QuotesForm'
import QuotesList from 'components/pages/Sales/Quotes/QuotesList'
import Sequences from 'components/pages/Sales/Sequences/Sequences'
import SequencesCreate from 'components/pages/Sales/Sequences/SequencesCreate'
import SequencesDetails from 'components/pages/Sales/Sequences/SequencesDetails'
import SequencesInsights from 'components/pages/Sales/Sequences/SequencesInsights'
import ThankYou from 'components/pages/ThankYou'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import AppLayout from 'layouts/AppLayout'
import UnsavedWrapLayout from 'layouts/UnsavedWrapLayout'
import Analytics from 'pages/authenticated/Analytics'
import Automations from 'pages/authenticated/Automations'
import Campaign from 'pages/authenticated/Campaign'
import ClientPortal from 'pages/authenticated/Customers/ClientPortal'
import Dashboards from 'pages/authenticated/Dashboards'
import {Inbox} from 'pages/authenticated/Email'
import Invoices from 'pages/authenticated/Finance/Invoices'
import PurchaseOrders from 'pages/authenticated/Finance/PurchaseOrders'
import Purchases from 'pages/authenticated/Finance/Purchases'
import SalesOrders from 'pages/authenticated/Finance/SalesOrders'
import Vendors from 'pages/authenticated/Finance/Vendors'
import GettingStarted from 'pages/authenticated/GettingStarted'
import Home from 'pages/authenticated/Home'
import Employees from 'pages/authenticated/Hr/Employees'
import Expenses from 'pages/authenticated/Hr/Expenses'
import TimeOff from 'pages/authenticated/Hr/TimeOff'
import Budgets from 'pages/authenticated/Projects/Budgets'
import Projects from 'pages/authenticated/Projects/Projects'
import Tasks from 'pages/authenticated/Projects/Tasks'
import Products from 'pages/authenticated/Sales/Products'
import Settings from 'pages/authenticated/Settings'
import Goals from 'pages/authenticated/Teams/Goals/Goals'
import DomainVerification from 'pages/unauthenticated/DomainVerification'
import Emailverify from 'pages/unauthenticated/Emailverify'
import Signin from 'pages/unauthenticated/SignIn'
import SignUp from 'pages/unauthenticated/SignUp'
import {Redirect, Route, Switch} from 'react-router-dom'
import CreateGoals from 'pages/authenticated/Teams/Goals/CreateGoals'
import GoalsDetails from 'pages/authenticated/Teams/Goals/GoalsDetails'
import {
  ANNOUNCEMENTS_PATH,
  APPRAISALS_PATH,
  AUTOMATIONS_PATH,
  BUDGETING_PATH,
  BUDGETS_PLANNER_PATH,
  CAMPAIGNS_PATH,
  CLIENT_PORTAL_PATH,
  COMPANIES_PATH,
  CONTACTS_PATH,
  DASHBOARDS_PATH,
  DEALS_PATH,
  EMPLOYEES_PATH,
  EXPENSES_PATH,
  FORECASTS_PATH,
  INVOICES_PATH,
  MEETINGS_PATH,
  PRODUCTS_PATH,
  PROJECTS_PATH,
  PROOFS_PATH,
  PURCHASE_ORDERS_PATH,
  PURCHASES_PATH,
  QUOTES_PATH,
  REPORTS_PATH,
  RESOURCE_PLANNING_PATH,
  SALES_ORDERS_PATH,
  SEQUENCES_PATH,
  SETTINGS_PATH,
  TASKS_PATH,
  TIME_OFF_PATH,
  TIMESHEETS_PATH,
  VENDORS_PATH,
} from 'constants/path'
import ProtectedRoute from './ProtectedRoute'

// eslint-disable-next-line no-useless-escape
const subDomainRegex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i

const authRoutes = [
  {
    path: '/',
    exact: true,
    component: window.location.hostname.match(subDomainRegex) !== null ? Signin : DomainVerification,
    title: window.location.hostname.match(subDomainRegex) !== null ? 'Signin' : 'Domain Verification',
  },
  {
    path: '/signin',
    exact: true,
    component:
      window.location.hostname.match(subDomainRegex) !== null || window.localStorage.getItem('domain')
        ? Signin
        : DomainVerification,
    title:
      window.location.hostname.match(subDomainRegex) !== null || window.localStorage.getItem('domain')
        ? 'Signin'
        : 'Domain Verification',
  },
  {path: '/signup', exact: true, component: SignUp, title: 'Sign up'},
  {path: '/emailverify', exact: true, component: Emailverify, title: 'Email Verify'},
  {path: '/thankyou', exact: true, component: ThankYou, title: 'Thank You'},
]

const routesWithLayout = [
  {path: '/getting-started', exact: true, component: GettingStarted, title: 'Getting Started'},
  {path: '/home', exact: true, component: Home, title: 'Home'},
  {
    path: `/${CONTACTS_PATH.PREFIX}/:id`,
    exact: true,
    component: ContactCustomer,
    title: 'Contact Details',
    contacts: true,
  },
  {path: `/${CONTACTS_PATH.PREFIX}`, component: Contacts, title: 'Contacts'},
  {
    path: `/${COMPANIES_PATH.PREFIX}/:id`,
    exact: true,
    component: CompaniesDetail,
    title: 'Company Details',
    companies: true,
  },
  {path: `/${COMPANIES_PATH.PREFIX}`, component: Companies, title: 'Companies'},
  {path: `/${CLIENT_PORTAL_PATH.PREFIX}`, component: ClientPortal, title: 'Client Portal'},
  {path: '/calendar', exact: true, component: CalendarContainer, title: 'Calendar'},
  {path: '/activities-feed', exact: true, component: ActivityFeed, title: 'Activities Feed'},
  {path: '/live-visitors', exact: true, component: LiveVisitors, title: 'Live Visitors'},
  {path: '/inbox', exact: true, component: Inbox, title: 'Inbox'},
  {path: `/${DEALS_PATH.PREFIX}`, exact: true, component: Deals, title: 'Deals'},
  {path: `/${DEALS_PATH.PREFIX}/:dealId`, exact: true, component: DealsDetails, title: 'Deals details', deals: true},
  {path: `/${CAMPAIGNS_PATH.PREFIX}`, component: Campaign, title: 'Campaigns'},
  {path: `/${MEETINGS_PATH.PREFIX}`, exact: true, component: MeetingList, title: 'Meetings'},
  {path: `/${FORECASTS_PATH.PREFIX}`, exact: true, component: Forecast, title: 'Forecasting'},
  {path: `/${SEQUENCES_PATH.PREFIX}`, exact: true, component: Sequences, title: 'Sequences'},
  {path: `/${QUOTES_PATH.PREFIX}`, exact: true, component: QuotesList, title: 'Quotes'},
  {path: `/${PRODUCTS_PATH.PREFIX}`, component: Products, title: 'All Products'},
  {path: `/${BUDGETING_PATH.PREFIX}`, exact: true, component: Budgets, title: 'Budgeting'},
  {path: `/${TASKS_PATH.PREFIX}`, exact: true, component: Tasks, title: 'Tasks'},
  {path: `/${TIMESHEETS_PATH.PREFIX}`, exact: true, component: TimeTracking, title: 'Timesheets'},
  {path: `/${PROOFS_PATH.PREFIX}`, component: ProofsList, title: 'Proofs'},
  {path: `/${RESOURCE_PLANNING_PATH.PREFIX}`, component: ResourcePlanning, title: 'Resource Planning'},
  {path: `/${INVOICES_PATH.PREFIX}`, component: Invoices, title: 'Invoices'},
  {path: `/${SALES_ORDERS_PATH.PREFIX}`, component: SalesOrders, title: 'Sales Orders'},
  {path: `/${PURCHASE_ORDERS_PATH.PREFIX}`, component: PurchaseOrders, title: 'Purchases Orders'},
  {path: `/${BUDGETS_PLANNER_PATH.PREFIX}`, exact: true, component: BudgetsPlanner, title: 'Budgets Planner'},
  {path: `/${VENDORS_PATH.PREFIX}`, component: Vendors, title: 'Vendors'},
  {path: `/${PURCHASES_PATH.PREFIX}`, component: Purchases, title: 'Purchases'},
  {path: `/${EMPLOYEES_PATH.PREFIX}`, component: Employees, title: 'Employees'},
  {path: `/${TIME_OFF_PATH.PREFIX}`, component: TimeOff, title: 'TimeOff'},
  {path: `/${EXPENSES_PATH.PREFIX}`, component: Expenses, title: 'Expenses'},
  {path: '/payroll', exact: true, component: Payroll, title: 'Payroll'},
  {path: '/contracts', exact: true, component: Contracts, title: 'Contracts'},
  {path: `/${ANNOUNCEMENTS_PATH.PREFIX}`, exact: true, component: Announcements, title: 'Announcement'},
  {path: `/${AUTOMATIONS_PATH.PREFIX}`, exact: true, component: Automations, title: 'Automation'},
  {path: `/${DASHBOARDS_PATH.PREFIX}`, component: Dashboards, title: 'Dashboards'},
  {path: `/${REPORTS_PATH.PREFIX}`, component: Analytics, title: 'Reports'},
  {path: `/${SETTINGS_PATH.PREFIX}`, component: Settings, title: 'Settings'},
  {path: '/goals', component: Goals, title: 'Goals'},
]

const routesWithoutLayout = [
  {
    path: `/${CLIENT_PORTAL_PATH.PREFIX}/${CLIENT_PORTAL_PATH.ANNOUNCEMENTS}/${CLIENT_PORTAL_PATH.ANNOUNCEMENTS_CREATE}`,
    exact: true,
    component: AnnouncementDetails,
    title: 'Create Announcement',
    clientPortal: true,
  },
  {
    path: `/${CLIENT_PORTAL_PATH.PREFIX}/${CLIENT_PORTAL_PATH.ANNOUNCEMENTS}/${CLIENT_PORTAL_PATH.ANNOUNCEMENTS_EDIT}/:id`,
    exact: true,
    component: AnnouncementDetails,
    title: 'Edit Announcement',
  },
  {path: `/${MEETINGS_PATH.PREFIX}/:meetingId`, exact: true, component: MeetingUpdate, title: 'Meetings'},
  {
    path: `/${PROJECTS_PATH.PREFIX}/:projectId/${PROJECTS_PATH.FORMS}/:id`,
    exact: true,
    component: UpdateFormPage,
    title: 'Form',
  },
  {
    path: `/${PROOFS_PATH.PREFIX}/${PROOFS_PATH.APPROVE}/:id`,
    exact: true,
    component: ApprovePage,
    title: 'Proofs',
    unsavedWrap: true,
  },
  {
    path: `/${EMPLOYEES_PATH.PREFIX}/${EMPLOYEES_PATH.CREATE}`,
    exact: true,
    component: CreateEmployee,
    title: 'Create Employees',
    unsavedWrap: true,
  },
  {
    path: `/${EMPLOYEES_PATH.PREFIX}/${EMPLOYEES_PATH.UPDATE}/:id`,
    exact: true,
    component: CreateEmployee,
    title: 'Update Employees',
    unsavedWrap: true,
  },
  {
    path: `/${TIME_OFF_PATH.PREFIX}/${TIME_OFF_PATH.CREATE}`,
    exact: true,
    component: CreateTimeOffForm,
    title: 'Create Time-off',
    unsavedWrap: true,
  },
  {
    path: `/${TIME_OFF_PATH.PREFIX}/${TIME_OFF_PATH.UPDATE}/:id`,
    exact: true,
    component: CreateTimeOffForm,
    title: 'Update Time-off',
  },
  {
    path: `/${EXPENSES_PATH.PREFIX}/${EXPENSES_PATH.CREATE}`,
    exact: true,
    component: CreateExpense,
    title: 'Create Expenses',
    unsavedWrap: true,
  },
  {
    path: `/${EXPENSES_PATH.PREFIX}}/${EXPENSES_PATH.UPDATE}/:id`,
    exact: true,
    component: CreateExpense,
    title: 'Update Expenses',
    unsavedWrap: true,
  },
  {
    path: `/${ANNOUNCEMENTS_PATH.PREFIX}/${ANNOUNCEMENTS_PATH.CREATE}`,
    exact: true,
    component: CreateAnnouncement,
    title: 'Create Announcements',
    unsavedWrap: true,
  },
  {
    path: `/${ANNOUNCEMENTS_PATH.PREFIX}/${ANNOUNCEMENTS_PATH.UPDATE}/:id`,
    exact: true,
    component: CreateAnnouncement,
    title: 'Update Announcements',
    unsavedWrap: true,
  },
  {
    path: `/${AUTOMATIONS_PATH.PREFIX}/${AUTOMATIONS_PATH.BUILDER}`,
    exact: true,
    component: AutomationBuilder,
    title: 'Automations',
  },
  {path: '/goals/create/:type', component: CreateGoals, title: 'Goals Create', exact: true},
  {path: '/goals/create/:type/:personalType', component: CreateGoals, title: 'Goals Create', exact: true},
]

const publicRoutes = [
  {path: '/account/:domian/:userName', exact: true, component: MeetingBook, title: 'Meeting Booking'},
  {
    path: `/${MEETINGS_PATH.PREFIX}/${MEETINGS_PATH.CONFIRM}/new`,
    exact: true,
    component: MeetingConfrimd,
    title: 'Meetings Confirm New',
  },
  {path: '/public/form/:id', exact: true, component: PublicForm, title: 'Form'},
]

const ConditionalRouteWithLayout = ({
  component: Component,
  condition = false,
  fallbackRoute,
  unsavedWrap = false,
  ...rest
}) => {
  return (
    <ProtectedRoute
      key={rest.path}
      {...rest}
      render={props =>
        condition ? (
          <AppLayout>
            <UnsavedWrapLayout unsavedWrap={unsavedWrap}>
              <Component {...props} />
            </UnsavedWrapLayout>
          </AppLayout>
        ) : (
          <Redirect to={fallbackRoute} />
        )
      }
    />
  )
}

const AuthRoute = ({path, component: Component, title, exact = false}) => (
  <Route
    exact={exact}
    path={path}
    key={path}
    render={props => (
      <>
        <DocumentTitle title={title} />
        <Component {...props} />
      </>
    )}
  />
)

const ConditionalRouteWithoutLayout = ({
  component: Component,
  condition = false,
  fallbackRoute,
  unsavedWrap = false,
  ...rest
}) => {
  return (
    <ProtectedRoute
      key={rest.path}
      {...rest}
      render={props =>
        condition ? (
          <UnsavedWrapLayout unsavedWrap={unsavedWrap}>
            <Component {...props} />
          </UnsavedWrapLayout>
        ) : (
          <Redirect to={fallbackRoute} />
        )
      }
    />
  )
}

const Routes = () => {
  const {
    salesAndCrmAccess,
    financeAccess,
    projectsAndCollaborationAccess,
    workflowAndAutomationAccess,
    meetingsPermissions,
    sequencesPermissions,
    quotesPermissions,
    projectsPermissions,
    financePermissions,
  } = useUserPermissions()
  const campaignsAccess = salesAndCrmAccess?.campaigns
  const salesSequencesAccess = salesAndCrmAccess?.salesSequences
  const quotesAccess = salesAndCrmAccess?.quotes
  const invoicesAccess = financeAccess?.invoices
  const salesOrdersAccess = financeAccess?.salesOrders
  const purchaseOrdersAccess = financeAccess?.purchaseOrders
  const budgetPlannerAccess = financeAccess?.budgetPlanner
  const vendorsAccess = financeAccess?.vendors
  const purchasesAccess = financeAccess?.purchases
  const projectsAccess = projectsAndCollaborationAccess?.projects
  const advanceWorkflowsAccess = workflowAndAutomationAccess?.advanceWorkflows
  const manageMeetingsPermissions = meetingsPermissions?.manage_meetings
  const manageSequencesPermissions = sequencesPermissions?.manage_sequences
  const manageQuotesPermissions = quotesPermissions?.manage_quotes
  const manageInvoicesPermissions = financePermissions?.manage_invoices
  const manageSalesOrdersPermissions = financePermissions?.manage_sales_orders
  const managePurchaseOrdersPermissions = financePermissions?.manage_purchase_orders
  const manageVendorsPermissions = financePermissions?.manage_vendors
  const managePurchasesPermissions = financePermissions?.manage_purchases

  const conditionalRoutesWithLayout = [
    {
      path: `/${SEQUENCES_PATH.PREFIX}/:id/${SEQUENCES_PATH.INSIGHTS}`,
      exact: true,
      component: SequencesInsights,
      condition: salesSequencesAccess,
      fallbackRoute: `/${SEQUENCES_PATH.PREFIX}`,
      title: 'Sequences',
    },
    {
      path: `/${PROJECTS_PATH.PREFIX}/${PROJECTS_PATH.TEMPLATES}/:templateId`,
      component: ProjectTemplateInsideSteps,
      condition: projectsAccess,
      fallbackRoute: `/${PROJECTS_PATH.PREFIX}/${PROJECTS_PATH.TEMPLATES}`,
      title: 'Templates Steps',
    },
  ]

  const conditionalRoutesWithoutLayout = [
    {
      path: `/${CAMPAIGNS_PATH.PREFIX}/${CAMPAIGNS_PATH.MESSAGE}`,
      exact: true,
      component: OneTimeMessageSteps,
      condition: campaignsAccess,
      fallbackRoute: `/${CAMPAIGNS_PATH.PREFIX}`,
      title: 'Message',
      unsavedWrap: true,
      campaign: true,
    },
    {
      path: `/${CAMPAIGNS_PATH.PREFIX}/${CAMPAIGNS_PATH.MESSAGE}/:id`,
      exact: true,
      component: OneTimeMessageTab,
      condition: campaignsAccess,
      fallbackRoute: `/${CAMPAIGNS_PATH.PREFIX}`,
      title: 'Message',
      unsavedWrap: true,
      campaign: true,
    },
    {
      path: `/${CAMPAIGNS_PATH.PREFIX}/email`,
      exact: true,
      component: OneTimeEmailSteps,
      condition: campaignsAccess,
      fallbackRoute: `/${CAMPAIGNS_PATH.PREFIX}`,
      title: 'Email',
      unsavedWrap: true,
      campaign: true,
    },
    {
      path: `/${CAMPAIGNS_PATH.PREFIX}/${CAMPAIGNS_PATH.EMAIL}/:id`,
      exact: true,
      component: OneTimeEmailTab,
      condition: campaignsAccess,
      fallbackRoute: `/${CAMPAIGNS_PATH.PREFIX}`,
      title: 'Email',
      unsavedWrap: true,
      campaign: true,
    },
    {
      path: `/${CAMPAIGNS_PATH.PREFIX}/${CAMPAIGNS_PATH.SMS}`,
      exact: true,
      component: OneTimeSms,
      condition: campaignsAccess,
      fallbackRoute: `/${CAMPAIGNS_PATH.PREFIX}`,
      title: 'SMS',
      unsavedWrap: true,
      campaign: true,
    },
    {
      path: `/${CAMPAIGNS_PATH.PREFIX}/${CAMPAIGNS_PATH.SMS}/:id`,
      exact: true,
      component: OneTimeSms,
      condition: campaignsAccess,
      fallbackRoute: `/${CAMPAIGNS_PATH.PREFIX}`,
      title: 'SMS',
      unsavedWrap: true,
      campaign: true,
    },
    {
      path: `/${MEETINGS_PATH.PREFIX}/${MEETINGS_PATH.CREATE}`,
      exact: true,
      component: MeetingCreate,
      condition: manageMeetingsPermissions,
      fallbackRoute: `/${MEETINGS_PATH.PREFIX}`,
      title: 'Meetings Create',
    },
    {
      path: `/${SEQUENCES_PATH.PREFIX}/${SEQUENCES_PATH.CREATE}`,
      exact: true,
      component: SequencesCreate,
      condition: salesSequencesAccess && manageSequencesPermissions,
      fallbackRoute: `/${SEQUENCES_PATH.PREFIX}`,
      title: 'Sequences Create',
      unsavedWrap: true,
    },
    {
      path: `/${SEQUENCES_PATH.PREFIX}/:id`,
      exact: true,
      component: SequencesDetails,
      condition: salesSequencesAccess,
      fallbackRoute: `/${SEQUENCES_PATH.PREFIX}`,
      title: 'Sequences',
      unsavedWrap: true,
    },
    {
      path: `/${QUOTES_PATH.PREFIX}/${QUOTES_PATH.ADD}`,
      exact: true,
      component: QuotesForm,
      condition: quotesAccess && manageQuotesPermissions,
      fallbackRoute: `/${QUOTES_PATH.PREFIX}`,
      title: 'Quotes Add',
      unsavedWrap: true,
    },
    {
      path: `/${QUOTES_PATH.PREFIX}/:id`,
      exact: true,
      component: QuotesForm,
      condition: quotesAccess,
      fallbackRoute: `/${QUOTES_PATH.PREFIX}`,
      title: 'Quotes',
      unsavedWrap: true,
    },
    {
      path: `/${INVOICES_PATH.PREFIX}/${INVOICES_PATH.CREATE}`,
      exact: true,
      component: InvoicesIndex,
      condition: invoicesAccess && manageInvoicesPermissions,
      fallbackRoute: `/${INVOICES_PATH.PREFIX}`,
      title: 'Create Invoices',
      unsavedWrap: true,
    },
    {
      path: `/${INVOICES_PATH.PREFIX}/${INVOICES_PATH.UPDATE}/:id`,
      exact: true,
      component: InvoicesEdit,
      condition: invoicesAccess,
      fallbackRoute: `/${INVOICES_PATH.PREFIX}`,
      title: 'Invoices',
      unsavedWrap: true,
    },
    {
      path: `/${SALES_ORDERS_PATH.PREFIX}/${SALES_ORDERS_PATH.CREATE}`,
      exact: true,
      component: IndexSalesOrders,
      condition: salesOrdersAccess && manageSalesOrdersPermissions,
      fallbackRoute: `/${SALES_ORDERS_PATH.PREFIX}`,
      title: 'Create Sales Orders',
      unsavedWrap: true,
    },
    {
      path: `/${SALES_ORDERS_PATH.PREFIX}/${SALES_ORDERS_PATH.UPDATE}/:id`,
      exact: true,
      component: SalesOrdersEdit,
      condition: salesOrdersAccess,
      fallbackRoute: `/${SALES_ORDERS_PATH.PREFIX}`,
      title: 'Sales Orders',
      unsavedWrap: true,
    },
    {
      path: `/${PURCHASE_ORDERS_PATH.PREFIX}/${PURCHASE_ORDERS_PATH.CREATE}`,
      exact: true,
      component: Index,
      condition: purchaseOrdersAccess && managePurchaseOrdersPermissions,
      fallbackRoute: `/${PURCHASE_ORDERS_PATH.PREFIX}`,
      title: 'Create Purchase Orders',
      unsavedWrap: true,
    },
    {
      path: `/${PURCHASE_ORDERS_PATH.PREFIX}/${PURCHASE_ORDERS_PATH.UPDATE}/:id`,
      exact: true,
      component: PurchaseOrdersEdit,
      condition: purchaseOrdersAccess,
      fallbackRoute: `/${PURCHASE_ORDERS_PATH.PREFIX}`,
      title: 'Purchase Orders',
      unsavedWrap: true,
    },
    {
      path: `/${BUDGETS_PLANNER_PATH.PREFIX}/${BUDGETS_PLANNER_PATH.NEW}`,
      exact: true,
      component: CreateBudgetPlanner,
      condition: budgetPlannerAccess,
      fallbackRoute: `/${BUDGETS_PLANNER_PATH.PREFIX}`,
      title: 'Create Budgets Planner',
      unsavedWrap: true,
    },
    {
      path: `/${BUDGETS_PLANNER_PATH.PREFIX}/${BUDGETS_PLANNER_PATH.NEW}/:id`,
      exact: true,
      component: CreateBudgetPlanner,
      condition: budgetPlannerAccess,
      fallbackRoute: `/${BUDGETS_PLANNER_PATH.PREFIX}`,
      title: 'Update Budgets Planner',
      unsavedWrap: true,
    },
    {
      path: `/${VENDORS_PATH.PREFIX}/${VENDORS_PATH.CREATE}`,
      exact: true,
      component: CreateModal,
      condition: vendorsAccess && manageVendorsPermissions,
      fallbackRoute: `/${VENDORS_PATH.PREFIX}`,
      title: 'Vendors Create',
      unsavedWrap: true,
    },
    {
      path: `/${VENDORS_PATH.PREFIX}/${VENDORS_PATH.UPDATE}/:id`,
      exact: true,
      component: CreateModal,
      condition: vendorsAccess,
      fallbackRoute: `/${VENDORS_PATH.PREFIX}`,
      title: 'Vendors',
    },
    {
      path: `/${PURCHASES_PATH.PREFIX}/${PURCHASES_PATH.ADD}`,
      exact: true,
      component: PurchaseModal,
      condition: purchasesAccess && managePurchasesPermissions,
      fallbackRoute: `/${PURCHASES_PATH.PREFIX}`,
      title: 'Add purchases',
      unsavedWrap: true,
    },
    {
      path: `/${PURCHASES_PATH.PREFIX}/${PURCHASES_PATH.UPDATE}/:id`,
      exact: true,
      component: PurchaseModal,
      condition: purchasesAccess,
      fallbackRoute: `/${PURCHASES_PATH.PREFIX}`,
      title: 'Purchases',
      unsavedWrap: true,
    },
    {
      path: `/${AUTOMATIONS_PATH.PREFIX}/${AUTOMATIONS_PATH.BUILDER}`,
      exact: true,
      component: AutomationBuilder,
      condition: advanceWorkflowsAccess,
      fallbackRoute: `/${AUTOMATIONS_PATH.PREFIX}`,
      title: 'Automations',
    },
    {
      path: `/${AUTOMATIONS_PATH.PREFIX}/${AUTOMATIONS_PATH.BUILDER}/:id`,
      exact: true,
      component: AutomationBuilder,
      condition: advanceWorkflowsAccess,
      fallbackRoute: `/${AUTOMATIONS_PATH.PREFIX}`,
      title: 'Automations',
    },
  ]

  return (
    <Switch>
      {authRoutes.map(route => (
        <AuthRoute key={route.path} {...route} />
      ))}

      {conditionalRoutesWithLayout.map(route => (
        <ConditionalRouteWithLayout key={route.path} {...route} />
      ))}

      {conditionalRoutesWithoutLayout.map(route => (
        <ConditionalRouteWithoutLayout key={route.path} {...route} />
      ))}

      {routesWithoutLayout.map(({component: Component, unsavedWrap = false, ...route}) => (
        <ProtectedRoute
          key={route.path}
          {...route}
          render={props => (
            <UnsavedWrapLayout unsavedWrap={unsavedWrap}>
              <Component {...props} />
            </UnsavedWrapLayout>
          )}
        />
      ))}

      {routesWithLayout.map(({component: Component, ...rest}) => (
        <ProtectedRoute
          key={rest.path}
          {...rest}
          render={props => (
            <AppLayout>
              <Component {...props} />
            </AppLayout>
          )}
        />
      ))}

      {publicRoutes.map(({path, exact = false, component: Component, title}) => (
        <Route
          key={path}
          path={path}
          exact={exact}
          render={props => (
            <>
              <DocumentTitle title={title} />
              <Component {...props} />
            </>
          )}
        />
      ))}

      {/* Start:- Warning: do not move below 2 routes to anywhere
      1.> /projects/:projectId(\d+)
      2.> /projects
      */}
      <ProtectedRoute
        path="/projects/:projectId(\d+)"
        render={props => (
          <>
            {projectsAccess ? (
              <>
                <AppLayout {...props}>
                  <DocumentTitle title="Projects Steps" />
                  <InsideSteps {...props} />
                </AppLayout>
              </>
            ) : (
              <Redirect to={`/${PROJECTS_PATH.PREFIX}`} />
            )}
          </>
        )}
      />

      <ProtectedRoute
        path={`/${PROJECTS_PATH.PREFIX}`}
        render={props => (
          <AppLayout {...props}>
            <DocumentTitle title="Projects" />
            <Projects {...props} />
          </AppLayout>
        )}
      />
      {/* End Warning */}

      {/* <ProtectedRoute
        exact
        path=`${APPRAISALS_PATH.PREFIX}`
        render={props => (
          <AppLayout>
            <DocumentTitle title="Appraisal" />
            <Appraisal {...props} />
          </AppLayout>
        )}
      />

      <ProtectedRoute
        exact
        path=`/${APPRAISALS_PATH.PREFIX}/${APPRAISALS_PATH.MANAGE}`
        render={props => (
          <AppLayout>
            <DocumentTitle title="Appraisal" />
            <ManageAppraisal {...props} />
          </AppLayout>
        )}
      /> */}

      <ProtectedRoute
        exact
        path={`/${APPRAISALS_PATH.PREFIX}/1`}
        render={props => (
          <>
            <DocumentTitle title="Appraisal" />
            <UnSavedWrap>
              <ViewAppraisal {...props} />
            </UnSavedWrap>
          </>
        )}
      />

      <ProtectedRoute
        exact
        path={`/${APPRAISALS_PATH.PREFIX}/${APPRAISALS_PATH.TEMPLATES}`}
        render={props => (
          <AppLayout>
            <DocumentTitle title="Appraisal" />
            <AppraisalTemplate {...props} />
          </AppLayout>
        )}
      />

      <ProtectedRoute
        exact
        path={`/${APPRAISALS_PATH.PREFIX}/${APPRAISALS_PATH.SEND}/:id`}
        render={props => (
          <>
            <DocumentTitle title="Appraisal" />
            <UnSavedWrap>
              <AppraisalSend {...props} />
            </UnSavedWrap>
          </>
        )}
      />

      <ProtectedRoute
        path={`/${APPRAISALS_PATH.PREFIX}/${APPRAISALS_PATH.CREATE}`}
        render={props => (
          <>
            <DocumentTitle title="Create Appraisal" />
            <UnSavedWrap>
              <CreateAppraiselTemplate {...props} />
            </UnSavedWrap>
          </>
        )}
      />

      <ProtectedRoute
        exact
        path={`/${APPRAISALS_PATH.PREFIX}/${APPRAISALS_PATH.UPDATE}/:id`}
        render={props => (
          <>
            <DocumentTitle title="Update Appraisal" />
            <UnSavedWrap>
              <CreateAppraiselTemplate {...props} />
            </UnSavedWrap>
          </>
        )}
      />

      <Redirect to="/home" />
    </Switch>
  )
}

export default Routes
