import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import InvoicesList from 'components/pages/Finance/Invoices/InvoicesList'
import PrefixList from 'components/pages/Finance/Invoices/settings/Prefix/PrefixList'
import {INVOICES_PATH} from 'constants/path'

const routes = [
  {path: INVOICES_PATH.ALL, component: InvoicesList, title: 'All Invoices', exact: true},
  {path: INVOICES_PATH.INVOICE_PREFIX, component: PrefixList, title: 'Prefix'},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={`/${INVOICES_PATH.PREFIX}/${path}`}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} invoices />
        <Component {...props} />
      </>
    )}
  />
)

const InvoicesRoutes = () => (
  <Switch>
    <Route exact path={`/${INVOICES_PATH.PREFIX}`}>
      <Redirect to={`/${INVOICES_PATH.PREFIX}/${INVOICES_PATH.ALL}`} />
    </Route>
    {routes.map(renderRoute)}
    <Route path={`/${INVOICES_PATH.PREFIX}/*`}>
      <Redirect to={`/${INVOICES_PATH.PREFIX}/${INVOICES_PATH.ALL}`} />
    </Route>
  </Switch>
)

export default InvoicesRoutes
