import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiChevronRight} from 'react-icons/fi'
import _ from 'lodash'
import moment from 'moment'
import {
  KooperButton,
  KooperCheckbox,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperGrid,
  KooperGridColumn,
  KooperGridRow,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {EXPORT_PROJECTS_TIMESHEET, GET_PROJECTS_TIMESHEET_TIME_LOGS} from 'actions/types'
import {
  deleteProjectsTimesheetTimeLogs,
  exportProjectsTimesheet,
  statusProjectsTimesheetTimeLogs,
} from 'actions/projects'
import {downloadResponseCSV, get, getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import {timeStatusList} from 'constants/projects'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import TimeModal from './TimeModal'

const TimesheetDetails = props => {
  const {projectId, timesheetId, currentListParams, setCurrentListParams, checked, setChecked, rolePermissions} = props

  const dispatch = useDispatch()

  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false)
  const [tableData, setTableData] = useState([])
  const [activeAccordion, setActiveAccordion] = useState(-1)
  const [timeId, setTimeId] = useState(null)

  const {successLabels = [], type = []} = useSelector(state => state.apiReducer)
  const {
    getAllTimeLogs = {},
    timesheetSummary = {},
    projectAssignees = [],
    exportTimesheetData,
    timesheetList = [],
  } = useSelector(state => state.projects)

  useEffect(() => {
    if (successLabels.includes(GET_PROJECTS_TIMESHEET_TIME_LOGS)) {
      const data = Object.keys(getAllTimeLogs).map(key => ({
        key,
        data: getAllTimeLogs[key],
      }))
      setTableData(data)
    }
  }, [successLabels, getAllTimeLogs])

  useEffect(() => {
    if (successLabels.includes(EXPORT_PROJECTS_TIMESHEET)) {
      const fileName = timesheetList.find(timesheet => timesheet.id === timesheetId)?.title || 'timesheet'
      downloadResponseCSV(exportTimesheetData, fileName)
    }
  }, [successLabels, exportTimesheetData, timesheetList, timesheetId])

  const getValue = key => {
    if (currentListParams.groupBy === 'date') {
      return moment(key).format('MMM DD')
    }
    if (currentListParams.groupBy === 'month') {
      return moment(key).format('MMM, YYYY')
    }
    if (currentListParams.groupBy === 'person') {
      const assignee = projectAssignees.find(assignee => assignee.id === +key)
      if (assignee) {
        return `${get(['firstName'], assignee, '')} ${get(['lastName'], assignee, '')}`
      }
      return null
    }
    return null
  }

  const handleCheckAll = () => {
    const allIds = tableData
      .map(({data}) => data)
      .flat()
      .map(({id}) => id)
    if (_.xor(allIds, checked).length === 0) {
      setChecked([])
    } else {
      setChecked(allIds)
    }
  }

  const isCheckedAll = () => {
    const allIds = tableData
      .map(({data}) => data)
      .flat()
      .map(({id}) => id)
    const checkedAll = _.xor(allIds, checked).length === 0 && allIds.length !== 0
    if (checkedAll) {
      return true
    }
    return false
  }

  const isChecked = id => {
    const check = checked.find(check => check === id)
    if (check) {
      return true
    }
    return false
  }

  const handleCheck = id => {
    const isChecked = checked.some(check => check === id)
    if (isChecked) {
      const removeChecked = checked.filter(check => check !== id)
      setChecked(removeChecked)
    } else {
      setChecked([...checked, id])
    }
  }

  const renderTableBody = () => {
    if (type.includes(GET_PROJECTS_TIMESHEET_TIME_LOGS)) {
      return <KooperTablePlaceholder columns={7} />
    }
    if (!type.includes(GET_PROJECTS_TIMESHEET_TIME_LOGS) && !tableData.length) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="7" className="emptyValue">
            No Data Available
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return tableData.map(({key, data}, index) => (
      <>
        <KooperTableRow
          style={{cursor: 'pointer'}}
          onClick={() => setActiveAccordion(activeAccordion === index ? '-1' : index)}
        >
          <KooperTableCell className="d-flex" style={{justifyContent: 'flex-start'}}>
            {activeAccordion === index ? (
              <SvgIcon path="common/down" style={{marginRight: 8}} />
            ) : (
              <FiChevronRight style={{marginRight: 8}} />
            )}
            {getValue(key)}
          </KooperTableCell>
        </KooperTableRow>
        {data.map(logTime => (
          <KooperTableRow
            key={logTime.id}
            style={{
              display: activeAccordion === index ? '' : 'none',
            }}
            onClick={() => {
              if (rolePermissions) {
                setTimeId(logTime.id)
                setIsTimeModalOpen(true)
              }
            }}
          >
            <KooperTableCell className="pl-4 d-flex" style={{justifyContent: 'flex-start'}}>
              <KooperCheckbox
                className="pr-4 mr-2"
                checked={isChecked(logTime.id)}
                onChange={() => handleCheck(logTime.id)}
                onClick={e => e.stopPropagation()}
              />
              <div>
                {get(['createdByDetails', 'firstName'], logTime, '')}{' '}
                {get(['createdByDetails', 'lastName'], logTime, '')}
              </div>
            </KooperTableCell>
            <KooperTableCell>{`${get(['loggedHrs'], logTime, 0)}h ${get(['loggedMins'], logTime, 0)}m`}</KooperTableCell>
            <KooperTableCell>{getFormattedDate(logTime.date)}</KooperTableCell>

            <KooperTableCell>{logTime.tasklistDetails && get(['tasklistDetails', 'title'], logTime)}</KooperTableCell>
            <KooperTableCell>{logTime.taskDetails && get(['taskDetails', 'title'], logTime)}</KooperTableCell>
            <KooperTableCell>{startCase(get(['status'], logTime, ''))}</KooperTableCell>
          </KooperTableRow>
        ))}
      </>
    ))
  }

  return (
    <>
      <div className="project-discussion-side-wrap">
        <div className="project-discussion-head">
          <div>
            {checked.length > 0 && (
              <div className="d-flex" style={{justifyContent: 'flex-start', gap: 'var(--gap-medium)'}}>
                <h3 className="mb-0 mr-2">{`${checked.length} out of ${
                  tableData
                    .map(({data}) => data)
                    .flat()
                    .map(({id}) => id).length
                }`}</h3>
                <KooperTooltip
                  content={
                    rolePermissions ? 'Change Status' : getPermissionTooltipContent({roleAccess: rolePermissions})
                  }
                  position="top center"
                  size="mini"
                  trigger={
                    <KooperDropdown className="button icon" icon={<SvgIcon path="common/check" />}>
                      <KooperDropdownMenu>
                        {timeStatusList.map(({key, text, value}) => (
                          <KooperDropdownItem
                            key={key}
                            content={text}
                            disabled={!rolePermissions}
                            onClick={() => {
                              if (rolePermissions) {
                                const data = {status: value}
                                dispatch(
                                  statusProjectsTimesheetTimeLogs({projectId, timesheetId, timeIds: checked, data})
                                )
                              }
                            }}
                          />
                        ))}
                      </KooperDropdownMenu>
                    </KooperDropdown>
                  }
                />
                <KooperTooltip
                  content={rolePermissions ? 'Delete' : getPermissionTooltipContent({roleAccess: rolePermissions})}
                  position="top center"
                  size="mini"
                  trigger={
                    <KooperButton
                      className="icon"
                      onClick={() =>
                        rolePermissions &&
                        dispatch(deleteProjectsTimesheetTimeLogs({projectId, timesheetId, timeIds: checked}))
                      }
                    >
                      <SvgIcon path="common/delete" />
                    </KooperButton>
                  }
                />
              </div>
            )}
          </div>
          <div className="d-flex mailActivity">
            <KooperTooltip
              position="top center"
              content="Status"
              size="mini"
              trigger={
                <KooperDropdown
                  icon="chevron down"
                  button
                  className="dropdown-ellipsis"
                  options={[{key: 'all', value: 'all', text: 'All'}, ...timeStatusList]}
                  value={currentListParams.status}
                  onChange={(e, {value}) =>
                    setCurrentListParams({
                      ...currentListParams,
                      status: value,
                    })
                  }
                />
              }
            />
            <KooperTooltip
              position="top center"
              content="Logged By"
              size="mini"
              trigger={
                <KooperDropdown
                  icon="chevron down"
                  button
                  className="dropdown-ellipsis"
                  options={[
                    {key: 'all', value: 'all', text: 'All'},
                    ...projectAssignees.map(({id, firstName, lastName}) => ({
                      key: id,
                      value: id,
                      text: `${firstName} ${lastName}`,
                    })),
                  ]}
                  value={currentListParams.createdBy}
                  onChange={(e, {value}) =>
                    setCurrentListParams({
                      ...currentListParams,
                      createdBy: value,
                    })
                  }
                />
              }
            />
            <KooperTooltip
              position="top center"
              content="Group By"
              size="mini"
              trigger={
                <KooperDropdown
                  icon="chevron down"
                  button
                  className="dropdown-ellipsis"
                  options={[
                    {key: 'date', value: 'date', text: 'Date'},
                    {key: 'month', value: 'month', text: 'Month'},
                    {key: 'person', value: 'person', text: 'Person'},
                  ]}
                  value={currentListParams.groupBy}
                  onChange={(e, {value}) =>
                    setCurrentListParams({
                      ...currentListParams,
                      groupBy: value,
                    })
                  }
                />
              }
            />
            <KooperTooltip
              position="top center"
              content="Order By"
              size="mini"
              trigger={
                <KooperDropdown
                  icon="chevron down"
                  button
                  className="dropdown-ellipsis"
                  options={[
                    {key: 'asc', value: 'asc', text: 'Ascending'},
                    {key: 'desc', value: 'desc', text: 'Descending'},
                  ]}
                  value={currentListParams.orderBy}
                  onChange={(e, {value}) =>
                    setCurrentListParams({
                      ...currentListParams,
                      orderBy: value,
                    })
                  }
                />
              }
            />
            <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
              <KooperButton
                primary
                className={!rolePermissions ? 'disabled-button' : ''}
                onClick={() => {
                  if (rolePermissions) {
                    setTimeId(null)
                    setIsTimeModalOpen(true)
                  }
                }}
              >
                Add Time
              </KooperButton>
            </LockPermissionTooltip>
            {rolePermissions && (
              <KooperTooltip
                content="More"
                size="mini"
                position="top center"
                trigger={
                  <KooperDropdown
                    className="project-discussion-head-more icon button"
                    icon={<SvgIcon path="common/more-verticle" />}
                  >
                    <KooperDropdownMenu>
                      <KooperDropdownItem onClick={() => dispatch(exportProjectsTimesheet({projectId, timesheetId}))}>
                        <SvgIcon path="common/download" /> Export as CSV
                      </KooperDropdownItem>
                    </KooperDropdownMenu>
                  </KooperDropdown>
                }
              />
            )}
          </div>
        </div>

        <div className="project-discussion-body">
          <KooperGrid>
            <KooperGridRow columns={5}>
              <KooperGridColumn className="summary-grid">
                <div style={{'--summary-color': 'green'}}>
                  <span>Estimated time</span>
                  <span>{`${get(['estimatedHrs'], timesheetSummary, 0)}h ${get(
                    ['estimatedMins'],
                    timesheetSummary,
                    0
                  )}m`}</span>
                </div>
              </KooperGridColumn>
              <KooperGridColumn className="summary-grid">
                <div style={{'--summary-color': 'blue'}}>
                  <span>Total logged time</span>
                  <span>{`${get(['totalLoggedTime', 'loggedHrs'], timesheetSummary, 0)}h ${get(
                    ['totalLoggedTime', 'loggedMins'],
                    timesheetSummary,
                    0
                  )}m`}</span>
                </div>
              </KooperGridColumn>
              <KooperGridColumn className="summary-grid">
                <div style={{'--summary-color': 'orange'}}>
                  <span>Billed time</span>
                  <span>{`${get(['loggedTimeByStatus', 'billed', 'loggedHrs'], timesheetSummary, 0)}h ${get(
                    ['loggedTimeByStatus', 'billed', 'loggedMins'],
                    timesheetSummary,
                    0
                  )}m`}</span>
                </div>
              </KooperGridColumn>
              <KooperGridColumn className="summary-grid">
                <div style={{'--summary-color': 'red'}}>
                  <span>Billable time</span>
                  <span>{`${get(['loggedTimeByStatus', 'billable', 'loggedHrs'], timesheetSummary, 0)}h ${get(
                    ['loggedTimeByStatus', 'billable', 'loggedMins'],
                    timesheetSummary,
                    0
                  )}m`}</span>
                </div>
              </KooperGridColumn>
              <KooperGridColumn className="summary-grid">
                <div style={{'--summary-color': 'purple'}}>
                  <span>Non-billable</span>
                  <span>{`${get(['loggedTimeByStatus', 'non-billable', 'loggedHrs'], timesheetSummary, 0)}h ${get(
                    ['loggedTimeByStatus', 'non-billable', 'loggedMins'],
                    timesheetSummary,
                    0
                  )}m`}</span>
                </div>
              </KooperGridColumn>
            </KooperGridRow>
          </KooperGrid>

          <KooperTable basic>
            <KooperTableHeader>
              <KooperTableRow>
                <KooperTableHeaderCell className="pl-4 d-flex" style={{justifyContent: 'flex-start'}}>
                  <KooperTooltip
                    content="Select All"
                    position="top center"
                    size="mini"
                    trigger={<KooperCheckbox className="pr-4 mr-2" checked={isCheckedAll()} onChange={handleCheckAll} />}
                  />
                  <div>Logged By</div>
                </KooperTableHeaderCell>
                <KooperTableHeaderCell>Time Logged</KooperTableHeaderCell>
                <KooperTableHeaderCell>Date</KooperTableHeaderCell>
                <KooperTableHeaderCell>Tasklist</KooperTableHeaderCell>
                <KooperTableHeaderCell>Task</KooperTableHeaderCell>
                <KooperTableHeaderCell>Status</KooperTableHeaderCell>
              </KooperTableRow>
            </KooperTableHeader>

            <KooperTableBody>{renderTableBody()}</KooperTableBody>
          </KooperTable>
        </div>
      </div>

      {isTimeModalOpen && (
        <TimeModal
          open={isTimeModalOpen}
          toggle={setIsTimeModalOpen}
          projectId={projectId}
          timesheetId={timesheetId}
          timeId={timeId}
        />
      )}
    </>
  )
}

export default TimesheetDetails
