import React, {useEffect, useState} from 'react'
import {
  KooperButton,
  KooperFormField,
  KooperGrid,
  KooperGridColumn,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperRadio,
} from 'kooper-library'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import {currentSubscription, resumeBilling} from 'actions/billing'
import {useDispatch, useSelector} from 'react-redux'
import {CANCEL_BILLING, CURRENT_SUBSCRIPTION, RESUME_BILLING} from 'actions/types'
import useApiResponse from 'hooks/impure/useApiResponse'
import {get} from 'utils/helper'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import PlanDetails from './PlanDetails'
import PlanList from './PlanList'
import CancelPlanModal from './CancelPlanModal'
import ChangeCardDetails from './ChangeCardDetails'
import BillingInfo from './BillingInfo'

const getPlan = plan => {
  if (plan.includes('Monthly')) {
    return plan.replace('Monthly', 'Yearly')
  }
  if (plan.includes('Yearly')) {
    return plan.replace('Yearly', 'Monthly')
  }
  return plan
}

const PlanStep1 = props => {
  const {
    setStep,
    paymentData: {interval: subInterval = 'monthly'},
    paymentData,
    setPaymentData,
  } = props

  const dispatch = useDispatch()
  const history = useHistory()

  const [isChangeCardDetail, setIsChangeCardDetail] = useState(false)
  const [updateBillingInfo, setUpdateBillingInfo] = useState(false)
  const [cancelConfirmation, setCancelConfirmation] = useState(false)
  const [thankYou, setThankYou] = useState(false)
  const [startCancelPlan, setStartCancelPlan] = useState(false)
  const [showTrialing, setShowTrialing] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {currentSubscriptionData = {}} = useSelector(state => state.billing)
  const {manageBillingDefaultPermissions} = useUserPermissions()

  useApiResponse({
    label: RESUME_BILLING,
    onSuccess: () => {
      setStartCancelPlan(false)
      dispatch(currentSubscription())
    },
  })

  useApiResponse({
    label: CANCEL_BILLING,
    onSuccess: () => {
      dispatch(currentSubscription())
      setCancelConfirmation(false)
      setThankYou(true)
    },
  })

  useEffect(() => {
    if (successLabels.includes(CURRENT_SUBSCRIPTION)) {
      setShowTrialing(currentSubscriptionData?.subscription?.status === 'trialing')
    }
  }, [successLabels, currentSubscriptionData])

  const renderCancelPlan = () => {
    if (currentSubscriptionData?.subscription?.status === 'active' && manageBillingDefaultPermissions) {
      if (!get(['subscription', 'isCancelled'], currentSubscriptionData)) {
        return (
          <KooperGridColumn width={16} style={{textAlign: 'left', marginTop: '40px'}}>
            <h4 className="mt-4 mb-2">Want to cancel your current plan?</h4>
            <KooperButton color="red" inverted onClick={() => setCancelConfirmation(true)}>
              Cancel Plan
            </KooperButton>
          </KooperGridColumn>
        )
      }
    }
  }

  return (
    <KooperGrid>
      {isChangeCardDetail && (
        <ChangeCardDetails isChangeCardDetail={isChangeCardDetail} setIsChangeCardDetail={setIsChangeCardDetail} />
      )}
      {updateBillingInfo && (
        <BillingInfo updateBillingInfo={updateBillingInfo} setUpdateBillingInfo={setUpdateBillingInfo} />
      )}

      <KooperGridColumn width={16}>
        <PlanDetails
          currentSubscriptionData={currentSubscriptionData}
          setStartCancelPlan={setStartCancelPlan}
          setStep={setStep}
          isChangeCardDetail={isChangeCardDetail}
          setIsChangeCardDetail={setIsChangeCardDetail}
          showTrialing={showTrialing}
          setShowTrialing={setShowTrialing}
          updateBillingInfo={updateBillingInfo}
          setUpdateBillingInfo={setUpdateBillingInfo}
          rolePermissions={manageBillingDefaultPermissions}
        />

        <div className="myPlanPage">
          <div className="priceHeader">
            <h3 className="mb-2">Happy Customers, Happy Teams</h3>
            <KooperFormField>
              <KooperGrid>
                <KooperGridColumn width={16}>
                  <KooperRadio
                    id="myPlan1"
                    label="Annually"
                    inline
                    value="yearly"
                    checked={subInterval === 'yearly'}
                    onChange={(e, {value}) =>
                      setPaymentData({...paymentData, interval: value, plan: getPlan(paymentData.plan), addons: []})
                    }
                  />
                  <span>(Limited Time)</span>
                  <KooperRadio
                    id="myPlan2"
                    label="Monthly"
                    value="monthly"
                    checked={subInterval === 'monthly'}
                    onChange={(e, {value}) =>
                      setPaymentData({...paymentData, interval: value, plan: getPlan(paymentData.plan), addons: []})
                    }
                  />
                  <a href="#" className="comparePlan">
                    Compare Plans
                  </a>
                </KooperGridColumn>
              </KooperGrid>
            </KooperFormField>
          </div>

          <KooperGrid relaxed columns="equal">
            <PlanList
              subInterval={subInterval}
              setPaymentData={setPaymentData}
              setStep={setStep}
              rolePermissions={manageBillingDefaultPermissions}
            />
          </KooperGrid>
          <KooperGrid>{renderCancelPlan()}</KooperGrid>
        </div>
      </KooperGridColumn>

      {cancelConfirmation && <CancelPlanModal isOpen={cancelConfirmation} setIsOpen={setCancelConfirmation} />}

      {thankYou && (
        <KooperModal
          size="small"
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
          open={thankYou}
          onClose={() => setThankYou(false)}
        >
          <KooperModalHeader>Thank You !!!</KooperModalHeader>
          <KooperModalContent>
            <h4>Thank You for using Kooper</h4>
          </KooperModalContent>
          <KooperModalActions>
            <KooperButton
              color="primary"
              onClick={() => {
                history.push(`/inbox`)
                setThankYou(!thankYou)
              }}
            >
              OK
            </KooperButton>
          </KooperModalActions>
        </KooperModal>
      )}

      {startCancelPlan && (
        <KooperModal
          size="small"
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
          open={startCancelPlan}
          onClose={() => setStartCancelPlan(false)}
        >
          <KooperModalHeader>Reactivate Subscription</KooperModalHeader>
          <KooperModalContent>
            <h4>Are you sure you want restart the subscription ?</h4>
          </KooperModalContent>
          <KooperModalActions>
            <KooperButton primary onClick={() => dispatch(resumeBilling())}>
              OK
            </KooperButton>
          </KooperModalActions>
        </KooperModal>
      )}
    </KooperGrid>
  )
}

export default PlanStep1
