import React from 'react'
import {KooperTab, KooperTabPane} from 'kooper-library'
import SettingLayout from 'layouts/settingLayout'
import ProductsDefault from './ProductsDefault'
import ProductFields from './ProductFields'
import ProductImport from './ProductImport'

const panes = [
  {
    menuItem: 'Categories',
    render: () => (
      <KooperTabPane attached={false}>
        <ProductsDefault />
      </KooperTabPane>
    ),
  },
  {
    menuItem: 'Fields',
    render: () => (
      <KooperTabPane attached={false}>
        <ProductFields />
      </KooperTabPane>
    ),
  },
  {
    menuItem: 'Import',
    render: () => (
      <KooperTabPane attached={false}>
        <ProductImport />
      </KooperTabPane>
    ),
  },
]

function ProductsSettings() {
  return (
    <SettingLayout
      header="Settings"
      subHeader="Organize and store all your product data that can be used by sales team"
      table
      headerDivider
    >
      <KooperTab panes={panes} secondary menu={{secondary: true, pointing: true, color: 'blue'}} />
    </SettingLayout>
  )
}

export default ProductsSettings
