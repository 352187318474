/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
  KooperForm,
  KooperFormField,
  KooperInput,
  KooperButton,
  KooperIcon,
  KooperCardGroup,
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
} from 'kooper-library'
import _ from 'lodash'

const ImportFile = props => {
  const {name, sampleImportAction, sampleData, sampleFileName, importData, currentStep, setCurrentStep, steps, format} =
    props

  const dispatch = useDispatch()

  const [uploadFile, setUploadFile] = useState(null)

  const [type, setType] = useState('')

  // useffect for loading all data
  useEffect(() => {
    dispatch(sampleImportAction())
  }, [])

  // function to Download data
  const downloadResponseCSV = (data, name) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', type === 'csv' ? `${name}.csv` : `${name}.xlsx`) // or any other extension
    document.body.appendChild(link)
    link.click()
  }

  return (
    <>
      <div className="backHeader">
        <KooperButton
          icon
          onClick={() => {
            setCurrentStep(steps[steps.indexOf(currentStep) - 1])
          }}
        >
          <KooperIcon name="left arrow" />
        </KooperButton>
      </div>
      {currentStep === 'step2' && (
        <KooperCardGroup>
          {format.map(el => (
            <KooperCard
              link
              className="hoverCard"
              onClick={() => {
                setCurrentStep('step3')
                setType(el)
              }}
            >
              <KooperCardContent>
                <KooperCardHeader>{_.upperCase(el)}</KooperCardHeader>
              </KooperCardContent>
            </KooperCard>
          ))}
        </KooperCardGroup>
      )}

      {currentStep === 'step3' && (
        <KooperForm >
          <KooperFormField>
            <label htmlFor="import">
              Import {name} {_.upperCase(type)}
            </label>
            <KooperInput
              type="file"
              onChange={e => {
                setUploadFile(e.target.files)
              }}
            />
            <small>Max upload file size is 2 MB</small>
            <KooperButton
              primary
              size="tiny"
              className="m-2"
              disabled={uploadFile === null}
              onClick={() => {
                const data = {
                  file: uploadFile,
                }
                dispatch(importData(data))
              }}
            >
              Upload File
            </KooperButton>
          </KooperFormField>
          <h5 className="mb-0">Download {_.upperCase(type)} example</h5>
          <p className="kooper-lead m-0">
            Download file :{' '}
            <KooperButton
              onClick={() => {
                dispatch(sampleImportAction())
                downloadResponseCSV(sampleData, sampleFileName)
              }}
            >
              Download sample {name}
            </KooperButton>
          </p>
        </KooperForm>
      )}
    </>
  )
}
export default ImportFile
