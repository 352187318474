import React, {useCallback} from 'react'
import {FaCalendar} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectsPlanningReportUtilization} from 'actions/analytics_projects'
import {
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperGrid,
  KooperGridColumn,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {GET_PROJECTS_PLANNING_REPORT_UTILIZATION} from 'actions/types'
import {startCase} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import DateRangeGroup from '../../DateRangeGroup'

const ResourceUtilization = () => {
  const dispatch = useDispatch()

  const {type = []} = useSelector(state => state.apiReducer)
  const {projectsPlanningReportUtilization = []} = useSelector(state => state.analyticsProjects)

  const actionsToDispatch = useCallback(
    data => {
      const {from, to} = data
      const payload = {from, to}
      dispatch(getProjectsPlanningReportUtilization(payload))
    },
    [dispatch]
  )

  const renderTableBody = () => {
    if (type.includes(GET_PROJECTS_PLANNING_REPORT_UTILIZATION)) {
      return <KooperTablePlaceholder columns={9} />
    }
    if (!type.includes(GET_PROJECTS_PLANNING_REPORT_UTILIZATION) && !projectsPlanningReportUtilization.length) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="9" className="emptyValue">
            No Data Available
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return projectsPlanningReportUtilization.map(u => (
      <KooperTableRow className="tableLink" key={u.id}>
        <KooperTableCell>{startCase(`${u.firstName} ${u.lastName}`)}</KooperTableCell>
        <KooperTableCell>
          <div className="project-resource-utilization-cell">
            <div className="progress" style={{width: `${u.estimatedUtilization ?? 0}}%`}} />
            <div className="content">
              <span>{u.estimatedUtilization ?? 0}%</span>
            </div>
          </div>
        </KooperTableCell>
        <KooperTableCell>
          <div className="project-resource-utilization-cell">
            <div className="progress" style={{width: `${u.actualUtilization ?? 0}%`}} />
            <div className="content">
              <span>{u.actualUtilization ?? 0}%</span>
            </div>
          </div>
        </KooperTableCell>
        <KooperTableCell>
          <div className="project-resource-utilization-cell">
            <div className="progress" style={{width: `${u.billableUtilization ?? 0}%`}} />
            <div className="content">
              <span>{u.billableUtilization ?? 0}%</span>
            </div>
          </div>
        </KooperTableCell>
        <KooperTableCell>
          <div className="project-resource-utilization-cell">
            <div className="progress" style={{width: `${u.nonbillableUtilization ?? 0}%`}} />
            <div className="content">
              <span>{u.nonbillableUtilization ?? 0}%</span>
            </div>
          </div>
        </KooperTableCell>
        <KooperTableCell>{u.availableTime}h</KooperTableCell>
        <KooperTableCell>{u.loggedTime}</KooperTableCell>
        <KooperTableCell>{u.billableTime}</KooperTableCell>
        <KooperTableCell>{u.nonBillableTime}</KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/resource-utilization" />
            <h5>Resource Utilization</h5>
          </div>
        </div>
        <div className="page-action">
          <DateRangeGroup actionsToDispatch={actionsToDispatch} />
        </div>
      </div>

      <KooperGrid>
        <KooperGridColumn>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Resource Utilization</KooperCardHeader>
              <KooperTooltip
                content="List of all agents based on resources utilized over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0">
              <KooperTable basic>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell>User</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Estimated Utilization</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Actual Utilization</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Billable Utilization</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Non-billable Utilization</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Available Time</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Logged Time</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Billable Time</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Non-Billable Time</KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>{renderTableBody()}</KooperTableBody>
              </KooperTable>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default ResourceUtilization
