import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {KooperModal, KooperModalHeader, KooperModalContent, KooperInput, KooperButton, KooperModalActions} from 'kooper-library'
import {updateSocial} from 'actions/multiple_fields'
import {startCase} from 'utils/helper'

const EditSocialModal = props => {
  const {isModalOpen, setIsModalOpen, selectedSocial, id, identifier} = props

  const dispatch = useDispatch()

  const [url, setUrl] = useState('')

  useEffect(() => {
    if (selectedSocial) {
      setUrl(selectedSocial.url)
    }
  }, [selectedSocial])

  return (
    <KooperModal size="mini" open={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)}>
      <KooperModalHeader>Edit {startCase(selectedSocial.category)}</KooperModalHeader>
      <KooperModalContent>
        <KooperInput
          iconPosition="left"
          icon={selectedSocial.category}
          // label={startCase(selectedSocial.category)}
          value={url}
          onChange={e => setUrl(e.target.value)}
        />
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic color="light" onClick={() => setIsModalOpen(false)}>
          Cancel
        </KooperButton>
        <KooperButton
          color="blue"
          disabled={(url || '').trim() === ''}
          onClick={() => {
            const data = {url, category: selectedSocial.category}

            dispatch(updateSocial(id, data, selectedSocial.id, identifier))
            setIsModalOpen(!isModalOpen)
          }}
        >
          Save
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default EditSocialModal
