import SvgIcon from 'components/common/SvgIcon'
import React, {useEffect} from 'react'
import {Controller} from 'react-hook-form'
import {removeDoubleQuotes} from 'utils/helper'
import {KooperFormField, KooperFormInput, KooperFormTextArea, KooperRadio, KooperTooltip} from 'kooper-library'

function Settings({control, title, description, setValue, errors}) {
  useEffect(() => {
    setValue('settings.title', title)
    setValue('settings.description', description)
  }, [title, description, setValue])

  return (
    <div className="settingsConfig">
      <KooperFormField required>
        <div className="info-header">
          <label>Title</label>
          <KooperTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Specify title of form"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="settings.title"
          render={({value, onChange}) => (
            <KooperFormInput
              type="text"
              maxLength={50}
              placeholder="Form Title..."
              value={value}
              onChange={e => {
                onChange(e.target.value)
              }}
              error={
                errors?.settings?.title && {
                  content: removeDoubleQuotes(errors?.settings?.title?.message),
                }
              }
            />
          )}
          control={control}
        />
      </KooperFormField>
      <KooperFormField>
        <div className="info-header">
          <label>Description</label>
          <KooperTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Specify a brief description about form"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="settings.description"
          render={({onChange, value}) => (
            <KooperFormTextArea
              type="text"
              // maxLength={250}
              value={value}
              placeholder="Description"
              onChange={e => {
                onChange(e.target.value)
              }}
            />
          )}
          control={control}
        />
      </KooperFormField>
      <KooperFormField>
        <div className="info-header">
          <label>Redirect URL</label>
          <KooperTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Add a redirect URl in the form"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="settings.redirectUrl"
          render={({value, onChange}) => (
            <KooperFormInput
              type="text"
              placeholder="https://"
              value={value}
              onChange={(e, {value}) => {
                onChange(value)
              }}
              error={
                errors?.settings?.redirectUrl && {
                  content: removeDoubleQuotes(errors?.settings?.redirectUrl?.message),
                }
              }
            />
          )}
          control={control}
        />
      </KooperFormField>
      <KooperFormField>
        <div className="info-header">
          <label>Confirmation Message</label>
          <KooperTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Confirmation message will be sent after successful form submission"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="settings.confirmMessage"
          render={({onChange, value}) => (
            <KooperFormTextArea
              maxLength={200}
              type="text"
              value={value}
              onChange={e => {
                onChange(e.target.value)
              }}
            />
          )}
          control={control}
        />
      </KooperFormField>
      <KooperFormField width={8}>
        <div className="d-flex">
          <div className="info-header">
            <label className="m-0">Show Question Number</label>
          </div>
          <Controller
            name="settings.showQuestionNumber"
            control={control}
            render={({value, onChange, onBlur}) => (
              <KooperTooltip
                content={value ? 'Enabled' : 'Disabled'}
                size="mini"
                position="top center"
                trigger={
                  <KooperRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} onBlur={onBlur} />
                }
              />
            )}
          />
        </div>
      </KooperFormField>

      <KooperFormField width={8}>
        <div className="d-flex">
          <div className="info-header">
            <label className="m-0">Indicate Mandatory</label>
          </div>
          <Controller
            name="settings.indicateMandatory"
            control={control}
            render={({value, onChange}) => (
              <KooperTooltip
                content={value ? 'Enabled' : 'Disabled'}
                size="mini"
                position="top center"
                trigger={<KooperRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />}
              />
            )}
          />
        </div>
      </KooperFormField>
      <KooperFormField width={8}>
        <div className="d-flex">
          <div className="info-header">
            <label className="m-0">Show Branding</label>
          </div>
          <Controller
            name="settings.showBranding"
            control={control}
            render={({value, onChange}) => (
              <KooperTooltip
                content={value ? 'Enabled' : 'Disabled'}
                size="mini"
                position="top center"
                trigger={<KooperRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />}
              />
            )}
          />
        </div>
      </KooperFormField>
      <KooperFormField width={8}>
        <div className="d-flex ">
          <div className="info-header">
            <label className="m-0">Show Progress Bar</label>
          </div>
          <Controller
            name="settings.showProgressBar"
            control={control}
            render={({value, onChange}) => (
              <KooperTooltip
                content={value ? 'Enabled' : 'Disabled'}
                size="mini"
                position="top center"
                trigger={<KooperRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />}
              />
            )}
          />
        </div>
      </KooperFormField>
    </div>
  )
}

export default Settings
