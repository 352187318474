import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperForm,
  KooperFormDropdown,
  KooperFormField,
  KooperFormInput,
  KooperTooltip,
} from 'kooper-library'

import {ADD_SURVEY, DELETE_SURVEY, EDIT_SURVEY, GET_CHAT_SURVEY} from 'actions/types'
import {getAllMailInbox} from 'actions/inbox'
import {addSurvey, deleteSurvey, editSurvey, getChatSurvey} from 'actions/settings_customer_satisfaction'
import DeleteModal from 'components/common/DeleteModal'
import SettingLayout from 'layouts/settingLayout'
import {removeDoubleQuotes} from 'utils/helper'
import {chatSurveySchema} from 'validation/Settings/customerSatisfaction/chatSurvey.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {FaClipboardList} from 'react-icons/fa'
import SvgIcon from 'components/common/SvgIcon'
import {INBOX_TYPES} from 'constants/enums'
import {SETTINGS_PATH} from 'constants/path'

const ChatSurveyForm = ({toggleCreate, setWarningModal}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id} = useParams()

  const [listMailBox, setListMailBox] = useState([])
  const [idToDelete, setIdToDelete] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {inbox: mailBoxList} = useSelector(state => state.mailInbox)
  const {chatSurveySingle} = useSelector(state => state.settingsCustomerSatisfaction)
  const {productivityPermissions} = useUserPermissions()
  const customerSatficationsPermissions = productivityPermissions?.customer_satfication

  const defaultValue = {
    subject: '',
    inboxIds: [],
    ratingsType: 'star',
    commentEnabled: false,
    allowMultipleFeedback: false,
  }

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(chatSurveySchema),
    defaultValues: defaultValue,
  })

  const handleReset = () => {
    if (id) {
      reset(_.pick(chatSurveySingle, ['inboxIds', 'subject', 'ratingsType', 'commentEnabled', 'allowMultipleFeedback']))
    } else {
      reset(defaultValue)
    }
  }

  useEffect(() => {
    if (!mailBoxList) dispatch(getAllMailInbox())
    if (id) dispatch(getChatSurvey())
  }, [dispatch, id])

  useEffect(() => {
    if (mailBoxList) {
      const list = mailBoxList
        .filter(data => data.type === INBOX_TYPES.WEB)
        .map(({id, name = '', email = ''}) => ({
          key: id,
          value: id,
          content: (
            <div>
              {name}
              <small className="ml-2 kooper-lead">{email}</small>
            </div>
          ),
          text: name,
        }))
      setListMailBox(list)
    }
  }, [mailBoxList])

  useEffect(() => {
    if (id && isDirty) {
      setWarningModal(isDirty)
    }
    return () => {
      if (id && isDirty) setWarningModal(false)
    }
  }, [isDirty, setWarningModal])

  useEffect(() => {
    if (successLabels.includes(GET_CHAT_SURVEY)) {
      handleReset()
    }
    if (successLabels.includes(EDIT_SURVEY)) {
      dispatch(getChatSurvey())
    }
    if (successLabels.includes(DELETE_SURVEY)) {
      dispatch({type: 'CLEAR_CHAT_SURVEY'})
      history.push(`${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.CHAT_SURVEY}`)
    }
  }, [successLabels])

  const surveySubmit = data => {
    const payload = {
      ...data,
      ...(!id && {type: 'web'}),
    }
    if (id) {
      dispatch(editSurvey(id, payload))
      setWarningModal(false)
    } else {
      dispatch(addSurvey(payload))
    }
  }

  return (
    <SettingLayout
      icon={<FaClipboardList />}
      header={id ? 'Edit Chat Survey' : 'Create Chat Survey'}
      headerbackbtn={
        !id && {
          onClick: () => toggleCreate(false),
        }
      }
      subHeader="Create and share an engaging survey through chat messenger to collect customer feedback"
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: id ? 'Reset' : 'Cancel',
          disabled: isLoadingData && (type.includes(EDIT_SURVEY) || type.includes(ADD_SURVEY)),
          onClick: () => {
            if (id) {
              handleReset()
            } else {
              toggleCreate(false)
            }
          },
        },
        success: {
          content: id ? 'Update' : 'Create',
          loading: isLoadingData && (type.includes(EDIT_SURVEY) || type.includes(ADD_SURVEY)),
          ...(customerSatficationsPermissions && {
            onClick: handleSubmit(data => surveySubmit(data)),
          }),
          disabled: !isDirty || (isLoadingData && (type.includes(EDIT_SURVEY) || type.includes(ADD_SURVEY))),
        },
      }}
      lockRole={!customerSatficationsPermissions}
    >
      <KooperForm className="errorLabel">
        <KooperFormField required width={10}>
          <div className="info-header">
            <label className="label-class">Headline</label>
            <KooperTooltip
              content="Specify headline of your survey"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="subject"
            control={control}
            render={({value, onChange}) => (
              <KooperFormInput
                value={value}
                placeholder="Enter Headline"
                onChange={(e, {value}) => onChange(value)}
                error={
                  errors.subject && {
                    content: removeDoubleQuotes(errors.subject.message),
                  }
                }
              />
            )}
          />
        </KooperFormField>
        <KooperFormField required width={10}>
          <div className="info-header">
            <label className="d-block label-class">Select Inbox</label>
            <KooperTooltip
              content="Select inbox in which you want to receive survey response"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="inboxIds"
            control={control}
            render={({value, onChange}) => (
              <KooperFormDropdown
                fluid
                multiple
                selection
                placeholder="Select Inbox"
                value={value}
                options={listMailBox}
                onChange={(e, {value}) => onChange(value)}
                error={
                  errors.inboxIds && {
                    content: removeDoubleQuotes(errors.inboxIds.message),
                  }
                }
              />
            )}
          />
        </KooperFormField>
        <KooperFormField width={6}>
          <div className="info-header">
            <label>Rating Type</label>
            <KooperTooltip
              content="Choose the rating type for your survey"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="ratingsType"
            control={control}
            render={({value, onChange}) => (
              <KooperFormDropdown
                selection
                value={value}
                options={[
                  {
                    value: 'star',
                    text: 'Star Ratings',
                    key: 'star',
                  },
                  {
                    value: 'emoji',
                    text: 'Emoji Ratings',
                    key: 'emoji',
                  },
                ]}
                onChange={(e, {value}) => onChange(value)}
              />
            )}
          />
        </KooperFormField>

        <KooperCard fluid className="switchBox switchBoxSmall">
          <KooperCardContent>
            <div className="d-flex">
              <div>
                <h5>Additional Comment</h5>
                <p>Include additional comment textbox</p>
              </div>
              <Controller
                name="commentEnabled"
                control={control}
                render={({value, onChange}) => {
                  return <KooperCheckbox toggle checked={value} onChange={() => onChange(!value)} />
                }}
              />
            </div>
          </KooperCardContent>
          <KooperCardContent>
            <div className="d-flex">
              <div>
                <h5>Allow Multiple Feedback</h5>
                <p>Allow multiple feedback from the customer</p>
              </div>
              <Controller
                name="allowMultipleFeedback"
                control={control}
                render={({value, onChange}) => {
                  return <KooperCheckbox toggle checked={value} onChange={() => onChange(!value)} />
                }}
              />
            </div>
          </KooperCardContent>
        </KooperCard>

        {id && (
          <>
            <h4 className="mt-5 mb-0">Want to delete Chat Survey?</h4>
            <p className="kooper-lead">Lorem Ipsum has been the industry standard dummy text</p>
            <KooperButton
              className="basic-red"
              onClick={() => {
                setIdToDelete(id)
                setDeleteModal(true)
              }}
            >
              Delete Chat Survey
            </KooperButton>
          </>
        )}
      </KooperForm>

      {deleteModal && (
        <DeleteModal
          idTobeDeleted={idToDelete}
          deleteAction={deleteSurvey}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          type={DELETE_SURVEY}
        />
      )}
    </SettingLayout>
  )
}

export default ChatSurveyForm
