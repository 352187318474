/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  KooperModalHeader,
  KooperModal,
  KooperModalContent,
  KooperButton,
  KooperModalActions,
  KooperRadio,
  KooperForm,
  KooperFormField,
} from 'kooper-library'
import {get} from 'utils/helper'
import {multipleMergeContact} from 'actions/contacts'

const MergeContactModal = props => {
  const {isModalOpen, setIsModalOpen, mergeId} = props
  const dispatch = useDispatch()
  const {getAllContactsList = []} = useSelector(state => state.contacts)
  const [mergeContactData, setMergeContactData] = useState([])
  const [mergeName, setMergeName] = useState([])
  const [mergeCompany, setMergeCompany] = useState([])
  const [mergeTitle, setMergeTitle] = useState([])
  const [mergeDescription, setMergeDescription] = useState([])
  const [mergeAddress, setMergeAddress] = useState([])
  const [meregAssigneeId, setMeregAssigneeId] = useState([])
  const [meregContactType, setMeregContactType] = useState([])
  const [meregStatusId, setMeregStatusId] = useState([])
  const [meregisFollowed, setMeregisFollowed] = useState([])
  const [radiobtn, setRadiobtn] = useState({})

  useEffect(() => {
    let temp = []

    getAllContactsList.forEach(list => {
      mergeId.forEach(id => {
        if (id === list.id) {
          const {
            id,
            firstName,
            lastName,
            company,
            companyId,
            title,
            description,
            address,
            assigneeId,
            status,
            isFollowed,
            owner,
            statusId,
            contactType,
            contactTypeId,
          } = list
          temp = [
            ...temp,
            {
              id,
              firstName,
              lastName,
              company,
              companyId,
              title,
              address,
              description,
              assigneeId,
              status,
              isFollowed,
              owner,
              statusId,
              contactType,
              contactTypeId,
            },
          ]
        }
      })
    })
    setMergeContactData(temp)
  }, [mergeId])

  useEffect(() => {
    let tempRadioBtn
    // firstname & lastName
    const tempName = mergeContactData.map(desgin => {
      return {name: `${desgin.firstName} ${desgin.lastName}`.trim() || `#${desgin.id}`, id: desgin.id}
    })
    const uniqName = _.uniqBy(tempName, 'name')
    setMergeName(uniqName)
    tempRadioBtn = {
      ...tempRadioBtn,
      name: get([[0], 'id'], uniqName, null),
    }

    // company
    const companyNullRemove = mergeContactData.filter(function (el) {
      return !!el.company
    })
    const uniqCompany = _.uniqBy(companyNullRemove, "company['id']")
    setMergeCompany(uniqCompany)
    tempRadioBtn = {
      ...tempRadioBtn,
      companyId: get([[0], 'company', 'id'], uniqCompany, null),
    }

    // title
    const titleNullRemove = mergeContactData.filter(function (el) {
      return !!el.title
    })
    const uniqTitle = _.uniqBy(titleNullRemove, 'title')
    setMergeTitle(uniqTitle)
    tempRadioBtn = {
      ...tempRadioBtn,
      title: get([[0], 'id'], uniqTitle, null),
    }

    // description
    const descriptionNullRemove = mergeContactData.filter(function (el) {
      return !!el.description
    })
    const uniqDescrption = _.uniqBy(descriptionNullRemove, 'description')
    setMergeDescription(uniqDescrption)
    tempRadioBtn = {
      ...tempRadioBtn,
      description: get([[0], 'id'], uniqDescrption, null),
    }

    // Address
    const addressNullRemove = mergeContactData.filter(function (el) {
      return !!el.address
    })
    const tempAddress = addressNullRemove.map(desgin => {
      return {
        address: `${get(['address', 'streetAddress'], desgin) || ''} ${get(['address', 'locality'], desgin) || ''} ${
          get(['address', 'state'], desgin) || ''
        }
         ${get(['address', 'country'], desgin) || ''} ${get(['address', 'postalCode'], desgin) || ''}`,
        id: desgin.id,
      }
    })
    const uniqAddress = _.uniqBy(tempAddress, 'address')
    setMergeAddress(uniqAddress)
    tempRadioBtn = {
      ...tempRadioBtn,
      address: get([[0], 'id'], uniqAddress, null),
    }

    // contactType
    const contactTypeNullRemove = mergeContactData.filter(function (el) {
      return !!el.contactType
    })
    const uniqContactType = _.uniqBy(contactTypeNullRemove, "contactType['id']")
    setMeregContactType(uniqContactType)
    tempRadioBtn = {
      ...tempRadioBtn,
      contactTypeId: get([[0], 'contactTypeId'], uniqContactType, null),
    }

    // AssigneeId
    const assigneeIdNullRemove = mergeContactData.filter(function (el) {
      return !!el.assigneeId
    })
    const uniqAssigneeId = _.uniqBy(assigneeIdNullRemove, "owner['id']")
    setMeregAssigneeId(uniqAssigneeId)
    tempRadioBtn = {
      ...tempRadioBtn,
      assigneeId: get([[0], 'assigneeId'], uniqAssigneeId, null),
    }

    // Status
    const statusIdNullRemove = mergeContactData.filter(function (el) {
      return !!el.status
    })
    const uniqStatusId = _.uniqBy(statusIdNullRemove, "status['id']")
    setMeregStatusId(uniqStatusId)
    tempRadioBtn = {
      ...tempRadioBtn,
      statusId: get([[0], 'statusId'], uniqStatusId, null),
    }

    // Followed
    const uniqisFollowed = _.uniqBy(mergeContactData, 'isFollowed')
    setMeregisFollowed(uniqisFollowed)
    tempRadioBtn = {
      ...tempRadioBtn,
      isFollowed: get([[0], 'isFollowed'], uniqisFollowed, null),
    }

    setRadiobtn(tempRadioBtn)
  }, [mergeContactData])

  const multipleMergeBtn = () => {
    const getData = _.omitBy(radiobtn, _.isNull)
    const data = {
      ids: mergeId,
      data: getData,
    }
    dispatch(multipleMergeContact(data))
  }

  return (
    <KooperModal className="kooper-modal" open={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)}>
      <KooperModalHeader>Merge Contacts</KooperModalHeader>
      <KooperModalContent scrolling>
        <h4 className="pb-3">Merging Contacts ({mergeId.length})</h4>
        <KooperForm>
          <KooperFormField>
            <label>Name</label>
            {mergeName.length > 0 ? (
              mergeName.map((item, i) => (
                <KooperRadio
                  className="mb-2 mr-2"
                  label={item.name}
                  name="radioName"
                  key={i}
                  value={item.id}
                  checked={item.id === radiobtn.name}
                  onChange={() => setRadiobtn({...radiobtn, name: item.id})}
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
          <KooperFormField>
            <label>company</label>
            {mergeCompany.length > 0 ? (
              mergeCompany.map((item, i) => (
                <KooperRadio
                  className="mb-2 mr-2"
                  key={i}
                  label={get(['company', 'name'], item)}
                  name="radioCompany"
                  value={get(['company', 'id'], item)}
                  checked={get(['company', 'id'], item) === radiobtn.companyId}
                  onChange={() =>
                    setRadiobtn({
                      ...radiobtn,
                      companyId: get(['company', 'id'], item),
                    })
                  }
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
          <KooperFormField>
            <label>Title</label>
            {mergeTitle.length > 0 ? (
              mergeTitle.map((item, i) => (
                <KooperRadio
                  className="mb-2 mr-2"
                  key={i}
                  label={get(['title'], item)}
                  name="radioTitle"
                  value={item.id}
                  checked={item.id === radiobtn.title}
                  onChange={() => setRadiobtn({...radiobtn, title: item.id})}
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
          <KooperFormField>
            <label>Description</label>
            {mergeDescription.length > 0 ? (
              mergeDescription.map((item, i) => (
                <KooperRadio
                  className="mb-2 mr-2"
                  key={i}
                  label={get(['description'], item)}
                  name="radioDescription"
                  value={item.id}
                  checked={item.id === radiobtn.description}
                  onChange={() => setRadiobtn({...radiobtn, description: item.id})}
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
          <KooperFormField>
            <label>Address</label>
            {mergeAddress.length > 0 ? (
              mergeAddress.map((item, i) => (
                <KooperRadio
                  className="mb-2 mr-2"
                  key={i}
                  label={get(['address'], item)}
                  name="radioAddress"
                  value={item.id}
                  checked={item.id === radiobtn.address}
                  onChange={() => setRadiobtn({...radiobtn, address: item.id})}
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
          <KooperFormField>
            <label>Contact Type</label>
            {meregContactType.length > 0 ? (
              meregContactType.map((item, i) => (
                <KooperRadio
                  className="mb-2 mr-2"
                  key={i}
                  label={get(['contactType', 'type'], item)}
                  name="radioContactType"
                  value={get(['contactType', 'id'], item)}
                  checked={get(['contactTypeId'], item) === radiobtn.contactTypeId}
                  onChange={() =>
                    setRadiobtn({
                      ...radiobtn,
                      contactTypeId: get(['contactType', 'id'], item),
                    })
                  }
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
          <KooperFormField>
            <label>Assignee</label>
            {meregAssigneeId.length > 0 ? (
              meregAssigneeId.map((item, i) => (
                <KooperRadio
                  className="mb-2 mr-2"
                  key={i}
                  label={`${get(['owner', 'firstName'], item, ' ')} ${get(['owner', 'lastName'], item, ' ')}`}
                  name="radioOwner"
                  value={get(['assigneeId'], item)}
                  checked={get(['assigneeId'], item) === radiobtn.assigneeId}
                  onChange={() =>
                    setRadiobtn({
                      ...radiobtn,
                      assigneeId: get(['assigneeId'], item),
                    })
                  }
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
          <KooperFormField>
            <label>Status</label>
            {meregStatusId.length > 0 ? (
              meregStatusId.map((item, i) => (
                <KooperRadio
                  className="mb-2 mr-2"
                  key={i}
                  label={get(['status', 'status'], item)}
                  name="radioStatus"
                  value={get(['status', 'id'], item)}
                  checked={get(['statusId'], item) === radiobtn.statusId}
                  onChange={() =>
                    setRadiobtn({
                      ...radiobtn,
                      statusId: get(['status', 'id'], item),
                    })
                  }
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
          <KooperFormField>
            <label>Followed</label>
            {meregisFollowed.length > 0 ? (
              meregisFollowed.map((item, i) => (
                <KooperRadio
                  className="mb-2 mr-2"
                  key={i}
                  label={get(['isFollowed'], item)}
                  name="radioFollowed"
                  value={get(['isFollowed'], item)}
                  checked={get(['isFollowed'], item) === radiobtn.isFollowed}
                  onChange={() =>
                    setRadiobtn({
                      ...radiobtn,
                      isFollowed: get(['isFollowed'], item),
                    })
                  }
                />
              ))
            ) : (
              <p className="kooper-lead mb-0">No Data Available</p>
            )}
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={() => multipleMergeBtn()}>
          Save
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default MergeContactModal
