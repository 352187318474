import React from 'react'
import {useDispatch} from 'react-redux'
import moment from 'moment'
import {KooperButton, KooperDatePicker, KooperModal, KooperModalActions, KooperModalContent, KooperModalHeader} from 'kooper-library'
import {snoozeConversation} from 'actions/inbox'
import SvgIcon from 'components/common/SvgIcon'

const SnoozeConversationModal = ({
  timePicker,
  setTimePicker,
  snoozeDatePickerTime,
  setSnoozeDatePickerTime,
  ConversationId,
}) => {
  const dispatch = useDispatch()

  const date = moment(new Date())
  const roundUp =
    date.minute() || date.second() || date.millisecond() ? date.add(1, 'hour').startOf('hour') : date.startOf('hour')

  const snoozeMail = time => {
    const data = {
      ts: time,
      isSnoozed: true,
    }
    dispatch(snoozeConversation(data, ConversationId))
  }

  return (
    <KooperModal
      centered={false}
      size="mini"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setTimePicker(false)}
      open={timePicker}
    >
      <KooperModalHeader >Snooze Date and Time</KooperModalHeader>
      <KooperModalContent>
        <KooperDatePicker
          defaultValue={new Date()}
          min={roundUp.toDate()}
          inputProps={{
            component: props => <input {...props} readOnly />,
          }}
          onChange={value => {
            setSnoozeDatePickerTime(value)
          }}
        />
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setTimePicker(!timePicker)}>
          Cancel
        </KooperButton>
        <KooperButton
          color="primary"
          onClick={() => {
            snoozeMail(snoozeDatePickerTime)
            setTimePicker(false)
          }}
        >
          Snooze
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default SnoozeConversationModal
