import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {KooperButton, KooperIcon, KooperImage, KooperTooltip} from 'kooper-library'
import {deletePaymentMethods, updatePaymentMethods} from 'actions/billing'
import visa from 'assets/visa.png'
import SvgIcon from 'components/common/SvgIcon'

const SavedCardList = () => {
  const dispatch = useDispatch()

  const {getPaymentMethodsData = []} = useSelector(state => state.billing)

  return (
    <div className="saved-cards">
      {getPaymentMethodsData.map(card => (
        <div className={`card-info ${card.isDefault ? 'active' : ''}`}>
          <KooperIcon name="check square" />
          <p>
            <KooperImage src={visa} /> {card.cardHolderName} {card.id}
          </p>
          <span>xxxx xxxx xxxx {card.last4}</span>
          {!card.isDefault && (
            <>
              <KooperButton
                primary
                className="basic-fill-btn"
                style={{width: 'fit-content', whiteSpace: 'nowrap'}}
                onClick={() => dispatch(updatePaymentMethods(card.id))}
              >
                Make Default
              </KooperButton>
              <KooperTooltip
                content="Delete"
                size="mini"
                position="top center"
                trigger={
                  <KooperButton basic onClick={() => dispatch(deletePaymentMethods(card.id))}>
                    <SvgIcon path="common/delete" />
                  </KooperButton>
                }
              />
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export default SavedCardList
