import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {Controller} from 'react-hook-form'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

function SubHeader({setToggleModal, toggleModal, activeSection, index, control, data}) {
  return (
    <KooperModal
      open={toggleModal === 'sub_header'}
      onClose={() => setToggleModal(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      centered={false}
    >
      <KooperModalHeader >Edit Sub Header</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Sub Header</label>
            </div>
            <Controller
              name={`templateSections[${activeSection}].sectionFields[${index}].label`}
              control={control}
              defaultValue={data?.label}
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  placeholder="Enter Text"
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  onBlur={onBlur}
                  //   error={
                  //     _.get(errors, `fields[${index}].label`) && {
                  //       content: removeDoubleQuotes(_.get(errors, `fields[${index}].label.message`)),
                  //     }
                  //   }
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton onClick={() => setToggleModal(false)}>Cancel</KooperButton>
        <KooperButton primary onClick={() => setToggleModal(false)}>
          Ok
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default SubHeader
