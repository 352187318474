/* eslint-disable no-restricted-syntax */
import React, {useEffect, useState} from 'react'
import {KooperButton, KooperTab} from 'kooper-library'
import {useHistory, useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {useForm} from 'react-hook-form'
import {getInvoicesData, updateInvoices} from 'actions/invoices'
import {get} from 'utils/helper'
import {GET_ALL_ADDRESSESS, GET_INVOICES_DATA, UPDATE_INVOICES} from 'actions/types'
import {getAllAddressess} from 'actions/settings_organisation'
import {invoicesEditSchema} from 'validation/Finance/invoices.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useApiResponse from 'hooks/impure/useApiResponse'
import {INVOICES_PATH} from 'constants/path'
import Setup from './InvoicesForm/Setup'
import Product from './InvoicesForm/Product'
import Customization from './InvoicesForm/Customization'
import PreviewInvoices from './Templates/PreviewInvoices'
import InvoiceModal from './InvoicesForm/InvoiceModal'

const invoicesEditDefaultValues = {
  assigneeId: null,
  invoiceNumber: '',
  prefixId: '',
  subject: '',
  purchaseOrderNumber: '',
  salesOrderNumber: '',
  contactId: null,
  invoiceDate: null,
  exciseDuty: '',
  salesCommission: '',
  status: '',
  // dealId: '',
  description: '',
  billingAddress: {},
  shippingAddress: {},
  products: [],
  subTotal: 0,
  discount: 0,
  tax: 0,
  adjustment: 0,
  total: 0,
  terms: '',
  template: {templateNo: 2, color: '#1e90ff', header: '', footer: '', addressId: ''},
}

const steps = [{name: 'Setup'}, {name: 'Product'}, {name: 'Customization'}, {name: 'Preview'}]

function InvoicesEdit() {
  const history = useHistory()
  const {goBack} = useHistory()
  const dispatch = useDispatch()
  const {id: editId} = useParams()

  const [index, setIndex] = useState(0)
  const [InvoiceDate, setInvoiceDate] = useState(null)
  const [discountValue, setDiscountValue] = useState(0)
  const [taxValue, setTaxValue] = useState(0)
  const [adjustmentValue, setAdjustmentValue] = useState(0)
  const [searchValue, setSearchValue] = useState([])
  const [addressTemplate, setAddressTemplate] = useState()
  const [showInvoiceModal, setShowInvoiceModal] = useState(false)

  const {invoicePublicLink, invoicesData = {}, productsData = []} = useSelector(state => state.invoices)
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {financePermissions} = useUserPermissions()
  const manageInvoicesPermissions = financePermissions?.manage_invoices

  const {handleSubmit, errors, clearErrors, control, watch, setValue, reset} = useForm({
    mode: 'onTouched',
    shouldUnregister: false,
    shouldFocusError: true,
    reValidateMode: 'onChange',
    defaultValues: invoicesEditDefaultValues,
    resolver: joiResolver(invoicesEditSchema),
  })

  const {data: allAddressess = []} = useApiResponse({
    action: getAllAddressess,
    enabled: true,
    label: GET_ALL_ADDRESSESS,
    storePath: 'settingsOrganisation.allAddressess',
  })

  useEffect(() => {
    if (editId) dispatch(getInvoicesData(editId))

    return () => {
      dispatch({type: 'CLEAR_INVOICE_FORM'})
      dispatch({type: 'CLEAR_INVOICE_DATA'})
      dispatch({type: 'CLEAR_INVOICE_UPLOADFILE'})
    }
  }, [editId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_INVOICES_DATA)) {
      const {
        invoiceNumber,
        subject,
        assigneeId,
        contactId,
        invoiceDate,
        exciseDuty,
        salesCommission,
        status,
        billingAddress,
        shippingAddress,
        description,
        products,
        subTotal,
        total,
        terms,
        template,
      } = invoicesData
      if (template) {
        delete template?.id
        delete template?.accountId
        delete template?.soId
        delete template?.logo
        delete template?.paymentProcess
        delete template?.invoiceId
      }
      reset({
        invoiceNumber,
        prefixId: get(['prefixId'], invoicesData, ''),
        subject,
        assigneeId,
        invoiceDate,
        purchaseOrderNumber: get(['purchaseOrderNumber'], invoicesData, ''),
        salesOrderNumber: get(['salesOrderNumber'], invoicesData, ''),
        contactId,
        exciseDuty,
        salesCommission,
        status,
        billingAddress,
        shippingAddress,
        description,
        products,
        subTotal,
        total,
        terms,
        template,
        discount: get(['discount'], invoicesData, null),
        tax: get(['tax'], invoicesData, null),
        adjustment: get(['adjustment'], invoicesData, null),
      })
      setSearchValue(
        productsData?.map(
          ({productId, productName: title, unitAmount, quantity, unitPrice, taxValue, discountValue, unitTotal}) => {
            return {productId, title, unitAmount, quantity, unitPrice, taxValue, discountValue, unitTotal}
          }
        )
      )
      setAddressTemplate(
        allAddressess?.filter(({id}) => {
          return id === template?.addressId
        })
      )
      setDiscountValue(invoicesData.discount)
      setTaxValue(invoicesData.tax)
      setInvoiceDate(invoicesData.invoiceDate)
      setAdjustmentValue(invoicesData.adjustment)
    }
  }, [successLabels, invoicesData, productsData, reset])

  useEffect(() => {
    if (invoicesData?.template?.logo) {
      // const [, setPreview] = preview;
      // setPreview(previewLogo);
      dispatch({type: 'SET_INVOICE_PREVIEW_FILE', payload: invoicesData?.template?.logo})
    }
  }, [invoicesData])

  useEffect(() => {
    if (successLabels.includes(UPDATE_INVOICES)) {
      // history.push(`/${PURCHASE_ORDERS_PATH.PREFIX}`)
      setShowInvoiceModal(true)
      dispatch({type: 'CLEAR_INVOICE_FORM'})
      dispatch({type: 'CLEAR_INVOICE_DATA'})
      dispatch({type: 'CLEAR_INVOICE_UPLOADFILE'})
    }
  }, [successLabels, history])

  useEffect(() => {
    if (invoicesData.id) {
      if (invoicesData?.invoiceNumber) {
        dispatch({
          type: 'SETUP_SUBMIT',
          payload: {
            invoiceNumber: invoicesData?.invoiceNumber,
            invoiceDate: invoicesData?.invoiceDate,
            status: invoicesData?.status,
            billingAddress: invoicesData?.billingAddress,
            shippingAddress: invoicesData?.shippingAddress,
            description: invoicesData?.description,
            salesCommission: invoicesData?.salesCommission,
            exciseDuty: invoicesData?.exciseDuty,
            salesOrderNumber: invoicesData?.salesOrderNumber,
          },
        })
      }

      if (productsData) {
        dispatch({
          type: 'PRODUCT_HANDLE_SUBMIT',
          payload: {
            products: productsData,
            total: invoicesData?.total,
            subTotal: invoicesData?.subTotal,
            discount: invoicesData?.discount,
            tax: invoicesData?.tax,
            adjustment: invoicesData?.adjustment,
            terms: invoicesData?.terms,
          },
        })
      }

      if (invoicesData?.template) {
        dispatch({
          type: 'CUSTOMIZATION_SUBMIT',
          payload: {
            template: invoicesData?.template,
          },
        })
      }
    }
  }, [invoicesData, productsData, dispatch])

  const submitForm = data => {
    const formData = new FormData()

    const payload = {...data}

    const newList = _.omitBy(payload, value => !!(value === '' || value === null || _.isEqual(value, [])))

    for (const key in newList) {
      if (key === 'products') {
        formData.append(key, JSON.stringify(newList[key]))
      } else if (key === 'shippingAddress') {
        formData.append(key, JSON.stringify(newList[key]))
      } else if (key === 'billingAddress') {
        formData.append(key, JSON.stringify(newList[key]))
      } else if (key === 'template') {
        formData.append(key, JSON.stringify(newList[key]))
      } else {
        formData.append(key, newList[key])
      }
    }
    dispatch(updateInvoices(editId, formData))
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar tabCustomize">
        <div>
          <KooperButton primary onClick={() => history.push(`/${INVOICES_PATH.PREFIX}`)}>
            Exit
          </KooperButton>
          {index > 0 && (
            <KooperButton className="ml-2" primary onClick={() => setIndex(index - 1)}>
              Back
            </KooperButton>
          )}
        </div>
        <KooperTab
          panes={steps.map(item => ({
            menuItem: {
              key: item.name,
              icon: item.icon,
              content: item.name,
            },
            render: null,
          }))}
          menu={{
            secondary: true,
            pointing: true,
            color: 'blue',
          }}
          activeIndex={index}
          onTabChange={(e, {activeIndex}) => {
            setIndex(activeIndex)
          }}
          renderActiveOnly={false}
        />
        <LockPermissionTooltip isRoleAccessDenied={!manageInvoicesPermissions}>
          <KooperButton
            primary
            className={!manageInvoicesPermissions ? 'disabled-button' : ''}
            {...(manageInvoicesPermissions && {
              onClick: handleSubmit(submitForm),
            })}
          >
            Save
          </KooperButton>
        </LockPermissionTooltip>
      </div>
      <Setup
        style={{display: index === 0 ? '' : 'none'}}
        control={control}
        errors={errors}
        clearErrors={clearErrors}
        watch={watch}
        setValue={setValue}
        InvoiceDate={InvoiceDate}
        setInvoiceDate={setInvoiceDate}
      />

      <Product
        style={{display: index === 1 ? '' : 'none'}}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        discountValue={discountValue}
        setDiscountValue={setDiscountValue}
        taxValue={taxValue}
        setTaxValue={setTaxValue}
        adjustmentValue={adjustmentValue}
        setAdjustmentValue={setAdjustmentValue}
      />

      <Customization
        style={{display: index === 2 ? '' : 'none'}}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        addressTemplate={addressTemplate}
        setAddressTemplate={setAddressTemplate}
        editId={editId}
      />

      <PreviewInvoices style={{display: index === 3 ? '' : 'none'}} />

      {showInvoiceModal && (
        <InvoiceModal
          link={invoicePublicLink}
          open={showInvoiceModal}
          toggle={() => {
            setShowInvoiceModal(false)
            goBack()
          }}
        />
      )}
    </div>
  )
}

export default InvoicesEdit
