import React from 'react'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'
import {createPipeline} from 'actions/deals'
import SvgIcon from 'components/common/SvgIcon'

const CreateDealModal = ({createDeal, setCreateDeal}) => {
  const dispatch = useDispatch()

  const {errors, control, handleSubmit} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        pipeline: Joi.string().trim().min(4).max(30).required().messages({
          'string.empty': `Pipeline cannot be an empty field`,
          'string.min': `Pipeline name should have a minimum length of 4 characters`,
          'string.max': `Pipeline name must be less or equal to 30 characters`,
        }),
      })
    ),
    defaultValues: {pipeline: ''},
  })

  return (
    <KooperModal
      size="tiny"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={createDeal}
      onClose={setCreateDeal}
      centered={false}
    >
      <KooperModalHeader>Create Deal Pipeline</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <label>Pipeline Name</label>
            <Controller
              name="pipeline"
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  maxLength={20}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  onBlur={onBlur}
                  error={
                    errors.pipeline && {
                      content: errors.pipeline.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setCreateDeal(!createDeal)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(data => dispatch(createPipeline(data)))}>
          Create
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default CreateDealModal
