import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {BsExclamationTriangle} from 'react-icons/bs'
import {
  KooperButton,
  KooperHeader,
  KooperLabel,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {
  ADD_INBOX,
  DELETE_INBOX,
  GET_ALL_MAIL_INBOX,
  GET_TEAMS_LIST,
  GET_USERS,
  SEND_VERIFICATION_MAIL_INBOX,
} from 'actions/types'
import {deleteInbox, getAllMailInbox} from 'actions/inbox'
import create from 'assets/create-inbox.svg'
import SettingLayout from 'layouts/settingLayout'
import CreateFirst from 'components/common/CreateFirst'
import {get, getFormattedDate, getPermissionTooltipContent, startCase, wrapText} from 'utils/helper'
import DeleteModal from 'components/common/DeleteModal'
import {getAllTeams} from 'actions/settings_organisation'
import {getUserInfo} from 'utils/local-storage'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getUsers} from 'actions/settings'
import {INBOX_TYPES} from 'constants/enums'
import {SETTINGS_PATH} from 'constants/path'
import SetupModal from './SetupModal'
import GmailSetupModal from './GmailSetupModal'
import EmailSetupModal from './EmailSetupModal'
import EmailSetup from './EmailSetup'

export const InboxTab = () => {
  const history = useHistory()

  const userId = Number(get(['id'], getUserInfo()))

  const [currentModal, setCurrentModal] = useState('')
  const [inboxId, setInboxId] = useState(null)
  const [inboxEmail, setInboxEmail] = useState('')
  const [inboxInternalEmail, setInboxInternalEmail] = useState('')
  const [inboxSetupModalOpen, setInboxSetupModalOpen] = useState(false)
  const [addNewModal, setAddNewModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteInboxId, setDeleteInboxId] = useState(false)
  const [assigneeOptionsList, setAssigneeOptionsList] = useState([])
  const [teamOptionsList, setTeamOptionsList] = useState([])

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const emailLearnMore = settingsLearnMoreUrl?.channels?.email?.learnMore

  const {isLoading} = useApiLoader({label: GET_ALL_MAIL_INBOX})
  const {channelsPermissions} = useUserPermissions()
  const manageEmailsPermissions = channelsPermissions?.manage_emails

  const {data: allMailInbox = [], callAction} = useApiResponse({
    action: getAllMailInbox,
    payload: {type: INBOX_TYPES.EMAIL},
    enabled: true,
    storePath: 'mailInbox.inbox',
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        value: user.id,
        text: `${user.firstName} ${user.lastName}${userId === user.id ? ' (Me)' : ''}`,
        key: user.id,
        isInvitee: false,
        self: userId === user.id,
      }))

      setAssigneeOptionsList(list || [])
    },
  })

  useApiResponse({
    action: getAllTeams,
    enabled: true,
    label: GET_TEAMS_LIST,
    storePath: 'settingsOrganisation.teamsList',
    onSuccess: teamsList => {
      const list = teamsList.map(team => ({
        key: team.id,
        value: team.id,
        text: team.name,
      }))
      setTeamOptionsList(list || [])
    },
  })

  // Delete Inbox
  useEffect(() => {
    if (
      successLabels.includes(DELETE_INBOX) ||
      successLabels.includes(ADD_INBOX) ||
      successLabels.includes(SEND_VERIFICATION_MAIL_INBOX)
    ) {
      callAction()
    }
  }, [successLabels, callAction])

  // Fetching list of assignees for particular Inbox
  const getAssigneeListAsString = assignees => {
    const assigneeNames = (assignees || []).map(assignee => assignee.firstName)
    return assigneeNames.toString(',')
  }

  const renderALLInbox = () => {
    return allMailInbox.map((inbox, index) => (
      <KooperTableRow
        key={inbox.id}
        className="tableLink"
        onClick={() => {
          history.push({
            pathname: `/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.EMAILS}/${inbox.id} `,
            state: inbox.name,
          })
        }}
      >
        <KooperTableCell>{index + 1}</KooperTableCell>
        <KooperTableCell>
          <KooperHeader as="h5">{startCase(wrapText(inbox.name, 20))} </KooperHeader>
        </KooperTableCell>
        <KooperTableCell>{inbox.email || '-'}</KooperTableCell>
        <KooperTableCell>{startCase(wrapText(getAssigneeListAsString(inbox.assignees), 20))}</KooperTableCell>
        <KooperTableCell>{getFormattedDate(inbox.createdAt)}</KooperTableCell>
        <KooperTableCell>
          {inbox.emailVerified && <KooperLabel color="green">Verified</KooperLabel>}
          {!inbox.emailVerified && (
            <LockPermissionTooltip isRoleAccessDenied={!manageEmailsPermissions}>
              <KooperLabel
                color="orange"
                onClick={e => {
                  e.stopPropagation()
                  if (manageEmailsPermissions) {
                    setInboxId(inbox.id)
                    setInboxEmail(inbox.email)
                    setInboxInternalEmail(inbox.internalEmail)
                    setInboxSetupModalOpen(true)
                  }
                }}
              >
                <BsExclamationTriangle /> Needs Setup
              </KooperLabel>
            </LockPermissionTooltip>
          )}
        </KooperTableCell>
        <KooperTableCell>
          <KooperTooltip
            content={
              manageEmailsPermissions ? 'Delete' : getPermissionTooltipContent({roleAccess: manageEmailsPermissions})
            }
            position="top center"
            size="mini"
            trigger={
              <KooperButton
                icon
                onClick={e => {
                  e.stopPropagation()
                  if (manageEmailsPermissions) {
                    setDeleteModal(!deleteModal)
                    setDeleteInboxId(inbox.id)
                  }
                }}
              >
                <SvgIcon path="common/delete" />
              </KooperButton>
            }
          />
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  const toggleEmailSetup = () => {
    setCurrentModal('setupModal')
  }

  const renderEmailSetup = () => {
    if (currentModal === 'setupModal') {
      return (
        <SetupModal
          open={currentModal === 'setupModal'}
          toggle={() => {
            setAddNewModal(false)
            setCurrentModal('')
          }}
          setCurrentModal={setCurrentModal}
        />
      )
    }
    if (currentModal === 'gmailSetupModal') {
      return (
        <GmailSetupModal
          open={currentModal === 'gmailSetupModal'}
          toggle={toggleEmailSetup}
          assigneeOptionsList={assigneeOptionsList}
          teamOptionsList={teamOptionsList}
        />
      )
    }
    if (currentModal === 'otherSetupModal') {
      return (
        <EmailSetupModal
          open={currentModal === 'otherSetupModal'}
          toggle={toggleEmailSetup}
          assigneeOptionsList={assigneeOptionsList}
          teamOptionsList={teamOptionsList}
          setCurrentModal={setCurrentModal}
          setAddNewModal={setAddNewModal}
        />
      )
    }
    return null
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (allMailInbox.length < 1) {
    return (
      <>
        <div className="setEmpty-wrap">
          <CreateFirst
            header="Inbox Email"
            subHeader="Configure your shared email inboxes. Manage and assign users for each team inbox."
            addFirst={() => {
              if (manageEmailsPermissions) {
                setAddNewModal(true)
                setCurrentModal('setupModal')
              }
            }}
            buttonText="Create New Email"
            tooltip={!manageEmailsPermissions}
            tooltipContent={getPermissionTooltipContent({roleAccess: manageEmailsPermissions})}
            feature1="feature 1"
            feature2="feature 2"
            feature3="feature 3"
            list1="Create an Inbox in one click"
            list2="User friendly UI"
            list3="Manage all inboxes in one place"
            imageSrc={create}
          />
          {addNewModal && renderEmailSetup()}
        </div>
      </>
    )
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="common/mail" />}
      header="Email Inbox"
      subHeader="Create dedicated inboxes for efficient customer email management and organization"
      learnMoreUrl={emailLearnMore}
      headerButton={{
        onClick: () => {
          if (manageEmailsPermissions) {
            setAddNewModal(true)
            setCurrentModal('setupModal')
          }
        },
        content: 'Create Inbox',
      }}
      lockRole={!manageEmailsPermissions}
      table
      headerDivider
    >
      <KooperTable basic selectable>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>#</KooperTableHeaderCell>
            <KooperTableHeaderCell>Inbox Name</KooperTableHeaderCell>
            <KooperTableHeaderCell>Inbox Address</KooperTableHeaderCell>
            <KooperTableHeaderCell>Assignee</KooperTableHeaderCell>
            <KooperTableHeaderCell>Created Date</KooperTableHeaderCell>
            <KooperTableHeaderCell>Status</KooperTableHeaderCell>
            <KooperTableHeaderCell>Action</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>
          {isLoadingData && (type.includes(GET_USERS) || type.includes(GET_ALL_MAIL_INBOX)) ? (
            <KooperTablePlaceholder columns={6} />
          ) : (
            renderALLInbox()
          )}
        </KooperTableBody>
      </KooperTable>

      {addNewModal && renderEmailSetup()}

      {inboxSetupModalOpen && (
        <EmailSetup
          id={inboxId}
          accountEmail={inboxEmail}
          internalEmail={inboxInternalEmail}
          inboxSetupModalOpen={inboxSetupModalOpen}
          setInboxSetupModalOpen={setInboxSetupModalOpen}
        />
      )}

      {deleteModal && (
        <DeleteModal
          idTobeDeleted={deleteInboxId}
          deleteAction={deleteInbox}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          type="DELETE_INBOX"
        />
      )}
    </SettingLayout>
  )
}
