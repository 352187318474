import {GET_API_TOKEN} from 'actions/types'

export default function (state = {}, action) {
  switch (action.type) {
    case GET_API_TOKEN:
      return {...state, apiTokenData: action.payload}

    default:
      return state
  }
}
