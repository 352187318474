import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Progress} from 'semantic-ui-react'
import {ResponsiveHeatMap} from '@nivo/heatmap'
import {ResponsiveLine} from '@nivo/line'
import {ResponsivePie} from '@nivo/pie'
import _ from 'lodash'
import {
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperDropdown,
  KooperGrid,
  KooperGridColumn,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {
  ASSIGNED_VS_RESOLVED,
  GET_USERS,
  RATINGS_AND_NPS_SCORE,
  USER_ACTIVITIES_OVERTIME,
  USER_ACTIVITIES_TABLE_SLA,
  USER_ASSIGNMENT_OVERTIME,
} from 'actions/types'
import {
  assignedVSResolved,
  ratingsAndNpsScore,
  totalAssigned,
  totalResolved,
  userActivitiesOverTime,
  userActivitiesTableSLA,
  userAssignmentOvertime,
} from 'actions/analytics_users'
import {getUsers} from 'actions/settings'
import CustomLoader from 'components/common/CustomLoader'
import NoDataChart from 'components/common/NoDataChart'
import {get, getSortType, startCase} from 'utils/helper'
import {getGridYValues, maxOfGraph} from 'utils/graph'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import {AssigneeDropdown, MailboxDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {DATE_RANGE_KEY} from 'constants/variables'

const Users = () => {
  const dispatch = useDispatch()

  const [data, setData] = useState(JSON.parse(localStorage.getItem(DATE_RANGE_KEY)).persistDateRangeDataToDispatch)
  const [usersList, setUsersList] = useState([])
  const [user, setUser] = useState('all')
  const [userActivitiesData, setUserActivitiesData] = useState([])
  const [statusLabel, setStatusLabel] = useState(null)
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
  })

  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {analyticsSelectedInbox, analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)
  const {
    totalResolvedData = {},
    totalAssignedData = {},
    assignedVsResolvedData = [],
    ratingsAndNpsScoreData: {npsScore = {}} = {},
    userAssignmentOvertimeData: {dataArray = [], timeArray = []} = {},
    userActivities = [],
  } = useSelector(state => state.analyticsUsers)
  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const usersAccess = analyticsAccess?.users
  const performanceReportsPermissions = reportsPermissions?.performance_reports

  const {
    isLoading: isLoadingUserActivitiesTableSla,
    data: userActivitiesSLAData = {},
    totalPages,
    currentPage,
  } = useApiResponse({
    label: USER_ACTIVITIES_TABLE_SLA,
    storePath: 'analyticsUsers.userActivitiesSLAData',
  })

  useApiResponse({
    action: getUsers,
    enabled: usersAccess && performanceReportsPermissions,
    dependency: [usersAccess, performanceReportsPermissions],
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUsersList(list || [])
    },
  })

  useEffect(() => {
    if (!performanceReportsPermissions || !usersAccess) return
    if (user === 'all') {
      delete data.userId
    } else {
      data.userId = user
    }
    dispatch(userActivitiesOverTime(data))
  }, [performanceReportsPermissions, usersAccess, user, data, dispatch])

  useEffect(() => {
    if (successLabels.includes(USER_ACTIVITIES_OVERTIME)) {
      setUserActivitiesData(userActivities)
    }
  }, [successLabels, userActivities])

  useEffect(() => {
    if (!performanceReportsPermissions || !usersAccess) return
    const newList = _.omitBy(currentListParams, value => {
      if (value === 'none' || value === '') {
        return true
      }
      return false
    })

    dispatch(userActivitiesTableSLA({...data, ...newList}))
  }, [performanceReportsPermissions, usersAccess, analyticsSelectedAssignee, currentListParams, dispatch, data])

  const actionsToDispatch = useCallback(
    data => {
      dispatch(totalResolved(data))
      dispatch(totalAssigned(data))
      dispatch(assignedVSResolved(data))
      dispatch(ratingsAndNpsScore(data))
      dispatch(userAssignmentOvertime(data))
      setData(data)
    },
    [dispatch]
  )

  const totalUserAssignmentOvertimeData = () => {
    const values = dataArray.map(data => data.percentage).reduce((a, b) => a + b, 0)
    return values.toFixed(1)
  }

  const getUserListArray = () => {
    return [{value: 'all', text: 'All', key: 'all'}, ...usersList]
  }

  const hideGraphdata = point => {
    if (statusLabel === point.label) {
      setStatusLabel(null)
      setUserActivitiesData(userActivities)
      return
    }
    const temp = userActivities.map(val =>
      val.id === point.label
        ? {
            id: val.id,
            color: val.color,
            data: val.data.map(({x}) => {
              return {
                x,
                y: 0,
              }
            }),
          }
        : val
    )
    setStatusLabel(point.label)
    setUserActivitiesData(temp)
  }

  const handleSort = clickedColumn => () => {
    if (currentListParams.sortBy !== clickedColumn) {
      setCurrentListParams({
        ...currentListParams,
        sortType: 'asc',
        sortBy: clickedColumn,
      })
      return
    }
    setCurrentListParams({
      ...currentListParams,
      sortType: currentListParams.sortType === 'asc' ? 'desc' : 'asc',
    })
  }

  const renderUserBody = () => {
    if (isLoadingUserActivitiesTableSla) {
      return <KooperTablePlaceholder columns={6} line={1} row={3} />
    }
    if (!isLoadingUserActivitiesTableSla && get(['data'], userActivitiesSLAData, []).length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="6" className="emptyValue">
            No Data Available
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return get(['data'], userActivitiesSLAData, []).map(
      ({user, unresolved, resolved, firstResponseTime, resolvedTime, feedback}, index) => (
        <KooperTableRow key={index}>
          <KooperTableCell>{startCase(user)}</KooperTableCell>
          <KooperTableCell>{unresolved}</KooperTableCell>
          <KooperTableCell>{resolved}</KooperTableCell>
          <KooperTableCell>{firstResponseTime}</KooperTableCell>
          <KooperTableCell>{resolvedTime}</KooperTableCell>
          <KooperTableCell>{feedback}</KooperTableCell>
        </KooperTableRow>
      )
    )
  }

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/users" />
            <h5>Users</h5>
          </div>
        </div>
        {usersAccess && performanceReportsPermissions && (
          <div className="page-action">
            <MailboxDropdown />
            <AssigneeDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              userId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
              teamId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'team'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
              inboxId={
                analyticsSelectedInbox !== 'all' && analyticsSelectedInbox ? Number(analyticsSelectedInbox) : null
              }
            />
          </div>
        )}
      </div>

      <KooperGrid>
        <KooperGridColumn width={4}>
          <KooperCard fluid className="valueBox">
            <KooperCardContent>
              <KooperCardHeader>Total Resolved</KooperCardHeader>
              <h3>
                {get(['resolved'], totalResolvedData, 0)}
                <span className={get(['growth'], totalResolvedData, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <KooperTooltip
                    position="top center"
                    size="mini"
                    content="Compared to Previous Duration"
                    trigger={
                      <div>
                        {get(['growth'], totalResolvedData, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], totalResolvedData, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn width={4}>
          <KooperCard fluid className="valueBox">
            <KooperCardContent>
              <KooperCardHeader>Total Assigned</KooperCardHeader>
              <h3>
                {get(['assigned'], totalAssignedData, 0)}
                <span className={get(['growth'], totalAssignedData, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <KooperTooltip
                    position="top center"
                    size="mini"
                    content="Compared to Previous Duration"
                    trigger={
                      <div>
                        {get(['growth'], totalAssignedData, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], totalAssignedData, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Assigned vs Resolved</KooperCardHeader>
              <KooperTooltip
                content="Breakdown of user performance based on assigned and resolved conversation"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 270}}>
              {type.includes(ASSIGNED_VS_RESOLVED) && <CustomLoader />}
              {!type.includes(ASSIGNED_VS_RESOLVED) && !assignedVsResolvedData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={assignedVsResolvedData.map(item => ({
                    ...item,
                    label: `${item.label} : ${item.value} (${item.percentage.toFixed(1)}%)`,
                  }))}
                  margin={{top: 0, right: 150, bottom: 20, left: 0}}
                  startAngle={-50}
                  innerRadius={0.6}
                  padAngle={1}
                  cornerRadius={1}
                  colors={d => d.data.color}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.4]],
                  }}
                  arcLinkLabel="value"
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsDiagonalLength={5}
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{from: 'color'}}
                  enableArcLabels={false}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                  }}
                  animate
                  legends={[
                    {
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: 60,
                      translateY: 0,
                      itemsSpacing: 20,
                      itemWidth: 100,
                      itemHeight: 18,
                      itemTextColor: '#999',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(ASSIGNED_VS_RESOLVED) && <NoDataChart top="10px" />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>NPS Score</KooperCardHeader>
              <KooperTooltip
                content="Breakdown of NPS score based on users"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 270}}>
              {type.includes(RATINGS_AND_NPS_SCORE) && <CustomLoader />}
              {!type.includes(RATINGS_AND_NPS_SCORE) && !get(['data'], npsScore, []).every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={get(['data'], npsScore, []).map(item => ({
                    ...item,
                    label: `${item.label}: ${item.percentage.toFixed(1)}%`,
                  }))}
                  margin={{top: 20, right: 20, bottom: 20, left: -50}}
                  startAngle={-50}
                  innerRadius={0.7}
                  padAngle={1}
                  cornerRadius={1}
                  colors={d => d.data.color}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.4]],
                  }}
                  arcLinkLabel={e => `${e.id} (${e.value})`}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsDiagonalLength={5}
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{from: 'color'}}
                  enableArcLabels={false}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                  }}
                  animate
                  legends={[
                    {
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: -20,
                      translateY: 0,
                      itemsSpacing: 20,
                      itemWidth: 100,
                      itemHeight: 18,
                      itemTextColor: '#999',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(RATINGS_AND_NPS_SCORE) && <NoDataChart top="10px" />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>User Assignment Overtime</KooperCardHeader>
              <KooperTooltip
                content="Overview of assignment of all user for the selected range"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <div className="heatMapBar">
              least busy
              <div className="value" style={{background: 'rgba(29, 137, 255, 0.4)'}} />
              <div className="value" style={{background: 'rgba(29, 137, 255, 0.55)'}} />
              <div className="value" style={{background: 'rgba(29, 137, 255, 0.7)'}} />
              <div className="value" style={{background: 'rgba(29, 137, 255, 0.85)'}} />
              <div className="value  mr-3" />
              busiest
            </div>
            <KooperCardContent className="pt-0" style={{height: 500}}>
              {type.includes(USER_ASSIGNMENT_OVERTIME) && <CustomLoader />}
              <KooperGrid>
                <KooperGridColumn width={14} style={{height: 500}}>
                  {!type.includes(USER_ASSIGNMENT_OVERTIME) && (
                    <ResponsiveHeatMap
                      data={dataArray.map(v => ({
                        ...v,
                        label: v.name
                          .split(' ')
                          .map(n => n[0])
                          .join(''),
                      }))}
                      keys={timeArray}
                      indexBy="label"
                      margin={{
                        top: 20,
                        right: 0,
                        bottom: 30,
                        left: 80,
                      }}
                      cellOpacity={1}
                      padding={3}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        orient: 'top',
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 44,
                      }}
                      axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Time',
                        legendPosition: 'middle',
                        legendOffset: -70,
                      }}
                      colors={[
                        'rgba(29, 137, 255, 0.4)',
                        'rgba(29, 137, 255, 0.55)',
                        'rgba(29, 137, 255, 0.7)',
                        'rgba(29, 137, 255, 0.85)',
                        'rgba(29, 137, 255, 1)',
                      ]}
                      labelTextColor="#ffffff"
                      animate={false}
                      motionStiffness={100}
                      motionDamping={9}
                      hoverTarget="cell"
                      cellHoverOthersOpacity={1}
                    />
                  )}
                  {/* <div className="heatMapBelowbar" style={{marginTop: -20}}>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                    <div className="heatMapBelowbarItem">0%</div>
                  </div> */}
                </KooperGridColumn>
                <KooperGridColumn width={2} style={{height: 500}}>
                  {!type.includes(USER_ASSIGNMENT_OVERTIME) && (
                    <>
                      <div style={{height: 20}} className="ml-4 mr-4">
                        Total ({totalUserAssignmentOvertimeData()}%)
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: 432,
                        }}
                        className="heatMapSidebar pt-0 ml-4 mr-4"
                      >
                        {dataArray.map((data, index) => {
                          return (
                            <div className="heatMapSidebarItem" key={index}>
                              <div
                                style={{
                                  position: 'relative',
                                  top: '50%',
                                }}
                              >
                                <Progress
                                  percent={data.percentage}
                                  size="tiny"
                                  color="blue"
                                  style={{
                                    margin: 0,
                                    width: 70,
                                    float: 'left',
                                  }}
                                />
                                <div
                                  style={{
                                    marginTop: -3,
                                    float: 'right',
                                  }}
                                >
                                  {get(['percentage'], data, 0).toFixed(1)}%
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  )}
                </KooperGridColumn>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader className="headerBox">User Activities Over Time</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of user activities over a timeframe"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
              <KooperDropdown
                selection
                search
                style={{fontSize: '12px', marginLeft: 'auto'}}
                options={getUserListArray()}
                onChange={(e, {value}) => setUser(value)}
                defaultValue={getUserListArray()[0].value}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 300}}>
              {type.includes(USER_ACTIVITIES_OVERTIME) && <CustomLoader />}
              {!type.includes(USER_ACTIVITIES_OVERTIME) &&
                !userActivities?.map(({data}) => data.every(({y}) => y === 0)).includes(false) && (
                  <div className="empty-chart">No Data Available</div>
                )}
              {!type.includes(USER_ACTIVITIES_OVERTIME) && (
                <ResponsiveLine
                  data={userActivitiesData}
                  margin={{
                    top: 27,
                    right: 27,
                    bottom: 60,
                    left: 60,
                  }}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(userActivitiesData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableSlices="x"
                  enableGridX={false}
                  gridYValues={getGridYValues(userActivitiesData)}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    // format: tick => {
                    //   const diff = Moment(
                    //     get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))
                    //   ).diff(
                    //     Moment(get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))),
                    //     'days'
                    //   )
                    //   if (diff > 0 && diff < 17) {
                    //     return tick
                    //   }
                    //   return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    // },
                    tickPadding: 5,
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(userActivitiesData),
                    legend: 'Conversations',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  colors={d => d.color}
                  lineWidth={2}
                  pointSize={3}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  enableArea
                  useMesh
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 30,
                      itemOpacity: 1,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      onClick: point => {
                        hideGraphdata(point)
                      },
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                            itemOpacity: 0.75,
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionConfig="molasses"
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Users</KooperCardHeader>
              <KooperTooltip
                content="List of users along with their detailed performance analytics over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0">
              <KooperTable sortable basic>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell
                      sorted={currentListParams.sortBy === 'user' ? getSortType(currentListParams.sortType) : null}
                      onClick={handleSort('user')}
                    >
                      Name
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                      sorted={
                        currentListParams.sortBy === 'unresolved' ? getSortType(currentListParams.sortType) : null
                      }
                      onClick={handleSort('unresolved')}
                    >
                      Unresolved
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                      sorted={currentListParams.sortBy === 'resolved' ? getSortType(currentListParams.sortType) : null}
                      onClick={handleSort('resolved')}
                    >
                      Resolved
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                      sorted={
                        currentListParams.sortBy === 'firstResponseTime'
                          ? getSortType(currentListParams.sortType)
                          : null
                      }
                      onClick={handleSort('firstResponseTime')}
                    >
                      First Response Time
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                      sorted={
                        currentListParams.sortBy === 'resolvedTime' ? getSortType(currentListParams.sortType) : null
                      }
                      onClick={handleSort('resolvedTime')}
                    >
                      Avg Resolution Time
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                      sorted={currentListParams.sortBy === 'feedback' ? getSortType(currentListParams.sortType) : null}
                      onClick={handleSort('feedback')}
                    >
                      Feedback Ratings
                    </KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>{renderUserBody()}</KooperTableBody>
              </KooperTable>
              <KooperCustomPagination
                activePage={currentPage}
                totalPages={totalPages}
                onPageChange={page =>
                  setCurrentListParams(currentListParams => ({
                    ...currentListParams,
                    page,
                  }))
                }
              />
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default Users
