import React, {useEffect, useState} from 'react'
import {KooperDropdown, KooperDropdownHeader, KooperDropdownItem, KooperDropdownMenu, KooperInput, KooperTooltip} from 'kooper-library'

const KooperCustomDropdown = ({
  dropdownProps = {},
  menu = {},
  input = {},
  header = null,
  options = [],
  item = {},
  extraAction = null,
  activeIndicator = false,
  onClick,
  value = [],
  multiple = false,
  tooltip = false,
  tooltipContent = '',
}) => {
  const [optionState, setOptionState] = useState([])

  useEffect(() => {
    setOptionState(options)
  }, [options])

  const findIsActive = option => {
    if (multiple) {
      return activeIndicator && value ? value.some(o => o === option.value) : false
    }
    return activeIndicator && value ? value === option.value : false
  }
  return (
    <>
      {tooltip ? (
        <KooperTooltip
          content={tooltipContent}
          size="mini"
          position="top center"
          trigger={
            <KooperDropdown {...dropdownProps}>
              <KooperDropdownMenu>
                <KooperInput
                  {...input}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onChange={e => {
                    const searchQuery = e.target.value
                    let updated

                    if (searchQuery === '') {
                      updated = options
                    } else {
                      updated = options.filter(o => {
                        return o.text.toLowerCase().startsWith(searchQuery.toLowerCase())
                      })
                    }
                    setOptionState(updated)
                  }}
                />
                {header && <KooperDropdownHeader content={header} />}
                <KooperDropdownMenu {...menu} scrolling>
                  {optionState.length > 0 ? (
                    optionState.map(option => (
                      <KooperDropdownItem
                        key={option.value}
                        {...option}
                        {...item}
                        active={findIsActive(option)}
                        onClick={e => {
                          if (multiple && Array.isArray(value)) {
                            let newList = []
                            if (value.some(val => val === option.value)) {
                              newList = value.filter(n => n !== option.value)
                            } else {
                              const currentSelected = value
                              newList = [...currentSelected, option.value]
                            }
                            onClick(newList)
                          } else {
                            onClick(option.value)
                          }

                          e.stopPropagation()
                        }}
                      />
                    ))
                  ) : (
                    <p
                      style={{
                        position: 'relative',
                        textAlign: 'center',
                      }}
                    >
                      No Data Available
                    </p>
                  )}
                  {extraAction && <KooperDropdownItem {...extraAction} />}
                </KooperDropdownMenu>
              </KooperDropdownMenu>
            </KooperDropdown>
          }
        />
      ) : (
        <KooperDropdown {...dropdownProps}>
          <KooperDropdownMenu>
            <KooperInput
              {...input}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
              onChange={e => {
                const searchQuery = e.target.value
                let updated

                if (searchQuery === '') {
                  updated = options
                } else {
                  updated = options.filter(o => {
                    return o.text.toLowerCase().startsWith(searchQuery.toLowerCase())
                  })
                }
                setOptionState(updated)
              }}
            />
            {header && <KooperDropdownHeader content={header} />}
            <KooperDropdownMenu {...menu} scrolling>
              {optionState.length > 0 ? (
                optionState.map(option => (
                  <KooperDropdownItem
                    key={option.value}
                    {...option}
                    {...item}
                    active={findIsActive(option)}
                    onClick={e => {
                      if (multiple && Array.isArray(value)) {
                        let newList = []
                        if (value.some(val => val === option.value)) {
                          newList = value.filter(n => n !== option.value)
                        } else {
                          const currentSelected = value
                          newList = [...currentSelected, option.value]
                        }
                        onClick(newList)
                      } else {
                        onClick(option.value)
                      }

                      e.stopPropagation()
                    }}
                  />
                ))
              ) : (
                <p
                  style={{
                    position: 'relative',
                    textAlign: 'center',
                  }}
                >
                  No Data Available
                </p>
              )}
              {extraAction && <KooperDropdownItem {...extraAction} />}
            </KooperDropdownMenu>
          </KooperDropdownMenu>
        </KooperDropdown>
      )}
    </>
  )
}

export default KooperCustomDropdown
