import React from 'react'
import {useSelector} from 'react-redux'
import {KooperDropdown, KooperTooltip} from 'kooper-library'

const statusList = [
  {key: 'all', value: 'all', text: 'All'},
  {key: 'billed', value: 'billed', text: 'Billed'},
  {key: 'billable', value: 'billable', text: 'Billable'},
  {key: 'non-billable', value: 'non-billable', text: 'Non-billable'},
  {key: 'void', value: 'void', text: 'Void'},
  {key: 'none', value: 'none', text: 'None'},
]

const TimeTrackingFilter = ({currentListParams, setCurrentListParams}) => {
  const {projectsList = []} = useSelector(state => state.projects)
  const {getUsersList: {users = []} = {}} = useSelector(state => state.settings)

  return (
    <>
      <KooperTooltip
        position="top center"
        content="Project"
        size="mini"
        trigger={
          <KooperDropdown
            icon="chevron down"
            button
            className="dropdown-ellipsis"
            options={[
              {key: 'all', value: 'all', text: 'All'},
              ...projectsList.map(({id, title}) => ({key: id, value: id, text: title})),
            ]}
            value={currentListParams.projectId}
            onChange={(e, {value}) =>
              setCurrentListParams({
                ...currentListParams,
                projectId: value,
              })
            }
          />
        }
      />

      <KooperTooltip
        position="top center"
        content="Status"
        size="mini"
        trigger={
          <KooperDropdown
            icon="chevron down"
            button
            className="dropdown-ellipsis"
            options={statusList}
            value={currentListParams.status}
            onChange={(e, {value}) =>
              setCurrentListParams({
                ...currentListParams,
                status: value,
              })
            }
          />
        }
      />

      <KooperTooltip
        position="top center"
        content="Logged By"
        size="mini"
        trigger={
          <KooperDropdown
            icon="chevron down"
            button
            className="dropdown-ellipsis"
            options={[
              {key: 'all', value: 'all', text: 'All'},
              ...users.map(({id, firstName, lastName}) => ({key: id, value: id, text: `${firstName} ${lastName}`})),
            ]}
            value={currentListParams.createdBy}
            onChange={(e, {value}) =>
              setCurrentListParams({
                ...currentListParams,
                createdBy: value,
              })
            }
          />
        }
      />

      <KooperTooltip
        position="top center"
        content="Group By"
        size="mini"
        trigger={
          <KooperDropdown
            icon="chevron down"
            button
            className="dropdown-ellipsis"
            options={[
              {key: 'date', value: 'date', text: 'Date'},
              {key: 'person', value: 'person', text: 'Person'},
              {key: 'project', value: 'project', text: 'Project'},
              {key: 'timesheet', value: 'timesheet', text: 'Timesheet'},
            ]}
            value={currentListParams.groupBy}
            onChange={(e, {value}) =>
              setCurrentListParams({
                ...currentListParams,
                groupBy: value,
              })
            }
          />
        }
      />

      <KooperTooltip
        position="top center"
        content="Order By"
        size="mini"
        trigger={
          <KooperDropdown
            icon="chevron down"
            button
            className="dropdown-ellipsis"
            options={[
              {key: 'asc', value: 'asc', text: 'Asc'},
              {key: 'desc', value: 'desc', text: 'Desc'},
            ]}
            value={currentListParams.orderBy}
            onChange={(e, {value}) =>
              setCurrentListParams({
                ...currentListParams,
                orderBy: value,
              })
            }
          />
        }
      />
    </>
  )
}

export default TimeTrackingFilter
