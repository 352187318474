import React from 'react'
import {KooperTab, KooperTabPane} from 'kooper-library'
import SettingLayout from 'layouts/settingLayout'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import ScoringList from './ScoringList'
import EmailInsightsList from './EmailInsightsList'

const panes = [
  {
    menuItem: {
      key: 'scoring',
      icon: <SvgIcon path="settings/scoring" />,
      content: 'Scoring',
    },
    render: () => (
      <KooperTabPane className="mt-0" attached={false}>
        <ScoringList />
      </KooperTabPane>
    ),
  },
  {
    menuItem: {
      key: 'email-insights',
      icon: <SvgIcon path="settings/email-insights" />,
      content: 'Email Insights',
    },

    render: () => (
      <KooperTabPane className="mt-0" attached={false}>
        <EmailInsightsList />
      </KooperTabPane>
    ),
  },
]

const ScoringTab = () => {
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const scoringRulesLearnMore = settingsLearnMoreUrl?.sales?.forecasting?.learnMore
  return (
    <SettingLayout
      icon={<SvgIcon path="settings/scoring-rules" />}
      header="Scoring Rules"
      subHeader="Create custom scoring rules to prioritize leads and enhance sales efficiency"
      learnMoreUrl={scoringRulesLearnMore}
      table
      headerDivider
    >
      <KooperTab panes={panes} secondary menu={{secondary: true, pointing: true, color: 'blue'}} />
    </SettingLayout>
  )
}
export default ScoringTab
