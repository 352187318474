import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperFormTextArea,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperRadio,
  KooperTooltip,
} from 'kooper-library'
import {getUserInfo} from 'utils/local-storage'
import {
  CREATE_PROJECT_DISCUSSION_TOPIC,
  GET_PROJECT_DISCUSSION_SINGLE_TOPIC,
  UPDATE_PROJECT_DISCUSSION_TOPIC,
} from 'actions/types'
import {get, removeDoubleQuotes} from 'utils/helper'
import {
  createProjectDiscussionTopic,
  getProjectDiscussionSingleTopic,
  updateProjectDiscussionTopic,
} from 'actions/projects'
import {topicSchema} from 'validation/Projects/projects/projects.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const topicDefaultValues = {
  title: '',
  description: '',
  subscribers: [Number(getUserInfo().id)],
  isPinned: false,
}

const TopicModal = ({open, toggle, projectId, topicId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectAssignees = []} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: topicDefaultValues,
    resolver: joiResolver(topicSchema),
  })

  useApiResponse({
    action: getProjectDiscussionSingleTopic,
    payload: {projectId, topicId},
    dependency: [projectId, topicId],
    enabled: projectId && topicId,
    label: GET_PROJECT_DISCUSSION_SINGLE_TOPIC,
    storePath: 'projects.topicData',
    onSuccess: topicData => {
      const data = {
        title: get(['title'], topicData, ''),
        description: get(['description'], topicData, ''),
        subscribers: get(['subscribers'], topicData, []),
        isPinned: get(['isPinned'], topicData, false),
      }
      reset(data)
    },
  })

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECT_DISCUSSION_TOPIC) ||
      successLabels.includes(UPDATE_PROJECT_DISCUSSION_TOPIC)
    ) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    if (topicId) {
      dispatch(updateProjectDiscussionTopic({projectId, topicId, data}))
    } else {
      dispatch(createProjectDiscussionTopic({projectId, data}))
    }
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>{topicId ? 'Edit Topic' : 'Add Topic'} </KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label>Title</label>
              <KooperTooltip
                content="Specify title of the topic"
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="right center"
              />
            </div>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  maxLength={50}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField>
            <div className="info-header">
              <label>Description</label>
              <KooperTooltip
                content="Specify a brief description about the topic"
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="right center"
              />
            </div>
            <Controller
              name="description"
              render={({value, onChange}) => (
                <KooperFormTextArea
                  maxLength={200}
                  style={{resize: 'none'}}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.description && {content: removeDoubleQuotes(errors.description.message)}}
                />
              )}
              control={control}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Subscribers</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Add agents as subscribers this particular topic discussion"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="subscribers"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  search
                  multiple
                  options={projectAssignees.map(({id, firstName, lastName}) => ({
                    key: id,
                    value: id,
                    text: `${firstName} ${lastName}`,
                  }))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.subscribers && {content: removeDoubleQuotes(errors.subscribers.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField>
            <div className="d-flex">
              <div>
                <label>Pin to top</label>
                <p className="kooper-lead">Pin this topic to top</p>
              </div>
              <Controller
                name="isPinned"
                control={control}
                render={({value, onChange}) => (
                  <KooperRadio toggle checked={value} onClick={(e, {checked}) => onChange(checked)} />
                )}
              />
            </div>
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          {topicId ? 'Update' : 'Add'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default TopicModal
