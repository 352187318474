import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar'
import {FiFolder, FiHome} from 'react-icons/fi'
import {
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperGrid,
  KooperGridColumn,
  KooperProgress,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

const Projects = () => {
  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <FiHome />
            <h5>Projects</h5>
          </div>
        </div>
        <div className="page-action">Date Range</div>
      </div>

      <KooperGrid columns="equal">
        <KooperGridColumn>
          <KooperCard fluid className="projchartBox">
            <KooperCardContent className="projheaderBox">
              <div className="projd-flex">
                <span className="projSvg">
                  <FiFolder className="m-0" />
                </span>
                <div className="proj-Content">
                  <span>2</span>
                  <span>Total Projects</span>
                </div>
              </div>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn>
          <KooperCard fluid className="projchartBox">
            <KooperCardContent className="projheaderBox">
              <div className="projd-flex">
                <span className="projSvg">
                  <FiFolder className="m-0" />
                </span>
                <div className="proj-Content">
                  <span>2</span>
                  <span>Archived Projects</span>
                </div>
              </div>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn>
          <KooperCard fluid className="projchartBox">
            <KooperCardContent className="projheaderBox">
              <div className="projd-flex">
                <KooperGrid centered>
                  <KooperGridColumn
                    style={{
                      width: '80px',
                      height: '80px',
                      marginRight: '18px',
                    }}
                  >
                    <CircularProgressbar
                      styles={buildStyles({
                        textColor: 'rgba(0, 0, 0, 0.6)',
                        trailColor: '#f2f4f7',
                        textSize: '7px',
                      })}
                      strokeWidth={10}
                      value={0}
                      text="No Data Available"
                    />
                  </KooperGridColumn>
                </KooperGrid>
                <div className="proj-Content">
                  <span>Project</span>
                  <span>Distribution</span>
                </div>
              </div>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Project Status</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of projects based on status"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent>
              <KooperGrid>
                <KooperGridColumn width="8">
                  <KooperTable sortable basic>
                    <KooperTableHeader>
                      <KooperTableRow>
                        <KooperTableHeaderCell>Status</KooperTableHeaderCell>
                        <KooperTableHeaderCell>Count</KooperTableHeaderCell>
                      </KooperTableRow>
                    </KooperTableHeader>
                    <KooperTableBody>
                      <KooperTableRow>
                        <KooperTableCell>Active</KooperTableCell>
                        <KooperTableCell>2</KooperTableCell>
                      </KooperTableRow>
                    </KooperTableBody>
                  </KooperTable>
                </KooperGridColumn>
                <KooperGridColumn width="8">
                  <span>Graph chart</span>
                </KooperGridColumn>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Project Category</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of projects based on category"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent>
              <KooperGrid>
                <KooperGridColumn width="8">
                  <span>Graph chart</span>
                </KooperGridColumn>
                <KooperGridColumn width="8">
                  <KooperTable sortable basic>
                    <KooperTableHeader>
                      <KooperTableRow>
                        <KooperTableHeaderCell>Category</KooperTableHeaderCell>
                        <KooperTableHeaderCell>Count</KooperTableHeaderCell>
                      </KooperTableRow>
                    </KooperTableHeader>
                    <KooperTableBody>
                      <KooperTableRow>
                        <KooperTableCell>Uncategorized</KooperTableCell>
                        <KooperTableCell>2</KooperTableCell>
                      </KooperTableRow>
                    </KooperTableBody>
                  </KooperTable>
                </KooperGridColumn>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>

      <KooperGrid>
        <KooperGridColumn>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Project</KooperCardHeader>
              <KooperTooltip
                content="All projects listed based on different criterias over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent>
              <KooperGrid>
                <KooperGridColumn>
                  <KooperTable sortable basic>
                    <KooperTableHeader>
                      <KooperTableRow>
                        <KooperTableHeaderCell>Project Name</KooperTableHeaderCell>
                        <KooperTableHeaderCell>Manager</KooperTableHeaderCell>
                        <KooperTableHeaderCell>Status</KooperTableHeaderCell>
                        <KooperTableHeaderCell>Start Date</KooperTableHeaderCell>
                        <KooperTableHeaderCell>Due Date</KooperTableHeaderCell>
                        <KooperTableHeaderCell>Assignees</KooperTableHeaderCell>
                        <KooperTableHeaderCell>Task Completion %</KooperTableHeaderCell>
                      </KooperTableRow>
                    </KooperTableHeader>
                    <KooperTableBody>
                      <KooperTableRow>
                        <KooperTableCell>Onboarding Project</KooperTableCell>
                        <KooperTableCell>Ranto Berk</KooperTableCell>
                        <KooperTableCell>Active</KooperTableCell>
                        <KooperTableCell>Nov 22</KooperTableCell>
                        <KooperTableCell>Dec 05</KooperTableCell>
                        <KooperTableCell>4</KooperTableCell>
                        <KooperTableCell>
                          <div className="d-flex">
                            <KooperProgress className="m-0" style={{width: '75%'}} size="tiny" percent={15.79} /> 15.79%
                          </div>
                        </KooperTableCell>
                      </KooperTableRow>
                      <KooperTableRow>
                        <KooperTableCell>Testing</KooperTableCell>
                        <KooperTableCell>-</KooperTableCell>
                        <KooperTableCell>Active</KooperTableCell>
                        <KooperTableCell>-</KooperTableCell>
                        <KooperTableCell>-</KooperTableCell>
                        <KooperTableCell>1</KooperTableCell>
                        <KooperTableCell>
                          <div className="d-flex">
                            <KooperProgress className="m-0" style={{width: '75%'}} size="tiny" percent={0} /> 0%
                          </div>
                        </KooperTableCell>
                      </KooperTableRow>
                    </KooperTableBody>
                  </KooperTable>
                </KooperGridColumn>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default Projects
