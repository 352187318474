/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {BiChevronDown, BiChevronUp} from 'react-icons/bi'
import {
  KooperButton,
  KooperCheckbox,
  KooperForm,
  KooperFormInput,
  KooperFormSelect,
  KooperInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'
import {createProjectsTemplatesFinanceBudget} from 'actions/projects'
import {financeBudgetTypes, PROJECT_FINANCE_BUDGET_TYPES} from 'constants/projects'
import SvgIcon from 'components/common/SvgIcon'
import {templateFinanceBudgetSchema} from 'validation/Projects/projects/templates.schema'
import BudgetTypeForm from 'components/pages/Projects/Projects/BudgetTypeForm'
import ProjectTemplateBudgetForm from '../../../ProjectTemplateBudgetForm'
import ProjectTemplateFixedFeeBudgetForm from '../../../ProjectTemplateFixedFeeBudgetForm'

const defaultValues = {
  budgetType: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
  rangeType: 'financial',
  budgetAmount: 0,
  budgetTimeHour: 0,
  budgetTimeMinute: 0,
  timelogType: 'billable',
  repeatUnit: 'monthly',
  carryUnderspend: false,
  carryOverspend: false,
  isDefaultBillableRateSet: false,
  defaultBillableRate: 0,
  budgetProfitMargin: 0,
  budgetTargetProfit: 0,
  budgetTargetCosts: 0,
}

const CreateTemplateBudgetModal = ({open, toggle, templateId}) => {
  const dispatch = useDispatch()

  const [budgetTimeError, setBudgetTimeError] = useState('')
  const [isAdvanceOption, setIsAdvanceOption] = useState(false)
  const [isEnableNotificationChecked, setIsEnableNotificationChecked] = useState(false)

  const {handleSubmit, control, errors, watch, setValue, getValues} = useForm({
    shouldUnregister: false,
    resolver: joiResolver(templateFinanceBudgetSchema),
    defaultValues,
  })

  const watchBudgetType = watch('budgetType')
  const watchRangeType = watch('rangeType')
  const watchIsDefaultBillableRateSet = watch('isDefaultBillableRateSet')

  const submitForm = data => {
    const payload = {...data}
    if (payload.rangeType === 'time') {
      if (payload.budgetTimeHour * 60 + payload.budgetTimeMinute === 0) {
        setBudgetTimeError('Please enter a value greater than 0')
        return
      }
      payload.budgetAmount = payload.budgetTimeHour * 60 + payload.budgetTimeMinute
    }
    delete payload.budgetTimeHour
    delete payload.budgetTimeMinute
    delete payload.budgetTargetProfit
    delete payload.budgetTargetCosts

    dispatch(createProjectsTemplatesFinanceBudget({templateId, data: payload}))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader >Create a new budget</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <BudgetTypeForm control={control} budgetTypes={financeBudgetTypes} />

          {[PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER].includes(
            watchBudgetType
          ) && (
            <ProjectTemplateBudgetForm
              control={control}
              errors={errors}
              budgetType={watchBudgetType}
              rangeType={watchRangeType}
              budgetTimeError={budgetTimeError}
              setBudgetTimeError={setBudgetTimeError}
            />
          )}

          {[PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE].includes(watchBudgetType) && (
            <ProjectTemplateFixedFeeBudgetForm
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
            />
          )}

          <br />
          <a className="my-2 d-flex-js" onClick={() => setIsAdvanceOption(!isAdvanceOption)}>
            Advanced Options {isAdvanceOption ? <BiChevronUp /> : <BiChevronDown />}
          </a>
          {isAdvanceOption && (
            <>
              <div className="d-flex-js">
                <Controller
                  name="isDefaultBillableRateSet"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperCheckbox checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
                <p className="ml-2 d-flex-js">
                  Set default billable rate of{' '}
                  <Controller
                    name="defaultBillableRate"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormInput
                        type="number"
                        disabled={!watchIsDefaultBillableRateSet}
                        style={{width: '80px'}}
                        className="mx-2"
                        value={value}
                        onClick={e => e.stopPropagation()}
                        onChange={(e, {value}) => onChange(value)}
                      />
                    )}
                  />{' '}
                  for this project
                </p>
              </div>
              <KooperCheckbox
                label="Enable notifications"
                checked={isEnableNotificationChecked}
                onChange={(e, {checked}) => setIsEnableNotificationChecked(checked)}
                disabled
              />
              <div className="d-flex-js ml-5">
                Notify <span className="kooper-dummyAvtar">SK</span> <SvgIcon path="common/plus" className="mx-1" /> via{' '}
                <KooperFormSelect
                  style={{width: '120px'}}
                  fluid
                  className="mx-2 mb-0"
                  disabled={!isEnableNotificationChecked}
                  defaultValue="email"
                  options={[
                    {key: 'email', value: 'email', text: 'email'},
                    {key: 'mobilePush', value: 'mobilePush', text: 'mobile push'},
                  ]}
                />
                when budget exceeds{' '}
                <KooperInput
                  className="ml-2"
                  style={{width: '60px'}}
                  label="%"
                  labelPosition="right"
                  value={80}
                  disabled={!isEnableNotificationChecked}
                />
              </div>
            </>
          )}
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Create Budget
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default CreateTemplateBudgetModal
