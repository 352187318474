import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

import {
  CREATE_PRODUCTS_CATEGORY,
  // EDIT_PRODUCTS_CATEGORY,
} from 'actions/types'
import {
  createProductCategory,
  getProductCategory,
  // editProductsCategory,
} from 'actions/productLibrary'
import {categorySchema} from 'validation/Sales/Products/category.schema'
import SvgIcon from 'components/common/SvgIcon'

const CategoryModal = props => {
  const dispatch = useDispatch()

  const {open, toggle} = props

  const {handleSubmit, errors, control} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(categorySchema),
    defaultValues: {
      name: '',
    },
  })

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  /*   useEffect(() => {
    return () => {
      categoryToggle("");
    };
  }, []); */

  /*   useEffect(() => {
    if (editId) {
      dispatch(getSingleAssetsCategory(editId));
    }
  }, [editId]);
 */
  useEffect(() => {
    if (successLabels.includes(/* GET_SINGLE_ASSETS_CATEGORY */)) {
      // reset({ name: getSingleAssetsCategoryData.name });
    }
    // if (successLabels.includes(EDIT_PRODUCTS_CATEGORY)) {
    //   toggle();
    // }
    if (successLabels.includes(CREATE_PRODUCTS_CATEGORY)) {
      toggle()
      dispatch(getProductCategory())
    }
  }, [successLabels])

  const handleSave = data => {
    // if (editId) {
    // dispatch(editProductsCategory(editId, data));
    // } else {
    dispatch(createProductCategory(data))
    // }
  }

  return (
    <KooperModal
      open={open}
      onClose={toggle}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      centered={false}
    >
      <KooperModalHeader >Create Category</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <label>Name</label>
            <Controller
              name="name"
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  maxLength={20}
                  autoFocus
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  onKeyDown={e => e.key === 'Enter' && handleSubmit(data => handleSave(data))}
                  error={
                    errors.name && {
                      content: errors.name.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton disabled={isLoadingData && type.includes(CREATE_PRODUCTS_CATEGORY)} basic onClick={toggle}>
          Cancel
        </KooperButton>
        <KooperButton
          loading={isLoadingData && type.includes(CREATE_PRODUCTS_CATEGORY)}
          disabled={isLoadingData && type.includes(CREATE_PRODUCTS_CATEGORY)}
          primary
          onClick={handleSubmit(handleSave)}
        >
          Create
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default CategoryModal
