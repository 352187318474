import {joiResolver} from '@hookform/resolvers'
import {getProjectFinanceSingleRate, updateProjectFinanceSingleRate} from 'actions/projects'
import {GET_PROJECT_FINANCE_SINGLE_RATE} from 'actions/types'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import Joi from 'joi'
import React, {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {getDecimalNumberInput, getFormattedDate, removeDoubleQuotes} from 'utils/helper'
import {
  KooperButton,
  KooperDatePicker,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperRadio,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
} from 'kooper-library'

const ProjectRateModal = ({open, toggle, projectId, budgetId, userId}) => {
  const dispatch = useDispatch()

  const [projectRateFrom, setProjectRateFrom] = useState('today')
  const [fromNewDate, setFromNewDate] = useState(new Date())

  const {projectData = {}} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        billableRate: Joi.number().min(1).required().messages({
          'number.min': 'Please enter a value greater than 0',
        }),
        costRate: Joi.number().min(1).required().messages({
          'number.min': 'Please enter a value greater than 0',
        }),
      })
    ),
    defaultValues: {
      billableRate: 0,
      costRate: 0,
    },
  })

  const {data: rateData = {}} = useApiResponse({
    action: getProjectFinanceSingleRate,
    payload: {projectId, budgetId, userId},
    dependency: [projectId, budgetId, userId],
    enabled: projectId && budgetId && userId,
    label: GET_PROJECT_FINANCE_SINGLE_RATE,
    storePath: 'projects.rateData',
    onSuccess: (rateData = {}) => {
      const data = {
        billableRate: rateData.billableRate ?? 0,
        costRate: rateData.costRate ?? 0,
      }
      reset(data)
    },
  })

  const submitForm = data => {
    const payload = {...data}
    if (projectRateFrom === 'today') {
      payload.fromDate = new Date()
    } else if (projectRateFrom === 'newDate') {
      payload.fromDate = fromNewDate()
    } else {
      payload.fromDate = new Date(projectData.createdAt)
    }
    dispatch(updateProjectFinanceSingleRate({projectId, budgetId, userId, data: payload}))
  }

  const renderTableBody = () => {
    return (
      <KooperTableRow className="tableLink">
        <KooperTableCell>${rateData.billableRate || 0}</KooperTableCell>
        <KooperTableCell>${rateData.costRate || 0}</KooperTableCell>
        <KooperTableCell>{rateData.fromDate ? getFormattedDate(rateData.fromDate) : '-'}</KooperTableCell>
        <KooperTableCell>
          <div>{rateData.updateBy?.name}</div>
          <div>{rateData.updatedAt ? getFormattedDate(rateData.updatedAt) : '-'}</div>
        </KooperTableCell>
      </KooperTableRow>
    )
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>
        Billable Rate For Project <strong>{projectData.title}</strong>
      </KooperModalHeader>
      <KooperModalContent>
        <h3>{rateData.user?.name}</h3>
        <KooperForm className="errorLabel">
          <KooperFormField required width={8}>
            <label>Billable Rate/h</label>
            <Controller
              name="billableRate"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  value={value || 0}
                  onChange={(e, {value}) => onChange(getDecimalNumberInput(value))}
                  error={errors.billableRate && {content: removeDoubleQuotes(errors.billableRate.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required width={8}>
            <label>Cost Rate/h</label>
            <Controller
              name="costRate"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  value={value || 0}
                  onChange={(e, {value}) => onChange(getDecimalNumberInput(value))}
                  error={errors.costRate && {content: removeDoubleQuotes(errors.costRate.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField>
            <label>Apply Project Rate:</label>
            <div>
              <KooperRadio
                label="from today"
                checked={projectRateFrom === 'today'}
                onChange={() => setProjectRateFrom('today')}
              />
            </div>
            <KooperFormGroup inline className="mt-1 mb-2">
              <KooperRadio checked={projectRateFrom === 'newDate'} onChange={() => setProjectRateFrom('newDate')} />
              <label className="ml-2">from</label>
              <KooperDatePicker
                disabled={projectRateFrom !== 'newDate'}
                time={false}
                value={fromNewDate}
                onChange={value => setFromNewDate(value)}
                inputProps={{readOnly: true}}
              />
            </KooperFormGroup>
            <KooperRadio
              checked={projectRateFrom === 'projectStartDate'}
              onChange={() => setProjectRateFrom('projectStartDate')}
              label={`for project duration (${getFormattedDate(projectData.createdAt)}-onwards)`}
            />
          </KooperFormField>
        </KooperForm>

        <h3>Effective Rate</h3>
        <KooperTable basic>
          <KooperTableHeader>
            <KooperTableRow>
              <KooperTableHeaderCell>Billable Rate/H</KooperTableHeaderCell>
              <KooperTableHeaderCell>Cost Rate/H</KooperTableHeaderCell>
              <KooperTableHeaderCell>Rate Applied</KooperTableHeaderCell>
              <KooperTableHeaderCell>Last Updated</KooperTableHeaderCell>
            </KooperTableRow>
          </KooperTableHeader>
          <KooperTableBody>{renderTableBody()}</KooperTableBody>
        </KooperTable>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Save
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default ProjectRateModal
