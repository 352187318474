import React from 'react'
import {KooperCard, KooperCardContent, KooperForm, KooperLabel} from 'kooper-library'
import RenderFilter from './RenderFilter'

const RenderRules = ({rulesFields, rulesRemove, control, errors, clearErrors, watch, setValue}) => {
  return (rulesFields || []).map((val, rulesIndex) => {
    return (
      <React.Fragment key={val.id}>
        <KooperCard fluid>
          <KooperCardContent>
            <KooperForm className="errorLabel camerrorLabel">
              <RenderFilter
                rulesFields={rulesFields}
                rulesIndex={rulesIndex}
                rulesRemove={rulesRemove}
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                watch={watch}
                setValue={setValue}
              />
            </KooperForm>
          </KooperCardContent>
        </KooperCard>
        {rulesIndex < rulesFields.length - 1 && (
          <KooperLabel basic className="andLable mb-4">
            {watch('matchCase') === 'and' ? 'AND' : 'OR'}
          </KooperLabel>
        )}
      </React.Fragment>
    )
  })
}

export default RenderRules
