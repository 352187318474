/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiFileText} from 'react-icons/fi'
import {
  KooperButton,
  KooperHeader,
  KooperLabel,
  KooperList,
  KooperListItem,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {
  DELETE_VENDORS_FIELD,
  GET_VENDORS_CATEGORY,
  CREATE_VENDORS_FIELD,
  GET_VENDORS_FIELD,
  EDIT_VENDORS_FIELD,
} from 'actions/types'
import {getVendorsField, getSingleVendorsField, getVendorsCategory, deleteVendorsField} from 'actions/vendors'
import DeleteModal from 'components/common/DeleteModal'
import CreateFirst from 'components/common/CreateFirst'
import SettingLayout from 'layouts/settingLayout'
import create from 'assets/create.svg'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {roleAccessDeniedContent} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import FieldsModal from './FieldsModal'

const Fields = () => {
  const dispatch = useDispatch()

  const [isFieldModal, setIsFieldModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [idToEdit, setIdToEdit] = useState(null)
  const [categoryList, setCategoryList] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [numberOfPage, setNumberOfPage] = useState(0)

  const [sort, setSort] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
    search: '',
    category: 'all',
  })
  const {isLoadingData, type, successLabels} = useSelector(state => state.apiReducer)

  const {getVendorsFieldData = [], getVendorsFieldPage} = useSelector(state => state.vendors)
  const {financePermissions} = useUserPermissions()
  const manageVendorSettingsPermissions = financePermissions?.manage_vendor_settings

  const {isLoading} = useApiLoader({label: GET_VENDORS_FIELD})

  useEffect(() => {
    if (
      successLabels.includes(CREATE_VENDORS_FIELD) ||
      successLabels.includes(DELETE_VENDORS_FIELD) ||
      successLabels.includes(EDIT_VENDORS_FIELD)
    ) {
      dispatch(getVendorsField())
    }
  }, [successLabels])

  useApiResponse({
    action: getVendorsCategory,
    enabled: true,
    label: GET_VENDORS_CATEGORY,
    storePath: 'vendors.getVendorsCategoryData',
    onSuccess: getVendorsCategoryData => {
      setCategoryList(
        getVendorsCategoryData.map(({category, id}) => {
          return {key: id, value: id, text: category}
        })
      )
    },
  })

  useEffect(() => {
    if (getVendorsFieldPage) {
      setCurrentPage(getVendorsFieldPage.page)
      setNumberOfPage(getVendorsFieldPage.totalPages)
      // dispatch({
      //   type: "",
      // });
    }
  }, [getVendorsFieldPage])

  useEffect(() => {
    const newList = _.omitBy(sort, value => {
      if (value === '' || value === 'all') {
        return true
      }
      return false
    })

    dispatch(getVendorsField(newList))
  }, [sort])

  const filedDeleteModal = () => {
    return (
      <DeleteModal
        idTobeDeleted={idToEdit}
        deleteAction={deleteVendorsField}
        isModalOpen={deleteModal}
        setIsModalOpen={setDeleteModal}
        modalText="Are you sure you want to Delete?"
        type={DELETE_VENDORS_FIELD}
      />
    )
  }

  const AddFirstFields = () => {
    if (isLoadingData) return null
    return (
      <SettingLayout>
        <CreateFirst
          src={<FiFileText />}
          header="Add Field"
          subHeader="Add fields to manage vendors, making it easier to track important details"
          addFirst={() => manageVendorSettingsPermissions && setIsFieldModal(true)}
          tooltip={!manageVendorSettingsPermissions}
          tooltipContent={getPermissionTooltipContent({
            roleAccess: manageVendorSettingsPermissions,
          })}
          buttonText="Create Field"
          imageSrc={create}
          Feature1="Field creation"
          list1="Create vendors field with ease"
          Feature2="Link category"
          list2="Easy to connect a vendor category with a field"
          Feature3="Simple Interface"
          list3="User-friendly interface for easy customization."
        />
      </SettingLayout>
    )
  }

  const renderFieldList = () =>
    isLoadingData && type.includes(GET_VENDORS_CATEGORY) ? (
      <KooperTablePlaceholder columns={6} />
    ) : !getVendorsFieldData[0] && getVendorsFieldData.length === 0 ? (
      <tr>
        <td colSpan="6" className="emptyValue">
          No Data Available
        </td>
      </tr>
    ) : (
      getVendorsFieldData.map(({id, name, type, categoryIds, required}, index) => {
        return (
          <KooperTableRow
            onClick={() => {
              dispatch(getSingleVendorsField(id))
              setIsFieldModal(true)
            }}
            className="tableLink"
            key={id}
          >
            <KooperTableCell className="pl-4">{index + 1}</KooperTableCell>
            <KooperTableCell>
              <KooperHeader as="h5">{startCase(name)}</KooperHeader>
            </KooperTableCell>
            <KooperTableCell>
              <KooperList horizontal>
                {categoryIds &&
                  categoryIds
                    .slice(0, 2)
                    .map(
                      ({category}, i) =>
                        category && (
                          <KooperListItem key={i}>{`${category} ${i + 1 !== categoryIds?.length ? ',' : ''}`}</KooperListItem>
                        )
                    )}
                <KooperListItem key="33">
                  {categoryIds?.length > 2 && <KooperLabel>& {categoryIds?.length - 2} more</KooperLabel>}
                </KooperListItem>
              </KooperList>
            </KooperTableCell>
            <KooperTableCell>{type}</KooperTableCell>
            <KooperTableCell>{required ? 'Yes' : 'No'}</KooperTableCell>
            <KooperTableCell>
              <KooperTooltip
                content={!manageVendorSettingsPermissions ? roleAccessDeniedContent : 'Delete'}
                size="mini"
                position="top center"
                trigger={
                  <KooperButton
                    icon
                    onClick={e => {
                      if (manageVendorSettingsPermissions) {
                        setDeleteModal(true)
                        setIdToEdit(id)
                      }
                      e.stopPropagation()
                    }}
                  >
                    <SvgIcon path="common/delete" />
                  </KooperButton>
                }
              />
            </KooperTableCell>
          </KooperTableRow>
        )
      })
    )

  const renderpage = () => {
    return (
      <SettingLayout
        header="Vendor Fields"
        subHeader="Create fields and link each field with a category for better vendor management"
        headerButton={{
          onClick: () => manageVendorSettingsPermissions && setIsFieldModal(true),
          content: 'Create Field',
        }}
        table
        lockRole={!manageVendorSettingsPermissions}
      >
        <KooperTable sortable basic selectable>
          <KooperTableHeader>
            <KooperTableRow>
              <KooperTableHeaderCell className="pl-4" disabled>
                #
              </KooperTableHeaderCell>
              <KooperTableHeaderCell>Name</KooperTableHeaderCell>
              <KooperTableHeaderCell disabled>Category</KooperTableHeaderCell>
              <KooperTableHeaderCell disabled>Type</KooperTableHeaderCell>
              <KooperTableHeaderCell disabled>Required</KooperTableHeaderCell>
              <KooperTableHeaderCell disabled>Action</KooperTableHeaderCell>
            </KooperTableRow>
          </KooperTableHeader>
          <KooperTableBody>{renderFieldList()}</KooperTableBody>
        </KooperTable>
        {/* <KooperCustomPagination
          totalPages={numberOfPage}
          activePage={currentPage}
          onPageChange={page => {
            setSort(sort => ({...sort, page}))
          }}
        /> */}
      </SettingLayout>
    )
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {!getVendorsFieldData[0] &&
      getVendorsFieldData.length === 0 &&
      !sort.search &&
      (!sort.category || sort.category === 'all')
        ? AddFirstFields()
        : renderpage()}

      {isFieldModal && (
        <FieldsModal
          open={isFieldModal}
          modalToggle={setIsFieldModal}
          categoryList={categoryList}
          setCategoryList={setCategoryList}
        />
      )}
      {deleteModal && filedDeleteModal()}
    </>
  )
}

export default Fields
