/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperButton,
  KooperHeader,
  KooperLabel,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {deleteProjectRisk, getAllProjectRisks, getSingleProjectRisk} from 'actions/projects'
import {DELETE_PROJECT_RISK, GET_ALL_PROJECT_RISKS} from 'actions/types'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import DeleteModal from 'components/common/DeleteModal'
import CreateFirst from 'components/common/CreateFirst'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import {roleAccessDeniedContent} from 'constants/variables'
import RiskModal from './RiskModal'

function RisksList(props) {
  const {projectId, rolePermissions} = props
  const dispatch = useDispatch()

  const [isAddRiskOpen, setIsAddRiskOpen] = useState(false)
  const [editId, setEditId] = useState()
  const [deleteModal, setDeleteModal] = useState(false)

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_ALL_PROJECT_RISKS})

  const {data: getRisksList = [], callAction} = useApiResponse({
    action: getAllProjectRisks,
    payload: projectId,
    dependency: [projectId],
    enabled: projectId,
    storePath: 'projects.getRisksList',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_PROJECT_RISK)) {
      callAction()
    }
  }, [successLabels, callAction])

  const renderTableBody = () => {
    return getRisksList.map(
      (
        {
          id,
          riskSource,
          createdBy,
          status,
          probabilityType,
          probabilityValue,
          impactType,
          impactValue,
          result,
          isImpactOnCost,
          isImpactOnPerformance,
          isImpactOnSchedule,
        },
        index
      ) => {
        return (
          <KooperTableRow
            key={id}
            className="tableLink"
            onClick={() => {
              dispatch(getSingleProjectRisk({riskId: id, projectId}))
              setIsAddRiskOpen(true)
            }}
          >
            <KooperTableCell>{index + 1}</KooperTableCell>
            <KooperTableCell>
              <KooperHeader as="h5">{startCase(riskSource)}</KooperHeader>
            </KooperTableCell>
            <KooperTableCell>{`${createdBy?.firstName} ${createdBy?.lastName}`}</KooperTableCell>
            <KooperTableCell>
              {probabilityType === 'Low' && (
                <span className="circle-type" style={{backgroundColor: '#59c894'}}>
                  {probabilityValue}
                </span>
              )}
            </KooperTableCell>
            <KooperTableCell style={{textAlign: 'center'}}>
              {probabilityType === 'Medium' && (
                <span className="circle-type" style={{backgroundColor: 'orange', marginLeft: '10px'}}>
                  {probabilityValue}
                </span>
              )}
            </KooperTableCell>
            <KooperTableCell style={{textAlign: 'center'}}>
              {probabilityType === 'High' && (
                <span className="circle-type" style={{backgroundColor: 'red'}}>
                  {probabilityValue}
                </span>
              )}
            </KooperTableCell>
            <KooperTableCell style={{textAlign: 'center'}}>
              {impactType === 'Low' && (
                <span className="circle-type" style={{backgroundColor: '#59c894'}}>
                  {impactValue}
                </span>
              )}
            </KooperTableCell>
            <KooperTableCell style={{textAlign: 'center'}}>
              {impactType === 'Medium' && (
                <span className="circle-type" style={{backgroundColor: 'orange', marginLeft: '10px'}}>
                  {impactValue}
                </span>
              )}
            </KooperTableCell>
            <KooperTableCell style={{textAlign: 'center'}}>
              {impactType === 'High' && (
                <span className="circle-type" style={{backgroundColor: 'red'}}>
                  {impactValue}
                </span>
              )}
            </KooperTableCell>
            <KooperTableCell
              style={{
                backgroundColor: '#cfe5fd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              {result >= 0 && result <= 15 ? (
                <span className="circle-type" style={{backgroundColor: '#59c894'}}>
                  {result}
                </span>
              ) : result > 15 && result <= 48 ? (
                <span className="circle-type" style={{backgroundColor: 'orange'}}>
                  {result}
                </span>
              ) : result > 48 ? (
                <span className="circle-type" style={{backgroundColor: 'red'}}>
                  {result}
                </span>
              ) : (
                ''
              )}
            </KooperTableCell>
            <KooperTableCell style={{textAlign: 'center', fontWeight: 'bold', color: '#ff3454'}}>
              {isImpactOnCost && <SvgIcon path="common/check" />}
            </KooperTableCell>
            <KooperTableCell style={{textAlign: 'center', fontWeight: 'bold', color: '#ff3454'}}>
              {isImpactOnSchedule && <SvgIcon path="common/check" />}
            </KooperTableCell>
            <KooperTableCell style={{textAlign: 'center', fontWeight: 'bold', color: '#ff3454'}}>
              {isImpactOnPerformance && <SvgIcon path="common/check" />}
            </KooperTableCell>
            <KooperTableCell>
              <KooperLabel
                style={{
                  background: status === 'Open' ? '#59c894' : status === 'Pending' ? 'orange' : 'red',
                  color: '#fff',
                }}
              >
                {status}
              </KooperLabel>
            </KooperTableCell>
            <KooperTableCell style={{marginLeft: '10px'}}>
              <KooperTooltip
                content={!rolePermissions ? roleAccessDeniedContent : 'Delete'}
                size="mini"
                position="top center"
                trigger={
                  <KooperButton
                    icon={<SvgIcon path="common/delete" />}
                    onClick={e => {
                      e.stopPropagation()
                      if (rolePermissions) {
                        setEditId(id)
                        setDeleteModal(true)
                      }
                    }}
                  />
                }
              />
            </KooperTableCell>
          </KooperTableRow>
        )
      }
    )
  }

  const renderRiskModal = () => {
    if (isAddRiskOpen) {
      return (
        <RiskModal
          projectId={projectId}
          setIsAddRiskOpen={setIsAddRiskOpen}
          isAddRiskOpen={isAddRiskOpen}
          rolePermissions={rolePermissions}
        />
      )
    }
  }

  if (!getRisksList?.length) {
    return (
      <div className="pageBodyWrap">
        <CreateFirst
          header="Risk Management"
          subHeader="Risk management helps in identifying, assessing, and mitigating potential threats to project success"
          addFirst={() => rolePermissions && setIsAddRiskOpen(true)}
          buttonText="Create Risk"
          tooltip={!rolePermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: rolePermissions})}
          Feature1="Risk Assessment"
          Feature2="Risk Identification"
          Feature3="Risk Monitoring"
          list1="Evaluate risks based on probability to prioritize actions."
          list2="Identify and categorize potential risks that could impact your project."
          list3="Track identified risks throughout the project lifecycle to stay proactive."
        />
        {renderRiskModal()}
      </div>
    )
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <div className="pageBodyWrap">
        <div className="pageHeader px-0">
          <div className="pageTitle">
            <div className="pageTitleIcon">
              <h5>Risks ({getRisksList ? getRisksList?.length : 0})</h5>
            </div>
          </div>
          <div className="pageAction">
            <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
              <KooperButton
                content="Create Risk"
                primary
                className={!rolePermissions ? 'disabled-button' : ''}
                onClick={() => setIsAddRiskOpen(!isAddRiskOpen)}
              />
            </LockPermissionTooltip>
          </div>
        </div>
        <KooperTable sortable basic selectable>
          <KooperTableHeader>
            <KooperTableRow>
              <KooperTableHeaderCell style={{textAlign: 'center'}} />
              <KooperTableHeaderCell style={{textAlign: 'center'}} />
              <KooperTableHeaderCell style={{textAlign: 'center'}} />
              <KooperTableHeaderCell style={{fontWeight: 'bold', textAlign: 'center'}} colSpan={3}>
                Probability
              </KooperTableHeaderCell>
              <KooperTableHeaderCell style={{fontWeight: 'bold', textAlign: 'center'}} colSpan={3}>
                Impact
              </KooperTableHeaderCell>
              <KooperTableHeaderCell style={{textAlign: 'center'}} />
              <KooperTableHeaderCell style={{fontWeight: 'bold', textAlign: 'center'}} colSpan={3}>
                Impact Areas
              </KooperTableHeaderCell>
              <KooperTableHeaderCell style={{textAlign: 'center'}} />
              <KooperTableHeaderCell style={{textAlign: 'center'}} />
            </KooperTableRow>
            <KooperTableRow>
              <KooperTableHeaderCell>#</KooperTableHeaderCell>
              <KooperTableHeaderCell>Source</KooperTableHeaderCell>
              <KooperTableHeaderCell>Created By</KooperTableHeaderCell>
              <KooperTableHeaderCell>Low</KooperTableHeaderCell>
              <KooperTableHeaderCell>Medium</KooperTableHeaderCell>
              <KooperTableHeaderCell>High</KooperTableHeaderCell>
              <KooperTableHeaderCell>Low</KooperTableHeaderCell>
              <KooperTableHeaderCell>Medium</KooperTableHeaderCell>
              <KooperTableHeaderCell>High</KooperTableHeaderCell>
              <KooperTableHeaderCell style={{textAlign: 'center'}}>Result</KooperTableHeaderCell>
              <KooperTableHeaderCell style={{textAlign: 'center'}}>Cost</KooperTableHeaderCell>
              <KooperTableHeaderCell style={{textAlign: 'center'}}>Schedule</KooperTableHeaderCell>
              <KooperTableHeaderCell style={{textAlign: 'center'}}>Performance</KooperTableHeaderCell>
              <KooperTableHeaderCell>Status</KooperTableHeaderCell>
              <KooperTableHeaderCell>Action</KooperTableHeaderCell>
            </KooperTableRow>
          </KooperTableHeader>

          <KooperTableBody>
            {isLoadingData && type.includes(GET_ALL_PROJECT_RISKS) ? (
              <KooperTablePlaceholder columns={15} />
            ) : !isLoadingData && (getRisksList || []).length > 0 ? (
              renderTableBody()
            ) : (
              <tr>
                <td colSpan={15} className="emptyValue">
                  No Data Available
                </td>
              </tr>
            )}
          </KooperTableBody>
        </KooperTable>
      </div>

      {renderRiskModal()}
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          onDelete={() => dispatch(deleteProjectRisk({riskId: editId, projectId}))}
          type={DELETE_PROJECT_RISK}
        />
      )}
    </>
  )
}

export default RisksList
