import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperContainer,
  KooperForm,
  KooperFormCheckbox,
  KooperFormDropdown,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormRadio,
} from 'kooper-library'
import {GET_SCORING_RULES_INSIGHTS, UPDATE_SCORING_RULES_INSIGHTS} from 'actions/types'
import {getScoringRulesInsights, updateScoringRulesInsights} from 'actions/settings_scoringrules'
import {removeDoubleQuotes} from 'utils/helper'
import {emailInsightschema} from 'validation/Settings/sales/Scoring.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useApiResponse from 'hooks/impure/useApiResponse'

function EmailInsightsList() {
  const dispatch = useDispatch()

  const [insightData, setInsightData] = useState([])

  const {successLabels = [], isLoadingData, type} = useSelector(state => state.apiReducer)

  const {salesPermissions, salesAndCrmAccess} = useUserPermissions()
  const scoringRulesAccess = salesAndCrmAccess?.scoringRules
  const manageScoringRulesPermissions = salesPermissions?.manage_scoring_rules

  const {
    handleSubmit,
    control,
    errors,
    setValue,
    watch,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange',
    defaultValues: {
      entity: 'contact',
      insights: [
        {field: 'opened', isActive: false, isAdd: true, points: 0},
        {field: 'clicked', isActive: false, isAdd: true, points: 0},
        {field: 'bounce', isActive: false, isAdd: true, points: 0},
      ],
    },
    resolver: joiResolver(emailInsightschema),
  })

  const {fields} = useFieldArray({
    control,
    name: 'insights',
  })

  const watchEntity = watch('entity')

  useApiResponse({
    action: getScoringRulesInsights,
    enabled: true,
    label: GET_SCORING_RULES_INSIGHTS,
    storePath: 'settingsScoringRules.getScoringRulesInsightsData',
    onSuccess: getScoringRulesInsightsData => {
      setInsightData(getScoringRulesInsightsData)
    },
  })

  useEffect(() => {
    if (watchEntity) {
      setValue('insights', insightData.filter(data => data.entity === watchEntity)?.[0]?.insights)
    }
  }, [watchEntity, setValue, insightData])

  useEffect(() => {
    if (successLabels.includes(UPDATE_SCORING_RULES_INSIGHTS)) {
      dispatch(getScoringRulesInsights())
    }
  }, [successLabels, dispatch])

  const getLabelName = index => {
    if (index === 0) {
      return 'Open'
    }
    if (index === 1) {
      return 'Click'
    }
    if (index === 2) {
      return 'Bounce'
    }
    return null
  }

  const submitData = data => {
    dispatch(updateScoringRulesInsights(data))
  }

  const cancelData = () => {
    if (watchEntity) {
      dispatch(getScoringRulesInsights())
    }
  }

  return (
    <KooperContainer fluid>
      <KooperForm className="mt-4 errorLabel">
        <KooperFormField>
          <label>Email Insights score - choose criteria</label>
          <KooperFormGroup inline>
            <Controller
              name="entity"
              control={control}
              render={({value, onChange}) => (
                <>
                  <KooperFormRadio
                    label="Contacts"
                    value="contact"
                    checked={value === 'contact'}
                    onChange={() => onChange('contact')}
                  />
                </>
              )}
            />
          </KooperFormGroup>
        </KooperFormField>
        <KooperFormField>
          {fields.map(({field, isActive, isAdd, points}, index) => {
            return (
              <KooperFormGroup key={index} inline>
                <KooperFormField width={2}>
                  <Controller
                    name={`insights[${index}].isActive`}
                    control={control}
                    defaultValue={isActive}
                    render={({value, onChange}) => (
                      <KooperFormCheckbox
                        className="mr-5"
                        label={getLabelName(index)}
                        checked={value === true}
                        onChange={(e, {checked}) => onChange(checked)}
                      />
                    )}
                  />
                </KooperFormField>
                <KooperFormField>
                  <Controller
                    name={`insights[${index}].isAdd`}
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormDropdown
                        selection
                        placeholder="Add"
                        options={[
                          {
                            value: 'Add',
                            text: 'Add',
                            key: 'Add',
                            icon: 'add',
                          },
                          {
                            value: 'Subtract',
                            text: 'Subtract',
                            key: 'Subtract',
                            icon: 'minus',
                          },
                        ]}
                        value={value ? 'Add' : 'Subtract'}
                        onChange={(e, {value}) => onChange(value === 'Add')}
                      />
                    )}
                  />
                </KooperFormField>
                <KooperFormField>
                  <Controller
                    name={`insights[${index}].points`}
                    control={control}
                    defaultValue={points}
                    render={({value, onChange}) => (
                      <KooperFormInput
                        style={{width: '50px'}}
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={
                          errors?.insights?.[index]?.points && {
                            content: removeDoubleQuotes(errors.insights[index].points.message),
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
                <span style={{marginTop: '3px'}}>Point(s)</span>
              </KooperFormGroup>
            )
          })}
          {/* <KooperFormGroup inline>
            <KooperCheckbox label="Open" className="mr-5" />
            <KooperFormDropdown
              className="mr-3 ml-5"
              placeholder="Add"
              name="operator"
              options={[
                { value: "Add", text: "Add", key: "Add", icon: "add" },
                {
                  value: "Subtract",
                  text: "Subtract",
                  key: "Subtract",
                  icon: "minus",
                },
              ]}
            />
            <KooperFormInput style={{ width: "50px" }} type="text" />

            <span style={{ marginTop: "3px" }}>Point(s)</span>
          </KooperFormGroup>

          <KooperFormGroup inline>
            <KooperCheckbox label="Click" className="mr-5" />
            <KooperFormDropdown
              className="mr-3 ml-5"
              placeholder="Add"
              name="operator"
              options={[
                { value: "Add", text: "Add", key: "Add", icon: "add" },
                {
                  value: "Subtract",
                  text: "Subtract",
                  key: "Subtract",
                  icon: "minus",
                },
              ]}
            />
            <KooperFormInput style={{ width: "50px" }} type="text" />

            <span style={{ marginTop: "3px" }}>Point(s)</span>
          </KooperFormGroup>

          <KooperFormGroup inline>
            <KooperCheckbox label="Bounce" className="mr-5" />
            <KooperFormDropdown
              className="mr-3 ml-5"
              placeholder="Add"
              name="operator"
              options={[
                { value: "Add", text: "Add", key: "Add", icon: "add" },
                {
                  value: "Subtract",
                  text: "Subtract",
                  key: "Subtract",
                  icon: "minus",
                },
              ]}
            />
            <KooperFormInput style={{ width: "50px" }} type="text" />

            <span style={{ marginTop: "3px" }}>Point(s)</span>
          </KooperFormGroup> */}
        </KooperFormField>
      </KooperForm>

      <div className="set-action-btn">
        <KooperButton basic onClick={() => cancelData()}>
          Cancel
        </KooperButton>
        <LockPermissionTooltip
          isRoleAccessDenied={!manageScoringRulesPermissions}
          isFeatureAccessDenied={!scoringRulesAccess}
        >
          <KooperButton
            primary
            {...(manageScoringRulesPermissions &&
              scoringRulesAccess && {
                onClick: handleSubmit(submitData),
              })}
            className={!manageScoringRulesPermissions || !scoringRulesAccess ? 'disabled-button' : ''}
            disabled={!isDirty || (isLoadingData && type.includes(UPDATE_SCORING_RULES_INSIGHTS))}
          >
            Update
          </KooperButton>
        </LockPermissionTooltip>
      </div>
    </KooperContainer>
  )
}

export default EmailInsightsList
