import React from 'react'
import {useSelector} from 'react-redux'
import {FiCheckCircle} from 'react-icons/fi'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCardDescription,
  KooperCardHeader,
  KooperCardMeta,
  KooperGridColumn,
  KooperMenu,
  KooperMenuItem,
} from 'kooper-library'
import {get, startCase} from 'utils/helper'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'

const getPlanTime = plan => {
  if (plan.includes('Yearly')) {
    return '/user/year'
  }
  return '/user/month'
}

const getPlanDescription = planName => {
  if (planName === 'standard') {
    return 'Lorem Ipsum is simply dummy text'
  }
  if (planName === 'business') {
    return 'Lorem Ipsum is simply dummy text'
  }
  if (planName === 'ultimate') {
    return 'Lorem Ipsum is simply dummy text'
  }
  return null
}

const getPlanFeatureContent = planName => {
  if (planName === 'standard') {
    return 'Get Started with'
  }
  if (planName === 'business') {
    return 'All Standard Features and'
  }
  if (planName === 'ultimate') {
    return 'All Business Features and'
  }
  return null
}

const PlanList = props => {
  const {subInterval, setPaymentData, setStep, rolePermissions} = props
  const {getKooperPlansData = {}} = useSelector(state => state.billing)

  const renderPlanCard = plan => {
    return (
      <KooperCard fluid className="planBar">
        <KooperCardContent>
          <KooperCardHeader>{startCase(get(['name'], plan, ''))}</KooperCardHeader>
          <KooperCardMeta style={{margin: '0 0 18px'}}>{getPlanDescription(plan.name)}</KooperCardMeta>
          <KooperButton style={{margin: '0 0 18px'}} className="basic-fill-btn" fluid>
            Choose Plan
          </KooperButton>
          <span>$ {get(['amount'], plan, '-')}</span> {getPlanTime(get(['plan'], plan, ''))}
        </KooperCardContent>
        <KooperCardContent extra>
          <KooperCardDescription>{getPlanFeatureContent(plan.name)}</KooperCardDescription>
          <KooperMenu secondary vertical fluid>
            {plan.features.map(feature => (
              <KooperMenuItem>
                <FiCheckCircle /> {feature}
              </KooperMenuItem>
            ))}
          </KooperMenu>
        </KooperCardContent>
        <KooperCardContent extra>
          Reporting, automation and <br /> integrations for support teams
        </KooperCardContent>
      </KooperCard>
    )
  }

  return get(['plans', subInterval], getKooperPlansData, []).map(plan => {
    return (
      <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
        <KooperGridColumn
          onClick={() => {
            if (rolePermissions) {
              setPaymentData(paymentData => ({...paymentData, plan: plan.plan}))
              setStep('step2')
            }
          }}
        >
          {renderPlanCard(plan)}
        </KooperGridColumn>
      </LockPermissionTooltip>
    )
  })
}

export default PlanList
