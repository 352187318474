import React from 'react'
import {KooperModal, KooperModalHeader, KooperModalActions, KooperButton, KooperRadio, KooperModalContent} from 'kooper-library'

const ExportLeadModal = props => {
  const {isModalOpen, setIsModalOpen} = props

  return (
    <KooperModal open={isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)}>
      <KooperModalHeader>Export Contacts</KooperModalHeader>
      <KooperModalContent style={{display: 'grid'}}>
        <KooperRadio name="export" label="Export all selected Contacts (1 Lead selected)" />
        <KooperRadio name="export" label="Export all Contacts" className="my-4" />
        <KooperRadio name="export" label="Export all Contacts that match this filter" />
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </KooperButton>
        <KooperButton color="blue">Export</KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default ExportLeadModal
