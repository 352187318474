import SvgIcon from 'components/common/SvgIcon'
import {KooperAccordion, KooperAccordionContent, KooperAccordionTitle, KooperMenu, KooperMenuItem} from 'kooper-library'
import {useState} from 'react'
import {FiChevronRight, FiLayers} from 'react-icons/fi'
import {useHistory} from 'react-router-dom'
import {isActiveNavlink} from 'utils/helper'

const goalsMenu = [
  {path: '/goals/all', title: 'All Goals', exact: true},
  {path: '/goals/organizational', title: 'Organizational', exact: false},
  {path: '/goals/team', title: 'Team', exact: false},
  {path: '/goals/personal', title: 'Personal', exact: false},
]
const GoalsMenu = () => {
  const history = useHistory()

  const [expand, setExpand] = useState([])

  const handleMenuExpand = key => {
    const menus = [...expand]
    if (menus.includes(key)) {
      menus.splice(menus.indexOf(key), 1)
      setExpand(menus)
    } else {
      setExpand([...menus, key])
    }
  }

  const isActive = key => !expand.includes(key)
  return (
    <KooperMenu vertical secondary className="kooper-menu">
      {goalsMenu.map(({path, title}) => (
        <KooperMenuItem key={path} active={isActiveNavlink(path)} onClick={() => history.push(path)}>
          <FiLayers /> {title}
        </KooperMenuItem>
      ))}

      <KooperAccordion>
        <KooperAccordionTitle onClick={() => handleMenuExpand('settings')}>
          Settings
          {isActive('settings') ? <SvgIcon path="common/down" /> : <FiChevronRight />}
        </KooperAccordionTitle>
        <KooperAccordionContent active={isActive('settings')}>
          <KooperMenuItem active={isActiveNavlink('/goals/settings')} onClick={() => history.push('/goals/settings')}>
            <SvgIcon path="finance/prefix" /> Settings
          </KooperMenuItem>
        </KooperAccordionContent>
      </KooperAccordion>
    </KooperMenu>
  )
}

export default GoalsMenu
