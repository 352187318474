import React from 'react'
import {AiFillLock} from 'react-icons/ai'
import {KooperTooltip} from 'kooper-library'
import {featureAccessDeniedContent} from 'constants/variables'

const LockFeature = () => {
  return <KooperTooltip content={featureAccessDeniedContent} position="top center" size="mini" trigger={<AiFillLock />} />
}

export default LockFeature
