import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCardDescription,
  KooperCardHeader,
  KooperCardMeta,
  KooperGrid,
  KooperGridColumn,
} from 'kooper-library'

import {createPaymentMethods, getBillingAddress, getPaymentMethods, updateBillingAddress} from 'actions/billing'
import {billingAddressSchema, cardDetailsSchema} from 'validation/Settings/billing.schema'
import {
  CREATE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  GET_BILLING_ADDRESS,
  UPDATE_BILLING_ADDRESS,
  UPDATE_PAYMENT_METHOD,
} from 'actions/types'
import useApiResponse from 'hooks/impure/useApiResponse'
import BillingInfoForm from './BillingInfoForm'
import SavedCardList from './SavedCardList'
import AddCardForm from './AddCardForm'
import CreditCardForm from './CreditCardForm'

const PlanStep3 = props => {
  const {setAddressValidation} = props

  const dispatch = useDispatch()

  const [addCard, setAddCard] = useState(false)

  const {successLabels = [], type = []} = useSelector(state => state.apiReducer)

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: {isDirty, isValid},
    clearErrors,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(billingAddressSchema),
    defaultValues: {
      city: '',
      businessName: '',
      country: '',
      line1: '',
      postalCode: '',
      state: '',
      isDefault: true,
    },
  })

  const {
    handleSubmit: handleSubmitNewCard,
    errors: errorsCard,
    control: controlCard,
    formState: {isDirty: isDirtyCard},
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(cardDetailsSchema),
    defaultValues: {
      number: '',
      expMonth: '',
      cvc: '',
      isDefault: true,
      cardHolderName: '',
    },
  })

  const {data: getBillingAddressData} = useApiResponse({
    action: getBillingAddress,
    enabled: true,
    label: GET_BILLING_ADDRESS,
    storePath: 'billing.getBillingAddressData',
    onSuccess: getBillingAddressData => {
      reset(getBillingAddressData)
    },
  })

  useEffect(() => {
    dispatch(getPaymentMethods())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_BILLING_ADDRESS)) {
      reset(getBillingAddressData)
    }
    if (
      successLabels.includes(CREATE_PAYMENT_METHOD) ||
      successLabels.includes(UPDATE_PAYMENT_METHOD) ||
      successLabels.includes(DELETE_PAYMENT_METHOD)
    ) {
      dispatch(getPaymentMethods())
    }
    if (successLabels.includes(CREATE_PAYMENT_METHOD)) {
      setAddCard(false)
    }
    if (successLabels.includes(UPDATE_BILLING_ADDRESS)) {
      dispatch(getBillingAddress())
    }
  }, [successLabels, reset, getBillingAddressData, dispatch])

  useEffect(() => {
    setAddressValidation(isValid)
  }, [isValid, setAddressValidation])

  const onSubmitBillingInfo = data => {
    const payload = {...data, postalCode: data.postalCode.toString()}
    dispatch(updateBillingAddress(payload))
  }

  const handleSubmitCard = data => {
    const payload = {...data}
    const [month, year] = data.expMonth.split('/')
    payload.expMonth = month
    payload.expYear = `20${year}`

    dispatch(createPaymentMethods(payload))
  }

  return (
    <KooperGrid>
      <KooperGridColumn width={11}>
        <KooperCard fluid className="switchBox">
          <KooperCardContent>
            <KooperCardHeader>Billing Information</KooperCardHeader>
            <KooperCardDescription className="mb-4">
              Provide your billing information for this plan update
            </KooperCardDescription>
            <BillingInfoForm control={control} errors={errors} clearErrors={clearErrors} />
            <KooperButton basic disabled={!isDirty} className="mr-3" onClick={() => reset(getBillingAddressData)}>
              Reset
            </KooperButton>
            <KooperButton primary disabled={!isDirty} onClick={handleSubmit(onSubmitBillingInfo)}>
              Update
            </KooperButton>
          </KooperCardContent>
        </KooperCard>

        <KooperCard fluid className="switchBox mt-4">
          <KooperCardContent>
            <KooperCardHeader>Card Information</KooperCardHeader>
            <KooperCardDescription className="mb-4">
              Provide your card information to pay for this selected plan and addons
            </KooperCardDescription>

            <h4>Saved Cards</h4>
            <SavedCardList />
            <KooperButton basic onClick={() => setAddCard(!addCard)}>
              Add New Card
            </KooperButton>

            {addCard && (
              <>
                <h5 className="mt-4">Add New Card</h5>
                <CreditCardForm onCancelClick={() => setAddCard(false)} />
                {/* <AddCardForm control={controlCard} errors={errorsCard} />

                <KooperButton className="mr-3" basic onClick={() => setAddCard(false)}>
                  Cancel
                </KooperButton>
                <KooperButton
                  primary
                  onClick={handleSubmitNewCard(handleSubmitCard)}
                  disabled={type.includes(CREATE_PAYMENT_METHOD) || !isDirtyCard}
                  loading={type.includes(CREATE_PAYMENT_METHOD)}
                >
                  Add
                </KooperButton> */}
              </>
            )}
          </KooperCardContent>
        </KooperCard>
      </KooperGridColumn>
    </KooperGrid>
  )
}

export default PlanStep3
