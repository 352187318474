/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {myActivities} from 'actions/home'
import {MY_ACTIVITIES} from 'actions/types'
import {FiChevronRight} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {getFormattedDate, startCase} from 'utils/helper'
import {
  KooperLabel,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'

function MyActivities() {
  const dispatch = useDispatch()

  const [activeAccordion, setActiveAccordion] = useState(-1)

  const {myActivitiesData = {}} = useSelector(state => state.home)
  const {dealData = [], projectTaskData = [], proofData = [], taskData = []} = myActivitiesData
  const {type = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    dispatch(myActivities())
  }, [dispatch])

  const renderTableBody = () => {
    if (type.includes(MY_ACTIVITIES)) {
      return <KooperTablePlaceholder columns={4} />
    }
    return (
      <>
        <KooperTableRow style={{cursor: 'pointer'}} onClick={() => setActiveAccordion(activeAccordion === 1 ? '-1' : 1)}>
          <KooperTableCell colspan="4" className="d-flex" style={{justifyContent: 'flex-start'}}>
            <h5 className="d-flex">
              {activeAccordion === 1 ? (
                <SvgIcon path="common/down" style={{marginRight: 8}} />
              ) : (
                <FiChevronRight style={{marginRight: 8}} />
              )}
              <div className="icon-wrapper">
                <SvgIcon path="navigation/projects" /> <span>Projects</span>
              </div>
            </h5>
          </KooperTableCell>
        </KooperTableRow>
        {projectTaskData?.length > 0 ? (
          projectTaskData?.map(({title, stageData, createdby, endDate, type, projectDetails}, index) => (
            <KooperTableRow
              key={index}
              style={{
                display: activeAccordion === 1 ? '' : 'none',
              }}
            >
              <KooperTableCell className="pl-4 d-flex" style={{justifyContent: 'flex-start'}}>
                <div className="myActivities-title">
                  <span>{startCase(title)}</span>
                  <div className="extra-field">
                    <KooperTooltip
                      content="Type"
                      size="mini"
                      position="top center"
                      trigger={
                        <KooperLabel className="activitiesButton" color="#988e8e">
                          {startCase(type)}
                        </KooperLabel>
                      }
                    />
                    <KooperTooltip
                      content="Project Title"
                      size="mini"
                      position="top center"
                      trigger={
                        <KooperLabel className="activitiesButton" color="#988e8e">
                          {startCase(projectDetails?.title)}
                        </KooperLabel>
                      }
                    />
                  </div>
                </div>
              </KooperTableCell>
              <KooperTableCell>{startCase(stageData?.name)}</KooperTableCell>
              <KooperTableCell>{getFormattedDate(endDate)}</KooperTableCell>
              <KooperTableCell>{createdby}</KooperTableCell>
            </KooperTableRow>
          ))
        ) : (
          <KooperTableRow
            style={{
              display: activeAccordion === 1 ? '' : 'none',
            }}
          >
            <KooperTableCell colSpan="4" className="emptyValue">
              No Data Available
            </KooperTableCell>
          </KooperTableRow>
        )}

        <KooperTableRow style={{cursor: 'pointer'}} onClick={() => setActiveAccordion(activeAccordion === 2 ? '-1' : 2)}>
          <KooperTableCell className="d-flex" style={{justifyContent: 'flex-start', borderTop: 'none'}}>
            <h5 className="d-flex">
              {activeAccordion === 2 ? (
                <SvgIcon path="common/down" style={{marginRight: 8}} />
              ) : (
                <FiChevronRight style={{marginRight: 8}} />
              )}
              <div className="icon-wrapper">
                <SvgIcon path="navigation/deals" /> <span>Deals</span>
              </div>
            </h5>
          </KooperTableCell>
        </KooperTableRow>
        {dealData?.length > 0 ? (
          dealData?.map(({title, stageData, createdby, endDate, monetaryValue}, index) => (
            <KooperTableRow
              key={index}
              style={{
                display: activeAccordion === 2 ? '' : 'none',
              }}
            >
              <KooperTableCell className="pl-4 d-flex" style={{justifyContent: 'flex-start'}}>
                <div className="myActivities-title">
                  <span>{startCase(title)}</span>

                  <KooperTooltip
                    content="Deals Value"
                    size="mini"
                    position="top center"
                    trigger={<KooperLabel color="#988e8e">{monetaryValue}</KooperLabel>}
                  />
                </div>
              </KooperTableCell>
              <KooperTableCell>{startCase(stageData?.stage)}</KooperTableCell>
              <KooperTableCell>{getFormattedDate(endDate)}</KooperTableCell>
              <KooperTableCell>{createdby}</KooperTableCell>
            </KooperTableRow>
          ))
        ) : (
          <KooperTableRow
            style={{
              display: activeAccordion === 2 ? '' : 'none',
            }}
          >
            <KooperTableCell colSpan="4" className="emptyValue">
              No Data Available
            </KooperTableCell>
          </KooperTableRow>
        )}

        <KooperTableRow style={{cursor: 'pointer'}} onClick={() => setActiveAccordion(activeAccordion === 3 ? '-1' : 3)}>
          <KooperTableCell className="d-flex" style={{justifyContent: 'flex-start', borderTop: 'none'}}>
            <h5 className="d-flex">
              {activeAccordion === 3 ? (
                <SvgIcon path="common/down" style={{marginRight: 8}} />
              ) : (
                <FiChevronRight style={{marginRight: 8}} />
              )}
              <div className="icon-wrapper">
                <SvgIcon path="navigation/tasks" /> <span>Tasks</span>
              </div>
            </h5>
          </KooperTableCell>
        </KooperTableRow>
        {taskData?.length > 0 ? (
          taskData?.map(({title, stage, createdby, endAt, type}, index) => (
            <KooperTableRow
              key={index}
              style={{
                display: activeAccordion === 3 ? '' : 'none',
              }}
            >
              <KooperTableCell className="pl-4 d-flex" style={{justifyContent: 'flex-start'}}>
                <div className="myActivities-title">
                  <span>{startCase(title)}</span>
                  <KooperTooltip
                    content="Type"
                    size="mini"
                    position="top center"
                    trigger={<KooperLabel color="#988e8e">{startCase(type)}</KooperLabel>}
                  />
                </div>
              </KooperTableCell>
              <KooperTableCell>{startCase(stage)}</KooperTableCell>
              <KooperTableCell>{getFormattedDate(endAt)}</KooperTableCell>
              <KooperTableCell>{createdby}</KooperTableCell>
            </KooperTableRow>
          ))
        ) : (
          <KooperTableRow
            style={{
              display: activeAccordion === 3 ? '' : 'none',
            }}
          >
            <KooperTableCell colSpan="4" className="emptyValue">
              No Data Available
            </KooperTableCell>
          </KooperTableRow>
        )}

        <KooperTableRow style={{cursor: 'pointer'}} onClick={() => setActiveAccordion(activeAccordion === 4 ? '-1' : 4)}>
          <KooperTableCell className="d-flex" style={{justifyContent: 'flex-start', borderTop: 'none'}}>
            <h5 className="d-flex">
              {activeAccordion === 4 ? (
                <SvgIcon path="common/down" style={{marginRight: 8}} />
              ) : (
                <FiChevronRight style={{marginRight: 8}} />
              )}
              <div className="icon-wrapper">
                <SvgIcon path="navigation/proofs" /> <span>Proofs</span>
              </div>
            </h5>
          </KooperTableCell>
        </KooperTableRow>
        {proofData?.length > 0 ? (
          proofData?.map(({title, stageData, createdby, dueDate, projectDetails}, index) => (
            <KooperTableRow
              key={index}
              style={{
                display: activeAccordion === 4 ? '' : 'none',
              }}
            >
              <KooperTableCell className="pl-4 d-flex" style={{justifyContent: 'flex-start'}}>
                <div className="myActivities-title">
                  <span>{startCase(title)}</span>
                  <KooperTooltip
                    content="Project Title"
                    size="mini"
                    position="top center"
                    trigger={<KooperLabel color="#988e8e">{startCase(projectDetails?.title)}</KooperLabel>}
                  />
                </div>
              </KooperTableCell>
              <KooperTableCell>{startCase(stageData?.name)}</KooperTableCell>
              <KooperTableCell>{getFormattedDate(dueDate)}</KooperTableCell>
              <KooperTableCell>{createdby}</KooperTableCell>
            </KooperTableRow>
          ))
        ) : (
          <KooperTableRow
            style={{
              display: activeAccordion === 4 ? '' : 'none',
            }}
          >
            <KooperTableCell colSpan="4" className="emptyValue">
              No Data Available
            </KooperTableCell>
          </KooperTableRow>
        )}
      </>
    )
  }

  return (
    <div className="myActivitiesBody">
      <div className="myActivityContent">
        <div className="pageBodyWrap" style={{marginTop: '20px', height: 'calc(100vh - 20.02vh)'}}>
          <KooperTable selectable sortable basic>
            <KooperTableHeader>
              <KooperTableRow>
                <KooperTableHeaderCell>Activities</KooperTableHeaderCell>
                <KooperTableHeaderCell>Stage</KooperTableHeaderCell>
                <KooperTableHeaderCell>Due Date</KooperTableHeaderCell>
                <KooperTableHeaderCell>Created By</KooperTableHeaderCell>
              </KooperTableRow>
            </KooperTableHeader>

            <KooperTableBody>{renderTableBody()}</KooperTableBody>
          </KooperTable>
        </div>
      </div>
    </div>
  )
}

export default MyActivities
