/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import _ from 'lodash'
import moment from 'moment'
import {
  KooperButton,
  KooperCheckbox,
  KooperDatePicker,
  KooperForm,
  KooperFormDropdown,
  KooperFormField,
  KooperGrid,
  KooperGridColumn,
  KooperInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperRadio,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {GET_ALL_COMPANIES, GET_STATIC_CONTACT} from 'actions/types'
import {getStaticSegmentContactList} from 'actions/segment'
import {getCompanyList} from 'actions/company'
import {get, KooperCountryList} from 'utils/helper'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import SvgIcon from 'components/common/SvgIcon'

const convertIsoformat = ['lastContactedFrom', 'lastContactedTo', 'createdAtFrom', 'createdAtTo']

const SegmentStep2Companies = props => {
  const {
    open,
    setOpen,
    CreateSegment,
    handleSubmit,
    errors,
    reset,
    control,
    getValues,
    watch,
    setValue,
    step1Values,
    filter,
    setFilter,
    editId,
  } = props
  const dispatch = useDispatch()
  const watchedValues = watch()

  const [assigneeData, setAssigneeData] = useState([])
  const [tagLabelData, setTagLabelData] = useState([])
  const [typeList, setTypeList] = useState([])
  const [numberOfPage, setNumberOfPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const initialCurrentListofFilter = {
    assigneeId: null,
    labelIds: null,
    isFollowed: null,
    interactionsFrom: '',
    interactionsTo: '',
    lastContactedFrom: null,
    lastContactedTo: null,
    inactiveDaysFrom: '',
    inactiveDaysTo: '',
    createdAtFrom: null,
    createdAtTo: null,
    contactTypeId: null,
    country: null,
    locality: '',
    state: '',
    postalCode: '',
  }

  const {getTypeListData} = useSelector(state => state.contacts)
  const {getStaticSegmentData: {companies: contacts = []} = {}} = useSelector(state => state.segment)
  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)

  const step1 = step1Values()

  const getTagList = useSelector(state => {
    return get(['multipleFields', 'getTagList'], state)
  })
  const {getUsersList = {}} = useSelector(state => state.settings)
  const {getAllCompanyList = [], companyPage: pages = []} = useSelector(state => state.companies)

  useEffect(() => {
    if (getTypeListData) {
      const companyList = getTypeListData.map(list => ({
        key: list.id,
        value: list.id,
        text: list.type,
      }))
      setTypeList(companyList)
    }
  }, [getTypeListData])

  useEffect(() => {
    if (getTagList) {
      const tagList = getTagList.map(list => ({
        key: list.id,
        value: list.id,
        text: list.label,
      }))
      setTagLabelData(tagList)
    }
  }, [getTagList])
  useEffect(() => {
    if (getUsersList) {
      const assigneeList = getUsersList.users.map(list => ({
        key: list.id,
        value: list.id,
        text: `${list.firstName} ${list.lastName}`,
      }))
      setAssigneeData(assigneeList)
    }
  }, [getUsersList])

  const applyFilterBtn = data => {
    const newData = _.pickBy(data.conditions, _.identity)
    convertIsoformat.map(item => {
      if (Object.keys(newData).includes(item)) {
        newData[item] = newData[item].toISOString()
      }
    })
    if (!_.isEmpty(newData)) {
      setFilter(newData)
      setValue('ids', [])
    }
  }
  const handleCheck = id => {
    const isChecked = watchedValues.ids.some(lead => lead === id)

    if (isChecked) {
      const removeChecked = watchedValues.ids.filter(lead => lead !== id)
      setValue('ids', removeChecked)
    } else {
      setValue('ids', [...watchedValues.ids, id])
    }
  }

  const isChecked = id => {
    const check = watchedValues.ids.find(lead => lead === id)
    if (check) {
      return true
    }
    return false
  }

  const renderContactList = () => {
    return contacts.map((item, i) => (
      <KooperTableRow key={item.id} className="tableLink">
        {step1.isStatic && (
          <KooperTableCell key="checkbox" onClick={e => e.stopPropagation()}>
            <KooperTooltip
              size="mini"
              position="bottom center"
              content="Select"
              trigger={
                <KooperCheckbox
                  id={`${item.id}segment`}
                  className="d-inline-block"
                  onChange={() => handleCheck(item.id)}
                  checked={isChecked(item.id)}
                />
              }
            />
          </KooperTableCell>
        )}
        <KooperTableCell>{get(['name'], item, '')}</KooperTableCell>
        <KooperTableCell>{get(['email'], item, '')}</KooperTableCell>

        <KooperTableCell>
          {get(['interactionTime'], item) && moment(get(['interactionTime'], item, '').format('DD MMM'))}
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  const isCheckedAll = () => {
    const allIds = (getAllCompanyList || []).map(lead => lead.id)

    const checkAll = _.xor(allIds, watchedValues.ids).length === 0 && allIds.length !== 0
    if (checkAll) {
      return true
    }
    return false
  }

  const handleCheckAll = () => {
    const allIds = getAllCompanyList.map(lead => lead.id)

    if (_.xor(allIds, watchedValues.ids).length === 0) {
      setValue('ids', [])
    } else setValue('ids', allIds)
  }

  const resetFilterBtn = () => {
    setFilter({})
    reset({ids: [], conditions: initialCurrentListofFilter})
    dispatch({type: 'CLEAR_STATIC_CONTACT'})
  }

  useEffect(() => {
    const newData = _.pickBy(filter, _.identity)
    if (!_.isEmpty(newData)) {
      dispatch(getStaticSegmentContactList(props.type, newData))
    } else {
      dispatch(getCompanyList({page: currentPage}))
      dispatch({type: 'CLEAR_STATIC_CONTACT'})
    }
  }, [filter, currentPage])

  useEffect(() => {
    if (successLabels.includes(GET_ALL_COMPANIES)) {
      setCurrentPage(pages.page)
      setNumberOfPage(pages.pages)
    }
  }, [successLabels, pages])

  const renderList = list => {
    return list.map((item, i) => (
      <KooperTableRow key={item.id} className="tableLink">
        {step1.isStatic && (
          <KooperTableCell key="checkbox" onClick={e => e.stopPropagation()}>
            <KooperTooltip
              size="mini"
              position="bottom center"
              content="Select"
              trigger={
                <KooperCheckbox
                  id={`${item.id}segment`}
                  className="d-inline-block"
                  onChange={() => handleCheck(item.id)}
                  checked={isChecked(item.id)}
                />
              }
            />
          </KooperTableCell>
        )}
        <KooperTableCell>{get(['name'], item, '')}</KooperTableCell>
        <KooperTableCell>{get(['emails', [0], 'email'], item, '--')}</KooperTableCell>

        <KooperTableCell>
          {get(['interactionTime'], item) ? moment(item.interactionTime).format('DD MMM') : '--'}
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  const renderBody = () => {
    if (type.includes(GET_ALL_COMPANIES) || type.includes(GET_STATIC_CONTACT)) {
      return <KooperTablePlaceholder />
    }
    if ((_.isEmpty(filter) && getAllCompanyList.length === 0) || (!_.isEmpty(filter) && contacts.length === 0)) {
      return (
        <tr>
          <td className="emptyValue" colSpan={step1.isStatic ? '4' : '3'}>
            No Data Available
          </td>
        </tr>
      )
    }
    if (_.isEmpty(filter) && !type.includes(GET_ALL_COMPANIES)) {
      return renderList(getAllCompanyList)
    }
    return renderList(contacts)
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setOpen(false)}
      open={open}
      size="large"
    >
      <KooperModalHeader>Create List</KooperModalHeader>
      <KooperModalContent className="kooper-seg-wrap">
        <div className="actBar">
          <h4 className="mb-3">Filter for Company</h4>
          <KooperForm>
            <KooperFormField>
              <label>Assignee</label>
              <Controller
                name="conditions.assigneeId"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormDropdown
                    placeholder="Assignee"
                    selection
                    search
                    fluid
                    clearable
                    options={assigneeData}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    value={value}
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField>
              <label>Tag</label>
              <Controller
                control={control}
                name="conditions.labelIds"
                render={({onChange, value}) => (
                  <KooperFormDropdown
                    placeholder="Tags"
                    options={tagLabelData}
                    search
                    fluid
                    selection
                    clearable
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField>
              <label>Followed</label>
              <Controller
                control={control}
                name="conditions.isFollowed"
                render={({onChange, value}) => (
                  <>
                    <KooperRadio
                      label="Followed"
                      className="mr-4"
                      name="radioGroup"
                      value="true"
                      checked={value === 'true'}
                      onChange={() => {
                        onChange('true')
                      }}
                    />
                    <KooperRadio
                      label="Not Followed"
                      name="radioGroup"
                      value="false"
                      checked={value === 'false'}
                      onChange={() => {
                        onChange('false')
                      }}
                    />
                  </>
                )}
              />
            </KooperFormField>
            <KooperFormField>
              <label>Interactions</label>
              <KooperGrid>
                <KooperGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.interactionsFrom"
                    render={({onChange, value}) => (
                      <KooperInput
                        fluid
                        label={{content: 'From', basic: true}}
                        onChange={(e, {value}) => onChange(value)}
                        value={value}
                        error={
                          errors?.interactionsFrom && {
                            content: errors?.interactionsFrom?.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperGridColumn>

                <KooperGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.interactionsTo"
                    render={({onChange, value}) => (
                      <KooperInput
                        fluid
                        label={{content: 'To', basic: true}}
                        onChange={(e, {value}) => onChange(value)}
                        value={value}
                        error={
                          errors?.interactionsTo && {
                            content: errors?.interactionsTo?.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperGridColumn>
              </KooperGrid>
            </KooperFormField>
            <KooperFormField>
              <label>Last Contacted</label>
              <KooperGrid>
                <KooperGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.lastContactedFrom"
                    render={({onChange, value}) => (
                      <KooperDatePicker
                        placeholder="Date"
                        time={false}
                        onChange={value => {
                          onChange(value)
                        }}
                        value={typeof value === 'string' ? new Date(value) : value}
                        max={new Date()}
                      />
                    )}
                  />
                </KooperGridColumn>
                <KooperGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.lastContactedTo"
                    render={({onChange, value}) => (
                      <KooperDatePicker
                        placeholder="Date"
                        time={false}
                        onChange={value => {
                          onChange(value)
                        }}
                        value={value}
                        min={getValues('lastContactedFrom')}
                        max={new Date()}
                      />
                    )}
                  />
                </KooperGridColumn>
              </KooperGrid>
            </KooperFormField>
            <KooperFormField>
              <label>Inactive Days</label>
              <KooperGrid>
                <KooperGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.inactiveDaysFrom"
                    render={({onChange, value}) => (
                      <KooperInput
                        placeholder="Days"
                        fluid
                        label={{content: 'From', basic: true}}
                        onChange={(e, {value}) => onChange(value)}
                        value={value}
                        error={
                          errors?.inactiveDaysFrom && {
                            content: errors?.inactiveDaysFrom?.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperGridColumn>
                <KooperGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.inactiveDaysTo"
                    render={({onChange, value}) => (
                      <KooperInput
                        placeholder="Days"
                        fluid
                        label={{content: 'To', basic: true}}
                        onChange={(e, {value}) => onChange(value)}
                        value={value}
                        error={
                          errors?.inactiveDaysTo && {
                            content: errors?.inactiveDaysTo?.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperGridColumn>
              </KooperGrid>
            </KooperFormField>
            <KooperFormField>
              <label>Created At</label>
              <KooperGrid>
                <KooperGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.createdAtFrom"
                    render={({onChange, value}) => (
                      <KooperDatePicker
                        placeholder="Date"
                        time={false}
                        onChange={value => {
                          onChange(value)
                        }}
                        value={value}
                        max={new Date()}
                      />
                    )}
                  />
                </KooperGridColumn>
                <KooperGridColumn width={8}>
                  <Controller
                    control={control}
                    name="conditions.createdAtTo"
                    render={({onChange, value}) => (
                      <KooperDatePicker
                        placeholder="Date"
                        time={false}
                        onChange={value => {
                          onChange(value)
                        }}
                        value={value}
                        min={getValues('createdAtFrom')}
                        max={new Date()}
                      />
                    )}
                  />
                </KooperGridColumn>
              </KooperGrid>
            </KooperFormField>
            <KooperFormField>
              <label>Contact Type</label>
              <Controller
                control={control}
                name="conditions.contactTypeId"
                render={({onChange, value}) => (
                  <KooperFormDropdown
                    placeholder="Contact Type"
                    options={typeList}
                    fluid
                    search
                    clearable
                    selection
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField>
              <label>Country</label>
              <Controller
                control={control}
                name="conditions.country"
                render={({onChange, value}) => (
                  <KooperFormDropdown
                    placeholder="Country"
                    search
                    fluid
                    clearable
                    options={KooperCountryList}
                    onChange={(e, {value}) => onChange(value)}
                    value={value}
                    selection
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField>
              <label>Locality</label>
              <Controller
                control={control}
                name="conditions.locality"
                render={({onChange, value}) => (
                  <KooperInput placeholder="Locality" value={value} onChange={(e, {value}) => onChange(value)} />
                )}
              />
            </KooperFormField>
            <KooperFormField>
              <label>State</label>
              <Controller
                control={control}
                name="conditions.state"
                render={({onChange, value}) => (
                  <KooperInput placeholder="State" value={value} onChange={(e, {value}) => onChange(value)} />
                )}
              />
            </KooperFormField>
            <KooperFormField>
              <label>Postal Code</label>
              <Controller
                control={control}
                name="conditions.postalCode"
                render={({onChange, value}) => (
                  <KooperInput
                    placeholder="Postal Code"
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors?.postalCode && {
                        content: errors?.postalCode?.message,
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
          </KooperForm>

          <KooperGrid className="mt-4">
            <KooperGridColumn width={8}>
              <KooperButton basic fluid onClick={() => resetFilterBtn()}>
                Reset Filter
              </KooperButton>
            </KooperGridColumn>
            <KooperGridColumn width={8}>
              <KooperButton fluid color="primary" onClick={handleSubmit(applyFilterBtn)}>
                Apply Filter
              </KooperButton>
            </KooperGridColumn>
          </KooperGrid>
        </div>
        <div className="seg-list-body">
          <h4>Companies ({contacts.length})</h4>
          <KooperTable basic>
            <KooperTableHeader>
              <KooperTableRow>
                {step1.isStatic && (
                  <KooperTableHeaderCell>
                    <KooperTooltip
                      size="mini"
                      position="bottom center"
                      content="Select All"
                      trigger={
                        <KooperCheckbox id="ckbSegment" label="" checked={isCheckedAll()} onChange={handleCheckAll} />
                      }
                    />
                  </KooperTableHeaderCell>
                )}
                <KooperTableHeaderCell className="prevCell">Name</KooperTableHeaderCell>

                <KooperTableHeaderCell>Email</KooperTableHeaderCell>

                <KooperTableHeaderCell>Last Interaction time</KooperTableHeaderCell>
              </KooperTableRow>
            </KooperTableHeader>
            <KooperTableBody>{renderBody()}</KooperTableBody>
          </KooperTable>
          <KooperCustomPagination
            totalPages={numberOfPage}
            activePage={currentPage}
            onPageChange={page => {
              setCurrentPage(sort => ({...sort, page}))
            }}
          />
        </div>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton onClick={() => setOpen('step1')} basic>
          Back
        </KooperButton>
        <KooperButton content={editId ? 'Update' : 'Create'} onClick={handleSubmit(CreateSegment)} primary />
      </KooperModalActions>
    </KooperModal>
  )
}

export default SegmentStep2Companies
