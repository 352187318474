/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {KooperButton, KooperIcon, KooperLabel, KooperTab, KooperTooltip} from 'kooper-library'
import {
  completeProjectMilestone,
  deleteProjectMilestone,
  getAllProjectMilestone,
  getSingleProejctMilestone,
} from 'actions/projects'
import _ from 'lodash'
import {COMPLETE_PROJECT_MILESTONE, COPY_PROJECT_MILESTONE, DELETE_PROJECT_MILESTONE} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import {getPermissionTooltipContent, milestoneStatusColor} from 'utils/helper'
import CreateFirst from 'components/common/CreateFirst'
import SvgIcon from 'components/common/SvgIcon'
import {PROJECTS_PATH} from 'constants/path'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import {roleAccessDeniedContent} from 'constants/variables'
import MilestoneModal from './MilestoneModal'

function MilestonesList(props) {
  const {projectId, rolePermissions} = props
  const dispatch = useDispatch()
  const history = useHistory()

  const [isMilestoneOpen, setIsMilestoneOpen] = useState(false)
  const [editId, setEditId] = useState()
  const [deleteModal, setDeleteModal] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [currentListParams, setCurrentListParams] = useState({
    type: '',
    term: '',
  })

  const {getMilestoneList = [], getMilestoneCounts} = useSelector(state => state.projects)
  const {successLabels = []} = useSelector(state => state.apiReducer)

  const steps = [
    {name: 'All', path: 'all', color: '#3b93f7', count: getMilestoneCounts?.total},
    {name: 'Upcoming', path: 'upcoming', color: '#ffc486', count: getMilestoneCounts?.upcoming},
    {name: 'Late', path: 'late', color: '#ff6562', count: getMilestoneCounts?.late},
    {name: 'Completed', path: 'completed', color: '#59c894', count: getMilestoneCounts?.completed},
  ]

  useEffect(() => {
    if (
      successLabels.includes(DELETE_PROJECT_MILESTONE) ||
      successLabels.includes(COPY_PROJECT_MILESTONE) ||
      successLabels.includes(COMPLETE_PROJECT_MILESTONE)
    ) {
      dispatch(getAllProjectMilestone({projectId}))
    }
  }, [successLabels, dispatch, projectId])

  const renderMilestoneModal = () => {
    if (isMilestoneOpen) {
      return (
        <MilestoneModal
          projectId={projectId}
          setIsMilestoneOpen={setIsMilestoneOpen}
          isMilestoneOpen={isMilestoneOpen}
        />
      )
    }
  }
  useEffect(() => {
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getAllProjectMilestone({projectId, params: newCurrentListParams}))
  }, [currentListParams, dispatch, projectId])

  if (getMilestoneCounts?.total === 0) {
    return (
      <div className="pageBodyWrap">
        {renderMilestoneModal()}
        <CreateFirst
          header="Milestones"
          subHeader="Milestones are key checkpoints or events in a project timeline that mark the completion of major goals"
          addFirst={() => rolePermissions && setIsMilestoneOpen(true)}
          buttonText="Create Milestone"
          tooltip={!rolePermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: rolePermissions})}
          Feature1="Milestone Creation"
          Feature2="Timeline"
          Feature3="Assign Milestone"
          list1="Create milestones to mark important events or achievements in your project timeline"
          list2="Set deadlines and track progress to ensure timely completion."
          list3="Assign Milestone to team members and monitor their progress"
        />
      </div>
    )
  }

  return (
    <>
      <div className="milestone-body">
        <div className="milestone-page-header">
          <div className="milestone-action">
            <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
              <KooperButton
                onClick={() => rolePermissions && setIsMilestoneOpen(true)}
                className={!rolePermissions ? 'disabled-button mr-2' : 'mr-2'}
                primary
              >
                Create Milestone
              </KooperButton>
            </LockPermissionTooltip>
          </div>
        </div>
        <KooperTab
          panes={steps.map(item => ({
            menuItem: {
              key: item.name,
              content: (
                <>
                  <span>{item.name}</span>
                  <span className="w-page-tabs ml-2">
                    <KooperLabel style={{background: item.color}}>{item.count}</KooperLabel>
                  </span>
                </>
              ),
            },
            render: null,
          }))}
          activeIndex={activeIndex}
          menu={{color: 'blue', secondary: true, pointing: true}}
          onTabChange={(e, {activeIndex}) => {
            setActiveIndex(activeIndex)
            setCurrentListParams({
              ...currentListParams,
              type:
                activeIndex === 1 ? 'upcoming' : activeIndex === 2 ? 'late' : activeIndex === 3 ? 'completed' : 'all',
            })
          }}
        />
        {getMilestoneList?.length > 0 ? (
          getMilestoneList?.map(({id, name, dueDetails, isCompleted, status, description}, index) => {
            return (
              <div className="w-milestone-row">
                <div className="w-milestone-row__date">
                  <div className="w-milestone-date">
                    <span className="w-milestone-date__month" style={milestoneStatusColor(status, isCompleted)}>
                      {dueDetails?.monthName}
                    </span>
                    <span className="w-milestone-date__day">
                      <span className="w-milestone-date__day-numeric">{dueDetails?.date}</span>
                      <span className="w-milestone-date__day-label">{dueDetails?.dayName}</span>
                    </span>
                  </div>
                </div>
                <div className="w-milestone-row__base ">
                  <div className="w-milestone-row__completion">
                    <KooperTooltip
                      size="mini"
                      position="top center"
                      content={isCompleted ? 'Mark milestone incomplete' : 'Mark milestone complete'}
                      trigger={
                        <button
                          className="w-completion-check"
                          onClick={e => {
                            e.stopPropagation()
                            const data = {isCompleted: !isCompleted}
                            dispatch(completeProjectMilestone({projectId, milestoneId: id, data}))
                          }}
                        >
                          <span className={`w-completion-check__holder ${isCompleted ? 'completed' : ''}`}>
                            <SvgIcon path="common/check" />
                          </span>
                        </button>
                      }
                    />
                  </div>

                  <div className="w-milestone-row__content">
                    <div className="w-milestone-row__title">
                      <h4
                        onClick={() => {
                          history.push(`${PROJECTS_PATH.MILESTONES}/${id}`)
                          dispatch(getSingleProejctMilestone({milestoneId: id, projectId}))
                        }}
                      >
                        {name}
                      </h4>
                    </div>
                    <p className="w-milestone-row__meta">You are responsible</p>
                    <div className="w-milestone-row__description">{description}</div>
                    <ul className="w-milestone-row__actions w-action-links">
                      <li>
                        {/* <KooperButton
                          onClick={() => {
                            dispatch(getSingleProejctMilestone({milestoneId: id, projectId}))
                            setIsMilestoneOpen(true)
                          }}
                          className="btn"
                        >
                          <KooperIcon name="edit" />
                          Edit
                        </KooperButton> */}

                        <KooperTooltip
                          content={!rolePermissions ? roleAccessDeniedContent : 'Edit'}
                          position="top center"
                          size="mini"
                          trigger={
                            <KooperButton
                              icon
                              onClick={() => {
                                if (rolePermissions) {
                                  dispatch(getSingleProejctMilestone({milestoneId: id, projectId}))
                                  setIsMilestoneOpen(true)
                                }
                              }}
                              className="btn"
                            >
                              <SvgIcon path="common/edit2" />
                            </KooperButton>
                          }
                        />
                      </li>
                      <li>
                        {/* <KooperButton
                          className="btn"
                          onClick={() => {
                            setEditId(id)
                            setDeleteModal(true)
                          }}
                        >
                          <KooperIcon name="trash alternate" /> Delete
                        </KooperButton> */}

                        <KooperTooltip
                          content={!rolePermissions ? roleAccessDeniedContent : 'Delete'}
                          position="top center"
                          size="mini"
                          trigger={
                            <KooperButton
                              icon
                              className="btn"
                              onClick={() => {
                                if (rolePermissions) {
                                  setEditId(id)
                                  setDeleteModal(true)
                                }
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </KooperButton>
                          }
                        />
                      </li>
                      <li>
                        <KooperButton
                          className="btn"
                          onClick={() => {
                            history.push(`${PROJECTS_PATH.MILESTONES}/${id}`)
                            dispatch(getSingleProejctMilestone({milestoneId: id, projectId}))
                          }}
                        >
                          <KooperIcon name="magnify" /> Comments
                        </KooperButton>
                      </li>
                      {/* <li>
                      <KooperButton className="btn">
                        <KooperIcon name="tasks" /> Attach task list
                      </KooperButton>
                    </li> */}
                    </ul>
                  </div>

                  <div className="w-milestone-row__utilities">
                    {isCompleted ? (
                      <KooperLabel style={milestoneStatusColor(status, isCompleted)}>Completed</KooperLabel>
                    ) : (
                      <KooperLabel style={milestoneStatusColor(status, isCompleted)}>
                        {dueDetails?.dueLabel}
                      </KooperLabel>
                    )}
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div className="no-Milestone">
            <h3 className="emptyValue">No Data Available</h3>
          </div>
        )}
      </div>
      {renderMilestoneModal()}
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          onDelete={() => dispatch(deleteProjectMilestone({milestoneId: editId, projectId}))}
          type={DELETE_PROJECT_MILESTONE}
        />
      )}
    </>
  )
}

export default MilestonesList
