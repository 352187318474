import SvgIcon from 'components/common/SvgIcon'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperDatePicker,
  KooperDropdown,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormTextArea,
  KooperTooltip,
} from 'kooper-library'
import {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import KeyResultCreatCard from './KeyResultCreatCard'
import AlignmentCreatCard from './AlignmentCreatCard'

const CreateTeamGoals = () => {
  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    // resolver: joiResolver(AnnouncementSchema),
    defaultValues: {},
  })
  const [keyResults, setKeyResults] = useState(0)
  return (
    <>
      <KooperCard fluid>
        <KooperCardContent>
          <KooperFormGroup>
            <KooperFormField width={8} required>
              <div className="info-header">
                <label>Team Goal Title</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Team Goal Title"
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="title"
                control={control}
                render={({onChange, value}) => <KooperFormInput maxLength={20} placeholder="Enter team goal title" />}
              />
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormGroup>
            <KooperFormField width={8}>
              <div className="info-header">
                <label>Team Goal Description</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Team Goal Description"
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="description"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormTextArea maxLength={100} placeholder="Enter team goal description" />
                )}
              />
            </KooperFormField>
          </KooperFormGroup>
        </KooperCardContent>
      </KooperCard>
      <KooperCard fluid>
        <KooperCardContent>
          <KooperForm>
            <KooperFormGroup>
              <KooperFormField required width={8}>
                <div className="info-header">
                  <label>Team</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select team"
                    size="mini"
                    position="top center"
                  />
                </div>
                <KooperDropdown
                  placeholder="Select a team"
                  fluid
                  selection
                  options={[
                    {key: 'engineering', text: 'Engineering', value: 'engineering'},
                    {key: 'design', text: 'Design', value: 'design'},
                    {key: 'marketing', text: 'Marketing', value: 'marketing'},
                  ]}
                />
              </KooperFormField>
              <KooperFormField width={8}>
                <div className="info-header">
                  <label>Goal Owner</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select a goal owner"
                    size="mini"
                    position="top center"
                  />
                </div>
                <KooperDropdown
                  placeholder="Select goal owner"
                  fluid
                  selection
                  defaultValue="jordan"
                  options={[
                    {key: 'jordan', text: 'Jordan', value: 'jordan'},
                    {key: 'alex', text: 'Alex', value: 'alex'},
                    {key: 'sam', text: 'Sam', value: 'sam'},
                  ]}
                />
              </KooperFormField>
            </KooperFormGroup>
            <KooperFormField>
              <KooperFormGroup>
                <KooperFormField width={8}>
                  <div className="info-header">
                    <label>Timeline Start date</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Timeline start date"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <KooperDatePicker time={false} />
                </KooperFormField>
                <KooperFormField width={8}>
                  <div className="info-header">
                    <label>Timeline End date</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Timeline end date"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <KooperDatePicker time={false} />
                </KooperFormField>
              </KooperFormGroup>
            </KooperFormField>
          </KooperForm>
        </KooperCardContent>
      </KooperCard>
      <KeyResultCreatCard control={control} />
      <AlignmentCreatCard control={control} />
    </>
  )
}

export default CreateTeamGoals
