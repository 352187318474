import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {FaFacebookSquare, FaTwitterSquare, FaLinkedin} from 'react-icons/fa'
import {KooperDropdownMenu, KooperDropdownItem, KooperDropdown} from 'kooper-library'
import {removeSocial} from 'actions/multiple_fields'

const IconList = props => {
  const [socialSetting, setSocialSetting] = useState(false)
  const {social, setSocialEdit, socialEdit, setSelectedSocial, id, identifier} = props
  const dispatch = useDispatch()

  const renderIcons = category => {
    if (category === 'facebook') {
      return <FaFacebookSquare />
    }
    if (category === 'twitter') {
      return <FaTwitterSquare />
    }
    if (category === 'linkedin') {
      return <FaLinkedin />
    }
  }

  return (
    <KooperDropdown
      direction="right"
      isOpen={socialSetting}
      onClose={() => setSocialSetting(!socialSetting)}
      icon={renderIcons(social.category)}
    >
      <KooperDropdownMenu>
        <KooperDropdownItem
          onClick={() => {
            setSelectedSocial(social)
            setSocialEdit(!socialEdit)
          }}
        >
          Edit
        </KooperDropdownItem>
        <KooperDropdownItem>
          <a href={`https://${social.url}`} target="_blank" rel="noreferrer">
            Go to Profile
          </a>
        </KooperDropdownItem>
        <KooperDropdownItem
          onClick={() => {
            dispatch(removeSocial(id, social.id, identifier))
          }}
        >
          Remove
        </KooperDropdownItem>
      </KooperDropdownMenu>
    </KooperDropdown>
  )
}
export default IconList
