import React from 'react'
import SvgIcon from 'components/common/SvgIcon'
import {
  KooperButton,
  KooperCheckbox,
  KooperHeader,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperFormField,
  KooperForm,
} from 'kooper-library'

const DeletePipelineModal = ({deleteDeal, setDeleteDeal, onDelete}) => {
  return (
    <KooperModal
      size="tiny"
      open={deleteDeal}
      centered={false}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={setDeleteDeal}
    >
      <KooperHeader >Delete Pipeline</KooperHeader>
      <KooperModalContent>
        <KooperForm>
          <KooperFormField>
            <KooperCheckbox label="Do you want to delete pipeline?" checked />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setDeleteDeal(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={onDelete}>
          Yes
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default DeletePipelineModal
