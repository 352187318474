/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {joiResolver} from '@hookform/resolvers'
import {createProjectFinanceBudget, getProjectFinanceBudget, updateProjectFinanceBudget} from 'actions/projects'
import React, {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {
  KooperButton,
  KooperCheckbox,
  KooperForm,
  KooperFormInput,
  KooperFormSelect,
  KooperInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'
import {GET_PROJECT_FINANCE_BUDGET} from 'actions/types'
import _ from 'lodash'
import {BiChevronDown, BiChevronUp} from 'react-icons/bi'
import {projectFinanceBudgetSchema} from 'validation/Projects/projects/finance.schema'
import {financeBudgetTypes, PROJECT_FINANCE_BUDGET_TYPES} from 'constants/projects'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import BudgetForm from './BudgetForm'
import FixedFeeBudgetForm from './FixedFeeBudgetForm'
import BudgetTypeForm from '../../../BudgetTypeForm'

const createProjectBudgetDefaultValues = {
  budgetType: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
  rangeType: 'financial',
  budgetAmount: 0,
  budgetTimeHour: 0,
  budgetTimeMinute: 0,
  startDate: new Date(),
  endDate: new Date(),
  timelogType: 'billable',
  repeatUnit: 'monthly',
  carryUnderspend: false,
  carryOverspend: false,
  isDefaultBillableRateSet: false,
  defaultBillableRate: 0,
  budgetProfitMargin: 0,
  budgetTargetProfit: 0,
  budgetTargetCosts: 0,
}

const CreateProjectBudgetModal = ({open, toggle, projectId, budgetId}) => {
  const dispatch = useDispatch()

  const [budgetTimeError, setBudgetTimeError] = useState('')
  const [isAdvanceOption, setIsAdvanceOption] = useState(false)
  const [isEnableNotificationChecked, setIsEnableNotificationChecked] = useState(false)

  const {handleSubmit, control, errors, watch, reset, setValue, getValues} = useForm({
    shouldUnregister: false,
    resolver: joiResolver(projectFinanceBudgetSchema),
    defaultValues: createProjectBudgetDefaultValues,
  })

  const watchBudgetType = watch('budgetType')
  const watchRangeType = watch('rangeType')
  const watchIsDefaultBillableRateSet = watch('isDefaultBillableRateSet')

  useApiResponse({
    action: getProjectFinanceBudget,
    payload: projectId,
    dependency: [projectId],
    enabled: projectId,
    label: GET_PROJECT_FINANCE_BUDGET,
    storePath: 'projects.projectBudget',
    onSuccess: (projectBudget = {}) => {
      const data = _.pick(projectBudget, [
        'budgetType',
        'rangeType',
        'startDate',
        'endDate',
        'timelogType',
        'repeatUnit',
        'carryUnderspend',
        'carryOverspend',
        'isDefaultBillableRateSet',
        'defaultBillableRate',
      ])
      if (projectBudget.rangeType === 'financial') {
        data.budgetAmount = projectBudget.budgetAmount
      }
      if (projectBudget.rangeType === 'time') {
        data.budgetTimeHour = Math.floor(projectBudget.budgetAmount / 60)
        data.budgetTimeMinute = projectBudget.budgetAmount % 60
      }
      if (projectBudget.budgetType === PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE) {
        data.budgetProfitMargin = projectBudget.budgetProfitMargin
        const targetProfitFromMargin = parseFloat(
          (projectBudget.budgetAmount * (projectBudget.budgetProfitMargin / 100)).toFixed(2)
        )
        const targetCostsFromMargin = parseFloat((projectBudget.budgetAmount - targetProfitFromMargin).toFixed(2))
        setValue('budgetTargetProfit', targetProfitFromMargin)
        setValue('budgetTargetCosts', targetCostsFromMargin)
      }
      reset(data)
    },
  })

  const submitForm = data => {
    const payload = {...data}
    if (payload.rangeType === 'time') {
      if (payload.budgetTimeHour * 60 + payload.budgetTimeMinute === 0) {
        setBudgetTimeError('Please enter a value greater than 0')
        return
      }
      payload.budgetAmount = payload.budgetTimeHour * 60 + payload.budgetTimeMinute
    }
    delete payload.budgetTimeHour
    delete payload.budgetTimeMinute
    delete payload.budgetTargetProfit
    delete payload.budgetTargetCosts

    if (budgetId) {
      delete payload.budgetType
      delete payload.repeatUnit
      dispatch(updateProjectFinanceBudget({projectId, budgetId, data: payload}))
    } else {
      dispatch(createProjectFinanceBudget({projectId, data: payload}))
    }
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader >{budgetId ? 'Edit Budget' : 'Create a New Budget'}</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          {!budgetId && <BudgetTypeForm control={control} budgetTypes={financeBudgetTypes} />}

          {[PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER].includes(
            watchBudgetType
          ) && (
            <BudgetForm
              control={control}
              errors={errors}
              budgetType={watchBudgetType}
              rangeType={watchRangeType}
              budgetTimeError={budgetTimeError}
              setBudgetTimeError={setBudgetTimeError}
              isDefaultBillableRateSet={watchIsDefaultBillableRateSet}
              budgetId={budgetId}
            />
          )}

          {[PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE].includes(watchBudgetType) && (
            <FixedFeeBudgetForm
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              budgetId={budgetId}
            />
          )}

          <br />
          <a className="my-2 d-flex-js" onClick={() => setIsAdvanceOption(!isAdvanceOption)}>
            Advanced Options {isAdvanceOption ? <BiChevronUp /> : <BiChevronDown />}
          </a>
          {isAdvanceOption && (
            <>
              <div className="d-flex-js">
                <Controller
                  name="isDefaultBillableRateSet"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperCheckbox checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
                <p className="ml-2 d-flex-js">
                  Set default billable rate of{' '}
                  <Controller
                    name="defaultBillableRate"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormInput
                        type="number"
                        disabled={!watchIsDefaultBillableRateSet}
                        style={{width: '80px'}}
                        className="mx-2"
                        value={value}
                        onClick={e => e.stopPropagation()}
                        onChange={(e, {value}) => onChange(value)}
                      />
                    )}
                  />{' '}
                  for this project
                </p>
              </div>
              <KooperCheckbox
                label="Enable notifications"
                checked={isEnableNotificationChecked}
                onChange={(e, {checked}) => setIsEnableNotificationChecked(checked)}
                disabled
              />
              <div className="d-flex-js ml-5">
                Notify <span className="kooper-dummyAvtar">SK</span> <SvgIcon path="common/plus" className="mx-1" /> via{' '}
                <KooperFormSelect
                  style={{width: '120px'}}
                  fluid
                  className="mx-2 mb-0"
                  disabled={!isEnableNotificationChecked}
                  defaultValue="email"
                  options={[
                    {key: 'email', value: 'email', text: 'email'},
                    {key: 'mobilePush', value: 'mobilePush', text: 'mobile push'},
                  ]}
                />
                when budget exceeds{' '}
                <KooperInput
                  className="ml-2"
                  style={{width: '60px'}}
                  label="%"
                  labelPosition="right"
                  value={80}
                  disabled={!isEnableNotificationChecked}
                />
              </div>
            </>
          )}
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          {budgetId ? 'Update' : `Create Budget`}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default CreateProjectBudgetModal
