/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash'
import React from 'react'
import {useSelector} from 'react-redux'
import {get, startCase} from 'utils/helper'
import {KooperIcon, KooperInput, KooperLabel} from 'kooper-library'

const PlanPreview = props => {
  const {paymentData, setPaymentData, addCoupon, setAddCoupon, tempCoupon, setTempCoupon, couponError, setCouponError} =
    props

  const {
    getKooperPlansData = {},
    currentSubscriptionData = {},
    calculateBillingData = {},
  } = useSelector(state => state.billing)

  const selectedAddOnInfo = () => {
    return get(['addOns', paymentData.interval], getKooperPlansData, []).filter(selected =>
      paymentData.addons.some(addon => addon.addon === selected.addOn)
    )
  }

  return (
    <div className="planRecap">
      <h5>Order Recap</h5>
      <div className="orderInfo">
        <div className="item">
          <p>{startCase(paymentData.plan)} Plan</p>
          <span>$ {calculateBillingData.planValue}</span>
        </div>
        <div className="item">
          <p>Plan (x {calculateBillingData.quantity}) Agents</p>
          <span> $ {calculateBillingData.planAmount}</span>
        </div>
        {selectedAddOnInfo().length > 0 && (
          <>
            <h4>Add-ons</h4>
            {selectedAddOnInfo().map(addon => (
              <div className="item">
                <p>{startCase(addon.name)}</p>
                <span> $ {calculateBillingData.addOnAmount}</span>
              </div>
            ))}
          </>
        )}
        <div className="couponCode">
          <h4>Discount</h4>
          <div className="item">
            {addCoupon &&
              currentSubscriptionData?.subscription?.activePlan !== paymentData.plan &&
              !paymentData.coupon && (
                <KooperInput
                  placeholder="Enter coupon"
                  action={{
                    color: 'blue',
                    content: 'Apply',
                    onClick: () => {
                      if (/^[A-Z0-9]*$/.test(tempCoupon)) {
                        setCouponError(false)
                        setPaymentData({
                          ...paymentData,
                          coupon: tempCoupon,
                        })
                      } else {
                        setCouponError(true)
                      }
                    },
                    disabled: tempCoupon.length < 1,
                  }}
                  value={tempCoupon}
                  onChange={e => {
                    if (!e.target.value.includes(' ')) {
                      setTempCoupon(e.target.value)
                      setCouponError(false)
                    }
                  }}
                />
              )}
            {!addCoupon && currentSubscriptionData?.subscription?.activePlan !== paymentData.plan && (
              <a onClick={() => setAddCoupon(true)} className="promoCode">
                Apply Coupon Code
              </a>
            )}
            {paymentData.coupon && (
              <>
                <KooperLabel className="kooper-label" color="green">
                  {paymentData.coupon}
                  <KooperIcon
                    name="delete"
                    onClick={() => {
                      setTempCoupon('')
                      setPaymentData(_.omit(paymentData, ['coupon']))
                    }}
                  />
                </KooperLabel>
                <span>- ${Number(calculateBillingData.couponDiscount).toFixed(2)}</span>
              </>
            )}
          </div>
          {couponError && (
            <span
              style={{
                color: 'red',
                position: 'relative',
                left: '5px',
                bottom: '8px',
                fontSize: '12px',
              }}
            >
              Invalid Coupon
            </span>
          )}
          {/* {calculateBillingData.type === 'upgrade_subscription' && ( */}
          <div className="item">
            <p>Previous Plan Discount</p>
            <span>- $ {Number(calculateBillingData.deductionFromCurrentPlan).toFixed(2)}</span>
          </div>
          {/* )} */}
        </div>
        <h4>Final Amount</h4>
        <div className="item">
          <p>Total Amount</p>
          <span> $ {Number(calculateBillingData.finalAmount).toFixed(2)}</span>
        </div>
      </div>
    </div>
  )
}

export default PlanPreview
