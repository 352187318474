import React from 'react'
import {KooperTooltip} from 'kooper-library'
import {featureAccessDeniedContent, roleAccessDeniedContent} from 'constants/variables'

const LockPermissionTooltip = ({children, isFeatureAccessDenied, isRoleAccessDenied}) => {
  if (isFeatureAccessDenied) {
    return <KooperTooltip content={featureAccessDeniedContent} position="top center" size="mini" trigger={children} />
  }
  if (isRoleAccessDenied) {
    return <KooperTooltip content={roleAccessDeniedContent} position="top center" size="mini" trigger={children} />
  }
  return children
}

export default LockPermissionTooltip
