import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

import {CREATE_SALES_ORDERS_PREFIX} from 'actions/types'
import {getSalesOrdersPrefixes, createSalesOrdersPrefix} from 'actions/salesOrders'
import {settingSchema} from 'validation/Finance/salesOrders.schema'
import SvgIcon from 'components/common/SvgIcon'

function PrefixModal({open, toggle}) {
  const dispatch = useDispatch()

  const {handleSubmit, errors, control} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(settingSchema),
    defaultValues: {
      prefix: '',
    },
  })

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(CREATE_SALES_ORDERS_PREFIX)) {
      toggle()
      dispatch(getSalesOrdersPrefixes())
    }
  }, [successLabels, dispatch])

  const handleSave = data => {
    dispatch(createSalesOrdersPrefix(data))
  }

  return (
    <KooperModal
      open={open}
      onClose={toggle}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      centered={false}
    >
      <KooperModalHeader >Create Prefix</KooperModalHeader>

      <KooperModalContent>
        <KooperForm className="errorLabel" onSubmit={handleSubmit(handleSave)}>
          <KooperFormField required>
            <label>Prefix Name</label>
            <Controller
              name="prefix"
              render={({value, onChange}) => (
                <KooperFormInput
                  maxLength={5}
                  autoFocus
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    errors.prefix && {
                      content: errors.prefix.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>

      <KooperModalActions>
        <KooperButton disabled={isLoadingData && type.includes(CREATE_SALES_ORDERS_PREFIX)} basic onClick={toggle}>
          Cancel
        </KooperButton>

        <KooperButton
          loading={isLoadingData && type.includes(CREATE_SALES_ORDERS_PREFIX)}
          disabled={isLoadingData && type.includes(CREATE_SALES_ORDERS_PREFIX)}
          primary
          onClick={handleSubmit(handleSave)}
        >
          Create
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default PrefixModal
