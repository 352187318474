/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {joiResolver} from '@hookform/resolvers'
import {Controller, useForm} from 'react-hook-form'
import Joi from 'joi'
import {
  KooperButton,
  KooperHeader,
  KooperTable,
  KooperTableHeader,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperModal,
  KooperModalHeader,
  KooperModalContent,
  KooperForm,
  KooperFormInput,
  KooperModalActions,
  KooperTooltip,
  KooperFormField,
} from 'kooper-library'

import {UPDATE_CONTACT_STATUS, DELETE_CONTACT_STATUS, GET_CONTACT_STATUSES, ADD_CONTACT_STATUS} from 'actions/types'
import {addContactStatus, deleteContactStatus, getContactStatus, updateContactStatus} from 'actions/custom_options'
import img from 'assets/create.svg'
import SettingLayout from 'layouts/settingLayout'
import DeleteModal from 'components/common/DeleteModal'
import {getPermissionTooltipContent, removeDoubleQuotes, startCase} from 'utils/helper'
import CreateFirst from 'components/common/CreateFirst'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const ContactStatus = () => {
  const dispatch = useDispatch()

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(
      Joi.object({
        status: Joi.string().trim().max(30).required().label('Contact'),
      })
    ),
    defaultValues: {
      status: '',
    },
  })

  const [modal, setModal] = useState(false)
  const [idToEdit, setIdToEdit] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const contactStatusLearnMore = settingsLearnMoreUrl?.dataManagement?.customization?.contactStatus?.learnMore

  const {isLoading} = useApiLoader({label: GET_CONTACT_STATUSES})
  const {generalAccess, customizationPermissions} = useUserPermissions()
  const dataManageCustomizationAccess = generalAccess?.dataManageCustomization
  const manageContactStatusPermissions = customizationPermissions?.manage_contact_status

  const {data: contactStatusData = []} = useApiResponse({
    action: getContactStatus,
    dependency: [dataManageCustomizationAccess],
    enabled: dataManageCustomizationAccess,
    label: GET_CONTACT_STATUSES,
    storePath: 'custom_option.contactStatusData',
  })

  useEffect(() => {
    if (
      successLabels.includes(ADD_CONTACT_STATUS) ||
      successLabels.includes(UPDATE_CONTACT_STATUS) ||
      successLabels.includes(DELETE_CONTACT_STATUS)
    ) {
      setModal(false)
      setIdToEdit(null)
      dispatch(getContactStatus())
      reset({status: ''})
    }
  }, [successLabels, setModal, setIdToEdit, dispatch])

  const onModelClose = () => {
    setModal(false)
    setIdToEdit(null)
    reset({status: ''})
  }

  const CreateContactStatus = () => {
    if (modal) {
      return (
        <KooperModal
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
          size="mini"
          centered={false}
          open={modal}
          onClose={onModelClose}
        >
          <KooperModalHeader >{idToEdit ? 'Update' : 'Create'} Status</KooperModalHeader>
          <KooperModalContent>
            <KooperForm className="errorLabel">
              <Controller
                control={control}
                name="status"
                render={({value, onChange}) => (
                  <KooperFormField required>
                    <label>Contact Status</label>
                    <KooperFormInput
                      maxLength={20}
                      placeholder="Write status.."
                      value={value}
                      onChange={e => onChange(e.target.value)}
                      error={
                        errors.status && {
                          content: removeDoubleQuotes(errors.status.message),
                        }
                      }
                    />
                  </KooperFormField>
                )}
              />
            </KooperForm>
          </KooperModalContent>
          <KooperModalActions>
            <KooperButton
              disabled={isLoadingData && (type.includes(ADD_CONTACT_STATUS) || type.includes(UPDATE_CONTACT_STATUS))}
              basic
              onClick={onModelClose}
            >
              Close
            </KooperButton>
            <LockPermissionTooltip isRoleAccessDenied={!manageContactStatusPermissions}>
              <KooperButton
                primary
                loading={isLoadingData && (type.includes(ADD_CONTACT_STATUS) || type.includes(UPDATE_CONTACT_STATUS))}
                disabled={
                  !isDirty ||
                  (isLoadingData && (type.includes(ADD_CONTACT_STATUS) || type.includes(UPDATE_CONTACT_STATUS)))
                }
                {...(manageContactStatusPermissions && {
                  onClick: handleSubmit(data => {
                    idToEdit ? dispatch(updateContactStatus(idToEdit, data)) : dispatch(addContactStatus(data))
                  }),
                })}
                className={!manageContactStatusPermissions ? 'disabled-button' : ''}
              >
                {idToEdit ? 'Update' : 'Create'}
              </KooperButton>
            </LockPermissionTooltip>
          </KooperModalActions>
        </KooperModal>
      )
    }
  }

  const renderList = () => {
    return contactStatusData.map((status, index) => (
      <tr
        className="tableLink"
        onClick={() => {
          setIdToEdit(status.id)
          setModal(true)
          reset({status: status.status})
        }}
        key={status.id}
      >
        <td className="pl-4">{index + 1}</td>
        <td>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <KooperHeader as="h5" className="m-0">
              {startCase(status.status || '-')}
            </KooperHeader>
          </div>
        </td>
        <td onClick={e => e.stopPropagation()}>
          <KooperTooltip
            content={
              manageContactStatusPermissions
                ? 'Delete'
                : getPermissionTooltipContent({roleAccess: manageContactStatusPermissions})
            }
            size="mini"
            position="top center"
            trigger={
              <KooperButton
                icon
                onClick={() => {
                  if (manageContactStatusPermissions) {
                    setIdToEdit(status.id)
                    setDeleteModal(true)
                  }
                }}
              >
                <SvgIcon path="common/delete" />
              </KooperButton>
            }
          />
        </td>
      </tr>
    ))
  }

  if (isLoading && dataManageCustomizationAccess) {
    return <PageLoader />
  }

  if (contactStatusData.length === 0 || !dataManageCustomizationAccess) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          addFirst={() => dataManageCustomizationAccess && manageContactStatusPermissions && setModal(true)}
          buttonText="Add Contact Status"
          tooltip={!dataManageCustomizationAccess || !manageContactStatusPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: dataManageCustomizationAccess,
            roleAccess: manageContactStatusPermissions,
          })}
          img={img}
          header="Contact Status"
          subHeader="Streamline your contact management by categorizing contacts based on status within the sales pipeline"
          Feature1="Customization"
          Feature2="Automation"
          Feature3="Reporting and Analytics"
          list1="Customize contact status to match your sales process and workflow"
          list2="Automate status changes based on predefined triggers or actions"
          list3="Generate reports to analyze contact progression based on status"
        />
        {modal && CreateContactStatus()}
      </div>
    )
  }

  return (
    <>
      {modal && CreateContactStatus()}
      <SettingLayout
        icon={<SvgIcon path="settings/contact-status" />}
        header="Manage Contact Status"
        subHeader="Create and manage list of contact status based on organizational need"
        learnMoreUrl={contactStatusLearnMore}
        headerButton={{
          onClick: () => manageContactStatusPermissions && setModal(true),
          content: 'Create Status',
        }}
        table
        headerDivider
        lockRole={!manageContactStatusPermissions}
      >
        <KooperTable basic selectable>
          <KooperTableHeader>
            <KooperTableRow>
              <th className="pl-4">#</th>
              <th>Status</th>
              <th>Action</th>
            </KooperTableRow>
          </KooperTableHeader>
          <tbody>
            {isLoadingData && type.includes(GET_CONTACT_STATUSES) ? <KooperTablePlaceholder columns={3} /> : renderList()}
          </tbody>
        </KooperTable>

        {deleteModal && (
          <DeleteModal
            idTobeDeleted={idToEdit}
            deleteAction={deleteContactStatus}
            isModalOpen={deleteModal}
            setIsModalOpen={setDeleteModal}
            modalText="Are you sure you want to delete ?"
            type={DELETE_CONTACT_STATUS}
          />
        )}
      </SettingLayout>
    </>
  )
}
export default ContactStatus
