import React from 'react'
import {useHistory, useRouteMatch} from 'react-router-dom/cjs/react-router-dom.min'
import {KooperMenu, KooperMenuItem} from 'kooper-library'
import {PROJECTS_PATH} from 'constants/path'

const TemplateFinanceMenu = () => {
  const history = useHistory()
  const {url} = useRouteMatch()

  return (
    <KooperMenu vertical secondary className="projects-side-menu kooper-menu">
      <KooperMenuItem
        active={window.location.pathname.includes(
          `/${PROJECTS_PATH.TEMPLATES_FINANCE}/${PROJECTS_PATH.TEMPLATES_FINANCE_SUMMARY}`
        )}
        onClick={() => history.push(`${url}/${PROJECTS_PATH.TEMPLATES_FINANCE_SUMMARY}`)}
      >
        Summary
      </KooperMenuItem>
      <KooperMenuItem
        active={window.location.pathname.includes(
          `/${PROJECTS_PATH.TEMPLATES_FINANCE}/${PROJECTS_PATH.TEMPLATES_FINANCE_RATES}`
        )}
        onClick={() => history.push(`${url}/${PROJECTS_PATH.TEMPLATES_FINANCE_RATES}`)}
      >
        Rates
      </KooperMenuItem>
    </KooperMenu>
  )
}

export default TemplateFinanceMenu
