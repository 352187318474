import React from 'react'
import {FiFileText} from 'react-icons/fi'
import {useHistory} from 'react-router'
import {KooperMenu, KooperMenuItem} from 'kooper-library'
import {ANNOUNCEMENTS_PATH} from 'constants/path'

const AnnouncementSidemenu = () => {
  const history = useHistory()

  return (
    <KooperMenu vertical secondary className="kooper-menu">
      <KooperMenuItem
        active={window.location.pathname === `/${ANNOUNCEMENTS_PATH.PREFIX}`}
        onClick={() => history.push(`/${ANNOUNCEMENTS_PATH.PREFIX}`)}
      >
        <FiFileText /> All Announcements
      </KooperMenuItem>
    </KooperMenu>
  )
}

export default AnnouncementSidemenu
