/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AiOutlineCheck} from 'react-icons/ai'
import {BsPin} from 'react-icons/bs'
import {FiArchive, FiChevronRight} from 'react-icons/fi'
import {MdOutlineMoreVert} from 'react-icons/md'
import _ from 'lodash'
import {
  KooperAccordion,
  KooperAccordionContent,
  KooperAccordionTitle,
  KooperButton,
  KooperDropdown,
  KooperDropdownDivider,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperMenu,
  KooperMenuItem,
  KooperTooltip,
} from 'kooper-library'

import {
  ARCHIVE_PROJECT_DESCUSSION_TOPIC,
  CREATE_PROJECT_DISCUSSION_TOPIC,
  DELETE_PROJECT_DISCUSSION_TOPIC,
  GET_PROJECT_DISCUSSION_TOPICS,
  GET_PROJECT_DISCUSSION_TOPIC_COMMENTS,
  PIN_PROJECT_DESCUSSION_TOPIC,
  UPDATE_PROJECT_DISCUSSION_TOPIC,
  UPDATE_PROJECT_DISCUSSION_TOPIC_SUBSCRIBERS,
} from 'actions/types'
import {
  statusProjectDiscussionTopic,
  deleteProjectDiscussionTopic,
  getProjectDiscussionTopics,
  pinProjectDiscussionTopic,
  getProjectDiscussionTopicComments,
  getProjectDiscussionSingleTopic,
  getProjectsFilters,
} from 'actions/projects'
import {getUsers} from 'actions/settings'
import DeleteModal from 'components/common/DeleteModal'
import {EmailBodyContentPlaceholder, ListPlaceholder} from 'components/common/InboxPlaceholders'
import {get, getFormattedDate, getPermissionTooltipContent, wrapText} from 'utils/helper'
import io from 'socket'
import {getAppToken, getUserInfo} from 'utils/local-storage'
import CreateFirst from 'components/common/CreateFirst'
import create from 'assets/create.svg'
import {useParams} from 'react-router'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import TopicModal from './TopicModal'
import SubscriberModal from './SubscriberModal'
import DiscussionBody from './DiscussionBody'

const Discussions = ({rolePermissions}) => {
  const {projectId} = useParams()
  const notificationAudio = new Audio('/juntos.mp3')

  const dispatch = useDispatch()

  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false)
  const [allTopics, setAllTopics] = useState([])
  const [activeTopics, setActiveTopics] = useState([])
  const [archiveTopics, setArchiveTopics] = useState([])
  const [isSubscriberModalOpen, setIsSubscriberModalOpen] = useState(false)
  const [topicId, setTopicId] = useState(null)
  const [isTopicDeleteModalOpen, setIsTopicDeleteModalOpen] = useState(false)
  const [currentlySelectedTopic, setCurrentlySelectedTopic] = useState(null)
  const [chatMessages, setChatMessages] = useState([])
  const [active, setActive] = useState({
    active: true,
    archieve: false,
  })

  const {successLabels = [], type = []} = useSelector(state => state.apiReducer)
  const {topics = [], topicData = {}, topicComments: {comments = []} = {}} = useSelector(state => state.projects)

  const {isLoading} = useApiLoader({label: GET_PROJECT_DISCUSSION_TOPICS})

  const setSelectedConversation = useCallback(
    conversationId => {
      if (topics.length > 0) {
        const indexofSelected = topics.findIndex(index => index.id === conversationId)
        if (indexofSelected !== -1) {
          setCurrentlySelectedTopic(topics[indexofSelected].id)
          dispatch(getProjectDiscussionSingleTopic({projectId, topicId: topics[indexofSelected].id}))
          dispatch(getProjectDiscussionTopicComments({projectId, topicId: topics[indexofSelected].id}))
        }
      }
    },
    [topics, dispatch, projectId]
  )

  const {callAction: callGetProjectDiscussionTopicsAction} = useApiResponse({
    action: getProjectDiscussionTopics,
    payload: projectId,
    dependency: [projectId],
    enabled: projectId,
    label: GET_PROJECT_DISCUSSION_TOPICS,
    storePath: 'projects.topics',
    onSuccess: topics => {
      if (topics?.length > 0) {
        setAllTopics(topics)
        setSelectedConversation(topics[0].id)
        const allActiveTopics = topics
          .filter(topic => topic.status === 'active')
          .sort(function (x, y) {
            return x.isPinned === y.isPinned ? 0 : x.isPinned ? -1 : 1
          })
        setActiveTopics(allActiveTopics)
        const allArchiveTopics = topics
          .filter(topic => topic.status === 'archived')
          .sort(function (x, y) {
            return x.isPinned === y.isPinned ? 0 : x.isPinned ? -1 : 1
          })
        setArchiveTopics(allArchiveTopics)
      }
    },
  })

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  useEffect(() => {
    dispatch(getProjectsFilters(projectId, {filters: ['assignedTo']}))
  }, [dispatch, projectId])

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECT_DISCUSSION_TOPIC_SUBSCRIBERS)) {
      dispatch(getProjectDiscussionSingleTopic({projectId, topicId: currentlySelectedTopic}))
    }
  }, [successLabels, dispatch, projectId, currentlySelectedTopic])

  useEffect(() => {
    // if (successLabels.includes(GET_PROJECT_DISCUSSION_TOPIC_COMMENTS)) {
    const newArray = []
    _.forEachRight(comments, function (value, index, array) {
      let temp = {}
      if (index === 0) {
        temp = {...value, showDateStamp: true}
      } else if (
        array[index - 1] &&
        getFormattedDate(value.createdAt) !== getFormattedDate(array[index - 1].createdAt)
      ) {
        temp = {...value, showDateStamp: true}
      } else {
        temp = {...value, showDateStamp: false}
      }
      newArray.push(temp)
    })
    setChatMessages(newArray.reverse() || [])
    // }
  }, [JSON.stringify(comments)])

  // temp
  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECT_DISCUSSION_TOPIC) ||
      successLabels.includes(UPDATE_PROJECT_DISCUSSION_TOPIC) ||
      successLabels.includes(DELETE_PROJECT_DISCUSSION_TOPIC) ||
      successLabels.includes(ARCHIVE_PROJECT_DESCUSSION_TOPIC) ||
      successLabels.includes(PIN_PROJECT_DESCUSSION_TOPIC)
    ) {
      callGetProjectDiscussionTopicsAction()
    }
  }, [successLabels, callGetProjectDiscussionTopicsAction])

  useEffect(() => {
    io.emit('join_rooms', {
      token: getAppToken(),
    })
  }, [])

  useEffect(() => {
    io.on('user_discussion', data => {
      if (getUserInfo().id !== data.createdBy.id) {
        notificationAudio.play()
      }
      const comment = _.pick(data, ['id', 'content', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy'])
      dispatch({
        type: 'SOCKET_APPEND_PROJECT_DISCUSSION_TOPIC_COMMENT',
        payload: {comment, topicId: data.topicId},
      })
    })

    io.on('deleted_discussion', data => {
      dispatch({
        type: 'SOCKET_DELETE_PROJECT_DISCUSSION_TOPIC_COMMENT',
        payload: data,
      })
    })

    return () => {
      io.off('user_discussion')
      io.off('deleted_discussion')
    }
  })

  const onClickTopic = id => {
    if (currentlySelectedTopic !== id) {
      setCurrentlySelectedTopic(id)
      dispatch(getProjectDiscussionSingleTopic({projectId, topicId: id}))
      dispatch(getProjectDiscussionTopicComments({projectId, topicId: id}))
    }
  }

  const renderDropdown = topic => {
    return (
      <KooperDropdownMenu>
        <KooperDropdownItem
          onClick={() => {
            setTopicId(topic.id)
            setIsSubscriberModalOpen(true)
          }}
        >
          <SvgIcon path="settings/users" /> Manage People
        </KooperDropdownItem>
        <KooperDropdownItem
          onClick={() => {
            setTopicId(topic.id)
            setIsTopicModalOpen(true)
          }}
        >
          <SvgIcon path="common/edit2" /> Edit
        </KooperDropdownItem>
        <KooperDropdownItem
          onClick={() => {
            setTopicId(topic.id)
            setIsTopicDeleteModalOpen(true)
          }}
        >
          <SvgIcon path="common/delete" /> Delete
        </KooperDropdownItem>
        <KooperDropdownDivider />
        <KooperDropdownItem
          onClick={() => {
            let data
            if (topic.status === 'active') {
              data = {status: 'archived'}
            } else {
              data = {status: 'active'}
            }
            dispatch(statusProjectDiscussionTopic({projectId, topicId: topic.id, data}))
          }}
        >
          {topic.status === 'active' ? (
            <>
              <FiArchive /> Archive
            </>
          ) : (
            <>
              <AiOutlineCheck /> Unarchive
            </>
          )}
        </KooperDropdownItem>
        <KooperDropdownItem
          onClick={() => {
            const data = {isPinned: !topic.isPinned}
            dispatch(pinProjectDiscussionTopic({projectId, topicId: topic.id, data}))
          }}
        >
          {topic.isPinned ? (
            <>
              <BsPin /> UnPin
            </>
          ) : (
            <>
              <AiOutlineCheck /> Pin
            </>
          )}
        </KooperDropdownItem>
      </KooperDropdownMenu>
    )
  }

  const renderTopicsList = topics => {
    return topics.map(topic => {
      return (
        <KooperMenuItem
          key={topic.id}
          className="project-discussion-sidemenu pr-0"
          active={currentlySelectedTopic === topic.id}
          onClick={() => onClickTopic(topic.id)}
        >
          <div className="d-flex" style={{justifyContent: 'flex-start'}} title={topic.title}>
            {topic.isPinned && <BsPin className="mr-1" />}
            {wrapText(topic.title, 15)}
          </div>
          {rolePermissions && (
            <KooperDropdown className="project-discussion-sidemenu-dropdown" icon={<MdOutlineMoreVert />}>
              {renderDropdown(topic)}
            </KooperDropdown>
          )}
        </KooperMenuItem>
      )
    })
  }

  const Sidemenu = () => {
    return (
      <KooperMenu vertical secondary className="projects-side-menu kooper-menu">
        <KooperAccordion className="mt-0">
          <KooperAccordionTitle className="d-flex">
            <div
              className="d-flex"
              style={{justifyContent: 'flex-start'}}
              onClick={() => setActive({...active, active: !active.active})}
            >
              {active.active ? (
                <SvgIcon path="common/down" style={{marginRight: 8}} />
              ) : (
                <FiChevronRight style={{marginRight: 8}} />
              )}
              Active
            </div>
            {rolePermissions && (
              <SvgIcon
                path="common/plus"
                onClick={() => {
                  setTopicId(null)
                  setIsTopicModalOpen(true)
                }}
              />
            )}
          </KooperAccordionTitle>
          <KooperAccordionContent active={active.active}>{renderTopicsList(activeTopics)}</KooperAccordionContent>
        </KooperAccordion>

        <KooperAccordion>
          <KooperAccordionTitle
            className="d-flex"
            style={{justifyContent: 'flex-start'}}
            onClick={() => setActive({...active, archieve: !active.archieve})}
          >
            {active.archieve ? (
              <SvgIcon path="common/down" style={{marginRight: 8}} />
            ) : (
              <FiChevronRight style={{marginRight: 8}} />
            )}
            Archived
          </KooperAccordionTitle>
          <KooperAccordionContent active={active.archieve}>{renderTopicsList(archiveTopics)}</KooperAccordionContent>
        </KooperAccordion>
      </KooperMenu>
    )
  }

  const renderDiscussionHeader = () => {
    return (
      <div className="project-discussion-head">
        {/* <h5 className="m-0">Open Topic</h5> */}
        <h5 className="m-0">{get(['title'], topicData, '')}</h5>
        <div className="d-flex project-discussion-head-action">
          <div className="project-discussion-head-usersGroup">
            {get(['subscriberDetails'], topicData, []).map(subscriber => (
              <KooperTooltip
                position="top center"
                size="mini"
                content={`${subscriber.firstName} ${subscriber.lastName}`}
                trigger={
                  <div className="discussion-users" style={{'--user-bg': 'green'}} key={subscriber.id}>
                    {`${get(['firstName'], subscriber, '')[0].toUpperCase()}${get(
                      ['lastName'],
                      subscriber,
                      ''
                    )[0].toUpperCase()}`}
                  </div>
                }
              />
            ))}
            {rolePermissions && (
              <KooperButton icon className="m-0 ml-2">
                <SvgIcon
                  path="common/plus"
                  onClick={() => {
                    setTopicId(topicData.id)
                    setIsSubscriberModalOpen(true)
                  }}
                />
              </KooperButton>
            )}
          </div>
          {rolePermissions && (
            <KooperDropdown className="project-discussion-head-more icon button" icon={<MdOutlineMoreVert />}>
              {renderDropdown(topicData)}
            </KooperDropdown>
          )}
        </div>
      </div>
    )
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {type.includes(GET_PROJECT_DISCUSSION_TOPICS) && (
        <div className="projects-side-menu-loading">
          <ListPlaceholder />
        </div>
      )}
      {(type.includes(GET_PROJECT_DISCUSSION_TOPICS) || type.includes(GET_PROJECT_DISCUSSION_TOPIC_COMMENTS)) && (
        <div className="project-discussion-side-wrap">
          <EmailBodyContentPlaceholder />
        </div>
      )}
      {allTopics.length === 0 &&
      !type.includes(GET_PROJECT_DISCUSSION_TOPICS) &&
      !type.includes(GET_PROJECT_DISCUSSION_TOPIC_COMMENTS) ? (
        <CreateFirst
          header="Topics"
          subHeader="Topic-centric conversations for your team to quickly get to the heart of any Issue"
          addFirst={() => rolePermissions && setIsTopicModalOpen(true)}
          tooltip={!rolePermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: rolePermissions})}
          buttonText="Create Topic"
          imageSrc={create}
          Feature1="Create discussions"
          list1="Create and manage discussion on a topic within projects with ease"
          Feature2="Assign agents"
          list2="Select agents as a subscribers for discussions"
          Feature3="Prioritize discussions"
          list3="Pin any discussion on top which have more priority"
        />
      ) : (
        <>
          <Sidemenu />
          {!type.includes(GET_PROJECT_DISCUSSION_TOPIC_COMMENTS) && (
            <div className="project-discussion-side-wrap">
              {renderDiscussionHeader()}
              <DiscussionBody
                projectId={projectId}
                topicId={currentlySelectedTopic}
                chatMessages={chatMessages}
                rolePermissions={rolePermissions}
              />
            </div>
          )}
        </>
      )}

      {isTopicModalOpen && (
        <TopicModal open={isTopicModalOpen} toggle={setIsTopicModalOpen} projectId={projectId} topicId={topicId} />
      )}

      {isSubscriberModalOpen && (
        <SubscriberModal
          open={isSubscriberModalOpen}
          toggle={setIsSubscriberModalOpen}
          projectId={projectId}
          topicId={topicId}
        />
      )}

      {isTopicDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isTopicDeleteModalOpen}
          setIsModalOpen={setIsTopicDeleteModalOpen}
          onDelete={() => dispatch(deleteProjectDiscussionTopic({projectId, topicId}))}
          type={DELETE_PROJECT_DISCUSSION_TOPIC}
        />
      )}
    </>
  )
}

export default Discussions
