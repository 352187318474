import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormTextArea,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperRadio,
} from 'kooper-library'

import {cancelBilling} from 'actions/billing'
import {removeDoubleQuotes} from 'utils/helper'
import {cancelPlanSchema} from 'validation/Settings/billing.schema'
import SvgIcon from 'components/common/SvgIcon'

const reasons = [
  'lack of specific features',
  'product is having bugs or unstable',
  'pricing',
  'product design is not intuitive',
  'others',
]

const CancelPlanModal = props => {
  const {isOpen, setIsOpen} = props

  const dispatch = useDispatch()

  const [currentSlide, setCurrentSlide] = useState(0)

  const {handleSubmit, errors, control, watch} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(cancelPlanSchema),
    defaultValues: {
      reason: '',
      comment: '',
    },
  })
  const watchedReason = watch('reason')

  const Cancel1 = () => {
    return (
      <>
        <KooperModalHeader>Cancel Plan</KooperModalHeader>
        <KooperModalContent>Are you sure you want to cancel the plan ?</KooperModalContent>
      </>
    )
  }

  const Cancel2 = () => {
    return (
      <>
        <KooperModalHeader>Feedback</KooperModalHeader>
        <KooperModalContent>
          <KooperForm className="errorLabel">
            <KooperFormField required>
              <label>Let us know your feedback to imporove out product..!!</label>
              <Controller
                name="reason"
                control={control}
                render={({value, onChange}) => (
                  <div className="cancelReason">
                    {reasons.map(reason => (
                      <KooperRadio
                        key={reason}
                        value={reason}
                        checked={value === reason}
                        onChange={(e, {value}) => onChange(value)}
                        label={reason}
                      />
                    ))}
                  </div>
                )}
              />
              {errors.reason && (
                <p style={{marginTop: '2px'}} className="errorLabelError">
                  {removeDoubleQuotes(errors.reason.message)}
                </p>
              )}
            </KooperFormField>
            <KooperFormField required={watchedReason === 'others'}>
              <label className="mt-4">Write comment here</label>
              <Controller
                name="comment"
                control={control}
                render={({value, onChange, onBlur}) => (
                  <KooperFormTextArea
                    type="textarea"
                    className="mt-2"
                    placeholder="Write your reason here..."
                    onBlur={onBlur}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={
                      watchedReason === 'others' &&
                      errors.comment && {
                        content: removeDoubleQuotes(errors.comment.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
          </KooperForm>
        </KooperModalContent>
      </>
    )
  }

  const cancelConfirmCarousel = [Cancel1(), Cancel2()]

  const actionCancelModal = action => {
    if (action === 'prev') {
      setCurrentSlide(currentSlide => currentSlide - 1)
    }
    if (action === 'next') {
      setCurrentSlide(currentSlide => currentSlide + 1)
    }
  }

  const submitFeedback = data => {
    dispatch(cancelBilling(data))
  }

  return (
    <KooperModal
      size="tiny"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      toggle={() => setIsOpen(!isOpen)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
    >
      {cancelConfirmCarousel[currentSlide]}
      <KooperModalActions>
        {currentSlide > 0 && (
          <KooperButton basic onClick={() => actionCancelModal('prev')}>
            Back
          </KooperButton>
        )}
        {cancelConfirmCarousel.length - 1 > currentSlide && (
          <>
            <KooperButton basic onClick={() => setIsOpen(false)}>
              Cancel
            </KooperButton>
            <KooperButton primary onClick={() => actionCancelModal('next')}>
              Next
            </KooperButton>
          </>
        )}
        {cancelConfirmCarousel.length - 1 === currentSlide && (
          <KooperButton primary onClick={handleSubmit(submitFeedback)}>
            Submit
          </KooperButton>
        )}
      </KooperModalActions>
    </KooperModal>
  )
}

export default CancelPlanModal
