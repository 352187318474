import React, {useCallback, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {KooperBreadcrumb, KooperBreadcrumbDivider, KooperBreadcrumbSection} from 'kooper-library'
import {getProjectsHealthData, getProjectsHealthList} from 'actions/analytics_projects'
import {startCase} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import DateRangeGroup from '../../../DateRangeGroup'
import ProjectHealthList from './ProjectHealthList'
import ProjectHealthData from './ProjectHealthData'

const ProjectHealth = () => {
  const dispatch = useDispatch()
  const [activeSection, setActiveSection] = useState('projectHealth')
  const [selectedProjectId, setSelectedProjectId] = useState(null)

  const {projectsHealthList = [], projectsHealthData = {}} = useSelector(state => state.analyticsProjects)
  const projectName = projectsHealthList.find(project => project.id === selectedProjectId)?.title

  const actionsToDispatch = useCallback(
    data => {
      dispatch(getProjectsHealthList(data))
    },
    [dispatch]
  )

  const handleProjectClick = projectId => {
    setActiveSection('project')
    setSelectedProjectId(projectId)
    dispatch(getProjectsHealthData(projectId))
  }

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/project-health" />
            <KooperBreadcrumb>
              <KooperBreadcrumbSection
                link
                onClick={() => setActiveSection('projectHealth')}
                active={activeSection === 'projectHealth'}
              >
                <h5>Project Health</h5>
              </KooperBreadcrumbSection>
              {activeSection === 'project' && (
                <>
                  <KooperBreadcrumbDivider />
                  <KooperBreadcrumbSection
                    link={activeSection !== 'project'}
                    active={activeSection === 'project'}
                    onClick={() => setActiveSection('project')}
                  >
                    <h5>{startCase(projectName)}</h5>
                  </KooperBreadcrumbSection>
                </>
              )}
            </KooperBreadcrumb>
          </div>
        </div>
        {activeSection === 'projectHealth' && (
          <div className="page-action">
            <DateRangeGroup actionsToDispatch={actionsToDispatch} />
          </div>
        )}
      </div>

      {activeSection === 'projectHealth' && (
        <ProjectHealthList projectsHealthList={projectsHealthList} onProjectClick={handleProjectClick} />
      )}

      {activeSection === 'project' && <ProjectHealthData projectsHealthData={projectsHealthData} />}
    </div>
  )
}

export default ProjectHealth
