import KooperCustomPagination from 'components/common/KooperCustomPagination'
import SvgIcon from 'components/common/SvgIcon'
import {
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperGrid,
  KooperGridColumn,
  KooperGridRow,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import React from 'react'

const Goals = () => {
  const currentPage = 1
  const totalPages = 1
  const isLoading = false
  const goalList = []
  const renderUserBody = () => {
    if (isLoading) {
      return <KooperTablePlaceholder columns={6} line={1} row={3} />
    }
    if (!isLoading && goalList?.length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="6" className="emptyValue">
            No Data Available
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    // return get(['data'], userActivitiesSLAData, []).map(
    //   ({user, unresolved, resolved, firstResponseTime, resolvedTime, feedback}, index) => (
    //     <KooperTableRow key={index}>
    //       <KooperTableCell>{startCase(user)}</KooperTableCell>
    //       <KooperTableCell>{unresolved}</KooperTableCell>
    //       <KooperTableCell>{resolved}</KooperTableCell>
    //       <KooperTableCell>{firstResponseTime}</KooperTableCell>
    //       <KooperTableCell>{resolvedTime}</KooperTableCell>
    //       <KooperTableCell>{feedback}</KooperTableCell>
    //     </KooperTableRow>
    //   )
    // )
  }
  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/email-tracking" />
            <h5>Goals Report</h5>
          </div>
        </div>
      </div>
      <KooperGrid columns="equal">
        <KooperGridColumn width={16}>
          <KooperCard fluid>
            <KooperCardContent>
              <KooperGrid columns={4}>
                <KooperGridRow>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <KooperCardHeader>Members With Goals</KooperCardHeader>
                      <h3>
                        --
                        {/* <KooperTooltip
                        content="Total"
                        size="mini"
                        position="top center"
                        trigger={
                          <span
                            className={
                              get(['growth'], emailStatsData?.totalEmails, []) === 'positive' ? '' : 'text-danger'
                            }
                          >
                            {get(['growth'], emailStatsData?.totalEmails, []) === 'positive' ? (
                              <SvgIcon path="common/arrow-up-right" />
                            ) : (
                              <SvgIcon path="common/arrow-down-left" className="down-left" />
                            )}
                            {get(['percentage'], emailStatsData?.totalEmails, [])}%
                          </span>
                        }
                      /> */}
                      </h3>
                    </div>
                  </KooperGridColumn>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <KooperCardHeader>Organization Alignment</KooperCardHeader>
                      <h3>
                        --
                        {/* <KooperTooltip
                          content="Open"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.openedEmails, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.openedEmails, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.openedEmails, [])}%
                            </span>
                          }
                        /> */}
                      </h3>
                    </div>
                  </KooperGridColumn>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <KooperCardHeader>Updated in last 30 days</KooperCardHeader>
                      <h3>
                        --
                        {/* <KooperTooltip
                          content="Clicked"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.clickedEmails, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.clickedEmails, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.clickedEmails, [])}%
                            </span>
                          }
                        /> */}
                      </h3>
                    </div>
                  </KooperGridColumn>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <KooperCardHeader>Goals</KooperCardHeader>
                      <h3>
                        --
                        {/* <KooperTooltip
                          content="Replied"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.repliedEmails, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.repliedEmails, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.repliedEmails, [])}%
                            </span>
                          }
                        /> */}
                      </h3>
                    </div>
                  </KooperGridColumn>
                </KooperGridRow>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Teams</KooperCardHeader>
              <KooperTooltip
                content="Teams"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0">
              <KooperTable sortable basic>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell
                    //   sorted={currentListParams.sortBy === 'user' ? getSortType(currentListParams.sortType) : null}
                    //   onClick={handleSort('user')}
                    >
                      Team
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                    //   sorted={
                    //     currentListParams.sortBy === 'unresolved' ? getSortType(currentListParams.sortType) : null
                    //   }
                    //   onClick={handleSort('unresolved')}
                    >
                      Active
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                    //   sorted={currentListParams.sortBy === 'resolved' ? getSortType(currentListParams.sortType) : null}
                    //   onClick={handleSort('resolved')}
                    >
                      Members with goals
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                    //   sorted={
                    //     currentListParams.sortBy === 'firstResponseTime'
                    //       ? getSortType(currentListParams.sortType)
                    //       : null
                    //   }
                    //   onClick={handleSort('firstResponseTime')}
                    >
                      Alignment
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                    //   sorted={
                    //     currentListParams.sortBy === 'resolvedTime' ? getSortType(currentListParams.sortType) : null
                    //   }
                    //   onClick={handleSort('resolvedTime')}
                    >
                      Progress updated
                    </KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>{renderUserBody()}</KooperTableBody>
              </KooperTable>
              <KooperCustomPagination
                activePage={currentPage}
                totalPages={totalPages}
                // onPageChange={page =>
                // //   setCurrentListParams(currentListParams => ({
                // //     ...currentListParams,
                // //     page,
                // //   }))
                // }
              />
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default Goals
