import {joiResolver} from '@hookform/resolvers'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {
  KooperButton,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormRadio,
  KooperFormSelect,
  KooperFormTextArea,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'

import {priorityList, statusList, taskTypes} from 'constants/variables'
import {startCase} from 'utils/helper'
import {createTaskSchema} from 'validation/Sales/sequence.schema'
import SvgIcon from 'components/common/SvgIcon'

const placeHolderList = [
  {value: '{{firstName}}', text: 'First Name'},
  {value: '{{lastName}}', text: 'Last Name'},
  {value: '{{companyName}}', text: 'Company Name'},
  {value: '{{jobTitle}}', text: 'Job title'},
]

const CreateTask = props => {
  const {handleAdd, addType, toggle, editData, open} = props
  const getType = type => {
    if (type === 'task_activity') {
      return 'activity'
    }
    if (type === 'task_meeting') {
      return 'meeting'
    }
    if (type === 'task_call') {
      return 'call'
    }
  }

  const {handleSubmit, errors, control, watch, setValue} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(createTaskSchema),
    defaultValues: editData || {
      type: getType(addType),
      subject: '',
      status: 'new',
      duration: '',
      priority: 'default',
      description: '',
      callType: 'inbound',
    },
  })

  const currentType = watch('type')
  const currentSubject = watch('subject')

  const createTask = data => {
    handleAdd(data)
    toggle(false)
  }

  const addPlaceholder = (string, stringToBeAdded) => {
    return string + stringToBeAdded
  }

  const createEditTaskForm = () => {
    return (
      <KooperForm className="errorLabel">
        <KooperFormField required>
          <label>Select Type</label>
          <Controller
            name="type"
            render={({value, onChange, onBlur}) => (
              <KooperFormSelect
                fluid
                options={taskTypes}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                onBlur={onBlur}
                error={
                  errors.type && {
                    content: errors.type.message,
                  }
                }
              />
            )}
            control={control}
          />
        </KooperFormField>

        <KooperFormField required>
          <label>
            Subject
            <div className="mailActivity" style={{float: 'right'}}>
              <KooperDropdown button icon="chevron down" text="Insert Merge Fields">
                <KooperDropdownMenu>
                  {placeHolderList.map(title => (
                    <KooperDropdownItem onClick={() => setValue('subject', addPlaceholder(currentSubject, title.value))}>
                      {title.text}
                    </KooperDropdownItem>
                  ))}
                </KooperDropdownMenu>
              </KooperDropdown>
            </div>
          </label>
          <Controller
            name="subject"
            control={control}
            render={({value, onChange, onBlur}) => (
              <KooperFormInput
                placeholder="Type your Subject.."
                maxLength={30}
                value={value}
                onChange={e => onChange(e.target.value)}
                onBlur={onBlur}
                error={
                  errors.subject && {
                    content: errors.subject.message,
                  }
                }
              />
            )}
          />
        </KooperFormField>

        {currentType === 'call' && (
          <KooperFormGroup className="fields" inline>
            <label>Direction</label>
            <Controller
              name="callType"
              render={({value, onChange}) => (
                <>
                  <KooperFormRadio
                    label="Inbound"
                    value="inbound"
                    checked={value === 'inbound'}
                    onClick={(e, {value}) => onChange(value)}
                    error={
                      errors.callType && {
                        content: errors.callType.message,
                      }
                    }
                  />
                  <KooperFormRadio
                    label="Outbound"
                    value="outbound"
                    checked={value === 'outbound'}
                    onClick={(e, {value}) => onChange(value)}
                    error={
                      errors.callType && {
                        content: errors.callType.message,
                      }
                    }
                  />
                </>
              )}
              control={control}
            />
          </KooperFormGroup>
        )}

        <KooperFormGroup widths="equal">
          <KooperFormField required>
            <label>Status</label>
            <Controller
              name="status"
              render={({value, onChange, onBlur}) => (
                <KooperFormSelect
                  selection
                  search
                  fluid
                  options={statusList}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  error={
                    errors.status && {
                      content: errors.status.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>

          <KooperFormField required>
            <label>Priority</label>
            <Controller
              name="priority"
              render={({value, onChange, onBlur}) => (
                <KooperFormSelect
                  fluid
                  search
                  selection
                  options={priorityList}
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  onBlur={onBlur}
                  error={
                    errors.priority && {
                      content: errors.priority.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>
        </KooperFormGroup>
        <KooperFormGroup widths="equal">
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Duration</label>
              <KooperTooltip trigger={<SvgIcon path="common/question" />} content="Select number of days" size="mini" />
            </div>
            <Controller
              name="duration"
              control={control}
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  placeholder="Duration"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  onBlur={onBlur}
                  error={
                    errors.duration && {
                      content: errors.duration.message,
                    }
                  }
                />
              )}
            />
          </KooperFormField>
        </KooperFormGroup>
        <KooperFormField>
          <label>Description</label>
          <Controller
            name="description"
            render={({value, onChange, onBlur}) => (
              <KooperFormTextArea
                maxLength={200}
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                }}
                onBlur={onBlur}
                error={
                  errors.description && {
                    content: errors.description.message,
                  }
                }
              />
            )}
            control={control}
          />
        </KooperFormField>
      </KooperForm>
    )
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={open}
      onClose={() => toggle(false)}
      size="tiny"
      centered={false}
    >
      <KooperModalHeader >{startCase(addType)}</KooperModalHeader>
      <KooperModalContent scrolling>{createEditTaskForm()}</KooperModalContent>
      <KooperModalActions>
        <KooperButton basic className="mr-3" onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(data => createTask(data))}>
          Save
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default CreateTask
