import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormTextArea,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'
import {getUserInfo} from 'utils/local-storage'
import {GET_ALL_CONTACTS_IDS, GET_PROJECTS_LOOKUP, GET_SINGLE_PROJECT, GET_USERS, UPDATE_PROJECT} from 'actions/types'
import {getProjectsLookup, getSingleProject, updateProject} from 'actions/projects'
import {getUsers} from 'actions/settings'
import {removeDoubleQuotes} from 'utils/helper'
import {createProjectAdvanceOptionSchema, editProjectDetailsSchema} from 'validation/Projects/projects/projects.schema'
import {getAllContactsIds} from 'actions/contacts'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import ProjectAdvanceOptionCarousel from './ProjectModalSteps/ProjectAdvanceOptionCarousel'

const editProjectDetailsDefaultValues = {
  title: '',
  description: '',
}

const editProjectAdvanceOptionDefaultValues = {
  assignedTo: [Number(getUserInfo().id)],
  managerId: null,
  contactIds: [],
  projectStartDate: new Date(),
  projectEndDate: new Date(),
  categoryId: null,
  status: 'active',
}

const EditProjectModal = ({open, toggle, id}) => {
  const dispatch = useDispatch()

  const [usersList, setUsersList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [contactsList, setContactsList] = useState([])
  const [currentSlide, setCurrentSlide] = useState(0)
  const [projectCreateData, setProjectCreateData] = useState({})

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {categories = []} = useSelector(state => state.projects)

  const {
    handleSubmit: handleSubmitProjectDetails,
    control: controlProjectDetails,
    errors: errorsProjectDetails,
    reset: resetProjectDetails,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: editProjectDetailsDefaultValues,
    resolver: joiResolver(editProjectDetailsSchema),
  })

  const {
    handleSubmit: handleSubmitProjectAdvanceOptions,
    control: controlProjectAdvanceOptions,
    errors: errorsProjectAdvanceOptions,
    reset: resetProjectAdvanceOptions,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: editProjectAdvanceOptionDefaultValues,
    resolver: joiResolver(createProjectAdvanceOptionSchema),
  })

  useApiResponse({
    action: getSingleProject,
    payload: id,
    dependency: [id],
    enabled: id,
    label: GET_SINGLE_PROJECT,
    storePath: 'projects.projectData',
    onSuccess: projectData => {
      const projectDetailsData = _.pick(projectData, ['title', 'description'])
      const projectAdvanceOptionData = _.pick(projectData, [
        'assignedTo',
        'managerId',
        'contactIds',
        'categoryId',
        'status',
      ])
      projectAdvanceOptionData.projectStartDate = projectData.startDate
      projectAdvanceOptionData.projectEndDate = projectData.endDate
      resetProjectDetails(projectDetailsData)
      resetProjectAdvanceOptions(projectAdvanceOptionData)
    },
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUsersList(list || [])
    },
  })

  useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
    onSuccess: contactsIdsList => {
      const list = contactsIdsList.map(({id, name}) => ({
        key: id,
        value: id,
        text: name,
      }))
      setContactsList(list || [])
    },
  })

  useEffect(() => {
    dispatch(getProjectsLookup({lookup: ['categories', 'users']}))
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_PROJECTS_LOOKUP)) {
      if (categories.length === 0) {
        setCategoryList([{key: 0, value: 0, text: 'General'}])
      } else {
        setCategoryList([...categories.map(({id, name}) => ({key: id, value: id, text: name}))])
      }
    }
  }, [successLabels, categories])

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECT)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const carousels = ['projectDetails', 'advanceOption']

  const actionCancelModal = action => {
    if (action === 'prev') {
      setCurrentSlide(currentSlide => currentSlide - 1)
    }
    if (action === 'next') {
      setCurrentSlide(currentSlide => currentSlide + 1)
    }
  }

  const submitForm = data => {
    if (currentSlide === 0) {
      const payload = {...data}
      setProjectCreateData({...projectCreateData, ...payload})
      actionCancelModal('next')
    }
    if (currentSlide === 1) {
      setProjectCreateData({...projectCreateData, ...data})
      const {projectStartDate, projectEndDate, ...resetData} = data
      const payload = {
        ...projectCreateData,
        ...resetData,
        startDate: projectStartDate,
        endDate: projectEndDate,
      }
      dispatch(updateProject(id, payload))
    }
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader className="project-modal-header">
        {id ? 'Edit Project' : 'Add Project'}{' '}
        <div className="project-step-container">
          <div className={`project-step ${currentSlide === 1 ? 'completed' : ''}`}>
            <span>1</span>
          </div>
          <div className="project-step-line" />
          <div className="project-step">
            <span>2</span>
          </div>
        </div>
      </KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <div style={{display: currentSlide === 0 ? '' : 'none'}}>
            <KooperFormField required>
              <div className="info-header">
                <label>Title</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Give your project a title"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                name="title"
                control={controlProjectDetails}
                render={({value, onChange}) => (
                  <KooperFormInput
                    maxLength={20}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errorsProjectDetails.title && {content: removeDoubleQuotes(errorsProjectDetails.title.message)}
                    }
                  />
                )}
              />
            </KooperFormField>

            <KooperFormField required>
              <div className="info-header">
                <label>Description</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Write a brief description about the project"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                name="description"
                control={controlProjectDetails}
                render={({value, onChange}) => (
                  <KooperFormTextArea
                    maxLength={200}
                    style={{resize: 'none'}}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errorsProjectDetails.description && {
                        content: removeDoubleQuotes(errorsProjectDetails.description.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
          </div>

          <ProjectAdvanceOptionCarousel
            style={{display: currentSlide === 1 ? '' : 'none'}}
            control={controlProjectAdvanceOptions}
            errors={errorsProjectAdvanceOptions}
            usersList={usersList}
            contactsList={contactsList}
            categoryList={categoryList}
            setCategoryList={setCategoryList}
          />
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        {currentSlide > 0 && (
          <KooperButton basic onClick={() => actionCancelModal('prev')}>
            Prev Step
          </KooperButton>
        )}
        <KooperButton
          primary
          onClick={
            currentSlide === 0 ? handleSubmitProjectDetails(submitForm) : handleSubmitProjectAdvanceOptions(submitForm)
          }
        >
          {currentSlide === carousels.length - 1 ? 'Update Project' : 'Next Step'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default EditProjectModal
