import React, {useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {ResponsivePie} from '@nivo/pie'
import {ResponsiveBar} from '@nivo/bar'
import Moment from 'moment'
import {KooperGrid, KooperGridColumn, KooperCard, KooperGridRow, KooperCardContent, KooperCardHeader, KooperTooltip} from 'kooper-library'
import {VENDORS_BY_CATEGORY, VENDORS_OVER_TIME} from 'actions/types'
import {totalVendors, newVendors, vendorsByCategory, vendorsOverTime} from 'actions/reports_vendors'
import {getGridYValuesBar, maxOfGraphBar} from 'utils/graph'
import {get} from 'utils/helper'
import NoDataChart from 'components/common/NoDataChart'
import CustomLoader from 'components/common/CustomLoader'
import {UserDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import {DATE_RANGE_KEY} from 'constants/variables'

const Vendors = () => {
  const dispatch = useDispatch()

  const {analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)
  const {
    totalVendorsData = [],
    newVendorsData = [],
    vendorsByCategoryData = [],
    vendorsOverTimeData = [],
  } = useSelector(state => state.reportsVendors)
  const {type = []} = useSelector(state => state.apiReducer)
  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const financeAccess = analyticsAccess?.finance
  const financeReportsPermissions = reportsPermissions?.finance_reports

  const diff = Moment(get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))).diff(
    Moment(get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))),
    'days'
  )

  const actionsToDispatch = useCallback(
    data => {
      dispatch(totalVendors(data))
      dispatch(newVendors(data))
      dispatch(vendorsOverTime(data))
      dispatch(vendorsByCategory(data))
    },
    [dispatch]
  )

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/vendor" />
            <h5>Vendors</h5>
          </div>
        </div>
        {financeAccess && financeReportsPermissions && (
          <div className="page-action">
            <UserDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              userId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
            />
          </div>
        )}
      </div>
      <KooperGrid columns="equal">
        <KooperGridColumn width={8} className="small-cards-home">
          <KooperCard fluid>
            <KooperCardContent>
              <KooperGrid columns={2}>
                <KooperGridRow>
                  <KooperGridColumn>
                    <KooperCardContent className="valueBox">
                      <KooperCardHeader>Total</KooperCardHeader>
                      <h3>{totalVendorsData}</h3>
                    </KooperCardContent>
                  </KooperGridColumn>
                  <KooperGridColumn>
                    <KooperCardContent className="valueBox">
                      <KooperCardHeader>New</KooperCardHeader>
                      <h3>
                        {get(['new', 'value'], newVendorsData, '')}
                        <KooperTooltip
                          content="New"
                          size="mini"
                          position="bottom center"
                          trigger={
                            <span
                              className={get(['new', 'growth'], newVendorsData, []) === 'positive' ? '' : 'text-danger'}
                            >
                              {get(['new', 'growth'], newVendorsData, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['new', 'percentage'], newVendorsData, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </KooperCardContent>
                  </KooperGridColumn>
                </KooperGridRow>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn width={8}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Vendors by Category</KooperCardHeader>
              <KooperTooltip
                content="Breakdown of vendors with respect to category"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(VENDORS_BY_CATEGORY) && <CustomLoader />}
              {!type.includes(VENDORS_BY_CATEGORY) && !vendorsByCategoryData?.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={vendorsByCategoryData}
                  colors={{datum: 'data.color'}}
                  margin={{top: 30, right: 30, bottom: 30, left: 30}}
                  startAngle={-45}
                  innerRadius={0.5}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableArcLinkLabels={false}
                  sliceLabel={({value}) => `${value}`}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, value, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}: {value} ({percentage}%)
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 100,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(VENDORS_BY_CATEGORY) && <NoDataChart top="35px" />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Vendors OverTime</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of vendors performance over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(VENDORS_OVER_TIME) && <CustomLoader />}
              {!type.includes(VENDORS_OVER_TIME) && (
                <ResponsiveBar
                  data={vendorsOverTimeData}
                  indexBy="time"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(vendorsOverTimeData, 'value')}
                  gridYValues={getGridYValuesBar(vendorsOverTimeData, 'value')}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    format: tick => {
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(vendorsOverTimeData, 'value'),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default Vendors
