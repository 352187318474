/* eslint-disable no-plusplus */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {FiChevronRight} from 'react-icons/fi'
import {MdFiberManualRecord} from 'react-icons/md'
import _ from 'lodash'
import moment from 'moment'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperDropdown,
  KooperLabel,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {DELETE_FORECAST, GET_ALL_PIPELINES, GET_FORECAST_LIST, GET_TEAMS_LIST, GET_USERS} from 'actions/types'
import {getPipelines} from 'actions/deals'
import {deleteForecast, getForecastDetail, getForecastList, getForecastSetting} from 'actions/forecast'
import DeleteModal from 'components/common/DeleteModal'
import create from 'assets/create.svg'
import CreateFirst from 'components/common/CreateFirst'
import {get, getPermissionTooltipContent, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import {getAllTeams} from 'actions/settings_organisation'
import useApiResponse from 'hooks/impure/useApiResponse'
import CreateForecastModal from './CreateForecastModal'

const Forecast = props => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [createForecast, setCreateForecast] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [forecastId, setForecastId] = useState(null)
  const [activeIndex, setActiveIndex] = useState(-1)
  const [usersList, setUsersList] = useState([])
  const [pipelinelist, setPipelineList] = useState([])
  const [teamList, setTeamsList] = useState([])
  const [filter, setFilter] = useState({})
  const [pipelineFilter, setPipelineFilter] = useState({})

  const {forecastList = []} = useSelector(state => state.forecast)
  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_FORECAST_LIST})
  const {salesAndCrmAccess, forecastingPermissions} = useUserPermissions()
  const salesForecastingAccess = salesAndCrmAccess?.salesForecasting
  const manageForecastingPermissions = forecastingPermissions?.manage_forecasting

  useEffect(() => {
    if (successLabels.includes(DELETE_FORECAST)) {
      setForecastId(null)
    }
  }, [successLabels])

  useEffect(() => {
    if (salesForecastingAccess && manageForecastingPermissions) {
      dispatch(getForecastSetting())
    }
  }, [salesForecastingAccess, manageForecastingPermissions, dispatch])

  useEffect(() => {
    if (salesForecastingAccess && manageForecastingPermissions) {
      if (get(['createForecast'], location.state)) {
        setCreateForecast(get(['createForecast'], location.state))
        dispatch(getForecastDetail(get(['forecastId'], location.state)))
      }
    }
  }, [salesForecastingAccess, manageForecastingPermissions, location.state, dispatch])

  useEffect(() => {
    if (!salesForecastingAccess || !manageForecastingPermissions) return
    if (filter) {
      dispatch(getForecastList(filter))
    }
    if (pipelineFilter) {
      dispatch(getForecastList(pipelineFilter))
    }
  }, [salesForecastingAccess, manageForecastingPermissions, dispatch, filter, pipelineFilter])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const usersList = getUsersList.users.map(list => ({
        value: list.id,
        key: list.id,
        text: `${list.firstName} ${list.lastName}`,
      }))

      setUsersList(usersList)
    },
  })

  useApiResponse({
    action: getAllTeams,
    enabled: true,
    label: GET_TEAMS_LIST,
    storePath: 'settingsOrganisation.teamsList',
    onSuccess: teamsList => {
      const teams = teamsList.map(team => ({
        key: team.id,
        value: team.id,
        text: team.name,
        isTeam: true,
      }))
      setTeamsList(teams)
    },
  })

  useApiResponse({
    action: getPipelines,
    dependency: [salesForecastingAccess, manageForecastingPermissions],
    enabled: salesForecastingAccess && manageForecastingPermissions,
    label: GET_ALL_PIPELINES,
    storePath: 'deals.getAllPipelineData',
    onSuccess: getAllPipelineData => {
      const pipeline = getAllPipelineData.map(pipeline => ({
        key: pipeline.id,
        text: pipeline.pipeline,
        value: pipeline.id,
      }))
      setPipelineList(pipeline)
    },
  })

  const userAndtemasList = [{key: 'All', value: ' ', text: 'All'}, ...usersList, ...teamList]
  const pipelineList = [{key: 'All', value: ' ', text: 'All'}, ...pipelinelist]

  const getNext3Months = d => {
    const st = []
    for (let i = 0; i < 3; i++) {
      st.push(moment(d).add(i, 'month').format('MMM'))
    }
    return st.join('-')
  }

  if (isLoading && salesForecastingAccess && manageForecastingPermissions) {
    return <PageLoader />
  }

  if (
    !salesForecastingAccess ||
    !manageForecastingPermissions ||
    (forecastList.length === 0 && _.isEmpty(filter) && _.isEmpty(pipelineFilter))
  ) {
    return (
      <>
        <CreateFirst
          src={<SvgIcon path="settings/users" />}
          Feature1="Forecast KPIs"
          Feature2="Make better decisions"
          Feature3="Multi-pipeline forecasting"
          list1="Set KPIs and metrics used for sales forecasting."
          list2="Analyze the percentage of forecast achieved by team or agent"
          list3="Manage multiple forecasts for multiple pipelines, organizing them as needed"
          addFirst={() => salesForecastingAccess && manageForecastingPermissions && setCreateForecast(!createForecast)}
          tooltip={!salesForecastingAccess || !manageForecastingPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: salesForecastingAccess,
            roleAccess: manageForecastingPermissions,
          })}
          buttonText="Create Forecast"
          img={create}
          header="Forecast"
          subHeader="Create and Customize your forecasting model to predict future goals accurately"
        />

        {createForecast && (
          <CreateForecastModal
            forecastId={forecastId}
            setForecastId={setForecastId}
            createForecast={createForecast}
            setCreateForecast={() => setCreateForecast(!createForecast)}
            {...props}
          />
        )}
      </>
    )
  }

  return (
    <div style={{height: '100%'}}>
      <KooperContainer className="mt-4">
        <div className="page-header px-0">
          <div className="page-title">
            <div className="page-titleIcon">
              <SvgIcon path="navigation/forecasting" />
              <h5>Forecasting ({_.size(forecastList) || 0})</h5>
            </div>
          </div>
          <div className="page-action">
            <KooperTooltip
              content="Select pipeline"
              position="top center"
              size="mini"
              trigger={
                <KooperDropdown
                  selection
                  placeholder="Select pipeline"
                  options={pipelineList}
                  defaultValue={' '}
                  onChange={(e, {value}) => setPipelineFilter({pipelineId: value})}
                />
              }
            />
            <KooperTooltip
              content="Select User & Team"
              position="top center"
              size="mini"
              trigger={
                <KooperDropdown
                  selection
                  placeholder="Select User or Teams"
                  options={userAndtemasList}
                  defaultValue={' '}
                  onChange={(e, {value}) => setFilter({userId: value})}
                />
              }
            />
            <LockPermissionTooltip isRoleAccessDenied={!manageForecastingPermissions}>
              <KooperButton
                primary
                className={!manageForecastingPermissions ? 'disabled-button' : ''}
                content="Create Forecast"
                onClick={() => manageForecastingPermissions && setCreateForecast(!createForecast)}
              />
            </LockPermissionTooltip>
          </div>
        </div>
        {forecastList.length > 0 ? (
          forecastList.map((list, index) => (
            <KooperCard
              fluid
              className="foreCast"
              onClick={() => {
                if (list.isRecurring) {
                  if (index === activeIndex) {
                    setActiveIndex(-1)
                  } else {
                    setActiveIndex(index)
                  }
                }
              }}
            >
              <KooperCardContent>
                {list.isRecurring && (
                  <span className="arrow">
                    <div className="d-flex">
                      <KooperTooltip
                        content={
                          manageForecastingPermissions
                            ? 'Edit'
                            : getPermissionTooltipContent({roleAccess: manageForecastingPermissions})
                        }
                        position="top center"
                        size="mini"
                        trigger={
                          <KooperButton
                            icon
                            className="mr-2"
                            onClick={e => {
                              e.preventDefault()
                              e.stopPropagation()
                              if (manageForecastingPermissions) {
                                setForecastId(list.id)
                                setCreateForecast(!createForecast)
                              }
                            }}
                          >
                            <SvgIcon path="common/edit2" />
                          </KooperButton>
                        }
                      />
                      <KooperTooltip
                        content={
                          manageForecastingPermissions
                            ? 'Delete'
                            : getPermissionTooltipContent({roleAccess: manageForecastingPermissions})
                        }
                        position="top center"
                        size="mini"
                        trigger={
                          <KooperButton
                            icon
                            onClick={e => {
                              e.preventDefault()
                              e.stopPropagation()
                              if (manageForecastingPermissions) {
                                setForecastId(list.id)
                                setIsDeleteModalOpen(true)
                              }
                            }}
                          >
                            <SvgIcon path="common/delete" />
                          </KooperButton>
                        }
                      />
                    </div>
                    {activeIndex === index ? <SvgIcon path="common/down" /> : <FiChevronRight />}
                  </span>
                )}
                <div className="info">
                  <h1>
                    {startCase(list.name)}
                    {list?.pipeline && (
                      <KooperLabel className="kooper-label">{_.get(list, 'pipeline.pipeline', '')}</KooperLabel>
                    )}
                  </h1>
                  <h2>
                    {`${startCase(_.get(list, 'assignee.firstName')) || ''} ${
                      startCase(_.get(list, 'assignee.lastName')) || ''
                    }`}
                  </h2>
                  <p>
                    <span>Duration:</span>
                    {startCase(list.timePeriod)}
                  </p>
                  <p>
                    <span>Created by: </span>
                    {`${startCase(_.get(list, 'created.firstName')) || '-'} ${
                      startCase(_.get(list, 'created.lastName')) || ''
                    }`}
                    <MdFiberManualRecord />
                    {list.startFrom}
                  </p>
                  <p>
                    <span>Achieved:</span>
                    {list.achived}
                  </p>
                </div>

                {activeIndex === index && list.isRecurring && (
                  <KooperTable basic>
                    <KooperTableHeader>
                      <KooperTableRow>
                        <KooperTableHeaderCell />
                        {(list.recurring || []).map(data => (
                          <KooperTableHeaderCell>
                            {list.timePeriod === 'quarterly'
                              ? `${getNext3Months(data.date)} ${moment(data.date).format('YYYY')}`
                              : moment(data.date).format('MMM YYYY')}
                          </KooperTableHeaderCell>
                        ))}
                      </KooperTableRow>
                    </KooperTableHeader>
                    <KooperTableBody>
                      <KooperTableRow>
                        <KooperTableCell>Percentage</KooperTableCell>
                        {(list.recurring || []).map(data => (
                          <KooperTableCell>
                            <p>{data.achived !== 0 ? `${((data.achived * 100) / data.target).toFixed(1)}%` : '-'}</p>
                          </KooperTableCell>
                        ))}
                      </KooperTableRow>
                      <KooperTableRow>
                        <KooperTableCell>Achieved</KooperTableCell>

                        {(list.recurring || []).map(data => (
                          <KooperTableCell>
                            <p>{moment(data.date).isSame(moment().format(), 'month') ? data.achived : '-'}</p>
                          </KooperTableCell>
                        ))}
                      </KooperTableRow>
                      <KooperTableRow>
                        <KooperTableCell>Total</KooperTableCell>

                        {(list.recurring || []).map(data => (
                          <KooperTableCell>
                            <p>{moment(data.date).isSame(moment().format(), 'month') ? data.target : '-'}</p>
                          </KooperTableCell>
                        ))}
                      </KooperTableRow>
                    </KooperTableBody>
                  </KooperTable>
                )}
              </KooperCardContent>
            </KooperCard>
          ))
        ) : (
          <KooperCard fluid className="foreCast nodataforeCast">
            <KooperCardContent>
              <div className="info">
                <h1>No data Available</h1>
              </div>
            </KooperCardContent>
          </KooperCard>
        )}
      </KooperContainer>

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          modalText="Are you sure you want to delete ?"
          deleteAction={deleteForecast}
          idTobeDeleted={forecastId}
          type={DELETE_FORECAST}
        />
      )}
      {createForecast && (
        <CreateForecastModal
          forecastId={forecastId}
          setForecastId={setForecastId}
          createForecast={createForecast}
          setCreateForecast={() => setCreateForecast(!createForecast)}
          {...props}
        />
      )}
    </div>
  )
}

export default Forecast
