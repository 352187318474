import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {useHistory} from 'react-router'
import {KooperButton, KooperCard, KooperCardContent, KooperGrid, KooperGridColumn, KooperImage} from 'kooper-library'
import {GOOGLE_CALENDAR_DISCONNECT} from 'actions/types'
import {googleCalendarAuthCheck, googleCalendarDisconnect} from 'actions/meeting'
import create from 'assets/calendar_dutt.svg'
import {get} from 'utils/helper'
import {DATE_RANGE_KEY} from 'constants/variables'
import {SETTINGS_PATH} from 'constants/path'

const GoogleCalendar = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {userData = {}} = useSelector(state => state.meetings)

  const logout = (res, req) => {
    dispatch(googleCalendarDisconnect())
  }

  useEffect(() => {
    if (!get(['GCAL'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))) {
      history.push(`${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.MEETINGS}`)
    }
    dispatch(googleCalendarAuthCheck())
  }, [dispatch, history])

  useEffect(() => {
    if (successLabels.includes(GOOGLE_CALENDAR_DISCONNECT)) {
      localStorage.removeItem('GCAL')
      history.push(`${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.MEETINGS}`)
    }
  }, [successLabels, history])

  // const email = localStorage.getItem("GEMAIL");

  return (
    <div>
      <KooperCard fluid>
        <KooperCardContent>
          <div className="createPage">
            <h2>Meetings</h2>
            <KooperGrid columns="equal">
              <KooperGridColumn>
                <div className="createPageInfo">
                  <p>{`Now connected to Google Calendar account ${_.get(userData, 'email') || ''}`}</p>
                  <p>
                    Calendar sync Calendar sync logs new meetings from your calendar to existing contacts in Kooper. You
                    can also create and send meeting invites from Kooper. This calendar setting is separate from the
                    meetings tool.
                  </p>
                  <p>{`${_.get(userData, 'family_name') || ''}`}</p>
                  <KooperButton onClick={() => logout()}>Disconnect</KooperButton>
                </div>
              </KooperGridColumn>
              <KooperGridColumn>
                <KooperImage src={create} />
              </KooperGridColumn>
            </KooperGrid>
          </div>
        </KooperCardContent>
      </KooperCard>
    </div>
  )
}

export default GoogleCalendar
