import React from 'react'
import {KooperTable, KooperTableHeader, KooperTableRow, KooperTableHeaderCell, KooperTableBody, KooperTableCell} from 'kooper-library'
import {getFormattedDate} from 'utils/helper'

function StandardTemplate({data}) {
  const {setupData = {}, template = {}, products = {}, addressTemplate = {}, style, preview} = data

  const addressData = addressTemplate[0]

  const {billingAddress, shippingAddress} = setupData

  return (
    <div className="pruchase-order" style={style}>
      <div className="pruchase-order-wrap">
        <div className="d-flex">
          <div className="purchase-logo">
            <img src={preview} alt="logo" />
          </div>
          <div className="purchase-name">
            <span>{template?.template?.header}</span>
          </div>
        </div>
        <div className="d-flex mt-2" style={{alignItems: 'flex-start'}}>
          <div className="purchase-order-number">
            <span>
              Purchase Order: <strong>{setupData?.poNumber}</strong>
            </span>
            <span>PO Date: {getFormattedDate(setupData?.poDate)}</span>
            <span>PO Status: {setupData?.status}</span>
          </div>
          <div className="purchase-order-company-section">
            <strong>Company Name: {addressData?.companyName}</strong>
            <span>
              <strong>Street Address: </strong>
              {addressData?.streetAddress}
            </span>
            <span>
              <strong>City: </strong>
              {addressData?.city},<strong>State: </strong>
              {addressData?.state},<strong>Zip: </strong>
              {addressData?.postalCode}
            </span>
            <span>
              <strong>Phone:</strong> {addressData?.phone}
            </span>
            <span>
              <strong>Email:</strong> {addressData?.email}
            </span>
          </div>
        </div>

        <KooperTable className="purchase-order-top-table">
          <KooperTableHeader>
            <KooperTableHeaderCell style={{background: template.template?.color}}>BILLING ADDRESS</KooperTableHeaderCell>
            <KooperTableHeaderCell style={{background: template.template?.color}}>SHIPPING ADDRESS</KooperTableHeaderCell>
          </KooperTableHeader>
          <KooperTableBody>
            <KooperTableRow>
              <KooperTableCell>
                <div className="purchase-order-to-section">
                  <span>
                    <strong>Street Address: </strong>
                    {billingAddress?.streetAddress}
                  </span>
                  <span>
                    <strong>City: </strong>
                    {billingAddress?.locality}
                  </span>
                  <span>
                    <strong>State: </strong>
                    {billingAddress?.state}
                  </span>
                  <span>
                    <strong>Country: </strong>
                    {billingAddress?.country}
                  </span>
                  <span>
                    <strong>Postal Code: </strong> {billingAddress?.postalCode}
                  </span>
                </div>
              </KooperTableCell>
              <KooperTableCell>
                <div className="purchase-order-shipTo-section">
                  <span>
                    <strong>Street Address: </strong>
                    {shippingAddress?.streetAddress}
                  </span>
                  <span>
                    <strong>City: </strong>
                    {shippingAddress?.locality}
                  </span>
                  <span>
                    <strong>State: </strong>
                    {shippingAddress?.state}
                  </span>
                  <span>
                    <strong>Country: </strong>
                    {shippingAddress?.country}
                  </span>
                  <span>
                    <strong>Postal Code: </strong> {shippingAddress?.postalCode}
                  </span>
                </div>
              </KooperTableCell>
            </KooperTableRow>
          </KooperTableBody>
        </KooperTable>

        <KooperTable className="purchase-order-top-table">
          <KooperTableHeader>
            <KooperTableHeaderCell style={{background: template.template?.color}}>P.O Date</KooperTableHeaderCell>
            <KooperTableHeaderCell style={{background: template.template?.color}}>Requisition Number</KooperTableHeaderCell>
            <KooperTableHeaderCell style={{background: template.template?.color}}>Shipped Via</KooperTableHeaderCell>
            <KooperTableHeaderCell style={{background: template.template?.color}}>Tracking Number</KooperTableHeaderCell>
          </KooperTableHeader>
          <KooperTableBody>
            <KooperTableRow>
              <KooperTableCell>{getFormattedDate(setupData?.poDate)}</KooperTableCell>
              <KooperTableCell>{setupData?.requisitionNumber}</KooperTableCell>
              <KooperTableCell>{setupData?.carrier}</KooperTableCell>
              <KooperTableCell>{setupData?.trackingNumber}</KooperTableCell>
            </KooperTableRow>
          </KooperTableBody>
        </KooperTable>

        <KooperTable basic className="purchase-order-top-table with-border">
          <KooperTableHeader>
            <KooperTableHeaderCell style={{width: 60, background: template.template?.color}}>
              Product Id
            </KooperTableHeaderCell>
            <KooperTableHeaderCell style={{width: 60, background: template.template?.color}}>Qty</KooperTableHeaderCell>
            <KooperTableHeaderCell style={{width: 130, background: template.template?.color}}>
              Unit Price
            </KooperTableHeaderCell>
            <KooperTableHeaderCell style={{background: template.template?.color}}>Unit Amount</KooperTableHeaderCell>
            <KooperTableHeaderCell style={{width: 200, background: template.template?.color}}>Discount</KooperTableHeaderCell>
            <KooperTableHeaderCell style={{width: 200, background: template.template?.color}}>Tax</KooperTableHeaderCell>
            <KooperTableHeaderCell style={{width: 90, background: template.template?.color}}>Total</KooperTableHeaderCell>
          </KooperTableHeader>
          <KooperTableBody>
            {products?.products?.map(prod => {
              return (
                <KooperTableRow>
                  <KooperTableCell>{prod.productId}</KooperTableCell>
                  <KooperTableCell>{prod.quantity}</KooperTableCell>
                  <KooperTableCell>{prod.unitPrice}</KooperTableCell>
                  <KooperTableCell>{prod.unitAmount}</KooperTableCell>
                  <KooperTableCell>{prod.discountValue}%</KooperTableCell>
                  <KooperTableCell>{prod.taxValue}%</KooperTableCell>
                  <KooperTableCell>{prod.unitTotal}</KooperTableCell>
                </KooperTableRow>
              )
            })}
            <KooperTableRow>
              <KooperTableCell className="purchase-order-terms-description" rowSpan="6" colSpan="3">
                <div className="purchase-order-terms">
                  <span>Terms and Conditions</span>
                  <span>{products?.terms}</span>
                </div>
                <div className="purchase-order-description">
                  <span>Description</span>
                  <span>{setupData?.description}</span>
                </div>
              </KooperTableCell>
              <KooperTableCell>
                <strong>Sub Total</strong>
              </KooperTableCell>
              <KooperTableCell style={{borderRight: '1px solid var(--border-color)'}}>{products?.subTotal}</KooperTableCell>
            </KooperTableRow>
            <KooperTableRow>
              <KooperTableCell>
                <strong>Discount</strong>
              </KooperTableCell>
              <KooperTableCell style={{borderRight: '1px solid var(--border-color)'}}>{products?.discount}%</KooperTableCell>
            </KooperTableRow>
            <KooperTableRow>
              <KooperTableCell>
                <strong>Tax</strong>
              </KooperTableCell>
              <KooperTableCell style={{borderRight: '1px solid var(--border-color)'}}>{products?.tax}%</KooperTableCell>
            </KooperTableRow>
            <KooperTableRow>
              <KooperTableCell>
                <strong>Adjustment</strong>
              </KooperTableCell>
              <KooperTableCell style={{borderRight: '1px solid var(--border-color)'}}>{products?.adjustment}</KooperTableCell>
            </KooperTableRow>
            <KooperTableRow>
              <KooperTableCell>
                <strong>Total</strong>
              </KooperTableCell>
              <KooperTableCell style={{borderRight: '1px solid var(--border-color)'}}>{products?.total}</KooperTableCell>
            </KooperTableRow>
          </KooperTableBody>
        </KooperTable>
        <div style={{textAlign: 'center'}}>
          <h6>{template.template?.footer}</h6>
        </div>
      </div>
    </div>
  )
}

export default StandardTemplate
