import React, {useRef, useState} from 'react'
import {KooperCard, KooperCardContent, KooperInput} from 'kooper-library'
import {getAccountSetting} from 'actions/account'
import {copyToClipboard} from 'utils/helper'
import SettingLayout from 'layouts/settingLayout'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {GET_ACCOUNT_SETTING} from 'actions/types'

export default function Activities() {
  const [copyMsg, setCopyMsg] = useState('Copy')

  const valueRef = useRef(null)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const activitiesLearnMore = settingsLearnMoreUrl?.dataManagement?.activities?.learnMore

  const {data: accountSettingData = {}} = useApiResponse({
    action: getAccountSetting,
    enabled: true,
    label: GET_ACCOUNT_SETTING,
    storePath: 'settings.accountSettingData',
  })

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/activities" />}
      header="Manual Logging"
      subHeader="Log outgoing and incoming emails manually by including these email addresses."
      learnMoreUrl={activitiesLearnMore}
      headerDivider
      table={false}
    >
      <KooperCard fluid>
        <KooperCardContent>
          <h4 style={{marginBottom: '6px'}}>BCC Address</h4>
          <KooperInput
            type="text"
            style={{width: '430px'}}
            value={accountSettingData.bccEmail}
            ref={valueRef}
            action={{
              color: 'blue',
              labelPosition: 'right',
              icon: 'copy',
              content: copyMsg,
              onClick: e => copyToClipboard(e, valueRef, setCopyMsg),
            }}
          />
          <p className="kooper-lead mt-3">
            BCC this email address when you send an email to log outgoing emails in your CRM. Emails BCCed to this
            address will be attached to any matching contacts. If no contact is found, a new one will be created.
          </p>
        </KooperCardContent>
      </KooperCard>
    </SettingLayout>
  )
}
