import React from 'react'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {KooperMenu, KooperMenuItem} from 'kooper-library'
import {isActiveNavlink} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import {PROJECTS_PATH} from 'constants/path'

const ProjectFinanceMenu = () => {
  const history = useHistory()
  const {url} = useRouteMatch()

  const menus = [
    {
      path: PROJECTS_PATH.FINANCE_SUMMARY,
      title: 'Summary',
      icon: <SvgIcon path="finance/summary" />,
    },
    {
      path: PROJECTS_PATH.FINANCE_BUDGETS,
      title: 'Budgets',
      icon: <SvgIcon path="finance/budgets" />,
    },
    {
      path: PROJECTS_PATH.FINANCE_RATES,
      title: 'Rates',
      icon: <SvgIcon path="finance/rates" />,
    },
    {
      path: PROJECTS_PATH.FINANCE_BILLING,
      title: 'Billing',
      icon: <SvgIcon path="finance/billing" />,
    },
  ]

  return (
    <KooperMenu vertical secondary className="projects-side-menu kooper-menu">
      {menus.map(({path, title, icon}) => (
        <KooperMenuItem
          key={path}
          active={isActiveNavlink(`${url}/${path}`)}
          onClick={() => history.push(`${url}/${path}`)}
        >
          {icon} {title}
        </KooperMenuItem>
      ))}
    </KooperMenu>
  )
}

export default ProjectFinanceMenu
