import React from 'react'
import {
  KooperGrid,
  KooperGridColumn,
  KooperImage,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
} from 'kooper-library'
import {getFormattedDate} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

function BasicTemplate({data}) {
  const {
    setupData = {},
    buyerInfo: {contacts = [], company} = {},
    yourInfo = {},
    template = {},
    products = {},
    signature = {},
    preview,
  } = data

  const yourCompany = yourInfo?.company || {}

  return (
    <div className="quote-theme">
      <div className="quote-thm-wrap">
        <div className="quo-thm-one">
          <div className="quo-thm-one-header">
            <h5 className="title" style={{backgroundColor: template.color}}>
              {template.header}
            </h5>
            <div className="date">
              {setupData.quoteNo ? <p>ID : {setupData.quoteNo}</p> : null}
              {/* <MdFiberManualRecord /> */}
              <p>{getFormattedDate(new Date())}</p>
              {setupData?.validTill ? <span>Valid till : {getFormattedDate(setupData.validTill)}</span> : null}
            </div>
          </div>
          <div className="kooper">
            {preview && (
              <div className="logo">
                <KooperImage src={preview} />
              </div>
            )}
            <div className="com-address">
              <h2>{setupData.name}</h2>
            </div>
          </div>
          <KooperGrid style={{padding: '0 100px'}}>
            {contacts?.map(contact => {
              return (
                <KooperGridColumn width={8} key={contact.id}>
                  <div className="bill-to">
                    {/* <h6>Bill To :</h6> */}
                    <h4>{`${contact.firstName} ${contact.lastName}`} </h4>
                    <h5>{contact.jobTitle}</h5>
                    {contact.phone && (
                      <p>
                        <SvgIcon path="settings/phone" /> {contact.phone}
                      </p>
                    )}
                    {contact.email && (
                      <a href="mailto:">
                        <SvgIcon path="common/mail" />
                        {contact.email}
                      </a>
                    )}
                  </div>
                </KooperGridColumn>
              )
            })}

            {company && (
              <KooperGridColumn width={8}>
                <div className="bill-to">
                  <h4>{company.name}</h4>
                  <p>
                    {company.streetAddress} {company.locality}
                  </p>
                  <p>
                    {company.state} {company.country}
                  </p>
                  <p>{company.postalCode}</p>
                </div>
              </KooperGridColumn>
            )}
          </KooperGrid>

          <div className="bill-item">
            {products?.products?.length ? (
              <KooperTable>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell>Product Name</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Item</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Unit Price</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Amount ($)</KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>
                  {products.products.map(prod => {
                    return (
                      <KooperTableRow>
                        <KooperTableCell>{prod.productId}</KooperTableCell>
                        <KooperTableCell>{prod.quantity}</KooperTableCell>
                        <KooperTableCell>{prod.unitPrice}</KooperTableCell>
                        <KooperTableCell>{prod.unitTotal}</KooperTableCell>
                      </KooperTableRow>
                    )
                  })}

                  <KooperTableRow style={{fontWeight: '500', fontSize: '15px'}}>
                    <KooperTableCell colspan="3">Total</KooperTableCell>
                    <KooperTableCell>{products.subTotal}</KooperTableCell>
                  </KooperTableRow>

                  {products?.otherCharges?.map((charge, index) => {
                    return (
                      <KooperTableRow key={`charge${index}`}>
                        <KooperTableCell
                          colspan="2"
                          // rowspan="3"
                          style={{verticalAlign: 'top'}}
                        >
                          {charge.name}
                        </KooperTableCell>
                        <KooperTableCell style={{textAlign: 'right'}}>
                          {charge.type}
                          {charge.subType === '%' ? `( ${charge.value} %)` : null}
                        </KooperTableCell>
                        <KooperTableCell>{charge.total}</KooperTableCell>
                      </KooperTableRow>
                    )
                  })}

                  <KooperTableRow style={{fontWeight: '600', fontSize: '15px'}}>
                    <KooperTableCell colspan="3" style={{textAlign: 'right'}}>
                      Quote Total
                    </KooperTableCell>
                    <KooperTableCell>$ {products.total}</KooperTableCell>
                  </KooperTableRow>
                </KooperTableBody>
              </KooperTable>
            ) : null}

            {template.terms && <div className="tc-info">{template.terms}</div>}

            {template.paymentProcess && <div className="tc-info">{template.paymentProcess}</div>}

            {signature.signature === 'Add Signature' ? (
              <div className="sign my-4">
                <h6>Customer Acceptance</h6>
                <KooperGrid>
                  <KooperGridColumn width={8}>
                    <h5>Signature</h5>
                  </KooperGridColumn>
                  <KooperGridColumn width={4}>
                    <h5>Printed Name</h5>
                  </KooperGridColumn>
                  <KooperGridColumn width={4}>
                    <h5>Date</h5>
                  </KooperGridColumn>
                </KooperGrid>
              </div>
            ) : null}
          </div>

          <div className="kooper">
            <div className="logo">
              {preview && <KooperImage src={preview} />}
              <h2>{yourCompany.name}</h2>
            </div>
            <div className="com-address">
              <p>
                {yourCompany.streetAddress ? `${yourCompany.streetAddress}, ` : ''}
                {yourCompany.locality ? `${yourCompany.locality}, ` : ''}
                {yourCompany.state ? `${yourCompany.state}, ` : ''}
                {yourCompany.country ? `${yourCompany.country}, ` : ''}
                {yourCompany.postalCode ? yourCompany.postalCode : ''}
              </p>
              {/* <p>
                <SvgIcon path="common/mail" /> info@kooper.com <FiPhone className="ml-4" /> +91 12345
                67890
              </p> */}
              {/* <a href="#">www.kooper.com</a> */}
            </div>
          </div>
          <p className="help-line" style={{backgroundColor: template.color}}>
            If you have any questions, Please contact on <b>{yourInfo.phone}</b>
            or
            <a href="mailto:">
              <b>{yourInfo.email}</b>
            </a>
            <p>{template.footer}</p>
          </p>
        </div>
      </div>
    </div>
  )
}

export default BasicTemplate
