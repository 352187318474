/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {FiCheck} from 'react-icons/fi'
import _ from 'lodash'
import {useHistory} from 'react-router'
import {KooperButton, KooperImage} from 'kooper-library'
import {GOOGLE_CALENDAR_ACCESS, MEETING_CREATE} from 'actions/types'

import {meetingCreate} from 'actions/meeting'
import {get} from 'utils/helper'
import circlecurrent from 'assets/stepcircle.png'
import circleremain from 'assets/stepcircle2.png'
import {createMeetingSchema} from 'validation/Sales/meeting.schema'
import {getUsers} from 'actions/settings'
import {MEETINGS_PATH} from 'constants/path'
import {getUserInfo} from 'utils/local-storage'
import Details from './components/Details'
import Configuration from './components/Configuration'
import Availabilty from './components/Availabilty'
import FormQuestion from './components/FormQuestion'
import Notification from './components/Notification'

const steps = [
  {name: 'Details'},
  {name: 'Configuration'},
  {name: 'Availability'},
  {name: 'Form Quesitions'},
  {name: 'Notification'},
]

const MeetingCreate = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const userId = Number(get(['id'], getUserInfo()))

  if (!history.location.state) {
    history.push(`/${MEETINGS_PATH.PREFIX}`)
  }
  const {meetingName, type, calendarId} = history.location.state

  const {isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const [index, setIndex] = useState(0)
  const [profilePicture, setProfilePicture] = useState('null')
  const [uploadProfile, setUploadProfile] = useState(null)
  const [userAccountImage, setUserAccountImage] = useState(false)

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(MEETING_CREATE) || successLabels.includes(GOOGLE_CALENDAR_ACCESS)) {
      history.push(`/${MEETINGS_PATH.PREFIX}`)
    }
  }, [successLabels, history])

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

  const convertArrayToDay = val => {
    if (val === [0, 1, 2, 3, 4, 5, 6]) return 'everyday'
    if (val === [1, 2, 3, 4, 5]) return 'mon-fri'
    if (val === [6, 0]) return 'sat-sun'
    return val
  }

  const convertMinToISTAvailable = val => {
    val = val.map(list => {
      const hoursFrom = Math.floor(list.from / 60)
      const realminFrom = list.from % 60
      const hoursTo = Math.floor(list.to / 60)
      const realminTo = list.to % 60
      return {
        from: new Date(`Wed Dec 16 2020
          ${`0${hoursFrom}`.slice(-2)}:${`0${realminFrom}`.slice(-2)}`),
        to: new Date(`Wed Dec 16 2020 ${`0${hoursTo}`.slice(-2)}:${`0${realminTo}`.slice(-2)}`),
        day: convertArrayToDay(list.day),
      }
    })
    return val
  }

  const initalCreate = {
    meetingName,
    meetingAvatar: '',
    meetingHeading: '',
    // url: "",
    assigneeId: userId,
    ownerDetail: true,
    ownerDesignation: '',
    ownerPhoneNo: null,
    ownerSocialMedia: [],
    displayMessage: '',
    ownerEmail: '',
    meetingDuration: [{time: 30}],
    meetingLocation: '',
    inviteSubject: 'Meeting with {{contact.firstName}}',
    inviteDescription: 'Description',
    meetingLanguage: '',
    meetingDateAndTimeFormat: '',
    meetingTimeZone: tz === 'Asia/Calcutta' ? 'Asia/Kolkata' : tz,
    meetingAvailableTimes: convertMinToISTAvailable([
      {day: [1], from: 540, to: 1080},
      {day: [2], from: 540, to: 1080},
      {day: [3], from: 540, to: 1080},
      {day: [4], from: 540, to: 1080},
      {day: [5], from: 540, to: 1080},
    ]),
    meetingScheduled: true,
    meetingScheduleValue: 12,
    meetingNoticeTime: 60,
    meetingBufferTime: 0,
    meetingTimeFrequency: 0,
    mailNotification: false,
    mailReminder: false,
    videoConferenceLink: '',
    meetingFormFields: [
      {field: 'FirstName', require: true, vaildation: 'text'},
      {field: 'LastName', require: true, vaildation: 'text'},
      {field: 'Email', require: true, vaildation: 'email'},
      {field: 'Phone No', require: false, vaildation: 'number'},
    ],
    formFieldWithKnownValue: true,
    submitFormIfAllKnownValue: false,
    termsAndCondition: true,
    type,
    privacyTitle: 'Title',
    privacyText:
      'Committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us.',
    privacyCheck: 'terms and conditions input checkbox content',
    createdBy: userId,
  }

  const {
    control,
    errors,

    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: {dirtyFields},
  } = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: initalCreate,
    resolver: joiResolver(createMeetingSchema),
  })

  const {
    fields: durationFields,
    append: appendDuration,
    remove: removeDuration,
  } = useFieldArray({
    control,
    name: 'meetingDuration',
  })
  const {
    fields: meetingAvalTime,
    append: appendMeetingAvailableTimes,
    remove: removeMeetingAvailableTimes,
  } = useFieldArray({
    control,
    name: 'meetingAvailableTimes',
  })

  const {
    fields: socialFields,
    append: appendSocial,
    remove: removeSocial,
  } = useFieldArray({
    control,
    name: 'ownerSocialMedia',
  })

  const {
    fields: FormFields,
    append: appendMeetingForm,
    remove: removeMeetingForm,
  } = useFieldArray({
    control,
    name: 'meetingFormFields',
  })

  const dayConvertIntoArray = val => {
    switch (val) {
      case 'everyday': {
        return [0, 1, 2, 3, 4, 5, 6]
      }
      case 'mon-fri': {
        return [1, 2, 3, 4, 5]
      }
      case 'sat-sun': {
        return [6, 0]
      }
      default:
        return val
    }
    // if (val === "everyday") return [0, 1, 2, 3, 4, 5, 6];
    // if (val === "mon-fri") return [1, 2, 3, 4, 5];
    // if (val === "sat-sun") return [6, 0];
    // return val;
  }

  const createMeeting = data => {
    if (index === 4) {
      if (data.meetingDuration) {
        const duration = data.meetingDuration.map(list => {
          return list.time
        })
        data.meetingDuration = _.compact(duration)
      }
      if (data.ownerSocialMedia && data.ownerSocialMedia.url === '') {
        delete data.ownerSocialMedia
      }
      if (data.meetingAvailableTimes) {
        const avaiableTime = data.meetingAvailableTimes.map(({from, to, day}) => {
          const avaialbleFROM = new Date(from)
          const avaialbleTO = new Date(to)
          return {
            from: avaialbleFROM.getHours() * 60 + avaialbleFROM.getMinutes(),
            to: avaialbleTO.getHours() * 60 + avaialbleTO.getMinutes(),
            day: dayConvertIntoArray(day),
          }
        })
        data.meetingAvailableTimes = avaiableTime
      }
      if (data.ownerSocialMedia) {
        const getNewData = _.filter(_.get(data, 'ownerSocialMedia'), item => item.url !== '' && item.url !== undefined)
        data.ownerSocialMedia = getNewData
      }
      const formData = new FormData()
      for (const property in data) {
        if (property && data[property]) {
          if (typeof data[property] === 'string') {
            formData.append(property, data[property])
          } else {
            formData.append(property, JSON.stringify(data[property]))
          }
        }
      }
      // formData.append(
      //   "meetingFormFields",
      //   JSON.stringify(["firstName", "lastName", "email"])
      // );
      if (uploadProfile) {
        formData.append('meetingAvatar', uploadProfile)
      }
      formData.append('meetingName', meetingName)
      formData.append('type', type)
      formData.append('calendarId', calendarId)
      formData.append('assigneeId', data.assigneeId ? data.assigneeId : userId)
      formData.append('createdBy', userId)
      dispatch(meetingCreate(formData))
    } else {
      index < 4 && setIndex(index + 1)
    }
  }

  return (
    <div className="kooper-full-page">
      <div className="page-header">
        <div className="top">
          <div className="title">
            <KooperButton inverted basic onClick={() => history.push(`/${MEETINGS_PATH.PREFIX}`)}>
              Exit
            </KooperButton>
            {index > 0 && (
              <KooperButton inverted basic onClick={() => setIndex(index - 1)}>
                Back
              </KooperButton>
            )}
          </div>

          <div className="center">
            <div className="kooper-steps">
              {steps.map(({name}, i) => {
                return (
                  <div key={name} className={`step ${index > i ? 'active' : index === i && 'focus'}`}>
                    <span>
                      {index > i ? (
                        <FiCheck />
                      ) : index === i ? (
                        <KooperImage src={circlecurrent} />
                      ) : index < i ? (
                        <KooperImage src={circleremain} />
                      ) : null}
                    </span>
                    <p>{name}</p>
                  </div>
                )
              })}
            </div>
          </div>

          <div className="action">
            <KooperButton
              primary
              loading={isLoadingData}
              disabled={isLoadingData}
              onClick={handleSubmit(createMeeting)}
            >
              {index !== 4 ? 'Next' : 'Create'}
            </KooperButton>
          </div>
        </div>
      </div>
      <div className="kooper-full-page" style={{background: '#f5f7fa'}}>
        <Details
          style={{display: index === 0 ? '' : 'none'}}
          errors={errors}
          control={control}
          watch={watch}
          setValue={setValue}
          Controller={Controller}
          getValues={getValues}
          profilePicture={profilePicture}
          setProfilePicture={setProfilePicture}
          uploadProfile={uploadProfile}
          setUserAccountImage={setUserAccountImage}
          setUploadProfile={setUploadProfile}
          userAccountImage={userAccountImage}
          removeDuration={removeDuration}
          appendDuration={appendDuration}
          durationFields={durationFields}
          socialFields={socialFields}
          removeSocial={removeSocial}
          appendSocial={appendSocial}
          socialFieldsName="ownerSocialMedia"
        />

        <Configuration
          style={{display: index === 1 ? '' : 'none'}}
          errors={errors}
          control={control}
          Controller={Controller}
          getValues={getValues}
          setValue={setValue}
        />
        <Availabilty
          style={{display: index === 2 ? '' : 'none'}}
          errors={errors}
          control={control}
          Controller={Controller}
          getValues={getValues}
          removeMeetingAvailableTimes={removeMeetingAvailableTimes}
          appendMeetingAvailableTimes={appendMeetingAvailableTimes}
          meetingAvalTime={meetingAvalTime}
          watch={watch}
        />
        <FormQuestion
          style={{display: index === 3 ? '' : 'none'}}
          errors={errors}
          control={control}
          Controller={Controller}
          watch={watch}
          setValue={setValue}
          formFields={FormFields}
          removeMeetingForm={removeMeetingForm}
          appendMeetingForm={appendMeetingForm}
        />

        <Notification
          style={{display: index === 4 ? '' : 'none'}}
          errors={errors}
          control={control}
          Controller={Controller}
        />
      </div>
    </div>
  )
}

export default MeetingCreate
