import React from 'react'
import {Controller} from 'react-hook-form'
import {AiOutlineQuestionCircle} from 'react-icons/ai'
import {removeDoubleQuotes} from 'utils/helper'
import {KooperForm, KooperFormField, KooperFormGroup, KooperFormSelect, KooperTooltip} from 'kooper-library'

const ProjectTemplateAdvanceOptionCarousel = ({
  style,
  control,
  errors,
  usersList,
  contactsList,
  categoryList,
  setCategoryList,
}) => {
  return (
    <KooperForm className="errorLabel" style={style}>
      <KooperFormField>
        <div className="info-header">
          <label>Users</label>
          <KooperTooltip
            trigger={<AiOutlineQuestionCircle />}
            content="Add members in the project"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="assignedTo"
          control={control}
          render={({value, onChange}) => (
            <KooperFormSelect
              search
              multiple
              placeholder="Select user"
              options={usersList}
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={errors.assignedTo && {content: removeDoubleQuotes(errors.assignedTo.message)}}
            />
          )}
        />
      </KooperFormField>

      <KooperFormField>
        <div className="info-header">
          <label>Project Owner</label>
          <KooperTooltip
            trigger={<AiOutlineQuestionCircle />}
            content="Assign a owner for the project for management purpose"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="managerId"
          control={control}
          render={({value, onChange}) => (
            <KooperFormSelect
              search
              placeholder="Select project owner"
              options={usersList}
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={errors.managerId && {content: removeDoubleQuotes(errors.managerId.message)}}
            />
          )}
        />
      </KooperFormField>

      <KooperFormField>
        <div className="info-header">
          <label>Contacts</label>
          <KooperTooltip
            trigger={<AiOutlineQuestionCircle />}
            content="Select contact for the project"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="contactIds"
          control={control}
          render={({value, onChange}) => (
            <KooperFormSelect
              search
              multiple
              placeholder="Select contact"
              options={contactsList}
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={errors.contactIds && {content: removeDoubleQuotes(errors.contactIds.message)}}
            />
          )}
        />
      </KooperFormField>

      <KooperFormGroup>
        <KooperFormField width={8}>
          <div className="info-header">
            <label>Category</label>
            <KooperTooltip
              trigger={<AiOutlineQuestionCircle />}
              content="Set Category of the project"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="categoryId"
            control={control}
            render={({value, onChange}) => (
              <KooperFormSelect
                selection
                fluid
                search
                placeholder="Select category"
                options={categoryList}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                error={errors.categoryId && {content: removeDoubleQuotes(errors.categoryId.message)}}
                allowAdditions
                onAddItem={(e, {value}) => setCategoryList([{key: value, value, text: value}, ...categoryList])}
              />
            )}
          />
        </KooperFormField>
      </KooperFormGroup>
    </KooperForm>
  )
}

export default ProjectTemplateAdvanceOptionCarousel
