/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {FiExternalLink, FiPieChart} from 'react-icons/fi'
import _ from 'lodash'
import {
  KooperButton,
  KooperButtonGroup,
  KooperCheckbox,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperGrid,
  KooperGridColumn,
  KooperHeader,
  KooperTable,
  KooperTableCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {
  CLONE_PRODUCT,
  DELETE_MULTIPLE_PRODUCTS,
  DELETE_PRODUCT,
  EXPORT_ALL_PRODUCTS,
  EXPORT_SELECTED_PRODUCTS,
  GET_PRODUCTS_LIST,
} from 'actions/types'
import {
  cloneProduct,
  deleteProduct,
  deleteMultipleProducts,
  exportAllProducts,
  exportSelectedProducts,
  getProductCategory,
  getProductlist,
  getProductData,
} from 'actions/productLibrary'
import create from 'assets/create.svg'
import DeleteModal from 'components/common/DeleteModal'
import {downloadResponseCSV, get, getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import CreateFirst from 'components/common/CreateFirst'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import ProductsDetails from './ProductsModal'

function ProductList() {
  const dispatch = useDispatch()
  const location = useLocation()

  const [open, setOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [checked, setChecked] = useState([])

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {categoryList, productList = [], exportData} = useSelector(state => state.productLibrary)

  const {isLoading} = useApiLoader({label: GET_PRODUCTS_LIST})
  const {salesAndCrmAccess, productLibraryPermissions} = useUserPermissions()
  const productsAccess = salesAndCrmAccess?.products
  const manageProductsPermissions = productLibraryPermissions?.manage_products

  useEffect(() => {
    if (productsAccess) {
      if (!categoryList) {
        dispatch(getProductCategory())
      }
      dispatch(getProductlist())
    }
  }, [productsAccess, dispatch])

  useEffect(() => {
    if (successLabels.includes(DELETE_PRODUCT)) {
      dispatch(getProductlist())
    }

    if (successLabels.includes(DELETE_MULTIPLE_PRODUCTS)) {
      dispatch(getProductlist())
      setChecked([])
    }

    if (successLabels.includes(CLONE_PRODUCT)) {
      dispatch(getProductlist())
    }

    if (successLabels.includes(EXPORT_ALL_PRODUCTS)) {
      downloadResponseCSV(exportData, 'all-products')
      setChecked([])
      dispatch({type: 'CLEAR_SELECTED_PRODUCTS'})
    }

    if (successLabels.includes(EXPORT_SELECTED_PRODUCTS)) {
      downloadResponseCSV(exportData, 'selected-products')
      setChecked([])
      dispatch({type: 'CLEAR_SELECTED_PRODUCTS'})
    }
  }, [successLabels])

  useEffect(() => {
    if (get(['open'], location.state)) {
      setOpen(get(['open'], location.state))
      dispatch(getProductData(get(['productId'], location.state)))
    }
  }, [location.state])

  const isCheckedAll = () => {
    const allIds = productList.map(lead => lead.id)

    const checkAll = _.xor(allIds, checked).length === 0 && allIds.length !== 0
    if (checkAll) {
      return true
    }
    return false
  }

  const handleCheckAll = () => {
    const allIds = productList.map(lead => lead.id)

    if (_.xor(allIds, checked).length === 0) {
      setChecked([])
    } else setChecked(allIds)
  }

  const handleCheck = id => {
    const isChecked = checked.some(lead => lead === id)

    if (isChecked) {
      const removeChecked = checked.filter(lead => lead !== id)
      setChecked(removeChecked)
    } else {
      setChecked([...checked, id])
    }
  }

  const isChecked = id => {
    const check = checked.find(lead => lead === id)
    if (check) {
      return true
    }
    return false
  }

  // if (productList.length < 1 && !isLoadingData) {
  //   return (
  //     <>
  //       <CreateFirst
  //         src={<FiPieChart />}
  //         addFirst={setOpen}
  //         buttonText="Create Product"
  //         img={create}
  //         header="Product"
  //         Feature1="Product added with all details and SKU"
  //         Feature2="Direct connected to Quotes for better sales performance"
  //         Feature3="Helps improve your brand value"
  //         list1="Add your product with all the required details such as SKU to keep a proper track of your company products"
  //         list2="Product you create are connected to quotes sections so you can create a fully customisable quote for your product"
  //         list3="Increase your brand value by adding products, manage analytics and analyzing the trend"
  //         subHeader="A product is an asset owned by the company which can be also liable for selling purposes. Adding product in this section allows you to create Quotes related to that product and improve your sales performance"
  //       />
  //     </>
  //   )
  // }

  const renderTableBody = () => {
    if (isLoadingData && type.includes(GET_PRODUCTS_LIST)) {
      return <KooperTablePlaceholder columns={9} />
    }

    return productList.map(({id, name, sku, unitPrice, billingFreq, createdAt, createdBy, category}, index) => {
      return (
        <KooperTableRow
          key={id}
          className="tableLink"
          onClick={() => {
            if (manageProductsPermissions) {
              dispatch(getProductData(id))
              setOpen(true)
            }
          }}
        >
          <KooperTableCell className="pl-4" onClick={e => e.stopPropagation()}>
            <KooperTooltip
              size="mini"
              position="bottom center"
              content="Select"
              trigger={
                <KooperCheckbox
                  id={`${id}label`}
                  className="d-inline-block"
                  onChange={() => handleCheck(id)}
                  checked={isChecked(id)}
                />
              }
            />
          </KooperTableCell>
          <KooperTableCell>
            <KooperHeader as="h5">{startCase(name)}</KooperHeader>
          </KooperTableCell>
          <KooperTableCell>{startCase(sku)}</KooperTableCell>
          <KooperTableCell>{startCase(category.name)}</KooperTableCell>
          <KooperTableCell>{unitPrice}</KooperTableCell>
          <KooperTableCell>{billingFreq}</KooperTableCell>
          <KooperTableCell>{createdBy.name}</KooperTableCell>
          <KooperTableCell>{getFormattedDate(createdAt)}</KooperTableCell>
          <KooperTableCell style={{minWidth: '110px', display: 'flex'}} onClick={e => e.stopPropagation()}>
            <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} floating className="icon bg-btn">
              <KooperDropdownMenu direction="left">
                <LockPermissionTooltip isRoleAccessDenied={!manageProductsPermissions}>
                  <KooperDropdownItem onClick={() => manageProductsPermissions && dispatch(cloneProduct(id))}>
                    <SvgIcon path="common/clone" />
                    Clone
                  </KooperDropdownItem>
                </LockPermissionTooltip>
                {checked.length < 1 && (
                  <LockPermissionTooltip isRoleAccessDenied={!manageProductsPermissions}>
                    <KooperDropdownItem
                      onClick={() => {
                        if (manageProductsPermissions) {
                          setEditId(id)
                          setDeleteModal(true)
                        }
                      }}
                    >
                      <SvgIcon path="common/delete" />
                      Delete
                    </KooperDropdownItem>
                  </LockPermissionTooltip>
                )}
              </KooperDropdownMenu>
            </KooperDropdown>
          </KooperTableCell>
        </KooperTableRow>
      )
    })
  }

  if (isLoading && productsAccess) {
    return <PageLoader />
  }

  if (productList.length === 0 || !productsAccess) {
    return (
      <>
        <CreateFirst
          src={<FiPieChart />}
          addFirst={() => productsAccess && manageProductsPermissions && setOpen(true)}
          tooltip={!productsAccess || !manageProductsPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: productsAccess,
            roleAccess: manageProductsPermissions,
          })}
          buttonText="Create Product"
          img={create}
          header="Product"
          Feature1="Product Catalog"
          Feature2="Import Products"
          Feature3="Categorization"
          list1="Centralized area to create and list all your products"
          list2="Import list of products from external source easily"
          list3="Set products under different categories for smoother sales operations"
          subHeader="Adding products allows you to create Quotes related to the products and improve sales operations"
        />
        {open && <ProductsDetails open={open} toggle={() => setOpen(false)} />}
      </>
    )
  }

  return (
    <div className="kooper-side-wrap">
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="navigation/products" />
            <h5>Product List ({productList ? productList.length : 0})</h5>
          </div>
        </div>
        <div className="page-action">
          <LockPermissionTooltip isRoleAccessDenied={!manageProductsPermissions}>
            <KooperButton basic onClick={() => manageProductsPermissions && dispatch(exportAllProducts())} floated="right">
              Export All
            </KooperButton>
          </LockPermissionTooltip>
          <LockPermissionTooltip isRoleAccessDenied={!manageProductsPermissions}>
            <KooperButton
              content="Create Product"
              primary
              className={!manageProductsPermissions ? 'disabled-button' : ''}
              onClick={() => manageProductsPermissions && setOpen(true)}
            />
          </LockPermissionTooltip>
        </div>
      </div>
      <KooperGrid>
        <KooperGridColumn width={8}>
          {checked.length > 0 && (
            <KooperButtonGroup basic compact className="ml-3 conFilter">
              <KooperTooltip
                size="mini"
                position="bottom center"
                content={
                  manageProductsPermissions
                    ? 'Export Selected Products'
                    : getPermissionTooltipContent({roleAccess: manageProductsPermissions})
                }
                trigger={
                  <KooperButton
                    color="light"
                    onClick={() => manageProductsPermissions && dispatch(exportSelectedProducts(checked))}
                  >
                    <FiExternalLink />
                  </KooperButton>
                }
              />

              <KooperTooltip
                size="mini"
                position="bottom center"
                content={
                  manageProductsPermissions
                    ? 'Delete Selected Products'
                    : getPermissionTooltipContent({roleAccess: manageProductsPermissions})
                }
                trigger={
                  <KooperButton color="light" onClick={() => manageProductsPermissions && setDeleteModal(true)}>
                    <SvgIcon path="common/delete" />
                  </KooperButton>
                }
              />
            </KooperButtonGroup>
          )}
        </KooperGridColumn>
      </KooperGrid>

      <KooperTable style={{marginTop: '0px'}} basic selectable>
        <thead>
          <tr>
            <th className="pl-4">
              <KooperTooltip
                size="mini"
                position="bottom center"
                content="Select All"
                trigger={<KooperCheckbox id="ckbxAll" label="" checked={isCheckedAll()} onChange={handleCheckAll} />}
              />
            </th>
            <th>Name</th>
            <th>SKU/Display Id</th>
            <th>Category</th>
            <th>Unit Price</th>
            <th>Billing Frequency</th>
            <th>Created By</th>
            <th>Created Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </KooperTable>
      {open && (
        <ProductsDetails open={open} toggle={() => setOpen(false)} editId={editId} clearEditId={() => setEditId()} />
      )}
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          deleteAction={checked.length > 0 ? deleteMultipleProducts : deleteProduct}
          idTobeDeleted={checked.length > 0 ? checked : editId}
          type={DELETE_PRODUCT}
        />
      )}
    </div>
  )
}

export default ProductList
