import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import Employees from 'components/pages/HumanResourse/Employees/Employees'
import EmployeeDepartments from 'components/pages/HumanResourse/Employees/Settings/Department/EmployeeDepartments'
import EmployeesGeneral from 'components/pages/HumanResourse/Employees/Settings/GeneralSetting/EmployeesGeneral'
import {EMPLOYEES_PATH} from 'constants/path'

const routes = [
  {path: EMPLOYEES_PATH.ALL, component: Employees, title: 'All employees', exact: true},
  {path: EMPLOYEES_PATH.GENERAL, component: EmployeesGeneral, title: 'Employees', exact: false},
  {path: EMPLOYEES_PATH.DEPARTMENTS, component: EmployeeDepartments, title: 'Employees', exact: false},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={`/${EMPLOYEES_PATH.PREFIX}/${path}`}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} />
        <Component {...props} />
      </>
    )}
  />
)

const EmployeeRoutes = () => (
  <Switch>
    <Route exact path={`/${EMPLOYEES_PATH.PREFIX}`}>
      <Redirect to={`/${EMPLOYEES_PATH.PREFIX}/${EMPLOYEES_PATH.ALL}`} />
    </Route>
    {routes.map(renderRoute)}
    <Route path={`/${EMPLOYEES_PATH.PREFIX}/*`}>
      <Redirect to={`/${EMPLOYEES_PATH.PREFIX}/${EMPLOYEES_PATH.ALL}`} />
    </Route>
  </Switch>
)

export default EmployeeRoutes
