import SvgIcon from 'components/common/SvgIcon'
import {FiCheckSquare, FiRadio} from 'react-icons/fi'
import {MdNumbers, MdOutlineChatBubbleOutline, MdOutlineThumbsUpDown, MdViewHeadline} from 'react-icons/md'
import {KooperDivider} from 'kooper-library'

export const projectStatusList = [
  {key: 'active', value: 'active', text: 'Active'},
  {key: 'inactive', value: 'inactive', text: 'Inactive'},
  {key: 'archived', value: 'archived', text: 'Archived'},
]

export const sortByList = [
  {key: 'category', value: 'category', text: 'Category'},
  {key: 'latestUpdated', value: 'updatedAt', text: 'Latest Updated'},
  {key: 'name', value: 'title', text: 'Name'},
  {key: 'status', value: 'status', text: 'Status'},
]

export const summary = [
  {color: 'green', time: '120h', name: 'Estimated time'},
  {color: 'blue', time: '6h 25m', name: 'Total logged time'},
  {color: 'orange', time: '20m', name: 'Billed time'},
  {color: 'red', time: '6h 5m', name: 'Billable time'},
  {color: 'purple', time: '0h', name: 'Non-billable'},
]

export const TimeData = [
  {
    date: 'Nov 22',
    data: [
      {
        loggedby: 'Samantha Kooper',
        timelogged: '2h 45m',
        date: 'Nov 22',
        description: 'Time entry logged for a sample task',
        tashlist: 'Private tasklist for selected project members',
        task: 'Task with logged time',
        status: 'billable',
      },
      {
        loggedby: 'Rejin Kooper',
        timelogged: '2h',
        date: 'Nov 22',
        description: 'Time logged using timer',
        tashlist: '',
        task: '',
        status: 'billable',
      },
    ],
  },
  {
    date: 'Nov 24',
    data: [
      {
        loggedby: 'Samantha Kooper',
        timelogged: '1h 45m',
        date: 'Nov 22',
        description: 'Time entry logged for a sample task',
        tashlist: 'Private tasklist for selected project members',
        task: 'Task with logged time',
        status: 'billable',
      },
      {
        loggedby: 'Rejin Kooper',
        timelogged: '2h',
        date: 'Nov 24',
        description: 'Time logged using timer',
        tashlist: '',
        task: '',
        status: 'billable',
      },
    ],
  },
]

export const timeStatusList = [
  // {key: 'billed', value: 'billed', text: 'Billed'},
  {key: 'billable', value: 'billable', text: 'Billable'},
  {key: 'non-billable', value: 'non-billable', text: 'Non-billable'},
  // {key: 'void', value: 'void', text: 'Void'},
  // {key: 'none', value: 'none', text: 'None'},
]

export const projectBudgetsBasedOnOptions = [
  {key: 'all', value: 'all', text: 'All time'},
  {key: 'billable', value: 'billable', text: 'Billable time'},
  {key: 'non-billable', value: 'non-billable', text: 'Non-billable time'},
]

export const projectBudgetsPeriodLengthOptions = [
  {key: 'monthly', value: 'month', text: 'Monthly'},
  {key: 'quarterly', value: 'quarter', text: 'Quarterly'},
  {key: 'yearly', value: 'year', text: 'Yearly'},
]

// proof

export const ProofApprovalStatus = [
  {key: 'all', value: 'all', text: 'All'},
  <KooperDivider />,
  {
    key: 'approved',
    value: 'approved',
    text: 'Approved',
  },
  {
    key: 'in_review',
    value: 'in_review',
    text: 'In Review',
  },
  {
    key: 'need_change',
    value: 'need_change',
    text: 'Need Change',
  },
]

export const ProofStatusOptions = [
  {
    value: 'review',
    text: 'Can review',
    content: (
      <div className="my-0">
        <div className="my-0">Can review</div>
        <p className="kooper-lead">Can leave feedback but not approve</p>
      </div>
    ),
    key: 'review',
  },
  {
    value: 'approve',
    text: 'Can approve',
    content: (
      <div className="my-0">
        <div className="my-0">Can approve</div>
        <p className="kooper-lead">Can leave feedback and also approve</p>
      </div>
    ),
    key: 'approve',
  },
]

// risks

export const riskProbabilityValueOptions = [
  {value: 1, text: '1', color: '#59c894'},
  {value: 2, text: '2', color: '#59c894'},
  {value: 3, text: '3', color: '#59c894'},
  {value: 4, text: '4', color: 'orange'},
  {value: 5, text: '5', color: 'orange'},
  {value: 6, text: '6', color: 'orange'},
  {value: 7, text: '7', color: 'red'},
  {value: 8, text: '8', color: 'red'},
  {value: 9, text: '9', color: 'red'},
]

export const projectRiskStatusOptions = [
  {value: 'Open', text: 'Open', color: '#59c894'},
  {value: 'Pending', text: 'Pending', color: 'orange'},
  {value: 'Closed', text: 'Closed', color: 'red'},
]

export const projectFormComponents = [
  {
    name: 'Page Title',
    icon: <SvgIcon path="common/file" />,
    type: 'page',
  },
  {
    name: 'Heading',
    icon: <MdViewHeadline />,
    type: 'header',
  },
  {
    name: 'Single Line Text',
    icon: <MdOutlineChatBubbleOutline />,
    type: 'text',
  },
  {
    name: 'Multi Line Text',
    icon: <MdOutlineThumbsUpDown />,
    type: 'textarea',
  },
  {
    name: 'Dropdown',
    icon: <SvgIcon path="common/file" />,
    type: 'dropdown',
  },
  {
    name: 'Checkboxes',
    icon: <FiCheckSquare />,
    type: 'checkbox',
  },
  {
    name: 'Multiple Choice',
    icon: <FiRadio />,
    type: 'radio',
  },
  {
    name: 'Number',
    icon: <MdNumbers />,
    type: 'number',
  },
  {
    name: 'Date',
    icon: <SvgIcon path="common/calendar" />,
    type: 'date',
  },
]

export const PROJECT_FINANCE_BUDGET_TYPES = {
  NO_BUDGET: 'no-budget',
  TIME_AND_MATERIALS: 'time_and_materials',
  FIXED_FEE: 'fixed_fee',
  RETAINER: 'retainer',
}

export const budgetTypes = [
  {
    title: 'No Budget',
    type: PROJECT_FINANCE_BUDGET_TYPES.NO_BUDGET,
    description: 'No budget set for the project',
  },
  {
    title: 'Fixed Fee',
    type: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
    description: 'Bill a fixed amount, regardless of logged time',
  },
  {
    title: 'Time And Materials',
    type: PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS,
    description: 'Bill clients for standalone projects',
  },
  {
    title: 'Retainer',
    type: PROJECT_FINANCE_BUDGET_TYPES.RETAINER,
    description: 'A monthly or quarterly recurring budget',
  },
]

export const financeBudgetTypes = budgetTypes.slice(1)
