import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperTable,
  KooperTooltip,
} from 'kooper-library'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {removeDoubleQuotes} from 'utils/helper'
import {isMatchOption} from 'constants/variables'
import useApiResponse from 'hooks/impure/useApiResponse'
import SettingLayout from 'layouts/settingLayout'
import {createRouting, updateRouting, getRoutingData} from 'actions/settings_automations'
import {getAllMailInbox} from 'actions/inbox'
import {CREATE_ROUTING, GET_ALL_MAIL_INBOX, GET_ROUTING_DATA, GET_USERS, UPDATE_ROUTING} from 'actions/types'
import {getUsers} from 'actions/settings'
import {routingSchema} from 'validation/Settings/automations/routing.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import {INBOX_TYPES} from 'constants/enums'
import {SETTINGS_PATH} from 'constants/path'

const defaultValues = {
  name: '',
  rule: '',
  inboxId: '',
  assignLimit: null,
}

const RoutingForm = ({toggleModal, setWarningModal}) => {
  const {id: editId} = useParams()
  const [inboxList, setInboxList] = useState([])
  const [allUserList, setAllUserList] = useState([])
  const [inboxType, setInboxType] = useState(null)
  const [urlTable, setUrlTable] = useState([])
  const [urlBase, setUrlBase] = useState({
    value: '',
    condition: '',
    userId: '',
  })

  const {isLoadingData, type} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const routingAddLearnMore = settingsLearnMoreUrl?.automations?.routing?.addLearnMore
  const routingUpdateLearnMore = settingsLearnMoreUrl?.automations?.routing?.updateLearnMore

  const history = useHistory()
  const dispatch = useDispatch()

  const {automationPermissions} = useUserPermissions()
  const manageRoutingRulesPermissions = automationPermissions?.manage_routing_rules

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: {isDirty},
    watch,
    clearErrors,
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(routingSchema),
    defaultValues,
  })

  const routingOption = [
    {
      key: 'round_robin',
      value: 'round_robin',
      text: 'Based on Round Robin',
    },
    {
      key: 'active_agents',
      value: 'active_agents',
      text: 'Based on Active Agents',
    },
    {
      key: 'last_agent_assigned',
      value: 'last_agent_assigned',
      text: 'Based on Last Agent Assigned',
    },
    ...(inboxType === INBOX_TYPES.EMAIL
      ? [
          {key: 'team', value: 'team', text: 'Based on Teams'},
          {
            key: 'load_balancing',
            value: 'load_balancing',
            text: 'Based on Load Balancing',
          },
        ]
      : []),
    ...(inboxType === INBOX_TYPES.WEB ? [{key: 'url', value: 'url', text: 'Based on URL'}] : []),
  ]

  const inboxId = watch('inboxId')
  const ruleWatch = watch('rule')

  useApiResponse({
    action: getUsers,
    enabled: ruleWatch === 'url',
    dependency: [ruleWatch === 'url'],
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setAllUserList(list || [])
    },
  })

  useEffect(() => {
    if (inboxId) {
      const inboxData = inboxList?.filter(data => data?.value === inboxId)
      setInboxType(inboxData?.[0]?.type)
    }
  }, [inboxId, inboxList])

  useApiResponse({
    action: getAllMailInbox,
    enabled: true,
    label: GET_ALL_MAIL_INBOX,
    storePath: 'mailInbox.inbox',
    onSuccess: data => {
      setInboxList(
        data.map(item => ({
          key: item.id,
          value: item.id,
          text: item.name,
          type: item.type,
        }))
      )
    },
  })

  useApiResponse({
    action: getRoutingData,
    payload: editId,
    dependency: [editId],
    enabled: editId,
    label: GET_ROUTING_DATA,
    storePath: 'settingAutomation.routingData',
    onSuccess: data => {
      reset({
        name: data.name,
        inboxId: data.inboxId,
        rule: data.rule,
        assignLimit: data.assignLimit,
      })
      if (data.subrules) {
        setUrlTable(data.subrules)
      }
    },
  })

  const submitForm = data => {
    const payload = {
      ...data,
      type: inboxType,
      ...(ruleWatch === 'url' && {subrules: urlTable}),
    }

    if (editId) {
      setWarningModal(false)
      dispatch(updateRouting(editId, payload))
      history.push(`${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.ROUTING}`)
    } else {
      dispatch(createRouting(payload))
      toggleModal(false)
    }
  }

  useEffect(() => {
    if (editId && isDirty) {
      setWarningModal(true)
    }
    return () => {
      if (editId) setWarningModal(false)
    }
  }, [isDirty, editId, setWarningModal])

  return (
    <SettingLayout
      header={editId ? 'Edit Routing' : 'Create Routing'}
      headerbackbtn={{
        onClick: () => {
          if (editId) {
            setWarningModal(false)
            history.push(`${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.ROUTING}`)
          } else {
            toggleModal(false)
          }
        },
      }}
      EDIT_TIMING_SCHEME
      subHeader="Create routing rules for equal distribution of customer queries"
      learnMoreUrl={editId ? routingUpdateLearnMore : routingAddLearnMore}
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Cancel',
          onClick: () => {
            if (editId) {
              setWarningModal(false)
              history.push(`${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.ROUTING}`)
            } else {
              toggleModal(false)
            }
          },
        },
        success: {
          content: editId ? 'Update' : 'Create',
          loading: isLoadingData && type.includes(UPDATE_ROUTING || CREATE_ROUTING),
          ...(manageRoutingRulesPermissions && {
            onClick: handleSubmit(submitForm),
          }),
        },
      }}
      lockRole={!manageRoutingRulesPermissions}
    >
      <KooperForm className="errorLabel">
        <KooperFormField required width={6}>
          <label>Name</label>
          <Controller
            control={control}
            name="name"
            render={({value, onChange}) => (
              <KooperFormInput
                maxLength={20}
                placeholder="Enter Routing Name"
                value={value}
                onChange={e => onChange(e.target.value)}
                error={
                  errors.name && {
                    content: removeDoubleQuotes(errors.name.message),
                  }
                }
              />
            )}
          />
        </KooperFormField>
        <KooperFormField required>
          <div className="info-header">
            <label>Select Inbox</label>
            <KooperTooltip
              content="Select inbox in which you want to route conversations"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="inboxId"
            render={({value, onChange, onBlur}) => (
              <KooperFormSelect
                width={6}
                fluid
                disabled={editId}
                placeholder="Select a inbox"
                options={inboxList}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                onClick={() => clearErrors('inboxId')}
                error={
                  errors.inboxId && {
                    content: removeDoubleQuotes(errors.inboxId.message),
                  }
                }
                onBlur={onBlur}
              />
            )}
            control={control}
          />
        </KooperFormField>
        <KooperFormField required>
          <div className="info-header">
            <label>Select Routing Rule</label>
            <KooperTooltip
              content="Select rule which will be applicable for conversation routing"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            control={control}
            name="rule"
            render={({value, onChange}) => (
              <KooperFormSelect
                width={6}
                options={routingOption}
                placeholder="Select Routing Rule"
                value={value}
                onChange={(e, {value}) => onChange(value)}
                onClick={() => clearErrors('rule')}
                error={
                  errors.rule && {
                    content: removeDoubleQuotes(errors.rule.message),
                  }
                }
              />
            )}
          />
        </KooperFormField>

        {inboxType === 'web' &&
          (ruleWatch === 'url' ? (
            <KooperCard fluid className="switchBox">
              <KooperCardContent>
                <KooperFormGroup>
                  <KooperFormInput
                    label="Enter URL / keyword"
                    width={6}
                    placeholder="Enter URL"
                    onChange={e => setUrlBase({...urlBase, value: e.target.value})}
                    value={urlBase.value}
                  />
                  <KooperFormField>
                    <label>Is match</label>
                    <KooperFormSelect
                      options={isMatchOption}
                      value={urlBase.condition}
                      onChange={(e, {value}) => setUrlBase({...urlBase, condition: value})}
                    />
                  </KooperFormField>
                  <KooperFormField>
                    <label>Assign to</label>
                    <KooperFormSelect
                      className="dropdown-ellipsis"
                      options={allUserList}
                      value={urlBase.userId}
                      onChange={(e, {value}) => setUrlBase({...urlBase, userId: value})}
                    />
                  </KooperFormField>
                  <KooperFormField width={2}>
                    <KooperButton
                      primary
                      style={{marginTop: '30px'}}
                      disabled={urlBase.value.trim() === '' || !urlBase.condition || !urlBase.userId}
                      onClick={() => {
                        setUrlTable([...urlTable, urlBase])
                        setUrlBase({
                          value: '',
                          condition: '',
                          userId: '',
                        })
                      }}
                    >
                      Add
                    </KooperButton>
                  </KooperFormField>
                </KooperFormGroup>
              </KooperCardContent>
              {urlTable.length > 0 && (
                <KooperCardContent>
                  <KooperTable basic style={{maxWidth: '900px'}}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Link / Keyword</th>
                        <th>Is Match</th>
                        <th>Assign to</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {urlTable.map((list, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{list.value}</td>
                          <td>
                            {isMatchOption.find(obj => obj.value === list.condition) &&
                              isMatchOption.find(obj => obj.value === list.condition).text}
                          </td>
                          <td>
                            {allUserList.find(obj => obj.value === list.userId) &&
                              allUserList.find(obj => obj.value === list.userId).text}
                          </td>
                          <td>
                            <KooperButton
                              icon
                              onClick={() => {
                                const updatedList = [...urlTable]
                                updatedList.splice(i, 1)
                                setUrlTable(updatedList)
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </KooperButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </KooperTable>
                </KooperCardContent>
              )}
            </KooperCard>
          ) : (
            <Controller
              control={control}
              name="assignLimit"
              render={({onChange, value}) => (
                <KooperFormField required>
                  <label>Maximum chats to an agent</label>
                  <p className="kooper-lead">
                    Set limit of open conversations assigned to an agent at a time, if the limit crosses, user won't be
                    assigned any new conversations till the open conversations are under limit
                  </p>
                  <KooperFormInput
                    width={3}
                    placeholder="Assignee Limit"
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={
                      errors.assignLimit && {
                        content: removeDoubleQuotes(errors.assignLimit.message),
                      }
                    }
                  />
                </KooperFormField>
              )}
            />
          ))}
      </KooperForm>
    </SettingLayout>
  )
}

export default RoutingForm
