import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {KooperButton, KooperModal, KooperModalActions, KooperModalContent, KooperModalHeader} from 'kooper-library'

import {getBillingAddress, updateBillingAddress} from 'actions/billing'
import {GET_BILLING_ADDRESS, UPDATE_BILLING_ADDRESS} from 'actions/types'
import {billingAddressSchema} from 'validation/Settings/billing.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import BillingInfoForm from './BillingInfoForm'

const BillingInfo = props => {
  const {updateBillingInfo, setUpdateBillingInfo} = props

  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {
    handleSubmit,
    errors,
    clearErrors,
    control,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(billingAddressSchema),
    defaultValues: {
      city: '',
      country: '',
      line1: '',
      postalCode: '',
      state: '',
      isDefault: true,
    },
  })

  useApiResponse({
    action: getBillingAddress,
    enabled: true,
    label: GET_BILLING_ADDRESS,
    storePath: 'billing.getBillingAddressData',
    onSuccess: getBillingAddressData => {
      reset(getBillingAddressData)
    },
  })

  useEffect(() => {
    if (successLabels.includes(UPDATE_BILLING_ADDRESS)) {
      setUpdateBillingInfo(false)
    }
  }, [successLabels, reset, setUpdateBillingInfo])

  const onSubmitBillingInfo = data => {
    const payload = {...data, postalCode: data.postalCode.toString()}
    dispatch(updateBillingAddress(payload))
  }

  return (
    <KooperModal
      size="small"
      open={updateBillingInfo}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setUpdateBillingInfo(!updateBillingInfo)}
    >
      <KooperModalHeader toggle={() => setUpdateBillingInfo(!updateBillingInfo)}>User Info</KooperModalHeader>
      <KooperModalContent>
        <BillingInfoForm control={control} errors={errors} clearErrors={clearErrors} />
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setUpdateBillingInfo(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary disabled={!isDirty} onClick={handleSubmit(onSubmitBillingInfo)}>
          Update
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default BillingInfo
