import {
  KooperGrid,
  KooperGridColumn,
  KooperImage,
  KooperListItem,
  KooperPlaceholder,
  KooperPlaceholderHeader,
  KooperPlaceholderLine,
  KooperPlaceholderParagraph,
} from 'kooper-library'

export const ListPlaceholder = ({rows = 6}) => {
  return Array.from({length: rows}, () => (
    <KooperListItem>
      <KooperGrid>
        <KooperGridColumn floated="left" width={16}>
          <KooperPlaceholder>
            <KooperPlaceholderParagraph>
              <KooperPlaceholderLine />
              <KooperPlaceholderLine />
              <KooperPlaceholderLine />
            </KooperPlaceholderParagraph>
          </KooperPlaceholder>
        </KooperGridColumn>
      </KooperGrid>
    </KooperListItem>
  ))
}

export const ParagraphPlaceholder = ({paragraphs = 5}) => {
  return Array.from({length: paragraphs}, (_, i) => {
    return (
      <KooperPlaceholderParagraph key={i}>
        <KooperPlaceholderLine />
        <KooperPlaceholderLine />
        <KooperPlaceholderLine />
      </KooperPlaceholderParagraph>
    )
  })
}

export const EmailBodyContentPlaceholder = ({paragraphs = 5}) => {
  return (
    <KooperPlaceholder fluid className="m-3">
      <KooperPlaceholderHeader image>
        <KooperPlaceholderLine />
        <KooperPlaceholderLine />
      </KooperPlaceholderHeader>
      <ParagraphPlaceholder paragraphs={paragraphs} />
    </KooperPlaceholder>
  )
}

export const EmptyInboxPlaceholder = ({header, subHeader, imageSrc, sidebarText}) => {
  return (
    <div className="noData">
      {sidebarText ? (
        <p>{sidebarText}</p>
      ) : (
        <>
          <KooperImage src={imageSrc} />
          <h5>{header}</h5>
          <span>{subHeader}</span>
        </>
      )}
    </div>
  )
}
