import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {joiResolver} from '@hookform/resolvers'
import {useForm} from 'react-hook-form'
import _ from 'lodash'
import {KooperButton, KooperCard, KooperCardContent, KooperContainer, KooperTab, KooperTabPane} from 'kooper-library'
import {createEmployees, getSingleEmployee, updateEmployee} from 'actions/employees'
import {CREATE_EMPLOYEE, GET_SINGLE_EMPLOYEE, UPDATE_EMPLOYEES} from 'actions/types'
import {employeesFormSchema} from 'validation/Hr/employees.schema'
import {employeeSummary} from 'constants/hr/employees'
import useApiResponse from 'hooks/impure/useApiResponse'
import {EMPLOYEES_PATH} from 'constants/path'
import CreateEmployeeForm from './CreateEmployeeForm'
import EmployeeHrInformation from './EmployeeHrInformation'
import EmployeePayrollInformation from './EmployeePayrollInformation'
import EmployeePersonalInformation from './EmployeePersonalInformation'
import EmployeeWorkInformation from './EmployeeWorkInformation'
import AppraisalList from './AppraisalList'

const CreateEmployee = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id: editId} = useParams()

  const [JoiningDate, setJoiningDate] = useState(null)
  const [DateOfBirth, setDateOfBirth] = useState()
  const [SalaryEffectiveDate, setSalaryEffectiveDate] = useState(null)
  const [newLabelModal, setNewLabelModal] = useState(false)
  const [active, setActive] = useState({
    payMethod: '',
    effectiveDate: false,
  })

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const initialCreateInfo = {
    employeeID: '',
    firstName: '',
    lastName: '',
    title: '',
    workEmail: '',
    workPhone: '',
    workMobileNo: '',
    managerId: null,
    departmentId: null,
    joiningDate: null,
    labelIds: [],
    offTimeApprover: null,
    expenseApprover: null,
    personalInfo: {},
    payrollInfo: {},
    employmentType: '',
    employmentStatus: '',
    leavingDate: null,
    allowPortalAccess: false,
  }

  const {
    errors,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(employeesFormSchema),
    defaultValues: initialCreateInfo,
  })

  const {data: employeesData = {}} = useApiResponse({
    action: getSingleEmployee,
    payload: editId,
    dependency: [editId],
    enabled: editId,
    label: GET_SINGLE_EMPLOYEE,
    storePath: 'employees.employeesData',
    onSuccess: () => {
      const {
        firstName,
        lastName,
        employeeID,
        title,
        workEmail,
        managerId,
        workPhone,
        workMobileNo,
        departmentId,
        joiningDate,
        expenseApprover,
        offTimeApprover,
        workAddressId,
        labelIds,
        employmentType,
        employmentStatus,
        leavingDate,
        allowPortalAccess,
        personalInfo,
        payrollInfo,
      } = employeesData
      if (payrollInfo) {
        delete payrollInfo?.id
        delete payrollInfo?.accountId
        delete payrollInfo?.employeeId
      }
      if (personalInfo) {
        delete personalInfo?.id
        delete personalInfo?.accountId
        delete personalInfo?.employeeId
      }
      if (SalaryEffectiveDate !== null) {
        setActive({
          effectiveDate: true,
        })
      }
      setJoiningDate(joiningDate)
      setSalaryEffectiveDate(payrollInfo?.salaryEffectiveDate)
      setDateOfBirth(personalInfo?.dateOfBirth)
      reset({
        firstName,
        lastName,
        employeeID,
        title,
        workEmail,
        managerId,
        workPhone,
        workMobileNo,
        departmentId,
        joiningDate,
        expenseApprover,
        offTimeApprover,
        workAddressId,
        labelIds,
        employmentType,
        employmentStatus,
        leavingDate,
        allowPortalAccess,
        personalInfo,
        payrollInfo,
      })
    },
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_EMPLOYEE) || successLabels.includes(UPDATE_EMPLOYEES)) {
      history.push(`/${EMPLOYEES_PATH.PREFIX}`)
    }
  }, [dispatch, history, successLabels])

  const panes = [
    {
      menuItem: {
        key: 'Work Information',
        icon: 'list ul',
        content: 'Work Information',
      },
      render: () => (
        <KooperTabPane attached={false} className="p-4">
          <EmployeeWorkInformation control={control} errors={errors} watch={watch} setValue={setValue} reset={reset} />
        </KooperTabPane>
      ),
    },
    {
      menuItem: {
        key: 'Personal Information',
        icon: 'list ul',
        content: 'Personal Information',
      },
      render: () => (
        <KooperTabPane attached={false} className="p-4">
          <EmployeePersonalInformation
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            reset={reset}
            DateOfBirth={DateOfBirth}
            setDateOfBirth={setDateOfBirth}
          />
        </KooperTabPane>
      ),
    },
    {
      menuItem: {
        key: 'HR Information',
        icon: 'list ul',
        content: 'HR Information',
      },
      render: () => (
        <KooperTabPane attached={false} className="p-4">
          <EmployeeHrInformation control={control} errors={errors} watch={watch} setValue={setValue} reset={reset} />
        </KooperTabPane>
      ),
    },
    {
      menuItem: {
        key: 'Payroll Information',
        icon: 'list ul',
        content: 'Payroll Information',
      },
      render: () => (
        <KooperTabPane attached={false} className="p-4">
          <EmployeePayrollInformation
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            reset={reset}
            SalaryEffectiveDate={SalaryEffectiveDate}
            setSalaryEffectiveDate={setSalaryEffectiveDate}
            active={active}
            setActive={setActive}
          />
        </KooperTabPane>
      ),
    },
    editId && {
      menuItem: {
        key: 'Appraisals',
        icon: 'list ul',
        content: 'Appraisals',
      },
      render: () => (
        <KooperTabPane attached={false} className="p-4">
          <AppraisalList />
        </KooperTabPane>
      ),
    },
  ]

  const handleSave = data => {
    const newList = _.omitBy(data, value => !!(value === '' || value === null || _.isEqual(value, [])))

    if (editId) {
      const newList = _.omitBy(data, value => !!(value === '' || value === null || _.isEqual(value, [])))
      return dispatch(updateEmployee(editId, newList))
    }

    dispatch(createEmployees(newList))
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <div>
          <KooperButton primary onClick={() => history.push(`/${EMPLOYEES_PATH.PREFIX}`)}>
            Exit
          </KooperButton>
        </div>
        <h3 className="m-0">Create New Employee</h3>
        <KooperButton primary onClick={handleSubmit(data => handleSave(data))} disabled={!isDirty}>
          {editId ? 'Update' : 'Create'}
        </KooperButton>
      </div>

      <KooperContainer>
        <KooperCard fluid>
          <KooperCardContent style={{padding: '18px'}}>
            <h3 className="mb-0">Employee Information</h3>
            <p className="mt-0 mb-4 card-description">
              Create employees profile by mentioning all the required information
            </p>

            <CreateEmployeeForm
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
              reset={reset}
              JoiningDate={JoiningDate}
              setJoiningDate={setJoiningDate}
              newLabelModal={newLabelModal}
              setNewLabelModal={setNewLabelModal}
              editId={editId}
            />
          </KooperCardContent>
        </KooperCard>

        <KooperCard fluid className="pb-5">
          <div>
            <KooperTab
              key="timeline"
              className="timeline"
              menu={{secondary: true, pointing: true, color: 'blue'}}
              panes={panes}
            />
          </div>
        </KooperCard>
      </KooperContainer>
    </div>
  )
}

export default CreateEmployee
