import React from 'react'
import {KooperButton, KooperModal, KooperModalContent, KooperModalHeader} from 'kooper-library'
import googleLogo from 'assets/google-logo.svg'
import emailLogo from 'assets/email-logo.svg'

const SetupModal = ({open, toggle, setCurrentModal}) => {
  return (
    <KooperModal size="small" closeIcon open={open} onClose={() => toggle()}>
      <KooperModalHeader>SetUp Email</KooperModalHeader>
      <KooperModalContent className="emlIbxSetupModal">
        <div style={{textAlign: 'center'}}>
          <h2 className="mb-3">Let's Connect Your Inbox!</h2>
          <p style={{width: '80%', margin: 'auto'}}>
            Connect the inbox you use for chatting with your customers, and all of your emails will automatically flow
            into kooper.
          </p>

          <div className="email-wrapper">
            <KooperButton onClick={() => setCurrentModal('gmailSetupModal')}>
              <img src={googleLogo} alt="google" />
              <p>Connect Gmail</p>
              <span>Start recieving your GSuite or Email directly into kooper</span>
            </KooperButton>
            <KooperButton onClick={() => setCurrentModal('otherSetupModal')}>
              <img src={emailLogo} alt="email" />
              <p>Other Email Client</p>
              <span>Setup email forwarding to receive email in your Kooper inbox</span>
            </KooperButton>
          </div>
        </div>
      </KooperModalContent>
    </KooperModal>
  )
}

export default SetupModal
