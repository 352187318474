/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Mention, MentionsInput} from 'react-mentions'
import {KooperAttachment, KooperButton, KooperEmojiPicker, KooperTooltip} from 'kooper-library'

import {sendNote} from 'actions/inbox'
import {uploadAttachments} from 'actions/attachments'
import {SEND_NOTE} from 'actions/types'
import AccountUserImage from 'components/common/AccountUserImage'
import UserImage from 'components/common/UserImage'
import {get, getPermissionTooltipContent} from 'utils/helper'
import classNames from 'styles/example.module.css'
import {getUserInfo} from 'utils/local-storage'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const EmailBodyFooter = props => {
  const {
    layout,
    noteToMessage,
    setNoteToMessage,
    teammate,
    filesNote,
    setFilesNote,
    requestToken,
    MessagesId,
    convertNote,
    uploadedFilesNote,
  } = props

  const dispatch = useDispatch()

  const emojiRef = useRef()
  const emojiButtonRef = useRef()

  const [mentionedTeammates, setmentionedTeamamtes] = useState([])
  const [isEmojiPickerEnabled, setEmojiPickerEnabled] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {conversationsPermissions} = useUserPermissions()
  const manageConversationPermissions = conversationsPermissions?.manage_conversations

  useEffect(() => {
    if (successLabels.includes(SEND_NOTE)) {
      setmentionedTeamamtes([])
    }
  }, [successLabels])

  useEffect(() => {
    function handleClickOutside(e) {
      if (
        emojiRef.current &&
        emojiButtonRef.current &&
        !emojiRef.current.contains(e.target) &&
        !emojiButtonRef.current.contains(e.target)
      ) {
        setEmojiPickerEnabled(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [emojiRef, emojiButtonRef])

  const getTeammatesExcludingMe = () => {
    const userId = get(['id'], getUserInfo())
    if (teammate) {
      return teammate
        .map(teammate => {
          const name = `${teammate.firstName} ${teammate.lastName}`
          return {
            id: teammate.id,
            display: name,
            avatar: teammate,
          }
        })
        .filter(teammate => teammate.id !== Number(userId))
    }
  }

  const getTeammatesForSuggesions = () => {
    const teamMatesForMention = getTeammatesExcludingMe()
    if (teamMatesForMention) {
      teamMatesForMention.push({id: -1, display: 'all'})
      return teamMatesForMention
    }
  }

  const onEnter = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()

      const data = {
        messageId: MessagesId,
        note: convertNote(noteToMessage),
        mentions: mentionedTeammates,
      }
      if (filesNote.length > 0) {
        data.attachments = uploadedFilesNote.map(file => file.id)
      }
      if (noteToMessage !== '' || uploadedFilesNote.length !== 0) {
        dispatch(sendNote(data))
      }
    }
  }

  const onMentionAdd = id => {
    if (id === -1) setmentionedTeamamtes(getTeammatesExcludingMe().map(mate => mate.id))
    else setmentionedTeamamtes([...mentionedTeammates, id])
  }

  const emojiSetFunction = emoji => {
    const noteArr = noteToMessage.split(' ')
    noteArr.push(emoji.native)
    const noteStr = noteArr.join('')
    setNoteToMessage(noteStr)
  }

  return (
    <div className="addNote" style={{marginRight: '4px'}}>
      <AccountUserImage />

      <MentionsInput
        style={layout === 'listView' ? {width: '90%'} : {width: '87%'}}
        placeholder="Type note here..."
        className="addNoteTextarea ml-2"
        value={noteToMessage}
        onChange={e => manageConversationPermissions && setNoteToMessage(e.target.value)}
        onKeyDown={e => manageConversationPermissions && noteToMessage.trim() && onEnter(e)}
        allowSuggestionsAboveCursor
        disabled={!manageConversationPermissions}
      >
        <Mention
          trigger="@"
          markup="@@@__display__@@^^^"
          data={getTeammatesForSuggesions()}
          renderSuggestion={suggetion => (
            <div key={suggetion.id} className="mentionUser">
              {suggetion.avatar ? (
                <UserImage profile={suggetion.avatar} className="ui avatar image" />
              ) : (
                <UserImage className="ui avatar image" />
              )}
              {suggetion.display}
            </div>
          )}
          className={classNames.mentions__mention}
          // style={{
          //   backgroundColor: "rgba(29,137,255,0.15)",
          //   lineHeight: "1.8",
          // }}
          appendSpaceOnAdd
          onAdd={onMentionAdd}
        />
      </MentionsInput>

      <KooperTooltip
        content={
          manageConversationPermissions
            ? 'Attachment'
            : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
        }
        position="top center"
        trigger={
          manageConversationPermissions ? (
            <div>
              <KooperAttachment
                style={{margin: '0 5px'}}
                title=""
                content={<SvgIcon path="common/attachment" />}
                multiple
                onChange={value => {
                  const data = {
                    file: value,
                  }

                  if (value.length > 0) {
                    dispatch(uploadAttachments(data, 'noteAttachment', requestToken))
                  }
                }}
                onClick={e => {
                  e.target.value = null
                }}
                fileList={files => {
                  setFilesNote(files)
                }}
              />
            </div>
          ) : (
            <div>
              <button style={{margin: '0 5px'}} type="button" className="ui icon button attach">
                <SvgIcon path="common/attachment" />
              </button>
            </div>
          )
        }
        size="mini"
      />

      <KooperTooltip
        content={
          manageConversationPermissions
            ? 'Emojis'
            : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
        }
        disabled={isEmojiPickerEnabled}
        trigger={
          <div ref={emojiButtonRef}>
            <KooperButton
              style={{margin: '0 5px'}}
              icon={<SvgIcon path="common/emoji" />}
              basic
              circular
              onClick={() => manageConversationPermissions && setEmojiPickerEnabled(!isEmojiPickerEnabled)}
            />
          </div>
        }
        size="mini"
        position="top center"
      />

      {isEmojiPickerEnabled && (
        <div ref={emojiRef}>
          <KooperEmojiPicker
            showSkinTones={false}
            showPreview={false}
            title={null}
            onClick={emoji => {
              emojiSetFunction(emoji)
            }}
          />
        </div>
      )}

      <KooperTooltip
        content={
          manageConversationPermissions
            ? 'Send'
            : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
        }
        size="mini"
        position="top center"
        trigger={
          <div>
            <KooperButton
              style={{margin: '0 5px'}}
              icon={<SvgIcon path="common/send" />}
              onClick={() => {
                if (!manageConversationPermissions) return
                const data = {
                  messageId: MessagesId,
                  note: convertNote(noteToMessage),
                  mentions: mentionedTeammates,
                }
                if (filesNote.length > 0) {
                  data.attachments = uploadedFilesNote.map(file => file.id)
                }

                if (noteToMessage.trim() || uploadedFilesNote.length !== 0) {
                  dispatch(sendNote(data))
                }
              }}
            />
          </div>
        }
      />
    </div>
  )
}

export default EmailBodyFooter
