import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

import {COPY_PROJECTS_NOTEBOOK_NOTE} from 'actions/types'
import {copyProjectsNotebookNote} from 'actions/projects'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const CopyNoteModal = ({open, toggle, projectId, notebookId, noteTitle, noteId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsList = [], notebooksList} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        title: Joi.string().trim().min(3).max(50).required().messages({
          'string.min': `Length must me at least 3 charactes`,
          'string.max': `Length must be less than or equal to 50 characters`,
        }),
        projectId: Joi.number().required().messages({
          'number.base': 'Project must be specified',
        }),
        notebookId: Joi.number().required().messages({
          'number.base': 'Notebook must be specified',
        }),
      })
    ),
    defaultValues: {
      title: '',
      projectId: null,
      notebookId: null,
    },
  })

  useEffect(() => {
    if (noteTitle && projectId && notebookId) {
      const data = {title: `Copy of ${noteTitle}`, projectId: Number(projectId), notebookId}
      reset(data)
    }
  }, [noteTitle, projectId, notebookId, reset])

  useEffect(() => {
    if (successLabels.includes(COPY_PROJECTS_NOTEBOOK_NOTE)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    dispatch(copyProjectsNotebookNote({projectId, noteId, data}))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Copy Note</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <label>Title</label>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <label>Project</label>
            <Controller
              name="projectId"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  options={projectsList.map(({id, title}) => ({key: id, value: id, text: title}))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.projectId && {content: removeDoubleQuotes(errors.projectId.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <label>Notebook</label>
            <Controller
              name="notebookId"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  options={notebooksList.map(({id, title}) => ({key: id, value: id, text: title}))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.notebookId && {content: removeDoubleQuotes(errors.notebookId.message)}}
                />
              )}
            />
          </KooperFormField>
          <p>Note: Copying this note to the same notebook will not copy its comments.</p>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Ok
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default CopyNoteModal
