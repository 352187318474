import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  KooperModal,
  KooperModalActions,
  KooperModalHeader,
  KooperForm,
  KooperFormField,
  KooperModalContent,
  KooperTextArea,
  KooperDropdown,
  KooperButton,
  KooperDatePicker,
  KooperFormInput,
} from 'kooper-library'
import {winDeal} from 'actions/deals'
import {WIN_DEAL} from 'actions/types'
import SvgIcon from 'components/common/SvgIcon'

const WonModal = props => {
  const dispatch = useDispatch()

  const {
    isModalOpen,
    setIsModalOpen,
    Id,
    type: {type, wonValue},
  } = props
  const [typeList, setTypeList] = useState([])
  const {getTypeListData} = useSelector(state => state.deals)
  const [closedDateDeal, setclosedDateDeal] = useState(new Date())
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const [editInfo, setEditInfo] = useState({
    closedAt: new Date(),
    monetaryValue: wonValue,
  })
  const wonDealBtn = () => {
    dispatch(winDeal(Id, editInfo))
  }
  const [isCompany, setIsCompany] = useState({})

  // const {
  //   control,
  //   errors,
  //   reset,
  //   watch,
  //   handleSubmit,
  //   formState: { dirtyFields, isDirty },
  // } = useForm({
  //   shouldFocusError: true,
  //   mode: "onTouched",
  //   reValidateMode: "onChange",
  //   defaultValues: {
  //     lossReason: null,
  //     closedNote: "",
  //     closedAt: new Date(),
  //   },
  //   resolver: joiResolver(
  //     Joi.object({
  //       lossReason: Joi.number().required(),
  //       closedNote: Joi.string().trim().allow("", null),
  //       closedAt: Joi.date().iso().required(),
  //     })
  //   ),
  // });

  useEffect(() => {
    if (type) {
      setIsCompany({
        contactCompany: type.contacts && type.contacts.filter(list => list.company !== null),
        leadCompany: type.leads && type.leads.filter(list => list.company !== null),
      })
    }
  }, [type])

  useEffect(() => {
    if (getTypeListData) {
      const companyList = getTypeListData.map(list => ({
        key: list.id,
        value: list.id,
        text: list.type,
      }))
      setTypeList(companyList)
    }
  }, [getTypeListData])

  useEffect(() => {
    if (successLabels.includes(WIN_DEAL)) {
      setEditInfo({closedAt: new Date()})
      setIsCompany({})
    }
  }, [successLabels])

  return (
    <KooperModal
      size="tiny"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isModalOpen}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <KooperModalHeader>Won Deal</KooperModalHeader>
      <KooperModalContent>
        <h5>Would you like to update your records to reflect a new Contact Type?</h5>
        <KooperForm>
          <KooperFormField required>
            <label>Won Value</label>
            <KooperFormInput
              placeholder="type here..."
              className="mb-3"
              type="number"
              value={editInfo.monetaryValue}
              onChange={(e, {value}) => {
                setEditInfo({...editInfo, monetaryValue: value})
              }}
            />
            <p className="kooper-lead">
              Lost opportunities can be seen in the opportunities list by selecting the status filter and checking the
              'Lost' box
            </p>
          </KooperFormField>
          {type.contacts !== null && (
            <KooperFormField className="mb-4">
              <label>Change type of contacts to contact Type</label>
              <KooperDropdown
                fluid
                selection
                search
                options={typeList}
                value={_.get(editInfo, 'contactTypeId')}
                onChange={(e, {value}) => {
                  setEditInfo({
                    ...editInfo,
                    contactTypeId: value,
                  })
                }}
              />
            </KooperFormField>
          )}
          {type.contacts !== null && _.size(isCompany.contactCompany) !== 0 && (
            <KooperFormField className="mb-4">
              <label>Change type of companies to companyType</label>
              <KooperDropdown
                fluid
                selection
                search
                options={typeList}
                value={_.get(editInfo, 'companyTypeId')}
                onChange={(e, {value}) => {
                  setEditInfo({
                    ...editInfo,
                    companyTypeId: value,
                  })
                }}
              />
            </KooperFormField>
          )}

          <KooperFormField>
            <label>Add Note</label>
            <KooperTextArea
              placeholder="type here..."
              className="mb-3"
              value={editInfo.closedNote}
              onChange={(e, {value}) => {
                setEditInfo({...editInfo, closedNote: value})
              }}
            />
            <p className="kooper-lead">
              Lost opportunities can be seen in the opportunities list by selecting the status filter and checking the
              'Lost' box
            </p>
          </KooperFormField>

          <KooperFormField required>
            <label>Closed Date</label>
            <KooperDatePicker
              
              value={closedDateDeal}
              inputProps={{readOnly: true}}
              onChange={value => {
                setEditInfo({...editInfo, closedAt: value.toISOString()})
                setclosedDateDeal(value)
              }}
              min={new Date()}
              time={false}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton
          basic
          onClick={() => {
            setIsModalOpen(false)
            setEditInfo({closedAt: new Date()})
            setIsCompany({})
          }}
        >
          Cancel
        </KooperButton>
        <KooperButton disabled={editInfo.monetaryValue.length === 0} primary onClick={() => wonDealBtn()}>
          Save
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default WonModal
