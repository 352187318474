import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Controller, useFieldArray} from 'react-hook-form'
import _ from 'lodash'
import {
  KooperButton,
  KooperDatePicker,
  KooperForm,
  KooperFormCheckbox,
  KooperFormDropdown,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperTooltip,
} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'

const RenderFilter = ({rulesFields, rulesIndex, rulesRemove, control, errors, clearErrors, watch, setValue}) => {
  const {
    fields: filtersFields,
    append: filtersAppend,
    remove: filtersRemove,
  } = useFieldArray({
    control,
    name: `rules[${rulesIndex}].filters`,
  })

  const {campaignFilters = []} = useSelector(state => state.campaign)

  const getSelectedKeyValue = (key, option) => {
    const selectedKey = campaignFilters.find(item => item.key === key)
    if (selectedKey) {
      return selectedKey[option]
    }
    return ''
  }

  const renderAppropriateField = (type, name, keyWatch, placeholder) => {
    switch (type) {
      case 'drodown':
        return (
          <KooperFormField width={4}>
            <Controller
              name={name}
              control={control}
              render={({value, onChange}) => (
                <KooperFormDropdown
                  selection
                  value={value}
                  defaultValue={value}
                  options={campaignFilters
                    .find(val => keyWatch === val.key)
                    ?.data?.map(obj => {
                      return {key: obj, value: obj, text: obj}
                    })}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    _.get(errors, name) && {
                      content: _.get(errors, `${name}.message`),
                    }
                  }
                  placeholder={placeholder}
                />
              )}
            />
          </KooperFormField>
        )

      case 'text':
        return (
          <KooperFormField width={4}>
            <Controller
              name={name}
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  type="text"
                  value={value}
                  defaultValue={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    _.get(errors, name) && {
                      content: _.get(errors, `${name}.message`),
                    }
                  }
                  placeholder={placeholder}
                />
              )}
            />
          </KooperFormField>
        )

      case 'number':
        return (
          <KooperFormField width={4}>
            <Controller
              name={name}
              control={control}
              render={({value, onChange}) => {
                return (
                  <KooperFormInput
                    type="number"
                    value={value}
                    defaultValue={value}
                    onChange={e => onChange(e.target.value)}
                    error={
                      _.get(errors, name) && {
                        content: _.get(errors, `${name}.message`),
                      }
                    }
                    placeholder={placeholder}
                  />
                )
              }}
            />
          </KooperFormField>
        )

      case 'date':
        return (
          <KooperFormField width={4}>
            <Controller
              control={control}
              name={name}
              render={({onChange, value}) => (
                <KooperDatePicker
                  max={new Date()}
                  onChange={value => {
                    onChange(value)
                  }}
                  defaultValue={typeof value === 'string' ? new Date(value) : value}
                  
                  dropUp
                  step={60}
                  inputProps={{
                    component: props => <input {...props} readOnly />,
                  }}
                />
              )}
            />
          </KooperFormField>
        )

      default:
        break
    }
  }

  useEffect(() => {
    if (!filtersFields[0]) {
      filtersAppend({
        key: '',
        condition: '',
      })
    }
  }, [filtersAppend, filtersFields])

  return (filtersFields || []).map((obj, filterIndex) => {
    const keyWatch = watch(`rules[${rulesIndex}].filters[${filterIndex}].key`)
    const conditionWatch = watch(`rules[${rulesIndex}].filters[${filterIndex}].condition`)
    return (
      <React.Fragment key={obj.id}>
        <section>
          <KooperForm>
            <KooperFormGroup className="m-0">
              <KooperFormField width={4}>
                <Controller
                  name={`rules[${rulesIndex}].filters[${filterIndex}].key`}
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormDropdown
                      selection
                      value={value}
                      defaultValue={value}
                      onClick={() => clearErrors([`rules[${rulesIndex}].filters[${filterIndex}].key`])}
                      placeholder="Select Filter"
                      options={(campaignFilters || []).map(obj => {
                        return {
                          key: obj.key,
                          value: obj.key,
                          text: obj.title,
                        }
                      })}
                      onChange={(e, {value}) => {
                        onChange(value)
                        setValue(`rules[${rulesIndex}].filters[${filterIndex}].condition`, '')
                        setValue(`rules[${rulesIndex}].filters[${filterIndex}].primaryValue`, '')
                        setValue(`rules[${rulesIndex}].filters[${filterIndex}].secondryValue`, '')
                        clearErrors([
                          `rules[${rulesIndex}].filters[${filterIndex}].condition`,
                          `rules[${rulesIndex}].filters[${filterIndex}].primaryValue`,
                          `rules[${rulesIndex}].filters[${filterIndex}].secondryValue`,
                        ])
                      }}
                      error={
                        _.get(errors, `rules[${rulesIndex}].filters[${filterIndex}].key`) && {
                          content: errors.rules[rulesIndex].filters[filterIndex].key.message,
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>

              {keyWatch && (
                <KooperFormField width={4}>
                  <Controller
                    name={`rules[${rulesIndex}].filters[${filterIndex}].condition`}
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormDropdown
                        selection
                        value={value}
                        defaultValue={value}
                        placeholder="Select Condition"
                        onClick={() => clearErrors([`rules[${rulesIndex}].filters[${filterIndex}].condition`])}
                        options={campaignFilters
                          .find(val => val.key === keyWatch)
                          ?.conditions?.map(obj => {
                            return {
                              key: obj.key,
                              text: obj.title,
                              value: obj.key,
                            }
                          })}
                        onChange={(e, {value}) => {
                          onChange(value)
                          setValue(`rules[${rulesIndex}].filters[${filterIndex}].primaryValue`, '')
                          setValue(`rules[${rulesIndex}].filters[${filterIndex}].secondryValue`, '')
                          clearErrors([
                            `rules[${rulesIndex}].filters[${filterIndex}].primaryValue`,
                            `rules[${rulesIndex}].filters[${filterIndex}].secondryValue`,
                          ])
                        }}
                        error={
                          _.get(errors, `rules[${rulesIndex}].filters[${filterIndex}].condition`) && {
                            content: errors.rules[rulesIndex].filters[filterIndex].condition.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
              )}

              {keyWatch &&
                conditionWatch &&
                getSelectedKeyValue(keyWatch, 'primaryValueType') &&
                renderAppropriateField(
                  getSelectedKeyValue(keyWatch, 'primaryValueType'),
                  `rules[${rulesIndex}].filters[${filterIndex}].primaryValue`,
                  keyWatch,
                  getSelectedKeyValue(keyWatch, 'primaryValueLabel')
                )}

              {keyWatch &&
                conditionWatch &&
                getSelectedKeyValue(keyWatch, 'secondryValueType') &&
                renderAppropriateField(
                  getSelectedKeyValue(keyWatch, 'secondryValueType'),
                  `rules[${rulesIndex}].filters[${filterIndex}].secondryValue`,
                  keyWatch,
                  getSelectedKeyValue(keyWatch, 'secondryValueLabel')
                )}

              <KooperFormField width={4}>
                <div style={{display: 'flex'}}>
                  <KooperTooltip
                    position="top center"
                    content="Delete Condition"
                    size="mini"
                    trigger={
                      <KooperButton
                        icon
                        basic
                        disabled={filtersFields.length === 1 && rulesFields.length === 1}
                        onClick={() => {
                          if (rulesFields[rulesIndex].filters.length === 1) {
                            rulesRemove(rulesIndex)
                          } else {
                            filtersRemove(filterIndex)
                          }
                        }}
                      >
                        <SvgIcon path="common/delete" />
                      </KooperButton>
                    }
                  />
                  {filtersFields.length < 2 && (
                    <KooperTooltip
                      position="top center"
                      content="Add New Condition"
                      size="mini"
                      trigger={
                        <KooperButton
                          basic
                          className="ml-3"
                          icon
                          onClick={() =>
                            filtersAppend({
                              key: '',
                              condition: '',
                            })
                          }
                        >
                          <SvgIcon path="common/plus" />
                        </KooperButton>
                      }
                    />
                  )}
                </div>
              </KooperFormField>
            </KooperFormGroup>
          </KooperForm>
        </section>

        {filterIndex < filtersFields.length - 1 && (
          <div className="slider">
            <p>OR</p>
            <Controller
              control={control}
              name={`rules[${rulesIndex}].operator`}
              render={({value, onChange}) => (
                <KooperFormCheckbox
                  slider
                  className="mx-3"
                  value={value}
                  onClick={(e, {checked}) => (checked ? onChange('and') : onChange('or'))}
                  checked={value === 'and'}
                  error={
                    _.get(errors, `rules[${rulesIndex}].operator`) && {
                      content: errors.rules[rulesIndex].operator.message,
                    }
                  }
                />
              )}
            />
            <p>AND</p>
          </div>
        )}
      </React.Fragment>
    )
  })
}

export default RenderFilter
