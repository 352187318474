import DocumentTitle from 'components/common/DocumentTitle'
import AllGoals from 'components/pages/Teams/Goals/AllGoalsList'
import OrganizationGoals from 'components/pages/Teams/Goals/OrganizationGoals'
import PersonalGoals from 'components/pages/Teams/Goals/PersonalGoals'
import SettingsGoals from 'components/pages/Teams/Goals/SettingsGoals'
import TeamsGoals from 'components/pages/Teams/Goals/TeamsGoals'
import GoalsDetails from 'pages/authenticated/Teams/Goals/GoalsDetails'

import {Redirect, Route, Switch} from 'react-router-dom'

export const goalsRoutes = [
  {path: '/goals/all', component: AllGoals, title: 'All Goals', exact: true},
  {path: '/goals/organizational', component: OrganizationGoals, title: 'Organization', exact: false},
  {path: '/goals/team', component: TeamsGoals, title: 'Team', exact: false},
  {path: '/goals/personal', component: PersonalGoals, title: 'Personal', exact: false},
  {path: '/goals/details/:type', component: GoalsDetails, title: 'Goals Details', exact: false},
  {path: '/goals/settings', component: SettingsGoals, title: 'settings', exact: false},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} salesOrders />
        <Component {...props} />
      </>
    )}
  />
)

const GoalsRoutes = () => (
  <Switch>
    {goalsRoutes.map(renderRoute)}
    <Route path="/goals">
      <Redirect to="/goals/all" />
    </Route>
  </Switch>
)

export default GoalsRoutes
