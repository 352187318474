import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {FiChevronRight, FiExternalLink} from 'react-icons/fi'
import moment from 'moment'
import {
  KooperButton,
  KooperLabel,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {DELETE_PROJECTS_TIMESHEET_TIME_LOGS, GET_PROJECTS_TIME_TRACKING} from 'actions/types'
import {deleteProjectsTimesheetTimeLogs} from 'actions/projects'
import {get, getPermissionTooltipContent, startCase} from 'utils/helper'
import DeleteModal from 'components/common/DeleteModal'
import SvgIcon from 'components/common/SvgIcon'
import {PROJECTS_PATH} from 'constants/path'
import TimeModal from '../Projects/Steps/Time/TimeModal'
import TimesheetModal from '../Projects/Steps/Time/TimesheetModal'

const TimeTrackingList = ({currentListParams, rolePermissions}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isTimeTrackingModalOpen, setIsTimeTrackingModalOpen] = useState(false)
  const [projectId, setProjectId] = useState(null)
  const [timesheetId, setTimesheetId] = useState(null)
  const [timeId, setTimeId] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isTimesheetModalOpen, setIsTimesheetModalOpen] = useState(false)
  const [activeAccordion, setActiveAccordion] = useState(0)

  const {type = []} = useSelector(state => state.apiReducer)

  const {timeTrackingList = {}, projectsList = [], timesheets = []} = useSelector(state => state.projects)
  const {getUsersList: {users = []} = {}} = useSelector(state => state.settings)

  const getValue = key => {
    if (currentListParams.groupBy === 'date') {
      return moment(key).format('MMM DD')
    }
    if (currentListParams.groupBy === 'person') {
      const user = users.find(user => user.id === +key)
      if (user) {
        return `${get(['firstName'], user, '')} ${get(['lastName'], user, '')}`
      }
      return null
    }
    if (currentListParams.groupBy === 'project') {
      const project = projectsList.find(project => project.id === +key)
      if (project) {
        return project.title
      }
      return null
    }
    if (currentListParams.groupBy === 'timesheet') {
      const timesheet = timesheets.find(timesheet => timesheet.id === +key)
      if (timesheet) {
        return timesheet.title
      }
      return null
    }
    return null
  }

  const getTotalTimeLogged = data => {
    const hoursMinutesData = data.map(({loggedHrs, loggedMins}) => ({loggedHrs, loggedMins}))
    const totalMinutes = hoursMinutesData.reduce((acc, currentValue) => {
      let minutes = 0
      if (currentValue.loggedHrs) {
        minutes += currentValue.loggedHrs * 60
      }
      if (currentValue.loggedMins) {
        minutes += currentValue.loggedMins
      }
      return acc + minutes
    }, 0)

    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60

    return `${hours}h ${minutes}m`
  }

  return (
    <>
      <KooperTable basic selectable>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell width="2">Creator</KooperTableHeaderCell>
            <KooperTableHeaderCell width="1">Date</KooperTableHeaderCell>
            <KooperTableHeaderCell width="2">Time Logged</KooperTableHeaderCell>
            <KooperTableHeaderCell width="5">Description</KooperTableHeaderCell>
            <KooperTableHeaderCell width="2">Task</KooperTableHeaderCell>
            <KooperTableHeaderCell width="2">Status</KooperTableHeaderCell>
            <KooperTableHeaderCell width="2">Action</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>

        <KooperTableBody>
          {type.includes(GET_PROJECTS_TIME_TRACKING) && <KooperTablePlaceholder columns={7} row={3} />}
          {!type.includes(GET_PROJECTS_TIME_TRACKING) &&
            Object.entries(timeTrackingList).map(([key, data], index) => {
              return (
                <>
                  <KooperTableRow
                    className="collapse-table"
                    onClick={() => setActiveAccordion(activeAccordion === index ? '-1' : index)}
                  >
                    <KooperTableCell colSpan="2">
                      {activeAccordion === index ? (
                        <SvgIcon path="common/down" style={{marginRight: 8}} />
                      ) : (
                        <FiChevronRight style={{marginRight: 8}} />
                      )}{' '}
                      {getValue(key)}
                    </KooperTableCell>
                    <KooperTableCell>{getTotalTimeLogged(data)}</KooperTableCell>
                    <KooperTableCell colSpan="3" />
                    <KooperTableCell>
                      {currentListParams.groupBy === 'timesheet' && (
                        <div
                          className="d-flex"
                          style={{
                            justifyContent: 'flex-start',
                          }}
                        >
                          <KooperTooltip
                            trigger={
                              <KooperButton
                                icon
                                className="mr-3"
                                onClick={() =>
                                  history.push({
                                    pathname: `/${PROJECTS_PATH.PREFIX}/${data[0].projectId}/${PROJECTS_PATH.TIME}`,
                                    state: {id: +key},
                                  })
                                }
                              >
                                <FiExternalLink />
                              </KooperButton>
                            }
                            size="mini"
                            position="top center"
                            content="Go to Timesheet"
                          />
                          <KooperTooltip
                            trigger={
                              <KooperButton
                                icon
                                onClick={() => {
                                  if (rolePermissions) {
                                    setProjectId(data[0].projectId)
                                    setTimesheetId(key)
                                    setIsTimesheetModalOpen(true)
                                  }
                                }}
                              >
                                <SvgIcon path="common/edit2" />
                              </KooperButton>
                            }
                            size="mini"
                            position="top center"
                            content={
                              rolePermissions
                                ? 'Edit Timesheet'
                                : getPermissionTooltipContent({roleAccess: rolePermissions})
                            }
                          />
                        </div>
                      )}
                    </KooperTableCell>
                  </KooperTableRow>
                  {data.map(timeTracking => (
                    <KooperTableRow
                      key={timeTracking.id}
                      className="tableLink"
                      style={{
                        display: activeAccordion === index ? '' : 'none',
                      }}
                      onClick={() => {
                        if (rolePermissions) {
                          setProjectId(timeTracking.projectId)
                          setTimesheetId(timeTracking.timesheetId)
                          setTimeId(timeTracking.id)
                          setIsTimeTrackingModalOpen(true)
                        }
                      }}
                    >
                      <KooperTableCell>
                        {`${startCase(get(['createdByDetails', 'firstName'], timeTracking, ''))} ${startCase(
                          get(['createdByDetails', 'lastName'], timeTracking, '')
                        )}`}
                      </KooperTableCell>
                      <KooperTableCell>{moment(timeTracking.date).format('MMM DD')}</KooperTableCell>
                      <KooperTableCell>{`${get(['loggedHrs'], timeTracking, 0)}h ${get(
                        ['loggedMins'],
                        timeTracking,
                        0
                      )}m`}</KooperTableCell>
                      <KooperTableCell>{get(['description'], timeTracking, '')}</KooperTableCell>
                      <KooperTableCell>
                        {timeTracking.taskDetails && startCase(get(['taskDetails', 'title'], timeTracking))}
                      </KooperTableCell>
                      <KooperTableCell>
                        <KooperLabel color="green">{startCase(get(['status'], timeTracking, ''))}</KooperLabel>
                      </KooperTableCell>
                      <KooperTableCell>
                        <div
                          className="d-flex"
                          style={{
                            justifyContent: 'flex-start',
                          }}
                        >
                          <KooperTooltip
                            trigger={
                              <KooperButton
                                icon
                                className="mr-3"
                                onClick={e => {
                                  e.stopPropagation()
                                  if (rolePermissions) {
                                    setProjectId(timeTracking.projectId)
                                    setTimesheetId(timeTracking.timesheetId)
                                    setTimeId(timeTracking.id)
                                    setIsTimeTrackingModalOpen(true)
                                  }
                                }}
                              >
                                <SvgIcon path="common/edit2" />
                              </KooperButton>
                            }
                            size="mini"
                            position="top center"
                            content={
                              rolePermissions ? 'Edit' : getPermissionTooltipContent({roleAccess: rolePermissions})
                            }
                          />
                          <KooperTooltip
                            trigger={
                              <KooperButton
                                icon
                                className="mr-3"
                                onClick={e => {
                                  e.stopPropagation()
                                  if (rolePermissions) {
                                    setProjectId(timeTracking.projectId)
                                    setTimesheetId(timeTracking.timesheetId)
                                    setTimeId(timeTracking.id)
                                    setIsDeleteModalOpen(true)
                                  }
                                }}
                              >
                                <SvgIcon path="common/delete" />
                              </KooperButton>
                            }
                            size="mini"
                            position="top center"
                            content={
                              rolePermissions ? 'Delete' : getPermissionTooltipContent({roleAccess: rolePermissions})
                            }
                          />
                          {/* <KooperTooltip
                          trigger={
                            <KooperButton icon>
                              <FiDownload />
                            </KooperButton>
                          }
                          size="mini"
                          position="top center"
                          content="Export"
                        /> */}
                        </div>
                      </KooperTableCell>
                    </KooperTableRow>
                  ))}
                </>
              )
            })}
        </KooperTableBody>
      </KooperTable>

      {isTimeTrackingModalOpen && (
        <TimeModal
          open={isTimeTrackingModalOpen}
          toggle={setIsTimeTrackingModalOpen}
          projectId={projectId}
          timesheetId={timesheetId}
          timeId={timeId}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={() => dispatch(deleteProjectsTimesheetTimeLogs({projectId, timesheetId, timeIds: [timeId]}))}
          type={DELETE_PROJECTS_TIMESHEET_TIME_LOGS}
        />
      )}

      {isTimesheetModalOpen && (
        <TimesheetModal
          open={isTimesheetModalOpen}
          toggle={setIsTimesheetModalOpen}
          projectId={projectId}
          timesheetId={timesheetId}
        />
      )}
    </>
  )
}

export default TimeTrackingList
