import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {KooperButton, KooperFormInput, KooperModal, KooperModalActions, KooperModalContent} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'

function QuoteModal({open, toggle, link}) {
  const {push} = useHistory()
  const [copyLink, setCopyLink] = useState('Copy')
  return (
    <KooperModal
      open={open}
      onClose={toggle}
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      style={{textAlign: 'center'}}
    >
      <KooperModalContent>
        <h1>Download as PDf</h1>
        <h2 className="mt-3">A web page for your quote has been created</h2>
        <p className="my-4">
          Your quote has been successfully created, below is the link to download and view it. You can also send this
          quote directly to the customer from below button
        </p>

        <KooperFormInput
          readOnly
          value={link.link}
          style={{width: '80%'}}
          action={{
            color: 'blue',
            labelPosition: 'right',
            icon: 'copy',
            content: copyLink,
            onClick: () => {
              navigator.clipboard.writeText(link.link)
              setCopyLink('Copied')
            },
          }}
        />
        <div className="mt-4">
          <KooperButton
            primary
            onClick={() => {
              push({
                pathname: '/inbox',
                state: {
                  quoteState: link.link,
                },
              })
            }}
          >
            Send Quote
          </KooperButton>
          {/* <KooperButton basic className="ml-3">
            Download
          </KooperButton> */}
        </div>
      </KooperModalContent>

      <KooperModalActions>
        <KooperButton basic onClick={toggle}>
          Cancel
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default QuoteModal
