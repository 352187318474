import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import PurchasesCategory from 'components/pages/Finance/Purchases/Settings/Category/Category'
import Purchases from 'components/pages/Finance/Purchases/Purchases'
import {PURCHASES_PATH} from 'constants/path'

const routes = [
  {path: PURCHASES_PATH.ALL, component: Purchases, title: 'All Purchases', exact: true},
  {path: PURCHASES_PATH.CATEGORY, component: PurchasesCategory, title: 'Category', exact: true},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={`/${PURCHASES_PATH.PREFIX}/${path}`}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} purchases />
        <Component {...props} />
      </>
    )}
  />
)

const PurchasesRoutes = () => (
  <Switch>
    <Route exact path={`/${PURCHASES_PATH.PREFIX}`}>
      <Redirect to={`/${PURCHASES_PATH.PREFIX}/${PURCHASES_PATH.ALL}`} />
    </Route>
    {routes.map(renderRoute)}
    <Route path={`/${PURCHASES_PATH.PREFIX}`}>
      <Redirect to={`/${PURCHASES_PATH.PREFIX}/${PURCHASES_PATH.ALL}`} />
    </Route>
  </Switch>
)

export default PurchasesRoutes
