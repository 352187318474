import React from 'react'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom'
import TemplateFinanceRates from 'components/pages/Projects/Projects/ProjectTemplates/TemplateSteps/Finance/Rates/TemplateFinanceRates'
import TemplateFinanceBudgetSummary from 'components/pages/Projects/Projects/ProjectTemplates/TemplateSteps/Finance/Summary/TemplateFinanceSummary'
import {PROJECTS_PATH} from 'constants/path'

const TemplateFinanceRoute = ({templateId, budgetId}) => {
  const {path, url} = useRouteMatch()

  return (
    <Switch>
      <Route
        path={`${path}/${PROJECTS_PATH.TEMPLATES_FINANCE_SUMMARY}`}
        render={props => <TemplateFinanceBudgetSummary {...props} templateId={templateId} budgetId={budgetId} />}
      />
      <Route
        path={`${path}/${PROJECTS_PATH.TEMPLATES_FINANCE_RATES}`}
        render={props => <TemplateFinanceRates {...props} templateId={templateId} budgetId={budgetId} />}
      />
      <Route path={path} exact render={() => <Redirect to={`${url}/${PROJECTS_PATH.TEMPLATES_FINANCE_SUMMARY}`} />} />
    </Switch>
  )
}

export default TemplateFinanceRoute
