import {joiResolver} from '@hookform/resolvers'
import {get, removeDoubleQuotes} from 'utils/helper'
import {getUserInfo} from 'utils/local-storage'
import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {ADD_GMAIL_INBOX} from 'actions/types'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import {addGmailInbox} from 'actions/inbox'
import googleLogo from 'assets/google-logo.svg'
import {gmailSetupSchema} from 'validation/Settings/channels/emailInbox.schema'

const GmailSetupModal = ({open, toggle, assigneeOptionsList, teamOptionsList}) => {
  const dispatch = useDispatch()
  const userId = Number(get(['id'], getUserInfo()))

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {newGmailInboxData = ''} = useSelector(state => state.inboxSetup)

  const {handleSubmit, control, errors} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(gmailSetupSchema),
    defaultValues: {
      inboxName: '',
      assigneeIds: [userId],
      teamIds: [],
    },
  })

  useEffect(() => {
    if (successLabels.includes(ADD_GMAIL_INBOX)) {
      window.open(newGmailInboxData, '_self')
    }
  }, [successLabels, newGmailInboxData])

  return (
    <KooperModal size="small" closeIcon open={open} onClose={() => toggle()}>
      <KooperModalHeader>Connect Your Gmail</KooperModalHeader>
      <KooperModalContent className="emlIbxSetupModal">
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class" htmlFor="inboxName">
                Inbox Name
              </label>
              <KooperTooltip
                trigger={<AiOutlineInfoCircle />}
                content="Specify a name for your mailbox"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="inboxName"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  placeholder="Enter Inbox Name"
                  id="inboxName"
                  maxLength={30}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.inboxName && {
                      content: removeDoubleQuotes(errors.inboxName.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>

          <KooperFormField required>
            <div className="info-header">
              <label className="label-class" htmlFor="assigneeIds">
                Assignee
              </label>
              <KooperTooltip
                trigger={<AiOutlineInfoCircle />}
                content="Select from the drop down whom you want to assign"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="assigneeIds"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  placeholder="Select Assignee"
                  search
                  multiple
                  id="assigneeIds"
                  options={assigneeOptionsList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.assigneeIds && {
                      content: removeDoubleQuotes(errors.assigneeIds.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>

          <KooperFormField>
            <label htmlFor="teamIds">Team</label>
            <Controller
              name="teamIds"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  placeholder="Select Team"
                  search
                  multiple
                  id="teamIds"
                  options={teamOptionsList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.teamIds && {
                      content: removeDoubleQuotes(errors.teamIds.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle()}>
          Back
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(data => dispatch(addGmailInbox(data)))}>
          <img src={googleLogo} alt="google" />
          Connect Your Gmail Account
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default GmailSetupModal
