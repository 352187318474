import React, {useEffect, useState} from 'react'
import {joiResolver} from '@hookform/resolvers'
import {updateApprovalStatus} from 'actions/proofs'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {removeDoubleQuotes} from 'utils/helper'
import {approvalStatusSchema} from 'validation/Projects/projects/proofs.schema'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormTextArea,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'

const initialCreateInfo = {
  approvalStatus: '',
  remarks: '',
}
function ApproveStatusModal(props) {
  const {isApproveStatusModal, setIsApproveStatusModal, approveStatus, editId} = props
  const dispatch = useDispatch()

  const [successMessage, setSuccessMessage] = useState('')

  const {handleSubmit, errors, control} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(approvalStatusSchema),
    defaultValues: initialCreateInfo,
  })

  useEffect(() => {
    if (approveStatus === 'approved') {
      setSuccessMessage('Proof has been approved successfully')
    } else {
      setSuccessMessage('Submitted request for change')
    }
  }, [approveStatus])

  const submitStatus = data => {
    data.approvalStatus = approveStatus

    dispatch(updateApprovalStatus(editId, data, successMessage))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isApproveStatusModal}
      onClose={() => setIsApproveStatusModal(false)}
      size="small"
      centered={false}
    >
      <KooperModalHeader >
        {approveStatus === 'approved' ? "You're about to approve" : "You're about to request change"}
      </KooperModalHeader>

      <KooperModalContent scrolling>
        <KooperForm className="errorLabel">
          <KooperFormField required width={16}>
            <Controller
              name="remarks"
              render={({onChange, value}) => (
                <KooperFormTextArea
                  type="text"
                  value={value}
                  maxLength={250}
                  placeholder={approveStatus === 'approved' ? 'Any other feedback?' : 'What needs to be changed?'}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    errors.remarks && {
                      content: removeDoubleQuotes(errors.remarks.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>

      <KooperModalActions style={{position: 'relative'}}>
        <KooperButton basic onClick={() => setIsApproveStatusModal(false)}>
          Cancel
        </KooperButton>

        <KooperButton primary onClick={handleSubmit(submitStatus)}>
          {approveStatus === 'approved' ? 'Submit Approval' : 'Submit request'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default ApproveStatusModal
