import React from 'react'
import {
  KooperCheckbox,
  KooperDatePicker,
  KooperFormField,
  KooperFormInput,
  KooperFormRadio,
  KooperFormSelect,
  KooperFormTextArea,
} from 'kooper-library'

const PreviewQuestions = ({type, question, isRequired, settings, description}) => {
  switch (type) {
    case 'page':
      return (
        <KooperFormField>
          <div>
            <h3>{question}</h3>
          </div>
        </KooperFormField>
      )
    case 'header':
      return (
        <KooperFormField>
          <div>
            <h3>{question}</h3>
          </div>
        </KooperFormField>
      )
    case 'text':
      return (
        <KooperFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div className="form-description">
            <p>{description}</p>
          </div>
          <KooperFormInput type="text" />
        </KooperFormField>
      )
    case 'dropdown':
      return (
        <KooperFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div className="form-description">
            <p>{description}</p>
          </div>
          <KooperFormSelect options={settings?.options?.map(({choice}) => ({key: choice, value: choice, text: choice}))} />
        </KooperFormField>
      )
    case 'number':
      return (
        <KooperFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div className="form-description">
            <p>{description}</p>
          </div>
          <KooperFormInput type="number" />
        </KooperFormField>
      )
    case 'date':
      return (
        <KooperFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div className="form-description">
            <p>{description}</p>
          </div>
          <KooperDatePicker time={false} />
        </KooperFormField>
      )
    case 'checkbox':
      return (
        <KooperFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div className="form-description">
            <p>{description}</p>
          </div>
          {settings?.options?.map(({choice}) => (
            <KooperCheckbox className="mr-2" label={choice} />
          ))}
        </KooperFormField>
      )
    case 'radio':
      return (
        <KooperFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div className="form-description">
            <p>{description}</p>
          </div>
          {settings?.options?.map(({choice}) => (
            <KooperFormRadio className="mb-2" label={choice} />
          ))}
        </KooperFormField>
      )
    case 'textarea':
      return (
        <KooperFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div className="form-description">
            <p>{description}</p>
          </div>
          <KooperFormTextArea type="text" />
        </KooperFormField>
      )

    default:
      return null
  }
}

export default PreviewQuestions
