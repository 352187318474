import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'
import {MOVE_PROJECT_MILESTONE} from 'actions/types'
import {moveProjectMilestone} from 'actions/projects'
import {removeDoubleQuotes} from 'utils/helper'
import {milestoneMoveSchema} from 'validation/Projects/projects/milestone.schema'
import SvgIcon from 'components/common/SvgIcon'

const MoveMilestoneModal = ({open, toggle, projectId, milestoneId, milestoneName}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsList = []} = useSelector(state => state.projects)

  const {handleSubmit, control, errors} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(milestoneMoveSchema),
    defaultValues: {
      name: milestoneName,
      projectId: null,
    },
  })

  useEffect(() => {
    if (successLabels.includes(MOVE_PROJECT_MILESTONE)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    dispatch(moveProjectMilestone({projectId, milestoneId, data}))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Move Milestone to Another Project</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label>Name</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify name of the milestone"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="name"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.name && {content: removeDoubleQuotes(errors.name.message)}}
                />
              )}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label>Project</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Select project in which you want to move the milestone"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="projectId"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  options={projectsList
                    .filter(project => project.id !== Number(projectId))
                    .map(({id, title}) => ({key: id, value: id, text: title}))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.projectId && {content: removeDoubleQuotes(errors.projectId.message)}}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Move Milestone
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default MoveMilestoneModal
