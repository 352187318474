import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {CardElement, useStripe, useElements, Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import {createPaymentMethodsToken} from 'actions/billing'
import {KooperButton} from 'kooper-library'
import {CREATE_PAYMENT_METHOD} from 'actions/types'

const stripePromise = loadStripe(
  'pk_test_51OtQtADwqiosW2hDlJGtM366fL65LUMW18zi073xAH57gMruiK7SxkFW28Qz2M57HRjNHXXjfCd4bX5ykS6VoiAS00NrBUbG6D'
)

const CreateCreditCardForm = ({onCancelClick}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)

  const dispatch = useDispatch()

  const {type = []} = useSelector(state => state.apiReducer)

  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const cardElement = elements.getElement(CardElement)

    // Create token
    // const {token, error} = await stripe.createToken(cardElement)
    // console.log(error)

    // if (token?.id) {
    //   dispatch(createPaymentMethodsToken({token: token?.id}))
    // }

    // Create payment method
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      setError(error.message)
    } else {
      setError(null)
      dispatch(createPaymentMethodsToken({token: paymentMethod.id}))
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <CardElement
          onChange={event => {
            setError(event.error ? event.error.message : null)
          }}
          options={{
            hidePostalCode: true,
            disableLink: true,
            style: {
              base: {
                fontWeight: '500',
                fontFamily: 'Inter, Helvetica Neue, Arial, Helvetica, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
              },
              invalid: {
                iconColor: '#9f3a38',
                color: '#9f3a38',
              },
            },
          }}
        />
      </div>
      {error && <p style={{color: '#9f3a38', marginTop: '5px'}}>{error}</p>}
      <KooperButton className="mr-3 mt-2" basic onClick={onCancelClick}>
        Cancel
      </KooperButton>
      <KooperButton primary className="mt-2" disabled={!stripe || type.includes(CREATE_PAYMENT_METHOD)}>
        Add
      </KooperButton>
    </form>
  )
}

const CreditCardForm = ({onCancelClick}) => {
  return (
    <Elements stripe={stripePromise}>
      <CreateCreditCardForm onCancelClick={onCancelClick} />
    </Elements>
  )
}

export default CreditCardForm
