/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {useHistory, useParams} from 'react-router'
import _ from 'lodash'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperContainer,
  KooperDatePicker,
  KooperDropdown,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormRadio,
  KooperFormSelect,
  KooperGrid,
  KooperGridColumn,
  KooperGridRow,
  KooperInput,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {CREATE_BUDGET, GET_ACCOUNT_CURRENCY, GET_BUDGET_DATA, UPDATE_BUDGET} from 'actions/types'
import {getAccountCurrency} from 'actions/setting_currency'
import {getBreakdownList, createBudget, updateBudget, getBudgetData} from 'actions/budget'
import {removeDoubleQuotes, get} from 'utils/helper'
import {compareList, RangeBreakdown, months} from 'constants/finance/budgetPlanner'
import {createBudgetPlannerSchema} from 'validation/Finance/budgetPlanner.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useApiResponse from 'hooks/impure/useApiResponse'
import SvgIcon from 'components/common/SvgIcon'
import {BUDGETS_PLANNER_PATH} from 'constants/path'

const CreateBudgetPlanner = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id: editId} = useParams()

  const [currencyListData, setcurrencyListData] = useState([])
  const [usersList, setUsersList] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date('2023/12'))

  const {getBreakdownListData} = useSelector(state => state.budget)
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {financePermissions} = useUserPermissions()
  const manageBudgetPlannerPermissions = financePermissions?.manage_budget_planner

  const initialCreateInfo = {
    name: '',
    compareWith: '',
    compareType: '',
    currencyId: '',
    rangeBreakdown: 'month',
    rangeBreakdownBy: 'user',
    userId: null,
    contactId: null,
    projectId: null,
    rangeYear: new Date().getFullYear(),
    rangePeriod: 'date',
    startMonth: 0,
    startYear: 0,
    endMonth: 0,
    endYear: 0,
    ranges: [],
    rangeTotal: 0,
  }

  const {
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(createBudgetPlannerSchema),
    defaultValues: initialCreateInfo,
  })

  const watchRangeBreakdownBy = watch('rangeBreakdownBy')
  const watchRangeBreakdown = watch('rangeBreakdown')
  const watchRangePeriod = watch('rangePeriod')
  const watchRangeYear = watch('rangeYear')
  const watchRanges = watch('ranges')
  const watchUserId = watch('userId')
  const watchContactId = watch('contactId')
  const watchProjectId = watch('projectId')

  const {fields} = useFieldArray({
    control,
    name: 'ranges',
  })

  useEffect(() => {
    dispatch(getBreakdownList())
  }, [dispatch])

  const headerCellMonth = months.slice(startDate.getMonth(), endDate.getMonth() + 1)

  // For Sum Total
  function getSum(total, num) {
    return Number(total) + Number(Math.round(num))
  }

  const totalValue = watchRanges?.map(val => val.value)

  const sumTotal = totalValue?.reduce(getSum, 0)
  setValue('rangeTotal', sumTotal)

  const {data: budgetPlannerData = {}} = useApiResponse({
    action: getBudgetData,
    payload: editId,
    dependency: [editId],
    enabled: editId,
    label: GET_BUDGET_DATA,
    storePath: 'budget.budgetPlannerData',
    onSuccess: () => {
      const {
        name,
        compareWith,
        compareType,
        currencyId,
        rangeBreakdown,
        rangeBreakdownBy,
        rangeYear,
        rangePeriod,
        startMonth,
        startYear,
        endMonth,
        endYear,
        rangeTotal,
        ranges,
        userId,
        contactId,
        projectId,
      } = budgetPlannerData
      if (endMonth === null || endYear === null) {
        setEndDate(new Date())
      } else {
        endDate.setMonth(endMonth - 1)
        endDate.setFullYear(endYear)
      }
      if (startMonth === null || startYear === null) {
        setEndDate(new Date())
      } else {
        startDate.setMonth(startMonth - 1)
        startDate.setFullYear(startYear)
      }
      reset({
        name,
        compareWith,
        compareType,
        currencyId,
        rangeBreakdown,
        rangeBreakdownBy,
        rangeYear,
        rangePeriod,
        rangeTotal,
        ranges: ranges?.map(({month, value, year}) => {
          return {month, value, year}
        }),
        userId,
        contactId,
        projectId,
      })
    },
  })

  const userText = usersList?.filter(({value}) => {
    return value === watchUserId
  })
  const contactText = usersList?.filter(({value}) => {
    return value === watchContactId
  })
  const projectText = usersList?.filter(({value}) => {
    return value === watchProjectId
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_BUDGET) || successLabels.includes(UPDATE_BUDGET)) {
      history.push(`/${BUDGETS_PLANNER_PATH.PREFIX}`)
    }
  }, [successLabels, history, dispatch])

  useEffect(() => {
    if (watchRangePeriod === 'year') {
      if (watchRangeBreakdown === 'month') {
        if (
          get(['rangePeriod'], budgetPlannerData, '') === watchRangePeriod &&
          get(['rangeBreakdown'], budgetPlannerData, '') === watchRangeBreakdown &&
          get(['rangeYear'], budgetPlannerData, '') === +watchRangeYear
        ) {
          const data = get(['ranges'], budgetPlannerData, []).map(({month, year, value}) => ({month, year, value}))
          setValue('ranges', data)
        } else {
          const data = [...Array(12)].map((e, i) => ({month: i, year: watchRangeYear, value: 0}))
          setValue('ranges', data)
        }
      }
    }
  }, [watchRangePeriod, watchRangeYear, watchRangeBreakdown, setValue])

  useEffect(() => {
    if (watchRangePeriod === 'date') {
      if (watchRangeBreakdown === 'month') {
        const data = headerCellMonth.map((e, i) => ({
          month: i,
          year: startDate.getFullYear(),
          value: 0,
        }))
        setValue('ranges', data)
      }
    }
  }, [watchRangePeriod, watchRangeBreakdown, startDate, endDate, setValue])

  useEffect(() => {
    if (watchRangePeriod === 'date') {
      if (watchRangeBreakdown === 'year') {
        if (endDate.getFullYear() - startDate.getFullYear() >= 0) {
          const data = [...Array(endDate.getFullYear() - startDate.getFullYear() + 1)].map(() => ({
            year: startDate.getFullYear(),
            value: 0,
          }))
          setValue('ranges', data)
        } else {
          setValue('ranges', [])
        }
      }
    }
  }, [watchRangePeriod, watchRangeBreakdown, watchRangeYear, setValue])

  useEffect(() => {
    if (watchRangePeriod === 'year') {
      if (watchRangeBreakdown === 'year') {
        const data = [...Array(1)].map(() => ({year: watchRangeYear, value: 0}))
        setValue('ranges', data)
      }
    }
  }, [watchRangePeriod, watchRangeBreakdown, watchRangeYear, setValue])

  useEffect(() => {
    if (watchRangePeriod === 'year') {
      if (watchRangeBreakdown === 'quarter') {
        const data = [...Array(4)].map((e, i) => ({quarter: i, year: watchRangeYear, value: 0}))
        setValue('ranges', data)
      }
    }
  }, [watchRangePeriod, watchRangeBreakdown, watchRangeYear, setValue])

  useEffect(() => {
    if (watchRangePeriod === 'date') {
      if (watchRangeBreakdown === 'quarter') {
        const data = [...Array(4)].map((e, i) => ({quarter: i, year: watchRangeYear, value: 0}))
        setValue('ranges', data)
      }
    }
  }, [watchRangePeriod, watchRangeBreakdown, watchRangeYear, setValue])

  useEffect(() => {
    if (watchRangePeriod === 'date') {
      const StartMonth = startDate.getMonth()
      const StartYear = startDate.getFullYear()
      const EndMonth = endDate.getMonth()
      const EndYear = endDate.getFullYear()

      setValue('startMonth', StartMonth + 1)
      setValue('startYear', StartYear)
      setValue('endMonth', EndMonth + 1)
      setValue('endYear', EndYear)
    }
  }, [watchRangePeriod, startDate, endDate, setValue])

  useEffect(() => {
    if (watchRangeBreakdownBy === 'user') {
      const users = getBreakdownListData?.users.map(list => ({
        key: list.id,
        value: list.id,
        text: list.name,
      }))
      setUsersList(users)
    }

    if (watchRangeBreakdownBy === 'contact') {
      const contact = getBreakdownListData?.contacts.map(list => ({
        key: list.id,
        value: list.id,
        text: list.name,
      }))
      setUsersList(contact)
    }

    if (watchRangeBreakdownBy === 'project') {
      const projectList = getBreakdownListData?.projects.map(list => ({
        key: list.id,
        value: list.id,
        text: list.name,
      }))
      setUsersList(projectList)
    }
  }, [watchRangeBreakdownBy, getBreakdownListData])

  useApiResponse({
    label: GET_ACCOUNT_CURRENCY,
    enabled: true,
    storePath: 'settingCurrency.accountCurrencyList',
    action: getAccountCurrency,
    onSuccess: accountCurrencyList => {
      if (accountCurrencyList) {
        const currList = accountCurrencyList.map(list => ({
          key: list.base.id,
          value: list.currencyId,
          text: list.symbol,
        }))
        setcurrencyListData(currList)
      }
      if (accountCurrencyList) {
        const Bcur = accountCurrencyList.find(list => list.isBase && list)
        setValue('currencyId', Bcur?.currencyId)
      }
    },
  })

  const submitForm = data => {
    if (watchRangePeriod === 'date') {
      delete data.rangeYear
    } else if (watchRangePeriod === 'year' || watchRangeBreakdown === 'year') {
      delete data.startMonth
      delete data.startYear
      delete data.endMonth
      delete data.endYear
    }
    const newList = _.omitBy(data, value => !!(value === '' || value === null || _.isEqual(value, [])))

    if (editId) {
      return dispatch(updateBudget(editId, newList))
    }

    dispatch(createBudget(newList))
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <div>
          <KooperButton primary onClick={() => history.push(`/${BUDGETS_PLANNER_PATH.PREFIX}`)}>
            Exit
          </KooperButton>
        </div>
        <h3 className="m-0">Create Budget Planner</h3>
        <LockPermissionTooltip isRoleAccessDenied={!manageBudgetPlannerPermissions}>
          <KooperButton
            className={!manageBudgetPlannerPermissions ? 'disabled-button' : ''}
            primary
            {...(manageBudgetPlannerPermissions && {
              onClick: handleSubmit(submitForm),
            })}
            disabled={!isDirty}
          >
            Save
          </KooperButton>
        </LockPermissionTooltip>
      </div>

      <KooperContainer style={{width: '90%'}}>
        <KooperCard fluid>
          <KooperCardContent style={{padding: '18px'}}>
            <h3 className="mb-0">Create Budget Planner</h3>
            <p className="mt-0 mb-4 card-description">
              Create budget planner by mentioning all the required information
            </p>

            <KooperForm className="errorLabel">
              <KooperGrid>
                <KooperGridRow>
                  <KooperGridColumn width="8">
                    <KooperFormField width="8" required>
                      <div className="info-header">
                        <label>Budget Title</label>
                        <KooperTooltip
                          content="Specify title of the budget planner"
                          size="mini"
                          position="top center"
                          trigger={<SvgIcon path="common/question" />}
                        />
                      </div>
                      <Controller
                        name="name"
                        control={control}
                        render={({onChange, value}) => (
                          <KooperFormInput
                            maxLength={20}
                            type="text"
                            placeholder="Budget Title"
                            onChange={(e, {value}) => onChange(value)}
                            value={value}
                            error={
                              errors.name && {
                                content: removeDoubleQuotes(errors.name.message),
                              }
                            }
                          />
                        )}
                      />
                    </KooperFormField>

                    <KooperFormField required>
                      <div className="info-header">
                        <label>Compare with</label>
                        <KooperTooltip
                          content="Select input (e.g invoice) for comparing with budget in real time"
                          size="mini"
                          position="top center"
                          trigger={<SvgIcon path="common/question" />}
                        />
                      </div>
                      <div className="d-flex" style={{justifyContent: 'flex-start'}}>
                        <Controller
                          name="compareWith"
                          render={({onChange, value, onBlur}) => (
                            <KooperDropdown
                              style={{width: 180}}
                              fluid
                              className="mr-3"
                              options={compareList}
                              selection
                              onBlur={onBlur}
                              value={value}
                              onChange={(e, {value}) => {
                                onChange(value)
                              }}
                              error={
                                errors?.compareWith && {
                                  content: removeDoubleQuotes(errors?.compareWith?.message),
                                }
                              }
                            />
                          )}
                          control={control}
                        />

                        <Controller
                          name="compareType"
                          render={({onChange, value, onBlur}) => (
                            <KooperDropdown
                              style={{width: 180}}
                              fluid
                              options={[{key: 'issued', value: 'issued', text: 'issued'}]}
                              selection
                              onBlur={onBlur}
                              value={value}
                              onChange={(e, {value}) => {
                                onChange(value)
                              }}
                              error={
                                errors?.compareType && {
                                  content: removeDoubleQuotes(errors?.compareType?.message),
                                }
                              }
                            />
                          )}
                          control={control}
                        />
                      </div>
                      {errors.compareWith && (
                        <p className="errorLabelElement">{removeDoubleQuotes(errors?.compareWith?.message)}</p>
                      )}
                    </KooperFormField>

                    <KooperFormField>
                      <div className="info-header">
                        <label>Currency</label>
                        <KooperTooltip
                          content="Set a currency for the budget planner"
                          size="mini"
                          position="top center"
                          trigger={<SvgIcon path="common/question" />}
                        />
                      </div>
                      <Controller
                        name="currencyId"
                        control={control}
                        render={({value, onChange}) => (
                          <KooperFormSelect
                            style={{width: 180}}
                            selection
                            className="mr-3 mb-2"
                            fluid
                            search
                            placeholder="Select Currency"
                            options={currencyListData}
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            error={
                              errors.currencyId && {
                                content: errors.currencyId.message,
                              }
                            }
                          />
                        )}
                      />
                      <p className="kooper-lead mb-0">Sums in other currencies are converted into INR</p>
                    </KooperFormField>
                  </KooperGridColumn>

                  <KooperGridColumn width="8">
                    <KooperFormField required>
                      <div className="info-header">
                        <label>Period</label>
                        <KooperTooltip
                          content="Choose time period for the budget"
                          size="mini"
                          position="top center"
                          trigger={<SvgIcon path="common/question" />}
                        />
                      </div>
                      <Controller
                        name="rangePeriod"
                        control={control}
                        render={({value, onChange}) => (
                          <>
                            <KooperFormRadio
                              className="mb-2"
                              value="year"
                              checked={value === 'year'}
                              onChange={(e, {value}) => onChange(value)}
                              label="Year"
                            />
                            {watchRangePeriod === 'year' && (
                              <Controller
                                name="rangeYear"
                                control={control}
                                render={({value, onChange}) => (
                                  <KooperInput
                                    style={{width: 80, height: 30}}
                                    className="mb-2"
                                    value={value}
                                    onChange={(e, {value}) => onChange(value)}
                                  />
                                )}
                              />
                            )}
                            <KooperFormRadio
                              value="date"
                              checked={value === 'date'}
                              onChange={(e, {value}) => onChange(value)}
                              label="Date Range"
                            />
                            {watchRangePeriod === 'date' && (
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <KooperDatePicker
                                  style={{width: 160, height: 50}}
                                  className="mt-0"
                                  format="MM/yyyy"
                                  value={startDate}
                                  onChange={value => {
                                    setStartDate(value)
                                  }}
                                  time={false}
                                />
                                <p className="mx-3">- to -</p>
                                <KooperDatePicker
                                  style={{width: 160, height: 50}}
                                  className="mt-0"
                                  format="MM/yyyy"
                                  min={new Date(startDate)}
                                  value={endDate}
                                  time={false}
                                  onChange={value => {
                                    setEndDate(value)
                                  }}
                                />
                              </div>
                            )}
                          </>
                        )}
                      />
                    </KooperFormField>
                    <KooperFormField required>
                      <div className="info-header">
                        <label>Period Split</label>
                        <KooperTooltip
                          content="Select how budget is split into smaller time period"
                          size="mini"
                          position="top center"
                          trigger={<SvgIcon path="common/question" />}
                        />
                      </div>
                      <Controller
                        name="rangeBreakdown"
                        control={control}
                        render={({value, onChange}) => (
                          <KooperFormSelect
                            style={{width: 180}}
                            className="mr-3"
                            fluid
                            search
                            placeholder="Range Breakdown"
                            options={RangeBreakdown}
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            error={
                              errors.rangeBreakdown && {
                                content: errors.rangeBreakdown.message,
                              }
                            }
                          />
                        )}
                      />
                    </KooperFormField>
                  </KooperGridColumn>
                </KooperGridRow>
              </KooperGrid>
            </KooperForm>
          </KooperCardContent>
        </KooperCard>

        <KooperCard fluid>
          <KooperCardContent style={{padding: '18px'}}>
            <h3 className="mb-0">Budgets Settings</h3>
            <p className="mt-0 mb-4 card-description">Manage all settings related to budgets</p>
            <KooperForm className="errorLabel">
              <KooperFormField>
                <div className="info-header">
                  <label>Break Down By</label>
                  <KooperTooltip
                    content="Select what the budget is based on"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="rangeBreakdownBy"
                  render={({onChange, value, onBlur}) => (
                    <KooperDropdown
                      style={{width: 180}}
                      fluid
                      options={[
                        {key: 'user', value: 'user', text: 'Users'},
                        {key: 'contact', value: 'contact', text: 'Contact'},
                        {key: 'project', value: 'project', text: 'Project'},
                      ]}
                      selection
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.rangeBreakdownBy && {
                          content: removeDoubleQuotes(errors?.rangeBreakdownBy?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
              <KooperFormField>
                <div className="info-header">
                  <label>Users</label>
                  <KooperTooltip
                    content="Select users for a more detailed budget. Unselected users will be added to the “Other” row."
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name={
                    watchRangeBreakdownBy === 'user'
                      ? 'userId'
                      : watchRangeBreakdownBy === 'contact'
                      ? 'contactId'
                      : watchRangeBreakdownBy === 'project'
                      ? 'projectId'
                      : 'userId'
                  }
                  render={({onChange, value, onBlur}) => (
                    <KooperDropdown
                      style={{width: 180}}
                      fluid
                      options={usersList}
                      selection
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                    />
                  )}
                  control={control}
                />
              </KooperFormField>

              <KooperFormField>
                <KooperTable sortable basic selectable>
                  {watchRangePeriod === 'date' && watchRangeBreakdown === 'year' && (
                    <>
                      <KooperTableHeader>
                        <KooperTableRow>
                          <KooperTableCell>#</KooperTableCell>
                          <KooperTableHeaderCell>Users</KooperTableHeaderCell>
                          {endDate.getFullYear() - startDate.getFullYear() >= 0 &&
                            [...Array(endDate.getFullYear() - startDate.getFullYear() + 1)].map((e, i) => (
                              <KooperTableHeaderCell key={i}>{startDate.getFullYear() + i}</KooperTableHeaderCell>
                            ))}
                          <KooperTableHeaderCell>Total</KooperTableHeaderCell>
                        </KooperTableRow>
                      </KooperTableHeader>
                      <KooperTableBody>
                        <KooperTableRow className="tableLink">
                          <KooperTableCell>
                            <KooperCheckbox checked disabled />
                          </KooperTableCell>
                          <KooperTableCell>
                            {watchRangeBreakdownBy === 'user'
                              ? userText?.[0]?.text
                              : watchRangeBreakdownBy === 'contact'
                              ? contactText?.[0]?.text
                              : watchRangeBreakdownBy === 'project'
                              ? projectText?.[0]?.text
                              : 'Total'}
                          </KooperTableCell>
                          {fields.map(({value}, i) => {
                            return (
                              <KooperTableCell>
                                <Controller
                                  name={`ranges[${i}].value`}
                                  control={control}
                                  defaultValue={value}
                                  render={({onChange, value}) => (
                                    <KooperFormInput
                                      type="text"
                                      style={{width: 80}}
                                      onChange={(e, {value}) => {
                                        onChange(value)
                                      }}
                                      value={value}
                                      error={
                                        errors?.ranges?.[i]?.value && {
                                          content: removeDoubleQuotes(errors?.ranges?.[i]?.value?.message),
                                        }
                                      }
                                    />
                                  )}
                                />
                              </KooperTableCell>
                            )
                          })}
                          <KooperTableCell>{sumTotal}</KooperTableCell>
                        </KooperTableRow>
                      </KooperTableBody>
                    </>
                  )}

                  {watchRangeBreakdown === 'month' && (
                    <>
                      <KooperTableHeader>
                        <KooperTableRow>
                          <KooperTableHeaderCell>#</KooperTableHeaderCell>
                          <KooperTableHeaderCell>Users</KooperTableHeaderCell>
                          {watchRangePeriod === 'date'
                            ? headerCellMonth.map(month => <KooperTableHeaderCell>{month}</KooperTableHeaderCell>)
                            : watchRangePeriod === 'year'
                            ? months.map(month => <KooperTableHeaderCell>{month}</KooperTableHeaderCell>)
                            : null}
                          <KooperTableHeaderCell>Total</KooperTableHeaderCell>
                        </KooperTableRow>
                      </KooperTableHeader>
                      <KooperTableBody>
                        <KooperTableRow className="tableLink">
                          <KooperTableCell>
                            <KooperCheckbox checked disabled />
                          </KooperTableCell>
                          <KooperTableCell>
                            {watchRangeBreakdownBy === 'user'
                              ? userText?.[0]?.text
                              : watchRangeBreakdownBy === 'contact'
                              ? contactText?.[0]?.text
                              : watchRangeBreakdownBy === 'project'
                              ? projectText?.[0]?.text
                              : 'Total'}
                          </KooperTableCell>
                          {fields.map(({value}, i) => {
                            return (
                              <KooperTableCell>
                                <Controller
                                  name={`ranges[${i}].value`}
                                  control={control}
                                  defaultValue={value}
                                  render={({onChange, value}) => (
                                    <KooperFormInput
                                      type="text"
                                      style={{width: 80}}
                                      onChange={(e, {value}) => {
                                        onChange(value)
                                      }}
                                      value={value}
                                      error={
                                        errors?.ranges?.[i]?.value && {
                                          content: removeDoubleQuotes(errors?.ranges?.[i]?.value?.message),
                                        }
                                      }
                                    />
                                  )}
                                />
                              </KooperTableCell>
                            )
                          })}
                          <KooperTableCell>{sumTotal}</KooperTableCell>
                        </KooperTableRow>
                      </KooperTableBody>
                    </>
                  )}

                  {watchRangePeriod === 'year' && watchRangeBreakdown === 'year' && (
                    <>
                      <KooperTableHeader>
                        <KooperTableRow>
                          <KooperTableHeaderCell>#</KooperTableHeaderCell>
                          <KooperTableHeaderCell>Users</KooperTableHeaderCell>
                          <KooperTableHeaderCell>{watchRangeYear}</KooperTableHeaderCell>
                          <KooperTableHeaderCell>Total</KooperTableHeaderCell>
                        </KooperTableRow>
                      </KooperTableHeader>
                      <KooperTableBody>
                        <KooperTableRow className="tableLink">
                          <KooperTableCell>
                            <KooperCheckbox checked disabled />
                          </KooperTableCell>
                          <KooperTableCell>
                            {watchRangeBreakdownBy === 'user'
                              ? userText?.[0]?.text
                              : watchRangeBreakdownBy === 'contact'
                              ? contactText?.[0]?.text
                              : watchRangeBreakdownBy === 'project'
                              ? projectText?.[0]?.text
                              : 'Total'}
                          </KooperTableCell>
                          {fields.map(({value}, i) => {
                            return (
                              <KooperTableCell>
                                <Controller
                                  name={`ranges[${i}].value`}
                                  control={control}
                                  defaultValue={value}
                                  render={({onChange, value}) => (
                                    <KooperFormInput
                                      type="text"
                                      style={{width: 80}}
                                      onChange={(e, {value}) => {
                                        onChange(value)
                                      }}
                                      value={value}
                                      error={
                                        errors?.ranges?.[i]?.value && {
                                          content: removeDoubleQuotes(errors?.ranges?.[i]?.value?.message),
                                        }
                                      }
                                    />
                                  )}
                                />
                              </KooperTableCell>
                            )
                          })}
                          <KooperTableCell>{sumTotal}</KooperTableCell>
                        </KooperTableRow>
                      </KooperTableBody>
                    </>
                  )}

                  {watchRangeBreakdown === 'quarter' && (
                    <>
                      <KooperTableHeader>
                        <KooperTableRow>
                          <KooperTableHeaderCell>#</KooperTableHeaderCell>
                          <KooperTableHeaderCell>Users</KooperTableHeaderCell>
                          <KooperTableHeaderCell>Quarter I</KooperTableHeaderCell>
                          <KooperTableHeaderCell>Quarter II</KooperTableHeaderCell>
                          <KooperTableHeaderCell>Quarter III</KooperTableHeaderCell>
                          <KooperTableHeaderCell>Quarter IV</KooperTableHeaderCell>
                          <KooperTableHeaderCell>Total</KooperTableHeaderCell>
                        </KooperTableRow>
                      </KooperTableHeader>
                      <KooperTableBody>
                        <KooperTableRow className="tableLink">
                          <KooperTableCell>
                            <KooperCheckbox checked disabled />
                          </KooperTableCell>
                          <KooperTableCell>
                            {watchRangeBreakdownBy === 'user'
                              ? userText?.[0]?.text
                              : watchRangeBreakdownBy === 'contact'
                              ? contactText?.[0]?.text
                              : watchRangeBreakdownBy === 'project'
                              ? projectText?.[0]?.text
                              : 'Total'}
                          </KooperTableCell>
                          {fields.map(({value}, i) => {
                            return (
                              <KooperTableCell>
                                <Controller
                                  name={`ranges[${i}].value`}
                                  control={control}
                                  defaultValue={value}
                                  render={({onChange, value}) => (
                                    <KooperFormInput
                                      type="text"
                                      style={{width: 80}}
                                      onChange={(e, {value}) => {
                                        onChange(value)
                                      }}
                                      value={value}
                                      error={
                                        errors?.ranges?.[i]?.value && {
                                          content: removeDoubleQuotes(errors?.ranges?.[i]?.value?.message),
                                        }
                                      }
                                    />
                                  )}
                                />
                              </KooperTableCell>
                            )
                          })}
                          <KooperTableCell>{sumTotal}</KooperTableCell>
                        </KooperTableRow>
                      </KooperTableBody>
                    </>
                  )}
                </KooperTable>
              </KooperFormField>
            </KooperForm>
          </KooperCardContent>
        </KooperCard>
      </KooperContainer>
    </div>
  )
}

export default CreateBudgetPlanner
