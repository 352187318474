import React, {useEffect} from 'react'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperContainer,
  KooperDropdown,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormTextArea,
  KooperInput,
  KooperList,
  KooperListContent,
  KooperListDescription,
  KooperListHeader,
  KooperListItem,
  KooperRadio,
  KooperTabPane,
  KooperTooltip,
} from 'kooper-library'
import {startCase} from 'utils/helper'
import {formFieldValidation} from 'constants/sales/meeting'
import SvgIcon from 'components/common/SvgIcon'

const FormQuestion = props => {
  const {errors, control, Controller, watch, setValue, formFields, removeMeetingForm, appendMeetingForm, style} = props

  const watchPrePopulate = watch('formFieldWithKnownValue')
  const watchtermsAndCondition = watch('termsAndCondition')

  useEffect(() => {
    if (!watchPrePopulate) {
      setValue('submitFormIfAllKnownValue', false)
    }
  }, [watchPrePopulate, setValue])

  return (
    <KooperTabPane style={style}>
      <KooperContainer>
        <KooperCard fluid>
          <KooperCardContent>
            <h3 className="mb-0">Form Question</h3>
            <p className="mt-0 mb-4 kooper-lead">
              Manage your form questions which will be asked to the user booking the meeting
            </p>
            <KooperForm className="mt-4 errorLabel">
              <KooperFormField width={10}>
                <div className="info-header">
                  <label>Form Questions</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Ask prospects and customer a few qualifying questions as they are booking meeting with you."
                    size="mini"
                    position="top center"
                  />
                </div>
                {formFields?.map((item, index) => {
                  return (
                    <KooperFormGroup inline key={item.id}>
                      <KooperFormField width={6}>
                        <Controller
                          control={control}
                          name={`meetingFormFields[${index}].field`}
                          defaultValue={item.field}
                          render={({onChange, value}) => (
                            <KooperInput
                              readOnly={!(index > 2)}
                              defaultValue={startCase(value)}
                              placeholder={startCase(value)}
                              onChange={(e, {value}) => onChange(value)}
                            />
                          )}
                        />
                      </KooperFormField>

                      <KooperFormField width={5}>
                        <Controller
                          control={control}
                          name={`meetingFormFields[${index}].vaildation`}
                          defaultValue={item.vaildation}
                          render={({onChange, value}) => (
                            <KooperDropdown
                              selection
                              options={formFieldValidation}
                              value={value}
                              onChange={(e, {value}) => onChange(value)}
                            />
                          )}
                        />
                      </KooperFormField>
                      <KooperFormField width={3} Required>
                        <Controller
                          control={control}
                          name={`meetingFormFields[${index}].require`}
                          defaultValue={item.require}
                          render={({onChange, value}) => (
                            <KooperCheckbox
                              checked={value}
                              onChange={(e, {checked}) => onChange(checked)}
                              disabled={!(index > 2)}
                              label="Required"
                            />
                          )}
                        />
                      </KooperFormField>
                      {index > 2 && (
                        <KooperTooltip
                          size="mini"
                          position="top center"
                          content="Delete"
                          trigger={
                            <KooperButton icon onClick={() => removeMeetingForm(index)}>
                              <SvgIcon path="common/delete" />
                            </KooperButton>
                          }
                        />
                      )}
                    </KooperFormGroup>
                  )
                })}
                <KooperFormField>
                  <KooperButton
                    basic
                    onClick={() => {
                      appendMeetingForm({
                        field: 'Note',
                        require: false,
                        vaildation: 'text',
                      })
                    }}
                  >
                    Add Field
                  </KooperButton>
                </KooperFormField>
              </KooperFormField>
            </KooperForm>
          </KooperCardContent>
        </KooperCard>
        <KooperCard fluid>
          <KooperCardContent>
            <h3 className="mb-0">Pre-populate Fields</h3>
            <p className="mt-0 mb-4 kooper-lead">Manage your pre populate fields settings for user</p>
            <KooperForm className="mt-4 errorLabel">
              <KooperFormField width={12}>
                <KooperCard fluid>
                  <KooperCardContent>
                    <KooperList relaxed="very" divided>
                      <KooperListItem>
                        <KooperListContent>
                          <Controller
                            name="formFieldWithKnownValue"
                            render={({onChange, value}) => (
                              <KooperRadio
                                toggle
                                style={{float: 'right'}}
                                onChange={(e, {checked}) => onChange(checked)}
                                checked={value}
                              />
                            )}
                            control={control}
                          />
                          <KooperListHeader style={{marginBottom: '4px'}}>
                            Pre-populate fields with known values
                          </KooperListHeader>
                          <KooperListDescription>
                            Control whether or not forms pre-populate with know information when a contact returns to
                            your site.
                          </KooperListDescription>
                        </KooperListContent>
                      </KooperListItem>
                    </KooperList>
                  </KooperCardContent>
                  <KooperCardContent>
                    <KooperList relaxed="very" divided>
                      <KooperListItem>
                        <KooperListContent>
                          <Controller
                            name="submitFormIfAllKnownValue"
                            render={({onChange, value}) => (
                              <KooperRadio
                                toggle
                                disabled={!watchPrePopulate}
                                style={{float: 'right'}}
                                onChange={(e, {checked}) => onChange(checked)}
                                checked={value}
                              />
                            )}
                            control={control}
                          />
                          <KooperListHeader style={{marginBottom: '4px'}}>
                            Auto-submit form when all fields are pre-populate
                          </KooperListHeader>
                          <KooperListDescription>
                            Control whether or not your contact will see this form when all fields are pre-populate
                          </KooperListDescription>
                        </KooperListContent>
                      </KooperListItem>
                    </KooperList>
                  </KooperCardContent>
                  <KooperCardContent>
                    <KooperList relaxed="very" divided>
                      <KooperListItem>
                        <KooperListContent>
                          <Controller
                            name="termsAndCondition"
                            render={({onChange, value}) => (
                              <KooperRadio
                                toggle
                                style={{float: 'right'}}
                                onChange={(e, {checked}) => onChange(checked)}
                                checked={value}
                              />
                            )}
                            control={control}
                          />
                          <KooperListHeader style={{marginBottom: '4px'}}>Privacy and consent (GDRP)</KooperListHeader>
                          <KooperListDescription>
                            Add consent checkbox and text to your from to follow privacy laws and regulations
                            {watchtermsAndCondition && (
                              <KooperForm className="mt-4 errorLabel">
                                <KooperFormField>
                                  <div className="info-header">
                                    <label>Privacy Title</label>
                                    <KooperTooltip
                                      content="Lorem ipsum"
                                      size="mini"
                                      position="top center"
                                      trigger={<SvgIcon path="common/question" />}
                                    />
                                  </div>
                                  <Controller
                                    name="privacyTitle"
                                    render={({onChange, value}) => (
                                      <KooperFormInput
                                        fluid
                                        value={value}
                                        placeholder="Privacy Title"
                                        onChange={e => {
                                          onChange(e.target.value)
                                        }}
                                        error={
                                          errors.privacyTitle && {
                                            content: errors.privacyTitle.message,
                                          }
                                        }
                                      />
                                    )}
                                    control={control}
                                  />
                                </KooperFormField>
                                <KooperFormField>
                                  <div className="info-header">
                                    <label>Privacy Text</label>
                                    <KooperTooltip
                                      content="Lorem ipsum"
                                      size="mini"
                                      position="top center"
                                      trigger={<SvgIcon path="common/question" />}
                                    />
                                  </div>
                                  <Controller
                                    name="privacyText"
                                    render={({onChange, value}) => (
                                      <KooperFormTextArea
                                        maxlength="300"
                                        placeholder="Privacy Text"
                                        value={value}
                                        onChange={(e, {value}) => onChange(value)}
                                      />
                                      // <KooperEditorSmall
                                      //   setContents={value}
                                      //   onChange={(content) => {
                                      //     onChange(content);
                                      //   }}
                                      //   width="100%"
                                      //   setOptions={{
                                      //     // defaultStyle: `font-size: ${inboxFontSize}px; font-family: ${inboxFont};`,
                                      //     buttonList: [
                                      //       [
                                      //         'bold',
                                      //         'underline',
                                      //         'italic',
                                      //         'fontColor',
                                      //         'hiliteColor',
                                      //         'indent',
                                      //       ],
                                      //     ],
                                      //   }}
                                      // />
                                    )}
                                    control={control}
                                  />
                                </KooperFormField>
                                <KooperFormField required>
                                  <div className="info-header">
                                    <label>Terms & Condition checkbox text</label>
                                    <KooperTooltip
                                      content="Lorem ipsum"
                                      size="mini"
                                      position="top center"
                                      trigger={<SvgIcon path="common/question" />}
                                    />
                                  </div>
                                  <KooperFormGroup inline>
                                    <KooperFormField width={16}>
                                      <Controller
                                        name="privacyCheck"
                                        render={({onChange, value}) => (
                                          <KooperFormInput
                                            fluid
                                            value={value}
                                            width="16"
                                            placeholder="Privacy Check"
                                            onChange={e => {
                                              onChange(e.target.value)
                                            }}
                                            error={
                                              errors.privacyCheck && {
                                                content: errors.privacyCheck.message,
                                              }
                                            }
                                          />
                                        )}
                                        control={control}
                                      />
                                    </KooperFormField>
                                  </KooperFormGroup>
                                </KooperFormField>
                              </KooperForm>
                            )}
                          </KooperListDescription>
                        </KooperListContent>
                      </KooperListItem>
                    </KooperList>
                  </KooperCardContent>
                </KooperCard>
              </KooperFormField>
            </KooperForm>
          </KooperCardContent>
        </KooperCard>
      </KooperContainer>
    </KooperTabPane>
  )
}

export default FormQuestion
