/* eslint-disable no-prototype-builtins */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  KooperModal,
  KooperModalHeader,
  KooperModalContent,
  KooperForm,
  KooperFormField,
  KooperModalActions,
  KooperButton,
  KooperInput,
  KooperDropdown,
  KooperDatePicker,
  KooperRadio,
  KooperChipInput,
  KooperFormInput,
  KooperFormSelect,
  KooperFormTextArea,
  KooperTooltip,
  KooperFormGroup,
  KooperGrid,
  KooperGridColumn,
  KooperSearch,
  KooperTable,
  KooperTableRow,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableBody,
  KooperTableCell,
} from 'kooper-library'

import {
  ADD_DEAL,
  GET_ACCOUNT_CURRENCY,
  GET_USERS,
  CONTACT_ALL_COMPANIES,
  GET_ALL_CONTACTS,
  GET_SOURCE_DEALS,
  GET_ALL_TAG_LABEL,
} from 'actions/types'

import {getAccountCurrency} from 'actions/setting_currency'
import {addDeal, getSourceDeals} from 'actions/deals'
import {getProductlist} from 'actions/productLibrary'
import {createTagSettings, getTagLabel} from 'actions/multiple_fields'
import {get, startCase} from 'utils/helper'
import {priorityList} from 'constants/variables'
import KooperCustomDropdown from 'components/common/KooperCustomDropdown'
import CreateLabel from 'components/common/CreateLabel'
import {createDealSchema} from 'validation/Sales/deals.schema'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getContactCompanyList} from 'actions/contacts'
import {billingFreqOptions} from '../Products/ProductsModal'

const testIsNumber = value => /^\d{0,8}(\.\d{1,2})?$/g.test(value)
const testIsPercentage = value => /^[0-9][0-9]?(\.\d+)?$|^100$/.test(value)

/**
 *
 * @param {number} totalPrice - multiplication of quantity and price
 * @param {'%' | 'USD'} discountType
 * @param {number} discountValue 0-100 if disType is % otherwise valid number
 */

const getUnitTotal = (totalPrice, discountType, discountValue) => {
  if (!testIsNumber(totalPrice)) return 0

  if (discountType === '%') {
    if (!testIsPercentage(discountValue)) return 0

    return Number((totalPrice * ((100 - discountValue) / 100)).toFixed(2))
  }

  if (discountType === 'USD') {
    if (!testIsNumber(discountValue)) return 0

    return Number((totalPrice - discountValue).toFixed(2))
  }
}

const typeOptions = [
  {key: '%', value: '%', text: '%'},
  {
    key: 'USD',
    value: 'USD',
    text: 'USD',
  },
]

const CreateDealModal = props => {
  const dispatch = useDispatch()

  const {isModalOpen, setIsModalOpen, pipelineId} = props

  const [createInfo, setCreateInfo] = useState({})
  const [assigneeData, setAssigneeData] = useState([])
  const [stageListData, setStageListData] = useState([])
  const [currencyListData, setcurrencyListData] = useState([])
  const [sourceListData, setSourceListData] = useState([])
  const [labelOptionsState, setLabelOptionsState] = useState([])
  const [newLabelModal, setNewLabelModal] = useState(false)
  const [listOfLable, setListOfLabel] = useState([])
  const [contactError, setContactError] = useState(false)
  const [primaryContactData, setPrimaryContactData] = useState([])
  const [contactCompanyList, setContactCompanyList] = useState([])
  const [allDataList, setAllDataList] = useState([])
  const [primaryModal, setPrimaryModal] = useState(false)
  const [radioprimary, setRadioprimary] = useState(false)
  const [primaryValue, setPrimaryValue] = useState({})
  const [contactsCompany, setContactsCompany] = useState([])
  const [searchBoxOptions, setSearchBoxOptions] = useState([])
  const [optionsToShow, setOptionsToShow] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchValue, setSearchValue] = useState([])
  const [closeDate, setCloseDate] = useState(null)

  const {getStageDeals: getStageDealsList = []} = useSelector(state => state.deals)
  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  const inital = {
    name: '',
    assigneeId: null,
    monetaryValue: null,
    currencyId: null,
    stageId: null,
    sourceId: null,
    priority: 'default',
    win: null,
    description: '',
    closeDate: null,
    labelIds: [],
  }
  const {
    handleSubmit,
    errors,
    control,
    reset,
    setValue,
    clearErrors,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(createDealSchema),
    defaultValues: inital,
  })

  useApiResponse({
    label: GET_ACCOUNT_CURRENCY,
    enabled: true,
    storePath: 'settingCurrency.accountCurrencyList',
    action: getAccountCurrency,
    onSuccess: accountCurrencyList => {
      if (accountCurrencyList) {
        const currList = accountCurrencyList.map(list => ({
          key: list.base.id,
          value: list.currencyId,
          text: list.symbol,
        }))
        setcurrencyListData(currList)
      }
      if (accountCurrencyList) {
        const Bcur = accountCurrencyList.find(list => list.isBase && list)
        setValue('currencyId', Bcur?.currencyId)
      }
    },
  })

  useApiResponse({
    action: getContactCompanyList,
    enabled: true,
    label: CONTACT_ALL_COMPANIES,
    storePath: 'deals.getAllContactCompanyList',
    onSuccess: getAllContactCompanyList => {
      const companyList = getAllContactCompanyList.map(list => ({
        key: list.name,
        value: list.id,
        text: list.name,
      }))
      setContactCompanyList(companyList)
    },
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setAssigneeData(list)
    },
  })

  useApiResponse({
    label: GET_ALL_CONTACTS,
    storePath: 'deals.getAllContactsList',
    onSuccess: getAllContactsList => {
      const contactData = getAllContactsList.map(list => ({
        key: `contact${list.id}`,
        text: `${`${get(['firstName'], list, '')} ${get(['lastName'], list, '')}`.trim() || `#${list.id}`} (Contact)`,
        value: `contactId${list.id}`,
      }))
      setPrimaryContactData([...primaryContactData, ...contactData])
      setAllDataList([...allDataList, ...getAllContactsList])

      const contactlist = getAllContactsList?.filter(data => {
        return data?.id !== null
      })

      const contactListData = contactlist?.map(({id, company}) => ({
        key: `contact${id}`,
        text: company?.name,
        value: `contactId${company?.name}`,
      }))
      setContactsCompany(contactListData)
    },
  })

  useApiResponse({
    action: getSourceDeals,
    enabled: true,
    label: GET_SOURCE_DEALS,
    storePath: 'deals.getSourceDeals',
    onSuccess: getSourceDealsList => {
      const sourceList = getSourceDealsList.map(list => ({
        key: list.id,
        value: list.id,
        text: list.source,
      }))
      setSourceListData(sourceList)
    },
  })

  useEffect(() => {
    if (getStageDealsList) {
      const stageList = getStageDealsList.map(list => ({
        key: list.id,
        value: list.id,
        text: startCase(list.stage),
      }))
      setStageListData(stageList)
    }
  }, [getStageDealsList])

  useApiResponse({
    action: getTagLabel,
    enabled: true,
    label: GET_ALL_TAG_LABEL,
    storePath: 'multipleFields.getTagList',
    onSuccess: getTagList => {
      const list = getTagList.map(user => ({
        value: user.id,
        text: user.label,
        key: user.id,
        label: {
          style: {background: user.color},
          empty: true,
          circular: true,
        },
      }))
      setLabelOptionsState(list)
    },
  })

  useEffect(() => {
    delete createInfo.contactId
    delete createInfo.contact
    delete createInfo.companyId
    delete createInfo.company
    setCreateInfo({...createInfo, ...primaryValue})
  }, [primaryValue])

  useEffect(() => {
    if (successLabels.includes(ADD_DEAL)) {
      setCreateInfo({})
      setPrimaryValue({})
      setCloseDate(null)
      return () => {
        reset(inital)
      }
    }
  }, [successLabels])

  const createDealBtn = data => {
    data.products = searchValue

    if (closeDate) {
      data.closeDate = new Date(closeDate).toISOString()
    }

    data.products.map(val => {
      val.unitTotal = getUnitTotal(val.quantity * val.unitPrice, val.discountType, val.discountValue)
      delete val.description
      delete val.title
      delete val.price
    })
    const newList = _.omitBy(data, value => !!(value === '' || value === null || _.isEqual(value, [])))

    const createInfoNull = _.omitBy(createInfo, _.isNull)
    delete createInfoNull.type
    if (createInfoNull.hasOwnProperty('contactId')) {
      delete createInfoNull.companyId
    }
    const combineObj = {...createInfoNull, ...newList, pipelineId}
    if (primaryValue.type) {
      setContactError(false)
      dispatch(addDeal(combineObj))
    } else {
      setContactError(true)
    }
  }

  const primaryContactFunction = value => {
    if (_.includes(value, 'contactId')) {
      const contact = value.substring(9)
      const tempC = allDataList.filter(list => {
        if (list.id === contact) {
          return list
        }
      })
      setPrimaryValue({
        contactId: contact,
        companyId: _.get(tempC[0], 'company.name', '--'),
        type: value,
      })
    }
  }

  const createNewLabel = () => {
    if (newLabelModal) {
      return (
        <CreateLabel
          isModalOpen={newLabelModal}
          setIsModalOpen={setNewLabelModal}
          createMethod={data => dispatch(createTagSettings(data))}
        />
      )
    }
  }

  const isModalPrimary = () => (
    <KooperModal
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={primaryModal}
      onClose={() => setPrimaryModal(!primaryModal)}
    >
      <KooperModalHeader>Add a New Primary Contact</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperForm>
          <KooperFormField>
            <KooperRadio
              label="Create as Contact"
              name="radioGroup"
              checked={radioprimary === true}
              onChange={() => {
                setRadioprimary(!radioprimary)
              }}
            />
            {radioprimary && (
              <KooperInput
                onChange={(e, {value}) => {
                  setPrimaryValue({contact: value, type: value})
                }}
              />
            )}
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setPrimaryModal(false)}>
          Cancel
        </KooperButton>
        <KooperButton
          color="blue"
          onClick={() => {
            setPrimaryContactData([
              ...primaryContactData,
              {
                key: primaryValue.type,
                text: primaryValue.type,
                value: primaryValue.type,
              },
            ])
            setPrimaryModal(false)
          }}
        >
          Add
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )

  const {productList = []} = useSelector(state => state.productLibrary)

  useEffect(() => {
    if (!productList?.length) dispatch(getProductlist())
    // return () => {
    //   buttonRef.current.disabled = false;
    // };
  }, [])

  // * set searchbox options for dropdown
  useEffect(() => {
    if (productList.length) {
      const options = productList.map(item => {
        return {
          productId: item.id,
          title: item.name,
          description: item.description,
          price: item.sku,
          quantity: 1,
          billingFreq: item.billingFreq,
          unitPrice: Number(item.unitPrice),
          discountType: '%',
          discountValue: 0,
        }
      })
      setSearchBoxOptions(options)
    }
  }, [productList])

  const handleResultSelect = (e, {result}) => {
    setSearchValue(searchValue => {
      if (searchValue.some(sv => sv.productId === result.productId)) {
        return searchValue
      }
      return [...searchValue, result]
    })
    setSearchText('')
    setOptionsToShow([])
  }

  const handleSearchChange = (e, {value}) => {
    setSearchText(value)
    setTimeout(() => {
      if (value.length < 1) {
        setOptionsToShow(searchBoxOptions)
        setSearchText('')
        setSearchValue([])
        return
      }
      const re = new RegExp(_.escapeRegExp(searchText), 'i')
      const isMatch = result => re.test(result.title)
      setOptionsToShow(_.filter(searchBoxOptions, isMatch))
    }, 300)
  }

  const changeSearchValue = (index, value, property) => {
    setSearchValue(searchValue => {
      const tempSearchValue = [...searchValue]
      const newValue = {
        ...tempSearchValue[index],
        [property]: value,
      }
      tempSearchValue.splice(index, 1, newValue)
      return tempSearchValue
    })
  }

  return (
    <KooperModal
      style={{width: '740px'}}
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isModalOpen}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <KooperModalHeader>Add a New Deal</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperGrid>
          <KooperGridColumn width={16}>
            <KooperForm className="errorLabel" id="user-form">
              <KooperFormGroup widths="equal">
                <KooperFormField required>
                  <label>Name</label>
                  <Controller
                    control={control}
                    name="name"
                    render={({onChange, value}) => (
                      <KooperFormInput
                        type="text"
                        maxLength={20}
                        fluid
                        placeholder="Name"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        error={
                          errors.name && {
                            content: errors.name.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
                <KooperFormField required>
                  <div className="info-header">
                    <label className="label-class">Stage</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Choose deal’s initial stage"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="stageId"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        fluid
                        selection
                        placeholder="Stage"
                        onChange={(e, {value}) => onChange(value)}
                        onClick={() => errors.stageId && clearErrors('stageId')}
                        value={value}
                        error={
                          errors.stageId && {
                            content: errors.stageId.message,
                          }
                        }
                        options={stageListData}
                      />
                    )}
                  />
                </KooperFormField>
              </KooperFormGroup>

              <KooperFormGroup widths="equal">
                <KooperFormField required>
                  <div className="info-header">
                    <label className="label-class">Primary Contact</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Select contact person for the deal"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <KooperDropdown
                    selection
                    search
                    fluid
                    value={primaryValue.type}
                    options={_.uniqBy(primaryContactData, 'key')}
                    allowAdditions
                    additionLabel="create as New "
                    onAddItem={() => setPrimaryModal(!primaryModal)}
                    onChange={(e, {value}) => {
                      primaryContactFunction(value)
                      setContactError(false)
                    }}
                    error={
                      contactError && {
                        content: 'primary contact is required',
                      }
                    }
                  />
                  {contactError && (
                    <p
                      style={{
                        color: '#a03c3a',
                        fontSize: '11px',
                        margin: '4px',
                      }}
                    >
                      primary contact is required
                    </p>
                  )}
                </KooperFormField>

                {primaryValue.hasOwnProperty('contactId') ? (
                  <KooperFormField>
                    <div className="info-header">
                      <label>Company</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Choose company to which the deal belong"
                        size="mini"
                        psoition="top center"
                      />
                    </div>
                    <KooperDropdown
                      selection
                      fluid
                      search
                      value={primaryValue.companyId}
                      options={primaryValue.hasOwnProperty('contactId') && contactsCompany}
                      onChange={(e, {value}) => {
                        setPrimaryValue({...primaryValue, companyId: value})
                      }}
                      allowAdditions
                      additionLabel="Add company "
                      onAddItem={(e, {value}) => {
                        delete primaryValue.companyId
                        setContactCompanyList([...contactCompanyList, {key: value, text: value, value}])
                        setPrimaryValue({...primaryValue, company: value})
                      }}
                    />
                  </KooperFormField>
                ) : (
                  <KooperFormField>
                    <div className="info-header">
                      <label>Company</label>
                      <KooperTooltip
                        trigger={<SvgIcon path="common/question" />}
                        content="Choose company to which the deal belong"
                        size="mini"
                        psoition="top center"
                      />
                    </div>
                    <KooperFormInput
                      // disabled={
                      //   primaryValue.hasOwnProperty("lead") ||
                      //   primaryValue.hasOwnProperty("contact")
                      //     ? false
                      //     : true
                      // }
                      maxLength={20}
                      placeholder="Add company"
                      value={primaryValue.company}
                      onChange={(e, {value}) => {
                        setPrimaryValue({...primaryValue, company: value})
                      }}
                    />
                  </KooperFormField>
                )}
              </KooperFormGroup>

              <KooperFormGroup widths="equal">
                <KooperFormField required>
                  <div className="info-header">
                    <label className="label-class">Owner</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Select owner of the deal"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="assigneeId"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        fluid
                        selection
                        onClick={() => errors.assigneeId && clearErrors('assigneeId')}
                        placeholder="Add owner"
                        options={assigneeData}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        error={
                          errors.assigneeId && {
                            content: errors.assigneeId.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
                <KooperFormField>
                  <div className="info-header">
                    <label>Close Date</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Set the closing date"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  {/* <Controller
                    name="closeDate"
                    control={control}
                    render={({ value, onChange }) => ( */}

                  <KooperDatePicker
                    type="date"
                    value={typeof value === 'string' ? new Date(closeDate) : closeDate}
                    inputProps={{readOnly: true}}
                    min={new Date()}
                    time={false}
                    onChange={value => {
                      setCloseDate(value)
                    }}
                  />
                </KooperFormField>
              </KooperFormGroup>

              <KooperFormGroup widths="equal">
                <KooperFormField>
                  <div className="info-header">
                    <label>Source</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Set deal source"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="sourceId"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        fluid
                        selection
                        options={sourceListData}
                        onChange={(e, {value}) => onChange(value)}
                        value={value}
                      />
                    )}
                  />
                </KooperFormField>
                <KooperFormField>
                  <div className="info-header">
                    <label>Priority</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Set deal priority"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="priority"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        fluid
                        selection
                        onChange={(e, {value}) => onChange(value)}
                        options={priorityList}
                        value={value}
                      />
                    )}
                  />
                </KooperFormField>
              </KooperFormGroup>

              <KooperFormGroup widths="equal">
                <KooperFormField>
                  <div className="info-header">
                    <label>Currency</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention deal currency"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="currencyId"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        selection
                        fluid
                        search
                        placeholder="Select Currency"
                        options={currencyListData}
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={
                          errors.currencyId && {
                            content: errors.currencyId.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
                <KooperFormField>
                  <div className="info-header">
                    <label>Win Percentage</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention deal win percentage probability"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="win"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormInput
                        type="number"
                        placeholder="e.g. 5%"
                        onChange={(e, {value}) => onChange(value)}
                        value={value}
                        error={
                          errors.win && {
                            content: errors.win.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
              </KooperFormGroup>

              <KooperFormField fluid>
                <div className="info-header">
                  <label>Value</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set value of the deal"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="monetaryValue"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormInput
                      type="number"
                      placeholder="Add value"
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.monetaryValue && {
                          content: errors.monetaryValue.message,
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>

              <KooperFormGroup widths="equal">
                <KooperFormField>
                  <label>Description</label>
                  <Controller
                    name="description"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormTextArea
                        maxLength={160}
                        onChange={(e, {value}) => onChange(value)}
                        placeholder="Write your description here"
                        value={value}
                        style={{resize: 'none'}}
                        error={
                          errors.description && {
                            content: errors.description.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
              </KooperFormGroup>

              <KooperFormGroup>
                <KooperFormField widths="equal">
                  <div className="info-header">
                    <label className="d-block">Add Tag</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Add tags to the deal"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    control={control}
                    name="labelIds"
                    render={({onChange}) => (
                      <KooperChipInput
                        defaultValue={listOfLable.map(tag => {
                          return {
                            id: tag.value,
                            text: (
                              <>
                                <div
                                  style={{
                                    height: '10px',
                                    width: '10px',
                                    background: tag.label.style.background,
                                    borderRadius: '50%',
                                    marginRight: '10px',
                                    display: 'inline-block',
                                  }}
                                />
                                {tag.text}
                              </>
                            ),
                          }
                        })}
                        onChange={value => {
                          onChange(value)
                          const valueIds = value.map(tag => tag.id)
                          const removeTag = listOfLable.filter(contact => valueIds.indexOf(contact.value) !== -1)
                          setListOfLabel(removeTag)
                          const id = _.map(removeTag, 'value')
                          setCreateInfo({...createInfo, labelIds: id})
                        }}
                        hideInputField
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="labelIds"
                    render={({onChange}) => (
                      <KooperCustomDropdown
                        options={labelOptionsState}
                        multiple
                        dropdownProps={{
                          icon: (
                            <KooperTooltip
                              content="Tags"
                              size="mini"
                              position="bottom center"
                              trigger={<SvgIcon path="common/tag" id="TooltipTagInbox" />}
                            />
                          ),
                          direction: 'right',
                          closeOnChange: false,
                        }}
                        menu={{scrolling: true}}
                        input={{
                          icon: 'search',
                          iconPosition: 'left',
                          className: 'search',
                        }}
                        activeIndicator
                        value={listOfLable.map(labels => labels.value)}
                        onClick={value => {
                          onChange(value)
                          const updatedList = labelOptionsState.filter(val => value.indexOf(val.value) !== -1)
                          setListOfLabel(updatedList)
                        }}
                        extraAction={{
                          content: '+ Add Tag',
                          onClick: () => setNewLabelModal(true),
                        }}
                      />
                    )}
                  />
                </KooperFormField>
              </KooperFormGroup>
              <KooperFormField>
                <label>Select Product & Services</label>
                <p className="kooper-lead">
                  Select your product or service for which you would like to make quote. You can add the product{' '}
                  <a href="/setting/all-products" target="_blank">
                    here
                  </a>
                  .
                </p>
                <KooperSearch
                  placeholder="Search to get needed results"
                  minCharacters={0}
                  results={optionsToShow}
                  value={searchText}
                  onResultSelect={handleResultSelect}
                  onSearchChange={_.debounce(handleSearchChange, 500, {
                    leading: true,
                  })}
                />
              </KooperFormField>
              <h5 className="mb-0">Review Line Items</h5>
              <p className="kooper-lead mt-1">Review the line items you want shown in your quote.</p>
              <KooperTable basic className="billProduct">
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell>#</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Product Name</KooperTableHeaderCell>
                    <KooperTableHeaderCell>SKU</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Billing Frequency</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Quantity</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Unit Price</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Unit Discount (%)</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Net Price</KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>
                  {searchValue?.length < 1 ? (
                    <tr>
                      <td colSpan="8" className="emptyValue required">
                        Add Line Items to Continue Filling Form
                      </td>
                    </tr>
                  ) : (
                    searchValue.map((item, index) => {
                      return (
                        <KooperTableRow key={`tablerow${index}`}>
                          <KooperTableCell>{index + 1}</KooperTableCell>

                          <KooperTableCell>{item.title}</KooperTableCell>

                          <KooperTableCell>{item.price}</KooperTableCell>

                          <KooperTableCell>
                            <KooperFormSelect
                              fluid
                              options={billingFreqOptions}
                              value={item.billingFreq}
                              onChange={(e, {value}) => changeSearchValue(index, value, 'billingFreq')}
                            />
                          </KooperTableCell>

                          <KooperTableCell>
                            <KooperInput
                              // type="number"
                              min={1}
                              value={item.quantity}
                              onChange={(e, {value}) => changeSearchValue(index, Number(value), 'quantity')}
                              style={{width: 'auto'}}
                              onBlur={() => {
                                if (!testIsNumber(item.quantity)) {
                                  changeSearchValue(index, '0', 'quantity')
                                }
                              }}
                            />
                          </KooperTableCell>

                          <KooperTableCell>
                            <KooperInput
                              style={{width: 'auto'}}
                              value={item.unitPrice}
                              onChange={(e, {value}) => changeSearchValue(index, Number(value), 'unitPrice')}
                              onBlur={() => {
                                if (!testIsNumber(item.unitPrice)) {
                                  changeSearchValue(index, '0', 'unitPrice')
                                }
                              }}
                            />
                          </KooperTableCell>

                          <KooperTableCell>
                            <KooperInput
                              style={{width: 'min-content'}}
                              value={item.discountValue}
                              onChange={(e, {value}) => changeSearchValue(index, Number(value), 'discountValue')}
                              onBlur={() => {
                                if (item.discountType === '%') {
                                  if (!testIsPercentage(item.discountValue)) {
                                    changeSearchValue(index, '0', 'discountValue')
                                  }
                                } else if (!testIsNumber(item.discountValue)) {
                                  changeSearchValue(index, '0', 'discountValue')
                                }
                              }}
                              label={
                                <KooperDropdown
                                  options={typeOptions}
                                  value={item.discountType}
                                  onChange={(e, {value}) => changeSearchValue(index, value, 'discountType')}
                                />
                              }
                            />
                          </KooperTableCell>
                          <KooperTableCell>
                            {getUnitTotal(item.quantity * item.unitPrice, item.discountType, item.discountValue)}
                          </KooperTableCell>
                        </KooperTableRow>
                      )
                    })
                  )}
                </KooperTableBody>
              </KooperTable>
            </KooperForm>
          </KooperGridColumn>
        </KooperGrid>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </KooperButton>
        <KooperButton
          color="blue"
          onClick={handleSubmit(createDealBtn)}
          disabled={!isDirty || (isLoadingData && type.includes(ADD_DEAL))}
          loading={isLoadingData && type.includes(ADD_DEAL)}
        >
          Create
        </KooperButton>
      </KooperModalActions>
      {primaryModal && isModalPrimary()}
      {createNewLabel()}
    </KooperModal>
  )
}

export default CreateDealModal
