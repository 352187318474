import SvgIcon from 'components/common/SvgIcon'
import {
  KooperCard,
  KooperCardContent,
  KooperHeader,
  KooperIcon,
  KooperInput,
  KooperModal,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

const ParentGoalModal = ({isModalOpen, setIsModalOpen = () => null}) => {
  return (
    <KooperModal
      size="small"
      open={isModalOpen}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <KooperModalHeader>Select a parent goal</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperHeader as="h4">
          Adding a parent goal allows your team to be aligned with the whole organization
        </KooperHeader>
        <div>
          <KooperInput fluid icon="search" placeholder="Search by goal" className="goal-search-input" />

          {[{title: 'New O goal'}, {title: 'Organization'}, {title: 'sghtghgh'}, {title: 'test title'}].map(
            (item, index) => (
              <KooperCard key={index} fluid link className="goal-card">
                <KooperCardContent>
                  <div className="card-content">
                    <KooperIcon name="building outline" className="card-icon" />
                    <div>
                      <div className="card-subtitle">LINEACR</div>
                      <div className="card-title">{item.title}</div>
                    </div>
                  </div>
                </KooperCardContent>
              </KooperCard>
            )
          )}
        </div>
      </KooperModalContent>
    </KooperModal>
  )
}

export default ParentGoalModal
