/* eslint-disable react/button-has-type */
import {
  addProjectMilestoneComment,
  completeProjectMilestone,
  deleteProjectMilestone,
  deleteProjectMilestoneComment,
  editProjectMilestoneComment,
  getSingleProejctMilestone,
} from 'actions/projects'
import {
  ADD_PROJECT_MILESTONE_COMMENT,
  COMPLETE_PROJECT_MILESTONE,
  COPY_PROJECT_MILESTONE,
  DELETE_PROJECT_MILESTONE,
  DELETE_PROJECT_MILESTONE_COMMENT,
  EDIT_PROJECT_MILESTONE,
  EDIT_PROJECT_MILESTONE_COMMENT,
} from 'actions/types'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getFormattedDate, milestoneStatusColor, removeDoubleQuotes} from 'utils/helper'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperForm,
  KooperFormField,
  KooperFormTextArea,
  KooperIcon,
  KooperLabel,
  KooperTooltip,
} from 'kooper-library'
import DeleteModal from 'components/common/DeleteModal'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {addCommentSchema} from 'validation/Projects/projects/milestone.schema'
import SvgIcon from 'components/common/SvgIcon'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import MilestoneModal from './MilestoneModal'
import MoveMilestoneModal from './MoveMilestoneModal'
import MilestoneDuplicateModal from './MilestoneDuplicateModal'

function MilestoneView(props) {
  const {projectId} = props

  const dispatch = useDispatch()

  const [isMilestoneOpen, setIsMilestoneOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [commentToDelete, setCommentToDelete] = useState(null)
  const [commentDeleteModalOpen, setCommentDeleteModalOpen] = useState(false)
  const [commentId, setCommentId] = useState(null)
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false)
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false)

  const {getSingleMilestoneData = {}} = useSelector(state => state.projects)
  const {name, description, dueDetails, status, isCompleted, id, comments} = getSingleMilestoneData
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsPermissions} = useUserPermissions()
  const manageProjectsMilestonesPermissions = projectsPermissions?.manage_milestones

  const {handleSubmit, errors, control, reset} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(addCommentSchema),
    defaultValues: {
      comment: '',
    },
  })

  useEffect(() => {
    if (
      successLabels.includes(COPY_PROJECT_MILESTONE) ||
      successLabels.includes(COMPLETE_PROJECT_MILESTONE) ||
      successLabels.includes(EDIT_PROJECT_MILESTONE)
    ) {
      dispatch(getSingleProejctMilestone({milestoneId: id, projectId}))
    }
    if (successLabels.includes(DELETE_PROJECT_MILESTONE)) {
      window.history.back()
      dispatch(getSingleProejctMilestone({milestoneId: id, projectId}))
    }
    if (
      successLabels.includes(ADD_PROJECT_MILESTONE_COMMENT) ||
      successLabels.includes(EDIT_PROJECT_MILESTONE_COMMENT) ||
      successLabels.includes(DELETE_PROJECT_MILESTONE_COMMENT)
    ) {
      reset({comment: ''})
      dispatch(getSingleProejctMilestone({milestoneId: id, projectId}))
      setCommentId(null)
    }
  }, [successLabels, dispatch, id, projectId])

  return (
    <>
      <div className="milestone-body">
        <div className="milestone-page-header">
          <div className="milestone-action">
            <>
              <KooperButton className="backBtn" onClick={() => window.history.back()}>
                <SvgIcon path="common/left" /> Back To Milestones
              </KooperButton>
              <LockPermissionTooltip isRoleAccessDenied={!manageProjectsMilestonesPermissions}>
                <KooperButton
                  className={!manageProjectsMilestonesPermissions ? 'disabled-button mr-2' : 'mr-2'}
                  onClick={() => {
                    if (manageProjectsMilestonesPermissions) {
                      dispatch(getSingleProejctMilestone({milestoneId: id, projectId}))
                      setIsMilestoneOpen(true)
                    }
                  }}
                  primary
                >
                  <SvgIcon path="common/edit2" /> Edit Milestone
                </KooperButton>
              </LockPermissionTooltip>
              <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon" direction="left">
                <KooperDropdownMenu>
                  <LockPermissionTooltip isRoleAccessDenied={!manageProjectsMilestonesPermissions}>
                    <KooperDropdownItem
                      onClick={() => {
                        if (manageProjectsMilestonesPermissions) {
                          dispatch(getSingleProejctMilestone({milestoneId: id, projectId}))
                          setIsMilestoneOpen(true)
                        }
                      }}
                    >
                      <SvgIcon path="common/edit2" /> Edit
                    </KooperDropdownItem>
                  </LockPermissionTooltip>
                  <LockPermissionTooltip isRoleAccessDenied={!manageProjectsMilestonesPermissions}>
                    <KooperDropdownItem
                      onClick={() => manageProjectsMilestonesPermissions && setIsDuplicateModalOpen(true)}
                    >
                      <SvgIcon path="common/clone" /> Clone
                    </KooperDropdownItem>
                  </LockPermissionTooltip>
                  <LockPermissionTooltip isRoleAccessDenied={!manageProjectsMilestonesPermissions}>
                    <KooperDropdownItem onClick={() => manageProjectsMilestonesPermissions && setDeleteModal(true)}>
                      <SvgIcon path="common/delete" /> Delete
                    </KooperDropdownItem>
                  </LockPermissionTooltip>
                  <LockPermissionTooltip isRoleAccessDenied={!manageProjectsMilestonesPermissions}>
                    <KooperDropdownItem onClick={() => manageProjectsMilestonesPermissions && setIsMoveModalOpen(true)}>
                      <SvgIcon path="common/move" /> Move
                    </KooperDropdownItem>
                  </LockPermissionTooltip>
                </KooperDropdownMenu>
              </KooperDropdown>
            </>
          </div>
        </div>
        <div className="w-milestone-row">
          <div className="w-milestone-row__date">
            <div className="w-milestone-date">
              <span className="w-milestone-date__month" style={milestoneStatusColor(status, isCompleted)}>
                {dueDetails?.monthName}
              </span>
              <span className="w-milestone-date__day">
                <span className="w-milestone-date__day-numeric">{dueDetails?.date}</span>
                <span className="w-milestone-date__day-label">{dueDetails?.dayName}</span>
              </span>
            </div>
          </div>
          <div className="w-milestone-row__base ">
            <div className="w-milestone-row__completion">
              <KooperTooltip
                size="mini"
                position="top center"
                content={isCompleted ? 'Mark milestone incomplete' : 'Mark milestone complete'}
                trigger={
                  <button
                    className="w-completion-check"
                    onClick={e => {
                      e.stopPropagation()
                      const data = {isCompleted: !isCompleted}
                      dispatch(completeProjectMilestone({projectId, milestoneId: id, data}))
                    }}
                  >
                    <span className={`w-completion-check__holder ${isCompleted ? 'completed' : ''}`}>
                      <SvgIcon path="common/check" />
                    </span>
                  </button>
                }
              />
            </div>

            <div className="w-milestone-row__content">
              <div className="w-milestone-row__title">
                <h4>{name}</h4>
              </div>
              <p className="w-milestone-row__meta">You are responsible</p>
              <div className="w-milestone-row__description">{description}</div>
              <ul className="w-milestone-row__actions w-action-links">
                <li>
                  <LockPermissionTooltip isRoleAccessDenied={!manageProjectsMilestonesPermissions}>
                    <KooperButton
                      onClick={() => {
                        if (manageProjectsMilestonesPermissions) {
                          dispatch(getSingleProejctMilestone({milestoneId: id, projectId}))
                          setIsMilestoneOpen(true)
                        }
                      }}
                      className="btn"
                    >
                      <SvgIcon className="mr-1" path="common/edit2" />
                      Edit
                    </KooperButton>
                  </LockPermissionTooltip>
                </li>
                <li>
                  <LockPermissionTooltip isRoleAccessDenied={!manageProjectsMilestonesPermissions}>
                    <KooperButton
                      className="btn"
                      onClick={() => {
                        if (manageProjectsMilestonesPermissions) {
                          setDeleteModal(true)
                        }
                      }}
                    >
                      <SvgIcon className="mr-1" path="common/delete" /> Delete
                    </KooperButton>
                  </LockPermissionTooltip>
                </li>
                {/* <li>
                  <KooperButton className="btn">
                    <KooperIcon name="tasks" /> Attach task list
                  </KooperButton>
                </li> */}
              </ul>
            </div>

            <div className="w-milestone-row__utilities">
              <KooperLabel style={milestoneStatusColor(status, isCompleted)}>{dueDetails?.dueLabel}</KooperLabel>
            </div>
          </div>
        </div>

        <div className="s-milestone-details__comments">
          <div className="s-comments-list__title">
            <h3>Comments</h3>
          </div>
          <div className="s-comments-list__list">
            <div className="s-comments-list__inner">
              <KooperForm className="errorLabel">
                <KooperFormField>
                  <Controller
                    name="comment"
                    render={({onChange, value}) => (
                      <KooperFormTextArea
                        type="text"
                        className="mt-4"
                        maxLength={200}
                        value={value}
                        placeholder="Add your comment here"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors.comment && {
                            content: removeDoubleQuotes(errors.comment.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
                <div style={{textAlign: 'right', margin: '18px 0'}}>
                  <KooperButton
                    basic
                    onClick={() => {
                      reset({comment: ''})
                      setCommentId(null)
                    }}
                  >
                    Cancel
                  </KooperButton>
                  <KooperButton
                    className="ml-2 mr-0"
                    primary
                    onClick={handleSubmit(data =>
                      commentId
                        ? dispatch(editProjectMilestoneComment({data, milestoneId: id, commentId, projectId}))
                        : dispatch(addProjectMilestoneComment({projectId, milestoneId: id, data}))
                    )}
                  >
                    {commentId ? 'Update Comment' : 'Send'}
                  </KooperButton>
                </div>
              </KooperForm>
              {comments?.map(({comment, createdAt, id, firstName, lastName}) => {
                return (
                  <KooperCard fluid>
                    <KooperCardContent>
                      <div className="d-flex">
                        <KooperCardHeader>
                          <div
                            className="d-flex proof-listing-assign"
                            style={{justifyContent: 'flex-start', fontSize: '30px'}}
                          >
                            <div>
                              <KooperTooltip
                                content={`${firstName} ${lastName}`}
                                position="top center"
                                size="mini"
                                trigger={<span>{`${firstName[0]}${lastName[0]}`}</span>}
                              />
                            </div>
                            <p className="kooper-lead">{`${firstName} ${lastName}`}</p>
                            <p className="kooper-lead ml-3 mb-1">{getFormattedDate(createdAt)}</p>
                          </div>
                        </KooperCardHeader>
                        <div className="d-flex">
                          <KooperTooltip
                            content="Edit"
                            position="top center"
                            size="mini"
                            trigger={
                              <KooperButton
                                className="mr-3"
                                icon
                                onClick={() => {
                                  setCommentId(id)
                                  reset({comment})
                                }}
                              >
                                <SvgIcon path="common/edit2" />
                              </KooperButton>
                            }
                          />
                          <KooperTooltip
                            content="Delete"
                            position="top center"
                            size="mini"
                            trigger={
                              <KooperButton
                                className="mr-0"
                                icon
                                onClick={() => {
                                  setCommentToDelete(id)
                                  setCommentDeleteModalOpen(true)
                                }}
                              >
                                <SvgIcon path="common/delete" />
                              </KooperButton>
                            }
                          />
                        </div>
                      </div>
                      <div className="form-description">
                        <p className="ml-5">{comment}</p>
                      </div>
                    </KooperCardContent>
                  </KooperCard>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      {isMilestoneOpen && (
        <MilestoneModal
          projectId={projectId}
          setIsMilestoneOpen={setIsMilestoneOpen}
          isMilestoneOpen={isMilestoneOpen}
        />
      )}
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          onDelete={() => dispatch(deleteProjectMilestone({milestoneId: id, projectId}))}
          type={DELETE_PROJECT_MILESTONE}
        />
      )}
      {commentDeleteModalOpen && (
        <DeleteModal
          isModalOpen={commentDeleteModalOpen}
          setIsModalOpen={setCommentDeleteModalOpen}
          modalText="Are you sure you want to delete ?"
          onDelete={() =>
            dispatch(deleteProjectMilestoneComment({milestoneId: id, projectId, commentId: commentToDelete}))
          }
          type={DELETE_PROJECT_MILESTONE_COMMENT}
        />
      )}
      {isMoveModalOpen && (
        <MoveMilestoneModal
          open={isMoveModalOpen}
          toggle={setIsMoveModalOpen}
          projectId={projectId}
          milestoneId={id}
          milestoneName={name}
        />
      )}
      {isDuplicateModalOpen && (
        <MilestoneDuplicateModal
          open={isDuplicateModalOpen}
          toggle={setIsDuplicateModalOpen}
          projectId={projectId}
          milestoneId={id}
          milestoneName={name}
        />
      )}
    </>
  )
}

export default MilestoneView
