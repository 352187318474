import React, {useEffect, useState} from 'react'
import {useHistory, useRouteMatch, useLocation} from 'react-router-dom'
import {FiChevronRight, FiFileText, FiLayers, FiPenTool, FiSettings} from 'react-icons/fi'
import {KooperAccordion, KooperAccordionContent, KooperAccordionTitle, KooperMenu, KooperMenuItem} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'
import {isActiveNavlink} from 'utils/helper'
import {TIME_OFF_PATH} from 'constants/path'

const TimeOffMenu = () => {
  const location = useLocation()
  const history = useHistory()
  const {url} = useRouteMatch()
  const [expand, setExpand] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const menus = [
    {
      key: 'time-off',
      title: 'Time Off',
      items: [
        {
          path: `/${TIME_OFF_PATH.PREFIX}/${TIME_OFF_PATH.ALL}`,
          title: 'All Time Off',
          icon: <FiFileText />,
        },
        {
          // path: `/${TIME_OFF_PATH.PREFIX}`,
          title: 'My Time Off',
          icon: <FiLayers />,
        },
        {
          // path: `/${TIME_OFF_PATH.PREFIX}`,
          title: 'Manage Approvals',
          icon: <FiPenTool />,
        },
      ],
    },
    {
      key: 'settings',
      title: 'Settings',
      items: [
        {
          path: `/${TIME_OFF_PATH.PREFIX}/${TIME_OFF_PATH.TYPES}`,
          title: 'Time Off Types',
          icon: <FiSettings />,
        },
      ],
    },
  ]

  const handleMenuExpand = key => {
    const menus = [...expand]
    if (menus.includes(key)) {
      menus.splice(menus.indexOf(key), 1)
      setExpand(menus)
    } else {
      setExpand([...menus, key])
    }
  }

  const isActive = key => !expand.includes(key)

  return (
    <KooperMenu vertical secondary className="kooper-menu">
      {menus.map(({key, title, items = []}) => (
        <KooperAccordion key={key}>
          <KooperAccordionTitle onClick={() => handleMenuExpand(key)}>
            <div className="d-flex-ac">
              {isActive(key) ? <SvgIcon path="common/down" /> : <FiChevronRight />} {title}
            </div>
          </KooperAccordionTitle>
          <KooperAccordionContent active={isActive(key)}>
            {items.map(({path, title, icon}) => (
              <KooperMenuItem key={path} active={isActiveNavlink(path)} onClick={() => history.push(path)}>
                {icon}
                {title}
              </KooperMenuItem>
            ))}
          </KooperAccordionContent>
        </KooperAccordion>
      ))}
    </KooperMenu>
  )
}

export default TimeOffMenu
