import CardLoader from 'components/common/CardLoader'
import KooperNoDataTable from 'components/common/KooperNoDataTable'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SearchComponent from 'components/common/SearchComponent'
import SvgIcon from 'components/common/SvgIcon'
import {Status} from 'constants/variables'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperDivider,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperTooltip,
} from 'kooper-library'
import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {createGoalsDropdownData} from 'constants/Teams/Goals/goals'
import GoalsCardItem from './GoalsCardItem'
import PersonalGoalSelectModal from './PersonalGoalSelectModal'

const OrganizationGoals = () => {
  const history = useHistory()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    status: '',
    sortBy: '',
    sortType: '',
    text: '',
  })

  const goalsList = [
    {
      title: 'Goal name',
      id: `1`,
      status: 'active',
      type: 'organization',
    },
  ]

  const isGoalsDataLoading = false

  // if (goalsList.length === 0) {
  //   return (
  //     <SettingLayout>
  //       <CreateFirst
  //         header="Sales Orders"
  //         // addFirst={() => manageSalesOrdersPermissions && history.push(`/sales-orders/create`)}
  //         // tooltip={!manageSalesOrdersPermissions}
  //         // tooltipContent={getPermissionTooltipContent({
  //         //   roleAccess: manageSalesOrdersPermissions,
  //         // })}
  //         buttonText="Create Sales Order"
  //         imageSrc={create}
  //         subHeader="Manage the sale of products to a customer at an agreed-upon price"
  //         Feature1="Order Management"
  //         list1="Easily create and modify sales orders with customizable templates"
  //         Feature2="Multi-Currency Support"
  //         list2="Process orders in various currencies to conduct business globally"
  //         Feature3="Comprehensive Credits and Discounts"
  //         list3="Define total order credits based on tax and discount percentages"
  //       />
  //     </SettingLayout>
  //   )
  // }

  return (
    <div className="kooper-side-wrap">
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="navigation/sales-orders" />
            <h5>Goals ({goalsList ? goalsList.length : 0})</h5>
          </div>
        </div>
        <div className="page-action">
          <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
          <KooperTooltip
            position="top center"
            size="mini"
            content="Select Status"
            trigger={
              <div className="mailActivity ml-3">
                <KooperDropdown
                  icon="chevron down"
                  button
                  defaultValue="all"
                  options={[{key: 'all', value: 'all', text: 'All Status'}, <KooperDivider />, ...Status]}
                  onChange={(e, {value}) => {
                    setCurrentListParams({
                      ...currentListParams,
                      status: value,
                    })
                  }}
                />
              </div>
            }
          />
          <LockPermissionTooltip // </div>isRoleAccessDenied={!manageSalesOrdersPermissions}
          >
            <KooperDropdown direction="left" button className="large primary goals-create-dropdown" text="Create Goals">
              <KooperDropdownMenu className="goals-create-dropdown-menu">
                {createGoalsDropdownData?.map(({value, Icon, header, subHeader}) => (
                  <KooperDropdownItem
                    key={value}
                    onClick={() =>
                      value === 'personal' ? setIsModalOpen(true) : history.push(`/goals/create/${value}`)
                    }
                  >
                    <div className="custom-dropdown-menu-icon">
                      <Icon />
                    </div>
                    <div className="goals-create-dropdown-content">
                      <h5>{header}</h5>
                      <p>{subHeader}</p>
                    </div>
                  </KooperDropdownItem>
                ))}
              </KooperDropdownMenu>
            </KooperDropdown>
          </LockPermissionTooltip>
        </div>
      </div>

      {isGoalsDataLoading && <CardLoader length={3} />}
      {goalsList?.length === 0 && !isGoalsDataLoading && (
        <KooperCard fluid className="project-list-card">
          <KooperCardContent>
            <KooperNoDataTable />
          </KooperCardContent>
        </KooperCard>
      )}
      {goalsList?.length > 0 && !isGoalsDataLoading
        ? goalsList?.map(goal => (
            <GoalsCardItem
              // reportPermissions={reportPermissions?.banner}
              // managePermission={assistPermissions?.manage_banner}
              // setCustomDeleteBanner={setCustomDeleteBanner}
              key={goal.id}
              goal={goal}
              // setCustomPublish={setCustomPublish}
            />
          ))
        : null}
      {/* {deleteModal && (
            <DeleteModal
              isModalOpen={deleteModal}
              setIsModalOpen={setDeleteModal}
              modalText="Are you sure you want to delete ?"
              deleteAction={deleteSalesOrders}
              idTobeDeleted={editId}
              type={DELETE_SALES_ORDERS}
            />
          )} */}
      {isModalOpen && <PersonalGoalSelectModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />}
    </div>
  )
}

export default OrganizationGoals
