import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  KooperButton,
  KooperDatePicker,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperFormTextArea,
  KooperGrid,
  KooperGridColumn,
  KooperHeader,
  KooperHeaderContent,
  KooperIcon,
  KooperInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTab,
  KooperTabPane,
} from 'kooper-library'
import moment from 'moment'
import {
  CREATE_PROJECTS_TASKS_LIST_TASK_COMMENT,
  GET_PROJECTS_TASKS_LIST_SINGLE_TASK,
  UPDATE_PROJECTS_TASKS_LIST_SINGLE_TASK,
} from 'actions/types'
import {
  createProjectsTasksListTaskComment,
  getProjectTasksListSingleTask,
  updateProjectTaskListSingleTask,
} from 'actions/projects'
import {get, getFormattedDate, getFormattedTime, removeDoubleQuotes, startCase} from 'utils/helper'
import {viewTaskSchema} from 'validation/Projects/projects/tasks.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const taskDefaultValues = {
  title: '',
  description: '',
  estimatedHrs: null,
  estimatedMin: null,
  startDate: new Date(moment().startOf('day')),
  endDate: new Date(moment().add(1, 'days').startOf('day')),
  stageId: null,
  labelIds: [],
  assignedTo: null,
}

const ViewTask = ({open, toggle, projectId, taskId}) => {
  const dispatch = useDispatch()

  const [comment, setComment] = useState('')

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {taskListAssignees = [], taskListStages = [], taskData = {}} = useSelector(state => state.projects)
  const {getTagList = []} = useSelector(state => state.multipleFields)
  const {allActivities = []} = taskData

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(viewTaskSchema),
    defaultValues: taskDefaultValues,
  })

  const {callAction: callGetProjectTasksListSingleTaskAction} = useApiResponse({
    action: getProjectTasksListSingleTask,
    payload: {projectId, taskId},
    dependency: [projectId, taskId],
    enabled: projectId && taskId,
    label: GET_PROJECTS_TASKS_LIST_SINGLE_TASK,
    storePath: 'projects.taskData',
    onSuccess: (taskData = {}) => {
      const data = _.pick(taskData, [
        'title',
        'description',
        'estimatedHrs',
        'estimatedMin',
        'startDate',
        'endDate',
        'stageId',
        'labelIds',
        'assignedTo',
      ])
      reset(data)
    },
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECTS_TASKS_LIST_TASK_COMMENT)) {
      setComment('')
      callGetProjectTasksListSingleTaskAction()
    }
  }, [successLabels, callGetProjectTasksListSingleTaskAction])

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECTS_TASKS_LIST_SINGLE_TASK)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    dispatch(updateProjectTaskListSingleTask({projectId, taskId, data}))
  }

  const panes = [
    {
      menuItem: 'Details',
      pane: (
        <KooperTabPane className="mt-3">
          <KooperForm className="errorLabel">
            <KooperFormField required>
              <label>Assignee</label>
              <Controller
                name="assignedTo"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormSelect
                    search
                    options={taskListAssignees.map(({id, firstName, lastName}) => ({
                      key: id,
                      value: id,
                      text: `${firstName} ${lastName}`,
                    }))}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.assignedTo && {content: removeDoubleQuotes(errors.assignedTo.message)}}
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField required>
              <label>Title</label>
              <Controller
                name="title"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormInput
                    maxLength={50}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField>
              <label>Description</label>
              <Controller
                name="description"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormTextArea
                    style={{resize: 'none'}}
                    value={value}
                    maxLength={200}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.description && {content: removeDoubleQuotes(errors.description.message)}}
                  />
                )}
              />
            </KooperFormField>
            <KooperFormGroup>
              <KooperFormField required width={8}>
                <label>Estimate Time</label>
                <KooperGrid columns="equal">
                  <KooperGridColumn>
                    <Controller
                      name="estimatedHrs"
                      control={control}
                      render={({value, onChange}) => (
                        <KooperFormInput
                          fluid
                          type="number"
                          placeholder="00H"
                          value={value}
                          onChange={(e, {value}) => onChange(value)}
                          error={errors.estimatedHrs && {content: removeDoubleQuotes(errors.estimatedHrs.message)}}
                        />
                      )}
                    />
                  </KooperGridColumn>
                  <KooperGridColumn>
                    <Controller
                      name="estimatedMin"
                      control={control}
                      render={({value, onChange}) => (
                        <KooperFormInput
                          fluid
                          type="number"
                          placeholder="00M"
                          value={value}
                          onChange={(e, {value}) => onChange(value)}
                          error={errors.estimatedMin && {content: removeDoubleQuotes(errors.estimatedMin.message)}}
                        />
                      )}
                    />
                  </KooperGridColumn>
                </KooperGrid>
              </KooperFormField>
              <KooperFormField required width={8}>
                <label>Stage</label>
                <Controller
                  name="stageId"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      options={taskListStages.map(({id, name}) => ({key: id, value: id, text: name}))}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={errors.stageId && {content: removeDoubleQuotes(errors.stageId.message)}}
                    />
                  )}
                />
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormField>
              <label>Actual Time</label>
              <p>
                {get(['actualHrs'], taskData, 0)}h {get(['actualMin'], taskData, 0)}m
              </p>
            </KooperFormField>
            <KooperFormGroup>
              <KooperFormField required>
                <label>Start Date</label>
                <Controller
                  name="startDate"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperDatePicker
                      time={false}
                      value={value ? new Date(value) : value}
                      onChange={value => {
                        onChange(value)
                      }}
                      min={new Date()}
                      defaultValue={typeof value === 'string' ? new Date(value) : value}
                      inputProps={{
                        component: props => <input {...props} readOnly />,
                      }}
                    />
                  )}
                />
                {errors.startDate && (
                  <p className="errorLabelElement">{removeDoubleQuotes(errors.startDate.message)}</p>
                )}
              </KooperFormField>
              <KooperFormField required>
                <label>Due Date</label>
                <Controller
                  name="endDate"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperDatePicker
                      time={false}
                      value={value ? new Date(value) : value}
                      onChange={value => {
                        onChange(value)
                      }}
                      min={new Date()}
                      defaultValue={typeof value === 'string' ? new Date(value) : value}
                      inputProps={{
                        component: props => <input {...props} readOnly />,
                      }}
                    />
                  )}
                />
                {errors.endDate && <p className="errorLabelElement">{removeDoubleQuotes(errors.endDate.message)}</p>}
              </KooperFormField>
            </KooperFormGroup>

            <KooperFormField>
              <label>Labels</label>
              <Controller
                name="labelIds"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormSelect
                    search
                    multiple
                    options={getTagList.map(({id, label, color}) => ({
                      key: id,
                      value: id,
                      text: startCase(label),
                      label: {color},
                    }))}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.labelIds && {content: removeDoubleQuotes(errors.labelIds.message)}}
                  />
                )}
              />
            </KooperFormField>
          </KooperForm>
        </KooperTabPane>
      ),
    },
    {
      menuItem: 'Timeline',
      pane: (
        <KooperTabPane className="mt-3">
          <KooperInput
            fluid
            action={{
              color: 'blue',
              content: 'Add Comment',
              onClick: () => {
                const data = {
                  content: comment,
                }

                if (comment.trim() !== '') {
                  dispatch(createProjectsTasksListTaskComment({projectId, taskId, data}))
                }
              },
            }}
            actionPosition="right"
            placeholder="Write a comment"
            value={comment}
            onChange={e => setComment(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                const data = {
                  content: comment,
                }

                if (comment.trim() !== '') {
                  dispatch(createProjectsTasksListTaskComment({projectId, taskId, data}))
                }
              }
            }}
          />

          <div className="modalTimeline">
            {allActivities.map(activity => (
              <KooperHeader as="h5">
                <KooperIcon>
                  <SvgIcon path="settings/activities" />
                </KooperIcon>
                <KooperHeaderContent>
                  <div className="content">
                    {[
                      <strong>{get(['agent'], activity, '')} &nbsp;</strong>,
                      <strong>
                        {activity.activityDescription}
                        &nbsp;
                      </strong>,
                    ]}
                    <span className="time mt-1">
                      {getFormattedTime(activity.ts || activity.createdAt)},{' '}
                      {getFormattedDate(activity.ts || activity.createdAt)}
                    </span>
                  </div>
                </KooperHeaderContent>
              </KooperHeader>
            ))}
          </div>
        </KooperTabPane>
      ),
    },
  ]

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Activities</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperTab panes={panes} menu={{secondary: true, pointing: true, color: 'blue'}} renderActiveOnly={false} />
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Update
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default ViewTask
