import React from 'react'
import SvgIcon from 'components/common/SvgIcon'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

const CloneDealModal = ({cloneDeal, setCloneDeal}) => {
  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={cloneDeal}
      size="tiny"
      onClose={setCloneDeal}
    >
      <KooperModalHeader>Create Deal Pipeline</KooperModalHeader>
      <KooperModalContent>
        <KooperForm>
          <KooperFormField>
            <label>Pipeline Name</label>
            <KooperInput placeholder="Enter board name" />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onCick={setCloneDeal}>
          Cancel
        </KooperButton>
        <KooperButton primary>Create</KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default CloneDealModal
