/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperButton,
  KooperContainer,
  KooperHeader,
  KooperLabel,
  KooperList,
  KooperListItem,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {DELETE_PRODUCTS_FIELD, GET_PRODUCTS_CATEGORY} from 'actions/types'
import {deleteProductsField, getProductCategory, getProductField, getProductFieldlist} from 'actions/productLibrary'
import DeleteModal from 'components/common/DeleteModal'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import FieldModal from './FieldsModal'

function ProductFields() {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()

  const {categoryList, fieldList = []} = useSelector(state => state.productLibrary)
  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)

  const {productLibraryPermissions} = useUserPermissions()
  const manageProductSettingsPermissions = productLibraryPermissions?.manage_product_settings

  useEffect(() => {
    dispatch(getProductFieldlist())
    if (!categoryList) {
      dispatch(getProductCategory())
    }
  }, [])

  useEffect(() => {
    if (successLabels.includes(DELETE_PRODUCTS_FIELD)) {
      dispatch(getProductFieldlist())
      setEditId()
    }
  }, [successLabels])

  return (
    <>
      <KooperContainer fluid>
        <div className="pageHeader px-0">
          <div className="pageTitle">
            <h5>Product Fields</h5>
          </div>
          <div className="pageAction">
            <KooperTooltip
              size="mini"
              position="bottom center"
              content={
                manageProductSettingsPermissions
                  ? 'Add new Product'
                  : getPermissionTooltipContent({roleAccess: manageProductSettingsPermissions})
              }
              trigger={
                <KooperButton
                  floated="right"
                  content="Add New"
                  onClick={() => manageProductSettingsPermissions && setOpen(true)}
                  className={!manageProductSettingsPermissions ? 'disabled-button' : ''}
                  primary
                />
              }
            />
          </div>
        </div>

        <div>
          <KooperTable sortable basic selectable>
            <KooperTableHeader>
              <KooperTableRow>
                <KooperTableHeaderCell className="pl-4" disabled>
                  #
                </KooperTableHeaderCell>
                <KooperTableHeaderCell>Name</KooperTableHeaderCell>
                <KooperTableHeaderCell disabled>Category</KooperTableHeaderCell>
                <KooperTableHeaderCell disabled>Type</KooperTableHeaderCell>
                <KooperTableHeaderCell disabled>Required</KooperTableHeaderCell>
                <KooperTableHeaderCell disabled>Action</KooperTableHeaderCell>
              </KooperTableRow>
            </KooperTableHeader>
            <KooperTableBody>
              {isLoadingData && type.includes(GET_PRODUCTS_CATEGORY) ? (
                <KooperTablePlaceholder columns={6} />
              ) : fieldList.length > 0 ? (
                fieldList.map(({id, name, required, categoryIds, type}, index) => {
                  return (
                    <KooperTableRow
                      onClick={() => {
                        if (manageProductSettingsPermissions) {
                          dispatch(getProductField(id))
                          setOpen(true)
                          setEditId(id)
                        }
                      }}
                      className="tableLink"
                      key={id}
                    >
                      <KooperTableCell className="pl-4">{index + 1}</KooperTableCell>
                      <KooperTableCell>
                        <KooperHeader as="h5">{startCase(name)}</KooperHeader>
                      </KooperTableCell>
                      <KooperTableCell>
                        <KooperList horizontal>
                          {categoryIds &&
                            categoryIds
                              .slice(0, 2)
                              .map(({category = '', id}, i) => (
                                <KooperListItem key={id}>
                                  {`${category} ${i + 1 !== categoryIds?.length ? ',' : ''}`}
                                </KooperListItem>
                              ))}
                          <KooperListItem>
                            {categoryIds?.length > 2 && (
                              <KooperLabel>
                                &{categoryIds.length - 2}
                                more
                              </KooperLabel>
                            )}
                          </KooperListItem>
                        </KooperList>
                      </KooperTableCell>
                      <KooperTableCell>{type}</KooperTableCell>
                      <KooperTableCell>{required ? 'Yes' : 'No'}</KooperTableCell>
                      <KooperTableCell onClick={e => e.stopPropagation()}>
                        <KooperTooltip
                          content={
                            manageProductSettingsPermissions
                              ? 'Delete'
                              : getPermissionTooltipContent({roleAccess: manageProductSettingsPermissions})
                          }
                          size="mini"
                          position="top center"
                          trigger={
                            <KooperButton
                              icon
                              onClick={() => {
                                if (manageProductSettingsPermissions) {
                                  setEditId(id)
                                  setDeleteModal(true)
                                }
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </KooperButton>
                          }
                        />
                      </KooperTableCell>
                    </KooperTableRow>
                  )
                })
              ) : (
                <tr>
                  <td colSpan="6" className="emptyValue">
                    No Data Available
                  </td>
                </tr>
              )}
            </KooperTableBody>
          </KooperTable>
        </div>
      </KooperContainer>

      {open && <FieldModal open={open} toggle={() => setOpen(false)} editId={editId} clearEditId={() => setEditId()} />}
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete this field?"
          deleteAction={deleteProductsField}
          idTobeDeleted={editId}
          type={DELETE_PRODUCTS_FIELD}
        />
      )}
    </>
  )
}

export default ProductFields
