/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Board from 'react-trello'
import {AiOutlineCheck, AiOutlineEye} from 'react-icons/ai'
import {FiArchive} from 'react-icons/fi'
import {MdOutlineMoreVert} from 'react-icons/md'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperDropdown,
  KooperDropdownDivider,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperIcon,
  KooperLabel,
  KooperTooltip,
} from 'kooper-library'

import {
  completeProjectTasksListTask,
  createProjectsTasksListStage,
  deleteProjectsTasksListStage,
  deleteProjectsTasksListTask,
  moveProjectsTasksListStage,
  updateProjectsTasksListTaskStage,
} from 'actions/projects'
import {get, getFormattedDate, removeDoubleQuotes, startCase} from 'utils/helper'
import DeleteModal from 'components/common/DeleteModal'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import {DELETE_PROJECTS_TASKS_LIST_STAGE, UPDATE_PROJECTS_TASKS_LIST_STAGE} from 'actions/types'
import {colorOptions} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'
import AssigneeModal from './AssigneeModal'
import CopyTaskModal from './CopyTaskModal'
import MoveTaskModal from './MoveTaskModal'
import StatusTaskModal from './StatusTaskModal'
import ViewTask from './ViewTask'
import UpdateColumnModal from './UpdateColumnModal'

const BoardView = ({kanBoardData, projectId, currentlySelectedTaskList, rolePermissions}) => {
  const dispatch = useDispatch()

  const [taskId, setTaskId] = useState(null)
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [taskTitle, setTaskTitle] = useState('')
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)
  const [stageId, setStageId] = useState(null)
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false)
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [taskStatus, setTaskStatus] = useState('')
  const [isViewTaskModalOpen, setIsViewTaskModalOpen] = useState(false)
  const [idToUpdateColumn, setIdToUpdateColumn] = useState(null)
  const [isUpdateColumnModalOpen, setIsUpdateColumnModalOpen] = useState(false)
  const [idToDeleteColumn, setIdToDeleteColumn] = useState(null)
  const [isDeleteColumnModalOpen, setIsDeleteColumnModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECTS_TASKS_LIST_STAGE)) {
      setIdToUpdateColumn(null)
      setIsUpdateColumnModalOpen(false)
    }
  }, [successLabels])
  useEffect(() => {
    if (successLabels.includes(DELETE_PROJECTS_TASKS_LIST_STAGE)) {
      setIdToDeleteColumn(null)
      setIsDeleteColumnModalOpen(false)
    }
  }, [successLabels])

  const MyCard = ({id, title, metadata}) => {
    return (
      <KooperCard fluid style={metadata.isCompleted ? {backgroundColor: '#fceded'} : {}}>
        <KooperCardContent className="p-3">
          <div className="d-flex mb-2">
            <h5
              className="mb-0 d-flex"
              style={{cursor: 'pointer'}}
              onClick={() => {
                if (rolePermissions && !metadata.isCompleted) {
                  setTaskId(id)
                  setIsViewTaskModalOpen(true)
                }
              }}
            >
              <KooperTooltip
                size="mini"
                position="top left"
                content={metadata.isCompleted ? 'Mark task incomplete' : 'Mark task complete'}
                trigger={
                  <div
                    style={{flexShrink: 0}}
                    className={`mr-1 project-board-task-check ${metadata.isCompleted ? 'completed' : ''}`}
                    onClick={e => {
                      e.stopPropagation()
                      const data = {isCompleted: !metadata.isCompleted}
                      dispatch(completeProjectTasksListTask({projectId, taskId: id, data}))
                    }}
                  >
                    <KooperIcon name="check" />
                  </div>
                }
              />{' '}
              {title}
            </h5>
            {rolePermissions && !metadata.isCompleted && (
              <KooperDropdown direction="left" icon={<MdOutlineMoreVert />} className="icon">
                <KooperDropdownMenu>
                  <KooperDropdownItem
                    onClick={() => {
                      setTaskId(id)
                      setIsViewTaskModalOpen(true)
                    }}
                  >
                    <AiOutlineEye /> View Task
                  </KooperDropdownItem>
                  <KooperDropdownItem
                    onClick={() => {
                      setTaskId(id)
                      setIsAssigneeModalOpen(true)
                    }}
                  >
                    <SvgIcon path="settings/users" /> Manage People
                  </KooperDropdownItem>
                  <KooperDropdownItem
                    onClick={() => {
                      setTaskId(id)
                      setIsDeleteModalOpen(true)
                    }}
                  >
                    <SvgIcon path="common/delete" /> Delete
                  </KooperDropdownItem>
                  <KooperDropdownDivider />
                  <KooperDropdownItem
                    onClick={() => {
                      setTaskId(id)
                      setTaskTitle(title)
                      setStageId(metadata.stageId)
                      setIsCopyModalOpen(true)
                    }}
                  >
                    <SvgIcon path="common/clone" /> Create a copy
                  </KooperDropdownItem>
                  <KooperDropdownItem
                    onClick={() => {
                      setTaskId(id)
                      setTaskTitle(title)
                      setIsMoveModalOpen(true)
                    }}
                  >
                    <SvgIcon path="common/move" /> Move
                  </KooperDropdownItem>
                  <KooperDropdownDivider />
                  <KooperDropdownItem
                    onClick={() => {
                      setTaskId(id)
                      setTaskStatus(metadata.status)
                      setIsStatusModalOpen(true)
                    }}
                  >
                    {metadata.status === 'active' ? (
                      <>
                        <FiArchive /> Archieve
                      </>
                    ) : (
                      <>
                        <AiOutlineCheck /> Unarchive
                      </>
                    )}
                  </KooperDropdownItem>
                </KooperDropdownMenu>
              </KooperDropdown>
            )}
          </div>
          <div className="project-board-card-labels">
            {get(['labels'], metadata, []).map(label => (
              <KooperLabel key={label.id} color={label.color}>
                {label.label}
              </KooperLabel>
            ))}
          </div>
          <div className="d-flex">
            <KooperTooltip
              position="top left"
              size="mini"
              content={`Due Date : ${getFormattedDate(get(['endDate'], metadata))}`}
              trigger={get(['endDate'], metadata) && <div>{getFormattedDate(get(['endDate'], metadata))}</div>}
            />
            {get(['progressRate'], metadata) && <div>{get(['progressRate'], metadata)}</div>}
          </div>
          <div className="d-flex-js">
            <KooperTooltip
              position="top left"
              size="mini"
              content={`${metadata.assigneeDetails?.firstName} ${metadata.assigneeDetails?.lastName}`}
              trigger={
                <div className="discussion-users" style={{'--user-bg': 'green'}}>
                  {`${(metadata.assigneeDetails?.firstName?.charAt(0) || '').toUpperCase()}${(
                    metadata.assigneeDetails?.lastName?.charAt(0) || ''
                  ).toUpperCase()}`}
                </div>
              }
            />

            {rolePermissions && !metadata.isCompleted && (
              <KooperTooltip
                position="top center"
                size="mini"
                content="Add Assignee"
                trigger={
                  <KooperButton
                    icon
                    className="m-0"
                    onClick={e => {
                      e.stopPropagation()
                      setTaskId(id)
                      setIsAssigneeModalOpen(true)
                    }}
                  >
                    <SvgIcon path="common/plus" />
                  </KooperButton>
                }
              />
            )}
          </div>
          <div className="d-flex mt-2">
            {metadata.actualTime ? (
              <span className="project-board-task-time">Log: {metadata.actualTime}</span>
            ) : (
              <span />
            )}
            {metadata.estimateTime ? (
              <KooperTooltip
                position="top left"
                size="mini"
                content="Estimated time for task completion"
                trigger={<span className="project-board-task-time">Est: {metadata.estimateTime}</span>}
              />
            ) : (
              <span />
            )}
          </div>
        </KooperCardContent>
      </KooperCard>
    )
  }

  const CustomLaneHeader = ({title, label, color, laneId}) => {
    return (
      <div className="d-flex project-board-column-header" style={{borderTopColor: color}}>
        <h3>
          {startCase(title)} <KooperLabel circular>{label}</KooperLabel>
        </h3>
        <KooperDropdown direction="left" icon={<SvgIcon path="common/more-verticle" />} className="icon morePrev">
          <KooperDropdownMenu>
            <KooperDropdownItem
              onClick={() => {
                setIdToUpdateColumn(laneId)
                setIsUpdateColumnModalOpen(true)
              }}
            >
              <SvgIcon path="common/edit2" /> Edit
            </KooperDropdownItem>
            <KooperDropdownItem
              onClick={() => {
                setIdToDeleteColumn(laneId)
                setIsDeleteColumnModalOpen(true)
              }}
            >
              <SvgIcon path="common/delete" /> Delete
            </KooperDropdownItem>
          </KooperDropdownMenu>
        </KooperDropdown>
      </div>
    )
  }

  const CustomNewLaneSection = ({onClick}) => {
    return (
      <KooperButton basic onClick={onClick}>
        <SvgIcon path="common/plus" /> Add a column
      </KooperButton>
    )
  }

  const CustomNewLaneForm = ({onAdd, onCancel}) => {
    const {handleSubmit, control, errors} = useForm({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      resolver: joiResolver(
        Joi.object({
          name: Joi.string().trim().min(2).label('Column').required(),
          color: Joi.string().required(),
        })
      ),
      defaultValues: {
        name: '',
        color: 'blue',
      },
    })

    return (
      <KooperCard>
        <KooperCardContent>
          <KooperForm className="errorLabel">
            <KooperFormField>
              <Controller
                name="name"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormInput
                    maxLength={20}
                    placeholder="Column name"
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.name && {content: removeDoubleQuotes(errors.name.message)}}
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField>
              <Controller
                name="color"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormSelect
                    placeholder="Choose color"
                    options={colorOptions}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.color && {content: removeDoubleQuotes(errors.color.message)}}
                  />
                )}
              />
            </KooperFormField>
            <div>
              <KooperButton basic size="tiny" className="mr-3" onClick={onCancel}>
                Cancel
              </KooperButton>
              <KooperButton primary size="tiny" onClick={handleSubmit(onAdd)}>
                Save
              </KooperButton>
            </div>
          </KooperForm>
        </KooperCardContent>
      </KooperCard>
    )
  }

  const onCardDrag = (cardId, stageLandId, targetLaneId) => {
    if (targetLaneId === stageLandId) {
      return
    }

    const data = {
      stageId: targetLaneId,
    }

    dispatch(updateProjectsTasksListTaskStage({projectId, cardId, data}))
  }

  const onLaneDragEnd = (removedIndex, addedIndex) => {
    const data = {sourceId: removedIndex + 1, targetId: addedIndex + 1}
    dispatch(moveProjectsTasksListStage({projectId, data}))
  }

  return (
    <>
      <Board
        components={{
          Card: MyCard,
          LaneHeader: CustomLaneHeader,
          NewLaneSection: CustomNewLaneSection,
          NewLaneForm: CustomNewLaneForm,
        }}
        handleDragEnd={onCardDrag}
        laneStyle={{
          // width: '20%',
          margin: '0',
          borderRight: '1px solid #ebedf1',
          borderRadius: '4px',
          backgroundColor: '#F9FBFD',
          height: 'calc(100vh - 132px)',
          overflow: 'hidden',
        }}
        data={kanBoardData}
        cardDragClass="draggingCard"
        className="canBanView projectCanBanView"
        cardDraggable={rolePermissions}
        canAddLanes
        editable
        draggable
        laneDraggable
        onLaneAdd={data => dispatch(createProjectsTasksListStage({projectId, data}))}
        handleLaneDragEnd={onLaneDragEnd}
      />
      {isAssigneeModalOpen && (
        <AssigneeModal
          open={isAssigneeModalOpen}
          toggle={setIsAssigneeModalOpen}
          projectId={projectId}
          taskId={taskId}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={() => dispatch(deleteProjectsTasksListTask({projectId, taskId}))}
          modalText="Are you sure you want to delete the task?"
        />
      )}

      {isCopyModalOpen && (
        <CopyTaskModal
          open={isCopyModalOpen}
          toggle={setIsCopyModalOpen}
          taskTitle={taskTitle}
          projectId={projectId}
          taskListId={currentlySelectedTaskList}
          taskId={taskId}
          stageId={stageId}
        />
      )}

      {isMoveModalOpen && (
        <MoveTaskModal
          open={isMoveModalOpen}
          toggle={setIsMoveModalOpen}
          taskTitle={taskTitle}
          projectId={projectId}
          taskListId={currentlySelectedTaskList}
          taskId={taskId}
        />
      )}

      {isStatusModalOpen && (
        <StatusTaskModal
          open={isStatusModalOpen}
          toggle={setIsStatusModalOpen}
          taskStatus={taskStatus}
          projectId={projectId}
          taskId={taskId}
        />
      )}

      {isViewTaskModalOpen && (
        <ViewTask open={isViewTaskModalOpen} toggle={setIsViewTaskModalOpen} projectId={projectId} taskId={taskId} />
      )}

      {isUpdateColumnModalOpen && (
        <UpdateColumnModal
          open={isUpdateColumnModalOpen}
          toggle={setIsUpdateColumnModalOpen}
          projectId={projectId}
          stageId={idToUpdateColumn}
        />
      )}

      {isDeleteColumnModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteColumnModalOpen}
          setIsModalOpen={setIsDeleteColumnModalOpen}
          onDelete={() => dispatch(deleteProjectsTasksListStage({projectId, stageId: idToDeleteColumn}))}
          modalText={
            <>
              <p>
                You are about to delete the{' '}
                <b>{kanBoardData?.lanes?.find(lane => lane.id === idToDeleteColumn)?.title}</b> column.
              </p>
              <p>Once you delete, all your tasks will be deleted and this action can not be reversed. </p>
            </>
          }
        />
      )}
    </>
  )
}

export default BoardView
