/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react'
import {joiResolver} from '@hookform/resolvers'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {useHistory, useParams} from 'react-router'
import _ from 'lodash'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperContainer,
  KooperDivider,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormTextArea,
  KooperGrid,
  KooperGridColumn,
  KooperGridRow,
  KooperInput,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {removeDoubleQuotes} from 'utils/helper'
import {useDispatch, useSelector} from 'react-redux'
import {createAppraisalTemplate, getSingleAppraisalTemplate, updateAppraisalTemplate} from 'actions/appraisal'
import {CREATE_APPRAISAL_TEMPLATE, GET_APPRAISAL_TEMPLATE_DATA, UPDATE_APPRAISAL_TEMPLATE} from 'actions/types'
import {appraisalTemplateSchema, updateappraisalTemplateSchema} from 'validation/Hr/appraisal.schema'
import SvgIcon from 'components/common/SvgIcon'
import {APPRAISALS_PATH} from 'constants/path'
import {
  DescriptionFieldDefaultValue,
  DividerField,
  HeaderFieldDefaultValue,
  NumberBoxFieldDefaultValue,
  ratingFieldDefaultValue,
  SubHeaderFieldDefaultValue,
  TextAreaFieldDefaultValue,
  TextBoxFieldDefaultValue,
} from './defaultValues'
import AllSection from './SectionComponent/AllSection'

const BodyOptions = [
  {
    key: 'header',
    value: 'header',
    text: 'Header',
  },
  {
    key: 'sub_header',
    value: 'sub_header',
    text: 'Sub Header',
  },
  {
    key: 'description',
    value: 'description',
    text: 'Description',
  },
  {
    key: 'number_box',
    value: 'number_box',
    text: 'Number Box',
  },
  {
    key: 'text_box',
    value: 'text_box',
    text: 'Text Box',
  },
  {
    key: 'text_area',
    value: 'text_area',
    text: 'Text Area',
  },
  {
    key: 'rating',
    value: 'rating',
    text: 'Rating',
  },
  {
    key: 'divider',
    value: 'divider',
    text: 'Divider',
  },
]

const CreateAppraiselTemplate = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id: editId} = useParams()

  const [active, setActive] = useState({
    employeeName: true,
    employeeNumber: false,
    showDOB: false,
    showAge: true,
    showHiredDate: true,
    showYearsOfService: true,
    showCountryOfWork: false,
    showOrganisation: true,
    showDepartment: true,
    showPosition: true,
    enableWeightage: false,
    employeeSignoff: false,
  })
  const [activeSection, setActiveSection] = useState(0)
  const [type, setType] = useState('')
  const [indexFieldOption, setIndexFieldOption] = useState(null)
  const [indexSection, setIndexSection] = useState(null)
  const [totalScore, setTotalScore] = useState(0)
  const [toggleModal, setToggleModal] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {appraisalTemplateData} = useSelector(state => state.appraisal)

  const initialCreateInfo = {
    name: '',
    description: '',
    viewByEmployeeAfterSubmit: false,
    viewByReviewerAfterSubmit: false,
    showEmployeeName: true,
    showEmployeeNumber: false,
    showDateOfBirth: false,
    showAge: true,
    showDateOfJoin: true,
    showYearOfService: true,
    showCountryOfWork: false,
    showOrganisation: true,
    showDepartment: true,
    showPosition: true,
    templateSections: [],
    showWeightage: false,
    sectionWeightage: [],
  }

  const {errors, control, handleSubmit, watch, setValue, reset} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(editId ? updateappraisalTemplateSchema : appraisalTemplateSchema),
    defaultValues: initialCreateInfo,
  })

  const watchShowEmployeeName = watch('showEmployeeName')
  const watchShowEmployeeNumber = watch('showEmployeeNumber')
  const watchShowDateOfBirth = watch('showDateOfBirth')
  const watchShowAge = watch('showAge')
  const watchShowDateOfJoin = watch('showDateOfJoin')
  const watchShowYearOfService = watch('showYearOfService')
  const watchShowCountryOfWork = watch('showCountryOfWork')
  const watchShowOrganisation = watch('showOrganisation')
  const watchShowDepartment = watch('showDepartment')
  const watchShowPosition = watch('showPosition')
  const watchShowWeightage = watch('showWeightage')
  const WatchtemplateSections = watch('templateSections')

  const resetData = () => {
    if (successLabels.includes(GET_APPRAISAL_TEMPLATE_DATA)) {
      const {
        name,
        description,
        viewByEmployeeAfterSubmit,
        viewByReviewerAfterSubmit,
        showEmployeeNumber,
        showEmployeeName,
        showDateOfBirth,
        showAge,
        showDateOfJoin,
        showYearOfService,
        showCountryOfWork,
        showOrganisation,
        showDepartment,
        showPosition,
        templateSections,
        sectionWeightage,
        showWeightage,
      } = appraisalTemplateData
      if (templateSections) {
        templateSections.map((val, i) => {
          return (
            // delete templateSections[i]?.id,
            delete templateSections[i]?.accountId, delete templateSections[i]?.templateId
          )
        })
      }
      reset({
        name,
        description,
        viewByEmployeeAfterSubmit,
        viewByReviewerAfterSubmit,
        showEmployeeName,
        showEmployeeNumber,
        showDateOfBirth,
        showAge,
        showDateOfJoin,
        showYearOfService,
        showCountryOfWork,
        showOrganisation,
        showDepartment,
        showPosition,
        templateSections,
        sectionWeightage,
        showWeightage,
      })
    }
  }

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'templateSections',
  })

  const {
    fields: fieldsOptions,
    append: appendOptions,
    remove: removeOptions,
  } = useFieldArray({
    control,
    name: `templateSections[${activeSection}].sectionFields`,
  })

  useEffect(() => {
    if (editId) {
      dispatch(getSingleAppraisalTemplate(editId))
    }
  }, [editId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_APPRAISAL_TEMPLATE_DATA)) {
      resetData()
    }
  }, [successLabels, appraisalTemplateData])

  useEffect(() => {
    if (editId) {
      resetData()
    }
  }, [appraisalTemplateData, editId])

  const onAddField = field => {
    switch (field) {
      case 'header':
        appendOptions(HeaderFieldDefaultValue)
        break
      case 'sub_header':
        appendOptions(SubHeaderFieldDefaultValue)
        break
      case 'description':
        appendOptions(DescriptionFieldDefaultValue)
        break
      case 'number_box':
        appendOptions(NumberBoxFieldDefaultValue)
        break
      case 'text_box':
        appendOptions(TextBoxFieldDefaultValue)
        break
      case 'text_area':
        appendOptions(TextAreaFieldDefaultValue)
        break
      case 'divider':
        appendOptions(DividerField)
        break
      case 'rating':
        appendOptions(ratingFieldDefaultValue)
        break
      default:
        break
    }
    return null
  }

  useEffect(() => {
    if (successLabels.includes(CREATE_APPRAISAL_TEMPLATE) || successLabels.includes(UPDATE_APPRAISAL_TEMPLATE)) {
      history.push(`/${APPRAISALS_PATH.PREFIX}/${APPRAISALS_PATH.TEMPLATES}`)
    }
  }, [successLabels, history, dispatch])

  useEffect(() => {
    if (WatchtemplateSections) {
      for (const i in WatchtemplateSections) {
        const {sectionFields, sectionName} = WatchtemplateSections[i]
        let totalWeightage = 0
        for (const j in sectionFields) {
          if (
            sectionFields[j].fieldType === 'number_box' ||
            sectionFields[j].fieldType === 'text_box' ||
            sectionFields[j].fieldType === 'text_area'
          ) {
            totalWeightage += sectionFields[j].maxPoints
          }
        }
        setValue(`templateSections[${i}].weightage`, totalWeightage)
        setValue(`sectionWeightage[${i}].label`, sectionName)
        setValue(`sectionWeightage[${i}].value`, totalWeightage)
      }
      const totalAmountValue = WatchtemplateSections.reduce((acc, ite) => Number(acc) + Number(ite?.weightage), 0)

      setTotalScore(totalAmountValue)
    }
  }, [JSON.stringify(WatchtemplateSections)])

  const FieldOptionsBody = index => {
    return watch(`templateSections[${index}].sectionFields`).map((field, indexField) => {
      const watchInputFor = watch(
        `templateSections[${index}].sectionFields[${indexField}].fieldQuestions[${0}].inputFor`
      )
      const watchWithPoints = watch(`templateSections[${index}].sectionFields[${indexField}].withPoints`)
      const watchMaxPoints = watch(`templateSections[${index}].sectionFields[${indexField}].maxPoints`)
      const watchFieldType = watch(`templateSections[${index}].sectionFields[${indexField}].fieldType`)

      return (
        watchFieldType === field.fieldType && (
          <div className="sectionfields">
            <span>
              <b>{watch(`templateSections[${index}].sectionFields[${indexField}].fieldQuestions[${0}].question`)}</b>
            </span>

            <div className="d-flex" key={field.key} style={{justifyContent: 'flex-start'}}>
              {field.fieldType === 'header' ? (
                <h1>{watch(`templateSections[${index}].sectionFields[${indexField}].label`)}</h1>
              ) : field.fieldType === 'sub_header' ? (
                <h3>{watch(`templateSections[${index}].sectionFields[${indexField}].label`)}</h3>
              ) : field.fieldType === 'description' ? (
                <p>{watch(`templateSections[${index}].sectionFields[${indexField}].label`)}</p>
              ) : field.fieldType === 'number_box' ? (
                <>
                  {watchInputFor === 'both' || watchInputFor === 'employee' ? (
                    <KooperFormField required width={4} style={{marginLeft: '10px'}}>
                      <div className="info-header" style={{marginBottom: '0px'}}>
                        <label className="label-class">Filled by Employee</label>
                      </div>
                      <KooperFormInput placeholder="Enter Number Here" disabled />
                    </KooperFormField>
                  ) : null}
                  {watchInputFor === 'both' || watchInputFor === 'Reviewer' ? (
                    <KooperFormField required width={4} style={{marginLeft: '10px'}}>
                      <div className="info-header" style={{marginBottom: '0px'}}>
                        <label className="label-class">Filled by Reviewer</label>
                      </div>
                      <KooperFormInput placeholder="Enter Number Here" disabled />
                    </KooperFormField>
                  ) : null}
                  {watchWithPoints ? (
                    <>
                      <KooperFormField required width={2} style={{marginLeft: '10px'}}>
                        <div className="info-header" style={{marginBottom: '0px'}}>
                          <label className="label-class">Points</label>
                        </div>
                        <KooperFormInput disabled />
                      </KooperFormField>
                      <p>/{watchMaxPoints}</p>
                    </>
                  ) : null}
                </>
              ) : field.fieldType === 'text_box' ? (
                <>
                  {watchInputFor === 'both' || watchInputFor === 'employee' ? (
                    <KooperFormField required width={4} style={{marginLeft: '10px'}}>
                      <div className="info-header" style={{marginBottom: '0px'}}>
                        <label className="label-class">Filled by Employee</label>
                      </div>
                      <KooperFormInput placeholder="Enter Number Here" disabled />
                    </KooperFormField>
                  ) : null}
                  {watchInputFor === 'both' || watchInputFor === 'Reviewer' ? (
                    <KooperFormField required width={4} style={{marginLeft: '10px'}}>
                      <div className="info-header" style={{marginBottom: '0px'}}>
                        <label className="label-class">Filled by Reviewer</label>
                      </div>
                      <KooperFormInput placeholder="Enter Number Here" disabled />
                    </KooperFormField>
                  ) : null}
                  {watchWithPoints ? (
                    <>
                      <KooperFormField required width={2} style={{marginLeft: '10px'}}>
                        <div className="info-header" style={{marginBottom: '0px'}}>
                          <label className="label-class">Points</label>
                        </div>
                        <KooperFormInput disabled />
                      </KooperFormField>
                      <p>/{watchMaxPoints}</p>
                    </>
                  ) : null}
                </>
              ) : field.fieldType === 'text_area' ? (
                <>
                  {watchInputFor === 'both' || watchInputFor === 'employee' ? (
                    <KooperFormField required width={4} style={{marginLeft: '10px'}}>
                      <div className="info-header" style={{marginBottom: '0px'}}>
                        <label className="label-class">Filled by Employee</label>
                      </div>
                      <KooperFormInput placeholder="Enter Number Here" disabled />
                    </KooperFormField>
                  ) : null}
                  {watchInputFor === 'both' || watchInputFor === 'Reviewer' ? (
                    <KooperFormField required width={4} style={{marginLeft: '10px'}}>
                      <div className="info-header" style={{marginBottom: '0px'}}>
                        <label className="label-class">Filled by Reviewer</label>
                      </div>
                      <KooperFormInput placeholder="Enter Number Here" disabled />
                    </KooperFormField>
                  ) : null}
                  {watchWithPoints ? (
                    <>
                      <KooperFormField required width={2} style={{marginLeft: '10px'}}>
                        <div className="info-header" style={{marginBottom: '0px'}}>
                          <label className="label-class">Points</label>
                        </div>
                        <KooperFormInput disabled />
                      </KooperFormField>
                      <p>/{watchMaxPoints}</p>
                    </>
                  ) : null}
                </>
              ) : field.fieldType === 'divider' ? (
                <KooperDivider />
              ) : field.fieldType === 'rating' ? (
                <>
                  {watchInputFor === 'both' || watchInputFor === 'employee' ? (
                    <KooperFormField required width={4} style={{marginLeft: '10px'}}>
                      <div className="info-header" style={{marginBottom: '0px'}}>
                        <label className="label-class">Filled by Employee</label>
                      </div>
                      <KooperFormInput placeholder="Enter Number Here" disabled />
                    </KooperFormField>
                  ) : null}
                  {watchInputFor === 'both' || watchInputFor === 'Reviewer' ? (
                    <KooperFormField required width={4} style={{marginLeft: '10px'}}>
                      <div className="info-header" style={{marginBottom: '0px'}}>
                        <label className="label-class">Filled by Reviewer</label>
                      </div>
                      <KooperFormInput placeholder="Enter Number Here" disabled />
                    </KooperFormField>
                  ) : null}
                </>
              ) : null}
              <div className="d-flex" style={{marginLeft: 'auto', columnGap: 18}}>
                <KooperTooltip
                  content="Edit"
                  position="top center"
                  size="mini"
                  trigger={
                    <KooperButton
                      icon
                      className="m-0"
                      onClick={() => {
                        setType(field.fieldType)
                        setIndexFieldOption(indexField)
                        setIndexSection(index)
                        setToggleModal(field.fieldType)
                      }}
                    >
                      <SvgIcon path="common/edit2" />
                    </KooperButton>
                  }
                />
                <KooperTooltip
                  content="Delete"
                  position="top center"
                  size="mini"
                  trigger={
                    <KooperButton
                      icon
                      className="m-0"
                      onClick={e => {
                        e.stopPropagation()
                        setType('')
                        setIndexFieldOption(null)
                        removeOptions(indexField)
                      }}
                    >
                      <SvgIcon path="common/delete" />
                    </KooperButton>
                  }
                />
              </div>
            </div>
          </div>
        )
      )
    })
  }

  const submitForm = data => {
    const newList = _.omitBy(data, value => !!(value === '' || value === null || _.isEqual(value, [])))

    if (editId) {
      return dispatch(updateAppraisalTemplate(editId, newList))
    }
    dispatch(createAppraisalTemplate(newList))
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <div>
          <KooperButton primary onClick={() => history.push(`/${APPRAISALS_PATH.PREFIX}/${APPRAISALS_PATH.TEMPLATES}`)}>
            Exit
          </KooperButton>
        </div>
        <h3 className="m-0">Create New Template</h3>
        <div>
          <KooperButton primary onClick={handleSubmit(submitForm)}>
            {editId ? 'Update' : 'Create'}
          </KooperButton>
        </div>
      </div>

      <KooperForm className="errorLabel">
        <KooperContainer>
          <KooperCard fluid>
            <KooperCardContent>
              <h3 className="mb-0">Appraisal Template</h3>
              <p className="mt-0 mb-4 card-description">
                Create appraisal template by mentioning all the required information
              </p>

              <KooperFormField width="8" required>
                <label>Name</label>
                <Controller
                  name="name"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperFormInput
                      type="text"
                      placeholder="Name"
                      maxLength={20}
                      onChange={(e, {value}) => onChange(value)}
                      value={value}
                      error={
                        errors.name && {
                          content: removeDoubleQuotes(errors.name.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>

              <KooperFormField width="8" required>
                <label>Description</label>
                <Controller
                  name="description"
                  render={({onChange, value}) => (
                    <KooperFormTextArea
                      maxLength={200}
                      type="text"
                      value={value}
                      placeholder="Description"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors.description && {
                          content: removeDoubleQuotes(errors.description.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>

              <KooperFormField>
                <Controller
                  control={control}
                  name="viewByEmployeeAfterSubmit"
                  render={({onChange, value}) => (
                    <KooperFormField width={8}>
                      <div className="d-flex">
                        <div>
                          <label className="m-0">Employee able to view appraisal after submitting?</label>
                          <p className="kooper-lead m-0">Employee can view appraisal after successful submission</p>
                        </div>
                        <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                      </div>
                    </KooperFormField>
                  )}
                />
              </KooperFormField>

              <KooperFormField width={8}>
                <Controller
                  control={control}
                  name="viewByReviewerAfterSubmit"
                  render={({onChange, value}) => (
                    <KooperFormField>
                      <div className="d-flex">
                        <div>
                          <label className="m-0">Reviewer able to view after submitting?</label>
                          <p className="kooper-lead m-0">Reviewers can view appraisal after submission</p>
                        </div>
                        <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                      </div>
                    </KooperFormField>
                  )}
                />
              </KooperFormField>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent>
              <h3 className="mb-0">Header Options</h3>
              <p className="mt-0 mb-4 card-description">Select the header options for your appraisal template</p>

              <KooperGrid>
                <KooperGridColumn width="4">
                  <div className="appraiselHeaderoptions">
                    <Controller
                      control={control}
                      name="showEmployeeName"
                      render={({onChange, value}) => (
                        <KooperCheckbox
                          checked={value}
                          onChange={(e, {checked}) => onChange(checked)}
                          label="Show Employee Name"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="showEmployeeNumber"
                      render={({onChange, value}) => (
                        <KooperCheckbox
                          checked={value}
                          onChange={(e, {checked}) => onChange(checked)}
                          label="Show Employee Number"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="showDateOfBirth"
                      render={({onChange, value}) => (
                        <KooperCheckbox
                          checked={value}
                          onChange={(e, {checked}) => onChange(checked)}
                          label="Show Date of Birth"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="showAge"
                      render={({onChange, value}) => (
                        <KooperCheckbox
                          checked={value}
                          onChange={(e, {checked}) => onChange(checked)}
                          label="Show Age"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="showDateOfJoin"
                      render={({onChange, value}) => (
                        <KooperCheckbox
                          checked={value}
                          onChange={(e, {checked}) => onChange(checked)}
                          label="Show Date Of Join"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="showYearOfService"
                      render={({onChange, value}) => (
                        <KooperCheckbox
                          checked={value}
                          onChange={(e, {checked}) => onChange(checked)}
                          label="Show Years of Service"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="showCountryOfWork"
                      render={({onChange, value}) => (
                        <KooperCheckbox
                          checked={value}
                          onChange={(e, {checked}) => onChange(checked)}
                          label="Show Country of work"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="showOrganisation"
                      render={({onChange, value}) => (
                        <KooperCheckbox
                          checked={value}
                          onChange={(e, {checked}) => onChange(checked)}
                          label="Show Organisation"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="showDepartment"
                      render={({onChange, value}) => (
                        <KooperCheckbox
                          checked={value}
                          onChange={(e, {checked}) => onChange(checked)}
                          label="Show Department"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="showPosition"
                      render={({onChange, value}) => (
                        <KooperCheckbox
                          checked={value}
                          onChange={(e, {checked}) => onChange(checked)}
                          label="Show Position"
                        />
                      )}
                    />
                  </div>
                </KooperGridColumn>

                <KooperGridColumn width="12">
                  <div className="appraiselHeaderOptions">
                    {watchShowEmployeeName ? (
                      <KooperFormField>
                        <label>Employee Name</label>
                        <KooperInput placeholder="Employee's name" disabled="disabled" />
                      </KooperFormField>
                    ) : null}

                    {watchShowEmployeeNumber ? (
                      <KooperFormField>
                        <label>Employee Number</label>
                        <KooperInput placeholder="Employee's number" disabled="disabled" />
                      </KooperFormField>
                    ) : null}

                    {watchShowDateOfBirth ? (
                      <KooperFormField>
                        <label>Date of Birth</label>
                        <KooperInput placeholder="Date Of Birth" disabled="disabled" />
                      </KooperFormField>
                    ) : null}

                    {watchShowAge ? (
                      <KooperFormField>
                        <label>Age</label>
                        <KooperInput placeholder="Age" disabled="disabled" />
                      </KooperFormField>
                    ) : null}

                    {watchShowDateOfJoin ? (
                      <KooperFormField>
                        <label>Date Of Join</label>
                        <KooperInput placeholder="Date Of Join" disabled="disabled" />
                      </KooperFormField>
                    ) : null}

                    {watchShowYearOfService ? (
                      <KooperFormField>
                        <label>Year of Service</label>
                        <KooperInput placeholder="Year Of Service" disabled="disabled" />
                      </KooperFormField>
                    ) : null}

                    {watchShowCountryOfWork ? (
                      <KooperFormField>
                        <label>Country of Work</label>
                        <KooperInput placeholder="Country of Work" disabled="disabled" />
                      </KooperFormField>
                    ) : null}

                    {watchShowOrganisation ? (
                      <KooperFormField>
                        <label>Organization</label>
                        <KooperInput placeholder="Organization" disabled="disabled" />
                      </KooperFormField>
                    ) : null}

                    {watchShowDepartment ? (
                      <KooperFormField>
                        <label>Department</label>
                        <KooperInput placeholder="Department" disabled="disabled" />
                      </KooperFormField>
                    ) : null}

                    {watchShowPosition ? (
                      <KooperFormField>
                        <label>Position</label>
                        <KooperInput placeholder="Position" disabled="disabled" />
                      </KooperFormField>
                    ) : null}
                  </div>
                </KooperGridColumn>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent>
              <h3 className="mb-0">Body Options</h3>
              <p className="mt-0 mb-4 card-description">Select the body options for your appraisal template</p>

              <KooperGrid>
                <KooperGridColumn width="4" style={{paddingRight: 24}}>
                  <div className="appraiselBodyOptions">
                    {BodyOptions.map(types => (
                      <KooperButton onClick={() => onAddField(types.value)} disabled={fields.length < 1 && true}>
                        {types.text}
                      </KooperButton>
                    ))}
                  </div>
                </KooperGridColumn>
                <KooperGridColumn width="12">
                  {fields.length < 1 && (
                    <>
                      <h5>Add a section to start a form</h5>
                      <div className="d-flex" style={{justifyContent: 'flex-start', columnGap: 18}}>
                        <KooperTooltip
                          size="mini"
                          position="top center"
                          content="Add"
                          trigger={
                            <KooperButton
                              icon
                              circular
                              onClick={() => {
                                append({indexNo: 0, sectionName: `Section 1`, sectionFields: []})
                                setIndexSection(0)
                              }}
                            >
                              <SvgIcon path="common/plus" />
                            </KooperButton>
                          }
                        />
                      </div>
                    </>
                  )}
                  {fields.map((field, index) => {
                    return (
                      <>
                        <div
                          className="section-area"
                          style={activeSection === index ? {border: '2px solid green'} : null}
                          onClick={() => setActiveSection(index)}
                          key={field.index}
                        >
                          <p style={{textAlign: 'center'}}>{field.sectionName}</p>
                          {FieldOptionsBody(index)}
                        </div>
                        {index === fields.length - 1 && (
                          <div
                            className="d-flex"
                            style={{
                              justifyContent: 'flex-start',
                              columnGap: 18,
                            }}
                          >
                            <KooperTooltip
                              size="mini"
                              position="top center"
                              content="Add"
                              trigger={
                                <KooperButton
                                  icon
                                  className="m-0"
                                  onClick={() => {
                                    append({
                                      indexNo: index + 1,
                                      sectionName: `Section ${index + 2}`,
                                      sectionFields: [],
                                    })
                                    setIndexSection(index + 1)
                                  }}
                                >
                                  <SvgIcon path="common/plus" />
                                </KooperButton>
                              }
                            />
                            <KooperTooltip
                              size="mini"
                              position="top center"
                              content="Remove"
                              trigger={
                                <KooperButton icon className="m-0" onClick={() => remove(index)}>
                                  <SvgIcon path="common/delete" />
                                </KooperButton>
                              }
                            />
                          </div>
                        )}
                      </>
                    )
                  })}
                </KooperGridColumn>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent>
              <h3 className="mb-0">Footer Options</h3>
              <p className="mt-0 mb-4 card-description">Select footer options for you appraisal</p>

              <KooperGrid>
                <KooperGridRow>
                  <KooperGridColumn width="4">
                    <div className="appraiselFooteroptions">
                      <Controller
                        control={control}
                        name="showWeightage"
                        render={({onChange, value}) => (
                          <KooperCheckbox
                            checked={value}
                            onChange={(e, {checked}) => onChange(checked)}
                            label="Enable Weightage"
                          />
                        )}
                      />
                      <KooperCheckbox
                        checked={active.employeeSignoff}
                        onClick={() =>
                          setActive({
                            ...active,
                            employeeSignoff: !active.employeeSignoff,
                          })
                        }
                        label="Employee sign off required?"
                      />
                    </div>
                  </KooperGridColumn>
                  {watchShowWeightage ? (
                    <>
                      <KooperGridColumn width="8">
                        <KooperTable sortable basic selectable>
                          <KooperTableHeader>
                            <KooperTableHeaderCell>#</KooperTableHeaderCell>
                            <KooperTableHeaderCell>Description</KooperTableHeaderCell>
                            <KooperTableHeaderCell>Score Achieved</KooperTableHeaderCell>
                            <KooperTableHeaderCell>Weightage Achieved</KooperTableHeaderCell>
                          </KooperTableHeader>
                          <KooperTableBody>
                            <KooperTableRow>
                              <KooperTableCell>1</KooperTableCell>
                              <KooperTableCell>Section 1</KooperTableCell>
                              <KooperTableCell>0 / 40</KooperTableCell>
                              <KooperTableCell>0 / 100</KooperTableCell>
                            </KooperTableRow>
                            <KooperTableRow>
                              <KooperTableCell>2</KooperTableCell>
                              <KooperTableCell>Section 2</KooperTableCell>
                              <KooperTableCell>0 / 0</KooperTableCell>
                              <KooperTableCell>0 / 0</KooperTableCell>
                            </KooperTableRow>
                          </KooperTableBody>
                        </KooperTable>
                      </KooperGridColumn>
                      <KooperGridColumn width="4">
                        <h5>Enter Weightage</h5>

                        <KooperFormField>
                          <label>Section 1</label>
                          <KooperInput label={{basic: true, content: '%'}} labelPosition="right" style={{width: 230}} />
                        </KooperFormField>
                        <KooperFormField>
                          <label>Section 2</label>
                          <KooperInput label={{basic: true, content: '%'}} labelPosition="right" style={{width: 230}} />
                        </KooperFormField>
                      </KooperGridColumn>
                    </>
                  ) : (
                    <KooperGridColumn width="8">
                      {WatchtemplateSections.map(({sectionName, weightage}) => {
                        return (
                          <h5 className="mb-3 mt-0">
                            {sectionName} : <span>0 / {weightage}</span>
                          </h5>
                        )
                      })}
                      <h5 className="mb-3 mt-0">
                        Total Score : <span>0 / {totalScore}</span>
                      </h5>
                    </KooperGridColumn>
                  )}
                </KooperGridRow>

                {active.employeeSignoff ? (
                  <KooperGridRow>
                    <KooperGridColumn width="4" />
                    <KooperGridColumn width="12">
                      <KooperButton primary>Employee Sign off</KooperButton>
                    </KooperGridColumn>
                  </KooperGridRow>
                ) : null}
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>
        </KooperContainer>
      </KooperForm>
      {(indexFieldOption === 0 || indexFieldOption) && type && (
        <div className="end-part">
          <AllSection
            type={type}
            data={`templateSections[${indexSection}].sectionFields`}
            control={control}
            indexSection={indexSection}
            index={indexFieldOption}
            errors={errors}
            watch={watch}
            setValue={setValue}
            reset={reset}
            toggleModal={toggleModal}
            setToggleModal={setToggleModal}
            activeSection={activeSection}
          />
        </div>
      )}
    </div>
  )
}

export default CreateAppraiselTemplate
