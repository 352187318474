import React from 'react'
import {
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperDropdown,
  KooperForm,
  KooperFormDropdown,
  KooperFormField,
  KooperFormInput,
  KooperFormTextArea,
  KooperTabPane,
  KooperTooltip,
} from 'kooper-library'
import {KooperCountryList} from 'utils/helper'
import {contactToken} from 'constants/sales/meeting'
import SvgIcon from 'components/common/SvgIcon'

const Configuration = props => {
  const {errors, control, Controller, getValues, setValue, style} = props
  return (
    <KooperTabPane style={style}>
      <KooperContainer>
        <KooperCard fluid>
          <KooperCardContent>
            <h3 className="mb-0">Location & Video link</h3>
            <p className="mt-0 mb-4 kooper-lead">Manage your location of the meeting settings</p>
            <KooperForm className="mt-4 errorLabel">
              <KooperFormField width={12}>
                <div className="info-header">
                  <label>Loaction</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Where will you connect with your invitee? is there a dial-in number they should call? will they visit your office?"
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="meetingLocation"
                  render={({onChange, value}) => (
                    <KooperFormInput
                      type="text"
                      fluid
                      value={value}
                      placeholder="Meeting Location"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors.meetingLocation && {
                          content: errors.meetingLocation.message,
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
              <KooperFormField width={12}>
                <div className="info-header">
                  <label>Add Video conference link</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="By Connecting your video conference app, a unique Video conference link will be created for each meeting that's booked"
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="videoConferenceLink"
                  render={({onChange, value}) => (
                    <KooperFormInput
                      type="text"
                      fluid
                      value={value}
                      placeholder="Video Link"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors.videoConferenceLink && {
                          content: errors.videoConferenceLink.message,
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
            </KooperForm>
          </KooperCardContent>
        </KooperCard>
        <KooperCard fluid>
          <KooperCardContent>
            <h3 className="mb-0">Invite User</h3>
            <p className="mt-0 mb-4 kooper-lead">
              Set the settings of the meeting invitation which will be sent to user
            </p>
            <KooperForm className="mt-4 errorLabel">
              <KooperFormField width={12}>
                <div className="info-header">
                  <label>Invite subject</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="This is the name of the calender event you share with your prospect or Customer. for example, 'Product demo with Hardik Nariya'"
                    size="mini"
                    position="top center"
                  />
                </div>
                <div className="scoring-rules-drp mb-3">
                  <KooperDropdown
                    style={{width: '109px'}}
                    button
                    className="basic"
                    options={contactToken}
                    forceSelection={false}
                    selectOnBlur={false}
                    onChange={(e, {value}) => {
                      const contactToken = getValues('inviteSubject')
                      setValue('inviteSubject', `${contactToken} ${value} `)
                    }}
                    text="Insert Field"
                  />
                </div>
                <Controller
                  name="inviteSubject"
                  render={({onChange, value}) => (
                    <KooperFormInput
                      type="text"
                      fluid
                      value={value}
                      placeholder="Invite Subject"
                      onChange={(e, {value}) => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors.inviteSubject && {
                          content: errors.inviteSubject.message,
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
              <KooperFormField width={12}>
                <div className="info-header">
                  <label>Invite Description</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="This is the name of the calender event you share with your prospect or Customer. for example, 'Product demo with Hardik Nariya'"
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="inviteDescription"
                  render={({onChange, value}) => (
                    <KooperFormTextArea
                      type="text"
                      fluid
                      value={value}
                      placeholder="Enter Description"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors.inviteDescription && {
                          content: errors.inviteDescription.message,
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
              {/* <KooperFormField width={5}>
          <label>Booking page language</label>
          <p className="kooper-lead">
            If a visitor's browser setting can't be detected, they will see your
            meeting link English.
          </p>
          <Controller
            name="meetingLanguage"
            render={({ onChange, value }) => (
              <KooperFormDropdown
                placeholder="Select language"
                search
                selection
                value={value}
                options={language}
                onChange={(e, { value }) => {
                  onChange(value);
                }}
                error={
                  errors.meetingLanguage && {
                    content: errors.meetingLanguage.message,
                  }
                }
              />
            )}
            control={control}
          />
        </KooperFormField> */}
              <KooperFormField width={12}>
                <div className="info-header">
                  <label>Date and Time format</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="If a visitor's browser setting can't be detected, they will see date and time in US format."
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="meetingDateAndTimeFormat"
                  render={({onChange, value}) => (
                    <KooperFormDropdown
                      placeholder="Select language"
                      search
                      selection
                      value={value}
                      options={KooperCountryList}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors.meetingDateAndTimeFormat && {
                          content: errors.meetingDateAndTimeFormat.message,
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
            </KooperForm>
          </KooperCardContent>
        </KooperCard>
      </KooperContainer>
    </KooperTabPane>
  )
}

export default Configuration
