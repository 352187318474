import {getProjectsTasksList} from 'actions/projects'
import CreateFirst from 'components/common/CreateFirst'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperButton,
  KooperHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableFooter,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
} from 'kooper-library'
import {UPDATE_PROJECT_FINANCE_TASKS_LIST_BUDGET} from 'actions/types'
import {startCase} from 'utils/helper'
import ProjectBudgetTasklistBudgetModal from './ProjectBudgetTasklistBudgetModal'

const ProjectBudgetTasklistBudget = ({projectId}) => {
  const dispatch = useDispatch()

  const [tasklistBudgetModalOpen, setTasklistBudgetModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {tasksList = []} = useSelector(state => state.projects)

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECT_FINANCE_TASKS_LIST_BUDGET)) {
      setTasklistBudgetModalOpen(false)
      dispatch(getProjectsTasksList(projectId))
    }
  }, [successLabels, dispatch, projectId])

  const getCapacityUsedPercentage = (capacity, capacityUsed) => {
    return (capacityUsed / capacity) * 100
  }

  const renderTasklistBudgetModal = () => {
    if (tasklistBudgetModalOpen) {
      return (
        <ProjectBudgetTasklistBudgetModal
          open={tasklistBudgetModalOpen}
          toggle={setTasklistBudgetModalOpen}
          projectId={projectId}
        />
      )
    }
  }

  const renderTableBody = () => {
    return tasksList
      .filter(tasklist => +tasklist.capacity > 0)
      .map(({id, title, capacity = 0, capacityUsed = 0}) => (
        <KooperTableRow className="tableLink" key={id}>
          <KooperTableCell>
            <KooperHeader as="h5">{startCase(title)}</KooperHeader>
          </KooperTableCell>
          <KooperTableCell>${capacity}</KooperTableCell>
          <KooperTableCell>
            <div className="d-flex">
              <span>{getCapacityUsedPercentage(capacity, capacityUsed || 0)}%</span>
              <span>
                ${capacityUsed || 0} of ${capacity}
              </span>
            </div>
            <div className="budget-progress">
              {getCapacityUsedPercentage(capacity, capacityUsed || 0) <= 100 ? (
                <div
                  className="budget-progress-completed"
                  style={{width: `${getCapacityUsedPercentage(capacity, capacityUsed || 0)}%`}}
                />
              ) : (
                <div className="budget-progress-overflow" />
              )}
            </div>
          </KooperTableCell>
        </KooperTableRow>
      ))
  }

  const getTotalAssigned = () => tasksList.reduce((acc, item) => (item.capacity ? acc + item.capacity : acc), 0)

  if (tasksList.filter(tasklist => +tasklist.capacity > 0).length === 0) {
    return (
      <>
        <CreateFirst
          header="Tasklist Budgets"
          subHeader="Efficiently create and assign budgets for all tasks within the project"
          addFirst={() => setTasklistBudgetModalOpen(true)}
          buttonText="Create Tasklist Budget"
          Feature1="Budget creation"
          Feature2="Budget Alert"
          Feature3="Reporting"
          list1="Create budgets for individual tasks within a project."
          list2="Set task budget alerts to prevent overspending"
          list3="Generate detailed reports on task budgets and expenses"
        />
        {renderTasklistBudgetModal()}
      </>
    )
  }

  return (
    <>
      <div className="project-finance-budget-expense-action">
        <KooperButton primary onClick={() => setTasklistBudgetModalOpen(true)}>
          Assign Tasklist Budget
        </KooperButton>
      </div>

      <KooperTable basic>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>Task list</KooperTableHeaderCell>
            <KooperTableHeaderCell>Budget</KooperTableHeaderCell>
            <KooperTableHeaderCell style={{width: '300px'}}>Progress</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>{renderTableBody()}</KooperTableBody>
        <KooperTableFooter>
          <KooperTableRow>
            <KooperTableCell>
              <strong>Total Assigned</strong>
            </KooperTableCell>
            <KooperTableCell>
              <strong>${getTotalAssigned()}</strong>
            </KooperTableCell>
            <KooperTableCell colSpan="2" />
          </KooperTableRow>
        </KooperTableFooter>
      </KooperTable>

      {renderTasklistBudgetModal()}
    </>
  )
}

export default ProjectBudgetTasklistBudget
