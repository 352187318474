import {joiResolver} from '@hookform/resolvers'
import {get, removeDoubleQuotes} from 'utils/helper'
import {getUserInfo} from 'utils/local-storage'
import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'
import {addInbox} from 'actions/inbox'
import {ADD_INBOX} from 'actions/types'
import {emailSetupSchema} from 'validation/Settings/channels/emailInbox.schema'
import SvgIcon from 'components/common/SvgIcon'

const EmailSetupModal = ({open, toggle, assigneeOptionsList, teamOptionsList, setCurrentModal, setAddNewModal}) => {
  const dispatch = useDispatch()
  const userId = Number(get(['id'], getUserInfo()))

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {handleSubmit, errors, control} = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    defaultValues: {
      inboxName: '',
      accountEmail: '',
      assignee: [userId],
      teamIds: [],
    },
    resolver: joiResolver(emailSetupSchema),
  })

  useEffect(() => {
    if (successLabels.includes(ADD_INBOX)) {
      setCurrentModal('')
      setAddNewModal(false)
    }
  }, [successLabels, setCurrentModal, setAddNewModal])

  const submitForm = ({accountEmail, inboxName, assignee, teamIds}) => {
    const newData = {
      email: accountEmail,
      name: inboxName,
      assigneeIds: assignee,
      isSetup: true,
      teamIds,
    }
    dispatch(addInbox(newData))
  }

  return (
    <KooperModal
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={open}
      onClose={() => setAddNewModal(false)}
      toggle={toggle}
    >
      <KooperModalHeader>Create New Email Inbox</KooperModalHeader>
      <KooperModalContent className="emlIbxSetupModal">
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label htmlFor="inboxName" className="label-class">
                Mailbox Name
              </label>
              <KooperTooltip
                content="Give a name to your inbox"
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="right center"
              />
            </div>
            <Controller
              name="inboxName"
              control={control}
              render={({onChange, value}) => (
                <KooperFormInput
                  maxLength={30}
                  placeholder="Enter Inbox Name"
                  id="inboxName"
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.inboxName && {
                      content: removeDoubleQuotes(errors.inboxName.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>

          <KooperFormField required>
            <div className="info-header">
              <label htmlFor="accountEmail" className="label-class">
                Email
              </label>
              <KooperTooltip
                content="Add Emails to inbox"
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              name="accountEmail"
              control={control}
              render={({onChange, value}) => (
                <KooperFormInput
                  placeholder="Enter Email"
                  id="accountEmail"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.accountEmail && {
                      content: removeDoubleQuotes(errors.accountEmail.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>

          <KooperFormField required>
            <div className="info-header">
              <label htmlFor="assignee" className="label-class">
                Assignee
              </label>
              <KooperTooltip
                content="Assign team members to inbox"
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="top left"
              />
            </div>
            <Controller
              name="assignee"
              control={control}
              render={({onChange, value}) => (
                <KooperFormSelect
                  placeholder="Select Assignee"
                  search
                  multiple
                  id="assignee"
                  options={assigneeOptionsList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.assignee && {
                      content: removeDoubleQuotes(errors.assignee.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>

          <KooperFormField>
            <label htmlFor="teamIds">Team</label>
            <Controller
              name="teamIds"
              control={control}
              render={({onChange, value}) => (
                <KooperFormSelect
                  placeholder="Select Team"
                  multiple
                  search
                  id="teamIds"
                  options={teamOptionsList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.teamIds && {
                      content: removeDoubleQuotes(errors.teamIds.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>

      <KooperModalActions>
        <KooperButton basic onClick={() => toggle()}>
          Back
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Create
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default EmailSetupModal
