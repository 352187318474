import React from 'react'
import {useDispatch} from 'react-redux'
import {KooperButton, KooperModal, KooperModalActions, KooperModalContent, KooperModalHeader} from 'kooper-library'
import {deleteProjectFinanceBudget} from 'actions/projects'
import SvgIcon from 'components/common/SvgIcon'

const DeleteProjectBudgetModal = ({open, toggle, projectId, budgetId}) => {
  const dispatch = useDispatch()

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Delete Budget</KooperModalHeader>
      <KooperModalContent>
        <p>
          Are you sure you want to delete this budget? All historical data will be removed, this action cannot be
          undone.
        </p>
        <p>Alternatively you can choose to end the current budget, and the historical data will remain.</p>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton color="red" onClick={() => dispatch(deleteProjectFinanceBudget({projectId, budgetId}))}>
          Delete this budget
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default DeleteProjectBudgetModal
