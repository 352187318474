/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import {
  KooperButton,
  KooperHeader,
  KooperTable,
  KooperTableHeader,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperModal,
  KooperModalHeader,
  KooperModalContent,
  KooperForm,
  KooperFormInput,
  KooperModalActions,
  KooperTooltip,
  KooperFormField,
} from 'kooper-library'

import {ADD_LOST_REASONS, DELETE_LOST_REASONS, GET_LOST_REASONS, UPDATE_LOST_REASONS} from 'actions/types'
import create from 'assets/create.svg'
import SettingLayout from 'layouts/settingLayout'
import DeleteModal from 'components/common/DeleteModal'
import {addLostReasons, deleteLostReasons, getLostReasons, updateLostReasons} from 'actions/custom_options'
import {getPermissionTooltipContent, removeDoubleQuotes, startCase} from 'utils/helper'
import CreateFirst from 'components/common/CreateFirst'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const LossReason = () => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(
      Joi.object({
        reason: Joi.string().trim().max(30).required().label('reason'),
      })
    ),
    defaultValues: {
      reason: '',
    },
  })

  const [modal, setModal] = useState(false)
  const [idToEdit, setIdToEdit] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const manageReasonLearnMore = settingsLearnMoreUrl?.dataManagement?.customization?.lossReason?.learnMore

  const {isLoading} = useApiLoader({label: GET_LOST_REASONS})
  const {generalAccess, customizationPermissions} = useUserPermissions()
  const dataManageCustomizationAccess = generalAccess?.dataManageCustomization
  const manageLossReasonsPermissions = customizationPermissions?.manage_loss_reasons

  const {data: lostReasonsData = []} = useApiResponse({
    action: getLostReasons,
    dependency: [dataManageCustomizationAccess],
    enabled: dataManageCustomizationAccess,
    label: GET_LOST_REASONS,
    storePath: 'custom_option.lostReasonsData',
  })

  useEffect(() => {
    if (
      successLabels.includes(ADD_LOST_REASONS) ||
      successLabels.includes(UPDATE_LOST_REASONS) ||
      successLabels.includes(DELETE_LOST_REASONS)
    ) {
      setModal(false)
      setIdToEdit(null)
      dispatch(getLostReasons())
      reset({reason: ''})
    }
  }, [successLabels])

  const onModelClose = () => {
    setModal(false)
    setIdToEdit(null)
    reset({status: ''})
  }

  const CreateModal = () => {
    if (modal) {
      return (
        <KooperModal
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
          size="mini"
          centered={false}
          open={modal}
          onClose={onModelClose}
        >
          <KooperModalHeader >{idToEdit ? 'Update' : 'Create'} reason</KooperModalHeader>
          <KooperModalContent>
            <KooperForm className="errorLabel">
              <Controller
                control={control}
                name="reason"
                render={({value, onChange}) => (
                  <KooperFormField required>
                    <label>Reason</label>
                    <KooperFormInput
                      maxLength={20}
                      placeholder="Write reason.."
                      value={value}
                      onChange={e => onChange(e.target.value)}
                      error={
                        errors.reason && {
                          content: removeDoubleQuotes(errors.reason.message),
                        }
                      }
                    />
                  </KooperFormField>
                )}
              />
            </KooperForm>
          </KooperModalContent>
          <KooperModalActions>
            <KooperButton
              size="tiny"
              disabled={isLoadingData && (type.includes(ADD_LOST_REASONS) || type.includes(UPDATE_LOST_REASONS))}
              basic
              onClick={onModelClose}
            >
              Close
            </KooperButton>
            <LockPermissionTooltip isRoleAccessDenied={!manageLossReasonsPermissions}>
              <KooperButton
                primary
                size="tiny"
                loading={isLoadingData && (type.includes(ADD_LOST_REASONS) || type.includes(UPDATE_LOST_REASONS))}
                disabled={
                  !isDirty || (isLoadingData && (type.includes(ADD_LOST_REASONS) || type.includes(UPDATE_LOST_REASONS)))
                }
                {...(manageLossReasonsPermissions && {
                  onClick: handleSubmit(data => {
                    idToEdit ? dispatch(updateLostReasons(idToEdit, data)) : dispatch(addLostReasons(data))
                  }),
                })}
                className={!manageLossReasonsPermissions ? 'disabled-button' : ''}
              >
                {idToEdit ? 'Update' : 'Create'}
              </KooperButton>
            </LockPermissionTooltip>
          </KooperModalActions>
        </KooperModal>
      )
    }
  }

  const renderList = () => {
    return lostReasonsData.map((reason, index) => (
      <tr
        className="tableLink"
        onClick={() => {
          setIdToEdit(reason.id)
          setModal(true)
          reset({reason: reason.reason})
        }}
        key={reason.id}
      >
        <td className="pl-4">{index + 1}</td>
        <td>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <KooperHeader as="h5" className="m-0">
              {startCase(reason.reason || '-')}
            </KooperHeader>
          </div>
        </td>
        <td onClick={e => e.stopPropagation()}>
          <KooperTooltip
            content={
              manageLossReasonsPermissions
                ? 'Delete'
                : getPermissionTooltipContent({roleAccess: manageLossReasonsPermissions})
            }
            size="mini"
            position="top center"
            trigger={
              <KooperButton
                icon
                onClick={() => {
                  if (manageLossReasonsPermissions) {
                    setIdToEdit(reason.id)
                    setDeleteModal(true)
                  }
                }}
              >
                <SvgIcon path="common/delete" />
              </KooperButton>
            }
          />
        </td>
      </tr>
    ))
  }

  if (isLoading && dataManageCustomizationAccess) {
    return <PageLoader />
  }

  if (lostReasonsData.length === 0 || !dataManageCustomizationAccess) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          addFirst={() => dataManageCustomizationAccess && manageLossReasonsPermissions && setModal(true)}
          buttonText="Add Loss Reason"
          tooltip={!dataManageCustomizationAccess || !manageLossReasonsPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: dataManageCustomizationAccess,
            roleAccess: manageLossReasonsPermissions,
          })}
          img={create}
          header="Loss reasons"
          subHeader="Create list of reason why potential deals were not closed successfully"
          Feature1="Customization"
          Feature2="Analysis"
          Feature3="Follow-up"
          list1="Customize the list of loss categories for selecting common reasons"
          list2="Automatic trend analysis to highlight recurring issues in lost opportunities"
          list3="Merge with follow-up tasks to address and learn from lost deals"
        />
        {modal && CreateModal()}
      </div>
    )
  }

  return (
    <>
      {modal && CreateModal()}
      <SettingLayout
        icon={<SvgIcon path="settings/loss-reasons" />}
        header="Manage Loss Reasons"
        subHeader="Identify and Create sales lost reasons for better strategy development"
        learnMoreUrl={manageReasonLearnMore}
        headerButton={{
          onClick: () => manageLossReasonsPermissions && setModal(true),
          content: 'Create Loss Reason',
        }}
        table
        headerDivider
        lockRole={!manageLossReasonsPermissions}
      >
        <KooperTable basic selectable>
          <KooperTableHeader>
            <KooperTableRow>
              <th className="pl-4">#</th>
              <th>reason</th>
              <th>Action</th>
            </KooperTableRow>
          </KooperTableHeader>
          <tbody>
            {isLoadingData && type.includes(GET_LOST_REASONS) ? <KooperTablePlaceholder columns={3} /> : renderList()}
          </tbody>
        </KooperTable>

        {deleteModal && (
          <DeleteModal
            idTobeDeleted={idToEdit}
            deleteAction={deleteLostReasons}
            isModalOpen={deleteModal}
            setIsModalOpen={setDeleteModal}
            modalText="Are you sure you want to delete ?"
            type={DELETE_LOST_REASONS}
          />
        )}
      </SettingLayout>
    </>
  )
}
export default LossReason
