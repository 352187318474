import React from 'react'
import {getFormattedDate} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import {KooperCard, KooperCardContent, KooperCardDescription, KooperCardHeader, KooperCardMeta} from 'kooper-library'

const ProjectBillingInvoiceList = ({invoiceList = [], onListItemClick, onDeleteClick}) => {
  if (invoiceList.length === 0) {
    return (
      <KooperCard fluid>
        <KooperCardContent className="emptyValue">No Invoice Available</KooperCardContent>
      </KooperCard>
    )
  }

  return invoiceList.map(invoice => (
    <KooperCard fluid key={invoice.id} onClick={() => onListItemClick(invoice.id)}>
      <KooperCardContent>
        <KooperCardHeader className="mb-2" style={{display: 'flex', justifyContent: 'space-between'}}>
          {invoice.invoiceNumber}
          <SvgIcon
            path="common/delete"
            floated="right"
            onClick={e => {
              e.stopPropagation()
              onDeleteClick(invoice.id)
            }}
          />
        </KooperCardHeader>
        <KooperCardMeta style={{opacity: '0.6'}}>{getFormattedDate(invoice.issueDate)}</KooperCardMeta>
        <KooperCardDescription>{invoice.description || 'No description provided'}</KooperCardDescription>
      </KooperCardContent>
    </KooperCard>
  ))
}

export default ProjectBillingInvoiceList
