import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {KooperButton, KooperModal, KooperModalContent, KooperModalHeader} from 'kooper-library'

import {createPaymentMethods, getPaymentMethods} from 'actions/billing'
import {CREATE_PAYMENT_METHOD, DELETE_PAYMENT_METHOD, UPDATE_PAYMENT_METHOD} from 'actions/types'
import {cardDetailsSchema} from 'validation/Settings/billing.schema'
import SvgIcon from 'components/common/SvgIcon'
import AddCardForm from './AddCardForm'
import SavedCardList from './SavedCardList'
import CreditCardForm from './CreditCardForm'

const ChangeCardDetails = props => {
  const {isChangeCardDetail, setIsChangeCardDetail} = props

  const dispatch = useDispatch()

  const [addCard, setAddCard] = useState(false)

  const {successLabels = [], type = []} = useSelector(state => state.apiReducer)

  const {handleSubmit, errors, control, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(cardDetailsSchema),
    defaultValues: {
      number: '',
      expMonth: '',
      cvc: '',
      isDefault: true,
      cardHolderName: '',
    },
  })

  useEffect(() => {
    dispatch(getPaymentMethods())
  }, [dispatch])

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PAYMENT_METHOD) ||
      successLabels.includes(UPDATE_PAYMENT_METHOD) ||
      successLabels.includes(DELETE_PAYMENT_METHOD)
    ) {
      reset({
        number: '',
        expMonth: '',
        cvc: '',
        isDefault: true,
        cardHolderName: '',
      })
      dispatch(getPaymentMethods())
      setIsChangeCardDetail(false)
    }
  }, [successLabels, dispatch, reset, setIsChangeCardDetail])

  const handleSubmitCard = data => {
    const payload = {...data}
    const [month, year] = data.expMonth.split('/')
    payload.expMonth = month
    payload.expYear = `20${year}`
    dispatch(createPaymentMethods(payload))
  }

  return (
    <KooperModal
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isChangeCardDetail}
      onClose={() => setIsChangeCardDetail(false)}
    >
      <KooperModalHeader>Change Card Details</KooperModalHeader>
      <KooperModalContent>
        <h5>Saved Card</h5>
        <SavedCardList />

        <KooperButton basic onClick={() => setAddCard(!addCard)}>
          Add New Card
        </KooperButton>
        {addCard && (
          <>
            <h5 className="mt-4">Add New Card</h5>
            <CreditCardForm onCancelClick={() => setAddCard(false)} />

            {/* <AddCardForm control={control} errors={errors} />
            <KooperButton basic onClick={() => setAddCard(false)}>
              Cancel
            </KooperButton>
            <KooperButton
              className="ml-3"
              primary
              onClick={handleSubmit(handleSubmitCard)}
              disabled={type.includes(CREATE_PAYMENT_METHOD)}
              loading={type.includes(CREATE_PAYMENT_METHOD)}
            >
              Add
            </KooperButton> */}
          </>
        )}
      </KooperModalContent>
    </KooperModal>
  )
}

export default ChangeCardDetails
