import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  KooperButton,
  KooperCheckbox,
  KooperChipInput,
  KooperDropdown,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormSelect,
  KooperFormTextArea,
  KooperGrid,
  KooperGridColumn,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTooltip,
} from 'kooper-library'

import {CREATE_CONTACT} from 'actions/types'
import {addContacts} from 'actions/contacts'
import {createTagSettings} from 'actions/multiple_fields'
import {get, removeDoubleQuotes, KooperCountryList} from 'utils/helper'
import EmailCreateMultiple from 'components/common/EmailCreateMultiple'
import WebsiteCreateMultiple from 'components/common/WebsiteCreateMultiple'
import PhoneCreateMultiple from 'components/common/PhoneCreateMultiple'
import CreateLabel from 'components/common/CreateLabel'
import KooperCustomDropdown from 'components/common/KooperCustomDropdown'
import {createContactSchema} from 'validation/Customers/contacts.schema'
import {addSocialOptionList} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'

const CreateContactModal = props => {
  const dispatch = useDispatch()
  const {isModalOpen, setIsModalOpen} = props
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const initialCreateInfo = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    emails: [{category: 'work', email: ''}],
    phones: [{category: 'work', phone: ''}],
    title: '',
    website: '',
    websites: [{category: 'work', url: ''}],
    address: {},
    statusId: null,
    assigneeId: null,
    contactTypeId: null,
    socials: [{category: 'facebook', url: ''}],
    description: '',
    labelIds: [],
    allowPortalAccess: false,
  }
  const {handleSubmit, errors, control} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(createContactSchema),
    defaultValues: initialCreateInfo,
  })

  const [createInfo, setCreateInfo] = useState({})
  const [statusOptionList, setStatusOptionList] = useState([])
  const [labelOptionsState, setLabelOptionsState] = useState([])
  const [newLabelModal, setNewLabelModal] = useState(false)
  const [listOfLable, setListOfLabel] = useState([])
  const [userOption, setUserOption] = useState([])

  const [contactTypeList, setContactTypeList] = useState([])
  const [contactCompanyList, setContactCompanyList] = useState([])

  const {getTagList} = useSelector(state => state.multipleFields)
  const {
    contactStatusListData,
    getAllContactCompanyList,

    getTypeListData,
  } = useSelector(state => state.contacts)

  const {getUsersList = {}} = useSelector(state => state.settings)

  useEffect(() => {
    if (getTagList) {
      const list = getTagList?.map(user => ({
        value: user.id,
        text: user.label,
        key: user.id,
        label: {
          style: {background: user.color},
          empty: true,
          circular: true,
        },
      }))
      setLabelOptionsState(list)
    }
  }, [getTagList])
  useEffect(() => {
    if (getTypeListData) {
      const list = getTypeListData?.map(item => ({
        value: item?.id,
        text: item?.type,
        key: item?.id,
      }))
      setContactTypeList(list)
    }
  }, [getTypeListData])

  useEffect(() => {
    if (getAllContactCompanyList) {
      const companyList = getAllContactCompanyList?.map(list => ({
        key: list?.id,
        value: list?.id,
        text: list?.name,
      }))
      setContactCompanyList(companyList)
    }
  }, [getAllContactCompanyList])

  useEffect(() => {
    if (contactStatusListData) {
      const list = contactStatusListData?.map(item => ({
        value: item.id,
        text: item.status,
        key: item.id,
      }))
      setStatusOptionList(list)
    }
  }, [contactStatusListData])

  useEffect(() => {
    if (getUsersList) {
      const list = getUsersList.users?.map(user => ({
        value: user.id,
        text: `${get(['firstName'], user, '')} ${get(['lastName'], user, '')}`,
        id: user.id,
      }))
      setUserOption(list)
    }
  }, [getUsersList])

  const createNewLabel = () => {
    if (newLabelModal) {
      return (
        <CreateLabel
          isModalOpen={newLabelModal}
          setIsModalOpen={setNewLabelModal}
          createMethod={data => dispatch(createTagSettings(data))}
        />
      )
    }
  }

  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: 'emails',
  })

  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: 'phones',
  })

  const {
    fields: websiteFields,
    append: appendWebsite,
    remove: removeWebsite,
  } = useFieldArray({
    control,
    name: 'websites',
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_CONTACT)) {
      setCreateInfo(initialCreateInfo)
    }
  }, [successLabels])

  const createContact = data => {
    const getNewData = _.pickBy(
      {
        ...data,
        emails: _.filter(_.get(data, 'emails'), item => item.email !== '' && item.email !== undefined),
        phones: _.filter(_.get(data, 'phones'), item => item.phone !== '' && item.phone !== undefined),
        websites: _.filter(_.get(data, 'websites'), item => item.url !== '' && item.url !== undefined),
        socials: _.filter(_.get(data, 'socials'), item => item.url !== '' && item.url !== undefined),
      },
      _.identity
    )
    dispatch(addContacts({...getNewData, ...createInfo}))
  }

  return (
    <KooperModal
      size="small"
      open={isModalOpen}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <KooperModalHeader>Add Contact</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperForm className="errorLabel">
          <KooperFormGroup widths="equal">
            <KooperFormField required>
              <label>First Name</label>
              <Controller
                name="firstName"
                render={({onChange, value}) => (
                  <KooperFormInput
                    type="text"
                    maxLength={20}
                    fluid
                    value={value}
                    placeholder="Type name..."
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors.firstName && {
                        content: removeDoubleQuotes(errors.firstName.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField required>
              <label>Last Name</label>
              <Controller
                name="lastName"
                render={({onChange, value}) => (
                  <KooperFormInput
                    type="text"
                    fluid
                    maxLength={20}
                    value={value}
                    placeholder="Type name..."
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors.lastName && {
                        content: removeDoubleQuotes(errors.lastName.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormGroup widths="equal">
            <KooperFormField required>
              <label>Email</label>
              <Controller
                name="email"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormInput
                    placeholder="Type Email"
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={
                      errors.email && {
                        content: removeDoubleQuotes(errors.email.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField>
              <label>Phone</label>
              <Controller
                name="phone"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormInput
                    placeholder="Type Phone"
                    maxLength={15}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    error={
                      errors.phone && {
                        content: removeDoubleQuotes(errors.phone.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
          </KooperFormGroup>
          <KooperFormGroup widths="equal">
            <KooperFormField>
              <EmailCreateMultiple
                emailFields={emailFields}
                errors={errors}
                appendEmail={appendEmail}
                control={control}
                removeEmail={removeEmail}
              />
            </KooperFormField>

            <KooperFormField>
              <PhoneCreateMultiple
                phoneFields={phoneFields}
                errors={errors}
                appendPhone={appendPhone}
                control={control}
                removePhone={removePhone}
              />
            </KooperFormField>
          </KooperFormGroup>

          <KooperFormGroup widths="equal">
            <KooperFormField>
              <label>Title</label>
              <Controller
                name="title"
                render={({onChange, value}) => (
                  <KooperFormInput
                    type="text"
                    fluid
                    maxLength={20}
                    value={value}
                    placeholder="Type title..."
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors.title && {
                        content: removeDoubleQuotes(errors.title.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField>
              <div className="info-header">
                <label className="m-0">Company</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Select a company for this contact"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <KooperDropdown
                placeholder="Company"
                selection
                fluid
                search
                clearable
                additionPosition="bottom"
                allowAdditions
                additionLabel={<i>Add Company: </i>}
                onChange={(e, {value}) => {
                  if (typeof value === 'number') {
                    setCreateInfo({...createInfo, companyId: value})
                  } else {
                    setCreateInfo({...createInfo, company: value})
                  }
                }}
                onAddItem={(e, {value}) => {
                  setContactCompanyList([{value, key: value, text: value}, ...contactCompanyList])
                }}
                value={createInfo.company ? get(['company'], createInfo) : get(['companyId'], createInfo)}
                options={contactCompanyList}
              />
            </KooperFormField>
          </KooperFormGroup>

          <KooperFormField>
            <label>Website</label>
            <Controller
              name="website"
              control={control}
              render={({value, onChange}) => (
                <KooperFormInput
                  placeholder="Website"
                  maxLength={200}
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.website && {
                      content: removeDoubleQuotes(errors.website.message),
                    }
                  }
                />
              )}
            />
          </KooperFormField>

          <KooperFormField className="mb-4">
            <div className="info-header">
              <label className="label-class">Owner</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Who is the owner of this contact"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="assigneeId"
              render={({onChange, value, onBlur}) => (
                <KooperFormSelect
                  fluid
                  options={userOption}
                  selection
                  placeholder="Owners"
                  onBlur={onBlur}
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  error={
                    errors?.assigneeId && {
                      content: removeDoubleQuotes(errors?.assigneeId?.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>
          <KooperFormField>
            <div className="d-flex align-items-center">
              <div className="info-header">
                <label className="m-0">Allow access to client portal</label>
                <KooperTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Let the contact access client portal"
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="allowPortalAccess"
                control={control}
                render={({value, onChange}) => (
                  <KooperTooltip
                    content={value ? 'Enabled' : 'Disabled'}
                    size="mini"
                    position="top center"
                    trigger={<KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />}
                  />
                )}
              />
            </div>
          </KooperFormField>

          <KooperFormField>
            <WebsiteCreateMultiple
              websiteFields={websiteFields}
              errors={errors}
              appendWebsite={appendWebsite}
              control={control}
              removeWebsite={removeWebsite}
            />
          </KooperFormField>

          <KooperFormField className="mb-2">
            <label>Address</label>

            <KooperFormField>
              <Controller
                name="address.streetAddress"
                render={({onChange, value}) => (
                  <KooperFormInput
                    type="text"
                    fluid
                    value={value}
                    maxLength={20}
                    placeholder="Street"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors?.address?.streetAddress && {
                        content: removeDoubleQuotes(errors?.address?.streetAddress?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperGrid className="mt-2">
              <KooperGridColumn width={8}>
                <KooperFormField>
                  <Controller
                    name="address.locality"
                    render={({onChange, value}) => (
                      <KooperFormInput
                        type="text"
                        fluid
                        value={value}
                        placeholder="Locality"
                        maxLength={20}
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.address?.locality && {
                            content: removeDoubleQuotes(errors?.address?.locality?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
              </KooperGridColumn>
              <KooperGridColumn width={8}>
                <KooperFormField>
                  <Controller
                    name="address.state"
                    render={({onChange, value}) => (
                      <KooperFormInput
                        type="text"
                        fluid
                        value={value}
                        placeholder="State"
                        maxLength={20}
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.address?.state && {
                            content: removeDoubleQuotes(errors?.address?.state?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
              </KooperGridColumn>
              <KooperGridColumn width={8}>
                <Controller
                  name="address.country"
                  render={({onChange, value, onBlur}) => (
                    <KooperFormSelect
                      search
                      fluid
                      options={KooperCountryList}
                      selection
                      onBlur={onBlur}
                      placeholder="Country"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.address?.country && {
                          content: removeDoubleQuotes(errors?.address?.country?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperGridColumn>
              <KooperGridColumn width={8}>
                <KooperFormField>
                  <Controller
                    name="address.postalCode"
                    render={({onChange, value, onBlur}) => (
                      <KooperFormInput
                        type="text"
                        fluid
                        value={value}
                        onBlur={onBlur}
                        maxLength={10}
                        placeholder="Postal Code"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.address?.postalCode && {
                            content: removeDoubleQuotes(errors?.address?.postalCode?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
              </KooperGridColumn>
            </KooperGrid>
          </KooperFormField>

          <KooperFormField>
            <div className="info-header">
              <label>Add Social</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Add links of social media"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="socials[0].url"
              render={({onChange, value}) => (
                <KooperFormInput
                  type="text"
                  label={
                    <Controller
                      name="socials[0].category"
                      render={({onChange, onBlur, value}) => (
                        <KooperFormSelect
                          fluid
                          options={addSocialOptionList}
                          selection
                          placeholder="Social category"
                          onBlur={onBlur}
                          value={value}
                          onChange={(e, {value}) => {
                            onChange(value)
                          }}
                          error={
                            errors?.socials?.category && {
                              content: removeDoubleQuotes(errors?.socials?.category?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  }
                  fluid
                  value={value}
                  placeholder="Type URL"
                  maxLength={200}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    errors?.socials?.url && {
                      content: removeDoubleQuotes(errors?.socials?.url?.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>

          <KooperFormField>
            <KooperGrid>
              <KooperGridColumn width={8}>
                <div className="info-header">
                  <label style={{fontWeight: '500'}}>Status</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set status of contact"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="statusId"
                  render={({onChange, onBlur, value}) => (
                    <KooperFormSelect
                      fluid
                      options={statusOptionList}
                      selection
                      className="mt-2"
                      placeholder="Status"
                      value={value}
                      onBlur={onBlur}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.statusId && {
                          content: removeDoubleQuotes(errors?.statusId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperGridColumn>
              <KooperGridColumn width={8}>
                <div className="info-header">
                  <label style={{fontWeight: '500'}}>Contact Type</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select type of the contact"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  control={control}
                  name="contactTypeId"
                  render={({value, onChange, onBlur}) => (
                    <KooperFormSelect
                      placeholder="Contact Type"
                      options={contactTypeList}
                      fluid
                      className="mt-2"
                      selection
                      value={value}
                      onBlur={onBlur}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                    />
                  )}
                />
              </KooperGridColumn>
            </KooperGrid>
          </KooperFormField>
          <KooperFormField>
            <label>Description</label>
            <Controller
              name="description"
              render={({onChange, value}) => (
                <KooperFormTextArea
                  type="text"
                  value={value}
                  maxLength={200}
                  placeholder="Description"
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    errors.description && {
                      content: removeDoubleQuotes(errors.description.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>

          <KooperFormField>
            <div className="info-header">
              <label className="d-block">Add Tag</label>
              <KooperTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Label contact with multiple tags for easy identification"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              control={control}
              name="labelIds"
              render={({value, onChange}) => (
                <KooperChipInput
                  defaultValue={listOfLable.map(tag => {
                    return {
                      id: tag.value,
                      text: (
                        <>
                          <div
                            key={tag.value}
                            style={{
                              height: '10px',
                              width: '10px',
                              background: tag.label.style.background,
                              borderRadius: '50%',
                              marginRight: '10px',
                              display: 'inline-block',
                            }}
                          />
                          {tag.text}
                        </>
                      ),
                    }
                  })}
                  onChange={value => {
                    onChange(value)
                    const valueIds = value.map(tag => tag.id)
                    const removeTag = listOfLable.filter(contact => valueIds.indexOf(contact.value) !== -1)
                    setListOfLabel(removeTag)
                    const id = _.map(removeTag, 'value')
                    setCreateInfo({...createInfo, labelIds: id})
                  }}
                  hideInputField
                />
              )}
            />
            <Controller
              control={control}
              name="labelIds"
              render={({value, onChange}) => (
                <KooperCustomDropdown
                  options={labelOptionsState}
                  multiple
                  dropdownProps={{
                    icon: (
                      <KooperTooltip
                        content="Tags"
                        size="mini"
                        position="bottom center"
                        trigger={<SvgIcon path="common/tag" id="TooltipTagInbox" />}
                      />
                    ),
                    direction: 'right',
                    closeOnChange: false,
                  }}
                  menu={{scrolling: true}}
                  input={{
                    icon: 'search',
                    iconPosition: 'left',
                    className: 'search',
                  }}
                  activeIndicator
                  value={listOfLable.map(labels => labels.value)}
                  onClick={value => {
                    onChange(value)
                    const updatedList = labelOptionsState.filter(val => value.indexOf(val.value) !== -1)
                    setListOfLabel(updatedList)
                  }}
                  extraAction={{
                    content: '+ Add Tag',
                    onClick: () => setNewLabelModal(true),
                  }}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </KooperButton>

        <KooperButton primary onClick={handleSubmit(createContact)}>
          Add
        </KooperButton>
      </KooperModalActions>
      {createNewLabel()}
    </KooperModal>
  )
}

export default CreateContactModal
