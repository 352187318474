import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'
import {CREATE_PURCHASES_CATEGORY, EDIT_PURCHASES_CATEGORY, GET_SINGLE_PURCHASES_CATEGORY} from 'actions/types'
import {createPurchasesCategory, getSinglePurchasesCategory, editPurchasesCategory} from 'actions/purchases'
import {createPurchasesCategorySchema} from 'validation/Finance/purchases.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'

const initialFormData = {
  category: '',
}

const PurchasesCategoryModal = props => {
  const dispatch = useDispatch()
  const {open, toggleModal, editId, categoryToggle} = props
  const {handleSubmit, errors, control, reset} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(createPurchasesCategorySchema),
    defaultValues: initialFormData,
  })

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {getSinglePurchasesCategoryData} = useSelector(state => state.purchases)
  const {financePermissions} = useUserPermissions()
  const managePurchaseSettingsPermissions = financePermissions?.manage_purchase_settings

  useEffect(() => {
    return () => {
      categoryToggle('')
    }
  }, [dispatch, categoryToggle])

  useEffect(() => {
    if (editId) {
      dispatch(getSinglePurchasesCategory(editId))
    }
  }, [editId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_PURCHASES_CATEGORY)) {
      reset({category: getSinglePurchasesCategoryData.category})
    }
    if (successLabels.includes(EDIT_PURCHASES_CATEGORY)) {
      toggleModal(false)
    }
    if (successLabels.includes(CREATE_PURCHASES_CATEGORY)) {
      toggleModal(false)
    }
  }, [successLabels, toggleModal, getSinglePurchasesCategoryData, reset])

  const handleSave = data => {
    if (editId) {
      dispatch(editPurchasesCategory(editId, data))
    } else {
      dispatch(createPurchasesCategory(data))
    }
  }

  return (
    <KooperModal
      open={open}
      onClose={() => toggleModal(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      centered={false}
    >
      <KooperModalHeader >Category Field</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel" onSubmit={handleSubmit(handleSave)}>
          <KooperFormField required>
            <label>Name</label>
            <Controller
              name="category"
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  maxLength={20}
                  autoFocus
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  onKeyDown={e => e.key === 'Enter' && handleSubmit(data => handleSave(data))}
                  error={
                    errors.category && {
                      content: errors.category.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton
          disabled={
            isLoadingData && (type.includes(EDIT_PURCHASES_CATEGORY) || type.includes(CREATE_PURCHASES_CATEGORY))
          }
          basic
          onClick={() => toggleModal(false)}
        >
          Cancel
        </KooperButton>
        <LockPermissionTooltip isRoleAccessDenied={!managePurchaseSettingsPermissions}>
          <KooperButton
            loading={
              isLoadingData && (type.includes(EDIT_PURCHASES_CATEGORY) || type.includes(CREATE_PURCHASES_CATEGORY))
            }
            disabled={
              isLoadingData && (type.includes(EDIT_PURCHASES_CATEGORY) || type.includes(CREATE_PURCHASES_CATEGORY))
            }
            primary
            className={!managePurchaseSettingsPermissions ? 'disabled-button' : ''}
            {...(managePurchaseSettingsPermissions && {
              onClick: handleSubmit(data => handleSave(data)),
            })}
          >
            {editId ? 'Save' : 'Create'}
          </KooperButton>
        </LockPermissionTooltip>
      </KooperModalActions>
    </KooperModal>
  )
}

export default PurchasesCategoryModal
