import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import axios from 'axios'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperContainer,
  KooperDatePicker,
  KooperEditor,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperTooltip,
} from 'kooper-library'

import {
  GET_ALL_COMPANIES_IDS,
  GET_ALL_CONTACTS_IDS,
  GET_ALL_TAG_LABEL,
  GET_CONTACT_STATUSES,
  CONTACT_TYPE_LIST,
  CREATE_CLIENT_PORTAL_ANNOUNCEMENT,
  GET_CLIENT_PORTAL_SINGLE_ANNOUNCEMENT,
  UPDATE_CLIENT_PORTAL_ANNOUNCEMENT,
} from 'actions/types'
import {getAllCompaniesIds} from 'actions/company'
import {getAllContactsIds, getContactType} from 'actions/contacts'
import {getContactStatus} from 'actions/custom_options'
import {getTagLabel} from 'actions/multiple_fields'
import {KOOPER_ATTACHMENTS} from 'actions/endpoint'
import {
  createClientPortalAnnouncement,
  getClientPortalSingleAnnouncement,
  updateClientPortalAnnouncement,
} from 'actions/client-portal'
import {editorOptions} from 'constants/variables'
import {get, removeDoubleQuotes} from 'utils/helper'
import {getAppToken} from 'utils/local-storage'
import {announcementSchema} from 'validation/Customers/clientPortal.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {CLIENT_PORTAL_PATH} from 'constants/path'

const announcementDefaultValues = {
  displayTo: '',
  companyIds: [],
  tagIds: [],
  contactIds: [],
  statusIds: [],
  contactTypeIds: [],
  title: '',
  announcement: '',
  isScheduled: false,
  scheduledDateTime: null,
  enableUnPublish: false,
  unpublishDate: null,
}

const displayToOptions = [
  {key: 'all', value: 'all', text: 'All'},
  {key: 'companies', value: 'companies', text: 'Companies'},
  {key: 'tags', value: 'tags', text: 'Tags'},
  {key: 'specific', value: 'specific', text: 'Specific'},
  {key: 'status', value: 'status', text: 'Status'},
  {key: 'contact_type', value: 'contact_type', text: 'Contact Type'},
]

const AnnouncementCreate = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {id: editId} = useParams()

  const [companiesList, setCompaniesList] = useState([])
  const [contactsList, setContactsList] = useState([])
  const [tagsList, setTagsList] = useState([])
  const [statusList, setStatusList] = useState([])
  const [typesList, setTypesList] = useState([])
  const [isDraft, setIsDraft] = useState(true)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {contactStatusData = []} = useSelector(state => state.custom_option)
  const {getTypeListData = []} = useSelector(state => state.contacts)
  const {announcementData = {}} = useSelector(state => state.clientPortal)

  const {clientPortalPermissions} = useUserPermissions()
  const announcementsPermissions = clientPortalPermissions?.announcements

  const {handleSubmit, control, errors, watch, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(announcementSchema),
    defaultValues: announcementDefaultValues,
  })
  const watchDisplayTo = watch('displayTo')
  const watchIsScheduled = watch('isScheduled')
  const watchEnableUnPublish = watch('enableUnPublish')

  useApiResponse({
    action: getTagLabel,
    enabled: true,
    label: GET_ALL_TAG_LABEL,
    storePath: 'multipleFields.getTagList',
    onSuccess: getTagList => {
      const list = getTagList?.map(({id, label}) => ({key: id, value: id, text: label}))
      setTagsList(list || [])
    },
  })

  useApiResponse({
    action: getAllCompaniesIds,
    enabled: true,
    label: GET_ALL_COMPANIES_IDS,
    storePath: 'companies.companiesIdsList',
    onSuccess: companiesIdsList => {
      const list = companiesIdsList?.map(({id, name}) => ({key: id, value: id, text: name}))
      setCompaniesList(list || [])
    },
  })

  useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
    onSuccess: contactsIdsList => {
      const list = contactsIdsList.map(({id, name}) => ({
        key: id,
        value: id,
        text: name,
      }))
      setContactsList(list || [])
    },
  })

  useEffect(() => {
    dispatch(getContactStatus())
    dispatch(getContactType())
  }, [dispatch])

  useEffect(() => {
    if (editId) dispatch(getClientPortalSingleAnnouncement(editId))
  }, [editId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_CONTACT_STATUSES)) {
      const list = contactStatusData.map(({id, status}) => ({key: id, value: id, text: status}))
      setStatusList(list)
    }
    if (successLabels.includes(CONTACT_TYPE_LIST)) {
      const list = getTypeListData.map(({id, type}) => ({key: id, value: id, text: type}))
      setTypesList(list)
    }
  }, [successLabels, contactStatusData, getTypeListData])

  useEffect(() => {
    if (successLabels.includes(GET_CLIENT_PORTAL_SINGLE_ANNOUNCEMENT)) {
      const data = {
        displayTo: get(['displayTo'], announcementData, 'all'),
        companyIds: get(['companyIds'], announcementData, []),
        tagIds: get(['tagIds'], announcementData, []),
        contactIds: get(['contactIds'], announcementData, []),
        statusIds: get(['statusIds'], announcementData, []),
        contactTypeIds: get(['contactTypeIds'], announcementData, []),
        title: get(['title'], announcementData, ''),
        announcement: get(['announcement'], announcementData, ''),
        isScheduled: get(['isScheduled'], announcementData, false),
        scheduledDateTime: get(['scheduledDateTime'], announcementData, null),
        enableUnPublish: get(['enableUnPublish'], announcementData, false),
        unpublishDate: get(['unpublishDate'], announcementData, null),
      }
      setIsDraft(announcementData.isDraft)
      reset(data)
    }
  }, [successLabels, announcementData, reset])

  useEffect(() => {
    if (
      successLabels.includes(CREATE_CLIENT_PORTAL_ANNOUNCEMENT) ||
      successLabels.includes(UPDATE_CLIENT_PORTAL_ANNOUNCEMENT)
    ) {
      history.push(`/${CLIENT_PORTAL_PATH.PREFIX}/${CLIENT_PORTAL_PATH.ANNOUNCEMENTS}`)
    }
  }, [successLabels, history])

  const submitForm = (data, isDraft) => {
    const payload = {...data, isDraft}
    if (editId) {
      dispatch(updateClientPortalAnnouncement(editId, payload))
    } else {
      dispatch(createClientPortalAnnouncement(payload))
    }
  }

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    if (files && files[0]) {
      const formData = new FormData()
      formData.append('files[0]', files[0])
      axios({
        method: 'post',
        url: `${KOOPER_ATTACHMENTS}/announcement-image`,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${getAppToken()}`},
      }).then(function (res) {
        const response = {
          result: [
            {
              url: res.data.payload,
            },
          ],
        }
        uploadHandler(response)
      })
    }
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <div>
          <KooperButton
            primary
            onClick={() => history.push(`/${CLIENT_PORTAL_PATH.PREFIX}/${CLIENT_PORTAL_PATH.ANNOUNCEMENTS}`)}
          >
            Exit
          </KooperButton>
        </div>
        <h3 className="m-0">{`${editId ? 'Update' : 'Create'}  Announcement`}</h3>
        <div>
          {isDraft && (
            <LockPermissionTooltip isRoleAccessDenied={!announcementsPermissions}>
              <KooperButton
                primary
                className={`mr-3 ${!announcementsPermissions ? 'disabled-button' : ''}`}
                {...(announcementsPermissions && {
                  onClick: handleSubmit(data => submitForm(data, true)),
                })}
              >
                Save as draft
              </KooperButton>
            </LockPermissionTooltip>
          )}
          <LockPermissionTooltip isRoleAccessDenied={!announcementsPermissions}>
            <KooperButton
              primary
              className={!announcementsPermissions ? 'disabled-button' : ''}
              {...(announcementsPermissions && {
                onClick: handleSubmit(data => submitForm(data, false)),
              })}
            >
              Publish
            </KooperButton>
          </LockPermissionTooltip>
        </div>
      </div>

      <KooperForm className="errorLabel">
        <KooperContainer>
          <KooperCard fluid>
            <KooperCardContent>
              <h3 className="mb-0">Announcement</h3>
              <p className="mt-0 mb-4 card-description">Create announcement to share any news and updates</p>

              <KooperFormField width={6}>
                <div className="info-header">
                  <label className="label-class">Display To</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select whom you want to display the announcement"
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="displayTo"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      options={displayToOptions}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={errors.displayTo && {content: removeDoubleQuotes(errors.displayTo.message)}}
                    />
                  )}
                />
              </KooperFormField>

              {watchDisplayTo === 'companies' && (
                <KooperFormField width={6}>
                  <div className="info-header">
                    <label className="label-class">Companies</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content=" Select companies whom you want to share the announcement"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="companyIds"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        placeholder="Select Company"
                        multiple
                        options={companiesList}
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={errors.companyIds && {content: removeDoubleQuotes(errors.companyIds.message)}}
                      />
                    )}
                  />
                </KooperFormField>
              )}
              {watchDisplayTo === 'tags' && (
                <KooperFormField width={6}>
                  <div className="info-header">
                    <label className="label-class">tags</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Select tags for the announcement"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="tagIds"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        placeholder="Select Tag"
                        multiple
                        options={tagsList}
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={errors.tagIds && {content: removeDoubleQuotes(errors.tagIds.message)}}
                      />
                    )}
                  />
                </KooperFormField>
              )}
              {watchDisplayTo === 'specific' && (
                <KooperFormField width={6}>
                  <div className="info-header">
                    <label className="label-class">Contacts</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Select specific contacts who you want to share the announcement"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="contactIds"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        placeholder="Select Contact"
                        multiple
                        options={contactsList}
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={errors.contactIds && {content: removeDoubleQuotes(errors.contactIds.message)}}
                      />
                    )}
                  />
                </KooperFormField>
              )}
              {watchDisplayTo === 'status' && (
                <KooperFormField width={6}>
                  <div className="info-header">
                    <label className="label-class">Status</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Select status type for your announcement"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="statusIds"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        placeholder="Select Status"
                        multiple
                        options={statusList}
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={errors.statusIds && {content: removeDoubleQuotes(errors.statusIds.message)}}
                      />
                    )}
                  />
                </KooperFormField>
              )}
              {watchDisplayTo === 'contact_type' && (
                <KooperFormField width={6}>
                  <div className="info-header">
                    <label className="label-class">Type</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Set the type of contact for the announcement"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="contactTypeIds"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        placeholder="Select Type"
                        multiple
                        options={typesList}
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={errors.contactTypeIds && {content: removeDoubleQuotes(errors.contactTypeIds.message)}}
                      />
                    )}
                  />
                </KooperFormField>
              )}

              <KooperFormField width={6}>
                <div className="info-header">
                  <label className="label-class">Title</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify title of the announcement"
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="title"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperFormInput
                      maxLength={30}
                      className="mb-0"
                      placeholder="Title"
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                    />
                  )}
                />
              </KooperFormField>

              <KooperFormField>
                <div className="info-header">
                  <label>Announcement</label>
                  <KooperTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Write your announcement"
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="announcement"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperEditor
                      setDefaultStyle="font-size: 15px; line-height: 1.5;"
                      setContents={value}
                      onChange={content => onChange(content)}
                      width="100%"
                      setOptions={editorOptions}
                      onImageUploadBefore={handleImageUploadBefore}
                    />
                  )}
                />
                {errors.announcement && (
                  <p className="errorLabelElement">{removeDoubleQuotes(errors.announcement.message)}</p>
                )}
              </KooperFormField>
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent>
              <h3 className="mb-0">Announcement Settings</h3>
              <p className="mt-0 mb-4 card-description">Manage all settings related to announcement.</p>

              <KooperFormField>
                <Controller
                  control={control}
                  name="isScheduled"
                  render={({onChange, value}) => (
                    <KooperFormField>
                      <div className="d-flex">
                        <div>
                          <label>Enable Schedule</label>
                          <p className="kooper-lead">Schedule announcement for the right time</p>
                        </div>
                        <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                      </div>
                    </KooperFormField>
                  )}
                />
              </KooperFormField>

              {watchIsScheduled && (
                <KooperFormField width={6}>
                  <div className="info-header">
                    <label>Schedule Date and Time</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Set publish date and time"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="scheduledDateTime"
                    control={control}
                    render={({onChange, value}) => (
                      <KooperDatePicker
                        value={typeof value === 'string' ? new Date(value) : value}
                        inputProps={{readOnly: true}}
                        onChange={value => onChange(value)}
                        error={
                          errors.scheduledDateTime && {
                            content: removeDoubleQuotes(errors.scheduledDateTime.message),
                          }
                        }
                      />
                    )}
                  />
                  {errors.scheduledDateTime && (
                    <p className="errorLabelElement">{removeDoubleQuotes(errors.scheduledDateTime.message)}</p>
                  )}
                </KooperFormField>
              )}

              <KooperFormField>
                <Controller
                  control={control}
                  name="enableUnPublish"
                  render={({onChange, value}) => (
                    <KooperFormField>
                      <div className="d-flex">
                        <div>
                          <label>Enable Unpublish</label>
                          <p className="kooper-lead">Announcement will be unpublished after the mentioned time</p>
                        </div>
                        <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                      </div>
                    </KooperFormField>
                  )}
                />
              </KooperFormField>

              {watchEnableUnPublish && (
                <KooperFormField width={6}>
                  <div className="info-header">
                    <label>Unpublishing Date</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Set date for announcement unpublishing"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="unpublishDate"
                    control={control}
                    render={({onChange, value}) => (
                      <KooperDatePicker
                        type="date"
                        value={typeof value === 'string' ? new Date(value) : value}
                        inputProps={{readOnly: true}}
                        time={false}
                        onChange={value => onChange(value)}
                        error={
                          errors.unpublishDate && {
                            content: removeDoubleQuotes(errors.unpublishDate.message),
                          }
                        }
                      />
                    )}
                  />
                  {errors.unpublishDate && (
                    <p className="errorLabelElement">{removeDoubleQuotes(errors.unpublishDate.message)}</p>
                  )}
                </KooperFormField>
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperContainer>
      </KooperForm>
    </div>
  )
}

export default AnnouncementCreate
