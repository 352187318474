import {joiResolver} from '@hookform/resolvers'
import {moveProjectFinanceBillingItems} from 'actions/projects'
import SvgIcon from 'components/common/SvgIcon'
import Joi from 'joi'
import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {removeDoubleQuotes} from 'utils/helper'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'

const ProjectBillingMoveExpenseModal = ({open, toggle, projectId, budgetId, expenseIds, invoiceId}) => {
  const dispatch = useDispatch()

  const {billingOpenInvoicesList = []} = useSelector(state => state.projects)

  const {handleSubmit, control, errors} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        newInvoiceId: Joi.number().required().messages({
          'number.bas': 'Invoice must be specified',
        }),
      })
    ),
    defaultValues: {
      newInvoiceId: null,
    },
  })

  const submitForm = data => {
    const payload = {...data, expenseIds}
    if (invoiceId) {
      payload.oldInvoiceId = invoiceId
    }
    dispatch(moveProjectFinanceBillingItems({projectId, budgetId, data: payload}))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Add Expenses to Invoice</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <label>Choose destination</label>
            <Controller
              name="newInvoiceId"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  placeholder="Select destination"
                  options={billingOpenInvoicesList
                    .filter(invoice => invoice.id !== invoiceId)
                    .map(invoice => ({
                      key: invoice.id,
                      value: invoice.id,
                      text: invoice.invoiceNumber,
                    }))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.newInvoiceId && {content: removeDoubleQuotes(errors.newInvoiceId.message)}}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Move Expenses
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default ProjectBillingMoveExpenseModal
