import React, {useState} from 'react'
import {FiChevronRight} from 'react-icons/fi'
import {KooperMenu, KooperMenuItem, KooperAccordion, KooperAccordionTitle, KooperAccordionContent} from 'kooper-library'
import AddSegment from 'components/pages/Customers/Segment/AddSegment'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import {useDispatch} from 'react-redux'
import {getContactList} from 'actions/contacts'
import SvgIcon from 'components/common/SvgIcon'
import {CONTACTS_PATH} from 'constants/path'

const ContactMenu = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [activeContacts, setActiveContacts] = useState(true)

  return (
    <>
      <KooperMenu vertical secondary className="kooper-menu">
        <KooperAccordion className="mt-0">
          <KooperAccordionTitle
            style={{justifyContent: 'flex-start'}}
            onClick={() => setActiveContacts(!activeContacts)}
          >
            {activeContacts ? <SvgIcon path="common/down" /> : <FiChevronRight />}
            Contacts
          </KooperAccordionTitle>
          <KooperAccordionContent active={activeContacts}>
            <KooperMenuItem
              active={window.location.pathname === `/${CONTACTS_PATH.PREFIX}`}
              onClick={() => {
                history.push(`/${CONTACTS_PATH.PREFIX}`)
                dispatch(getContactList())
              }}
            >
              <SvgIcon path="clients/contacts/all-contacts" /> All Contacts
            </KooperMenuItem>
            {/* <KooperMenuItem
              active={window.location.pathname === `/contacts/newcontact`}
              onClick={() => history.push(`/contacts/newcontact`)}
            >
              <SvgIcon path="settings/users" /> New
            </KooperMenuItem> */}
            {/* <KooperMenuItem>
              <SvgIcon path="settings/users" /> Active
            </KooperMenuItem>
            <KooperMenuItem>
              <SvgIcon path="settings/users" /> Slipping Away
            </KooperMenuItem>
            <KooperMenuItem>
              <SvgIcon path="settings/users" /> Unengaged Leads
            </KooperMenuItem> */}
          </KooperAccordionContent>
          <KooperAccordionContent active={activeContacts}>
            <AddSegment type="contacts" {...props} />
          </KooperAccordionContent>
        </KooperAccordion>
      </KooperMenu>
    </>
  )
}

export default ContactMenu
