import React from 'react'
import {Controller} from 'react-hook-form'
import {AiOutlineQuestionCircle} from 'react-icons/ai'
import {removeDoubleQuotes} from 'utils/helper'
import {KooperForm, KooperFormField, KooperFormInput, KooperFormTextArea, KooperTooltip} from 'kooper-library'
import {budgetTypes, PROJECT_FINANCE_BUDGET_TYPES} from 'constants/projects'
import SvgIcon from 'components/common/SvgIcon'
import ProjectTemplateBudgetForm from './ProjectTemplateBudgetForm'
import ProjectTemplateFixedFeeBudgetForm from './ProjectTemplateFixedFeeBudgetForm'
import BudgetTypeForm from '../BudgetTypeForm'

const ProjectTemplateDetailsCarousel = ({
  style,
  control,
  errors,
  budgetType,
  rangeType,
  budgetTimeError,
  setBudgetTimeError,
  setValue,
  getValues,
}) => {
  return (
    <KooperForm className="errorLabel" style={style}>
      <KooperFormField required>
        <div className="info-header">
          <label>Title</label>
          <KooperTooltip
            trigger={<SvgIcon path="common/question" />}
            content="Give your project a title"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="title"
          control={control}
          render={({value, onChange}) => (
            <KooperFormInput
              maxLength={20}
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
            />
          )}
        />
      </KooperFormField>

      <KooperFormField>
        <div className="info-header">
          <label>Description</label>
          <KooperTooltip
            trigger={<AiOutlineQuestionCircle />}
            content="Write a brief description about the project"
            size="mini"
            psoition="top center"
          />
        </div>
        <Controller
          name="description"
          control={control}
          render={({value, onChange}) => (
            <KooperFormTextArea
              maxLength={200}
              style={{resize: 'none'}}
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={errors.description && {content: removeDoubleQuotes(errors.description.message)}}
            />
          )}
        />
      </KooperFormField>

      <BudgetTypeForm control={control} budgetTypes={budgetTypes} />

      {[PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER].includes(
        budgetType
      ) && (
        <ProjectTemplateBudgetForm
          control={control}
          errors={errors}
          rangeType={rangeType}
          budgetTimeError={budgetTimeError}
          setBudgetTimeError={setBudgetTimeError}
          budgetType={budgetType}
        />
      )}

      {[PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE].includes(budgetType) && (
        <ProjectTemplateFixedFeeBudgetForm
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
        />
      )}
    </KooperForm>
  )
}

export default ProjectTemplateDetailsCarousel
