import {KooperCard, KooperCardContent, KooperDivider, KooperIcon} from 'kooper-library'

const ActivityCard = ({activities = [], username = 'You'}) => {
  return (
    <KooperCard fluid>
      <KooperCardContent>
        {activities?.map(({title, time}, index) => (
          <>
            <div className="goal-activity-item">
              <KooperIcon name="user" />
              <div className="goal-activity-item-details">
                <span className="goal-activity-item-details-text">
                  <span className="goal-activity-item-details-user-name">{username}</span>
                  &nbsp;
                  {title}
                </span>
                <span>{time}</span>
              </div>
            </div>
            {index < activities.length - 1 && <KooperDivider />}
          </>
        ))}
      </KooperCardContent>
    </KooperCard>
  )
}

export default ActivityCard
