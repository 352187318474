import React from 'react'
import SvgIcon from 'components/common/SvgIcon'
import {KooperButton, KooperModal, KooperModalActions, KooperModalContent, KooperModalHeader} from 'kooper-library'

const UnsavedChanges = props => {
  const {cleanUp} = props

  return (
    <KooperModal
      size="tiny"
      dimmer="blurring"
      open
      centered={false}
      closeIcon={
        <SvgIcon
          path="common/close"
          className="closeIcon"
          onClick={() => {
            cleanUp(false)
          }}
        />
      }
    >
      <KooperModalHeader >Warning</KooperModalHeader>
      <KooperModalContent>
        <h5>There are some unsaved changes !</h5>
        <p className="kooper-lead">Do you still wish to leave?</p>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton
          basic
          onClick={() => {
            cleanUp(false)
          }}
        >
          No
        </KooperButton>
        <KooperButton
          primary
          content="Yes"
          onClick={() => {
            cleanUp(true)
          }}
        />
      </KooperModalActions>
    </KooperModal>
  )
}

export default UnsavedChanges
