/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FaBriefcase, FaStar} from 'react-icons/fa'
import {useHistory} from 'react-router'
import {FiFilter, FiStar, FiGitMerge, FiExternalLink} from 'react-icons/fi'
import _ from 'lodash'
import moment from 'moment'
import {
  KooperButtonGroup,
  KooperButton,
  KooperDropdown,
  KooperCheckbox,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperTableRow,
  KooperTableHeader,
  KooperTable,
  KooperTableHeaderCell,
  KooperTableBody,
  KooperTableCell,
  KooperTooltip,
  KooperLabel,
  KooperTablePlaceholder,
  KooperRating,
  KooperHeader,
} from 'kooper-library'

import {
  CREATE_COMPANY,
  COLUMNS_SELECTION_COMPANY,
  DELETE_COMPANY,
  GET_ALL_COMPANIES,
  MULTIPLE_MERGE_COMPANY,
  MULTIPLE_UPDATE_COMPANY,
} from 'actions/types'
import {getTagLabel} from 'actions/multiple_fields'
import {getContactType} from 'actions/contacts'
import {getCompanyList, deleteCompany, companyMultipleUpdate, getColumnsSelectionCompany} from 'actions/company'
import CustomizeTableColumn from 'components/common/CustomizeTableColumn'
import {
  get,
  getFormattedDate,
  GetFormattedDate,
  getPermissionTooltipContent,
  getSortType,
  startCase,
} from 'utils/helper'
import create from 'assets/create.svg'
import DeleteModal from 'components/common/DeleteModal'
import SearchComponent from 'components/common/SearchComponent'
import CreateFirst from 'components/common/CreateFirst'
import CompaniesMenu from 'pages/authenticated/Customers/Companies'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getUsers} from 'actions/settings'
import {COMPANIES_PATH, SETTINGS_PATH} from 'constants/path'
import CompanyFilter from './CompanyFilter'
import EditCompanyModal from './CompanyModal/EditModal'
import CreateCompanyModal from './CompanyModal/CreateModal'
import MergeCompanyModal from './CompanyModal/MergeModal'
import MailLeadModal from './CompanyModal/MailModal'
import ExportLeadModal from './CompanyModal/ExportModal'
import ImportModal from './CompanyModal/ImportModal'
import PreviewModal from './CompanyModal/PreviewModal'

const Companies = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [checked, setChecked] = useState([])
  const [isCustomizeColumn, setIsCustomizeColumn] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)
  const [isExportOpen, setIsExportOpen] = useState(false)
  const [isMergeModal, setIsMergeModal] = useState(false)
  const [isMailModal, setIsMailModal] = useState(false)
  const [isCreateModal, setIsCreateModal] = useState(false)
  const [isEditModal, setIsEditModal] = useState(false)
  const [activeClassFilter, setActiveClassFilter] = useState(false)
  const [getCompanyId, setGetCompanyId] = useState(0)
  const [sort, setSort] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
    text: '',
    ...get(['match', 'params'], props),
  })
  const [previewModal, setPreviewModal] = useState(false)
  const [singleIdForDelete, setSingleIdForDelete] = useState(null)

  const {getAllCompanyList = []} = useSelector(state => state.companies)
  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {segmentList = []} = useSelector(state => state.segment)

  const getAllCompanyColumnsList = useSelector(state => {
    return get(['companies', 'getAllCompanyColumnsList', 'columns'], state, [])
  })

  const {totalPages, currentPage, totalRecords} = useApiResponse({storePath: 'companies.companyPage'})

  const {isLoading} = useApiLoader({label: GET_ALL_COMPANIES})
  const {salesAndCrmAccess, contactsAndCompaniesPermissions} = useUserPermissions()
  const importAndExportAccess = salesAndCrmAccess?.importAndExport
  const manageContactsCompanyPermissions = contactsAndCompaniesPermissions?.manage_contacts_company
  const bulkActionsPermissions = contactsAndCompaniesPermissions?.bulk_actions
  const manageImportExportPermissions = contactsAndCompaniesPermissions?.manage_import_export

  useEffect(() => {
    dispatch(getColumnsSelectionCompany())
    dispatch(getUsers())
    dispatch(getTagLabel())
    dispatch(getContactType())
  }, [dispatch])

  const handleContactName = () => {
    if (get(['match', 'params', 'segmentId'], props)) {
      return get(
        ['name'],
        get(['companies'], segmentList, []).find(
          item => item.id === Number(get(['match', 'params', 'segmentId'], props))
        ),
        'Companies'
      )
    }
    return 'Companies'
  }

  const handleSort = clickedColumn => () => {
    if (sort.sortBy !== clickedColumn) {
      setSort({
        ...sort,
        sortType: 'asc',
        sortBy: clickedColumn,
      })
      return
    }
    setSort({
      ...sort,
      sortType: sort.sortType === 'asc' ? 'desc' : 'asc',
    })
  }

  useEffect(() => {
    if (successLabels.includes(MULTIPLE_MERGE_COMPANY)) {
      setIsMergeModal(false)
      setChecked([])
    }
    if (successLabels.includes(MULTIPLE_UPDATE_COMPANY)) {
      setChecked([])
      setIsEditModal(false)
    }
    if (successLabels.includes(CREATE_COMPANY)) {
      setIsCreateModal(false)
    }
    if (successLabels.includes(COLUMNS_SELECTION_COMPANY)) {
      setChecked([])
      setIsCustomizeColumn(false)
    }
    if (successLabels.includes(DELETE_COMPANY)) {
      setChecked([])
    }
  }, [successLabels])

  useEffect(() => {
    dispatch(getCompanyList(sort))
  }, [dispatch, sort])

  useEffect(() => {
    setSort({...sort, ...get(['match', 'params'], props)})
  }, [JSON.stringify(get(['match', 'params'], props))])

  const customizeColumnLogic = (item, key) => {
    const conditionalObject = {
      phones: get(['phones', [0], 'phone'], item, '--'),
      emails: get(['emails', [0], 'email'], item, '--'),
      owner: get(['owner', 'firstName'], item, '--'),
      inactiveDays: get(['interactionTime'], item) ? moment(get(['interactionTime'], item)).fromNow(true) : '--',
      domain: get(['domain'], item, '--'),
      contactType: get(['contactType', 'type'], item),
      updatedAt: getFormattedDate(item.updatedAt),
      createdAt: getFormattedDate(item.createdAt),
      street: get(['address', 'streetAddress'], item, '--'),
      city: get(['address', 'locality'], item, '--'),
      state: get(['address', 'state'], item, '--'),
      country: get(['address', 'country'], item, '--'),
      zip: get(['address', 'postalCode'], item, '--'),
      websites: get(['websites', [0], 'url'], item, '--'),
      lastContacted: get(['interactionTime'], item) ? GetFormattedDate(get(['interactionTime'], item)) : '--',
      tags: get(['labels'], item, [])
        .slice(0, 2)
        .map(tag => {
          return (
            <>
              {tag.label}
              <br />
            </>
          )
        }),
    }

    if (Object.keys(conditionalObject).includes(key)) {
      return get([key], conditionalObject)
    }
    return item[key]
  }

  const handleSingleDelete = id => {
    setIsDeleteModalOpen(!isDeleteModalOpen)
    setSingleIdForDelete(id)
  }

  const handleCheck = id => {
    const isChecked = checked.some(lead => lead === id)

    if (isChecked) {
      const removeChecked = checked.filter(lead => lead !== id)
      setChecked(removeChecked)
    } else {
      setChecked([...checked, id])
    }
  }

  const isChecked = id => {
    const check = checked.find(lead => lead === id)
    if (check) {
      return true
    }
    return false
  }

  const isCheckedAll = () => {
    const allIds = (getAllCompanyList || []).map(lead => lead.id)

    const checkAll = _.xor(allIds, checked).length === 0 && allIds.length !== 0
    if (checkAll) {
      return true
    }
    return false
  }

  const handleCheckAll = () => {
    const allIds = (getAllCompanyList || []).map(lead => lead.id)

    if (_.xor(allIds, checked).length === 0) {
      setChecked([])
    } else setChecked(allIds)
  }

  const renderOptionList = () => {
    if (checked.length > 1) {
      return (
        <KooperButtonGroup basic compact className="ml-3 conFilter">
          <KooperTooltip
            size="mini"
            position="top center"
            content={
              bulkActionsPermissions
                ? 'Compose Bulk Mail'
                : getPermissionTooltipContent({roleAccess: bulkActionsPermissions})
            }
            trigger={
              <KooperButton onClick={() => bulkActionsPermissions && setIsMailModal(!isMailModal)}>
                <SvgIcon path="common/mail" />
              </KooperButton>
            }
          />
          {checked.some(item => {
            return (getAllCompanyList || []).some(list => list.id === item && !list.isFollowed)
          }) ? (
            <KooperTooltip
              size="mini"
              position="top center"
              content={
                bulkActionsPermissions
                  ? 'Add Selected Favorite'
                  : getPermissionTooltipContent({roleAccess: bulkActionsPermissions})
              }
              trigger={
                <KooperButton
                  color="light"
                  onClick={() =>
                    bulkActionsPermissions &&
                    dispatch(
                      companyMultipleUpdate({
                        ids: checked,
                        isFollowed: true,
                      })
                    )
                  }
                >
                  <FiStar />
                </KooperButton>
              }
            />
          ) : (
            <KooperTooltip
              size="mini"
              position="top center"
              content={
                bulkActionsPermissions
                  ? 'Remove Selected Favorite'
                  : getPermissionTooltipContent({roleAccess: bulkActionsPermissions})
              }
              trigger={
                <KooperButton
                  color="light"
                  onClick={() =>
                    bulkActionsPermissions &&
                    dispatch(
                      companyMultipleUpdate({
                        ids: checked,
                        isFollowed: false,
                      })
                    )
                  }
                >
                  <FaStar style={{color: '#ecd60e'}} />
                </KooperButton>
              }
            />
          )}
          <KooperTooltip
            size="mini"
            position="top center"
            content={
              bulkActionsPermissions
                ? 'Edit Selected Companies'
                : getPermissionTooltipContent({roleAccess: bulkActionsPermissions})
            }
            trigger={
              <KooperButton onClick={() => bulkActionsPermissions && setIsEditModal(!isEditModal)}>
                <SvgIcon path="common/edit2" />
              </KooperButton>
            }
          />
          <KooperTooltip
            size="mini"
            position="top center"
            content={
              bulkActionsPermissions
                ? 'Merge Selected Companies'
                : getPermissionTooltipContent({roleAccess: bulkActionsPermissions})
            }
            trigger={
              <KooperButton onClick={() => bulkActionsPermissions && setIsMergeModal(!isMergeModal)}>
                <FiGitMerge />
              </KooperButton>
            }
          />
          <KooperTooltip
            size="mini"
            position="top center"
            content={
              bulkActionsPermissions
                ? 'Export Selected Companies'
                : getPermissionTooltipContent({roleAccess: bulkActionsPermissions})
            }
            trigger={
              <KooperButton onClick={() => bulkActionsPermissions && setIsExportOpen(!isExportOpen)}>
                <FiExternalLink />
              </KooperButton>
            }
          />
          <KooperTooltip
            size="mini"
            position="top center"
            content={
              bulkActionsPermissions
                ? 'Delete Selected Companies'
                : getPermissionTooltipContent({roleAccess: bulkActionsPermissions})
            }
            trigger={
              <KooperButton onClick={() => bulkActionsPermissions && setIsDeleteModalOpen(!isDeleteModalOpen)}>
                <SvgIcon path="common/delete" />
              </KooperButton>
            }
          />
        </KooperButtonGroup>
      )
    }
  }

  const renderLeadList = () => {
    return (getAllCompanyList || []).map(item => (
      <KooperTableRow
        key={item.id}
        className="tableLink"
        onClick={() => {
          history.push({
            pathname: `/${COMPANIES_PATH.PREFIX}/${get(['id'], item, '')}`,
            // state: {
            //   ...get(["routeState"], JSON.parse(localStorage.getItem("sales"))),
            //   companyName: get(["name"], item, ""),
            // },
          })
        }}
      >
        <KooperTableCell
          style={{minWidth: '85px', justifyContent: 'flex-start'}}
          className="pl-4 pt-4 d-flex"
          key="checkbox"
          onClick={e => e.stopPropagation()}
        >
          <KooperTooltip
            size="mini"
            position="top center"
            content="Select"
            trigger={
              <KooperCheckbox
                id={`${item.id}label`}
                className="d-inline-block"
                onChange={() => handleCheck(item.id)}
                checked={isChecked(item.id)}
              />
            }
          />
          <KooperTooltip
            size="mini"
            position="top center"
            content={item.isFollowed ? 'Remove Favorite' : 'Add Favorite'}
            trigger={
              <KooperRating
                icon="star"
                className="ml-4"
                onClick={() => {
                  dispatch(
                    companyMultipleUpdate({
                      ids: [item.id],
                      isFollowed: !item.isFollowed,
                    })
                  )
                }}
                rating={item.isFollowed ? 1 : 0}
              />
            }
          />
        </KooperTableCell>
        <KooperTableCell key="firstName" className="prevCell">
          <KooperHeader as="h5">{startCase(get(['name'], item, ''))}</KooperHeader>
          <div className="PrevActPart">
            <KooperLabel
              as="a"
              color="blue"
              onClick={e => {
                e.stopPropagation()
                setPreviewModal(!previewModal)
                setGetCompanyId(item.id)
              }}
            >
              Preview
            </KooperLabel>
            {/* <KooperButton
              className="mx-3"
              icon={
                <KooperTooltip
                  content="Create Conversation"
                  position="top center"
                  size="mini"
                  trigger={<SvgIcon path="common/mail" />}
                />
              }
            /> */}
          </div>
        </KooperTableCell>
        <KooperTableCell>{get(['emails', [0], 'email'], item, '--')}</KooperTableCell>
        {getAllCompanyColumnsList.map(key => {
          return <KooperTableCell key={key}>{customizeColumnLogic(item, key)}</KooperTableCell>
        })}
        <KooperTableCell>
          <KooperTooltip
            content={
              manageContactsCompanyPermissions
                ? 'Delete'
                : getPermissionTooltipContent({roleAccess: manageContactsCompanyPermissions})
            }
            size="mini"
            position="top center"
            trigger={
              <KooperButton
                icon={<SvgIcon path="common/delete" />}
                onClick={e => {
                  e.stopPropagation()
                  if (manageContactsCompanyPermissions) {
                    handleSingleDelete(item.id)
                  }
                }}
              />
            }
          />
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {getAllCompanyList.length > 0 || sort.text || isFilterOpen ? (
        <>
          <CompaniesMenu />
          <div className="kooper-side-wrap" style={{paddingRight: isFilterOpen && 300}}>
            <div className="page-header">
              <div className="page-title">
                <div className="page-titleIcon">
                  <SvgIcon path="navigation/companies" />
                  <h5>
                    {checked && checked.length > 0
                      ? `${checked.length} out of ${(getAllCompanyList || []).length} Selected`
                      : `${handleContactName()} (${totalRecords})`}
                  </h5>
                  {renderOptionList()}
                </div>
              </div>
              <div className="page-action">
                <SearchComponent onSuccess={value => setSort({text: value})} />

                <KooperTooltip
                  size="mini"
                  position="top center"
                  content="Filters"
                  trigger={
                    <KooperButton
                      icon
                      className={isFilterOpen || activeClassFilter ? 'active' : null}
                      onClick={() => (!isFilterOpen ? setIsFilterOpen(!isFilterOpen) : null)}
                    >
                      <FiFilter id="TooltipProductInbox" />
                    </KooperButton>
                  }
                />

                <KooperTooltip
                  size="mini"
                  position="top center"
                  content="Settings"
                  trigger={
                    <KooperDropdown floating button className="icon" icon={<SvgIcon path="common/settings" />}>
                      <KooperDropdownMenu className="left">
                        <KooperDropdownItem onClick={() => setIsCustomizeColumn(!isCustomizeColumn)}>
                          Customize Columns
                        </KooperDropdownItem>
                        <LockPermissionTooltip
                          isFeatureAccessDenied={!importAndExportAccess}
                          isRoleAccessDenied={!manageImportExportPermissions}
                        >
                          <KooperDropdownItem
                            text="Import from Excel or CSV file"
                            onClick={() =>
                              importAndExportAccess &&
                              manageImportExportPermissions &&
                              history.push(`/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.IMPORT}`)
                            }
                          />
                        </LockPermissionTooltip>
                        <LockPermissionTooltip
                          isFeatureAccessDenied={!importAndExportAccess}
                          isRoleAccessDenied={!manageImportExportPermissions}
                        >
                          <KooperDropdownItem
                            text="Export Companies"
                            onClick={() =>
                              importAndExportAccess &&
                              manageImportExportPermissions &&
                              history.push(`/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.EXPORT}`)
                            }
                          />
                        </LockPermissionTooltip>
                      </KooperDropdownMenu>
                    </KooperDropdown>
                  }
                />
                <LockPermissionTooltip isRoleAccessDenied={!manageContactsCompanyPermissions}>
                  <KooperButton
                    primary
                    className={!manageContactsCompanyPermissions ? 'disabled-button' : ''}
                    onClick={() => manageContactsCompanyPermissions && setIsCreateModal(!isCreateModal)}
                    content="Create Company"
                  />
                </LockPermissionTooltip>
              </div>
            </div>
            <div style={{display: isFilterOpen ? 'block' : 'none'}}>
              <CompanyFilter
                isFilterOpen={isFilterOpen}
                setIsFilterOpen={setIsFilterOpen}
                setActiveClassFilter={setActiveClassFilter}
              />
            </div>
            <div className="tableScroll">
              <KooperTable selectable sortable basic>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell className="pl-4">
                      <KooperTooltip
                        size="mini"
                        position="top center"
                        content="Select All"
                        trigger={
                          <KooperCheckbox id="ckbxAll" label="" checked={isCheckedAll()} onChange={handleCheckAll} />
                        }
                      />
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                      sorted={sort.sortBy === 'name' ? getSortType(sort.sortType) : null}
                      onClick={handleSort('name')}
                    >
                      Name
                    </KooperTableHeaderCell>
                    <KooperTableHeaderCell
                      sorted={sort.sortBy === 'emails' ? getSortType(sort.sortType) : null}
                      onClick={handleSort('emails')}
                    >
                      Emails
                    </KooperTableHeaderCell>
                    {getAllCompanyColumnsList.map(col => {
                      return (
                        <KooperTableHeaderCell
                          key={col}
                          sorted={sort.sortBy === col ? getSortType(sort.sortType) : null}
                          onClick={handleSort(col)}
                        >
                          {startCase(col)}
                        </KooperTableHeaderCell>
                      )
                    })}
                    <KooperTableHeaderCell>Action</KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>
                  {isLoadingData && type.includes(GET_ALL_COMPANIES) ? (
                    <KooperTablePlaceholder columns={8} rows={5} />
                  ) : (getAllCompanyList || []).length > 0 ? (
                    renderLeadList()
                  ) : (
                    <KooperTableRow>
                      <KooperTableCell colSpan="8" className="emptyValue">
                        No Data Available
                      </KooperTableCell>
                    </KooperTableRow>
                  )}
                </KooperTableBody>
              </KooperTable>
              <KooperCustomPagination
                totalPages={totalPages}
                activePage={currentPage}
                onPageChange={page => {
                  setSort(sort => ({...sort, page}))
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <div>
          <CreateFirst
            src={<FaBriefcase />}
            header="Company"
            subHeader="Companies are also regular customers who can have a relationship of deals"
            addFirst={() => setIsCreateModal(!isCreateModal)}
            buttonText="Create Company"
            Feature1="Profile Creation"
            Feature2="Company Segmentation"
            Feature3="Import Company"
            list1="Create a comprehensive company profile in one click"
            list2="Differentiate your company based on industry, location"
            list3="Upload company list from any source"
            imageSrc={create}
          />
        </div>
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          modalText="Are you sure you want to delete ?"
          deleteAction={deleteCompany}
          idTobeDeleted={checked.length > 0 ? [...checked].join(',') : singleIdForDelete}
          type={DELETE_COMPANY}
        />
      )}
      {isCustomizeColumn && (
        <CustomizeTableColumn
          isModalOpen={isCustomizeColumn}
          setIsModalOpen={setIsCustomizeColumn}
          identifier="company"
        />
      )}
      {isCreateModal && <CreateCompanyModal isModalOpen={isCreateModal} setIsModalOpen={setIsCreateModal} />}
      {isImportModalOpen && <ImportModal isModalOpen={isImportModalOpen} setIsModalOpen={setIsImportModalOpen} />}
      {isMailModal && <MailLeadModal isModalOpen={isMailModal} setIsModalOpen={setIsMailModal} checked={checked} />}
      {isExportOpen && <ExportLeadModal isModalOpen={isExportOpen} setIsModalOpen={setIsExportOpen} />}
      {isEditModal && <EditCompanyModal isModalOpen={isEditModal} setIsModalOpen={setIsEditModal} checked={checked} />}
      <MergeCompanyModal isModalOpen={isMergeModal} setIsModalOpen={setIsMergeModal} mergeId={checked} />
      {previewModal && (
        <PreviewModal isModalOpen={previewModal} setIsModalOpen={setPreviewModal} getCompanyId={getCompanyId} />
      )}
    </>
  )
}

export default Companies
