import React from 'react'
import {KooperCard, KooperCardContent, KooperContainer, KooperImage} from 'kooper-library'
import meetConf from 'assets/meet-conf.svg'
import vlogo from 'assets/desk-v-logo.svg'

const MeetingConfrimd = props => {
  const {detail, heading, owner} = props.history.location.state
  return (
    <>
      <div className="meetBookPage">
        <KooperContainer>
          <h1>{heading}</h1>
          <KooperCard fluid className="meetBooking">
            <KooperCardContent className="meet-booked">
              <KooperImage src={meetConf} />
              <h1>Booking Confirmed</h1>
              <p className="mt-3 mb-0">
                {`You're booked with ${owner} on `}
                <span>{detail}</span>
              </p>
              <p className="mt-0 mb-4">An invitation has been emailed to you.</p>
            </KooperCardContent>
          </KooperCard>
          <div className="cal-branding">
            <a href="">
              <KooperImage src={vlogo} /> Create your own meeting booking page
            </a>
          </div>
        </KooperContainer>
      </div>
    </>
  )
}
export default MeetingConfrimd
