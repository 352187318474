import React from 'react'
import {Controller} from 'react-hook-form'
import {KooperDropdown, KooperForm, KooperFormField, KooperTooltip} from 'kooper-library'
import {removeDoubleQuotes} from 'utils/helper'
import {EmploymentStatus, EmploymentType} from 'constants/hr/employees'
import SvgIcon from 'components/common/SvgIcon'

const EmployeeHrInformation = props => {
  const {errors, control} = props

  return (
    <KooperForm className="errorLabel">
      <KooperFormField width={4}>
        <div className="info-header mb-1">
          <label>Employment Type</label>
          <KooperTooltip
            content="Select the type of your employee"
            trigger={<SvgIcon path="common/question" />}
            size="mini"
            position="top center"
          />
        </div>
        <Controller
          name="employmentType"
          render={({onChange, value}) => (
            <KooperDropdown
              fluid
              selection
              options={EmploymentType}
              value={value}
              onChange={(e, {value}) => {
                onChange(value)
              }}
              error={
                errors?.employmentType && {
                  content: removeDoubleQuotes(errors?.employmentType?.message),
                }
              }
            />
          )}
          control={control}
        />
      </KooperFormField>

      <KooperFormField width={4}>
        <div className="info-header mb-1">
          <label>Employment Status</label>
          <KooperTooltip
            content="Set status condition of employee"
            trigger={<SvgIcon path="common/question" />}
            size="mini"
            position="top center"
          />
        </div>
        <Controller
          name="employmentStatus"
          render={({onChange, value}) => (
            <KooperDropdown
              fluid
              selection
              options={EmploymentStatus}
              value={value}
              onChange={(e, {value}) => {
                onChange(value)
              }}
              error={
                errors?.employmentStatus && {
                  content: removeDoubleQuotes(errors?.employmentStatus?.message),
                }
              }
            />
          )}
          control={control}
        />
      </KooperFormField>
    </KooperForm>
  )
}

export default EmployeeHrInformation
