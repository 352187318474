/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCheckbox,
  KooperContainer,
  KooperDatePicker,
  KooperDivider,
  KooperDropdown,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperInput,
  KooperLabel,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
} from 'kooper-library'
import {GET_ALL_EMPLOYEES, GET_DEPARTMENT, GET_REVEIWER_LIST, SEND_APPRAISAL} from 'actions/types'
import {getReveiwerList, getSingleAppraisalTemplate, sendAppraisal} from 'actions/appraisal'
import {getAllEmployees, getDepartment} from 'actions/employees'
import {getFormattedDate, removeDoubleQuotes} from 'utils/helper'
import SearchComponent from 'components/common/SearchComponent'
import {sendAppraisalSchema} from 'validation/Hr/appraisal.schema'
import useApiResponse from 'hooks/impure/useApiResponse'
import {APPRAISALS_PATH} from 'constants/path'

const AppraisalSend = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {id: editId} = useParams()

  const [ReveiwerOption, setReveiwerOption] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDateForEmployee, setEndDateForEmployee] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [ids, setIds] = useState([])
  const [departmentList, setDepartmentList] = useState([])
  const [currentListParams, setCurrentListParams] = useState({
    department: '',
    text: '',
  })

  const {appraisalTemplateData} = useSelector(state => state.appraisal)
  const {successLabels = [], isLoadingData, type} = useSelector(state => state.apiReducer)

  const initialCreateInfo = {
    flow: '',
    reviewerId: null,
    isPrivate: false,
    setNotification: false,
    employeeIds: [],
  }

  const {errors, control, watch, setValue, handleSubmit} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(sendAppraisalSchema),
    defaultValues: initialCreateInfo,
  })

  const watchSetNotification = watch('setNotification')

  useEffect(() => {
    dispatch(getSingleAppraisalTemplate(editId))
  }, [dispatch, editId])

  useEffect(() => {
    if (successLabels.includes(SEND_APPRAISAL)) {
      history.push(`/${APPRAISALS_PATH.PREFIX}/${APPRAISALS_PATH.TEMPLATES}`)
    }
  }, [successLabels, history, dispatch])

  useApiResponse({
    action: getDepartment,
    enabled: true,
    label: GET_DEPARTMENT,
    storePath: 'employees.getDepartmentData',
    onSuccess: getDepartmentData => {
      const list = getDepartmentData.map(department => ({
        value: department.id,
        text: department.department,
        id: department.id,
      }))
      setDepartmentList(list)
    },
  })

  useApiResponse({
    action: getReveiwerList,
    enabled: true,
    label: GET_REVEIWER_LIST,
    storePath: 'appraisal.reveiwerList',
    onSuccess: reveiwerList => {
      const list = reveiwerList.map(employee => ({
        value: employee.id,
        text: `${employee.name}(${employee.employeeID})`,
        id: employee.id,
      }))
      setReveiwerOption(list)
    },
  })

  const {data: allEmployeesList = []} = useApiResponse({
    action: getAllEmployees,
    enabled: true,
    label: GET_ALL_EMPLOYEES,
    storePath: 'employees.allEmployeesList',
    onSuccess: () => {
      const list = allEmployeesList.map(({id}) => id)
      setIds(list)
    },
  })

  useEffect(() => {
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getAllEmployees(newCurrentListParams))
  }, [currentListParams, dispatch])

  const handleCheck = id => {
    const isChecked = ids.some(elpoyeeId => elpoyeeId === id)

    if (isChecked) {
      const removeChecked = ids.filter(elpoyeeId => elpoyeeId !== id)
      setIds(removeChecked)
    } else {
      setIds([...ids, id])
    }
  }

  const isChecked = id => {
    const check = ids.find(elpoyeeId => elpoyeeId === id)
    if (check) {
      return true
    }
    return false
  }

  const isCheckedAll = () => {
    const allIds = (allEmployeesList || []).map(elpoyeeId => elpoyeeId.id)

    const checkAll = _.xor(allIds, ids).length === 0 && allIds.length !== 0
    if (checkAll) {
      return true
    }
    return false
  }

  const handleCheckAll = () => {
    const allIds = allEmployeesList.map(elpoyeeId => elpoyeeId.id)

    if (_.xor(allIds, ids).length === 0) {
      setIds([])
    } else setIds(allIds)
  }

  useEffect(() => {
    if (ids) {
      setValue('employeeIds', ids)
    }
  }, [ids, setValue])

  const renderTableBody = () => {
    return allEmployeesList.map(({id, firstName, lastName, department, employmentStatus, createdAt}, index) => {
      return (
        <KooperTableRow key={id} className="tableLink">
          <KooperTableCell>{index + 1}</KooperTableCell>
          <KooperTableCell>
            <KooperCheckbox className="mr-3" value={id} onChange={() => handleCheck(id)} checked={isChecked(id)} />
            {`${firstName} ${lastName || ''}`}
          </KooperTableCell>
          <KooperTableCell>{department?.department}</KooperTableCell>
          <KooperTableCell>manager</KooperTableCell>
          <KooperTableCell>{getFormattedDate(createdAt)}</KooperTableCell>
          <KooperTableCell>
            <KooperLabel className="tag-label">{employmentStatus}</KooperLabel>
          </KooperTableCell>
        </KooperTableRow>
      )
    })
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <div>
          <KooperButton primary onClick={() => history.push(`/${APPRAISALS_PATH.PREFIX}/${APPRAISALS_PATH.TEMPLATES}`)}>
            Back
          </KooperButton>
        </div>
        <h3 className="m-0">Send Appraisals</h3>
        <div>
          <KooperButton primary onClick={handleSubmit(data => dispatch(sendAppraisal(editId, data)))}>
            Send Appraisals
          </KooperButton>
        </div>
      </div>

      <KooperForm className="errorLabel">
        <KooperContainer>
          <KooperCard fluid>
            <KooperCardContent>
              <div className="appraisalFormName">
                <h5>Appraisal Form Name:</h5>
                <span>{appraisalTemplateData?.name}</span>
              </div>
              <div className="appraisalFormDescription">
                <h5>Appraisal Form Description:</h5>
                <span>{appraisalTemplateData?.description}</span>
              </div>

              <KooperFormGroup>
                <KooperFormField required>
                  <label>Select Flow</label>
                  <Controller
                    name="flow"
                    render={({onChange, value}) => (
                      <KooperDropdown
                        selection
                        options={[
                          {value: 'upward', key: 'upward', text: 'Upward Appraisal'},
                          {value: 'downward', key: 'downward', text: 'Downward Appraisal'},
                        ]}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                      />
                    )}
                    control={control}
                  />
                  {errors.flow && <p className="errorLabelElement">{removeDoubleQuotes(errors?.flow?.message)}</p>}
                </KooperFormField>
                <KooperFormField required>
                  <label>Select Reviewer</label>
                  <Controller
                    name="reviewerId"
                    render={({onChange, value, onBlur}) => (
                      <KooperDropdown
                        options={ReveiwerOption}
                        selection
                        placeholder="Reveiwer Id"
                        onBlur={onBlur}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                      />
                    )}
                    control={control}
                  />
                  {errors.reviewerId && (
                    <p className="errorLabelElement">{removeDoubleQuotes(errors?.reviewerId?.message)}</p>
                  )}
                </KooperFormField>
              </KooperFormGroup>

              <KooperFormField>
                <Controller
                  control={control}
                  name="isPrivate"
                  render={({onChange, value}) => (
                    <KooperFormField>
                      <div className="d-flex">
                        <div>
                          <label className="m-0">Set as Private</label>
                          <p className="kooper-lead">Only admins with private appraisal access can view</p>
                        </div>
                        <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                      </div>
                    </KooperFormField>
                  )}
                />
              </KooperFormField>

              <KooperFormField>
                <Controller
                  control={control}
                  name="setNotification"
                  render={({onChange, value}) => (
                    <KooperFormField>
                      <div className="d-flex">
                        <div>
                          <label className="m-0">Set Notification</label>
                          <p className="kooper-lead">Enable notification for sending Appraisal</p>
                        </div>
                        <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                      </div>
                    </KooperFormField>
                  )}
                />
              </KooperFormField>

              {watchSetNotification ? (
                <KooperFormGroup>
                  <KooperFormField>
                    <label>Start Date</label>
                    <Controller
                      name="notificationTimeline.startDate"
                      render={({onChange, value}) => (
                        <KooperDatePicker
                          type="date"
                          value={typeof value === 'string' ? new Date(startDate) : startDate}
                          inputProps={{readOnly: true}}
                          time={false}
                          onChange={value => {
                            onChange(value)
                            setStartDate(value)
                          }}
                          error={
                            errors?.notificationTimeline?.startDate && {
                              content: removeDoubleQuotes(errors?.notificationTimeline?.startDate?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </KooperFormField>
                  <KooperFormField>
                    <label>End Date for Employees</label>
                    <Controller
                      name="notificationTimeline.endDateForEmployee"
                      render={({onChange, value}) => (
                        <KooperDatePicker
                          type="date"
                          value={typeof value === 'string' ? new Date(endDateForEmployee) : endDateForEmployee}
                          inputProps={{readOnly: true}}
                          time={false}
                          onChange={value => {
                            onChange(value)
                            setEndDateForEmployee(value)
                          }}
                          error={
                            errors?.notificationTimeline?.endDateForEmployee && {
                              content: removeDoubleQuotes(errors?.notificationTimeline?.endDateForEmployee?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </KooperFormField>
                  <KooperFormField>
                    <label>End Date</label>
                    <Controller
                      name="notificationTimeline.endDate"
                      render={({onChange, value}) => (
                        <KooperDatePicker
                          type="date"
                          value={typeof value === 'string' ? new Date(endDate) : endDate}
                          inputProps={{readOnly: true}}
                          time={false}
                          onChange={value => {
                            onChange(value)
                            setEndDate(value)
                          }}
                          error={
                            errors?.notificationTimeline?.endDate && {
                              content: removeDoubleQuotes(errors?.notificationTimeline?.endDate?.message),
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </KooperFormField>
                </KooperFormGroup>
              ) : null}
            </KooperCardContent>
          </KooperCard>

          <KooperCard fluid>
            <KooperCardContent>
              <h3 className="mb-0">Select The Reviewers</h3>
              <p className="mt-0 mb-4">Select reviewers who will be reviewing your appraisal</p>

              <KooperFormGroup widths="equal">
                <KooperFormField>
                  <label>Search Query</label>
                  <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
                </KooperFormField>

                <KooperFormField>
                  <label>By Department</label>
                  <KooperDropdown
                    selection
                    defaultValue="all"
                    options={[{key: 'all', value: 'all', text: 'All Department'}, <KooperDivider />, ...departmentList]}
                    onChange={(e, {value}) => {
                      setCurrentListParams({
                        ...currentListParams,
                        department: value,
                      })
                    }}
                  />
                </KooperFormField>
              </KooperFormGroup>

              <KooperFormGroup widths="equal">
                <KooperFormField>
                  <label>Resigned After</label>
                  <KooperInput />
                </KooperFormField>

                <KooperFormField>
                  <label>Joined Before</label>
                  <KooperInput />
                </KooperFormField>
              </KooperFormGroup>

              <KooperFormField>
                <label>Selection Type</label>
                <p className="kooper-lead">Selection types</p>
                <div>
                  <KooperCheckbox
                    label="Select All on Current Page"
                    checked={isCheckedAll()}
                    onChange={handleCheckAll}
                  />
                </div>
                {/* <div>
                  <KooperRadio
                    label="Select All From Entire List"
                    checked={active.selectAllFromEntirePage}
                    onClick={() =>
                      setActive({
                        ...active,
                        selectAllOnCurrentPage: false,
                        selectAllFromEntirePage: true,
                        deselectAll: false,
                      })
                    }
                  />
                </div>
                <div>
                  <KooperRadio
                    label="Deselect All"
                    checked={active.deselectAll}
                    onClick={() =>
                      setActive({
                        ...active,
                        selectAllOnCurrentPage: false,
                        selectAllFromEntirePage: false,
                        deselectAll: true,
                      })
                    }
                  />
                </div> */}
              </KooperFormField>

              <KooperTable sortable basic selectable>
                <KooperTableHeader>
                  <KooperTableHeaderCell>#</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Name</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Department</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Position</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Hired Date</KooperTableHeaderCell>
                  <KooperTableHeaderCell>Employment Status</KooperTableHeaderCell>
                </KooperTableHeader>
                <KooperTableBody>
                  {isLoadingData && type.includes(GET_ALL_EMPLOYEES) ? (
                    <KooperTablePlaceholder columns={6} />
                  ) : (allEmployeesList || []).length > 0 ? (
                    renderTableBody()
                  ) : (
                    <tr>
                      <td colSpan={6} className="emptyValue">
                        No Data Available
                      </td>
                    </tr>
                  )}
                </KooperTableBody>
              </KooperTable>
            </KooperCardContent>
          </KooperCard>
        </KooperContainer>
      </KooperForm>
    </div>
  )
}

export default AppraisalSend
