import React, {useState} from 'react'
import {
  KooperHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
} from 'kooper-library'
import {get, getTimeDifferenceFromNow} from 'utils/helper'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import ViewTask from './ViewTask'

const TableView = props => {
  const {tableData, totalPages, currentListParams, setCurrentListParams, projectId, rolePermissions} = props

  const [taskId, setTaskId] = useState(null)
  const [isViewTaskModalOpen, setIsViewTaskModalOpen] = useState(false)

  const renderTableBody = () => {
    if (tableData.length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="6" className="emptyValue">
            No Data Available
          </KooperTableCell>
        </KooperTableRow>
      )
    }
    return tableData.map((task, index) => (
      <KooperTableRow
        className="tableLink"
        key={task.id}
        onClick={() => {
          if (rolePermissions) {
            setTaskId(task.id)
            setIsViewTaskModalOpen(true)
          }
        }}
      >
        <KooperTableCell>{index + 1}</KooperTableCell>
        <KooperTableCell>
          <KooperHeader as="h5">{task.title}</KooperHeader>
        </KooperTableCell>
        <KooperTableCell>{getTimeDifferenceFromNow(task.startDate)}</KooperTableCell>
        <KooperTableCell>{getTimeDifferenceFromNow(task.endDate)}</KooperTableCell>
        <KooperTableCell>{get(['stageDetails', 'name'], task, '')}</KooperTableCell>
        <KooperTableCell>
          <div>{`${get(['assigneeDetails', 'firstName'], task, '')} ${get(
            ['assigneeDetails', 'lastName'],
            task,
            ''
          )}`}</div>
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <>
      <KooperTable basic selectable>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>#</KooperTableHeaderCell>
            <KooperTableHeaderCell>Title</KooperTableHeaderCell>
            <KooperTableHeaderCell>Start Date</KooperTableHeaderCell>
            <KooperTableHeaderCell>Due Date</KooperTableHeaderCell>
            <KooperTableHeaderCell>Stage</KooperTableHeaderCell>
            <KooperTableHeaderCell>Assignees</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>{renderTableBody()}</KooperTableBody>
      </KooperTable>
      {totalPages > 1 && (
        <KooperCustomPagination
          totalPages={totalPages}
          activePage={currentListParams.page}
          onPageChange={page => {
            setCurrentListParams(sort => ({...sort, page}))
          }}
        />
      )}

      {isViewTaskModalOpen && (
        <ViewTask open={isViewTaskModalOpen} toggle={setIsViewTaskModalOpen} projectId={projectId} taskId={taskId} />
      )}
    </>
  )
}

export default TableView
