import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperAttachment,
  KooperCard,
  KooperCardContent,
  KooperCardDescription,
  KooperCardHeader,
  KooperCardMeta,
  KooperCheckbox,
  KooperDropdown,
  KooperForm,
  KooperFormCheckbox,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperTooltip,
} from 'kooper-library'
import {UPDATE_CONFIGURE_WIDGET} from 'actions/types'
import {
  GetConfigureWidget,
  GetInstallationWidget,
  UpdateConfigureWidget,
  uploadConfigureLogo,
} from 'actions/settings_messenger'
import SettingLayout from 'layouts/settingLayout'
import {get, removeDoubleQuotes} from 'utils/helper'
import {languageOptions} from 'constants/variables'
import {configureDefaultValue, configureSchema} from 'validation/Settings/messenger.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'

const replyOptions = [
  {
    key: 'Typically reply in few minutes',
    text: 'Typically reply in few minutes',
    value: 'Typically reply in few minutes',
  },
  {
    key: 'Typically reply in few hours',
    text: 'Typically reply in few hours',
    value: 'Typically reply in few hours',
  },
  {
    key: 'Typically reply in few days',
    text: 'Typically reply in few days',
    value: 'Typically reply in few days',
  },
  {
    key: 'Do Not Show Any Message',
    text: 'Do Not Show Any Message',
    value: 'Do Not Show Any Message',
  },
  {
    key: 'Custom message',
    text: 'Custom message',
    value: 'Custom message',
  },
]

const Configure = ({setWarningModal}) => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    control,
    watch,
    reset,
    errors,
    setValue,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(configureSchema),
    defaultValues: configureDefaultValue,
  })

  const heading = watch('heading')
  const subHeading = watch('subHeading')
  const replyTime = watch('replyTime')
  const replyTimeInputValue = watch('replyTimeInputValue')
  const submitBtnText = watch('submitBtnText')

  const [active, setActive] = useState(true)
  const [captureVisitorInfo, setCaptureVisitorInfo] = useState(true)
  const [profilePic, setProfilePic] = useState('')
  const [files, setFiles] = useState(null)

  const {successLabels = []} = useSelector(store => store.apiReducer)
  const {installation = [], configure, attachmentInfo} = useSelector(store => store.settingsMessenger)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const configureLearnMore = settingsLearnMoreUrl?.messenger?.configure?.learnMore
  const singleConversationLearnMore = settingsLearnMoreUrl?.messenger?.configure?.singleConversationLearnMore

  const {channelsPermissions} = useUserPermissions()
  const manageMessangerPagesPermissions = channelsPermissions?.manage_messanger_pages

  useEffect(() => {
    dispatch(GetInstallationWidget())
  }, [dispatch])

  useEffect(() => {
    if (installation && installation[0]) dispatch(GetConfigureWidget(installation[0].id))
  }, [dispatch, installation])

  useEffect(() => {
    if (configure && configure.id) {
      setProfilePic(configure.profilePic ? configure.profilePic : '')
      const data = {
        heading: get(['heading'], configure, ''),
        subHeading: get(['subHeading'], configure, ''),
        language: get(['language'], configure, 'en'),
        msgTimeout: get(['msgTimeout'], configure, 60),
        replyTime: get(['replyTime'], configure, ''),
        showTeamMembers: get(['showTeamMembers'], configure, true),
        singleConversation: get(['singleConversation'], configure, false),
        emailFollowup: get(['emailFollowup'], configure, false),
        isName: get(['isName'], configure, true),
        isEmail: get(['isEmail'], configure, true),
        isNumber: get(['isNumber'], configure, false),
        submitBtnText: get(['submitBtnText'], configure, 'Submit'),
      }
      if (
        configure.replyTime !== 'Typically reply in few minutes' &&
        configure.replyTime !== 'Typically reply in few hours' &&
        configure.replyTime !== 'Typically reply in few days' &&
        configure.replyTime !== 'Do Not Show Any Message'
      ) {
        data.replyTimeInputValue = configure.replyTime ? configure.replyTime : ' '
        data.replyTime = 'Custom message'
      } else {
        data.replyTimeInputValue = '  '
      }
      reset(data)
    } else {
      reset(configureDefaultValue)
    }
  }, [configure, reset])

  useEffect(() => {
    if (attachmentInfo) {
      setProfilePic(attachmentInfo.map(val => val?.url)?.[0])
      setFiles(attachmentInfo.map(val => val?.name)?.[0])
    }
  }, [attachmentInfo])

  useEffect(() => {
    if (successLabels.includes(UPDATE_CONFIGURE_WIDGET)) {
      setWarningModal(false)
    }
  }, [successLabels, setWarningModal])

  useEffect(() => {
    if (isDirty) setWarningModal(isDirty)
    return () => {
      setWarningModal(false)
    }
  }, [isDirty, setWarningModal])

  const resetData = () => {
    if (configure && configure.id) {
      setProfilePic(configure.profilePic ? configure.profilePic : '')
      const data = {
        heading: configure.heading === null ? '' : configure.heading,
        subHeading: configure.subHeading === null ? '' : configure.subHeading,
        language: get(['language'], configure, 'en'),
        msgTimeout: get(['msgTimeout'], configure, 60),
        replyTime: configure.replyTime === null ? '' : configure.replyTime,
        showTeamMembers: get(['showTeamMembers'], configure, true),
        singleConversation: get(['singleConversation'], configure, false),
        emailFollowup: get(['emailFollowup'], configure, false),
        isName: get(['isName'], configure, true),
        isEmail: get(['isEmail'], configure, true),
        isNumber: get(['isNumber'], configure, false),
        submitBtnText: get(['submitBtnText'], configure, 'Submit'),
      }
      if (
        configure.replyTime !== 'Typically reply in few minutes' &&
        configure.replyTime !== 'Typically reply in few hours' &&
        configure.replyTime !== 'Typically reply in few days' &&
        configure.replyTime !== 'Do Not Show Any Message'
      ) {
        data.replyTimeInputValue = configure.replyTime ? configure.replyTime : ' '
        data.replyTime = 'Custom message'
      } else {
        data.replyTimeInputValue = '  '
      }
      reset(data)
    } else {
      reset(configureDefaultValue)
    }
  }

  const updateData = data => {
    if (data.replyTime === 'Custom message') data.replyTime = data.replyTimeInputValue
    delete data.replyTimeInputValue
    if (attachmentInfo?.map(val => val?.url)) data.profilePic = profilePic
    if (configure.id) dispatch(UpdateConfigureWidget(configure.id, data))
    dispatch({type: UPDATE_CONFIGURE_WIDGET, data})
    setWarningModal(false)
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/configure" />}
      header="Configure"
      subHeader="Configure your chat messenger for better customer engagement"
      learnMoreUrl={configureLearnMore}
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Reset',
          onClick: resetData,
        },
        success: {
          content: 'Save',
          disabled: !isDirty && active,
          ...(manageMessangerPagesPermissions && {
            onClick: handleSubmit(e => updateData(e)),
          }),
        },
      }}
      lockRole={!manageMessangerPagesPermissions}
    >
      <KooperForm className="errorLabel">
        <Controller
          control={control}
          name="heading"
          render={({onChange, value}) => (
            <KooperFormField required width={8}>
              <label>Heading</label>
              <KooperFormInput
                placeholder="Headline for your Messenger..."
                value={value}
                maxLength={30}
                onChange={(e, {value}) => onChange(value)}
                error={
                  errors.heading && {
                    content: removeDoubleQuotes(errors.heading.message),
                  }
                }
              />
            </KooperFormField>
          )}
        />
        <Controller
          control={control}
          name="subHeading"
          render={({onChange, value}) => (
            <KooperFormField required width={8}>
              <label>Sub-Heading</label>
              <KooperFormInput
                placeholder="Sub-Headline for your messenger..."
                value={value}
                maxLength={30}
                onChange={(e, {value}) => onChange(value)}
                error={
                  errors.subHeading && {
                    content: removeDoubleQuotes(errors.subHeading.message),
                  }
                }
              />
            </KooperFormField>
          )}
        />
        <Controller
          control={control}
          name="language"
          render={({onChange, value}) => (
            <KooperFormField required width={8}>
              <label>Select Language</label>
              <KooperDropdown
                placeholder="Select Language"
                search
                selection
                options={languageOptions}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                error={
                  errors.language && {
                    content: removeDoubleQuotes(errors.language.message),
                  }
                }
              />
            </KooperFormField>
          )}
        />
        <KooperFormField className="custom-upload-btn">
          <label>Upload Logo</label>
          <p className="kooper-lead pt-1">Supports JPEG and PNG of max 5MB Scales upto 128px*32px.</p>
          <div className="attachBox">
            {manageMessangerPagesPermissions ? (
              <KooperAttachment
                basic
                type="file"
                content={
                  <>
                    <SvgIcon path="common/attachment" /> Upload Logo
                  </>
                }
                accept=".jpeg,.png,.jpg"
                // success="replyAttachment"
                onClick={e => {
                  e.target.value = null
                }}
                onChange={value => {
                  const data = {
                    file: value,
                  }
                  if (value.length > 0) {
                    dispatch(uploadConfigureLogo(data))
                    setActive(false)
                  }
                  value = null
                }}
                maxSize={5120}
                // fileList={(files) => {
                //   setFiles(files);
                // }}
              />
            ) : (
              <LockPermissionTooltip isRoleAccessDenied={!manageMessangerPagesPermissions}>
                <button type="button" className="ui icon button attach">
                  <SvgIcon path="common/attachment" /> Upload Logo
                </button>
              </LockPermissionTooltip>
            )}
          </div>
          <p>{files}</p>
        </KooperFormField>
        <Controller
          control={control}
          name="replyTime"
          render={({onChange, value}) => (
            <KooperFormField required width={8}>
              <div className="info-header">
                <label>Set Typical Reply Time</label>
                <KooperTooltip
                  content="Show how soon your team can reply to the messages"
                  size="mini"
                  position="top center"
                  trigger={<SvgIcon path="common/question" />}
                />
              </div>
              <KooperDropdown selection options={replyOptions} value={value} onChange={(e, {value}) => onChange(value)} />
            </KooperFormField>
          )}
        />
        {replyTime === 'Custom message' && (
          <Controller
            control={control}
            name="replyTimeInputValue"
            render={({onChange, value}) => (
              <KooperFormField required width={8}>
                <KooperFormInput
                  placeholder="Enter Custom Message..."
                  value={value}
                  className="mt-3 mb-0"
                  maxLength={30}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.replyTimeInputValue && {
                      content: removeDoubleQuotes(errors.replyTimeInputValue.message),
                    }
                  }
                />
              </KooperFormField>
            )}
          />
        )}

        <KooperCard fluid className="switchBox">
          <KooperCardContent>
            <KooperCardHeader>Timing</KooperCardHeader>
            <KooperCardDescription>
              Manage your timing settings for your messenger on your integrated page.
            </KooperCardDescription>
            <KooperForm className="mt-4">
              <KooperFormField>
                <div className="info-header">
                  <label>Close Message Popup</label>
                  <KooperTooltip
                    content="Set time for the close message popup"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <KooperFormGroup inline>
                  <Controller
                    control={control}
                    name="msgTimeout"
                    render={({onChange, value}) => (
                      <KooperFormInput
                        style={{width: '57px'}}
                        className="miniInput"
                        placeholder="00"
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={
                          errors.msgTimeout && {
                            content: removeDoubleQuotes(errors.msgTimeout.message),
                          }
                        }
                      />
                    )}
                  />
                  <p>second</p>
                </KooperFormGroup>
              </KooperFormField>
            </KooperForm>
          </KooperCardContent>
        </KooperCard>
      </KooperForm>

      <KooperCard fluid className="switchBox">
        <KooperCardContent>
          <div className="d-flex">
            <div>
              <h5> Show recently active team members (up to 3)</h5>
              <p className="kooper-lead">Show last active agents information</p>
            </div>
            <Controller
              control={control}
              name="showTeamMembers"
              render={({onChange, value}) => (
                <KooperFormCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
              )}
            />
          </div>
        </KooperCardContent>
        <KooperCardContent>
          <div className="d-flex">
            <div>
              <h5>Only allow single conversation</h5>
              <p className="kooper-lead">
                Only allow creation of one conversation per user. <a href={singleConversationLearnMore}>Learn More</a>{' '}
                of its impacts
              </p>
            </div>
            <Controller
              control={control}
              name="singleConversation"
              render={({onChange, value}) => (
                <KooperFormCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
              )}
            />
          </div>
        </KooperCardContent>
        <KooperCardContent>
          <div className="d-flex">
            <div>
              <h5>Email Followup</h5>
              <p className="kooper-lead">Enable email followup</p>
            </div>
            <Controller
              control={control}
              name="emailFollowup"
              render={({onChange, value}) => (
                <KooperFormCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
              )}
            />
          </div>
        </KooperCardContent>
        <KooperCardContent>
          <div className="d-flex">
            <div>
              <h5>Capture Visitors Information</h5>
              <p className="kooper-lead">Capture the information of the visitor mentioned in below toggles</p>
            </div>
            <KooperFormCheckbox
              toggle
              checked={captureVisitorInfo}
              onChange={(e, value) => {
                if (value.checked === false) {
                  setValue('isName', false, {
                    shouldValidate: true,
                  })
                  setValue('isEmail', false, {
                    shouldValidate: true,
                  })
                  setValue('isNumber', false, {
                    shouldValidate: true,
                  })
                  setValue('submitBtnText', '', {
                    shouldValidate: true,
                  })
                  // setValue("submitMsg", "", {
                  //   shouldValidate: true,
                  // });
                } else if (value.checked === true) {
                  setValue('isName', true, {
                    shouldValidate: true,
                  })
                  setValue('isEmail', true, {
                    shouldValidate: true,
                  })
                  setValue('isNumber', get(['isNumber'], configure, true), {
                    shouldValidate: true,
                  })
                  setValue('submitBtnText', get(['submitBtnText'], configure, ''), {
                    shouldValidate: true,
                  })
                }
                setCaptureVisitorInfo(value.checked)
              }}
            />
          </div>
          <div className="smallForm" style={{display: captureVisitorInfo ? 'block' : 'none'}}>
            <KooperForm className="errorLabel">
              <Controller
                control={control}
                name="isName"
                render={({onChange}) => (
                  <KooperFormField>
                    <label>
                      Name
                      <KooperCheckbox toggle disabled checked="true" onChange={(e, {checked}) => onChange(checked)} />
                    </label>
                  </KooperFormField>
                )}
              />
              <Controller
                control={control}
                name="isEmail"
                render={({onChange}) => (
                  <KooperFormField>
                    <label>
                      Email
                      <KooperCheckbox toggle disabled checked="true" onChange={(e, {checked}) => onChange(checked)} />
                    </label>
                  </KooperFormField>
                )}
              />
              <Controller
                control={control}
                name="isNumber"
                render={({onChange, value}) => (
                  <KooperFormField>
                    <label>
                      Phone No
                      <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    </label>
                  </KooperFormField>
                )}
              />
              <KooperFormGroup>
                <Controller
                  control={control}
                  name="submitBtnText"
                  render={({onChange, value}) => (
                    <KooperFormField width={16}>
                      <label>Submit Button Text</label>
                      <p className="kooper-lead">Message to be displayed on submit button</p>
                      <KooperFormInput
                        placeholder="e.g. Submit"
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        maxLength={20}
                        error={
                          errors.submitBtnText && {
                            content: removeDoubleQuotes(errors.submitBtnText.message),
                          }
                        }
                      />
                    </KooperFormField>
                  )}
                />
              </KooperFormGroup>
            </KooperForm>
          </div>
        </KooperCardContent>
      </KooperCard>
    </SettingLayout>
  )
}

export default Configure
