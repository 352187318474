import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import _ from 'lodash'
import {
  KooperModal,
  KooperModalHeader,
  KooperModalContent,
  KooperForm,
  KooperFormField,
  KooperSearch,
  KooperTable,
  KooperTableHeader,
  KooperTableRow,
  KooperTableHeaderCell,
  KooperTableBody,
  KooperTableCell,
  KooperFormSelect,
  KooperInput,
  KooperDropdown,
  KooperModalActions,
  KooperButton,
} from 'kooper-library'
import {editDeal} from 'actions/deals'
import {getProductlist} from 'actions/productLibrary'
import SvgIcon from 'components/common/SvgIcon'
import {billingFreqOptions} from '../Products/ProductsModal'

const testIsNumber = value => /^\d{0,8}(\.\d{1,2})?$/g.test(value)
const testIsPercentage = value => /^[0-9][0-9]?(\.\d+)?$|^100$/.test(value)

const getUnitTotal = (totalPrice, discountType, discountValue) => {
  if (!testIsNumber(totalPrice)) return 0

  if (discountType === '%') {
    if (!testIsPercentage(discountValue)) return 0

    return Number((totalPrice * ((100 - discountValue) / 100)).toFixed(2))
  }

  if (discountType === 'USD') {
    if (!testIsNumber(discountValue)) return 0

    return Number((totalPrice - discountValue).toFixed(2))
  }
}

const typeOptions = [
  {key: '%', value: '%', text: '%'},
  {
    key: 'USD',
    value: 'USD',
    text: 'USD',
  },
]

function AddProductModel(props) {
  const dispatch = useDispatch()
  const {isModalOpen, setIsModalOpen, dealId} = props
  const [optionsToShow, setOptionsToShow] = useState([])
  const [searchValue, setSearchValue] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchBoxOptions, setSearchBoxOptions] = useState([])

  const {handleSubmit} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
  })

  const {productList = []} = useSelector(state => state.productLibrary)

  useEffect(() => {
    if (!productList?.length) dispatch(getProductlist())
    // return () => {
    //   buttonRef.current.disabled = false;
    // };
  }, [])

  useEffect(() => {
    if (productList.length) {
      const options = productList.map(item => {
        return {
          productId: item.id,
          title: item.name,
          description: item.description,
          price: item.sku,
          quantity: 1,
          billingFreq: item.billingFreq,
          unitPrice: Number(item.unitPrice),
          discountType: '%',
          discountValue: 0,
        }
      })
      setSearchBoxOptions(options)
    }
  }, [productList])

  const handleResultSelect = (e, {result}) => {
    setSearchValue(searchValue => {
      if (searchValue.some(sv => sv.productId === result.productId)) {
        return searchValue
      }
      return [...searchValue, result]
    })
    setSearchText('')
    setOptionsToShow([])
  }

  const handleSearchChange = (e, {value}) => {
    setSearchText(value)
    setTimeout(() => {
      if (value.length < 1) {
        setOptionsToShow(searchBoxOptions)
        setSearchText('')
        setSearchValue([])
        return
      }
      const re = new RegExp(_.escapeRegExp(searchText), 'i')
      const isMatch = result => re.test(result.title)
      setOptionsToShow(_.filter(searchBoxOptions, isMatch))
    }, 300)
  }

  const changeSearchValue = (index, value, property) => {
    setSearchValue(searchValue => {
      const tempSearchValue = [...searchValue]
      const newValue = {
        ...tempSearchValue[index],
        [property]: value,
      }
      tempSearchValue.splice(index, 1, newValue)
      return tempSearchValue
    })
  }

  const createProductBtn = data => {
    data.products = searchValue

    data.products.map(val => {
      val.unitTotal = getUnitTotal(val.quantity * val.unitPrice, val.discountType, val.discountValue)
      delete val.description
      delete val.title
      delete val.price
    })

    dispatch(editDeal(data, dealId))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={isModalOpen}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <KooperModalHeader>Add New Product</KooperModalHeader>
      <KooperModalContent scrolling>
        <KooperForm>
          <KooperFormField>
            <label>Select Product & Services</label>
            <p className="kooper-lead">
              Select your product or service for which you would like to make quote. You can add the product{' '}
              <a href="/setting/all-products" target="_blank">
                here
              </a>
              .
            </p>
            <KooperSearch
              placeholder="Search to get needed results"
              minCharacters={0}
              results={optionsToShow}
              value={searchText}
              onResultSelect={handleResultSelect}
              onSearchChange={_.debounce(handleSearchChange, 500, {
                leading: true,
              })}
            />
          </KooperFormField>
          <h5 className="mb-0">Review Line Items</h5>
          <p className="kooper-lead mt-1">Review the line items you want shown in your quote.</p>
          <KooperTable basic className="billProduct">
            <KooperTableHeader>
              <KooperTableRow>
                <KooperTableHeaderCell>#</KooperTableHeaderCell>
                <KooperTableHeaderCell>Product Name</KooperTableHeaderCell>
                <KooperTableHeaderCell>SKU</KooperTableHeaderCell>
                <KooperTableHeaderCell>Billing Frequency</KooperTableHeaderCell>
                <KooperTableHeaderCell>Quantity</KooperTableHeaderCell>
                <KooperTableHeaderCell>Unit Price</KooperTableHeaderCell>
                <KooperTableHeaderCell>Unit Discount (%)</KooperTableHeaderCell>
                <KooperTableHeaderCell>Net Price</KooperTableHeaderCell>
              </KooperTableRow>
            </KooperTableHeader>
            <KooperTableBody>
              {searchValue?.length < 1 ? (
                <tr>
                  <td colSpan="8" className="emptyValue required">
                    Add Line Items to Continue Filling Form
                  </td>
                </tr>
              ) : (
                searchValue.map((item, index) => {
                  return (
                    <KooperTableRow key={`tablerow${index}`}>
                      <KooperTableCell>{index + 1}</KooperTableCell>

                      <KooperTableCell>{item.title}</KooperTableCell>

                      <KooperTableCell>{item.price}</KooperTableCell>

                      <KooperTableCell>
                        <KooperFormSelect
                          fluid
                          options={billingFreqOptions}
                          value={item.billingFreq}
                          onChange={(e, {value}) => changeSearchValue(index, value, 'billingFreq')}
                        />
                      </KooperTableCell>

                      <KooperTableCell>
                        <KooperInput
                          // type="number"
                          min={1}
                          value={item.quantity}
                          onChange={(e, {value}) => changeSearchValue(index, Number(value), 'quantity')}
                          style={{width: 'auto'}}
                          onBlur={() => {
                            if (!testIsNumber(item.quantity)) {
                              changeSearchValue(index, '0', 'quantity')
                            }
                          }}
                        />
                      </KooperTableCell>

                      <KooperTableCell>
                        <KooperInput
                          style={{width: 'auto'}}
                          value={item.unitPrice}
                          onChange={(e, {value}) => changeSearchValue(index, Number(value), 'unitPrice')}
                          onBlur={() => {
                            if (!testIsNumber(item.unitPrice)) {
                              changeSearchValue(index, '0', 'unitPrice')
                            }
                          }}
                        />
                      </KooperTableCell>

                      <KooperTableCell>
                        <KooperInput
                          style={{width: 'min-content'}}
                          value={item.discountValue}
                          onChange={(e, {value}) => changeSearchValue(index, Number(value), 'discountValue')}
                          onBlur={() => {
                            if (item.discountType === '%') {
                              if (!testIsPercentage(item.discountValue)) {
                                changeSearchValue(index, '0', 'discountValue')
                              }
                            } else if (!testIsNumber(item.discountValue)) {
                              changeSearchValue(index, '0', 'discountValue')
                            }
                          }}
                          label={
                            <KooperDropdown
                              options={typeOptions}
                              value={item.discountType}
                              onChange={(e, {value}) => changeSearchValue(index, value, 'discountType')}
                            />
                          }
                        />
                      </KooperTableCell>
                      <KooperTableCell>
                        {getUnitTotal(item.quantity * item.unitPrice, item.discountType, item.discountValue)}
                      </KooperTableCell>
                    </KooperTableRow>
                  )
                })
              )}
            </KooperTableBody>
          </KooperTable>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(createProductBtn)}>
          Add
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default AddProductModel
