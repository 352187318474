import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import VendorsList from 'components/pages/Finance/Vendors/VendorsList'
import VendorsFields from 'components/pages/Finance/Vendors/Settings/Fields/Fields'
import Category from 'components/pages/Finance/Vendors/Settings/Category/Category'
import {VENDORS_PATH} from 'constants/path'

const routes = [
  {path: VENDORS_PATH.ALL, component: VendorsList, title: 'All Vendors', exact: true},
  {path: VENDORS_PATH.CATEGORY, component: Category, title: 'Category', exact: true},
  {path: VENDORS_PATH.FIELDS, component: VendorsFields, title: 'Fields', exact: true},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={`/${VENDORS_PATH.PREFIX}/${path}`}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} vendor />
        <Component {...props} />
      </>
    )}
  />
)

const VendorsRoutes = () => (
  <Switch>
    <Route exact path={`/${VENDORS_PATH.PREFIX}`}>
      <Redirect to={`/${VENDORS_PATH.PREFIX}/${VENDORS_PATH.ALL}`} />
    </Route>
    {routes.map(renderRoute)}
    <Route path={`/${VENDORS_PATH.PREFIX}`}>
      <Redirect to={`/${VENDORS_PATH.PREFIX}/${VENDORS_PATH.ALL}`} />
    </Route>
  </Switch>
)

export default VendorsRoutes
