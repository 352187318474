import SvgIcon from 'components/common/SvgIcon'
import _ from 'lodash'
import React from 'react'
import {Controller, useFieldArray} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperForm,
  KooperFormDropdown,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperFormRadio,
  KooperHeader,
  KooperTabPane,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

const billingBasedOnOptions = [
  {
    value: 'Fixed_Cost',
    text: 'Fixed Cost',
    key: 'Fixed_Cost',
  },
  {
    value: 'Specific_Cost',
    text: 'Specific Agent Cost',
    key: 'Specific_Cost',
  },
]

const BillingPane = ({control, errors, isBillableActive, billingType, list}) => {
  const {getUsersList: {users = []} = {}} = useSelector(state => state.settings)

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'users',
  })

  return (
    <KooperTabPane>
      <KooperCard fluid className="switchBox">
        <KooperCardContent>
          <div className="d-flex">
            <div>
              <label>Billing Preferences</label>
              <p className="kooper-lead" style={{width: '100%'}}>
                Agents can manually run the timer on tickets
              </p>
            </div>
            <Controller
              control={control}
              name="isBillable"
              render={({value, onChange}) => (
                <div className="d-flex">
                  <KooperFormRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                </div>
              )}
            />
          </div>
        </KooperCardContent>
        <KooperCardContent>
          <KooperForm className="errorLabel">
            <KooperFormGroup>
              <KooperFormField fluid disabled={!isBillableActive} width={billingType === 'Fixed_Cost' ? 8 : 12}>
                <label>Bill based on</label>
                <Controller
                  control={control}
                  name="billingType"
                  render={({value, onChange}) => (
                    <KooperFormDropdown
                      selection
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      placeholder="Select"
                      options={billingBasedOnOptions}
                    />
                  )}
                />
              </KooperFormField>

              {billingType === 'Fixed_Cost' && (
                <KooperFormField disabled={!isBillableActive} width={4} required>
                  <label>Enter Cost of Tickets (INR)</label>
                  <Controller
                    control={control}
                    name="fixedCost"
                    render={({value, onChange, onBlur}) => (
                      <KooperFormInput
                        required
                        placeholder="Enter the Fixed Cost"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        onBlur={onBlur}
                        error={
                          errors.fixedCost && {
                            content: errors.fixedCost.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
              )}
            </KooperFormGroup>

            {billingType === 'Specific_Cost' && (
              <>
                {fields.map(({id, cost}, index) => {
                  return (
                    <KooperFormGroup style={{alignItems: 'flex-end'}}>
                      <KooperFormField required disabled={!isBillableActive} width={6}>
                        <label>Users</label>
                        <Controller
                          control={control}
                          name={`users[${index}].id`}
                          defaultValue={id}
                          render={({value, onChange}) => (
                            <KooperFormDropdown
                              selection
                              value={value}
                              onChange={(e, {value}) => onChange(value)}
                              placeholder="Select User"
                              options={users?.map(list => ({
                                value: list.id,
                                text: `${list.firstName} ${list.lastName}`,
                                key: list.id,
                              }))}
                              error={
                                _.get(errors, `users[${index}].id`) && {
                                  content: errors.users[index].id.message,
                                }
                              }
                            />
                          )}
                        />
                      </KooperFormField>
                      <KooperFormField required disabled={!isBillableActive} width={6}>
                        <label>Enter Cost of Tickets (INR)</label>
                        <Controller
                          control={control}
                          name={`users[${index}].cost`}
                          defaultValue={cost}
                          render={({value, onChange}) => (
                            <KooperFormInput
                              placeholder="Enter the Cost"
                              value={value}
                              onChange={e => onChange(e.target.value)}
                              error={
                                _.get(errors, `users[${index}].cost`) && {
                                  content: errors.users[index].cost.message,
                                }
                              }
                            />
                          )}
                        />
                      </KooperFormField>
                      <KooperTooltip
                        position="top center"
                        content="Delete"
                        size="mini"
                        trigger={
                          <KooperButton
                            className="bg-btn-color"
                            icon
                            onClick={() => remove(index)}
                            disabled={!isBillableActive}
                          >
                            <SvgIcon path="common/delete" />
                          </KooperButton>
                        }
                      />
                    </KooperFormGroup>
                  )
                })}
                <KooperTooltip
                  position="top center"
                  content="Add Agent"
                  trigger={
                    <KooperButton
                      className="bg-btn-color"
                      icon
                      onClick={() => append({id: '', cost: ''})}
                      disabled={!isBillableActive}
                    >
                      <SvgIcon path="common/plus" />
                    </KooperButton>
                  }
                  size="mini"
                />
              </>
            )}
          </KooperForm>
          <KooperTable basic selectable className="mt-3">
            <KooperTableHeader>
              <KooperTableRow>
                <KooperTableHeaderCell className="pl-4">#</KooperTableHeaderCell>
                <KooperTableHeaderCell>User</KooperTableHeaderCell>
                <KooperTableHeaderCell>Cost Per Hour</KooperTableHeaderCell>
              </KooperTableRow>
            </KooperTableHeader>
            <KooperTableBody>
              {list?.map((list, index) => (
                <KooperTableRow className="tableLink" key={list.id}>
                  <KooperTableCell className="pl-4">{index + 1}</KooperTableCell>
                  <KooperTableCell>
                    <KooperHeader as="h5">{list.name}</KooperHeader>
                  </KooperTableCell>
                  <KooperTableCell>{list.cost}</KooperTableCell>
                </KooperTableRow>
              ))}
            </KooperTableBody>
          </KooperTable>
        </KooperCardContent>
      </KooperCard>
      <a>Billing History</a>
    </KooperTabPane>
  )
}

export default BillingPane
