import SvgIcon from 'components/common/SvgIcon'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCardHeader,
  KooperDropdown,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperTooltip,
} from 'kooper-library'
import {useState} from 'react'
import {Controller} from 'react-hook-form'

const KeyResultCreatCard = ({control}) => {
  const [keyResults, setKeyResults] = useState(0)
  return (
    <KooperCard fluid>
      <KooperCardContent>
        <KooperCardHeader>Key results</KooperCardHeader>
        <KooperFormField>
          <label>
            How will you achieve this goal? Break down your goal into specific and measurable steps to help you get
            there.
          </label>
        </KooperFormField>
        <KooperFormField>
          {Array.from({length: keyResults}, (_, i) => i).map(int => (
            <div>
              <KooperFormGroup>
                <KooperFormField width={8}>
                  <div className="info-header">
                    <label>Key Result Title</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Key result title"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="title"
                    control={control}
                    render={({onChange, value}) => (
                      <KooperFormInput
                        maxLength={20}
                        placeholder="e.g. By the end of year, improve net paid users by 10%"
                      />
                    )}
                  />
                </KooperFormField>
              </KooperFormGroup>
              <KooperFormGroup>
                <KooperFormField width={8}>
                  <div className="info-header">
                    <label>Select Metric</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Select metric"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <KooperDropdown
                    placeholder="Select goal owner"
                    fluid
                    selection
                    defaultValue="Numeric"
                    options={[
                      {key: 'Numeric', text: 'Numeric', value: 'Numeric'},
                      {key: 'Percentage', text: 'Percentage', value: 'Percentage'},
                      {key: 'Yes/No', text: 'Yes/No', value: 'Yes/No'},
                    ]}
                  />
                </KooperFormField>
              </KooperFormGroup>
              <KooperFormGroup>
                <KooperFormField width={8}>
                  <div className="info-header">
                    <label>Start</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Start"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="Start"
                    control={control}
                    render={({onChange, value}) => <KooperFormInput placeholder="" />}
                  />
                </KooperFormField>
                <KooperFormField width={8}>
                  <div className="info-header">
                    <label>Target</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Target"
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="Target"
                    control={control}
                    render={({onChange, value}) => <KooperFormInput placeholder="" />}
                  />
                </KooperFormField>
              </KooperFormGroup>
            </div>
          ))}
          <KooperButton onClick={() => setKeyResults(keyResults + 1)} basic content="+ Add key result" />
        </KooperFormField>
      </KooperCardContent>
    </KooperCard>
  )
}

export default KeyResultCreatCard
