import React from 'react'
import {Redirect, Route, Switch} from 'react-router'
import Announcements from 'components/pages/Customers/ClientPortal/Announcements'
import Customization from 'components/pages/Customers/ClientPortal/Customization'
import General from 'components/pages/Customers/ClientPortal/General'
import Tickets from 'components/pages/Customers/ClientPortal/Tickets'
import DocumentTitle from 'components/common/DocumentTitle'
import {CLIENT_PORTAL_PATH} from 'constants/path'

const routes = [
  // Client Portal
  {
    path: CLIENT_PORTAL_PATH.ANNOUNCEMENTS,
    component: Announcements,
    title: 'Announcements',
    exact: true,
  },
  {
    path: CLIENT_PORTAL_PATH.CUSTOMIZATION,
    component: Customization,
    title: 'Customization',
    exact: true,
  },
  // Settings
  {
    path: CLIENT_PORTAL_PATH.GENERAL,
    component: General,
    title: 'General',
    exact: true,
  },
  {
    path: CLIENT_PORTAL_PATH.TICKETS,
    component: Tickets,
    title: 'Tickets',
    exact: true,
  },
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={`/${CLIENT_PORTAL_PATH.PREFIX}/${path}`}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} clientPortal />
        <Component {...props} />
      </>
    )}
  />
)

const ClientPortalRoute = () => (
  <Switch>
    <Route exact path={`/${CLIENT_PORTAL_PATH.PREFIX}`}>
      <Redirect to={`/${CLIENT_PORTAL_PATH.PREFIX}/${CLIENT_PORTAL_PATH.ANNOUNCEMENTS}`} />
    </Route>
    {routes.map(renderRoute)}
    <Route path={`/${CLIENT_PORTAL_PATH.PREFIX}/*`}>
      <Redirect to={`/${CLIENT_PORTAL_PATH.PREFIX}/${CLIENT_PORTAL_PATH.ANNOUNCEMENTS}`} />
    </Route>
  </Switch>
)

export default ClientPortalRoute
