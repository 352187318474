import React from 'react'
import {KooperTab, KooperTabPane} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'
import MyActivities from 'components/pages/Home/MyActivities'
import Overview from 'components/pages/Home/Overview'

const Home = () => {
  const panes = [
    {
      menuItem: {
        key: 'overview',
        icon: <SvgIcon path="home/overview" />,
        content: 'Overview',
      },
      render: () => (
        <KooperTabPane>
          <Overview />
        </KooperTabPane>
      ),
    },
    {
      menuItem: {
        key: 'myActivities',
        icon: <SvgIcon path="home/overview" />,
        content: 'My Activities',
      },
      render: () => (
        <KooperTabPane>
          <MyActivities />
        </KooperTabPane>
      ),
    },
  ]

  return (
    <>
      <div className="home-steps-container">
        <KooperTab menu={{secondary: true, pointing: true, color: 'blue'}} panes={panes} />
      </div>
    </>
  )
}

export default Home
