import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import _ from 'lodash'
import {
  KooperHeader,
  KooperHeaderSubheader,
  KooperImage,
  KooperMenuItem,
  KooperSearch,
  KooperTooltip,
} from 'kooper-library'
import {
  globalSearchCustomers,
  globalSearchFinance,
  globalSearchHr,
  globalSearchInbox,
  globalSearchProjects,
  globalSearchSales,
} from 'actions'
import {
  GLOBAL_SEARCH_CUSTOMERS,
  GLOBAL_SEARCH_FINANCE,
  GLOBAL_SEARCH_INBOX,
  GLOBAL_SEARCH_PROJECTS,
  GLOBAL_SEARCH_SALES,
} from 'actions/types'
import {get, startCase, wrapText} from 'utils/helper'
import inbox from 'assets/nav-inbox.svg'
import contact from 'assets/nav-contact.svg'
import SvgIcon from 'components/common/SvgIcon'
import {FiSearch} from 'react-icons/fi'
import {
  ANNOUNCEMENTS_PATH,
  BUDGETS_PLANNER_PATH,
  CONTACTS_PATH,
  DEALS_PATH,
  EMPLOYEES_PATH,
  EXPENSES_PATH,
  FORECASTS_PATH,
  INVOICES_PATH,
  PRODUCTS_PATH,
  PROJECTS_PATH,
  PURCHASE_ORDERS_PATH,
  PURCHASES_PATH,
  QUOTES_PATH,
  SALES_ORDERS_PATH,
  SEQUENCES_PATH,
  TASKS_PATH,
  VENDORS_PATH,
} from 'constants/path'

const GlobalSearch = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isSearchEnable, setIsSearchEnable] = useState(false)
  const [search, setSearch] = useState({
    value: null,
    results: [],
  })

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {
    globalSearchCustomersData = {},
    globalSearchInboxData = {},
    globalSearchSalesData = {},
    globalSearchProjectsData = {},
    globalSearchFinanceData = {},
    globalSearchHrData = {},
  } = useSelector(state => state.appReducer)

  const globalData = _.merge(
    globalSearchCustomersData,
    globalSearchInboxData,
    globalSearchSalesData,
    globalSearchProjectsData,
    globalSearchFinanceData,
    globalSearchHrData
  )

  useEffect(() => {
    if (search.value !== null) {
      dispatch(globalSearchCustomers(search.value))
      dispatch(globalSearchInbox(search.value))
      dispatch(globalSearchSales(search.value))
      dispatch(globalSearchProjects(search.value))
      dispatch(globalSearchFinance(search.value))
      dispatch(globalSearchHr(search.value))
    }
  }, [search.value, dispatch])

  useEffect(() => {
    if (
      (successLabels.includes(GLOBAL_SEARCH_CUSTOMERS),
      successLabels.includes(GLOBAL_SEARCH_INBOX),
      successLabels.includes(GLOBAL_SEARCH_SALES),
      successLabels.includes(GLOBAL_SEARCH_PROJECTS),
      successLabels.includes(GLOBAL_SEARCH_FINANCE))
    ) {
      const arr = Object.keys(globalData).reduce((acc, ele) => {
        globalData[ele]?.map(single => {
          let obj = {}

          switch (ele) {
            case 'conversations': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['subject'], single, ' ')}`, 20),
                price: startCase(ele),
                image: inbox,
                redirectUrl: {
                  pathname: `/inbox`,
                  state: {
                    inboxId: single.id,
                    conversation: single,
                  },
                },
              }
              return acc.push(obj)
            }
            case 'contacts': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['firstName'], single, ' ')} ${get(['lastName'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/${CONTACTS_PATH.PREFIX}/${single.id}`,
              }
              return acc.push(obj)
            }
            case 'companies': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['name'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/company/${single.id}`,
              }
              return acc.push(obj)
            }
            case 'deals': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['name'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/${DEALS_PATH.PREFIX}/${single.id}`,
              }
              return acc.push(obj)
            }
            case 'sequences': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['name'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/${SEQUENCES_PATH.PREFIX}/${single.id}`,
              }
              return acc.push(obj)
            }
            case 'products': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['name'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: {
                  pathname: `/${PRODUCTS_PATH.PREFIX}/${PRODUCTS_PATH.ALL}`,
                  state: {
                    open: true,
                    productId: single.id,
                  },
                },
              }
              return acc.push(obj)
            }
            case 'quotes': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['name'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/${QUOTES_PATH.PREFIX}/${single.id}`,
              }
              return acc.push(obj)
            }
            case 'forecasts': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['name'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: {
                  pathname: `/${FORECASTS_PATH.PREFIX}`,
                  state: {
                    createForecast: true,
                    forecastId: single.id,
                  },
                },
              }
              return acc.push(obj)
            }
            case 'projects': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['title'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/${PROJECTS_PATH.PREFIX}/${single.id}`,
              }
              return acc.push(obj)
            }

            case 'vendors': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['name'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/${VENDORS_PATH.PREFIX}/${VENDORS_PATH.UPDATE}/${single.id}`,
              }
              return acc.push(obj)
            }
            case 'purchases': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['name'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/${PURCHASES_PATH.PREFIX}/${PURCHASES_PATH.UPDATE}${single.id}`,
              }
              return acc.push(obj)
            }
            case 'invoices': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['subject'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/${INVOICES_PATH.PREFIX}/${INVOICES_PATH.UPDATE}/${single.id}`,
              }
              return acc.push(obj)
            }
            case 'purchaseOrders': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['subject'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/${PURCHASE_ORDERS_PATH.PREFIX}/${PURCHASE_ORDERS_PATH.UPDATE}/${single.id}`,
              }
              return acc.push(obj)
            }
            case 'salesOrders': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['subject'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/${SALES_ORDERS_PATH.PREFIX}/${SALES_ORDERS_PATH.UPDATE}/${single.id}`,
              }
              return acc.push(obj)
            }
            case 'budgets': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['name'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/${BUDGETS_PLANNER_PATH.PREFIX}/${BUDGETS_PLANNER_PATH.NEW}/${single.id}`,
              }
              return acc.push(obj)
            }
            case 'employees': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['firstName'], single, ' ')} ${get(['lastName'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/${EMPLOYEES_PATH.PREFIX}/${EMPLOYEES_PATH.UPDATE}/${single.id}`,
              }
              return acc.push(obj)
            }
            case 'expenses': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['name'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/${EXPENSES_PATH.PREFIX}/${EXPENSES_PATH.UPDATE}/${single.id}`,
              }
              return acc.push(obj)
            }
            case 'announcements': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['title'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: `/${ANNOUNCEMENTS_PATH.PREFIX}/${ANNOUNCEMENTS_PATH.UPDATE}/${single.id}`,
              }
              return acc.push(obj)
            }
            case 'tasks': {
              obj = {
                key: single.id,
                title: wrapText(`${get(['subject'], single, ' ')}`, 20),
                price: startCase(ele),
                image: contact,
                redirectUrl: {
                  pathname: `/${TASKS_PATH.PREFIX}`,
                  state: {
                    viewTaskIsOpen: true,
                    taskId: single.id,
                  },
                },
              }
              return acc.push(obj)
            }

            default:
              break
          }
        })

        return acc
      }, [])

      setSearch(search => ({...search, results: arr}))
    }
  }, [successLabels, globalData])

  const doSearch = (e, {value}) => {
    setSearch({...search, value})
  }

  const handleResultSelect = (e, {result}) => {
    history.push(result.redirectUrl)
  }

  return (
    <>
      {isSearchEnable && (
        <KooperMenuItem className="globleSearch">
          <KooperSearch
            minCharacters={3}
            className="gsresult pt-2"
            type="search"
            input={{
              icon: (
                <i className="link icon" onClick={() => setIsSearchEnable(!isSearchEnable)}>
                  <SvgIcon path="common/close" style={{marginTop: '10px'}} />
                </i>
              ),

              // value:search.value,
              placeholder: 'Search',
            }}
            // loading={search.isLoading}
            onResultSelect={handleResultSelect}
            onSearchChange={_.debounce(doSearch, 500, {
              isLeading: true,
            })}
            results={search.results}
            resultRenderer={({title, price, id, description, image}) => {
              return (
                <>
                  {id === 0 && <h5>Search Result : {search.results.length} </h5>}
                  <div className="gsdetail">
                    <div className="gsdetailhead" key="content">
                      {image && (
                        <div className="gsimg">
                          <KooperImage src={image} />
                        </div>
                      )}
                      <KooperHeader as="h3">
                        {title && title}
                        {description && <KooperHeaderSubheader className="mt-1">{description}</KooperHeaderSubheader>}
                      </KooperHeader>
                    </div>
                    {price && <div>{price}</div>}
                  </div>
                </>
              )
            }}
          />
        </KooperMenuItem>
      )}
      {!isSearchEnable && (
        <KooperTooltip
          position="top center"
          content="Search"
          size="mini"
          trigger={
            <KooperMenuItem onClick={() => setIsSearchEnable(!isSearchEnable)}>
              <FiSearch />
            </KooperMenuItem>
          }
        />
      )}
    </>
  )
}

export default GlobalSearch
