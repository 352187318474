/* eslint-disable camelcase */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperDivider,
  KooperForm,
  KooperFormDropdown,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperGrid,
  KooperGridColumn,
  KooperTab,
  KooperTabPane,
  KooperTooltip,
  kooperToast,
} from 'kooper-library'

import {ADD_NEW_INVITEE, EDIT_USER, GET_USER} from 'actions/types'
import {getAllMailInbox} from 'actions/inbox'
import {editUser, getUser} from 'actions/settings_organisation'
import {addNewInvitee, setMetaData} from 'actions'
import SettingLayout from 'layouts/settingLayout'
import {get, removeDoubleQuotes, startCase} from 'utils/helper'
import {countryCodeList} from 'constants/variables'
import _ from 'lodash'
import {getUsers} from 'actions/settings'
import {getMetadataInfo} from 'utils/local-storage'
import {userFormEditSchema, userFormSchema} from 'validation/Settings/myOrganisation/users.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import {getAllRoles} from 'actions/roles'
import useApiResponse from 'hooks/impure/useApiResponse'
import {INBOX_TYPES} from 'constants/enums'
import {SETTINGS_PATH} from 'constants/path'

const UserForm = ({toggleForm, editId, setWarningModal}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [countryCodeUpdated, setCountryCodeUpdated] = useState([])
  const [listMailBox, setListMailBox] = useState([])

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {inbox: mailBoxList} = useSelector(state => state.mailInbox)
  const {getUserData} = useSelector(state => state.settings)
  const {myOrganizationDefaultPermissions} = useUserPermissions()

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const userAddLearnMore = settingsLearnMoreUrl?.myOrganization?.users?.addLearnMore
  const userUpdateLearnMore = settingsLearnMoreUrl?.myOrganization?.users?.updateLearnMore

  const {
    handleSubmit,
    control,
    errors,
    clearErrors,
    reset,
    watch,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(editId ? userFormEditSchema : userFormSchema),
    defaultValues: {
      roleId: 'agent',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      inboxIds: [],
      workPeriods: [
        {
          weekday: 'Monday',
          working_hour: 8,
        },
        {
          weekday: 'Tuesday',
          working_hour: 8,
        },
        {
          weekday: 'Wednesday',
          working_hour: 8,
        },
        {
          weekday: 'Thursday',
          working_hour: 8,
        },
        {
          weekday: 'Friday',
          working_hour: 8,
        },
        {
          weekday: 'Saturday',
          working_hour: 0,
        },
        {
          weekday: 'Sunday',
          working_hour: 0,
        },
      ],
    },
  })

  const {fields} = useFieldArray({control, name: 'workPeriods'})

  const {data: getRoleList = []} = useApiResponse({
    action: getAllRoles,
    enabled: true,
    storePath: 'roles.getRoleList',
  })

  useEffect(() => {
    window.history.pushState(null, '', window.location.href)
    window.onpopstate = () => {
      if (editId) {
        history.push(`${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.USERS}`)
      } else {
        toggleForm(false)
      }
    }
    return () => (window.onpopstate = () => {})
  }, [])

  useEffect(() => {
    const updatedList = countryCodeList.map(item => ({
      key: `${item.name} ${item.dial_code}`,
      value: item.dial_code,
      flag: item.code.toLowerCase(),
      text: `${item.dial_code}`,
      content: `${item.dial_code}  ${item.name}`,
    }))

    setCountryCodeUpdated(updatedList)
  }, [])

  useEffect(() => {
    if (!mailBoxList) dispatch(getAllMailInbox())
    if (editId) {
      dispatch(getUser(editId))
    }
    return () => {
      setWarningModal(false)
    }
  }, [dispatch])

  useEffect(() => {
    if (isDirty) {
      setWarningModal(isDirty)
    }
  }, [isDirty])

  useEffect(() => {
    if (mailBoxList) {
      const list = mailBoxList
        .filter(data => (data.type === INBOX_TYPES.EMAIL ? data.emailVerified : true))
        .map(inbox => ({
          value: inbox.id,
          key: inbox.id,
          text: inbox.name,
        }))
      setListMailBox(list)
    }
  }, [mailBoxList])

  useEffect(() => {
    if (successLabels.includes(EDIT_USER)) {
      dispatch(getUser(editId))
      history.push(`${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.USERS}`)
    }
    if (successLabels.includes(GET_USER)) {
      reset(
        _.omit(getUserData, [
          'avatar',
          'createdAt',
          'id',
          'isVerified',
          'isDisabled',
          'timezone',
          'dateFormat',
          'timeFormat',
        ])
      )
    }
    if (successLabels.includes(ADD_NEW_INVITEE)) {
      dispatch(getUsers())
      toggleForm(false)

      if (!get(['inviteStatus'], getMetadataInfo())) {
        const data = {
          inviteStatus: true,
        }
        dispatch(setMetaData(data))
      }
    }
  }, [successLabels])

  const submitForm = data => {
    if (editId) {
      dispatch(editUser(data, editId))
    } else {
      dispatch(addNewInvitee([data]))
    }
    setWarningModal(false)
  }

  const panes = [
    {
      menuItem: {key: 'details', icon: <SvgIcon path="settings/details" />, content: 'Details'},
      render: () => (
        <KooperTabPane attached={false}>
          <KooperForm className="errorLabel" id="user-form">
            <KooperGrid>
              <KooperGridColumn width={8}>
                <KooperFormField required>
                  <label>First Name</label>
                  <Controller
                    control={control}
                    name="firstName"
                    render={({onChange, value, onBlur}) => (
                      <KooperFormInput
                        maxLength={20}
                        fluid
                        value={value}
                        onBlur={onBlur}
                        onChange={e => onChange(e.target.value)}
                        error={
                          errors.firstName && {
                            content: removeDoubleQuotes(errors.firstName.message),
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
              </KooperGridColumn>
              <KooperGridColumn width={8}>
                <KooperFormField required>
                  <label>Last Name</label>
                  <Controller
                    control={control}
                    name="lastName"
                    render={({onChange, value, onBlur}) => (
                      <KooperFormInput
                        maxLength={20}
                        fluid
                        required
                        placeholder="Last Name"
                        value={value}
                        onBlur={onBlur}
                        onChange={e => onChange(e.target.value)}
                        error={
                          errors.lastName && {
                            content: removeDoubleQuotes(errors.lastName.message),
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
              </KooperGridColumn>
              <KooperGridColumn width={8}>
                <KooperFormField required>
                  <label>Email</label>
                  <Controller
                    control={control}
                    name="email"
                    render={({onChange, value, onBlur}) => (
                      <KooperFormInput
                        fluid
                        required
                        disabled={!!editId}
                        placeholder="e.g. email@domain.com"
                        onBlur={onBlur}
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        error={
                          errors.email && {
                            content: removeDoubleQuotes(errors.email.message),
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
              </KooperGridColumn>
              <KooperGridColumn width={8}>
                <KooperFormField required>
                  <label>Phone Number</label>
                  <KooperGrid>
                    <KooperGridColumn width={5}>
                      <Controller
                        name="phoneCode"
                        control={control}
                        render={({value, onChange, onBlur}) => (
                          <KooperFormDropdown
                            fluid
                            selection
                            value={value}
                            onChange={(e, {value}) => {
                              onChange(value)
                            }}
                            className="numDropDown"
                            onBlur={onBlur}
                            options={countryCodeUpdated}
                            search={(event, data) => {
                              const searchQuery = data
                              if (searchQuery === '') {
                                return event
                              }
                              const r = event.filter(o => {
                                return o.key.toLowerCase().includes(searchQuery.toLowerCase())
                              })
                              return r
                            }}
                          />
                        )}
                      />
                    </KooperGridColumn>
                    <KooperGridColumn width={11}>
                      <KooperFormField>
                        <Controller
                          control={control}
                          name="phone"
                          render={({onChange, value, onBlur}) => (
                            <KooperFormInput
                              fluid
                              maxLength={16}
                              value={value}
                              onChange={e => onChange(e.target.value)}
                              onBlur={onBlur}
                              error={
                                errors.phone && {
                                  content: removeDoubleQuotes(errors.phone.message),
                                }
                              }
                            />
                          )}
                        />
                      </KooperFormField>
                    </KooperGridColumn>
                  </KooperGrid>
                </KooperFormField>
              </KooperGridColumn>
              {!editId && (
                <KooperGridColumn width={8}>
                  <div className="info-header">
                    <label>Inbox</label>
                    <KooperTooltip
                      content="Assign an email inbox to this user."
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </div>
                  <Controller
                    control={control}
                    name="inboxIds"
                    render={({onChange, value, onBlur}) => (
                      <KooperFormSelect
                        multiple
                        search
                        options={listMailBox}
                        onChange={(e, {value}) => onChange(value)}
                        value={value}
                        onBlur={onBlur}
                        error={
                          errors.inboxIds && {
                            content: removeDoubleQuotes(errors.inboxIds.message),
                          }
                        }
                      />
                    )}
                  />
                </KooperGridColumn>
              )}
              <KooperGridColumn width={8}>
                <KooperFormField required>
                  <div className="info-header">
                    <label className="label-class">Role</label>
                    <KooperTooltip
                      content="Specify role of the user you want to assign"
                      size="mini"
                      position="top center"
                      trigger={<SvgIcon path="common/question" />}
                    />
                  </div>
                  <Controller
                    control={control}
                    name="roleId"
                    render={({onChange, value, onBlur}) => (
                      <KooperFormSelect
                        disabled={value === 1}
                        options={getRoleList.map(role => {
                          const isOwner = role.id === 1
                          return {
                            value: role.id,
                            key: role.id,
                            text: role.role,
                            disabled: isOwner,
                          }
                        })}
                        onChange={(e, {value}) => onChange(value)}
                        onClick={() => errors.roleId && clearErrors('roleId')}
                        onBlur={onBlur}
                        value={value}
                        error={
                          errors.roleId && {
                            content: removeDoubleQuotes(errors.roleId.message),
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
              </KooperGridColumn>
            </KooperGrid>
          </KooperForm>
        </KooperTabPane>
      ),
    },
    {
      menuItem: {key: 'settings', icon: <SvgIcon path="common/settings" />, content: 'Settings'},
      render: () => (
        <KooperTabPane attached={false}>
          <KooperForm className="errorLabel">
            <KooperFormField className="mt-3" width={8}>
              <div className="info-header">
                <label>Working Hours</label>
                <KooperTooltip
                  content="Set working hours of users for all working days"
                  size="mini"
                  position="top center"
                  trigger={<SvgIcon path="common/question" />}
                />
              </div>
              <KooperGrid columns={8}>
                {fields.map(({id, weekday, working_hour}, index) => (
                  <KooperGridColumn key={id}>
                    <KooperFormField className="text-center">
                      <Controller
                        name={`workPeriods[${index}].working_hour`}
                        control={control}
                        defaultValue={working_hour}
                        render={({value, onChange}) => (
                          <KooperFormInput
                            value={value}
                            onChange={(e, {value}) => {
                              if (value >= 0 && value <= 24) {
                                onChange(value)
                              }
                            }}
                          />
                        )}
                      />
                      <label>{startCase(weekday[0])}</label>
                      <Controller name={`workPeriods[${index}].weekday`} control={control} render={() => null} />
                    </KooperFormField>
                  </KooperGridColumn>
                ))}
              </KooperGrid>
            </KooperFormField>
          </KooperForm>
        </KooperTabPane>
      ),
    },
  ]

  return (
    <SettingLayout
      header={editId ? 'Update User' : 'Add Users'}
      headerbackbtn={{
        onClick: () => {
          if (editId) {
            setWarningModal(false)
            history.push(`/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.USERS}`)
          } else {
            setWarningModal(false)
            history.push(`/${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.USERS}`)
            toggleForm(false)
          }
        },
      }}
      subHeader={editId ? 'Update details of your existing users' : 'Add users by sending them email invitations'}
      learnMoreUrl={editId ? userUpdateLearnMore : userAddLearnMore}
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Cancel',
          disabled: isLoadingData && (type.includes(ADD_NEW_INVITEE) || type.includes(EDIT_USER)),
          onClick: () => {
            if (editId) {
              setWarningModal(false)
              history.push(`${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.USERS}`)
            } else {
              setWarningModal(false)
              history.push(`${SETTINGS_PATH.PREFIX}/${SETTINGS_PATH.USERS}`)
              toggleForm(false)
            }
          },
        },
        success: editId
          ? {
              content: 'Update',
              disabled: !isDirty || (isLoadingData && type.includes(EDIT_USER)),
              loading: isLoadingData && type.includes(EDIT_USER),
              ...(myOrganizationDefaultPermissions && {
                onClick: handleSubmit(data => submitForm(data)),
              }),
            }
          : {
              content: 'Create',
              disabled: isLoadingData && type.includes('ADD_NEW_INVITEE'),
              loading: isLoadingData && type.includes('ADD_NEW_INVITEE'),
              ...(myOrganizationDefaultPermissions && {
                onClick: handleSubmit(data => submitForm(data)),
              }),
            },
      }}
      lockRole={!myOrganizationDefaultPermissions}
    >
      <KooperTab panes={panes} menu={{color: 'blue', secondary: true, pointing: true}} />
    </SettingLayout>
  )
}

export default UserForm
