import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {
  KooperCheckbox,
  KooperDatePicker,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperGrid,
  KooperGridColumn,
  KooperGridRow,
  KooperTooltip,
} from 'kooper-library'
import {GET_ALL_TAG_LABEL, GET_USERS, GET_DEPARTMENT} from 'actions/types'

import {getDepartment} from 'actions/employees'
import {removeDoubleQuotes} from 'utils/helper'
import CreateLabel from 'components/common/CreateLabel'
import {createTagSettings, getTagLabel} from 'actions/multiple_fields'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'

const CreateEmployeeForm = props => {
  const {errors, watch, control, setJoiningDate, JoiningDate, newLabelModal, setNewLabelModal, editId} = props
  const dispatch = useDispatch()

  const [userOption, setUserOption] = useState([])
  const [departmentOption, setDepartmentOption] = useState([])
  const [labelOptionsState, setLabelOptionsState] = useState([])

  const watchJoiningDate = watch('joiningDate')

  const createNewLabel = () => {
    if (newLabelModal) {
      return (
        <CreateLabel
          isModalOpen={newLabelModal}
          setIsModalOpen={setNewLabelModal}
          createMethod={data => dispatch(createTagSettings(data))}
        />
      )
    }
  }

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list)
    },
  })

  useApiResponse({
    action: getDepartment,
    enabled: true,
    label: GET_DEPARTMENT,
    storePath: 'employees.getDepartmentData',
    onSuccess: getDepartmentData => {
      const list = getDepartmentData.map(department => ({
        value: department.id,
        text: department.department,
        id: department.id,
      }))
      setDepartmentOption(list)
    },
  })

  useApiResponse({
    action: getTagLabel,
    enabled: true,
    label: GET_ALL_TAG_LABEL,
    storePath: 'multipleFields.getTagList',
    onSuccess: getTagList => {
      const list = getTagList.map(label => ({
        value: label.id,
        text: label.label,
        key: label.id,
        label: {
          style: {background: label.color},
          empty: true,
          circular: true,
        },
      }))
      setLabelOptionsState(list)
    },
  })

  return (
    <KooperForm className="errorLabel">
      <KooperGrid>
        <KooperGridRow>
          <KooperGridColumn width="8">
            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label className="label-class">First Name</label>
                <KooperTooltip
                  content="Specify first name of the employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="firstName"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormInput
                    type="text"
                    placeholder="First Name"
                    onChange={(e, {value}) => onChange(value)}
                    value={value}
                    error={
                      errors.firstName && {
                        content: removeDoubleQuotes(errors.firstName.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Last Name</label>
                <KooperTooltip
                  content="Specify last name of the employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="lastName"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormInput
                    type="text"
                    placeholder="Last Name"
                    onChange={(e, {value}) => onChange(value)}
                    value={value}
                    error={
                      errors.lastName && {
                        content: removeDoubleQuotes(errors.lastName.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label className="label-class">Work Email</label>
                <KooperTooltip
                  content="Specify work email of employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="workEmail"
                render={({value, onChange, onBlur}) => (
                  <KooperFormInput
                    fluid
                    type="workEmail"
                    placeholder="Type your Email.."
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errors.workEmail && {
                        content: removeDoubleQuotes(errors.workEmail.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Work Phone</label>
                <KooperTooltip
                  content="Specify office number of the employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="workPhone"
                render={({value, onChange, onBlur}) => (
                  <KooperFormInput
                    fluid
                    type="text"
                    name="workPhone"
                    placeholder="Type your number.."
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errors.workPhone && {
                        content: errors.workPhone.message,
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Work Mobile</label>
                <KooperTooltip
                  content="Work mobile number of employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="workMobileNo"
                render={({value, onChange, onBlur}) => (
                  <KooperFormInput
                    fluid
                    type="text"
                    name="workMobileNo"
                    placeholder="Type your number.."
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errors.workMobileNo && {
                        content: errors.workMobileNo.message,
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Approvers</label>
                <KooperTooltip
                  content="approve employee request"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <div className="d-flex mb-3" style={{justifyContent: 'flex-start'}}>
                <div className="info-header mb-0 mr-3">
                  <label>Time Off:</label>
                  <KooperTooltip
                    content="Set time off for the employee"
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="offTimeApprover"
                  render={({onChange, value, onBlur}) => (
                    <KooperFormSelect
                      style={{minWidth: '14em'}}
                      fluid
                      options={userOption}
                      selection
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.offTimeApprover && {
                          content: removeDoubleQuotes(errors?.offTimeApprover?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </div>
              <div className="d-flex" style={{justifyContent: 'flex-start'}}>
                <div className="info-header mb-0 mr-3">
                  <label>Expenses</label>
                  <KooperTooltip
                    content="Choose expenses for approval"
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="expenseApprover"
                  render={({onChange, value, onBlur}) => (
                    <KooperFormSelect
                      style={{minWidth: '14em'}}
                      fluid
                      options={userOption}
                      selection
                      onBlur={onBlur}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.expenseApprover && {
                          content: removeDoubleQuotes(errors?.expenseApprover?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </div>
            </KooperFormField>
          </KooperGridColumn>

          <KooperGridColumn width="8">
            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label className="label-class">Employee ID</label>
                <KooperTooltip
                  content="Specify employee ID"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="employeeID"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormInput
                    type="text"
                    placeholder="Employee ID"
                    onChange={(e, {value}) => onChange(value)}
                    value={value}
                    error={
                      errors.employeeID && {
                        content: removeDoubleQuotes(errors.employeeID.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Title</label>
                <KooperTooltip
                  content="Specify title i.e Mr or Mrs"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="title"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormInput
                    type="text"
                    placeholder="Title"
                    onChange={(e, {value}) => onChange(value)}
                    value={value}
                    error={
                      errors.title && {
                        content: removeDoubleQuotes(errors.title.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Manager</label>
                <KooperTooltip
                  content="Allocate a manager for the employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="managerId"
                render={({onChange, value, onBlur}) => (
                  <KooperFormSelect
                    fluid
                    options={userOption}
                    selection
                    onBlur={onBlur}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errors?.managerId && {
                        content: removeDoubleQuotes(errors?.managerId?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label className="label-class">Department</label>
                <KooperTooltip
                  content="Assign employee to a department"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="departmentId"
                render={({onChange, value, onBlur}) => (
                  <KooperFormSelect
                    fluid
                    options={departmentOption}
                    selection
                    onBlur={onBlur}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errors?.departmentId && {
                        content: removeDoubleQuotes(errors?.departmentId?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Joining Date</label>
                <KooperTooltip
                  content="Set joining date of the employee"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="joiningDate"
                render={({onChange, value}) => (
                  <KooperDatePicker
                    type="date"
                    value={typeof value === 'string' ? new Date(JoiningDate) : JoiningDate}
                    inputProps={{readOnly: true}}
                    time={false}
                    onChange={value => {
                      onChange(value)
                      setJoiningDate(value)
                    }}
                    error={
                      errors?.joiningDate && {
                        content: removeDoubleQuotes(errors?.joiningDate?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>

            {editId && (
              <KooperFormField width={15}>
                <div className="info-header mb-1">
                  <label>Leaving date</label>
                  <KooperTooltip
                    content="Mention the leaving date of employee"
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="leavingDate"
                  control={control}
                  render={({onChange, value}) => (
                    <KooperDatePicker
                      type="date"
                      value={typeof value === 'string' ? new Date(value) : value}
                      inputProps={{readOnly: true}}
                      time={false}
                      min={new Date(watchJoiningDate)}
                      onChange={value => onChange(value)}
                      error={
                        errors?.leavingDate && {
                          content: removeDoubleQuotes(errors?.leavingDate?.message),
                        }
                      }
                    />
                  )}
                />
              </KooperFormField>
            )}

            <KooperFormField width={15}>
              <div className="info-header mb-1">
                <label>Labels</label>
                <KooperTooltip
                  content="Add tags for better understanding of approval request"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="labelIds"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormSelect
                    multiple
                    options={labelOptionsState}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                  />
                )}
              />
            </KooperFormField>

            <KooperFormField width={15}>
              <div className="d-flex">
                <div>
                  <label className="m-0">Allow Access To Employee Portal</label>
                </div>
                <Controller
                  name="allowPortalAccess"
                  control={control}
                  render={({value, onChange}) => (
                    <KooperCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </div>
            </KooperFormField>
          </KooperGridColumn>
        </KooperGridRow>
      </KooperGrid>
      {createNewLabel()}
    </KooperForm>
  )
}

export default CreateEmployeeForm
