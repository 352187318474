import {KooperGrid, KooperGridColumn} from 'kooper-library'
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const NoDataChart = ({top}) => {
  return (
    <KooperGrid centered>
      <KooperGridColumn
        style={{
          width: '250px',
          height: '250px',
          marginTop: `${top}`,
        }}
      >
        <CircularProgressbar
          styles={buildStyles({
            textColor: 'rgba(0, 0, 0, 0.6)',
            trailColor: '#f2f4f7',
            textSize: '7px',
          })}
          strokeWidth={10}
          value={0}
          text="No Data Available"
        />
      </KooperGridColumn>
    </KooperGrid>
  )
}

export default NoDataChart
