/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import _ from 'lodash'
import Moment from 'moment'

import {
  KooperButton,
  KooperButtonGroup,
  KooperFlag,
  KooperHeader,
  KooperHeaderSubheader,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableFooter,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {GET_LIVEUSER_CONTACTS, START_NEW_CONVERSATION, UPDATE_CONTACT_LIST} from 'actions/types'
import {getLiveCount, getLiveUserContacts, startNewConversation} from 'actions/liveVisitors'
import {get, getSortType, wrapText} from 'utils/helper'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import SvgIcon from 'components/common/SvgIcon'

const LiveVisitors = ({location}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const Page = location.state

  const [numberOfPage, setNumberOfPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [isButtonClick, setIsButtonClick] = useState(0)
  const [isModelOpen, setIsModelOpen] = useState(false)
  const [startNewConversationId, setStartNewConversationId] = useState(null)
  const [sort, setSort] = useState({
    sortBy: '',
    sortType: '',
  })

  const {
    getLiveUserContactData: liveUserContactList = [],
    getLiveUserNumberOfPages: liveUserPages = {},
    getLiveCount: liveCount = {},
  } = useSelector(state => state.liveVisitors)
  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    dispatch(getLiveUserContacts({page: Page || 1, online: true}))
    dispatch(getLiveCount())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(START_NEW_CONVERSATION)) {
      history.push(`/inbox`)
    }

    if (successLabels.includes(GET_LIVEUSER_CONTACTS || UPDATE_CONTACT_LIST)) {
      setNumberOfPage(liveUserPages.totalPages)
      setCurrentPage(0)
    }
  }, [successLabels])

  useEffect(() => {
    if (sort.sortBy || sort.text) {
      const newList = _.omitBy({...sort, online: true, page: currentPage}, value => {
        if (value === 'none' || value === '' || !value) {
          return true
        }
        return false
      })
      dispatch(getLiveUserContacts(newList))
    }
  }, [sort])

  const renderConformModel = () => {
    return (
      <KooperModal
        size="tiny"
        open={isModelOpen}
        centered={false}
        closeIcon={
          <SvgIcon
            path="common/close"
            className="closeIcon"
            onClick={() => {
              setIsModelOpen(false)
              setStartNewConversationId(null)
            }}
          />
        }
      >
        <KooperModalHeader >Confirmation</KooperModalHeader>
        <KooperModalContent>
          <h5>Do you wish to start a new conversation with this user?</h5>
        </KooperModalContent>
        <KooperModalActions>
          <KooperButton
            basic
            onClick={() => {
              setStartNewConversationId(null)
              setIsModelOpen(false)
            }}
          >
            No
          </KooperButton>
          <KooperButton
            primary
            onClick={() => {
              if (startNewConversationId) {
                dispatch(startNewConversation({contactId: startNewConversationId}))
              }
              setStartNewConversationId(null)
            }}
          >
            Yes
          </KooperButton>
        </KooperModalActions>
      </KooperModal>
    )
  }

  const renderFooterPages = () => {
    return (
      <KooperTableFooter>
        <KooperTableRow>
          <KooperTableHeaderCell colSpan="8" style={{textAlign: 'center'}}>
            <KooperCustomPagination
              activePage={numberOfPage}
              totalPages={currentPage}
              onPageChange={page =>
                setSort(sort => ({
                  ...sort,
                  page,
                }))
              }
            />
          </KooperTableHeaderCell>
        </KooperTableRow>
      </KooperTableFooter>
    )
  }

  const renderVisitorsList = () => {
    if (isLoadingData && type.includes(GET_LIVEUSER_CONTACTS)) {
      return <KooperTablePlaceholder columns={8} />
    }

    if (!isLoadingData && liveUserContactList.length === 0) {
      return (
        <KooperTableRow>
          <KooperTableCell colSpan="8" className="emptyValue">
            No Data Available
          </KooperTableCell>
        </KooperTableRow>
      )
    }

    return liveUserContactList.map((contact, index) => (
      <KooperTableRow key={index} className="tableLink">
        <KooperTableCell>{index + 1}</KooperTableCell>
        <KooperTableCell>
          <KooperHeader as="h5">
            {contact.firstName
              ? wrapText(`${get(['firstName'], contact, '-')} ${get(['lastName'], contact, ' ')}`, 13)
              : contact.id}
            <KooperHeaderSubheader>{get(['email'], contact, '-')}</KooperHeaderSubheader>
          </KooperHeader>
        </KooperTableCell>
        <KooperTableCell>
          {get(['city'], contact, '-')}
          <KooperHeaderSubheader>
            {get(['country'], contact, null) ? <KooperFlag name={contact.country.toLowerCase()} /> : null}
            {get(['country'], contact, '-')}
          </KooperHeaderSubheader>
        </KooperTableCell>
        <KooperTableCell>{get(['totalVisit'], contact, '-')}</KooperTableCell>
        <KooperTableCell>
          <KooperTooltip
            position="top center"
            size="mini"
            content={get(['pageUrl'], contact, '-')}
            trigger={
              <a target="_blank" href={get(['pageUrl'], contact, '')} rel="noopener noreferrer">
                {wrapText(get(['pageUrl'], contact, '-'), 25)}
              </a>
            }
          />
        </KooperTableCell>
        <KooperTableCell>{contact.duration ? Moment(contact.duration).fromNow() : '-'}</KooperTableCell>
        <KooperTableCell>
          <KooperTooltip
            position="top center"
            size="mini"
            content={get(['referralUrl'], contact, '-')}
            trigger={
              <a target="_blank" href={get(['referralUrl'], contact, '-')} rel="noopener noreferrer">
                {wrapText(get(['referralUrl'], contact, '-'), 25)}
              </a>
            }
          />
        </KooperTableCell>
        <KooperTableCell>
          <KooperButton
            primary
            onClick={() => {
              setIsModelOpen(true)
              setStartNewConversationId(contact.id)
            }}
          >
            Start Chat
          </KooperButton>
        </KooperTableCell>
      </KooperTableRow>
    ))
  }

  return (
    <div className="kooper-full-wrap">
      {isModelOpen && renderConformModel()}
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="inbox/user" />
            <h5>
              Live Users (<span>{get(['totalItems'], liveUserPages, 0)} Users</span>)
            </h5>
          </div>
        </div>
        <div className="page-action">
          <KooperButtonGroup basic>
            <KooperButton
              active={isButtonClick === 0}
              onClick={() => {
                dispatch(getLiveUserContacts({page: Page || 1, online: true}))
                setIsButtonClick(0)
              }}
            >
              All : {get(['totalCounts'], liveCount, '-')}
            </KooperButton>
            <KooperButton
              active={isButtonClick === 2}
              onClick={() => {
                dispatch(
                  getLiveUserContacts({
                    page: Page || 1,
                    online: true,
                    contactType: 2,
                  })
                )
                setIsButtonClick(2)
              }}
            >
              Repeat : {get(['repeatCounts'], liveCount, '-')}
            </KooperButton>
            <KooperButton
              active={isButtonClick === 1}
              onClick={() => {
                dispatch(
                  getLiveUserContacts({
                    page: Page || 1,
                    online: true,
                    contactType: 1,
                  })
                )
                setIsButtonClick(1)
              }}
            >
              New : {get(['newCounts'], liveCount, '-')}
            </KooperButton>
          </KooperButtonGroup>
        </div>
      </div>

      <KooperTable sortable basic selectable>
        <KooperTableHeader>
          <KooperTableRow>
            <KooperTableHeaderCell>#</KooperTableHeaderCell>
            <KooperTableHeaderCell
              sorted={sort.sortBy === 'name' && getSortType(sort.sortType)}
              onClick={() =>
                setSort({
                  sortBy: 'name',
                  sortType: sort.sortType === 'asc' ? 'desc' : 'asc',
                })
              }
            >
              Visitor
            </KooperTableHeaderCell>
            <KooperTableHeaderCell>Location</KooperTableHeaderCell>
            <KooperTableHeaderCell>Total visits</KooperTableHeaderCell>
            <KooperTableHeaderCell>Page</KooperTableHeaderCell>
            <KooperTableHeaderCell>Duration</KooperTableHeaderCell>
            <KooperTableHeaderCell>Referral URL</KooperTableHeaderCell>
            <KooperTableHeaderCell>Start Chat</KooperTableHeaderCell>
          </KooperTableRow>
        </KooperTableHeader>
        <KooperTableBody>{renderVisitorsList()}</KooperTableBody>

        {numberOfPage > 1 && renderFooterPages()}
      </KooperTable>
    </div>
  )
}

export default LiveVisitors
