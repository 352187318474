import SvgIcon from 'components/common/SvgIcon'
import React, {useState} from 'react'
import {Controller, useFieldArray} from 'react-hook-form'
import {KooperButton, KooperFormField, KooperFormInput, KooperFormTextArea, KooperRadio, KooperTooltip} from 'kooper-library'

const Checkboxes = ({question, control, currentIndex}) => {
  const [active, setActive] = useState(false)

  const {
    fields: optionFields,
    append: appendOption,
    remove: removeOption,
  } = useFieldArray({
    control,
    name: `${`questions[${currentIndex}].settings.options`}`,
  })

  return (
    <div className="edit-component-wrapper">
      <div className="edit-component-content">
        <KooperFormField>
          <Controller
            control={control}
            name={`questions[${currentIndex}].question`}
            render={({value, onChange}) => (
              <>
                <KooperFormInput
                  maxLength={100}
                  type="text"
                  required
                  fluid
                  label="Question"
                  placeholder="Enter the question here"
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                />
              </>
            )}
          />
        </KooperFormField>
        <div className="d-flex" style={{marginBottom: '1.5em'}}>
          {!active ? (
            <KooperButton className="addDesBtn" onClick={() => setActive(true)}>
              <SvgIcon path="common/plus" /> Add description
            </KooperButton>
          ) : null}
        </div>
        {active && (
          <KooperFormField>
            <Controller
              control={control}
              name={`questions[${currentIndex}].description`}
              render={({value, onChange}) => (
                <KooperFormTextArea
                  type="text"
                  // maxLength={250}
                  value={value}
                  placeholder="Description"
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                />
              )}
            />
          </KooperFormField>
        )}
        <KooperFormField>
          {optionFields?.length > 0 &&
            optionFields.map((option, index) => (
              <div key={option?.id} className="d-flex mb-3 singleAnsQuesInput" style={{alignItems: 'flex-start'}}>
                <Controller
                  control={control}
                  name={`questions[${currentIndex}].settings.options[${index}].choice`}
                  defaultValue={
                    question?.settings?.options[index]?.choice
                      ? question?.settings?.options[index]?.choice
                      : `Option ${index + 1}`
                  }
                  render={({value, onChange}) => (
                    <KooperFormField>
                      <KooperFormInput
                        type="text"
                        required
                        placeholder="Enter the option here"
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                      />
                    </KooperFormField>
                  )}
                />
                <KooperTooltip
                  content="Remove"
                  size="mini"
                  position="left center"
                  trigger={
                    <KooperButton
                      className="ml-3 mr-0 bg-btn-color"
                      disabled={optionFields.length <= 1}
                      basic
                      icon
                      circular
                      onClick={e => {
                        e.preventDefault()
                        removeOption(index)
                      }}
                    >
                      <SvgIcon path="common/delete" />
                    </KooperButton>
                  }
                />
              </div>
            ))}
          <KooperTooltip
            content="Add"
            size="mini"
            position="top center"
            trigger={
              <KooperButton
                icon
                circular
                className="bg-btn-color"
                onClick={e => {
                  e.preventDefault()
                  appendOption({
                    choice: '',
                    order: optionFields.length + 1,
                  })
                }}
              >
                <SvgIcon path="common/plus" />
              </KooperButton>
            }
          />
        </KooperFormField>

        <KooperFormField>
          <Controller
            name={`questions[${currentIndex}].isRequired`}
            control={control}
            render={({value, onChange}) => (
              <KooperRadio toggle label="Required" checked={value} onChange={(e, {checked}) => onChange(checked)} />
            )}
          />
        </KooperFormField>
      </div>
    </div>
  )
}
export default Checkboxes
