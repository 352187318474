import React from 'react'
import {KooperIcon, KooperPagination} from 'kooper-library'

const KooperCustomPagination = ({totalPages = 1, activePage = 1, onPageChange = () => {}}) => {
  if (totalPages === 1) return null

  return (
    <KooperPagination
      floated="right"
      className="mt-2"
      activePage={activePage}
      boundaryRange={1}
      onPageChange={(e, value) => {
        onPageChange(value.activePage)
      }}
      size="mini"
      siblingRange={1}
      totalPages={totalPages}
      ellipsisItem={null}
      firstItem={
        totalPages > 3
          ? {
              content: <KooperIcon name="angle double left" />,
              icon: true,
            }
          : null
      }
      lastItem={
        totalPages > 3
          ? {
              content: <KooperIcon name="angle double right" />,
              icon: true,
            }
          : null
      }
      prevItem={
        totalPages > 1
          ? {
              content: <KooperIcon name="angle left" />,
              icon: true,
            }
          : null
      }
      nextItem={
        totalPages > 1
          ? {
              content: <KooperIcon name="angle right" />,
              icon: true,
            }
          : null
      }
    />
  )
}

export default KooperCustomPagination
