import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveBar} from '@nivo/bar'
import {KooperCard, KooperCardContent, KooperGrid, KooperGridColumn, KooperTooltip, KooperCardHeader} from 'kooper-library'
import {ESTIMATED_REVENUE, WIN_RATIO} from 'actions/types'
import {UserDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import {estimatedRevenue, winRatio} from 'actions/reports_conversion'
import Moment from 'moment'
import {getGridYValuesMultipleBar, maxOfGraphMultipleBar} from 'utils/graph'
import {get} from 'utils/helper'
import CustomLoader from 'components/common/CustomLoader'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import {DATE_RANGE_KEY} from 'constants/variables'

const ConversionAnalytics = () => {
  const dispatch = useDispatch()

  const {type = []} = useSelector(state => state.apiReducer)
  const {estimatedRevenueData = [], winratioData = []} = useSelector(state => state.reportsConversion)
  const {analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)

  const diff = Moment(get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))).diff(
    Moment(get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))),
    'days'
  )
  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const dealConversionsAccess = analyticsAccess?.dealConversions
  const dealsReportsPermissions = reportsPermissions?.deals_reports

  const actionsToDispatch = useCallback(
    data => {
      dispatch(estimatedRevenue(data))
      dispatch(winRatio(data))
    },
    [dispatch]
  )

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/conversion" />
            <h5>Conversions</h5>
          </div>
        </div>
        {dealConversionsAccess && dealsReportsPermissions && (
          <div className="page-action">
            <UserDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              userId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
            />
          </div>
        )}
      </div>

      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Estimated Revenue</KooperCardHeader>
              <KooperTooltip
                content="Graphically represent the revenue collected from deals over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(ESTIMATED_REVENUE) && <CustomLoader />}
              {!type.includes(ESTIMATED_REVENUE) && (
                <ResponsiveBar
                  data={estimatedRevenueData}
                  keys={['abandoned', 'lost', 'won']}
                  indexBy="name"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  // colors={(d) => d.data[`${d.id}Color`]}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  valueScale={{type: 'linear'}}
                  indexScale={{type: 'band', round: true}}
                  minValue={0}
                  maxValue={maxOfGraphMultipleBar(estimatedRevenueData, ['abandoned', 'lost', 'won'])}
                  gridYValues={getGridYValuesMultipleBar(estimatedRevenueData, ['abandoned', 'lost', 'won'])}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Name',
                    legendPosition: 'middle',
                    legendOffset: 40,
                    format: tick => {
                      if (diff > 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesMultipleBar(estimatedRevenueData, ['abandoned', 'lost', 'won']),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 100,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent className="headerBox">
              <KooperCardHeader>Win Ratio</KooperCardHeader>
              <KooperTooltip
                content="Graphical representation of deals win ratio over selected time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(WIN_RATIO) && <CustomLoader />}
              {!type.includes(WIN_RATIO) && (
                <ResponsiveBar
                  data={winratioData}
                  keys={['winRatio']}
                  indexBy="name"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  // colors={(d) => d.data[`${d.id}Color`]}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  valueScale={{type: 'linear'}}
                  indexScale={{type: 'band', round: true}}
                  minValue={0}
                  maxValue={maxOfGraphMultipleBar(winratioData, ['winRatio'])}
                  gridYValues={getGridYValuesMultipleBar(winratioData, ['winRatio'])}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Name',
                    legendPosition: 'middle',
                    legendOffset: 40,
                    format: tick => {
                      if (diff > 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesMultipleBar(winratioData, ['winRatio']),
                    format: value => `${Number(value).toLocaleString('ru-RU')} %`,
                    legend: 'Win Ratio',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}

export default ConversionAnalytics
