import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  KooperButton,
  KooperDatePicker,
  KooperForm,
  KooperFormDropdown,
  KooperFormField,
  KooperGrid,
  KooperGridColumn,
  KooperInput,
  KooperRadio,
} from 'kooper-library'
import {getCompanyList} from 'actions/company'
import {get, removeDoubleQuotes, KooperCountryList} from 'utils/helper'
import {filterCompanySchema} from 'validation/Customers/company.schema'
import SvgIcon from 'components/common/SvgIcon'

const CompanyFilter = props => {
  const dispatch = useDispatch()
  const {isFilterOpen, setIsFilterOpen, setActiveClassFilter} = props
  const [assigneeData, setAssigneeData] = useState([])
  const [tagLabelData, setTagLabelData] = useState([])
  const [typeList, setTypeList] = useState([])
  const initialCurrentListofFilter = {
    assigneeId: null,
    labelIds: null,
    isFollowed: null,
    interactionsFrom: '',
    interactionsTo: '',
    lastContactedFrom: null,
    lastContactedTo: null,
    inactiveDaysFrom: '',
    inactiveDaysTo: '',
    createdAtFrom: null,
    createdAtTo: null,
    contactTypeId: null,
    country: null,
    locality: '',
    state: '',
    postalCode: '',
  }

  const {handleSubmit, errors, control, reset} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(filterCompanySchema),
    defaultValues: initialCurrentListofFilter,
  })
  const {getTypeListData} = useSelector(state => state.companies)

  const getTagList = useSelector(state => {
    return get(['multipleFields', 'getTagList'], state)
  })
  const {getUsersList = {}} = useSelector(state => state.settings)
  useEffect(() => {
    if (getTypeListData) {
      const companyList = getTypeListData.map(list => ({
        key: list.id,
        value: list.id,
        text: list.type,
      }))
      setTypeList(companyList)
    }
  }, [getTypeListData])

  useEffect(() => {
    if (getTagList) {
      const tagList = getTagList.map(list => ({
        key: list.id,
        value: list.id,
        text: list.label,
      }))
      setTagLabelData(tagList)
    }
  }, [getTagList])

  useEffect(() => {
    if (getUsersList) {
      const assigneeList = getUsersList?.users?.map(list => ({
        key: list.id,
        value: list.id,
        text: `${list.firstName} ${list.lastName}`,
      }))
      setAssigneeData(assigneeList || [])
    }
  }, [getUsersList])

  const convertIsoformat = ['lastContactedFrom', 'lastContactedTo', 'createdAtFrom', 'createdAtTo']
  const applyFilterBtn = data => {
    const newData = _.pickBy(data, _.identity)
    convertIsoformat.map(item => {
      if (Object.keys(newData).includes(item)) {
        newData[item] = newData[item].toISOString()
      }
    })
    if (!_.isEmpty(newData)) {
      setActiveClassFilter(true)
      dispatch(getCompanyList(newData))
    }
  }

  const resetFilterBtn = () => {
    reset(initialCurrentListofFilter)
    dispatch(getCompanyList())
  }

  return (
    <div className="kooper-filter-right">
      <h5 className="m-3">
        Filter for Company
        <KooperButton floated="right" className="p-0" icon onClick={() => setIsFilterOpen(!isFilterOpen)}>
          <SvgIcon path="common/close" />
        </KooperButton>
      </h5>
      <KooperForm>
        <KooperFormField>
          <label>Assignee</label>
          <Controller
            name="assigneeId"
            control={control}
            render={({onChange, value}) => (
              <KooperFormDropdown
                placeholder="Assignee"
                selection
                search
                fluid
                clearable
                options={assigneeData}
                onChange={(e, {value}) => {
                  onChange(value)
                }}
                value={value}
              />
            )}
          />
        </KooperFormField>
        <KooperFormField>
          <label>Tag</label>
          <Controller
            control={control}
            name="labelIds"
            render={({onChange, value}) => (
              <KooperFormDropdown
                placeholder="Tags"
                options={tagLabelData}
                search
                fluid
                selection
                clearable
                value={value}
                onChange={(e, {value}) => onChange(value)}
              />
            )}
          />
        </KooperFormField>
        <KooperFormField>
          <label>Followed</label>
          <Controller
            control={control}
            name="isFollowed"
            render={({onChange, value}) => (
              <>
                <KooperRadio
                  label="Followed"
                  className="mr-4"
                  name="radioGroup"
                  value="true"
                  checked={value === 'true'}
                  onChange={() => {
                    onChange('true')
                  }}
                />
                <KooperRadio
                  label="Not Followed"
                  name="radioGroup"
                  value="false"
                  checked={value === 'false'}
                  onChange={() => {
                    onChange('false')
                  }}
                />
              </>
            )}
          />
        </KooperFormField>
        <KooperFormField>
          <label>Interactions</label>
          <KooperGrid>
            <KooperGridColumn width={8}>
              <Controller
                control={control}
                name="interactionsFrom"
                render={({onChange, value}) => (
                  <KooperInput
                    fluid
                    label={{content: 'From', basic: true}}
                    onChange={(e, {value}) => onChange(value)}
                    value={value}
                    error={
                      errors?.interactionsFrom && {
                        content: removeDoubleQuotes(errors?.interactionsFrom?.message),
                      }
                    }
                  />
                )}
              />
            </KooperGridColumn>

            <KooperGridColumn width={8}>
              <Controller
                control={control}
                name="interactionsTo"
                render={({onChange, value}) => (
                  <KooperInput
                    fluid
                    label={{content: 'To', basic: true}}
                    onChange={(e, {value}) => onChange(value)}
                    value={value}
                    error={
                      errors?.interactionsTo && {
                        content: removeDoubleQuotes(errors?.interactionsTo?.message),
                      }
                    }
                  />
                )}
              />
            </KooperGridColumn>
          </KooperGrid>
        </KooperFormField>
        <KooperFormField>
          <label>Last Contacted</label>
          <KooperGrid>
            <KooperGridColumn width={10}>
              <Controller
                control={control}
                name="lastContactedFrom"
                render={({onChange, value}) => (
                  <KooperDatePicker
                    placeholder="Last Contacted"
                    time={false}
                    onChange={value => {
                      onChange(value)
                    }}
                    value={typeof value === 'string' ? new Date(value) : value}
                    max={new Date()}
                    inputProps={{
                      component: props => <input {...props} readOnly />,
                    }}
                  />
                )}
              />
            </KooperGridColumn>
          </KooperGrid>
        </KooperFormField>
        <KooperFormField>
          <label>Inactive Days</label>
          <KooperGrid>
            <KooperGridColumn width={8}>
              <Controller
                control={control}
                name="inactiveDaysFrom"
                render={({onChange, value}) => (
                  <KooperInput
                    placeholder="Inactive Days From"
                    fluid
                    label={{content: 'From', basic: true}}
                    onChange={(e, {value}) => onChange(value)}
                    value={value}
                    error={
                      errors?.inactiveDaysFrom && {
                        content: removeDoubleQuotes(errors?.inactiveDaysFrom?.message),
                      }
                    }
                  />
                )}
              />
            </KooperGridColumn>
            <KooperGridColumn width={8}>
              <Controller
                control={control}
                name="inactiveDaysTo"
                render={({onChange, value}) => (
                  <KooperInput
                    placeholder="Inactive Days TO"
                    fluid
                    label={{content: 'To', basic: true}}
                    onChange={(e, {value}) => onChange(value)}
                    value={value}
                    error={
                      errors?.inactiveDaysTo && {
                        content: removeDoubleQuotes(errors?.inactiveDaysTo?.message),
                      }
                    }
                  />
                )}
              />
            </KooperGridColumn>
          </KooperGrid>
        </KooperFormField>
        <KooperFormField>
          <label>Created At</label>
          <KooperGrid>
            <KooperGridColumn width={10}>
              <Controller
                control={control}
                name="createdAtFrom"
                render={({onChange, value}) => (
                  <KooperDatePicker
                    placeholder="Created At"
                    time={false}
                    onChange={value => {
                      onChange(value)
                    }}
                    value={value}
                    max={new Date()}
                    inputProps={{
                      component: props => <input {...props} readOnly />,
                    }}
                  />
                )}
              />
            </KooperGridColumn>
          </KooperGrid>
        </KooperFormField>
        <KooperFormField>
          <label>Contact Type</label>
          <Controller
            control={control}
            name="contactTypeId"
            render={({onChange, value}) => (
              <KooperFormDropdown
                placeholder="Contact Type"
                options={typeList}
                fluid
                search
                clearable
                selection
                value={value}
                onChange={(e, {value}) => onChange(value)}
              />
            )}
          />
        </KooperFormField>
        <KooperFormField>
          <label>Country</label>
          <Controller
            control={control}
            name="country"
            render={({onChange, value}) => (
              <KooperFormDropdown
                placeholder="Country"
                search
                fluid
                clearable
                options={KooperCountryList}
                onChange={(e, {value}) => onChange(value)}
                value={value}
                selection
              />
            )}
          />
        </KooperFormField>
        <KooperFormField>
          <label>Locality</label>
          <Controller
            control={control}
            name="locality"
            render={({onChange, value}) => (
              <KooperInput placeholder="Locality" value={value} onChange={(e, {value}) => onChange(value)} />
            )}
          />
        </KooperFormField>
        <KooperFormField>
          <label>State</label>
          <Controller
            control={control}
            name="state"
            render={({onChange, value}) => (
              <KooperInput placeholder="State" value={value} onChange={(e, {value}) => onChange(value)} />
            )}
          />
        </KooperFormField>
        <KooperFormField>
          <label>Postal Code</label>
          <Controller
            control={control}
            name="postalCode"
            render={({onChange, value}) => (
              <KooperInput
                placeholder="Postal Code"
                value={value}
                onChange={(e, {value}) => onChange(value)}
                error={
                  errors?.postalCode && {
                    content: removeDoubleQuotes(errors?.postalCode?.message),
                  }
                }
              />
            )}
          />
        </KooperFormField>
      </KooperForm>

      <div className="m-3">
        <KooperGrid>
          <KooperGridColumn width={8}>
            <KooperButton basic fluid onClick={() => resetFilterBtn()}>
              Reset Filter
            </KooperButton>
          </KooperGridColumn>
          <KooperGridColumn width={8}>
            <KooperButton fluid color="primary" onClick={handleSubmit(applyFilterBtn)}>
              Apply Filter
            </KooperButton>
          </KooperGridColumn>
        </KooperGrid>
      </div>
    </div>
  )
}

export default CompanyFilter
