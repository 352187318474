/* eslint-disable camelcase */
/* eslint-disable no-useless-return */
/* eslint-disable no-nested-ternary */
import React, {useState, useEffect, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {extractTextContentFromHTML} from 'utils/helper'
import Joi from 'joi'
import _ from 'lodash'
import {stateToHTML} from 'draft-js-export-html'
import {KooperButton, KooperTab, kooperToast} from 'kooper-library'

import {createCampaign, getCampaignFilters} from 'actions/campaign'
import {getMetaData, setMetaData} from 'actions'
import {CREATE_CAMPAIGN} from 'actions/types'
import {getAllMailInbox} from 'actions/inbox'
import {campaignCreate} from 'validation/Marketing/campaign.schema'
import {CAMPAIGN_TYPES} from 'constants/enums'
import {CAMPAIGNS_PATH} from 'constants/path'
import Configuration from './OneTimeMessageForm/Configuration'
import UserAudience from './OneTimeMessageForm/UserAudience'
import Messages from './OneTimeMessageForm/Messages'

const steps = [
  {name: 'Configuration', icon: 'cogs'},
  {name: 'User Audience', icon: 'users'},
  {name: 'Messages', icon: 'comments'},
]

const OneTimeMessageSteps = props => {
  const {setWarningModal} = props
  const dispatch = useDispatch()
  const history = useHistory()

  const [index, setIndex] = useState(0)
  const [disabledIndex, setDisabledIndex] = useState(0)
  const [campaignCreateData, setCampaignCreateData] = useState(0)
  const [messageStep, setMessageStep] = useState([{type: 'text', messageHtml: ''}])

  const {isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {inbox: inBoxList} = useSelector(state => state.mailInbox)

  const {
    handleSubmit: handleSubmit1,
    control: control1,
    errors: errors1,
    clearErrors: clearErrors1,
    getValues: getValues1,
    formState: {isDirty: isDirty1},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    shouldUnregister: false,
    resolver: joiResolver(
      Joi.object({
        name: Joi.string().trim().min(1).max(40).label('name').messages({
          'string.min': `Length must be at least 1 characters`,
          'string.max': `Length must be less than or equal to 40 characters`,
        }),
        userId: Joi.number().label('user').messages({
          'number.base': `Team member needs to be selected`,
        }),
        visibility: Joi.number().label('visibility'),
      })
    ),
    defaultValues: {name: '', userId: '', visibility: 1},
  })

  const {
    handleSubmit: handleSubmit2,
    control: control2,
    errors: errors2,
    clearErrors: clearErrors2,
    setValue: setValue2,
    watch: watch2,
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange',
    resolver: joiResolver(campaignCreate),
    defaultValues: {
      matchCase: 'or',
      rules: [
        {
          operator: 'or',
          filters: [],
        },
      ],
    },
  })

  const {setError: setError3, errors: errors3} = useForm()

  useEffect(() => {
    dispatch(getCampaignFilters())
  }, [dispatch])

  useEffect(() => {
    if (!inBoxList) dispatch(getAllMailInbox())
  }, [dispatch, inBoxList])

  useEffect(() => {
    if (successLabels.includes(CREATE_CAMPAIGN)) {
      history.push(`/${CAMPAIGNS_PATH.PREFIX}`)
    }
  }, [dispatch, history, successLabels])

  const submitData = data => {
    setWarningModal(false)
    if (index === 0) {
      setIndex(index + 1)
      setDisabledIndex(disabledIndex + 1)
      setCampaignCreateData({...campaignCreateData, ...data})
    } else if (index === 1) {
      setCampaignCreateData({...campaignCreateData, ...data})
      setIndex(index + 1)
      setDisabledIndex(disabledIndex + 1)
    } else if (index === 2) {
      if (messageStep.length <= 0) {
        kooperToast.error('Add minimum one message to proceed')
        return
      }
      if (messageStep.some(val => val.messageHtml?.getCurrentContent()?.getPlainText()?.trim().length === 0)) {
        kooperToast.error('Editor value is required')
        return
      }
      if (messageStep.some(val => val?.mediaUrl?.length === 0)) {
        kooperToast.error('URl is required')
      } else {
        setCampaignCreateData({
          ...campaignCreateData,
          campaign_message: data,
        })

        const modify_campaign_message = data?.map(obj => {
          if (obj?.type === 'text') {
            return {
              messageHtml: stateToHTML(obj?.messageHtml?.getCurrentContent()),
              messageText: extractTextContentFromHTML(stateToHTML(obj?.messageHtml?.getCurrentContent())),
            }
          }
          return _.omit(obj, ['type'])
        })
        const modify_rules = campaignCreateData?.rules?.map(obj => {
          return _.hasIn(obj, 'operator') ? obj : {...obj, operator: 'or'}
        })
        if (!getMetaData().campaignStatus) {
          dispatch(
            setMetaData({
              campaignStatus: true,
            })
          )
        }
        dispatch(
          createCampaign({
            type: CAMPAIGN_TYPES.MESSAGE,
            status: true,
            inboxId: inBoxList?.find(obj => obj.type === 'web')?.id,
            ...campaignCreateData,
            campaign_message: modify_campaign_message,
            rules: modify_rules,
          })
        )
      }
    }
  }

  const handleUnSaved = useCallback(
    _isDirty1 => {
      if (_isDirty1 || index > 0) setWarningModal(true)
    },
    [setWarningModal, index]
  )

  useEffect(() => {
    handleUnSaved(isDirty1)
    return () => {
      setWarningModal(false)
    }
  }, [handleUnSaved, isDirty1, setWarningModal])

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <div>
          <KooperButton basic inverted onClick={() => history.push(`/${CAMPAIGNS_PATH.PREFIX}`)}>
            Exit
          </KooperButton>
          {index > 0 && (
            <KooperButton basic inverted onClick={() => setIndex(index - 1)}>
              Back
            </KooperButton>
          )}
        </div>
        <KooperTab
          key={index}
          panes={steps.map((item, i) => ({
            menuItem: {
              key: item.name,
              icon: item.icon,
              content: item.name,
              disabled: !(disabledIndex >= i && _.isEmpty(errors1) && _.isEmpty(errors2)),
            },
            render: null,
          }))}
          menu={{
            secondary: true,
            pointing: true,
            color: 'blue',
          }}
          activeIndex={index}
          onTabChange={(e, {activeIndex}) => {
            setIndex(activeIndex)
          }}
          renderActiveOnly={false}
        />
        <KooperButton
          primary
          loading={isLoadingData}
          disabled={isLoadingData}
          onClick={
            index === 0
              ? handleSubmit1(submitData)
              : index === 1
              ? handleSubmit2(submitData)
              : () => submitData(messageStep)
          }
        >
          {index === steps.length - 1 ? 'Create' : 'Next'}
        </KooperButton>
      </div>
      <Configuration
        style={{display: index === 0 ? '' : 'none'}}
        control={control1}
        errors={errors1}
        clearErrors={clearErrors1}
      />
      <UserAudience
        style={{display: index === 1 ? '' : 'none'}}
        control={control2}
        errors={errors2}
        clearErrors={clearErrors2}
        watch={watch2}
        setValue={setValue2}
      />
      <Messages
        style={{display: index === 2 ? '' : 'none'}}
        messageStep={messageStep}
        setMessageStep={setMessageStep}
        errors={errors3}
        setError={setError3}
        getValuesForm1={getValues1}
      />
    </div>
  )
}

export default OneTimeMessageSteps
