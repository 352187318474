import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import Joi from 'joi'
import {
  KooperButton,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'

import {
  CREATE_PROJECTS_CATEGORY,
  DELETE_PROJECTS_CATEGORY,
  GET_PROJECTS_CATEGORIES,
  GET_PROJECTS_SINGLE_CATEGORY,
  UPDATE_PROJECTS_CATEGORY,
} from 'actions/types'
import {
  createProjectsCategory,
  deleteProjectsCategory,
  getProjectsCategories,
  getProjectsSingleCategory,
  updateProjectsCategory,
} from 'actions/settings_projects'
import create from 'assets/create-user.svg'
import DeleteModal from 'components/common/DeleteModal'
import CreateFirst from 'components/common/CreateFirst'
import SettingLayout from 'layouts/settingLayout'
import {getPermissionTooltipContent, removeDoubleQuotes, startCase} from 'utils/helper'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const ProjectCategories = () => {
  const dispatch = useDispatch()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editId, setEditId] = useState(null)
  const [deleteId, setDeleteId] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const projectCategoriesLearnMore = settingsLearnMoreUrl?.projects?.projectCategories?.learnMore

  const {isLoading} = useApiLoader({label: GET_PROJECTS_CATEGORIES})
  const {projectsAndCollaborationAccess, projectsPermissions} = useUserPermissions()
  const projectsAccess = projectsAndCollaborationAccess?.projects
  const manageProjectSettingsPermissions = projectsPermissions?.manage_project_settings

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        name: Joi.string().trim().min(3).max(20).required().messages({
          'string.min': `Length must me at least 3 charactes`,
          'string.max': `Length must be less than or equal to 20 characters`,
        }),
      })
    ),
    defaultValues: {
      name: '',
    },
  })

  const {data: projectsCategories = [], callAction: callGetProjectsCategoriesAction} = useApiResponse({
    action: getProjectsCategories,
    enabled: projectsAccess,
    storePath: 'settingsProjects.projectsCategories',
  })

  useApiResponse({
    action: getProjectsSingleCategory,
    payload: editId,
    dependency: [editId],
    enabled: editId,
    label: GET_PROJECTS_SINGLE_CATEGORY,
    storePath: 'settingsProjects.projectsCategoryData',
    onSuccess: (projectsCategoryData = {}) => {
      const data = _.pick(projectsCategoryData, ['name'])
      reset(data)
    },
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECTS_CATEGORY)) {
      callGetProjectsCategoriesAction()
      setIsModalOpen(false)
    }
    if (successLabels.includes(UPDATE_PROJECTS_CATEGORY)) {
      callGetProjectsCategoriesAction()
      reset({name: ''})
      setIsModalOpen(false)
      setEditId(null)
    }
    if (successLabels.includes(DELETE_PROJECTS_CATEGORY)) {
      callGetProjectsCategoriesAction()
    }
  }, [successLabels, callGetProjectsCategoriesAction, reset])

  const toggle = () => {
    if (editId) {
      reset({name: ''})
      setEditId(null)
    }
    setIsModalOpen(false)
  }

  const submitForm = data => {
    if (editId) {
      dispatch(updateProjectsCategory(editId, data))
    } else {
      dispatch(createProjectsCategory(data))
    }
  }

  const addProjectCategory = () => {
    return (
      <KooperModal
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        size="tiny"
        open={isModalOpen}
        onClose={toggle}
      >
        <KooperModalHeader>{editId ? 'Edit Category' : 'Add Category'}</KooperModalHeader>
        <KooperModalContent>
          <KooperForm className="errorLabel">
            <KooperFormField required>
              <label>Name</label>
              <Controller
                name="name"
                control={control}
                render={({value, onChange}) => (
                  <KooperFormInput
                    maxLength={20}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.name && {content: removeDoubleQuotes(errors.name.message)}}
                  />
                )}
              />
            </KooperFormField>
          </KooperForm>
        </KooperModalContent>
        <KooperModalActions>
          <KooperButton basic onClick={toggle}>
            Cancel
          </KooperButton>
          <KooperButton onClick={handleSubmit(submitForm)} className="mr-0" primary>
            {editId ? 'Update' : 'Add'}
          </KooperButton>
        </KooperModalActions>
      </KooperModal>
    )
  }

  if (isLoading && projectsAccess) {
    return <PageLoader />
  }

  if (!projectsCategories.length || !projectsAccess) {
    return (
      <>
        {isModalOpen && addProjectCategory()}
        <div className="setEmpty-wrap">
          <CreateFirst
            header="Project Categories"
            subHeader="Project categories are used to group related projects together based on common attributes"
            addFirst={() => projectsAccess && manageProjectSettingsPermissions && setIsModalOpen(true)}
            buttonText="Create Project Category"
            tooltip={!projectsAccess || !manageProjectSettingsPermissions}
            tooltipContent={getPermissionTooltipContent({
              featureAccess: projectsAccess,
              roleAccess: manageProjectSettingsPermissions,
            })}
            imageSrc={create}
            Feature1="Category Creation"
            list1="Create categories to organize projects based on attributes"
            Feature2="Categorization"
            list2="Assign projects to specific categories during project creation"
            Feature3="Filtering and Sorting"
            list3="Easily find projects using filters based on categories"
          />
        </div>
      </>
    )
  }

  return (
    <>
      <SettingLayout
        icon={<SvgIcon path="settings/project-categories" />}
        header="Projects Categories"
        subHeader="Create and manage categories to organize your projects efficiently"
        learnMore={projectCategoriesLearnMore}
        headerButton={{
          onClick: () => manageProjectSettingsPermissions && setIsModalOpen(true),
          content: 'Create Project Category',
        }}
        table
        headerDivider
        lockRole={!manageProjectSettingsPermissions}
      >
        <KooperTable basic selectable className="project-task-label-wrapper">
          <KooperTableHeader>
            <KooperTableHeaderCell>#</KooperTableHeaderCell>
            <KooperTableHeaderCell>Name</KooperTableHeaderCell>
            <KooperTableHeaderCell>Actions</KooperTableHeaderCell>
          </KooperTableHeader>
          <KooperTableBody>
            {projectsCategories.map((category, index) => (
              <KooperTableRow key={category.id}>
                <KooperTableCell>{index + 1}</KooperTableCell>
                <KooperTableCell>{startCase(category.name)}</KooperTableCell>
                <KooperTableCell>
                  <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon" direction="left">
                    <KooperDropdownMenu>
                      <KooperTooltip
                        content={
                          manageProjectSettingsPermissions
                            ? 'Edit'
                            : getPermissionTooltipContent({roleAccess: manageProjectSettingsPermissions})
                        }
                        position="top center"
                        size="mini"
                        trigger={
                          <KooperDropdownItem
                            icon
                            onClick={() => {
                              if (manageProjectSettingsPermissions) {
                                setEditId(category.id)
                                setIsModalOpen(true)
                              }
                            }}
                          >
                            <SvgIcon path="common/edit2" /> Edit
                          </KooperDropdownItem>
                        }
                      />
                      <KooperTooltip
                        content={
                          manageProjectSettingsPermissions
                            ? 'Delete'
                            : getPermissionTooltipContent({roleAccess: manageProjectSettingsPermissions})
                        }
                        position="top center"
                        size="mini"
                        trigger={
                          <KooperDropdownItem
                            icon
                            onClick={() => {
                              if (manageProjectSettingsPermissions) {
                                setDeleteId(category.id)
                                setIsDeleteModalOpen(true)
                              }
                            }}
                          >
                            <SvgIcon path="common/delete" /> Delete
                          </KooperDropdownItem>
                        }
                      />
                    </KooperDropdownMenu>
                  </KooperDropdown>
                </KooperTableCell>
              </KooperTableRow>
            ))}
          </KooperTableBody>
        </KooperTable>
      </SettingLayout>

      {isModalOpen && addProjectCategory()}

      {isDeleteModalOpen && (
        <DeleteModal
          idTobeDeleted={deleteId}
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          deleteAction={deleteProjectsCategory}
          type={DELETE_PROJECTS_CATEGORY}
        />
      )}
    </>
  )
}

export default ProjectCategories
