/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Controller, useForm, useFieldArray} from 'react-hook-form'
import {useParams, useHistory} from 'react-router'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperContainer,
  KooperForm,
  KooperCard,
  KooperCardContent,
  KooperDivider,
  KooperFormGroup,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperDatePicker,
  KooperFormTextArea,
  KooperTable,
  KooperTableHeader,
  KooperTableRow,
  KooperTableHeaderCell,
  KooperTableBody,
  KooperTableCell,
  KooperInput,
  KooperCheckbox,
  KooperTooltip,
} from 'kooper-library'
import {
  GET_ACCOUNT_CURRENCY,
  GET_PURCHASES_DATA,
  CREATE_PURCHASES,
  UPDATE_PURCHASES,
  GET_USERS,
  GET_PURCHASES_CATEGORY,
  GET_VENDORS_LIST,
} from 'actions/types'
import {getAccountCurrency} from 'actions/setting_currency'
import {getPurchasesCategory, createPurchases, getPurchasesData, updatePurchases} from 'actions/purchases'
import {getVendorslist} from 'actions/vendors'
import {removeDoubleQuotes} from 'utils/helper'
import {paymentMethod, unitList} from 'constants/finance/purchases'
import {createPurchaseSchema} from 'validation/Finance/purchases.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'
import {PURCHASES_PATH} from 'constants/path'

function PurchaseModal() {
  const history = useHistory()
  const dispatch = useDispatch()
  const {id: editId} = useParams()

  const [userOption, setUserOption] = useState([])
  const [issueDate, setIssueDate] = useState(null)
  const [categoryList, setCategoryList] = useState([])
  const [issuerList, setIssuerList] = useState([])
  const [currencyListData, setcurrencyListData] = useState([])

  const [discountValue, setDiscountValue] = useState(0)
  const [taxValue, setTaxValue] = useState(0)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {financePermissions} = useUserPermissions()
  const managePurchasesPermissions = financePermissions?.manage_purchases

  const initialCreateInfo = {
    assigneeId: null,
    billNo: '',
    name: '',
    vendorId: null,
    dateOfPurchase: null,
    comment: '',
    paymentMethod: null,
    currencyId: '',
    isChargeableToClient: false,
    discount: 0,
    taxPercent: 0,
    items: [
      {
        categoryId: '',
        itemName: '',
        description: '',
        quantity: '',
        unit: '',
        unitPrice: '',
        amount: 0,
      },
    ],
  }

  const {
    errors,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(createPurchaseSchema),
    defaultValues: initialCreateInfo,
  })

  const itemsWatch = watch('items')

  useApiResponse({
    action: getVendorslist,
    enabled: true,
    label: GET_VENDORS_LIST,
    storePath: 'vendors.vendorsList',
    dataToExtract: 'vendors',
    onSuccess: vendorsListData => {
      setIssuerList(
        vendorsListData?.vendors?.map(({name, id}) => {
          return {key: id, value: id, text: name}
        })
      )
    },
  })

  useApiResponse({
    label: GET_ACCOUNT_CURRENCY,
    enabled: true,
    storePath: 'settingCurrency.accountCurrencyList',
    action: getAccountCurrency,
    onSuccess: accountCurrencyList => {
      if (accountCurrencyList) {
        const currList = accountCurrencyList.map(list => ({
          key: list.base.id,
          value: list.currencyId,
          text: list.symbol,
        }))
        setcurrencyListData(currList)
      }
      if (accountCurrencyList) {
        const Bcur = accountCurrencyList.find(list => list.isBase && list)
        setValue('currencyId', Bcur?.currencyId)
      }
    },
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list || [])
    },
  })

  useApiResponse({
    action: getPurchasesCategory,
    enabled: true,
    label: GET_PURCHASES_CATEGORY,
    storePath: 'purchases.getPurchasesCategoryData',
    onSuccess: getPurchasesCategoryData => {
      setCategoryList(
        getPurchasesCategoryData.map(({category, id}) => {
          return {key: id, value: id, text: category}
        })
      )
    },
  })

  const {data: purchasesData = {}} = useApiResponse({
    action: getPurchasesData,
    payload: editId,
    dependency: [editId],
    enabled: editId,
    label: GET_PURCHASES_DATA,
    storePath: 'purchases.purchasesData',
    onSuccess: () => {
      const {
        assigneeId,
        name,
        billNo,
        vendorId,
        dateOfPurchase,
        paymentMethod,
        currencyId,
        comment,
        isChargeableToClient,
        discount,
        taxPercent,
        items,
      } = purchasesData
      setDiscountValue(purchasesData.discount)
      setTaxValue(purchasesData.taxPercent)
      setIssueDate(purchasesData.dateOfPurchase)
      reset({
        assigneeId,
        name,
        billNo,
        vendorId,
        dateOfPurchase,
        paymentMethod,
        currencyId,
        comment,
        isChargeableToClient,
        discount,
        taxPercent,
        items: items?.map(({description, itemName, quantity, unit, unitPrice, categoryId, amount}) => {
          return {description, itemName, quantity, unit, unitPrice, categoryId, amount}
        }),
      })
    },
  })

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'items',
  })

  // Calculation

  const getTotal = () => {
    let TotalAmount = 0

    itemsWatch?.map(({quantity, unitPrice}) => {
      TotalAmount += quantity * unitPrice
    })

    return TotalAmount
  }
  const NetPrice = i => {
    const data = itemsWatch[i]
    return Number(data.unitPrice) * Number(data.quantity)
  }
  const totalDiscount = () => {
    return Number((getTotal() * discountValue) / 100)
  }
  const getDiscountPrice = () => {
    return Number(getTotal() - (getTotal() * totalDiscount()) / getTotal() || 0)
  }
  const totalTax = () => {
    return Number((getDiscountPrice() * taxValue) / 100)
  }
  const getTotalPrice = () => {
    return Number(getDiscountPrice() + (getDiscountPrice() * totalTax()) / getDiscountPrice() || 0).toFixed(2)
  }

  useEffect(() => {
    if (successLabels.includes(CREATE_PURCHASES) || successLabels.includes(UPDATE_PURCHASES)) {
      history.push(`/${PURCHASES_PATH.PREFIX}`)
    }
  }, [successLabels, history, dispatch])

  const FieldList = () => {
    return (
      <>
        {fields.map(({id, categoryId, itemName, description, quantity, unit, unitPrice, amount}, i) => {
          return (
            <KooperTableRow key={id}>
              <KooperTableCell style={{width: 90}}>
                <div className="d-flex" style={{justifyContent: 'flex-start'}}>
                  {fields.length > 1 && (
                    <KooperButton className="m-0" icon onClick={() => remove(i)}>
                      <SvgIcon path="common/delete" />
                    </KooperButton>
                  )}
                  <KooperButton
                    className="mr-0"
                    icon
                    onClick={() =>
                      append({
                        categoryId: '',
                        itemName: '',
                        description: '',
                        quantity: '',
                        unit: '',
                        unitPrice: '',
                        amount: '',
                      })
                    }
                  >
                    <SvgIcon path="common/plus" />
                  </KooperButton>
                </div>
              </KooperTableCell>
              <KooperTableCell>
                <Controller
                  name={`items[${i}].categoryId`}
                  defaultValue={categoryId}
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      search
                      fluid
                      options={categoryList}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors?.items?.[i].categoryId && {
                          content: (
                            <div style={{bottom: '-27px'}}>
                              {removeDoubleQuotes(errors?.items?.[i].categoryId?.message)}
                            </div>
                          ),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperTableCell>
              <KooperTableCell fluid vertical>
                <KooperFormField>
                  <Controller
                    name={`items[${i}].itemName`}
                    control={control}
                    defaultValue={itemName}
                    render={({onChange, value}) => (
                      <KooperFormInput
                        maxLength={16}
                        type="text"
                        placeholder="Item Name"
                        onChange={(e, {value}) => onChange(value)}
                        value={value}
                        error={
                          errors?.items?.[i].itemName && {
                            content: removeDoubleQuotes(errors?.items?.[i].itemName?.message),
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
                <KooperFormField>
                  <Controller
                    name={`items[${i}].description`}
                    defaultValue={description}
                    render={({onChange, value}) => (
                      <KooperFormTextArea
                        maxLength={160}
                        style={{height: '39px'}}
                        type="text"
                        value={value}
                        placeholder="Description"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.items?.[i].description && {
                            content: removeDoubleQuotes(errors.items?.[i].description?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
              </KooperTableCell>

              <KooperTableCell>
                <Controller
                  name={`items[${i}].quantity`}
                  control={control}
                  defaultValue={quantity}
                  render={({onChange, value}) => (
                    <KooperInput
                      // type="number"
                      min={1}
                      placeholder="Quantity"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.items?.[i].quantity && {
                          content: removeDoubleQuotes(errors.items?.[i].quantity?.message),
                        }
                      }
                      style={{width: '95px'}}
                    />
                  )}
                />
              </KooperTableCell>
              <KooperTableCell>
                <Controller
                  name={`items[${i}].unit`}
                  defaultValue={unit}
                  render={({value, onChange}) => (
                    <KooperFormSelect
                      search
                      fluid
                      style={{width: '95px'}}
                      options={unitList}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors?.items?.[i].unit && {
                          content: removeDoubleQuotes(errors.items?.[i].unit?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </KooperTableCell>
              <KooperTableCell>
                <Controller
                  name={`items[${i}].unitPrice`}
                  control={control}
                  defaultValue={unitPrice}
                  render={({onChange, value}) => (
                    <KooperInput
                      style={{width: '95px'}}
                      placeholder="Price"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors?.items?.[i].unitPrice && {
                          content: removeDoubleQuotes(errors.items?.[i].unitPrice?.message),
                        }
                      }
                    />
                  )}
                />
              </KooperTableCell>

              <KooperTableCell>
                <Controller
                  name={`items[${i}].amount`}
                  control={control}
                  defaultValue={Number(amount)}
                  render={() => <KooperInput style={{width: '95px'}} value={NetPrice(i)} disabled />}
                />
              </KooperTableCell>
            </KooperTableRow>
          )
        })}
      </>
    )
  }

  const submitForm = data => {
    data.subTotal = getTotal()
    data.discountAmount = totalDiscount()
    data.taxAmount = totalTax()
    data.totalAmount = getTotalPrice()

    if (editId) {
      return dispatch(updatePurchases(editId, data))
    }

    dispatch(createPurchases(data))
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <KooperButton primary onClick={() => history.push(`/${PURCHASES_PATH.PREFIX}`)}>
          Back
        </KooperButton>
        <h3 className="m-0">Bills</h3>
        <LockPermissionTooltip isRoleAccessDenied={!managePurchasesPermissions}>
          <KooperButton
            className={!managePurchasesPermissions ? 'disabled-button' : ''}
            primary
            {...(managePurchasesPermissions && {
              onClick: handleSubmit(submitForm),
            })}
            disabled={!isDirty}
          >
            {editId ? 'Update' : 'Save'}
          </KooperButton>
        </LockPermissionTooltip>
      </div>
      <KooperContainer>
        <KooperCard fluid width={16}>
          <KooperCardContent>
            <h3 className="mb-0">Bill Details</h3>
            <p className="mt-0 mb-4 card-description">
              Create purchase bills by mentioning all the required information
            </p>
            <KooperDivider />
            <KooperForm className="errorLabel">
              <KooperFormGroup>
                <KooperFormField width={3}>
                  <div className="info-header">
                    <label className="label-class">Bill No</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention the bill number"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="billNo"
                    render={({value, onChange}) => (
                      <KooperFormInput
                        maxLength={16}
                        value={value}
                        placeholder="Bill No"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors.billNo && {
                            content: removeDoubleQuotes(errors.billNo.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>

                <KooperFormField required width={5}>
                  <div className="info-header">
                    <label className="label-class">Purchase Name</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention the name of the purchase"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="name"
                    control={control}
                    render={({onChange, value}) => (
                      <KooperFormInput
                        maxLength={20}
                        type="text"
                        placeholder="Purchase Name"
                        onChange={(e, {value}) => onChange(value)}
                        value={value}
                        error={
                          errors.name && {
                            content: removeDoubleQuotes(errors.name.message),
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>

                <KooperFormField required width={8}>
                  <div className="info-header">
                    <label className="label-class">Author</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Assign a author to the bill"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="assigneeId"
                    render={({onChange, value, onBlur}) => (
                      <KooperFormSelect
                        fluid
                        options={userOption}
                        selection
                        placeholder="Author"
                        onBlur={onBlur}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        error={
                          errors?.assigneeId && {
                            content: removeDoubleQuotes(errors?.assigneeId?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
              </KooperFormGroup>

              <KooperFormGroup widths="equal">
                <KooperFormField required>
                  <div className="info-header">
                    <label className="label-class">Date Of Issue</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Mention the purchase date"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="dateOfPurchase"
                    render={({onChange, value}) => (
                      <KooperDatePicker
                        type="date"
                        value={typeof value === 'string' ? new Date(issueDate) : issueDate}
                        inputProps={{readOnly: true}}
                        time={false}
                        onChange={value => {
                          onChange(value)
                          setIssueDate(value)
                        }}
                        max={new Date()}
                      />
                    )}
                    control={control}
                  />
                  {errors.dateOfPurchase && (
                    <p className="errorLabelElement">{removeDoubleQuotes(errors?.dateOfPurchase?.message)}</p>
                  )}
                </KooperFormField>

                <KooperFormField required>
                  <div className="info-header">
                    <label className="label-class">Issuer</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Name of the issue"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="vendorId"
                    render={({onChange, value}) => (
                      <KooperFormSelect
                        fluid
                        selection
                        options={issuerList}
                        placeholder="Choose supplier"
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        error={
                          errors?.vendorId && {
                            content: removeDoubleQuotes(errors?.vendorId?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
              </KooperFormGroup>

              <KooperFormGroup>
                <KooperFormField required width={8}>
                  <div className="info-header">
                    <label className="label-class">Comment</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Specify a comment on the purchase bill if any"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="comment"
                    render={({onChange, value}) => (
                      <KooperFormTextArea
                        maxLength={160}
                        type="text"
                        value={value}
                        placeholder="Comment"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors.comment && {
                            content: removeDoubleQuotes(errors.comment.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>
              </KooperFormGroup>

              <KooperFormGroup>
                <KooperFormField required width={5}>
                  <div className="info-header">
                    <label className="label-class">Payment</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Select payment method for the purchase"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="paymentMethod"
                    render={({onChange, value, onBlur}) => (
                      <KooperFormSelect
                        fluid
                        options={paymentMethod}
                        selection
                        onBlur={onBlur}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        error={
                          errors?.paymentMethod && {
                            content: removeDoubleQuotes(errors?.paymentMethod?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </KooperFormField>

                <KooperFormField width={3}>
                  <div className="info-header">
                    <label className="label-class">Currency</label>
                    <KooperTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Set the currency"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="currencyId"
                    control={control}
                    render={({value, onChange}) => (
                      <KooperFormSelect
                        selection
                        fluid
                        search
                        placeholder="Select Currency"
                        options={currencyListData}
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={
                          errors.currencyId && {
                            content: errors.currencyId.message,
                          }
                        }
                      />
                    )}
                  />
                </KooperFormField>
              </KooperFormGroup>

              <KooperFormField>
                <Controller
                  name="isChargeableToClient"
                  render={({value, onChange}) => (
                    <KooperCheckbox
                      style={{paddingTop: '30px'}}
                      label="Chargeable to the client"
                      checked={value}
                      onChange={(e, {checked}) => onChange(checked)}
                    />
                  )}
                  control={control}
                />
              </KooperFormField>
            </KooperForm>
          </KooperCardContent>
        </KooperCard>

        <KooperCard fluid width={16}>
          <KooperCardContent>
            <h3 className="mb-0">Product Details</h3>
            <p className="mt-0 mb-4 card-description">
              Select all the products that you want to display on the purchase bill
            </p>
            <KooperDivider />
            <KooperForm className="errorLabel">
              <KooperTable basic className="billProduct">
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell />
                    <KooperTableHeaderCell>Category</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Name</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Quantity</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Unit</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Unit Price</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Net Price</KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>{FieldList()}</KooperTableBody>
              </KooperTable>
              <div className="totalBillCount" style={{justifyContent: 'end'}}>
                <div className="count">
                  <p className="d-flex">
                    <span>Subtotal</span>
                    <span>{getTotal().toFixed(2)}</span>
                  </p>

                  <p className="d-flex">
                    <label
                      style={{
                        width: '70%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      Discount
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Controller
                          name="discount"
                          render={({value, onChange}) => (
                            <KooperInput
                              style={{width: '100px', marginRight: '4px'}}
                              value={value}
                              onChange={(e, {value}) => {
                                onChange(value)
                                setDiscountValue(value)
                              }}
                            />
                          )}
                          control={control}
                        />
                        <span>%</span>
                      </div>
                    </label>

                    <span>{totalDiscount()}</span>
                  </p>

                  <p className="d-flex">
                    <span>Sum without tax</span>
                    <span>{getDiscountPrice()}</span>
                  </p>

                  <p className="d-flex">
                    <label
                      style={{
                        width: '70%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      Tax
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Controller
                          name="taxPercent"
                          render={({value, onChange}) => (
                            <KooperInput
                              style={{width: '100px', marginRight: '4px'}}
                              value={value}
                              onChange={(e, {value}) => {
                                onChange(value)
                                setTaxValue(value)
                              }}
                            />
                          )}
                          control={control}
                        />
                        <span>%</span>
                      </div>
                    </label>

                    <span>{totalTax()}</span>
                  </p>

                  <p className="d-flex">
                    <span>Grand Total</span>
                    <span>{getTotalPrice()}</span>
                  </p>
                </div>
              </div>
            </KooperForm>
          </KooperCardContent>
        </KooperCard>
      </KooperContainer>
    </div>
  )
}

export default PurchaseModal
