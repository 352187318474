import React, {useState} from 'react'
import {KooperMenu, KooperMenuItem, KooperAccordion, KooperAccordionTitle, KooperAccordionContent} from 'kooper-library'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {FiChevronRight, FiLayers} from 'react-icons/fi'
import SvgIcon from 'components/common/SvgIcon'
import {isActiveNavlink} from 'utils/helper'
import {VENDORS_PATH} from 'constants/path'

const VendorsMenu = () => {
  const history = useHistory()
  const {url} = useRouteMatch()

  const [expand, setExpand] = useState([])

  const menus = [
    {
      key: 'settings',
      title: 'Settings',
      items: [
        {
          path: VENDORS_PATH.CATEGORY,
          title: 'Category',
          icon: <SvgIcon path="finance/category" />,
        },
        {
          path: VENDORS_PATH.FIELDS,
          title: 'Fields',
          icon: <SvgIcon path="finance/fields" />,
        },
      ],
    },
  ]

  const handleMenuExpand = key => {
    const menus = [...expand]
    if (menus.includes(key)) {
      menus.splice(menus.indexOf(key), 1)
      setExpand(menus)
    } else {
      setExpand([...menus, key])
    }
  }

  const isActive = key => !expand.includes(key)

  return (
    <KooperMenu vertical secondary className="kooper-menu">
      <KooperMenuItem
        active={isActiveNavlink(`/${VENDORS_PATH.PREFIX}/${VENDORS_PATH.ALL}`)}
        onClick={() => history.push(`/${VENDORS_PATH.PREFIX}/${VENDORS_PATH.ALL}`)}
      >
        <FiLayers /> All Vendors
      </KooperMenuItem>

      {menus.map(({title, key, items}) => (
        <KooperAccordion key={key}>
          <KooperAccordionTitle onClick={() => handleMenuExpand(key)}>
            {title}
            {isActive(key) ? <SvgIcon path="common/down" /> : <FiChevronRight />}
          </KooperAccordionTitle>
          <KooperAccordionContent active={isActive(key)}>
            {items.map(({path, title, icon}) => (
              <KooperMenuItem key={path} active={isActiveNavlink(path)} onClick={() => history.push(`${url}/${path}`)}>
                {icon} {title}
              </KooperMenuItem>
            ))}
          </KooperAccordionContent>
        </KooperAccordion>
      ))}
    </KooperMenu>
  )
}

export default VendorsMenu
