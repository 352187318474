/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiExternalLink} from 'react-icons/fi'
import {useHistory} from 'react-router'
import {
  KooperButton,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperHeader,
  KooperIcon,
  KooperLabel,
  KooperTable,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeader,
  KooperTableHeaderCell,
  KooperTablePlaceholder,
  KooperTableRow,
  KooperTooltip,
} from 'kooper-library'
import {cloneProjectForm, deleteProjectForm, getAllProjectForms, publishProjectForm} from 'actions/projects'
import CreateFirst from 'components/common/CreateFirst'
import {CLONE_PROJECT_FORM, DELETE_PROJECT_FORM, GET_ALL_PROJECT_FORMS, PROJECT_FORM_PUBLISHED} from 'actions/types'
import {getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import DeleteModal from 'components/common/DeleteModal'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {PROJECTS_PATH} from 'constants/path'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import FormModal from './FormModal'

function FormsList(props) {
  const {projectId, rolePermissions} = props
  const dispatch = useDispatch()
  const history = useHistory()

  const [isAddFormOpen, setIsAddFormOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [copyLink, setCopyLink] = useState('Copy')

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const getHref = uniqId => {
    const accountInfo = JSON.parse(localStorage.getItem('accountInfo'))
    return `https://${accountInfo.domain}.kooperhq.com/public/form/${uniqId}`
  }

  const {data: getFormsList = [], callAction: callGetAllProjectFormsAction} = useApiResponse({
    action: getAllProjectForms,
    payload: {projectId},
    dependency: [projectId],
    enabled: projectId,
    storePath: 'projects.getFormsList',
  })

  useEffect(() => {
    if (
      successLabels.includes(DELETE_PROJECT_FORM) ||
      successLabels.includes(CLONE_PROJECT_FORM) ||
      successLabels.includes(PROJECT_FORM_PUBLISHED)
    ) {
      callGetAllProjectFormsAction()
    }
  }, [successLabels, callGetAllProjectFormsAction])

  const renderFormModal = () => {
    if (isAddFormOpen) {
      return <FormModal projectId={projectId} setIsAddFormOpen={setIsAddFormOpen} isAddFormOpen={isAddFormOpen} />
    }
  }

  const renderTableBody = () => {
    return getFormsList.map(({id, title, responses, status, uniqueId, createdAt, createdBy}, index) => {
      return (
        <KooperTableRow
          key={id}
          className="tableLink"
          onClick={() => {
            history.push(`${PROJECTS_PATH.FORMS}/${id}`)
          }}
        >
          <KooperTableCell>{index + 1}</KooperTableCell>
          <KooperTableCell className="prevCell ">
            <KooperHeader as="h5">{startCase(title)}</KooperHeader>
            {status !== 'draft' && (
              <div className="PrevActPart" onClick={e => e.stopPropagation()}>
                <KooperLabel
                  basic
                  onClick={e => {
                    e.stopPropagation()
                    setCopyLink('Copied')
                    navigator.clipboard.writeText(getHref(uniqueId))
                  }}
                >
                  {copyLink} Link
                </KooperLabel>

                <a href={getHref(uniqueId)} target="_blank" rel="noreferrer">
                  <KooperButton
                    className="mx-3"
                    icon={
                      <KooperTooltip
                        content="Public Form Link"
                        position="top center"
                        size="mini"
                        trigger={<FiExternalLink />}
                      />
                    }
                  />
                </a>
              </div>
            )}
          </KooperTableCell>
          <KooperTableCell>{responses}</KooperTableCell>
          <KooperTableCell>{status && <KooperLabel color="green">{startCase(status)}</KooperLabel>}</KooperTableCell>
          <KooperTableCell>{createdAt && getFormattedDate(createdAt)}</KooperTableCell>
          <KooperTableCell>{`${createdBy?.firstName} ${createdBy?.lastName}`}</KooperTableCell>
          <KooperTableCell>
            <KooperDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon" direction="left">
              <KooperDropdownMenu>
                {status !== 'draft' && (
                  <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
                    <KooperDropdownItem href={rolePermissions && getHref(uniqueId)} target="_blank">
                      <KooperIcon name="share" /> View Published Form
                    </KooperDropdownItem>
                  </LockPermissionTooltip>
                )}
                {status === 'draft' && (
                  <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
                    <KooperDropdownItem
                      onClick={() => {
                        if (rolePermissions) {
                          const data = {
                            status: 'published',
                          }
                          dispatch(publishProjectForm({projectId, formId: id, data}))
                        }
                      }}
                    >
                      <KooperIcon name="share square" /> Publish
                    </KooperDropdownItem>
                  </LockPermissionTooltip>
                )}
                <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
                  <KooperDropdownItem
                    onClick={() => rolePermissions && dispatch(cloneProjectForm({projectId, formId: id}))}
                  >
                    <KooperIcon name="clone outline" /> Clone
                  </KooperDropdownItem>
                </LockPermissionTooltip>
                <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
                  <KooperDropdownItem
                    onClick={() => {
                      if (rolePermissions) {
                        setEditId(id)
                        setDeleteModal(true)
                      }
                    }}
                  >
                    <KooperIcon name="trash alternate" /> Delete
                  </KooperDropdownItem>
                </LockPermissionTooltip>
              </KooperDropdownMenu>
            </KooperDropdown>
          </KooperTableCell>
        </KooperTableRow>
      )
    })
  }

  if (!getFormsList?.length) {
    return (
      <div className="pageBodyWrap">
        <CreateFirst
          header="Forms"
          subHeader="Streamline project management process with customizable forms designed for project needs"
          addFirst={() => rolePermissions && setIsAddFormOpen(true)}
          buttonText="Create Form"
          tooltip={!rolePermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: rolePermissions})}
          Feature1="Easy Customization"
          Feature2="Distribution"
          Feature3="Confirmation Message"
          list1="Easily customize the layout, fields, and design of each form."
          list2="Distribute forms to team members within project for input and feedback."
          list3="Sent predefined confirmation message after form submission"
        />
        {renderFormModal()}
      </div>
    )
  }

  return (
    <>
      <div className="pageBodyWrap">
        <div className="pageHeader px-0">
          <div className="pageTitle">
            <div className="pageTitleIcon">
              <SvgIcon path="settings/users" />
              <h5>Forms ({getFormsList ? getFormsList?.length : 0})</h5>
            </div>
          </div>
          <div className="pageAction">
            <LockPermissionTooltip isRoleAccessDenied={!rolePermissions}>
              <KooperButton
                content="Create Form"
                className={!rolePermissions ? 'disabled-button' : ''}
                primary
                onClick={() => rolePermissions && setIsAddFormOpen(true)}
              />
            </LockPermissionTooltip>
          </div>
        </div>
        <KooperTable sortable basic selectable>
          <KooperTableHeader>
            <KooperTableRow>
              <KooperTableHeaderCell>#</KooperTableHeaderCell>
              <KooperTableHeaderCell>Title</KooperTableHeaderCell>
              <KooperTableHeaderCell>Total Responses</KooperTableHeaderCell>
              <KooperTableHeaderCell>Status</KooperTableHeaderCell>
              <KooperTableHeaderCell>Created By</KooperTableHeaderCell>
              <KooperTableHeaderCell>Created At</KooperTableHeaderCell>
              <KooperTableHeaderCell>Actions</KooperTableHeaderCell>
            </KooperTableRow>
          </KooperTableHeader>
          <KooperTableBody>
            {isLoadingData && type.includes(GET_ALL_PROJECT_FORMS) ? (
              <KooperTablePlaceholder columns={8} />
            ) : !isLoadingData && (getFormsList || []).length > 0 ? (
              renderTableBody()
            ) : (
              <tr>
                <td colSpan={8} className="emptyValue">
                  No Data Available
                </td>
              </tr>
            )}
          </KooperTableBody>
        </KooperTable>
      </div>
      {renderFormModal()}
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          onDelete={() => dispatch(deleteProjectForm({formId: editId, projectId}))}
          type={DELETE_PROJECT_FORM}
        />
      )}
    </>
  )
}

export default FormsList
