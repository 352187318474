import React from 'react'
import {KooperLoader} from 'kooper-library'

const PageLoader = () => {
  return (
    <KooperLoader
      active
      inline="centered"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#152e4d',
      }}
    />
  )
}

export default PageLoader
