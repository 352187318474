/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState, useEffect} from 'react'
import {atomOneLight} from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import SyntaxHighlighter from 'react-syntax-highlighter'
import {FiChevronRight} from 'react-icons/fi'
import _ from 'lodash'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperCardGroup,
  KooperCardHeader,
  KooperCardMeta,
  KooperHeader,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperImage,
  KooperGrid,
  KooperGridColumn,
  KooperFormCheckbox,
  KooperFormInput,
  KooperFormField,
  KooperForm,
  KooperColorPicker,
  KooperAccordionTitle,
  KooperAccordionContent,
  KooperAccordion,
  KooperCheckbox,
} from 'kooper-library'
import cal from 'assets/inline-meet.svg'
import {copyToClipboardWithoutInput} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const EmbedInline = props => {
  const {selectedEmbed, setIsEmbedDetails, url} = props
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const [copySuccess, setCopySuccess] = useState('COPY')
  const [widgetData, setWidgetData] = useState({
    hide_gdpr_banner: false,
    hide_event_type_details: false,
  })
  const [query, setQuery] = useState('')

  const [code, setCode] = useState(`
  <link
    rel="stylesheet"
    href="https://s3.amazonaws.com/app.veemo.io/crm-index.css"
  />
  <div 
    id="meetings-iframe-container"
    widget-type="${selectedEmbed}"
    data-src="${url}"
  ></div>
  <script
    type="text/javascript"
    src="https://s3.amazonaws.com/app.veemo.io/crm-index.js"
  ></script>`)

  useEffect(() => {
    if (!_.isEmpty(widgetData)) {
      const keys = Object.keys(widgetData || {})
      const query = keys
        .filter(key => widgetData[key])
        .map(key => `${key}=${widgetData[key]}`)
        .join('&')
      setQuery(query)
    }
  }, [widgetData])

  useEffect(() => {
    setCode(`
  <link
    rel="stylesheet"
    href="https://s3.amazonaws.com/app.veemo.io/crm-index.css"
  />
  <div 
    id="meetings-iframe-container"
    widget-type="${selectedEmbed}"
    data-src="${url}${query && `&${query}`}">
  </div>
  <script
    type="text/javascript"
    src="https://s3.amazonaws.com/app.veemo.io/crm-index.js"
  ></script>`)
  }, [query])

  return (
    <>
      <KooperHeader>Inline Embed</KooperHeader>
      <KooperModalContent className="cal-embd">
        <KooperGrid divided>
          <KooperGridColumn width={6}>
            <h5 className="m-0">Page Settings</h5>
            <p className="kooper-lead mb-4">Manage all settings related to inline style of calender</p>
            <KooperForm>
              <KooperFormField>
                <KooperCheckbox
                  className="mb-0"
                  label="Show events type details"
                  checked={widgetData.hide_event_type_details}
                  onChange={() =>
                    setWidgetData({
                      ...widgetData,
                      hide_event_type_details: !widgetData.hide_event_type_details,
                    })
                  }
                />
                <p className="kooper-lead" style={{padding: '8px 0 0 26px', fontSize: '13px'}}>
                  All event will be displayed in details in details
                </p>
              </KooperFormField>
              <KooperFormField>
                <KooperCheckbox
                  label="Show GDPR Banner"
                  className="mb-0"
                  checked={widgetData.hide_gdpr_banner}
                  onChange={() =>
                    setWidgetData({
                      ...widgetData,
                      hide_gdpr_banner: !widgetData.hide_gdpr_banner,
                    })
                  }
                />
                <p className="kooper-lead" style={{padding: '8px 0 0 26px', fontSize: '13px'}}>
                  Display GDRP banner on the calendar
                </p>
              </KooperFormField>
              <KooperFormField>
                <label>Brand Color</label>
                <p className="kooper-lead">Set brand color of the calendar</p>
                <div
                  style={{
                    padding: 5,
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                  }}
                  onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                >
                  <div
                    style={{
                      width: '50px',
                      height: '15px',
                      borderRadius: '2px',
                      background: `#${widgetData.brandColor}`,
                    }}
                  />
                </div>
                {isColorPickerOpen && (
                  <div
                    style={{
                      position: 'absolute',
                      zIndex: '2',
                    }}
                  >
                    <div
                      style={{
                        position: 'fixed',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                      }}
                      onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                    />
                    <KooperColorPicker
                      color={`#${widgetData.brandColor}`}
                      onChange={value =>
                        setWidgetData({
                          ...widgetData,
                          brandColor: value.hex.substring(1),
                        })
                      }
                    />
                  </div>
                )}
              </KooperFormField>
            </KooperForm>
          </KooperGridColumn>
          <KooperGridColumn width={10} className="pl-4">
            <h5 className="m-0">Embed Code</h5>
            <p className="kooper-lead">copy paste the code snippet to embed calender in your website in inline style</p>
            <div className="codeTextView">
              <SyntaxHighlighter language="javascript" style={atomOneLight}>
                {code}
              </SyntaxHighlighter>
            </div>
            <p className="kooper-lead">
              Copy the code snippet and paste it anywhere within the body of page. Now you have successfully embed the
              calendar for booking meetings.
            </p>
          </KooperGridColumn>
        </KooperGrid>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton
          basic
          onClick={() => {
            setIsEmbedDetails(false)
          }}
        >
          Back
        </KooperButton>
        <KooperButton primary onClick={e => copyToClipboardWithoutInput(code, setCopySuccess)}>
          {copySuccess}
        </KooperButton>
      </KooperModalActions>
    </>
  )
}

const EmbedPopupWidget = props => {
  const {selectedEmbed, setIsEmbedDetails, url} = props

  const [activeIndex, setActiveIndex] = useState(0)
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const [isButtonTextColor, setIsButtonTextColor] = useState(false)
  const [isButtonBackGColor, setIsButtonBackGColor] = useState(false)
  const [copySuccess, setCopySuccess] = useState('COPY')
  const [widgetData, setWidgetData] = useState({
    hide_gdpr_banner: false,
    hide_event_type_details: false,
    ButtonText: 'Schedule time with me',
  })
  const [query, setQuery] = useState('')

  const [code, setCode] = useState(`
  <link
    rel="stylesheet"
    href="https://s3.amazonaws.com/app.veemo.io/crm-index.css"
  />
  <div 
    id="meetings-iframe-container"
    widget-type="${selectedEmbed}"
    data-src="${url}"
  ></div>
  <script
    type="text/javascript"
    src="https://s3.amazonaws.com/app.veemo.io/crm-index.js"
  ></script>`)

  useEffect(() => {
    if (!_.isEmpty(widgetData)) {
      const keys = Object.keys(widgetData || {})
      const query = keys
        .filter(key => widgetData[key])
        .map(key => `${key}=${widgetData[key]}`)
        .join('&')

      setQuery(query)
    }
  }, [widgetData])

  useEffect(() => {
    if (query) {
      setCode(`
  <link
    rel="stylesheet"
    href="https://s3.amazonaws.com/app.veemo.io/crm-index.css"
  />
  <div 
    id="meetings-iframe-container"
    widget-type="${selectedEmbed}"
    data-src="${url}${query && `&${query}`}">
  </div>
  <script
    type="text/javascript"
    src="https://s3.amazonaws.com/app.veemo.io/crm-index.js"
  ></script>`)
    }
  }, [query])

  return (
    <>
      <KooperHeader>Popup Widget</KooperHeader>
      <KooperModalContent className="cal-embd">
        <KooperGrid divided>
          <KooperGridColumn width={6} className="pr-4">
            <KooperAccordion styled className="kooper-accordion">
              <KooperAccordionTitle
                active={activeIndex === 0}
                index={0}
                onClick={(e, titleProps) => {
                  const {index} = titleProps
                  const newIndex = activeIndex === index ? -1 : index
                  setActiveIndex(newIndex)
                }}
              >
                <h5 className="m-0">Widget Settings</h5>
                <span>{!activeIndex ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
              </KooperAccordionTitle>
              <KooperAccordionContent active={activeIndex === 0}>
                <p className="kooper-lead mb-4">Configure your calendar popup widget that will appear on your site</p>
                <KooperForm>
                  <KooperFormField>
                    <label>Button Text</label>
                    <p className="kooper-lead">Specify a text for widget button field</p>
                    <KooperFormInput
                      placeholder="e.g. Schedule time with me"
                      value={widgetData.ButtonText}
                      onChange={(e, {value}) => setWidgetData({...widgetData, ButtonText: value})}
                    />
                  </KooperFormField>

                  <KooperFormField>
                    <label>Button background color</label>
                    <p className="kooper-lead">Set background color of the button</p>
                    <div
                      style={{
                        padding: 5,
                        background: '#fff',
                        borderRadius: '1px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsButtonBackGColor(!isButtonBackGColor)}
                    >
                      <div
                        style={{
                          width: '50px',
                          height: '15px',
                          borderRadius: '2px',
                          background: `#${widgetData.color}`,
                        }}
                      />
                    </div>
                    {isButtonBackGColor && (
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: '2',
                        }}
                      >
                        <div
                          style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                          }}
                          onClick={() => setIsButtonBackGColor(!isButtonBackGColor)}
                        />
                        <KooperColorPicker
                          color={`#${widgetData.color}`}
                          onChange={value =>
                            setWidgetData({
                              ...widgetData,
                              color: value.hex.substring(1),
                            })
                          }
                        />
                      </div>
                    )}
                  </KooperFormField>
                  <KooperFormField>
                    <label>Button text color</label>
                    <p className="kooper-lead">set text color of the button</p>
                    <div
                      style={{
                        padding: 5,
                        background: '#fff',
                        borderRadius: '1px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsButtonTextColor(!isButtonTextColor)}
                    >
                      <div
                        style={{
                          width: '50px',
                          height: '15px',
                          borderRadius: '2px',
                          background: `#${widgetData.textColor}`,
                        }}
                      />
                    </div>
                    {isButtonTextColor && (
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: '2',
                        }}
                      >
                        <div
                          style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                          }}
                          onClick={() => setIsButtonTextColor(!isButtonTextColor)}
                        />
                        <KooperColorPicker
                          color={`#${widgetData.textColor}`}
                          onChange={value =>
                            setWidgetData({
                              ...widgetData,
                              textColor: value.hex.substring(1),
                            })
                          }
                        />
                      </div>
                    )}
                  </KooperFormField>
                </KooperForm>
              </KooperAccordionContent>
              <KooperAccordionTitle
                active={activeIndex === 1}
                index={1}
                onClick={(e, titleProps) => {
                  const {index} = titleProps
                  const newIndex = activeIndex === index ? -1 : index
                  setActiveIndex(newIndex)
                }}
              >
                <h5 className="m-0">Page Settings</h5>
                <span>{!activeIndex ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
              </KooperAccordionTitle>
              <KooperAccordionContent active={activeIndex === 1}>
                <p className="kooper-lead mb-4">Manage all setting related to popup widget</p>
                <KooperForm>
                  <KooperFormField>
                    <KooperFormCheckbox
                      className="mb-0"
                      label="Show events type details"
                      checked={widgetData.hide_event_type_details}
                      onChange={() =>
                        setWidgetData({
                          ...widgetData,
                          hide_event_type_details: !widgetData.hide_event_type_details,
                        })
                      }
                    />
                    <p className="kooper-lead" style={{padding: '8px 0 0 26px', fontSize: '13px'}}>
                      All event will be displayed in details in details
                    </p>
                  </KooperFormField>
                  <KooperFormField>
                    <KooperFormCheckbox
                      label="Show GDPR Banner"
                      className="mb-0"
                      checked={widgetData.hide_gdpr_banner}
                      onChange={() =>
                        setWidgetData({
                          ...widgetData,
                          hide_gdpr_banner: !widgetData.hide_gdpr_banner,
                        })
                      }
                    />
                    <p className="kooper-lead" style={{padding: '8px 0 0 26px', fontSize: '13px'}}>
                      Display GDRP banner on the calendar
                    </p>
                  </KooperFormField>
                  <KooperFormField>
                    <label>Brand Color</label>
                    <p className="kooper-lead">Set brand color of the calendar</p>
                    <div
                      style={{
                        padding: 5,
                        background: '#fff',
                        borderRadius: '1px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                    >
                      <div
                        style={{
                          width: '50px',
                          height: '15px',
                          borderRadius: '2px',
                          background: `#${widgetData.brandColor}`,
                        }}
                      />
                    </div>
                    {isColorPickerOpen && (
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: '2',
                        }}
                      >
                        <div
                          style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                          }}
                          onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                        />
                        <KooperColorPicker
                          color={`#${widgetData.brandColor}`}
                          onChange={value =>
                            setWidgetData({
                              ...widgetData,
                              brandColor: value.hex.substring(1),
                            })
                          }
                        />
                      </div>
                    )}
                  </KooperFormField>
                </KooperForm>
              </KooperAccordionContent>
            </KooperAccordion>
          </KooperGridColumn>
          <KooperGridColumn width={10} className="pl-4">
            <h5 className="m-0">Embed Code</h5>
            <p className="kooper-lead">
              Copy paste the code snippet to embed calender in your website in form of popup widget
            </p>
            <div className="codeTextView">
              <SyntaxHighlighter language="javascript" style={atomOneLight}>
                {code}
              </SyntaxHighlighter>
            </div>
            <p className="kooper-lead">
              Copy the code snippet and paste it anywhere within the body of page. Now you have successfully embed the
              calendar for booking meetings.
            </p>
          </KooperGridColumn>
        </KooperGrid>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton
          basic
          onClick={() => {
            setIsEmbedDetails(false)
          }}
        >
          Back
        </KooperButton>
        <KooperButton primary onClick={e => copyToClipboardWithoutInput(code, setCopySuccess)}>
          {copySuccess}
        </KooperButton>
      </KooperModalActions>
    </>
  )
}

const EmbedPopupText = props => {
  const {selectedEmbed, setIsEmbedDetails, url} = props
  const [widgetData, setWidgetData] = useState({
    hide_gdpr_banner: false,
    hide_event_type_details: false,
    LinkText: 'Schedule time with me',
  })
  const [activeIndex, setActiveIndex] = useState(0)
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const [query, setQuery] = useState('')
  const [copySuccess, setCopySuccess] = useState('COPY')
  const [code, setCode] = useState(`
  <link
    rel="stylesheet"
    href="https://s3.amazonaws.com/app.veemo.io/crm-index.css"
  />
  <div 
    id="meetings-iframe-container"
    widget-type="${selectedEmbed}"
    data-src="${url}"
  ></div>
  <script
    type="text/javascript"
    src="https://s3.amazonaws.com/app.veemo.io/crm-index.js"
  ></script>`)

  useEffect(() => {
    if (!_.isEmpty(widgetData)) {
      const keys = Object.keys(widgetData || {})
      const query = keys
        .filter(key => widgetData[key])
        .map(key => `${key}=${widgetData[key]}`)
        .join('&')

      setQuery(query)
    }
  }, [widgetData])

  useEffect(() => {
    if (query) {
      setCode(`
  <link
    rel="stylesheet"
    href="https://s3.amazonaws.com/app.veemo.io/crm-index.css"
  />
  <div 
    id="meetings-iframe-container"
    widget-type="${selectedEmbed}"
    data-src="${url}${query && `&${query}`}">
  </div>
  <script
    type="text/javascript"
    src="https://s3.amazonaws.com/app.veemo.io/crm-index.js"
  ></script>`)
    }
  }, [query])

  return (
    <>
      <KooperHeader>Popup Text</KooperHeader>
      <KooperModalContent className="cal-embd">
        <KooperGrid divided>
          <KooperGridColumn width={6} className="pr-4">
            <KooperAccordion styled className="kooper-accordion">
              <KooperAccordionTitle
                active={activeIndex === 0}
                index={0}
                onClick={(e, titleProps) => {
                  const {index} = titleProps
                  const newIndex = activeIndex === index ? -1 : index
                  setActiveIndex(newIndex)
                }}
              >
                <h5 className="m-0">Text Link Settings</h5>
                <span>{!activeIndex ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
              </KooperAccordionTitle>
              <KooperAccordionContent active={activeIndex === 0}>
                <p className="kooper-lead mb-4">Specify the text you want to use as a hyperlink for calender</p>
                <KooperForm>
                  <KooperFormField>
                    <label>Link Text</label>
                    <KooperFormInput
                      placeholder="e.g. Schedule time with me"
                      value={widgetData.LinkText}
                      onChange={(e, {value}) => setWidgetData({...widgetData, LinkText: value})}
                    />
                  </KooperFormField>
                </KooperForm>
              </KooperAccordionContent>
              <KooperAccordionTitle
                active={activeIndex === 1}
                index={1}
                onClick={(e, titleProps) => {
                  const {index} = titleProps
                  const newIndex = activeIndex === index ? -1 : index
                  setActiveIndex(newIndex)
                }}
              >
                <h5 className="m-0">Page Settings</h5>
                <span>{!activeIndex ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
              </KooperAccordionTitle>
              <KooperAccordionContent active={activeIndex === 1}>
                <p className="kooper-lead mb-4">
                  Manage all setting related to calendar appearance in form of popup text
                </p>
                <KooperForm>
                  <KooperFormField>
                    <KooperFormCheckbox
                      className="mb-0"
                      label="Show events type details"
                      checked={widgetData.hide_event_type_details}
                      onChange={() =>
                        setWidgetData({
                          ...widgetData,
                          hide_event_type_details: !widgetData.hide_event_type_details,
                        })
                      }
                    />
                    <p className="kooper-lead" style={{padding: '8px 0 0 26px', fontSize: '13px'}}>
                      All event will be displayed in details in details
                    </p>
                  </KooperFormField>
                  <KooperFormField>
                    <KooperFormCheckbox
                      label="Show GDPR Banner"
                      className="mb-0"
                      checked={widgetData.hide_gdpr_banner}
                      onChange={() =>
                        setWidgetData({
                          ...widgetData,
                          hide_gdpr_banner: !widgetData.hide_gdpr_banner,
                        })
                      }
                    />
                    <p className="kooper-lead" style={{padding: '8px 0 0 26px', fontSize: '13px'}}>
                      Display GDRP banner on the calendar
                    </p>
                  </KooperFormField>
                  <KooperFormField>
                    <label>Brand Color</label>
                    <p className="kooper-lead">Set brand color of the calendar</p>
                    <div
                      style={{
                        padding: 5,
                        background: '#fff',
                        borderRadius: '1px',
                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                        display: 'inline-block',
                        cursor: 'pointer',
                      }}
                      onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                    >
                      <div
                        style={{
                          width: '50px',
                          height: '15px',
                          borderRadius: '2px',
                          background: `#${widgetData.brandColor}`,
                        }}
                      />
                    </div>
                    {isColorPickerOpen && (
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: '2',
                        }}
                      >
                        <div
                          style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                          }}
                          onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                        />
                        <KooperColorPicker
                          color={`#${widgetData.brandColor}`}
                          onChange={value =>
                            setWidgetData({
                              ...widgetData,
                              brandColor: value.hex.substring(1),
                            })
                          }
                        />
                      </div>
                    )}
                  </KooperFormField>
                </KooperForm>
              </KooperAccordionContent>
            </KooperAccordion>
          </KooperGridColumn>
          <KooperGridColumn width={10} className="pl-4">
            <h5 className="m-0">Embed Code</h5>
            <p className="kooper-lead">
              copy paste the code snippet to embed calender in your website in form of popup text style
            </p>
            <div className="codeTextView">
              <SyntaxHighlighter language="javascript" style={atomOneLight}>
                {code}
              </SyntaxHighlighter>
            </div>
            <p className="kooper-lead">
              Copy the code snippet and paste it anywhere within the body of page. Now you have successfully embed the
              calendar for booking meetings.
            </p>
          </KooperGridColumn>
        </KooperGrid>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton
          basic
          onClick={() => {
            setIsEmbedDetails(false)
          }}
        >
          Back
        </KooperButton>
        <KooperButton primary onClick={e => copyToClipboardWithoutInput(code, setCopySuccess)}>
          {copySuccess}
        </KooperButton>
      </KooperModalActions>
    </>
  )
}

const EmbedModal = props => {
  const {isModalOpen, setIsModalOpen} = props
  const [selectedEmbed, setSelectedEmbed] = useState('')
  const [isEmbedDetails, setIsEmbedDetails] = useState(false)

  const EmbedFunction = () => {
    switch (selectedEmbed) {
      case 'inline': {
        return <EmbedInline selectedEmbed={selectedEmbed} setIsEmbedDetails={setIsEmbedDetails} {...props} />
      }
      case 'popupWidget': {
        return <EmbedPopupWidget selectedEmbed={selectedEmbed} setIsEmbedDetails={setIsEmbedDetails} {...props} />
      }
      case 'popupText': {
        return <EmbedPopupText selectedEmbed={selectedEmbed} setIsEmbedDetails={setIsEmbedDetails} {...props} />
      }
      default: {
        return ''
      }
    }
  }

  return (
    <KooperModal
      open={isModalOpen}
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => {
        setIsModalOpen(!isModalOpen)
        setIsEmbedDetails(false)
        setSelectedEmbed('')
      }}
    >
      {!isEmbedDetails ? (
        <>
          <KooperHeader>Embed Code</KooperHeader>
          <KooperModalContent className="cal-embd" scrolling>
            <h4>How do you want to add calender in your site?</h4>
            <KooperCardGroup>
              <KooperCard
                link
                className={selectedEmbed === 'inline' && 'active'}
                onClick={() => setSelectedEmbed('inline')}
              >
                <KooperCardContent>
                  <KooperImage src={cal} />
                  <KooperCardHeader>Inline Embed</KooperCardHeader>
                  <KooperCardMeta>Calendar will appear seamlessly within the body of your site</KooperCardMeta>
                </KooperCardContent>
              </KooperCard>
              <KooperCard
                link
                className={selectedEmbed === 'popupWidget' && 'active'}
                onClick={() => setSelectedEmbed('popupWidget')}
              >
                <KooperCardContent>
                  <KooperImage src={cal} />
                  <KooperCardHeader>Popup Widget</KooperCardHeader>
                  <KooperCardMeta>A button opens calender in a popup window</KooperCardMeta>
                </KooperCardContent>
              </KooperCard>
              <KooperCard
                link
                className={selectedEmbed === 'popupText' && 'active'}
                onClick={() => setSelectedEmbed('popupText')}
              >
                <KooperCardContent>
                  <KooperImage src={cal} />
                  <KooperCardHeader>Popup Text</KooperCardHeader>
                  <KooperCardMeta>
                    Add Preferred text to body of a page, which opens up popup window of calender when clicked
                  </KooperCardMeta>
                </KooperCardContent>
              </KooperCard>
            </KooperCardGroup>
            <a href="" target="_blank">
              See Example of how calender can let customers schedule directily from your site
            </a>
          </KooperModalContent>
          <KooperModalActions>
            <KooperButton basic onClick={() => setIsModalOpen(!isModalOpen)}>
              Cancel
            </KooperButton>
            <KooperButton primary disabled={!selectedEmbed} onClick={() => setIsEmbedDetails(!isEmbedDetails)}>
              Next
            </KooperButton>
          </KooperModalActions>
        </>
      ) : (
        isEmbedDetails && EmbedFunction()
      )}
    </KooperModal>
  )
}

export default EmbedModal
