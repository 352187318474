import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import _ from 'lodash'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormSelect,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'
import {removeDoubleQuotes} from 'utils/helper'

import {GET_PROJECTS_TASKS_LIST_SINGLE_TASK, UPDATE_PROJECTS_TASKS_LIST_TASK_ASSIGNEE} from 'actions/types'
import {getProjectTasksListSingleTask, updateProjectsTasksListTaskAssignee} from 'actions/projects'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const AssigneeModal = ({open, toggle, projectId, taskId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {taskListAssignees = []} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {assignedTo: null},
    resolver: joiResolver(
      Joi.object({
        assignedTo: Joi.number().required().messages({
          'number.base': 'Subscriber must be specified',
        }),
      })
    ),
  })

  useApiResponse({
    action: getProjectTasksListSingleTask,
    payload: {projectId, taskId},
    dependency: [projectId, taskId],
    enabled: projectId && taskId,
    label: GET_PROJECTS_TASKS_LIST_SINGLE_TASK,
    storePath: 'projects.taskData',
    onSuccess: (taskData = {}) => {
      reset(_.pick(taskData, 'assignedTo'))
    },
  })

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECTS_TASKS_LIST_TASK_ASSIGNEE)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    dispatch(updateProjectsTasksListTaskAssignee({projectId, taskId, data}))
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader>Assignees</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <label>Assignee</label>
            <Controller
              name="assignedTo"
              control={control}
              render={({value, onChange}) => (
                <KooperFormSelect
                  search
                  options={taskListAssignees.map(({id, firstName, lastName}) => ({
                    key: id,
                    value: id,
                    text: `${firstName} ${lastName}`,
                  }))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.assignedTo && {content: removeDoubleQuotes(errors.assignedTo.message)}}
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary onClick={handleSubmit(submitForm)}>
          Update
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default AssigneeModal
