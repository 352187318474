import React from 'react'
import {useSelector} from 'react-redux'
import {KooperContainer} from 'kooper-library'
import StandardTemplate from './StandardTemplate'

function PreviewSalesOrder({watch, addressTemplate, liveNumber, style}) {
  const {SalesOrderForm = {}} = useSelector(state => state.salesOrders)
  const {previewFile} = useSelector(state => state.salesOrders.uploadFile)

  const {template: {templateNo = 2} = {}} = SalesOrderForm

  if ((liveNumber || templateNo) === 2) {
    return (
      <KooperContainer>
        <StandardTemplate data={{...SalesOrderForm, preview: previewFile, ...watch, addressTemplate, style}} />
      </KooperContainer>
    )
  }
}

export default PreviewSalesOrder
