import React from 'react'
import {Controller} from 'react-hook-form'
import {
  KooperDatePicker,
  KooperDropdown,
  KooperForm,
  KooperFormField,
  KooperFormGroup,
  KooperFormInput,
  KooperRadio,
  KooperTooltip,
} from 'kooper-library'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const EmployeePayrollInformation = props => {
  const {SalaryEffectiveDate, setSalaryEffectiveDate, watch, errors, control, active, setActive} = props

  const watchPaymentMethod = watch('payrollInfo.paymentMethod')

  return (
    <KooperForm className="errorLabel">
      <KooperFormField>
        <div className="d-flex">
          <div>
            <label>Set Effective Date for salary</label>
            <p className="kooper-lead m-0">Set Effective Date for salary</p>
          </div>
          <KooperRadio
            checked={active.effectiveDate}
            onClick={() =>
              setActive({
                ...active,
                effectiveDate: !active.effectiveDate,
              })
            }
            toggle
          />
        </div>
      </KooperFormField>

      {active.effectiveDate ? (
        <KooperFormField width={4}>
          <label>Select Date</label>
          <Controller
            name="payrollInfo.salaryEffectiveDate"
            render={({onChange, value}) => (
              <KooperDatePicker
                type="date"
                value={typeof value === 'string' ? new Date(SalaryEffectiveDate) : SalaryEffectiveDate}
                inputProps={{readOnly: true}}
                time={false}
                onChange={value => {
                  onChange(value)
                  setSalaryEffectiveDate(value)
                }}
                error={
                  errors?.payrollInfo?.salaryEffectiveDate && {
                    content: removeDoubleQuotes(errors?.payrollInfo?.salaryEffectiveDate?.message),
                  }
                }
              />
            )}
            control={control}
          />
        </KooperFormField>
      ) : null}

      <KooperFormGroup>
        <KooperFormField width={8}>
          <label>Salary</label>
          <Controller
            name="payrollInfo.salary"
            render={({value, onChange, onBlur}) => (
              <KooperFormInput
                fluid
                type="text"
                name="salary"
                value={value}
                onChange={e => {
                  onChange(e.target.value)
                }}
                onBlur={onBlur}
                error={
                  errors?.payrollInfo?.salary && {
                    content: errors?.payrollInfo?.salary?.message,
                  }
                }
              />
            )}
            control={control}
          />
        </KooperFormField>

        <KooperFormField width={8}>
          <div className="info-header mb-1">
            <label>Period</label>
            <KooperTooltip
              content="Set Salary period i.e monthly, yearly or hourly"
              trigger={<SvgIcon path="common/question" />}
              size="mini"
              position="top center"
            />
          </div>
          <Controller
            name="payrollInfo.salaryPeriod"
            render={({onChange, value}) => (
              <KooperDropdown
                fluid
                selection
                options={[
                  {value: 'perYear', key: 'perYear', text: 'Per Year'},
                  {value: 'perMonth', key: 'perMonth', text: 'Per Month'},
                  {value: 'perHour', key: 'perHour', text: 'Per Hour'},
                ]}
                value={value}
                onChange={(e, {value}) => {
                  onChange(value)
                }}
                error={
                  errors?.payrollInfo?.salaryPeriod && {
                    content: removeDoubleQuotes(errors?.payrollInfo?.salaryPeriod?.message),
                  }
                }
              />
            )}
            control={control}
          />
        </KooperFormField>
      </KooperFormGroup>

      <KooperFormGroup style={{flexWrap: 'wrap', rowGap: 18}}>
        <KooperFormField width={8}>
          <div className="info-header mb-1">
            <label>Payment Method</label>
            <KooperTooltip
              content="Choose payment method of the salary"
              trigger={<SvgIcon path="common/question" />}
              size="mini"
              position="top center"
            />
          </div>
          <Controller
            name="payrollInfo.paymentMethod"
            render={({onChange, value}) => (
              <KooperDropdown
                fluid
                selection
                options={[
                  {value: 'bank', key: 'bank', text: 'Bank'},
                  {value: 'cheque', key: 'cheque', text: 'Cheque'},
                  {value: 'cash', key: 'cash', text: 'Cash'},
                ]}
                value={value}
                onChange={(e, {value}) => {
                  onChange(value)
                }}
                error={
                  errors?.payrollInfo?.paymentMethod && {
                    content: removeDoubleQuotes(errors?.payrollInfo?.paymentMethod?.message),
                  }
                }
              />
            )}
            control={control}
          />
        </KooperFormField>

        {watchPaymentMethod === 'bank' ? (
          <>
            <KooperFormField width={8}>
              <div className="info-header mb-1">
                <label>Bank</label>
                <KooperTooltip
                  content="Write name of the employee’s bank"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="payrollInfo.bankName"
                control={control}
                render={({onChange, value}) => (
                  <KooperFormInput
                    type="text"
                    placeholder="Bank Name"
                    onChange={(e, {value}) => onChange(value)}
                    value={value}
                    error={
                      errors?.payrollInfo?.bankName && {
                        content: removeDoubleQuotes(errors?.payrollInfo?.bankName?.message),
                      }
                    }
                  />
                )}
              />
            </KooperFormField>
            <KooperFormField width={8}>
              <div className="info-header mb-1">
                <label>Branch Code</label>
                <KooperTooltip
                  content="Mention code of the branch"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="payrollInfo.code"
                render={({value, onChange, onBlur}) => (
                  <KooperFormInput
                    fluid
                    type="text"
                    name="code"
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errors?.payrollInfo?.code && {
                        content: errors?.payrollInfo?.code?.message,
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>
            <KooperFormField width={8}>
              <div className="info-header mb-1">
                <label>Bank Branch</label>
                <KooperTooltip
                  content="Write name of the bank branch"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="payrollInfo.bankBranch"
                render={({value, onChange, onBlur}) => (
                  <KooperFormInput
                    fluid
                    type="text"
                    name="bankBranch"
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errors?.payrollInfo?.bankBranch && {
                        content: errors?.payrollInfo?.bankBranch?.message,
                      }
                    }
                  />
                )}
                control={control}
              />
            </KooperFormField>
          </>
        ) : null}

        {watchPaymentMethod === 'cheque' ? (
          <KooperFormField width={8}>
            <div className="info-header mb-1">
              <label>Employee Bank account number</label>
              <KooperTooltip
                content="Type bank account number"
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="payrollInfo.bankAccountNumber"
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  fluid
                  type="text"
                  name="bankAccountNumber"
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  error={
                    errors?.payrollInfo?.bankAccountNumber && {
                      content: errors?.payrollInfo?.bankAccountNumber?.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </KooperFormField>
        ) : null}
      </KooperFormGroup>
    </KooperForm>
  )
}

export default EmployeePayrollInformation
