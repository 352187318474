import React from 'react'
import {Controller} from 'react-hook-form'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
} from 'kooper-library'
import _ from 'lodash'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

function Header({setToggleModal, toggleModal, activeSection, index, control, data, errors}) {
  return (
    <KooperModal
      open={toggleModal === 'header'}
      onClose={() => setToggleModal(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      centered={false}
    >
      <KooperModalHeader >Edit Header</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <div className="info-header">
              <label className="label-class">Header</label>
            </div>
            <Controller
              name={`templateSections[${activeSection}].sectionFields[${index}].label`}
              control={control}
              defaultValue={data?.label}
              render={({value, onChange, onBlur}) => (
                <KooperFormInput
                  placeholder="Enter Text"
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  onBlur={onBlur}
                  // error={
                  //   _.get(errors, `templateSections[${activeSection}].sectionFields[${index}].label`) && {
                  //     content: removeDoubleQuotes(
                  //       _.get(errors, `templateSections[${activeSection}].sectionFields[${index}].label.message`)
                  //     ),
                  //   }
                  // }
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton onClick={() => setToggleModal(false)}>Cancel</KooperButton>
        <KooperButton primary onClick={() => setToggleModal(false)}>
          Ok
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default Header
