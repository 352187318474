import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  KooperButton,
  KooperCard,
  KooperCardContent,
  KooperContainer,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperRadio,
  KooperTabPane,
} from 'kooper-library'
import {sampleMail} from 'actions/meeting'
import {MEETING_SAMPLE_MAIL} from 'actions/types'
import {sendSampleEmail} from 'validation/Sales/meeting.schema'
import SvgIcon from 'components/common/SvgIcon'

const SampleMailModal = ({isModalOpen, setIsModalOpen}) => {
  const dispatch = useDispatch()
  const {isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)

  const {errors, control, handleSubmit} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    defaultValues: {email: ''},
    resolver: joiResolver(sendSampleEmail),
  })

  const SendMailBtn = data => {
    dispatch(sampleMail(data))
  }
  useEffect(() => {
    if (successLabels.includes(MEETING_SAMPLE_MAIL)) {
      setIsModalOpen()
    }
  }, [successLabels, setIsModalOpen])

  return (
    <KooperModal
      open={isModalOpen}
      size="tiny"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={setIsModalOpen}
    >
      <KooperModalHeader>Add Your Email</KooperModalHeader>
      <KooperModalContent>
        <KooperForm className="errorLabel">
          <KooperFormField required>
            <label>Add Email</label>
            <Controller
              name="email"
              control={control}
              render={({onChange, value}) => (
                <KooperFormInput
                  onChange={(e, {value}) => onChange(value)}
                  value={value}
                  fluid
                  error={
                    errors.email && {
                      content: errors.email.message,
                    }
                  }
                />
              )}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={setIsModalOpen}>
          Cancel
        </KooperButton>
        <KooperButton
          primary
          type="submit"
          loading={isLoadingData}
          disabled={isLoadingData}
          onClick={handleSubmit(SendMailBtn)}
        >
          Send Mail
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

const Notification = props => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {control, Controller, style} = props

  return (
    <KooperTabPane style={style}>
      <KooperContainer>
        <KooperCard fluid>
          <KooperCardContent>
            <h3 className="mb-0">Email Notification</h3>
            <p className="mt-0 mb-4 kooper-lead">Manage all settings related to email notification</p>
            <KooperForm className="errorLabel">
              <KooperFormField width={12}>
                <KooperCard fluid className="switchBox">
                  <KooperCardContent>
                    <div className="d-flex">
                      <div>
                        <h5>
                          Send confirmation emails to prospects and customer immediately after they book meeting through
                          this link.
                        </h5>
                        <p>
                          <KooperButton basic onClick={() => setIsModalOpen(true)} className="mr-2">
                            Send Test Email
                          </KooperButton>
                          Test email will be sent.
                        </p>
                      </div>
                      <Controller
                        name="mailNotification"
                        render={({onChange, value}) => (
                          <KooperRadio
                            toggle
                            style={{float: 'right'}}
                            onChange={(e, {checked}) => onChange(checked)}
                            checked={value}
                          />
                        )}
                        control={control}
                      />
                    </div>
                  </KooperCardContent>

                  <KooperCardContent>
                    <div className="d-flex">
                      <div>
                        <h5>
                          Send pre-meeting reminder emails to prospect and customer who book meeting through this link.
                        </h5>
                      </div>
                      <Controller
                        name="mailReminder"
                        render={({onChange, value}) => (
                          <KooperRadio
                            toggle
                            style={{float: 'right'}}
                            onChange={(e, {checked}) => onChange(checked)}
                            checked={value}
                          />
                        )}
                        control={control}
                      />
                    </div>
                  </KooperCardContent>
                </KooperCard>
              </KooperFormField>
            </KooperForm>
          </KooperCardContent>
        </KooperCard>
      </KooperContainer>
      {isModalOpen && <SampleMailModal setIsModalOpen={() => setIsModalOpen(!isModalOpen)} isModalOpen={isModalOpen} />}
    </KooperTabPane>
  )
}

export default Notification
