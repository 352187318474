import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveBar} from '@nivo/bar'
import _ from 'lodash'
import {
  KooperGrid,
  KooperCard,
  KooperGridColumn,
  KooperCardContent,
  KooperCardHeader,
  KooperGridRow,
  KooperTable,
  KooperTableHeader,
  KooperTableRow,
  KooperTableBody,
  KooperTableCell,
  KooperTableHeaderCell,
  KooperTooltip,
} from 'kooper-library'

import {EMAIL_CLICK_VS_TIME, EMAIL_OPEN_VS_TIME} from 'actions/types'
import {emailStats, emailOpenedVsTime, emailClickedVsTime, emailTemplateStats} from 'actions/reports_email'
import Moment from 'moment'
import {maxOfGraphBar, getGridYValuesBar} from 'utils/graph'
import {get, startCase} from 'utils/helper'
import CustomLoader from 'components/common/CustomLoader'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import {AssigneeDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import {DATE_RANGE_KEY} from 'constants/variables'

const EmailAnalytics = () => {
  const dispatch = useDispatch()

  const diff = Moment(get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))).diff(
    Moment(get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))),
    'days'
  )

  const {type = []} = useSelector(state => state.apiReducer)
  const {analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)
  // Data for graphs and content
  const {
    emailStatsData,
    emailOpenedVsTimeData = [],
    emailClickedVsTimeData = [],
    emailTemplateStatsData = [],
  } = useSelector(state => state.reportsEmail)
  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const emailTrackingAccess = analyticsAccess?.emailTracking
  const conversationsReportsPermissions = reportsPermissions?.conversations_reports

  // Actions to dispatch in Date function
  const actionsToDispatch = useCallback(
    data => {
      dispatch(emailStats(_.omit(data, 'graphType')))
      dispatch(emailOpenedVsTime(_.omit(data, 'graphType')))
      dispatch(emailClickedVsTime(_.omit(data, 'graphType')))
      dispatch(emailTemplateStats(_.omit(data, 'graphType')))
    },
    [dispatch]
  )
  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/email-tracking" />
            <h5>Email Tracking</h5>
          </div>
        </div>
        {emailTrackingAccess && conversationsReportsPermissions && (
          <div className="page-action">
            <AssigneeDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              userId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
              teamId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'team'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
            />
          </div>
        )}
      </div>
      <KooperGrid columns="equal">
        <KooperGridColumn width={16}>
          <KooperCard fluid>
            <KooperCardContent>
              <KooperGrid columns={4}>
                <KooperGridRow>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <KooperCardHeader>Total</KooperCardHeader>
                      <h3>
                        {get(['value'], emailStatsData?.totalEmails, '0')}
                        <KooperTooltip
                          content="Total"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.totalEmails, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.totalEmails, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.totalEmails, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </KooperGridColumn>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <KooperCardHeader>Open</KooperCardHeader>
                      <h3>
                        {get(['value'], emailStatsData?.openedEmails, '0')}
                        <KooperTooltip
                          content="Open"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.openedEmails, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.openedEmails, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.openedEmails, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </KooperGridColumn>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <KooperCardHeader>Clicked</KooperCardHeader>
                      <h3>
                        {get(['value'], emailStatsData?.clickedEmails, '0')}
                        <KooperTooltip
                          content="Clicked"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.clickedEmails, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.clickedEmails, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.clickedEmails, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </KooperGridColumn>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <KooperCardHeader>Replied</KooperCardHeader>
                      <h3>
                        {get(['value'], emailStatsData?.repliedEmails, '0')}
                        <KooperTooltip
                          content="Replied"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.repliedEmails, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.repliedEmails, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.repliedEmails, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </KooperGridColumn>
                </KooperGridRow>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>
          <KooperCard fluid>
            <KooperCardContent>
              <KooperGrid columns={3}>
                <KooperGridRow>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <div className="info-header">
                        <KooperCardHeader>Open Ratio</KooperCardHeader>
                        <KooperTooltip
                          content="Ratio of opened email to total emails sent"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="right center"
                        />
                      </div>
                      <h3>
                        {get(['openRatio', 'value'], emailStatsData, '0')}
                        <KooperTooltip
                          content="Open Ratio"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.openRatio, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.openRatio, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.openRatio, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </KooperGridColumn>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <div className="info-header">
                        <KooperCardHeader>Click Ratio</KooperCardHeader>
                        <KooperTooltip
                          content="Ratio of clicks on emails to total emails sent"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="right center"
                        />
                      </div>
                      <h3>
                        {get(['value'], emailStatsData?.clickRatio, '0')}
                        <KooperTooltip
                          content="Click Ratio"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.clickRatio, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.clickRatio, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.clickRatio, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </KooperGridColumn>
                  <KooperGridColumn>
                    <div className="valueBox">
                      <div className="info-header">
                        <KooperCardHeader>Response Ratio</KooperCardHeader>
                        <KooperTooltip
                          content="Ratio of response on emails to total emails sent"
                          trigger={<SvgIcon path="common/question" />}
                          size="mini"
                          position="right center"
                        />
                      </div>
                      <h3>
                        {get(['value'], emailStatsData?.responseRatio, '0')}
                        <KooperTooltip
                          content="Response Ratio"
                          size="mini"
                          position="top center"
                          trigger={
                            <span
                              className={
                                get(['growth'], emailStatsData?.responseRatio, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['growth'], emailStatsData?.responseRatio, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['percentage'], emailStatsData?.responseRatio, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </KooperGridColumn>
                </KooperGridRow>
              </KooperGrid>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent>
              <div className="info-header">
                <h5 className="m-0">Time-based Chart of Open</h5>
                <KooperTooltip
                  content="Graphical representation of email opened over a time frame"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(EMAIL_OPEN_VS_TIME) && <CustomLoader />}
              {!type.includes(EMAIL_OPEN_VS_TIME) && (
                <ResponsiveBar
                  data={emailOpenedVsTimeData}
                  // keys={"opened"}
                  indexBy="time"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(emailOpenedVsTimeData, 'value')}
                  gridYValues={getGridYValuesBar(emailOpenedVsTimeData, 'value')}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 40,
                    format: tick => {
                      if (diff > 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(emailOpenedVsTimeData, 'value'),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent>
              <div className="info-header">
                <h5 className="m-0">Time-based Chart of Click</h5>
                <KooperTooltip
                  content="Graphical representation of email clicked over a time frame"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
            </KooperCardContent>
            <KooperCardContent className="pt-0" style={{height: 330}}>
              {type.includes(EMAIL_CLICK_VS_TIME) && <CustomLoader />}
              {!type.includes(EMAIL_CLICK_VS_TIME) && (
                <ResponsiveBar
                  data={emailClickedVsTimeData}
                  // keys={"Clicked"}
                  indexBy="time"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(emailClickedVsTimeData, 'value')}
                  gridYValues={getGridYValuesBar(emailClickedVsTimeData, 'value')}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 40,
                    format: tick => {
                      if (diff > 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(emailClickedVsTimeData, 'value'),
                    legend: 'Clicked',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
      <KooperGrid>
        <KooperGridColumn width={16}>
          <KooperCard fluid className="chartBox">
            <KooperCardContent>
              <div className="info-header">
                <h5 className="m-0">Email Template Stats</h5>
                <KooperTooltip
                  content="List of email template based on their usage over a time frame"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
            </KooperCardContent>
            <KooperCardContent className="pt-0">
              <KooperTable responsive basic>
                <KooperTableHeader>
                  <KooperTableRow>
                    <KooperTableHeaderCell>Name</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Total</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Open</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Clicked</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Replied</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Open Ratio</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Click Ratio</KooperTableHeaderCell>
                    <KooperTableHeaderCell>Response Ratio</KooperTableHeaderCell>
                  </KooperTableRow>
                </KooperTableHeader>
                <KooperTableBody>
                  {emailTemplateStatsData && emailTemplateStatsData.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="emptyValue">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    <>
                      {emailTemplateStatsData?.map((data, index) => (
                        <KooperTableRow key={index}>
                          <KooperTableCell>{startCase(data.name)}</KooperTableCell>
                          <KooperTableCell>{data.totalEmails}</KooperTableCell>
                          <KooperTableCell>{data.openedEmails}</KooperTableCell>
                          <KooperTableCell>{data.clickedEmails}</KooperTableCell>
                          <KooperTableCell>{data.repliedEmails}</KooperTableCell>
                          <KooperTableCell>{data.openRatio}</KooperTableCell>
                          <KooperTableCell>{data.clickRatio}</KooperTableCell>
                          <KooperTableCell>{data.responseRaatio}</KooperTableCell>
                        </KooperTableRow>
                      ))}
                    </>
                  )}
                </KooperTableBody>
              </KooperTable>
            </KooperCardContent>
          </KooperCard>
        </KooperGridColumn>
      </KooperGrid>
    </div>
  )
}
export default EmailAnalytics
