/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {FiChevronRight} from 'react-icons/fi'
import {
  KooperButton,
  KooperDropdown,
  KooperDropdownItem,
  KooperDropdownMenu,
  KooperMenuItem,
  KooperAccordion,
  KooperAccordionContent,
  KooperAccordionTitle,
  KooperTooltip,
} from 'kooper-library'

import {ADD_SEGMENT, DELETE_SEGMENT, GET_SEGMENT, UPDATE_SEGMENT} from 'actions/types'
import {addSegment, deleteSegment, getSegment, getSegmentList, updateSegment} from 'actions/segment'
import DeleteModal from 'components/common/DeleteModal'
import {getContactList} from 'actions/contacts'
import {getCompanyList} from 'actions/company'
import {
  CompaniesSegmentStep2,
  ContactSegmentStep1,
  ContactSegmentStep2,
  LeadSegmentStep2,
} from 'validation/Customers/segment.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {getPermissionTooltipContent} from 'utils/helper'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import {MdOutlineMoreVert} from 'react-icons/md'
import SvgIcon from 'components/common/SvgIcon'
import SegmentStep2Companies from './Companies/SegmentStep2Companies'
import SegmentStep1 from './SegmentStep1'
import SegmentStep2 from './Contacts/SegmentStep2'

const AddSegment = props => {
  const {type} = props

  const history = useHistory()
  const dispatch = useDispatch()

  const [deleteId, setDeleteId] = useState('')
  const [activeLists, setActiveLists] = useState(true)
  const [deleteModal, setDeleteModal] = useState(false)
  const [currentStep, setCurrentStep] = useState(false)
  const [editId, setEditId] = useState('')
  const [filter, setFilter] = useState({})

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {segmentList = {}, segmentData = {}} = useSelector(state => state.segment)

  const {salesAndCrmAccess, contactsAndCompaniesPermissions} = useUserPermissions()
  const listAndSegemntsAccess = salesAndCrmAccess?.listAndSegemnts
  const createListsPermissions = contactsAndCompaniesPermissions?.create_lists

  const handleSchema = type => {
    if (type === 'contacts') {
      return ContactSegmentStep2
    }
    if (type === 'leads') {
      return LeadSegmentStep2
    }
    if (type === 'companies') {
      return CompaniesSegmentStep2
    }
  }

  const defaultValues = {
    name: '',
    isStatic: true,
  }
  const defaultValues1 = {
    ids: [],
    conditions: {},
  }

  const {handleSubmit, errors, control, getValues, reset, watch} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(ContactSegmentStep1),
    shouldUnregister: false,
    defaultValues,
  })
  const {
    handleSubmit: handleSubmit2,
    errors: errors2,
    control: control2,
    getValues: getValues2,
    reset: reset2,
    watch: watch2,
    setValue: setValue2,
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(handleSchema(type)),
    shouldUnregister: false,
    defaultValues: defaultValues1,
  })

  const CreateSegment = () => {
    const payload = {
      ...getValues(),
      ...getValues2(),
    }
    if (editId) {
      dispatch(updateSegment(type, payload, editId))
    } else {
      dispatch(addSegment(type, payload))
    }
  }

  useEffect(() => {
    if (
      successLabels.includes(ADD_SEGMENT) ||
      successLabels.includes(DELETE_SEGMENT) ||
      successLabels.includes(UPDATE_SEGMENT)
    ) {
      dispatch(getSegmentList(type))

      setCurrentStep(false)
      reset(defaultValues)
      reset2(defaultValues1)
      setDeleteId('')
      setEditId('')
    }
    if (successLabels.includes(GET_SEGMENT)) {
      reset({name: segmentData.name, isStatic: segmentData.isStatic})
      reset2({conditions: segmentData.conditions, ids: segmentData.ids})
      setFilter(segmentData.conditions)
    }
  }, [successLabels])

  useEffect(() => {
    if (listAndSegemntsAccess) {
      dispatch(getSegmentList(type))
    }
  }, [listAndSegemntsAccess, dispatch, type])

  useEffect(() => {
    if (currentStep === false) {
      dispatch({type: 'CLEAR_SEGMENT_DATA'})
      setDeleteId('')
      reset(defaultValues)
      reset2(defaultValues1)
    }
  }, [currentStep])

  return (
    <>
      <KooperAccordion>
        <KooperAccordionTitle onClick={() => setActiveLists(!activeLists)}>
          <div className="d-flex">
            {activeLists ? <SvgIcon path="common/down" /> : <FiChevronRight />}
            Lists
          </div>
          <div className="d-flex">
            {activeLists && (
              <KooperTooltip
                trigger={
                  <KooperButton
                    className="kooper-seg"
                    onClick={e => {
                      e.stopPropagation()
                      if (listAndSegemntsAccess && createListsPermissions) {
                        setCurrentStep('step1')
                      }
                    }}
                  >
                    <SvgIcon path="common/plus" />
                  </KooperButton>
                }
                content={
                  listAndSegemntsAccess && createListsPermissions
                    ? 'Add'
                    : getPermissionTooltipContent({
                        featureAccess: listAndSegemntsAccess,
                        roleAccess: createListsPermissions,
                      })
                }
                size="mini"
                position="top center"
              />
            )}
          </div>
        </KooperAccordionTitle>
        <KooperAccordionContent active={activeLists}>
          {(segmentList[type] || [])?.length > 0 ? (
            (segmentList[type] || []).map(segment => (
              <KooperMenuItem
                className="kooper-seg-item"
                active={window.location.pathname === `/${type}/segment/${segment.id}`}
                onClick={() => {
                  history.push(`/${type}/segment/${segment.id}`)
                  // eslint-disable-next-line no-unused-expressions
                  type === 'contacts'
                    ? dispatch(getContactList({segmentId: segment.id}))
                    : dispatch(getCompanyList({segmentId: segment.id}))
                }}
              >
                <div className="d-flex-js">
                  {/* <SvgIcon path="clients/lists"/> */}
                  {segment.name}
                </div>
                <KooperDropdown className="project-discussion-sidemenu-dropdown" icon={<MdOutlineMoreVert />}>
                  <KooperDropdownMenu>
                    <LockPermissionTooltip isRoleAccessDenied={!createListsPermissions}>
                      <KooperDropdownItem
                        onClick={() => {
                          if (createListsPermissions) {
                            setCurrentStep('step1')
                            setEditId(segment.id)
                            dispatch(getSegment(type, segment.id))
                          }
                        }}
                      >
                        <SvgIcon path="common/edit2" /> Edit
                      </KooperDropdownItem>
                    </LockPermissionTooltip>
                    <LockPermissionTooltip isRoleAccessDenied={!createListsPermissions}>
                      <KooperDropdownItem
                        onClick={() => {
                          if (createListsPermissions) {
                            setDeleteModal(true)
                            setDeleteId(segment.id)
                          }
                        }}
                      >
                        <SvgIcon path="common/delete" /> Delete
                      </KooperDropdownItem>
                    </LockPermissionTooltip>
                  </KooperDropdownMenu>
                </KooperDropdown>
              </KooperMenuItem>
            ))
          ) : (
            <KooperMenuItem>
              <td colSpan="6" className="emptyValue">
                No Data Available
              </td>
            </KooperMenuItem>
          )}
        </KooperAccordionContent>
      </KooperAccordion>

      {!(currentStep === false) && (
        <>
          <SegmentStep1
            open={currentStep === 'step1'}
            setOpen={setCurrentStep}
            control={control}
            handleSubmit={handleSubmit}
            errors={errors}
            watch={watch}
            type={type}
          />
          {currentStep === 'contacts' && (
            <SegmentStep2
              open={currentStep === 'contacts'}
              setOpen={setCurrentStep}
              control={control2}
              handleSubmit={handleSubmit2}
              errors={errors2}
              CreateSegment={CreateSegment}
              getValues={getValues2}
              watch={watch2}
              step1Values={watch}
              setValue={setValue2}
              reset={reset2}
              filter={filter}
              setFilter={setFilter}
              editId={editId}
              type={type}
            />
          )}
          {currentStep === 'companies' && (
            <SegmentStep2Companies
              open={currentStep === 'companies'}
              setOpen={setCurrentStep}
              control={control2}
              handleSubmit={handleSubmit2}
              errors={errors2}
              CreateSegment={CreateSegment}
              getValues={getValues2}
              watch={watch2}
              step1Values={watch}
              setValue={setValue2}
              reset={reset2}
              filter={filter}
              setFilter={setFilter}
              editId={editId}
              type={type}
            />
          )}
        </>
      )}
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          onDelete={() => {
            dispatch(deleteSegment(type, deleteId))
            setDeleteModal(false)
          }}
          type={type}
        />
      )}
    </>
  )
}

export default AddSegment
