/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import React, {useState, useEffect, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {KooperButton, KooperTab} from 'kooper-library'
import {createSalesOrders} from 'actions/salesOrders'
import {CREATE_SALES_ORDERS} from 'actions/types'
import {createSalesOrdersSchema, stepsProductsSchema, templateSchema} from 'validation/Finance/salesOrders.schema'
import {SALES_ORDERS_PATH} from 'constants/path'
import Setup from './Setup'
import Product from './Product'
import Customization from './Customization'
import PreviewSalesOrder from '../Templates/PreviewSalesOrder'
import SalesOrderModal from './SalesOrderModal'

const steps = [{name: 'Setup'}, {name: 'Product'}, {name: 'Customization'}, {name: 'Preview'}]

function Index(props) {
  const {setWarningModal} = props
  const history = useHistory()
  const {goBack} = useHistory()
  const dispatch = useDispatch()

  const [index, setIndex] = useState(0)
  const [disabledIndex, setDisabledIndex] = useState(0)
  const [salesOrdersCreate, setSalesOrdersCreate] = useState(0)
  const [dueDate, setDueDate] = useState(null)
  const [discountValue, setDiscountValue] = useState(0)
  const [taxValue, setTaxValue] = useState(0)
  const [adjustmentValue, setAdjustmentValue] = useState(0)
  const [searchValue, setSearchValue] = useState([])
  const [addressTemplate, setAddressTemplate] = useState()
  const [showSoModal, setShowSoModal] = useState(false)

  const {isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {soPublicLink} = useSelector(state => state.salesOrders)
  const {selectedFile} = useSelector(state => state.salesOrders.uploadFile)

  const initialCreateInfo1 = {
    assigneeId: null,
    soNumber: '',
    prefixId: '',
    subject: '',
    customerNumber: '',
    purchaseOrderNumber: '',
    quoteId: '',
    contactId: null,
    pending: null,
    dueDate: null,
    exciseDuty: '',
    salesCommission: '',
    carrier: '',
    status: '',
    description: '',
    billingAddress: {},
    shippingAddress: {},
  }

  const {
    handleSubmit: handleSubmit1,
    control: control1,
    errors: errors1,
    setValue: setValue1,
    watch: watch1,
    clearErrors: clearErrors1,
    formState: {isDirty: isDirty1},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    shouldUnregister: false,
    resolver: joiResolver(createSalesOrdersSchema),
    defaultValues: initialCreateInfo1,
  })

  const {
    handleSubmit: handleSubmit2,
    control: control2,
    errors: errors2,
    setValue: setValue2,
    watch: watch2,
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange',
    resolver: joiResolver(stepsProductsSchema),
    defaultValues: {
      products: [],
      subTotal: 0,
      discount: 0,
      tax: 0,
      adjustment: 0,
      total: 0,
      terms: '',
    },
  })

  const {
    handleSubmit: handleSubmit3,
    control: control3,
    errors: errors3,
    setValue: setValue3,
    watch: watch3,
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange',
    resolver: joiResolver(templateSchema),
    defaultValues: {
      template: {templateNo: 2, color: '#1e90ff', header: '', footer: '', addressId: ''},
    },
  })

  useEffect(() => {
    return () => {
      dispatch({type: 'CLEAR_SO_FORM'})
      dispatch({type: 'CLEAR_SO_DETAILS'})
      dispatch({type: 'CLEAR_SO_UPLOADFILE'})
    }
  }, [dispatch])

  const handleUnSaved = useCallback(
    _isDirty1 => {
      if (_isDirty1 || index > 0) setWarningModal(true)
    },
    [setWarningModal, index]
  )

  useEffect(() => {
    handleUnSaved(isDirty1)
    return () => {
      setWarningModal(false)
    }
  }, [handleUnSaved, isDirty1, setWarningModal])

  useEffect(() => {
    if (successLabels.includes(CREATE_SALES_ORDERS)) {
      // history.push('/${SALES_ORDERS_PATH.PREFIX}')
      setShowSoModal(true)
      setWarningModal(false)
    }
  }, [dispatch, history, setWarningModal, successLabels])

  const submitData = data => {
    const formData = new FormData()

    if (selectedFile && typeof selectedFile !== 'string') {
      formData.append('logo', selectedFile)
    }

    if (index === 0) {
      setIndex(index + 1)
      setDisabledIndex(disabledIndex + 1)
      setSalesOrdersCreate({...salesOrdersCreate, ...data})
      dispatch({type: 'SETUP_SUBMIT', payload: data})
    } else if (index === 1) {
      setIndex(index + 1)
      setSalesOrdersCreate({...salesOrdersCreate, ...data})
      setDisabledIndex(disabledIndex + 1)
      dispatch({type: 'PRODUCT_HANDLE_SUBMIT', payload: data})
    } else if (index === 2) {
      setIndex(index + 1)
      setSalesOrdersCreate({...salesOrdersCreate, ...data})
      setDisabledIndex(disabledIndex + 1)
      dispatch({type: 'CUSTOMIZATION_SUBMIT', payload: data})
    } else if (index === 3) {
      const payload = {...salesOrdersCreate}

      const newList = _.omitBy(payload, value => !!(value === '' || value === null || _.isEqual(value, [])))

      for (const key in newList) {
        if (key === 'products') {
          formData.append(key, JSON.stringify(newList[key]))
        } else if (key === 'shippingAddress') {
          formData.append(key, JSON.stringify(newList[key]))
        } else if (key === 'billingAddress') {
          formData.append(key, JSON.stringify(newList[key]))
        } else if (key === 'template') {
          formData.append(key, JSON.stringify(newList[key]))
        } else {
          formData.append(key, newList[key])
        }
      }
      dispatch(createSalesOrders(formData))
    }
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar tabCustomize">
        <div>
          <KooperButton basic inverted onClick={() => history.push(`/${SALES_ORDERS_PATH.PREFIX}`)}>
            Exit
          </KooperButton>
          {index > 0 && (
            <KooperButton basic inverted onClick={() => setIndex(index - 1)} className="ml-2">
              Back
            </KooperButton>
          )}
        </div>
        <KooperTab
          key={index}
          panes={steps.map((item, i) => ({
            menuItem: {
              key: item.name,
              icon: item.icon,
              content: item.name,
              disabled: !(disabledIndex >= i && _.isEmpty(errors1) && _.isEmpty(errors2)),
            },
            render: null,
          }))}
          menu={{
            secondary: true,
            pointing: true,
            color: 'blue',
          }}
          activeIndex={index}
          onTabChange={(e, {activeIndex}) => {
            setIndex(activeIndex)
          }}
          renderActiveOnly={false}
        />
        <KooperButton
          primary
          loading={isLoadingData}
          disabled={isLoadingData}
          onClick={
            index === 0
              ? handleSubmit1(submitData)
              : index === 1
              ? handleSubmit2(submitData)
              : index === 2
              ? handleSubmit3(submitData)
              : submitData
          }
        >
          {index === steps.length - 1 ? 'Create' : 'Next'}
        </KooperButton>
      </div>
      <Setup
        style={{display: index === 0 ? '' : 'none'}}
        control={control1}
        errors={errors1}
        clearErrors={clearErrors1}
        watch={watch1}
        setValue={setValue1}
        dueDate={dueDate}
        setDueDate={setDueDate}
      />

      <Product
        style={{display: index === 1 ? '' : 'none'}}
        control={control2}
        errors={errors2}
        watch={watch2}
        setValue={setValue2}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        discountValue={discountValue}
        setDiscountValue={setDiscountValue}
        taxValue={taxValue}
        setTaxValue={setTaxValue}
        adjustmentValue={adjustmentValue}
        setAdjustmentValue={setAdjustmentValue}
      />

      <Customization
        style={{display: index === 2 ? '' : 'none'}}
        control={control3}
        errors={errors3}
        watch={watch3}
        setValue={setValue3}
        addressTemplate={addressTemplate}
        setAddressTemplate={setAddressTemplate}
      />

      <PreviewSalesOrder style={{display: index === 3 ? '' : 'none'}} />

      {showSoModal && (
        <SalesOrderModal
          link={soPublicLink}
          open={showSoModal}
          toggle={() => {
            setShowSoModal(false)
            goBack()
          }}
        />
      )}
    </div>
  )
}

export default Index
