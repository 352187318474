import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import CreateOrganizationalGoals from 'components/pages/Teams/Goals/CreateGoals/CreateOrganizationalGoals'
import CreatePersonalGoals from 'components/pages/Teams/Goals/CreateGoals/CreatePersonalGoals'
import CreateTeamGoals from 'components/pages/Teams/Goals/CreateGoals/CreateTeamGoals'
import {KooperButton, KooperContainer, KooperForm} from 'kooper-library'
import {useHistory, useParams} from 'react-router-dom'

export default function CreateGoals() {
  const history = useHistory()
  const {type = 'team'} = useParams()
  const isDraft = true
  return (
    <div className="kooper-full-page">
      <div className="page-header p-0">
        <div className="campaignNavbar">
          <div>
            <KooperButton primary onClick={() => history.goBack()}>
              Exit
            </KooperButton>
          </div>
          <h3 className="m-0">New {type} goal</h3>
          <div>
            {isDraft && (
              <LockPermissionTooltip>
                <KooperButton primary className="mr-3">
                  Save as draft
                </KooperButton>
              </LockPermissionTooltip>
            )}
            <LockPermissionTooltip>
              <KooperButton primary className="disabled-button">
                Publish
              </KooperButton>
            </LockPermissionTooltip>
          </div>
        </div>
      </div>

      <KooperContainer>
        <KooperForm className="errorLabel">
          {type === 'organizational' && <CreateOrganizationalGoals />}
          {type === 'team' && <CreateTeamGoals />}
          {type === 'personal' && <CreatePersonalGoals />}
        </KooperForm>
      </KooperContainer>
    </div>
  )
}
