/* eslint-disable no-restricted-syntax */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {useHistory, useParams} from 'react-router'
import _ from 'lodash'
import {KooperButton, KooperTab} from 'kooper-library'
import {EDIT_MEETING, GET_SINGLE_MEETING, GOOGLE_CALENDAR_ACCESS} from 'actions/types'
import {editMeeting, getSingleMeetings} from 'actions/meeting'

import {startCase} from 'utils/helper'
import {createMeetingSchema} from 'validation/Sales/meeting.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import {getUsers} from 'actions/settings'
import {MEETINGS_PATH} from 'constants/path'
import Availabilty from './components/Availabilty'
import Configuration from './components/Configuration'
import Details from './components/Details'
import FormQuestion from './components/FormQuestion'
import Notification from './components/Notification'

const MeetingUpdate = () => {
  const {meetingId} = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const [profilePicture, setProfilePicture] = useState('null')
  const [uploadProfile, setUploadProfile] = useState(null)
  const [userAccountImage, setUserAccountImage] = useState(false)

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {getSingleMeeting} = useSelector(state => state.meetings)

  const {meetingsPermissions} = useUserPermissions()
  const manageMeetingsPermissions = meetingsPermissions?.manage_meetings

  useEffect(() => {
    dispatch(getUsers())
    if (meetingId) {
      dispatch(getSingleMeetings(meetingId))
    }
  }, [dispatch, meetingId])

  const MeetingDurationConvert = val => {
    if (val) {
      val = val?.map(duration => {
        return {
          time: duration,
        }
      })
      return val
    }
  }

  const convertArrayToDay = val => {
    if (_.isEqual(val.sort(), [0, 1, 2, 3, 4, 5, 6].sort())) return 'everyday'
    if (_.isEqual(val.sort(), [1, 2, 3, 4, 5].sort())) return 'mon-fri'
    if (_.isEqual(val.sort(), [0, 6].sort())) return 'sat-sun'
    return val
  }

  const convertMinToISTAvailable = val => {
    val = val?.map(list => {
      const hoursFrom = Math.floor(list.from / 60)
      const realminFrom = list.from % 60
      const hoursTo = Math.floor(list.to / 60)
      const realminTo = list.to % 60
      return {
        from: new Date(`Wed Dec 16 2020
          ${`0${hoursFrom}`.slice(-2)}:${`0${realminFrom}`.slice(-2)}`),
        to: new Date(`Wed Dec 16 2020 ${`0${hoursTo}`.slice(-2)}:${`0${realminTo}`.slice(-2)}`),
        day: convertArrayToDay(list.day),
      }
    })
    return val
  }

  const initialData = {
    meetingAvatar: _.get(getSingleMeeting, 'meetingAvatar', null),
    meetingHeading: _.get(getSingleMeeting, 'meetingHeading', ''),
    // url: _.get(getSingleMeeting, "url", ""),
    assigneeId: _.get(getSingleMeeting, 'assigneeId', ''),
    ownerDetail: _.get(getSingleMeeting, 'ownerDetail', true),
    ownerDesignation: _.get(getSingleMeeting, 'ownerDesignation', ''),
    ownerPhoneNo: _.get(getSingleMeeting, 'ownerPhoneNo', null),
    ownerSocialMedia: _.get(getSingleMeeting, 'ownerSocialMedia', []) || [],
    displayMessage: _.get(getSingleMeeting, 'displayMessage', ''),
    ownerEmail: _.get(getSingleMeeting, 'ownerEmail', ''),
    meetingDuration: MeetingDurationConvert(_.get(getSingleMeeting, 'meetingDuration')),
    meetingLocation: _.get(getSingleMeeting, 'meetingconfiguration.meetingLocation', ''),
    inviteSubject: _.get(getSingleMeeting, 'meetingconfiguration.inviteSubject', ''),
    inviteDescription: _.get(getSingleMeeting, 'meetingconfiguration.inviteDescription', ''),
    meetingLanguage: _.get(getSingleMeeting, 'meetingconfiguration.meetingLanguage', ''),
    meetingDateAndTimeFormat: _.get(getSingleMeeting, 'meetingconfiguration.meetingDateAndTimeFormat', ''),
    meetingTimeZone: _.get(getSingleMeeting, 'meetingconfiguration.meetingTimeZone') || '',

    meetingAvailableTimes: convertMinToISTAvailable(
      _.get(getSingleMeeting, 'meetingconfiguration.meetingAvailableTimes', [])
    ),
    meetingScheduled: _.get(getSingleMeeting, 'meetingconfiguration.meetingScheduled', true),
    meetingScheduleValue: _.get(getSingleMeeting, 'meetingconfiguration.meetingScheduleValue', 0),
    meetingNoticeTime: _.get(getSingleMeeting, 'meetingconfiguration.meetingNoticeTime', 0),
    meetingBufferTime: _.get(getSingleMeeting, 'meetingconfiguration.meetingBufferTime', 0),
    meetingTimeFrequency: _.get(getSingleMeeting, 'meetingconfiguration.meetingTimeFrequency', 0),
    mailNotification: _.get(getSingleMeeting, 'mailNotification', false),
    mailReminder: _.get(getSingleMeeting, 'mailReminder', false),
    videoConferenceLink: _.get(getSingleMeeting, 'meetingconfiguration.videoConferenceLink', ''),
    meetingFormFields: _.get(getSingleMeeting, 'meetingFormFields', [
      {field: 'FirstName', require: true, vaildation: 'text'},
      {field: 'LastName', require: true, vaildation: 'text'},
      {field: 'Email', require: true, vaildation: 'email'},
      {field: 'Phone No', require: false, vaildation: 'number'},
    ]),
    formFieldWithKnownValue: _.get(getSingleMeeting, 'formFieldWithKnownValue', false),
    submitFormIfAllKnownValue: _.get(getSingleMeeting, 'submitFormIfAllKnownValue', false),
    termsAndCondition: _.get(getSingleMeeting, 'termsAndCondition', false),
    privacyCheck: _.get(
      getSingleMeeting,
      'meetingprivacypolicy.privacyCheck',
      'terms and conditions input checkbox content'
    ),
    privacyText: _.get(getSingleMeeting, 'meetingprivacypolicy.privacyText', ''),
    privacyTitle: _.get(getSingleMeeting, 'meetingprivacypolicy.privacyTitle', 'Title'),
    // type: 1,
  }

  const {
    control,
    errors,
    reset,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: {dirtyFields, isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    defaultValues: initialData,
    resolver: joiResolver(createMeetingSchema),
  })

  const {
    fields: durationFields,
    append: appendDuration,
    remove: removeDuration,
  } = useFieldArray({
    control,
    name: 'meetingDuration',
  })
  const {
    fields: meetingAvalTime,
    append: appendMeetingAvailableTimes,
    remove: removeMeetingAvailableTimes,
  } = useFieldArray({
    control,
    name: 'meetingAvailableTimes',
  })
  const {
    fields: socialFields,
    append: appendSocial,
    remove: removeSocial,
  } = useFieldArray({
    control,
    name: 'ownerSocialMedia',
  })

  const {
    fields: FormFields,
    append: appendMeetingForm,
    remove: removeMeetingForm,
  } = useFieldArray({
    control,
    name: 'meetingFormFields',
  })

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_MEETING)) {
      reset(initialData)
      setProfilePicture(_.get(getSingleMeeting, 'meetingAvatar', null))
      setUserAccountImage(true)
    }
    if (successLabels.includes(EDIT_MEETING)) {
      reset(initialData)
      setUploadProfile(null)
      setProfilePicture(profilePicture)
    }
    if (successLabels.includes(GOOGLE_CALENDAR_ACCESS) || successLabels.includes(EDIT_MEETING)) {
      history.push(`/${MEETINGS_PATH.PREFIX}`)
    }
  }, [successLabels])

  const dayConvertIntoArray = val => {
    if (val === 'everyday') return [0, 1, 2, 3, 4, 5, 6]
    if (val === 'mon-fri') return [1, 2, 3, 4, 5]
    if (val === 'sat-sun') return [6, 0]
    return val
  }

  const updateMeeting = data => {
    const newData = _.pick(data, Object.keys(dirtyFields))
    if (newData.meetingDuration || data.meetingDuration) {
      const duration = (newData.meetingDuration || data.meetingDuration)?.map(list => {
        return list.time
      })
      newData.meetingDuration = duration
    }
    if (data.ownerSocialMedia && data.ownerSocialMedia.url === '') {
      delete data.ownerSocialMedia
    }
    if (newData.meetingAvailableTimes || data.meetingAvailableTimes) {
      const avaiableTime = (newData.meetingAvailableTimes || data.meetingAvailableTimes)?.map(({from, to, day}) => {
        const avaialbleFROM = new Date(from)
        const avaialbleTO = new Date(to)
        return {
          from: avaialbleFROM.getHours() * 60 + avaialbleFROM.getMinutes(),
          to: avaialbleTO.getHours() * 60 + avaialbleTO.getMinutes(),
          day: dayConvertIntoArray(day),
        }
      })
      newData.meetingAvailableTimes = avaiableTime
    }
    if (data.ownerSocialMedia) {
      const getNewData = _.filter(_.get(data, 'ownerSocialMedia'), item => item.url !== '' && item.url !== undefined)
      data.ownerSocialMedia = getNewData
    }
    const formData = new FormData()
    delete newData.mailNotification
    delete newData.mailReminder
    delete newData.formFieldWithKnownValue
    delete newData.submitFormIfAllKnownValue
    delete newData.termsAndCondition
    delete newData.meetingTimeZone

    for (const property in newData) {
      if (typeof newData[property] === 'string') {
        formData.append(property, newData[property])
      } else {
        formData.append(property, JSON.stringify(newData[property]))
      }
    }
    formData.append('mailNotification', data.mailNotification)
    formData.append('mailReminder', data.mailReminder)
    formData.append('formFieldWithKnownValue', data.formFieldWithKnownValue)
    formData.append('submitFormIfAllKnownValue', data.submitFormIfAllKnownValue)
    formData.append('meetingTimeZone', data.meetingTimeZone)
    formData.append('termsAndCondition', data.termsAndCondition)
    if (uploadProfile) {
      formData.append('meetingAvatar', uploadProfile)
    }
    dispatch(editMeeting(meetingId, formData))
  }

  const meeting = [
    {
      menuItem: {key: 'details', icon: 'list', content: 'Details'},
      render: () => (
        <Details
          errors={errors}
          control={control}
          watch={watch}
          setValue={setValue}
          meetingId={meetingId}
          Controller={Controller}
          getValues={getValues}
          profilePicture={profilePicture}
          setProfilePicture={setProfilePicture}
          uploadProfile={uploadProfile}
          setUserAccountImage={setUserAccountImage}
          setUploadProfile={setUploadProfile}
          userAccountImage={userAccountImage}
          removeDuration={removeDuration}
          appendDuration={appendDuration}
          durationFields={durationFields}
          socialFields={socialFields}
          removeSocial={removeSocial}
          appendSocial={appendSocial}
          socialFieldsName="ownerSocialMedia"
        />
      ),
    },
    {
      menuItem: {
        key: 'configuration',
        icon: 'setting',
        content: 'Configuration',
      },
      render: () => (
        <Configuration
          errors={errors}
          control={control}
          Controller={Controller}
          getValues={getValues}
          setValue={setValue}
        />
      ),
    },
    {
      menuItem: {
        key: 'availability',
        icon: 'clock',
        content: 'Availability',
      },
      render: () => (
        <Availabilty
          errors={errors}
          control={control}
          Controller={Controller}
          getValues={getValues}
          watch={watch}
          removeMeetingAvailableTimes={removeMeetingAvailableTimes}
          appendMeetingAvailableTimes={appendMeetingAvailableTimes}
          meetingAvalTime={meetingAvalTime}
        />
      ),
    },
    {
      menuItem: {
        key: 'formQuestion',
        icon: 'question circle',
        content: 'Form Question',
      },
      render: () => (
        <FormQuestion
          errors={errors}
          control={control}
          Controller={Controller}
          watch={watch}
          setValue={setValue}
          formFields={FormFields}
          removeMeetingForm={removeMeetingForm}
          appendMeetingForm={appendMeetingForm}
        />
      ),
    },
    {
      menuItem: {
        key: 'notification',
        icon: 'bell',
        content: 'Notification',
      },
      render: () => <Notification errors={errors} control={control} Controller={Controller} />,
    },
  ]

  return (
    <div className="kooper-full-page">
      <div className="page-header">
        <div className="top">
          <div className="title">
            <KooperButton basic inverted onClick={() => history.push(`/${MEETINGS_PATH.PREFIX}`)}>
              Exit
            </KooperButton>
          </div>

          <div className="center">
            <h5>{startCase(_.get(getSingleMeeting, 'meetingName', null))}</h5>
          </div>

          <div className="action">
            <LockPermissionTooltip isRoleAccessDenied={!manageMeetingsPermissions}>
              <KooperButton
                loading={isLoadingData && type.includes(EDIT_MEETING)}
                disabled={uploadProfile ? false : !isDirty || (isLoadingData && type.includes(EDIT_MEETING))}
                primary
                {...(manageMeetingsPermissions && {
                  onClick: handleSubmit(updateMeeting),
                })}
              >
                Save
              </KooperButton>
            </LockPermissionTooltip>
          </div>
        </div>
      </div>
      <div className="editTab">
        <KooperTab
          menu={{
            secondary: true,
            pointing: true,
            color: 'blue',
          }}
          panes={meeting}
        />
      </div>
    </div>
  )
}

export default MeetingUpdate
