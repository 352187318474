import React, {useEffect, useMemo, useState} from 'react'
import {KooperButton, KooperModal, KooperModalActions, KooperModalContent, KooperModalHeader} from 'kooper-library'
import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {getUsers} from 'actions/settings'
import {createProject, getProjectsLookup, getProjectsSingleTemplate, getProjectsTemplateslist} from 'actions/projects'
import {getAllContactsIds} from 'actions/contacts'
import {
  CREATE_PROJECT,
  GET_ALL_CONTACTS_IDS,
  GET_PROJECTS_LOOKUP,
  GET_PROJECTS_SINGLE_TEMPLATE,
  GET_USERS,
} from 'actions/types'
import {getUserInfo} from 'utils/local-storage'
import {joiResolver} from '@hookform/resolvers'
import {
  createProjectAdvanceOptionSchema,
  createProjectDetailsSchema,
} from 'validation/Projects/projects/projects.schema'
import {PROJECT_FINANCE_BUDGET_TYPES} from 'constants/projects'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import ProjectAdvanceOptionCarousel from './ProjectModalSteps/ProjectAdvanceOptionCarousel'
import ProjectDetailsCarousel from './ProjectModalSteps/ProjectDetailsCarousel'

const createProjectAdvanceOptionDefaultValues = {
  assignedTo: [Number(getUserInfo().id)],
  managerId: null,
  contactIds: [],
  projectStartDate: new Date(),
  projectEndDate: new Date(),
  categoryId: null,
  status: 'active',
}

const CreateProjectModalStep = ({open, toggle, templateId}) => {
  const dispatch = useDispatch()

  const [currentSlide, setCurrentSlide] = useState(0)
  const [budgetTimeError, setBudgetTimeError] = useState('')
  const [usersList, setUsersList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [contactsList, setContactsList] = useState([])
  const [projectCreateData, setProjectCreateData] = useState({})

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {categories = []} = useSelector(state => state.projects)

  const createProjectDetailsDefaultValues = useMemo(() => {
    return {
      useTemplate: !!templateId,
      templateId,
      title: '',
      description: '',
      budgetType: PROJECT_FINANCE_BUDGET_TYPES.NO_BUDGET,
      rangeType: 'financial',
      budgetAmount: 0,
      budgetTimeHour: 0,
      budgetTimeMinute: 0,
      startDate: new Date(),
      endDate: new Date(),
      timelogType: 'billable',
      repeatUnit: 'month',
      carryUnderspend: false,
      carryOverspend: false,
      budgetProfitMargin: 0,
      budgetTargetProfit: 0,
      budgetTargetCosts: 0,
    }
  }, [templateId])

  const {
    handleSubmit: handleSubmitProjectDetails,
    control: controlProjectDetails,
    errors: errorsProjectDetails,
    watch: watchProjectDetails,
    setValue: setValueProjectDetails,
    getValues: getValuesProjectDetails,
    reset: resetProjectDetails,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: createProjectDetailsDefaultValues,
    resolver: joiResolver(createProjectDetailsSchema),
  })

  const {
    handleSubmit: handleSubmitProjectAdvanceOptions,
    control: controlProjectAdvanceOptions,
    errors: errorsProjectAdvanceOptions,
    reset: resetProjectAdvanceOPtions,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: createProjectAdvanceOptionDefaultValues,
    resolver: joiResolver(createProjectAdvanceOptionSchema),
  })

  const watchBudgetType = watchProjectDetails('budgetType')
  const watchRangeType = watchProjectDetails('rangeType')
  const watchUseTemplate = watchProjectDetails('useTemplate')
  const watchTemplateId = watchProjectDetails('templateId')

  useApiResponse({
    action: getProjectsSingleTemplate,
    payload: watchTemplateId,
    dependency: [watchTemplateId],
    enabled: watchTemplateId,
    label: GET_PROJECTS_SINGLE_TEMPLATE,
    storePath: 'projects.projectsTemplateData',
    onSuccess: projectsTemplateData => {
      const {budgetData = {}} = projectsTemplateData || {}

      const projectDetailsData = {
        useTemplate: true,
        templateId: watchTemplateId,
        title: projectsTemplateData.title,
        description: projectsTemplateData.description,
        budgetType: projectsTemplateData.budgetType ?? createProjectDetailsDefaultValues.budgetType,
        rangeType: budgetData?.rangeType ?? createProjectDetailsDefaultValues.rangeType,
        budgetAmount: budgetData?.budgetAmount ?? createProjectDetailsDefaultValues.budgetAmount,
        budgetTimeHour: createProjectDetailsDefaultValues.budgetTimeHour,
        budgetTimeMinute: createProjectDetailsDefaultValues.budgetTimeMinute,
        startDate: createProjectDetailsDefaultValues.startDate,
        endDate: createProjectDetailsDefaultValues.endDate,
        timelogType: budgetData?.timelogType ?? createProjectDetailsDefaultValues.timelogType,
        repeatUnit: budgetData?.repeatUnit ?? createProjectDetailsDefaultValues.repeatUnit,
        carryUnderspend: budgetData?.carryUnderspend ?? createProjectDetailsDefaultValues.carryUnderspend,
        carryOverspend: budgetData?.carryOverspend ?? createProjectDetailsDefaultValues.carryOverspend,
        budgetProfitMargin: budgetData?.budgetProfitMargin ?? createProjectDetailsDefaultValues.budgetProfitMargin,
        budgetTargetProfit: createProjectDetailsDefaultValues.budgetTargetProfit,
        budgetTargetCosts: createProjectDetailsDefaultValues.budgetTargetCosts,
      }
      if (projectsTemplateData.rangeType === 'time') {
        projectDetailsData.budgetTimeHour = Math.floor(budgetData?.budgetAmount / 60)
        projectDetailsData.budgetTimeMinute = budgetData?.budgetAmount % 60
      }
      if (projectsTemplateData.budgetType === PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE) {
        const targetProfitFromMargin = parseFloat(
          (budgetData?.budgetAmount * (budgetData?.budgetProfitMargin / 100)).toFixed(2)
        )
        const targetCostsFromMargin = parseFloat((budgetData?.budgetAmount - targetProfitFromMargin).toFixed(2))
        projectDetailsData.budgetTargetProfit = targetProfitFromMargin
        projectDetailsData.budgetTargetCosts = targetCostsFromMargin
      }

      const advanceOptionData = {
        assignedTo: projectsTemplateData.assignedTo ?? createProjectAdvanceOptionDefaultValues.assignedTo,
        managerId: projectsTemplateData.managerId ?? createProjectAdvanceOptionDefaultValues.managerId,
        contactIds: projectsTemplateData.contactIds ?? createProjectAdvanceOptionDefaultValues.contactIds,
        projectStartDate: createProjectAdvanceOptionDefaultValues.projectStartDate,
        projectEndDate: createProjectAdvanceOptionDefaultValues.projectEndDate,
        categoryId: projectsTemplateData.categoryId ?? createProjectAdvanceOptionDefaultValues.categoryId,
        status: createProjectAdvanceOptionDefaultValues.status,
      }
      resetProjectDetails(projectDetailsData)
      resetProjectAdvanceOPtions(advanceOptionData)
    },
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUsersList(list || [])
    },
  })

  useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
    onSuccess: contactsIdsList => {
      const list = contactsIdsList.map(({id, name}) => ({
        key: id,
        value: id,
        text: name,
      }))
      setContactsList(list || [])
    },
  })

  useEffect(() => {
    dispatch(getProjectsLookup({lookup: ['categories', 'users']}))
    dispatch(getProjectsTemplateslist())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_PROJECTS_LOOKUP)) {
      if (categories.length === 0) {
        setCategoryList([{key: 0, value: 0, text: 'General'}])
      } else {
        setCategoryList([...categories.map(({id, name}) => ({key: id, value: id, text: name}))])
      }
    }
  }, [successLabels, categories])

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECT)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const carousels = ['projectDetails', 'advanceOption']

  const actionCancelModal = action => {
    if (action === 'prev') {
      setCurrentSlide(currentSlide => currentSlide - 1)
    }
    if (action === 'next') {
      setCurrentSlide(currentSlide => currentSlide + 1)
    }
  }

  const submitForm = data => {
    if (currentSlide === 0) {
      const payload = {...data}
      if (payload.rangeType === 'time') {
        if (payload.budgetTimeHour * 60 + payload.budgetTimeMinute === 0) {
          setBudgetTimeError('Please enter a value greater than 0')
          return
        }
        payload.budgetAmount = payload.budgetTimeHour * 60 + payload.budgetTimeMinute
      }
      setProjectCreateData({...projectCreateData, ...payload})
      actionCancelModal('next')
    }
    if (currentSlide === 1) {
      setProjectCreateData({...projectCreateData, ...data})
      const {projectStartDate, projectEndDate, ...resetData} = data
      const {
        templateId,
        title,
        description,
        budgetType,
        rangeType,
        budgetAmount,
        startDate,
        endDate,
        timelogType,
        repeatUnit,
        carryUnderspend,
        carryOverspend,
        budgetProfitMargin,
      } = projectCreateData
      const budgetData = {
        budgetType,
        rangeType,
        budgetAmount,
        startDate,
        endDate,
        timelogType,
        repeatUnit,
        carryUnderspend,
        carryOverspend,
        isDefaultBillableRateSet: false,
        defaultBillableRate: 0,
        budgetProfitMargin,
      }
      const payload = {
        templateId,
        title,
        description,
        startDate: projectStartDate,
        endDate: projectEndDate,
        ...resetData,
        budgetData,
      }
      dispatch(createProject(payload))
    }
  }

  return (
    <KooperModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="small"
      open={open}
      onClose={() => toggle(false)}
    >
      <KooperModalHeader className="project-modal-header">Add Project</KooperModalHeader>
      <KooperModalContent scrolling>
        <ProjectDetailsCarousel
          style={{display: currentSlide === 0 ? '' : 'none'}}
          control={controlProjectDetails}
          errors={errorsProjectDetails}
          budgetTimeError={budgetTimeError}
          setBudgetTimeError={setBudgetTimeError}
          budgetType={watchBudgetType}
          rangeType={watchRangeType}
          setValue={setValueProjectDetails}
          getValues={getValuesProjectDetails}
          useTemplate={watchUseTemplate}
        />
        <ProjectAdvanceOptionCarousel
          style={{display: currentSlide === 1 ? '' : 'none'}}
          control={controlProjectAdvanceOptions}
          errors={errorsProjectAdvanceOptions}
          usersList={usersList}
          contactsList={contactsList}
          categoryList={categoryList}
          setCategoryList={setCategoryList}
        />
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => toggle(false)}>
          Cancel
        </KooperButton>
        {currentSlide > 0 && (
          <KooperButton basic onClick={() => actionCancelModal('prev')}>
            Prev Step
          </KooperButton>
        )}
        <KooperButton
          primary
          onClick={
            currentSlide === 0 ? handleSubmitProjectDetails(submitForm) : handleSubmitProjectAdvanceOptions(submitForm)
          }
        >
          {currentSlide === carousels.length - 1 ? 'Create Project' : 'Next Step'}
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default CreateProjectModalStep
