import React from 'react'
import {
  KooperButton,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperModalHeader,
  KooperStatistic,
  KooperStatisticValue,
} from 'kooper-library'
import {get, startCase} from 'utils/helper'
import {useDispatch, useSelector} from 'react-redux'
import {startBilling, updateBilling} from 'actions/billing'
import _ from 'lodash'
import SvgIcon from 'components/common/SvgIcon'

const CheckoutModal = props => {
  const {isOpen, setIsOpen, paymentData} = props

  const dispatch = useDispatch()

  const {isLoadingData} = useSelector(state => state.apiReducer)
  const {calculateBillingData = {}, currentSubscriptionData = {}} = useSelector(state => state.billing)

  const payment = (
    <>
      <KooperModalHeader>Are you sure ?</KooperModalHeader>
      <KooperModalContent>
        <h5>You have chosen {startCase(paymentData.plan)} Plan</h5>
        <KooperStatistic color="blue">
          <KooperStatisticValue>$ {Number(calculateBillingData.finalAmount).toFixed(2)}</KooperStatisticValue>
        </KooperStatistic>
      </KooperModalContent>
    </>
  )

  const actionCancelModal = action => {
    if (action === 'completed') {
      if (get(['subscription', 'status'], currentSubscriptionData) === 'trialing') {
        dispatch(startBilling(_.omit(paymentData, 'interval')))
      } else {
        dispatch(updateBilling(_.omit(paymentData, 'interval')))
      }
    }
  }

  return (
    <KooperModal
      size="tiny"
      open={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      onClose={() => setIsOpen(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
    >
      {payment}
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsOpen(false)}>
          Cancel
        </KooperButton>
        <KooperButton primary loading={isLoadingData} onClick={() => actionCancelModal('completed')}>
          Pay Now
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default CheckoutModal
