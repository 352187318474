/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {KooperButton, KooperIcon, KooperProgress} from 'kooper-library'
import {wrapText} from 'utils/helper'
import {ATTACHMENT_POST} from 'actions/types'
import SvgIcon from './SvgIcon'

const AttachmentPreview = props => {
  const {files, success, cancelToken, uploadedFiles = [], setUploadedFiles} = props
  const dispatch = useDispatch()

  const {isLoadingData, successData = '', successLabels = []} = useSelector(state => state.apiReducer)
  const {attachmentList = [], successData: attachmentSuccessData} = useSelector(state => state.attachment)
  const {data: percent = {percent: 0}} = useSelector(state => state.loader)

  useEffect(() => {
    if (attachmentSuccessData === success && successLabels.includes(ATTACHMENT_POST)) {
      const newFiles = attachmentList.map(file => ({
        id: file.id,
        name: file.name,
        size: file.size,
      }))

      setUploadedFiles([...uploadedFiles, ...newFiles])
      dispatch({
        type: 'CLEAR_ATTACHMENT',
      })
    }
  }, [successLabels])

  const renderLoader = () => {
    if (isLoadingData && successData === success) {
      return files.map(file => (
        <div className="attachPreview">
          <h5>
            {wrapText(file.file.name, 15)}
            <span>
              {`(${
                Number(file.file.size / (1024 * 1024)).toFixed(0) > 1
                  ? `${Number(file.file.size / (1024 * 1024)).toFixed(0)} Mb`
                  : `${Number(file.file.size / 1024).toFixed(0)} Kb`
              })`}
            </span>
          </h5>
          <KooperProgress
            size="small"
            indicating
            percent={percent.percent}
            warning={percent.percent < 100}
            success={percent.percent === 100}
          />

          <KooperButton icon onClick={() => cancelToken.cancel()}>
            <SvgIcon path="common/delete" />
          </KooperButton>
        </div>
      ))
    }
    return null
  }

  const renderListOfFiles = () => {
    return uploadedFiles.map(file => (
      <div className="attachPreview" style={{justifyContent: 'space-between'}}>
        <h5>
          {wrapText(file.name, 15)}
          {`(${
            Number(file.size / (1024 * 1024)).toFixed(0) > 1
              ? `${Number(file.size / (1024 * 1024)).toFixed(0)} Mb`
              : `${Number(file.size / 1024).toFixed(0)} Kb`
          })`}
        </h5>
        <KooperIcon
          style={{marginTop: '-6px'}}
          onClick={() => {
            const updatedList = uploadedFiles.filter(list => file.id !== list.id)
            setUploadedFiles(updatedList)
          }}
          name="delete"
        />
      </div>
    ))
  }

  return (
    <div>
      {renderLoader()}
      {renderListOfFiles()}
    </div>
  )
}

export default AttachmentPreview
