import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {
  KooperButton,
  KooperForm,
  KooperFormField,
  KooperFormInput,
  KooperFormSelect,
  KooperHeader,
  KooperModal,
  KooperModalActions,
  KooperModalContent,
  KooperTooltip,
} from 'kooper-library'
import SvgIcon from 'components/common/SvgIcon'
import {MEETINGS_PATH} from 'constants/path'

const meetingType = [
  {key: 1, value: 1, text: 'Personal'},
  {key: 2, value: 2, text: 'Team'},
]

const CreateMeetingModal = props => {
  const {isModalOpen, setIsModalOpen} = props
  const history = useHistory()

  const [data, setData] = useState({
    meetingName: '',
    type: 1,
    calendarId: '',
  })
  const [calendarListState, setCalendarList] = useState([])
  const {meetingsList = {}} = useSelector(state => state.meetings)
  const {calendarList} = meetingsList

  useEffect(() => {
    if (calendarList) {
      const temp = calendarList.map(list => ({
        key: list.calendarId,
        text: `${list.family_name} (${list.email})`,
        value: list.calendarId,
      }))

      setCalendarList(temp)
    }
  }, [calendarList])

  return (
    <KooperModal
      size="tiny"
      open={isModalOpen}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <KooperHeader>Create Meeting</KooperHeader>
      <KooperModalContent>
        <KooperForm>
          <KooperFormField required>
            <label>Meeting Name</label>
            <KooperFormInput
              type="text"
              fluid
              maxLength={30}
              value={data.meetingName}
              placeholder="Type meeting name..."
              onChange={(e, {value}) => {
                setData({...data, meetingName: value})
              }}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label>Type</label>
              <KooperTooltip
                content="Lorem ipsum"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <KooperFormSelect
              fluid
              options={meetingType}
              selection
              placeholder="Type"
              defaultValue={data.type}
              onChange={(e, {value}) => {
                setData({
                  ...data,
                  type: value,
                })
              }}
            />
          </KooperFormField>
          <KooperFormField required>
            <div className="info-header">
              <label>Select Calendar</label>
              <KooperTooltip
                content="Lorem ipsum"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <KooperFormSelect
              fluid
              options={calendarListState}
              selection
              placeholder="Calendar"
              defaultValue={data.calendarId}
              onChange={(e, {value}) => {
                setData({
                  ...data,
                  calendarId: value,
                })
              }}
            />
          </KooperFormField>
        </KooperForm>
      </KooperModalContent>
      <KooperModalActions>
        <KooperButton basic onClick={() => setIsModalOpen(!isModalOpen)}>
          Cancel
        </KooperButton>
        <KooperButton
          disabled={!(data.meetingName.trim() && data.type && data.calendarId)}
          primary
          onClick={() => {
            history.push({
              pathname: `/${MEETINGS_PATH.PREFIX}/${MEETINGS_PATH.CREATE}`,
              state: data,
            })
          }}
        >
          Create
        </KooperButton>
      </KooperModalActions>
    </KooperModal>
  )
}

export default CreateMeetingModal
