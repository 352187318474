import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import DocumentTitle from 'components/common/DocumentTitle'
import PurchaseOrders from 'components/pages/Finance/PurchaseOrders/PurchaseOrders'
import PrefixList from 'components/pages/Finance/PurchaseOrders/Settings/Prefix/PrefixList'
import {PURCHASE_ORDERS_PATH} from 'constants/path'

const routes = [
  {path: PURCHASE_ORDERS_PATH.ALL, component: PurchaseOrders, title: 'All Purchase Orders', exact: true},
  {path: PURCHASE_ORDERS_PATH.PURCHASE_ORDERS_PREFIX, component: PrefixList, title: 'Prefix', exact: true},
]

const renderRoute = ({path, component: Component, title, exact}) => (
  <Route
    key={path}
    path={`/${PURCHASE_ORDERS_PATH.PREFIX}/${path}`}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} purchaseOrders />
        <Component {...props} />
      </>
    )}
  />
)

const PurchaseOrdersRoutes = () => (
  <Switch>
    <Route exact path={`/${PURCHASE_ORDERS_PATH.PREFIX}`}>
      <Redirect to={`/${PURCHASE_ORDERS_PATH.PREFIX}/${PURCHASE_ORDERS_PATH.ALL}`} />
    </Route>
    {routes.map(renderRoute)}
    <Route path={`/${PURCHASE_ORDERS_PATH.PREFIX}/*`}>
      <Redirect to={`/${PURCHASE_ORDERS_PATH.PREFIX}/${PURCHASE_ORDERS_PATH.ALL}`} />
    </Route>
  </Switch>
)

export default PurchaseOrdersRoutes
